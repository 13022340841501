import React, { useMemo } from 'react';
import {
  FaExternalLinkAlt,
  FaFan,
  FaGlobe,
  FaQrcode,
  FaShare
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MODAL_KEY_PAGE_LINK_SHARE } from '../../../../constants/modals';
import { setCurrentModal } from '../../../../actions/Modals';
import copyToClipBoard from '../../../../utils/copyToClipBoard';
import { TURBINE_PAGE } from '../../../../constants/urls';
import './style.css';

const TopNav = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);

  // Page
  const pageLinkId = useMemo(() => {
    return page?.pageLinkId || null;
  }, [page?.pageLinkId]);

  const qrCodeUrl = useMemo(() => {
    if (pageLinkId && pageLink?.id) {
      return `${TURBINE_PAGE}/link/${pageLinkId}`;
    }

    return null;
  }, [pageLink?.id, pageLinkId]);

  const onClickShare = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_PAGE_LINK_SHARE,
        data: {
          className: 'fullScreenIframe',
          qrCodeUrl,
          pageTitle: page?.title
        }
      })
    );
  };

  return (
    <div
      id="Page-TopNav"
      className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom"
    >
      <h1 id="turbine-page-title">{page?.title}</h1>
      <div className="btn-toolbar mb-2 mb-md-0">
        <div className="dropdown">
          <button
            className="btn btn-primary" // dropdown-toggle
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="d-flex align-items-center justify-content-between">
              <FaShare className="mr-2 d-none d-md-block" />
              <span>Share</span>
            </span>
          </button>
          <div className="dropdown-menu dropdown-menu-right p-0">
            <div className="p-3 border-bottom font-weight-bold text-center">
              Share Linkpage
            </div>
            <button
              type="button"
              className="dropdown-item border-bottom py-3"
              aria-label="Share Link"
              onClick={onClickShare}
            >
              <span className="d-flex align-items-center">
                <span className="bg-keppel text-white p-2 rounded">
                  <FaQrcode size={20} />
                </span>
                <span className="ml-2">Linkpage QR Code</span>
              </span>
            </button>

            <a
              href={qrCodeUrl}
              className="dropdown-item border-bottom py-3"
              aria-label="Share Link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="d-flex align-items-center justify-content-between">
                <span className="d-flex align-items-center">
                  <span className="bg-primary text-white p-2 rounded">
                    <FaGlobe size={20} />
                  </span>
                  <span className="ml-2">Open Linkpage</span>
                </span>
                <FaExternalLinkAlt className="text-muted" />
              </span>
            </a>

            <div className="card-footer">
              <div className="rounded border">
                <button
                  type="button"
                  title="Copy Linkpage"
                  className="dropdown-item border-bottom py-2 px-3"
                  onClick={() => {
                    copyToClipBoard(qrCodeUrl);
                    toast.success('Link Copied!');
                  }}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <FaFan />
                    <span
                      className="ml-2 overflow-hidden mx-3"
                      style={{
                        maxWidth: '180px',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {qrCodeUrl}
                    </span>
                    <span>Copy</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
