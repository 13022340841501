import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@apprentage/components/dist/components/Collapse';
import { FaClock, FaCog } from 'react-icons/fa';
import UserCompetenciesList from '../lists/UserCompetenciesList';
import { resetSideSheet, setSideSheet } from '../../actions/SideSheets';
import { SHEET_KEY_MANAGE_USER_COMPETENCIES } from '../../constants/sideSheets';
import { getApprenticeshipUser } from '../../actions/Apprenticeships';

const LogbookView = ({ className, ariaExpanded }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserId = apprenticeshipUser?.id || null;
  // Competencies
  const competencies = apprenticeship?.competencies || {};
  const competencyGroups = apprenticeship?.competencyGroups || [];
  // User Competencies
  const userCompetencies = apprenticeship.userCompetencies || null;
  const userCompetenciesHash = apprenticeship.userCompetenciesHash || null;

  const onClickManageUserCompetencies = (competencyId) => {
    dispatch(
      setSideSheet({
        key: SHEET_KEY_MANAGE_USER_COMPETENCIES,
        className: 'card-style MaterialSideSheet',
        data: {
          ...(competencyId ? { competencyId } : {}),
          title: 'Customize Target Hours',
          userCompetencies,
          userCompetenciesHash,
          competencies,
          competencyGroups
        },
        callbackPrimaryAction: () => {
          dispatch(
            getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId, orgId)
          );
          dispatch(resetSideSheet());
        }
      })
    );
  };

  return (
    <div className={`LogbookView ${className}`}>
      {!competencyGroups ||
      (Array.isArray(competencyGroups) && competencyGroups?.length === 0) ? (
        <div>No competency groups.</div>
      ) : (
        <div>
          <div className="bg-2f2f2f text-white rounded d-flex align-items-center justify-content-between p-3 mb-3">
            <div className="d-flex align-items-center">
              <FaClock
                className="mr-2"
                size={20}
              />
              <div className="h6 m-0">Target Hours</div>
            </div>
            <button
              onClick={onClickManageUserCompetencies}
              type="button"
              className="btn btn-outline-white btn-sm"
            >
              <span className="d-flex align-items-center">
                <FaCog />
                <span className="ml-2 d-none d-sm-block">Settings</span>
              </span>
            </button>
          </div>

          {competencyGroups.map((competencyGroup) => {
            const childCompetencies = competencyGroup?.competencyIds?.length
              ? competencyGroup?.competencyIds.map((cId) => competencies[cId])
              : [];

            return (
              <Fragment key={`competencyGroup-${competencyGroup?.id}`}>
                <Collapse
                  id={`competencyGroup-${competencyGroup?.id}`}
                  title={competencyGroup?.description}
                  className="mb-3"
                  p={3}
                  ariaExpanded={competencyGroups.length === 1 || ariaExpanded}
                >
                  <UserCompetenciesList
                    list={childCompetencies}
                    onClickTargetHours={onClickManageUserCompetencies}
                    showClassifications
                  />
                </Collapse>
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LogbookView;
