import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isProgramTrainer, isProgramManager } from '@apprentage/utils';
import {
  canAccessAllApprenticeshipUsers,
  canAccessUserWageSchedule
} from '../../../../permissions/access';
import UsersList from '../../../UsersList';
import { handleOpenUserSideSheet } from '../../../../actions/SideSheets';

const MyTrainees = ({ title = 'My Trainees', className = 'mt-3' }) => {
  const dispatch = useDispatch();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const locationId = currentUser?.locationId || null;

  const handleClickRow = (user) => {
    dispatch(
      handleOpenUserSideSheet({
        userId: user?.id,
        orgId,
        currentUserOrgId: currentUser?.orgId
        // route,
        // history
      })
    );
  };

  const hiddenColumns = useMemo(() => {
    const cols = ['locationId', 'remindersCell'];

    if (isProgramManager(role) || isProgramTrainer(role)) {
      cols.push('userProfileId');
      cols.push('isApprentice');
    }

    if (!canAccessUserWageSchedule(role)) {
      cols.push('wageScheduleCell');
    }

    return cols;
  }, [role]);

  const userListConfig = useMemo(
    () => ({
      orgId,
      supervisorIds: [userId],
      excludeTestUsers: true,
      excludeInactive: true,
      ...(!canAccessAllApprenticeshipUsers(role) ? { locationId } : {})
    }),
    [locationId, orgId, role, userId]
  );

  const [listConfig] = useState(userListConfig);

  return (
    <div className={className}>
      <div className="h4 mb-3 d-flex align-items-center">{title}</div>

      <UsersList
        className="bg-white shadow p-3 rounded"
        handleClickRow={handleClickRow}
        hiddenColumns={hiddenColumns}
        listConfig={listConfig}
      />
    </div>
  );
};

export default MyTrainees;
