import { isAdminOwnerSuperAdmin } from '@apprentage/utils';

export default function canDeleteResourceGroupTag(
  role = [],
  userId,
  authorUserId
) {
  if (!role || (Array.isArray(role) && role.length === 0)) {
    return false;
  }

  if (!userId) {
    return false;
  }

  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  return userId === authorUserId;
}
