import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IMG_LINKEDIN_ICON } from '@apprentage/constants';
import SkillsSelectMenu from '../../../OrgConsole/Skills/SkillsSelectMenu';
import { updateUser } from '../../../../services/user';
import { getDirectoryUser } from '../../../../actions/Directory';
import { getCurrentUser } from '../../../../actions/Users';
import RenderTinyMceEditor from '../../../ManageContent/Wysiwyg/RenderTinyMceEditor';
import './style.css'; // TODO use styled components

const ProfileForm = ({ handleCancel }) => {
  const dispatch = useDispatch();
  const aboutMeRef = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  // Local State
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(directoryUser?.firstName || '');
  const [lastName, setLastName] = useState(directoryUser?.lastName || '');
  const [middleName, setMiddleName] = useState(directoryUser?.middleName || '');
  const [suffix, setSuffix] = useState(directoryUser?.suffix || '');
  const [title, setTitle] = useState(directoryUser?.title || '');
  const [email] = useState(directoryUser?.email || '');
  const [phone, setPhone] = useState(directoryUser?.phone || '');
  const [phoneExt, setPhoneExt] = useState(directoryUser?.phoneExt || '');
  const [mobilePhone, setMobilePhone] = useState(
    directoryUser?.mobilePhone || ''
  );
  const [headline, setHeadline] = useState(directoryUser?.headline || '');
  const [linkedInUrl, setLinkedInUrl] = useState(
    directoryUser?.linkedInUrl || ''
  );
  const [aboutMe] = useState(directoryUser?.aboutMe || '');
  const [calendarLink, setCalendarLink] = useState(
    directoryUser?.calendarLink || ''
  );
  // Skills (Expertise)
  const [skillIds, setSKillIds] = useState(directoryUser?.skillIds);

  const onChangeSkills = (options) => {
    setSKillIds(options.map((skill) => skill.value));
  };

  // User
  const userId = currentUser?.id || '';
  // Organization
  const orgId = organization?.id || '';

  const name = useMemo(() => {
    let fullName = '';

    if (firstName) fullName += firstName;
    if (middleName) fullName += ` ${middleName}`;
    if (lastName) fullName += ` ${lastName}`;
    if (suffix) fullName += `, ${suffix}`;

    return fullName;
  }, [firstName, lastName, middleName, suffix]);

  if (!directoryUser?.id) {
    return null;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    toast.info('Saving...');

    const newSkillIds =
      Array.isArray(skillIds) && skillIds.length > 0 ? skillIds : null;

    const dataToSave = {
      name,
      firstName,
      middleName,
      lastName,
      suffix,
      title,
      phone,
      phoneExt,
      mobilePhone,
      headline,
      calendarLink,
      skillIds: newSkillIds,
      linkedInUrl
    };

    if (aboutMeRef && aboutMeRef.current && aboutMeRef.current.isDirty) {
      dataToSave.aboutMe = aboutMeRef.current.getContent();
    }

    updateUser(dataToSave, directoryUser?.id).then(() => {
      toast.dismiss();
      toast.success('Profile saved!');
      if (directoryUser?.id === userId) {
        dispatch(getCurrentUser({ userId: directoryUser?.id })).then(() => {
          setLoading(false);
          handleCancel();
        });
      }

      dispatch(getDirectoryUser({ orgId, userId: directoryUser?.id })).then(
        () => {
          if (directoryUser?.id !== userId) {
            // Don't reset form twice if editing current user in directory
            setLoading(false);
            handleCancel();
          }
        }
      );
    });
  };

  return (
    <form
      className="edit-user-details-form"
      onSubmit={onSubmit}
    >
      <div
        className="card-body"
        style={{
          overflowY: 'scroll',
          height: 'calc(100vh - 170px)'
        }}
      >
        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="firstName">
              First Name <span className="text-danger">*</span>
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="firstName"
              onChange={(e) => {
                const { value: newFirstName } = e.currentTarget;

                setFirstName(newFirstName);
              }}
              className="form-control"
              value={firstName}
              required
            />
          </div>

          <div className="col-sm-6">
            <label htmlFor="lastName">
              Last Name <span className="text-danger">*</span>
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="lastName"
              onChange={(e) => {
                const { value: newLastName } = e.currentTarget;

                setLastName(newLastName);
              }}
              className="form-control"
              value={lastName}
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="middleName">Middle Name</label>
            <input
              data-demo="disabled"
              type="text"
              name="middleName"
              onChange={(e) => {
                const { value: newMiddleName } = e.currentTarget;

                setMiddleName(newMiddleName);
              }}
              className="form-control"
              value={middleName}
            />
          </div>

          <div className="col-sm-6">
            <label htmlFor="suffix">Suffix/Professional Credentials</label>
            <input
              data-demo="disabled"
              type="text"
              name="suffix"
              onChange={(e) => {
                const { value: newSuffix } = e.currentTarget;

                setSuffix(newSuffix);
              }}
              className="form-control"
              value={suffix}
            />
            <div className="small mt-1">
              Ex: PE, AIA, EIT, PhD, CCNA, Jr., III, etc.
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="title">Title</label>
          <input
            data-demo="disabled"
            type="text"
            name="title"
            onChange={(e) => {
              const { value: newTitle } = e.currentTarget;

              setTitle(newTitle);
            }}
            className="form-control"
            value={title}
          />
        </div>

        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="phone">Phone</label>
            <input
              data-demo="disabled"
              type="text"
              name="phone"
              onChange={(e) => {
                const { value: newPhone } = e.currentTarget;

                setPhone(newPhone);
              }}
              className="form-control"
              value={phone}
            />
            <div className="small mt-1">Ex, 412-444-8615</div>
          </div>

          <div className="col-sm-6">
            <label htmlFor="phoneExt">Phone Extension</label>
            <input
              data-demo="disabled"
              type="text"
              name="phoneExt"
              onChange={(e) => {
                const { value: newPhoneExt } = e.currentTarget;

                setPhoneExt(newPhoneExt);
              }}
              className="form-control"
              value={phoneExt}
            />
            <div className="small mt-1">Ex, 101</div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="mobilePhone">Mobile Phone</label>
          <input
            data-demo="disabled"
            type="text"
            name="mobilePhone"
            onChange={(e) => {
              const { value: newMobilePhone } = e.currentTarget;

              setMobilePhone(newMobilePhone);
            }}
            className="form-control"
            value={mobilePhone}
          />
          <div className="small mt-1">Ex, 412-444-8615</div>
        </div>

        <hr className="mt-4 mb-3" />

        <div className="mb-3">
          <label htmlFor="headline">Headline</label>
          <input
            data-demo="disabled"
            type="text"
            name="headline"
            onChange={(e) => {
              const { value: newHeadline } = e.currentTarget;

              setHeadline(newHeadline);
            }}
            className="form-control"
            value={headline}
          />
        </div>

        <RenderTinyMceEditor
          id="user-aboutMe"
          className="mb-3"
          title="About Me"
          defaultValue={aboutMe}
          editorRef={aboutMeRef}
          maxCharCount={1000}
          menubar={false}
          mobileMenubar={false}
        />

        <SkillsSelectMenu
          className="position-relative list-group-item py-4 mb-3"
          ids={skillIds}
          onChange={onChangeSkills}
          title="Skills (Expertise)"
          subtitle="Select skills based on expertise in a given domain"
          showHelpText
          showManageLink
        />

        <div className="mb-3">
          <label
            htmlFor="headline"
            className="mb-0"
          >
            Calendar Link
          </label>
          <input
            data-demo="disabled"
            type="text"
            name="calendarLink"
            onChange={(e) => {
              const { value: newCalendarLink } = e.currentTarget;

              setCalendarLink(newCalendarLink);
            }}
            className="form-control"
            value={calendarLink}
          />
          <div className="small mt-1">Ex: https://calendly.com/xyz</div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="headline"
            className="d-flex align-items-center"
          >
            <img
              src={IMG_LINKEDIN_ICON}
              className="mr-1"
              alt="LinkedIn Icon"
              height="17"
              style={{
                height: '17px'
              }}
            />
            <span>LinkedIn</span>
          </label>
          <input
            data-demo="disabled"
            type="text"
            name="linkedInUrl"
            onChange={(e) => {
              const { value } = e.currentTarget;

              setLinkedInUrl(value);
            }}
            className="form-control"
            value={linkedInUrl}
          />
          <div className="text-muted small mt-1">
            Ex: https://www.linkedin.com/in/yourname
          </div>
        </div>

        <hr className="mt-4 mb-3" />

        <label htmlFor="email">Email address</label>

        <div className="mb-3 d-flex flex-row justify-content-between">
          <small
            data-demo="disabled"
            className="text-muted"
          >
            Current Email: <b>{email}</b>
          </small>
        </div>
      </div>
      <div className="card-footer p-3 d-flex align-items-center bg-light">
        <button
          className="btn btn-md btn-primary"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>

        <button
          className="btn btn-md btn-link ml-2"
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ProfileForm;
