import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEyeSlash } from 'react-icons/fa';
import { canManageUserPII } from '../../../../services/currentUser';
import { getDirectoryUserProfile } from '../../../../actions/Directory';

const Tabs = ({ activeTab, setActiveTab, className = 'pt-3 bg-light' }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgType = organization?.type || '';
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  // Current User
  const role = currentUser?.role || [];
  // Local State
  const [loading, setLoading] = useState(false);

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setActiveTab('profile');
            }}
          >
            Profile
          </button>
        </li>

        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'supervisors' ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setActiveTab('supervisors');
            }}
          >
            {orgType === 'workforce' ? 'Supervisors' : 'Mentors'}
          </button>
        </li>

        {canManageUserPII(role, enableWorkforceSuite) && (
          <li className="nav-item ml-2">
            <button
              className={`nav-link ${activeTab === 'privateInfo' ? 'active' : ''}`}
              type="button"
              onClick={() => {
                if (directoryUser?.userProfileId) {
                  setLoading(true);
                  dispatch(
                    getDirectoryUserProfile(directoryUser?.userProfileId)
                  ).then(() => {
                    setLoading(false);
                    setActiveTab('privateInfo');
                  });
                } else {
                  setActiveTab('privateInfo');
                }
              }}
            >
              <span className="d-flex align-items-center">
                <FaEyeSlash />
                <span className="ml-1">
                  {loading ? 'Decrypting...' : 'Private'}
                </span>
              </span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Tabs;
