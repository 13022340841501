import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaExclamationTriangle, FaMinusCircle, FaSync } from 'react-icons/fa';
import generateRandomNumber from '../../../../utils/generateRandomNumber';
import NumberStat from '../../../NumberStat';

const Form = ({ onSuccessCallback, onCancelCallback, onSecondaryCallback }) => {
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal Data
  const modalData = currentModal?.data || {};
  const accessCodeLength = modalData?.accessCodeLength || 6;
  // Local State
  const [loading, setLoading] = useState(false);
  const [accessCode, setAccessCode] = useState(
    modalData?.accessCode || generateRandomNumber(accessCodeLength)
  );

  const saveButtonDisabled = useMemo(() => {
    if (loading || !accessCode) {
      return true;
    }

    if (accessCode && accessCode.toString().length !== accessCodeLength) {
      return true;
    }

    if (accessCode === modalData?.accessCode) {
      return true;
    }

    return false;
  }, [accessCode, accessCodeLength, loading, modalData?.accessCode]);

  const onClickSave = () => {
    if (
      !accessCode ||
      (accessCode && accessCode.toString().length !== accessCodeLength)
    ) {
      toast.error(`Enter an access code (${accessCodeLength} characters).`, {
        toastId: 'accessCodeInvalid',
        autoClose: false
      });
      return;
    }

    setLoading(true);

    // Update Record
    if (onSuccessCallback && typeof onSuccessCallback === 'function') {
      onSuccessCallback(Number(accessCode));
    }
  };

  const onClickRemoveAccessCode = () => {
    setLoading(true);

    // Update Record
    if (onSecondaryCallback && typeof onSecondaryCallback === 'function') {
      onSecondaryCallback();
    }
  };

  return (
    <div className="card">
      <div className="card-header bg-dark py-3">
        <h5 className="font-weight-bold m-0 text-capitalize">
          Linkpage Access Code
        </h5>
      </div>

      <div className="card-body">
        <div>
          <h5 className="text-center">
            Protect this Linkpage with an access code.
          </h5>
          <div
            className="mt-4 d-flex justify-content-center"
            style={{
              height: '34px'
            }}
          >
            {loading ? (
              <div className="d-flex mr-2">
                {Array.from(Array(accessCodeLength).keys()).map(() => (
                  <Skeleton
                    className="ml-2"
                    style={{ height: '30px', width: '20px' }}
                  />
                ))}
              </div>
            ) : (
              <div className="d-flex">
                <NumberStat
                  size="md"
                  className="ml-3"
                  style={{
                    letterSpacing: '.75rem'
                  }}
                  num={accessCode}
                />
              </div>
            )}
            <button
              type="button"
              className="btn btn-white btn-sm ml-2"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setAccessCode(
                  generateRandomNumber(accessCodeLength).toString()
                );
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
              aria-label="New Code"
            >
              <FaSync />
            </button>
          </div>
          <div className="mt-4 d-flex justify-content-center" />
        </div>

        {modalData?.accessCode && !saveButtonDisabled && (
          <div className="d-flex align-items-center justify-content-center">
            <FaExclamationTriangle className="text-warning mr-2" />
            <span>
              Clicking save will revoke access for all previous access codes.
            </span>
          </div>
        )}
      </div>

      <div className="card-footer py-3 d-flex justify-content-between">
        <div>
          <button
            className="btn btn-primary btn-md"
            type="submit"
            disabled={saveButtonDisabled}
            onClick={onClickSave}
          >
            Save
          </button>
          <button
            className="btn btn-link btn-md ml-2"
            type="button"
            disabled={loading}
            onClick={onCancelCallback}
          >
            Cancel
          </button>
        </div>

        {onSecondaryCallback && modalData?.accessCode !== null && (
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={onClickRemoveAccessCode}
          >
            <span className="d-flex align-items-center">
              <FaMinusCircle />
              <span className="ml-1">Remove Code</span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Form;
