import { roleReadable } from '@apprentage/utils';
import React from 'react';
import { FaShieldAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const UserRolesCell = ({ row, value: userRoles }) => {
  const organization = useSelector((state) => state.organization);

  if (userRoles && Array.isArray(userRoles) && userRoles.length > 0) {
    return (
      <>
        {userRoles.map((userRole, index) => (
          <span
            key={`userRole-${row?.original?.id}-${index}`}
            className={`h6 ${index === userRoles.length - 1 ? '' : 'mr-2'}`}
          >
            <span className="badge bg-white border">
              <span className="d-flex align-items-center">
                <FaShieldAlt />
                <span className="ml-1">
                  {roleReadable([userRole], organization?.type)}
                </span>
              </span>
            </span>
          </span>
        ))}
      </>
    );
  }

  return null;
};

export default UserRolesCell;
