import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TbWorldSearch } from 'react-icons/tb';
import {
  ICON_ATTENDANCE,
  ICON_BRANDING,
  ICON_CHALLENGE,
  ICON_CHART,
  ICON_COHORT,
  ICON_BELL,
  ICON_USER_LOCK,
  ICON_PENCIL_BOX,
  ICON_QUIZ,
  ICON_SETTINGS
} from '@apprentage/constants';
import { FaCog, FaStream } from 'react-icons/fa';
import { manageEntry } from '../../../services/entry';
import {
  CLASS_CONSOLE_BRANDING,
  CLASS_CONSOLE_CHALLENGES,
  CLASS_CONSOLE_CHALLENGE_BASE,
  CLASS_CONSOLE_DASHBOARD,
  CLASS_CONSOLE_NOTIFICATIONS,
  CLASS_CONSOLE_PACING,
  CLASS_CONSOLE_PUBLISH,
  CLASS_CONSOLE_QUIZZES,
  CLASS_CONSOLE_SETTINGS,
  CLASS_CONSOLE_STREAMING,
  CLASS_CONSOLE_USERS,
  CONSOLE_CE_ATTENDANCE,
  CONSOLE_CE_SETTINGS,
  CONSOLE_CE_TRACKING,
  MANAGE_CURRENT_ENTRY
} from '../../../constants/routes';
import {
  routeWithClassId,
  hasContinueEducationSettings
} from '../../../services/courses';
import { canManageCourseContent } from '../../../services/currentUser';
import { canAccessTopNavLocationBar } from '../../../permissions/access';
import './style.css';

const Sidebar = () => {
  const pathname = window?.location?.pathname || null;
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const currentUser = useSelector((state) => state.currentUser);

  const isActive = (route, dynamic) => {
    if (!pathname) return null;

    // if (route === CLASSES) { // pathname === SLASH &&
    //   return true;
    // }

    if (dynamic && pathname.includes(route)) {
      return true;
    }

    return pathname === route;
  };

  const linkClassName = (route, dynamic = true) => {
    return `nav-link ${isActive(route, dynamic) ? 'active' : ''}`;
  };

  if (!course.id) {
    return null; // TODO add loader
  }

  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgType = organization?.type || '';
  // Course
  const classId = course?.id || null;
  const isPublic = course?.isPublic || false;
  const inviteOnly = course?.inviteOnly || false;
  const enableCourseContent = course?.enableCourseContent || null;
  // Permissions
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });
  const accessTopNavLocationBar = canAccessTopNavLocationBar(role);

  return (
    <nav
      id="sidebarMenu"
      className={`sidebar col-md-3 col-lg-2 d-md-block bg-white collapse d-print-none ${accessTopNavLocationBar ? 'has-location-bar' : ''}`}
    >
      <div className="sidebar-sticky border-right">
        <div
          style={{
            marginTop: '.7rem'
          }}
        >
          {/* <h6 className='sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted'>
            <span>
              <b>Course</b> Console
            </span>
          </h6> */}

          {manageCourse && hasContinueEducationSettings({ courseCohorts }) && (
            <div className="sidebar-module sidebar-module--assessments rounded bg-light pt-3 pb-1 mx-2 border mb-2">
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center ml-3 mb-1 text-muted">
                <span>Continuing Education</span>
              </h6>

              <ul className="nav flex-column">
                <li className="nav-item text-nowrap">
                  <Link
                    to={routeWithClassId({
                      route: CONSOLE_CE_ATTENDANCE,
                      classId
                    })}
                    className={linkClassName(CONSOLE_CE_ATTENDANCE, false)}
                  >
                    <i className={ICON_ATTENDANCE} /> Attendance
                  </Link>
                </li>

                <li className="nav-item text-nowrap">
                  <Link
                    to={routeWithClassId({
                      route: CONSOLE_CE_TRACKING,
                      classId
                    })}
                    className={linkClassName(CONSOLE_CE_TRACKING, false)}
                  >
                    <span className="d-flex align-items-center">
                      <FaStream />
                      <span className="ml-2">Tracking</span>
                    </span>
                  </Link>
                </li>

                {/* <li className='nav-item'>
                <Link
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_COHORTS,
                    classId
                  })}
                  className={linkClassName(CLASS_CONSOLE_COHORTS)}
                >
                  <i className={COHORT} /> Cohorts
                </Link>
              </li> */}

                <li className="nav-item text-nowrap">
                  <Link
                    to={routeWithClassId({
                      route: CONSOLE_CE_SETTINGS,
                      classId
                    })}
                    className={linkClassName(CONSOLE_CE_SETTINGS, false)}
                  >
                    <i className={ICON_SETTINGS} /> Settings
                  </Link>
                </li>
              </ul>
            </div>
          )}

          <ul className="nav flex-column">
            {enableCourseContent && manageCourse && (
              <li className="nav-item">
                <Link
                  className={linkClassName(MANAGE_CURRENT_ENTRY)}
                  to={{
                    pathname: MANAGE_CURRENT_ENTRY,
                    search: manageEntry({
                      manageType: 'edit',
                      classId,
                      contentType: 'class'
                    })
                  }}
                  title="Course Content"
                >
                  <i className={`${ICON_PENCIL_BOX} mr-1`} /> Content
                </Link>
              </li>
            )}

            {enableCourseContent && manageCourse && (
              <li className="nav-item">
                <Link
                  title="Course Reporting"
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_DASHBOARD,
                    classId
                  })}
                  className={linkClassName(CLASS_CONSOLE_DASHBOARD, true)}
                >
                  <i className={ICON_CHART} /> Reporting
                </Link>
              </li>
            )}

            {manageCourse && (
              <li className="nav-item">
                <Link
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_NOTIFICATIONS,
                    classId
                  })}
                  className={linkClassName(CLASS_CONSOLE_NOTIFICATIONS)}
                >
                  <i className={ICON_BELL} /> Notifications
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link
                to={routeWithClassId({
                  route: CLASS_CONSOLE_USERS,
                  classId
                })}
                className={linkClassName(CLASS_CONSOLE_USERS, true)}
              >
                <i className={ICON_COHORT} /> Users
              </Link>
            </li>

            {enableCourseContent && (
              <li className="nav-item">
                <Link
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_QUIZZES,
                    classId
                  })}
                  className={linkClassName(CLASS_CONSOLE_QUIZZES)}
                >
                  <i className={ICON_QUIZ} /> Quizzes
                </Link>
              </li>
            )}

            {enableCourseContent && (
              <li className="nav-item">
                <Link
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_CHALLENGES,
                    classId
                  })}
                  className={linkClassName(CLASS_CONSOLE_CHALLENGE_BASE)}
                >
                  <i className={ICON_CHALLENGE} /> Challenges
                </Link>
              </li>
            )}

            {manageCourse && (
              <li className="nav-item">
                <Link
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_STREAMING,
                    classId
                  })}
                  className={linkClassName(CLASS_CONSOLE_STREAMING)}
                >
                  <i className="fas fa-chalkboard-teacher" /> Live Stream
                </Link>
              </li>
            )}

            {enableCourseContent && manageCourse && (
              <li className="nav-item">
                <Link
                  className={linkClassName(CLASS_CONSOLE_PACING)}
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_PACING,
                    classId
                  })}
                  title="Pacing"
                >
                  <i className={ICON_USER_LOCK} /> Pacing
                </Link>
              </li>
            )}

            {manageCourse && (
              <li className="nav-item">
                <Link
                  className={linkClassName(CLASS_CONSOLE_BRANDING)}
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_BRANDING,
                    classId
                  })}
                  title="Course Branding"
                >
                  <i className={ICON_BRANDING} /> Branding
                </Link>
              </li>
            )}

            {manageCourse && (
              <li className="nav-item">
                <Link
                  className={linkClassName(CLASS_CONSOLE_PUBLISH)}
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_PUBLISH,
                    classId
                  })}
                  title="Publish Settings"
                >
                  <span className="d-flex align-items-center">
                    <TbWorldSearch />
                    <span className="ml-2">Publish</span>
                    <span className="ml-1 h6 m-0">
                      <span
                        className={`ml-1 badge badge-${isPublic ? 'success' : 'warning'}`}
                      >
                        {isPublic ? 'Public' : 'Private'}
                      </span>
                    </span>
                  </span>
                </Link>
              </li>
            )}

            {manageCourse && (
              <li className="nav-item">
                <Link
                  className={linkClassName(CLASS_CONSOLE_SETTINGS)}
                  to={routeWithClassId({
                    route: CLASS_CONSOLE_SETTINGS,
                    classId
                  })}
                  title="Course Settings"
                >
                  <span className="d-flex align-items-center">
                    <FaCog />
                    <span className="ml-2">Settings</span>
                    {inviteOnly && (
                      <span className="ml-1 h6 m-0">
                        <span className="ml-1 badge badge-warning">
                          Invite Only
                        </span>
                      </span>
                    )}
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
