import React from 'react';
import { FaEllipsisH, FaTrashAlt } from 'react-icons/fa';
import Badge from '../Badge';

const Item = ({ index, number, type, onClickEdit, onClickDelete }) => {
  return (
    <div className="reference d-flex justify-content-between align-items-center border rounded">
      <div className="px-3 d-flex align-items-center">
        <div className="d-block">
          <Badge className="h2 mr-2">{number}</Badge>
        </div>
        <div>
          {number === 1 ? 'Credit' : 'Credits'}
          <br />
          Type: {type ? <b>{type}</b> : 'n/a'}
        </div>
      </div>

      <div className="btn-group align-items-center px-2 py-3">
        <button
          className="btn btn-link btn-sm rounded"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <button
            className="dropdown-item"
            type="button"
            title={`Edit ${number}`}
            onClick={() => {
              onClickEdit(index);
            }}
          >
            Edit
          </button>
          <div className="dropdown-divider" />
          <button
            className="dropdown-item"
            type="button"
            title={`Delete ${number}?`}
            onClick={() => {
              onClickDelete(index);
            }}
          >
            <span className="d-flex align-items-center">
              <FaTrashAlt className="text-danger" />
              <span className="ml-2">Delete</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Item;
