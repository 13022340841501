import React, { useCallback, useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { GiUnicorn } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { fetchAnalytics } from '../../../services/analytics';
import NumberCard from '../../../components/NumberCard';

const Vitals = ({ className = '', size = 'md' }) => {
  const pageLink = useSelector((state) => state.pageLink);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [visits, setVisits] = useState(0);
  const [views, setViews] = useState(0);

  const retrieveUniqueVisits = useCallback(() => {
    if (pageLink?.id) {
      fetchAnalytics({
        orgId,
        refId: pageLink?.id,
        isUnique: true,
        count: true
      }).then((response) => {
        setVisits(response.total || 0);
      });
    }
  }, [orgId, pageLink?.id]);

  const retrieveViews = useCallback(() => {
    if (pageLink?.id) {
      fetchAnalytics({
        orgId,
        refId: pageLink?.id,
        count: true
      }).then((response) => {
        setViews(response.total || 0);
      });
    }
  }, [orgId, pageLink?.id]);

  useEffect(() => {
    retrieveUniqueVisits();
  }, [retrieveUniqueVisits]);

  useEffect(() => {
    retrieveViews();
  }, [retrieveViews]);

  return (
    <div className={className}>
      <div className="row">
        <div
          className="col-sm-6"
          // className="col-sm-4"
        >
          <NumberCard
            className="mb-3"
            size={size}
            num={views}
            text="Views"
            icon={() => <FaEye className="mr-1" />}
          />
        </div>
        <div
          className="col-sm-6"
          // className="col-sm-4"
        >
          <NumberCard
            className="mb-2"
            size={size}
            num={visits}
            text="Unique Views"
            icon={() => <GiUnicorn className="mr-1" />}
          />
        </div>

        {/* <div className="col-sm-4">
          <NumberCard
            className='mb-2'
            size={size}
            num={0}
            text="Clicks"
            icon={() => <FaLink className='mr-1' />}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Vitals;
