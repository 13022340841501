import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updatePageLink } from '../../../services/pageLinks';

const Title = ({ className = '', defaultValue, onSuccessCallback }) => {
  const titleRef = useRef(null);
  // Redux
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  // Local State
  const [title, setTitle] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const toastId = 'errorSavePageLink';

  const errorSaving = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.', {
      autoClose: false,
      toastId
    });
    setTitle(defaultValue || page?.title);
    setLoading(false);
  };

  const onBlurTitle = (e) => {
    const { innerHTML } = e.currentTarget;

    const value = innerHTML || '';

    setTitle(value);

    if (value !== defaultValue) {
      setLoading(true);

      updatePageLink(
        {
          title: value
        },
        pageLink?.id
      )
        .then((response) => {
          if (response?.error) {
            errorSaving(response?.error);
            return;
          }

          if (onSuccessCallback) {
            onSuccessCallback();
          }

          toast.success('Title updated!');
          setLoading(false);
        })
        .catch((error) => {
          errorSaving(error);
        });
    }
  };

  return (
    <h3
      id="pageLinkTitle"
      ref={titleRef}
      className={`text-center position-relative ${className}`}
      contentEditable={!loading}
      onBlur={onBlurTitle}
      disabled={loading}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

export default Title;
