const quizOutcomeState = {
  userName: null, // string
  userId: null, // string
  apprenticeshipId: null, // string
  orgId: null, // string
  locationId: null, // string
  title: null, // string
  difficulty: 0,
  score: 0,
  maxScore: 0,
  attempts: 0,
  questions: null, // (jsonb) [{}, {}, {}]
  userAnswers: null, // (jsonb) [1, 3, 0]
  rubric: null, // (jsonb) [1, 3, 1]
  evaluation: null, // (jsonb) [true, false, false]
  quizId: null, // string
  classId: null, // string
  topicId: null, // string
  prevScores: null, // (jsonb) [{}, {}, {}]
  id: null, // string
  createdAt: null, // timestampz
  updatedAt: null // timestampz
};

export default quizOutcomeState;
