import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICON_QUIZ } from '@apprentage/constants';
import { getQuiz, resetCurrentQuiz } from '../../actions/Quizzes';
import { getQuizOutcomes } from '../../actions/QuizOutcomes';
import { MANAGE_CURRENT_ENTRY } from '../../constants/routes';
import { manageEntry } from '../../services/entry';
import withConsole from '../App/withConsole';
import Form from './Form';
import Loading from '../Loading';
import CdnFiles from '../ManageContent/CdnFiles';
import DeleteQuiz from '../ManageContent/DeleteContent/DeleteQuiz';
import Tabs from './Tabs';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import EntryButton from '../btns/EntryButton';

const Quiz = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const currentTopic = useSelector((state) => state.currentTopic);
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  const entryId = searchParams.get('entryId');
  // Organization
  const orgId = organization?.id || null;
  // Course
  const classId = course?.id || null;
  const topicId = currentTopic?.id || null;
  const courseHasOrgIds =
    Array.isArray(course?.orgIds) && course?.orgIds?.length > 0;

  const initialListConfig = {
    limit: 1000,
    page: 1,
    orgIds: courseHasOrgIds ? [orgId, ...course.orgIds] : [orgId],
    quizId: entryId
  };

  const [listConfig] = useState(initialListConfig);

  useEffect(() => {
    if (entryId) {
      dispatch(getQuiz({ quizId: entryId }));
      dispatch(getQuizOutcomes(listConfig));
    }
  }, [dispatch, entryId, listConfig, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCurrentQuiz());
    };
  }, [dispatch]);

  const orgConsoleHeaderRoute = useMemo(() => {
    const urlParams = manageEntry({
      manageType: 'edit',
      entryId: topicId,
      topicId,
      classId,
      contentType: 'topic'
    });

    return MANAGE_CURRENT_ENTRY + urlParams;
  }, [topicId, classId]);

  if (entryId && !currentQuiz.id) {
    return <Loading text="Loading Quiz..." />;
  }

  return (
    <>
      <OrgConsoleHeader
        pageTitle={currentQuiz.title}
        icon={ICON_QUIZ}
        className="my-3"
        rawRoute={orgConsoleHeaderRoute}
      >
        <>
          {entryId && (
            <EntryButton
              title="Preview"
              classId={classId}
              topicId={topicId}
              entryId={entryId}
            />
          )}
        </>
      </OrgConsoleHeader>

      <Tabs active="settings" />

      <Form className="mb-3" />

      {currentQuiz.id && (
        <DeleteQuiz
          contentId={currentQuiz.id}
          title={currentQuiz.title}
        />
      )}

      {currentQuiz.id && (
        <div className="mb-5">
          <CdnFiles
            id="quiz-integration-files"
            contentId={currentQuiz.id}
            contentType="quiz"
            files={currentQuiz.cdnFiles}
            editMenu
          />
        </div>
      )}
    </>
  );
};

export default withConsole(Quiz);
