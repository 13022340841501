import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'competencies';

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const fetchCompetencies = async ({
  ids = [],
  orgId,
  compId,
  userId,
  apprenticeshipId,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    orgId,
    limit
  };

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (compId !== undefined) {
    params['f.compId[eq]'] = compId;
  }

  if (apprenticeshipId !== undefined) {
    params['f.apprenticeshipId[eq]'] = apprenticeshipId;
  }

  if (userId !== undefined) {
    params['f.userId[eq]'] = userId;
  }

  if (orgId !== undefined) {
    params['f.orgId[eq]'] = orgId;
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchCompetency = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateCompetency = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createCompetency = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteCompetency = (id) => {
  return new Promise((resolve) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
      });
  });
};
