import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import CdnFiles from '../ManageContent/CdnFiles';

const RenderFile = ({
  id,
  contentId,
  contentType,
  value,
  removeFileCallback
}) => {
  const fileObj = value ? JSON.parse(value) : null;

  if (!fileObj) {
    return (
      <div className="border p-2 d-flex align-items-center">
        <FaExclamationTriangle className="text-danger" />
        <span className="ml-1">No file uploaded.</span>
      </div>
    );
  }

  return (
    <CdnFiles
      id={`question-${id}-files`}
      contentId={contentId}
      contentType={contentType}
      files={[fileObj]}
      header={false}
      integration={false}
      removeFileCallback={removeFileCallback}
      editMenu
    />
  );
};

export default RenderFile;
