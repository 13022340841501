import React from 'react';
import NumberStat from '../../../NumberStat';
import shortenString from '../../../../utils/shortenString';

const SimilarityCell = ({ value: similarity }) => {
  const value = shortenString(similarity.toString(), 7, false);

  return (
    <div className="CdnFiles-SimilarityCell">
      <div>
        <NumberStat
          num={value}
          size="xs"
        />
      </div>
    </div>
  );
};

export default SimilarityCell;
