import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { handleOpenUserSideSheet } from '../../../../../actions/SideSheets';
import UsersNoLogbookList from '../../../../lists/UsersNoLogbookList';

const UsersNoLogbook = ({ className = '' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  const onClickUser = (userId) => {
    if (apprenticeshipId) {
      return history.push(`${apprenticeshipRoute}/users/${userId}`);
    }

    dispatch(
      handleOpenUserSideSheet({
        userId,
        orgId,
        currentUserOrgId: currentUser?.orgId
        // route,
        // history
      })
    );
  };

  return (
    <div className={className}>
      <UsersNoLogbookList onClickName={onClickUser} />
    </div>
  );
};

export default UsersNoLogbook;
