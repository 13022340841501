import {
  reactSelectDefaultValue,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import React from 'react';
import { answerEnum } from '../../constants/answers';
import SelectMenu from '../ManageContent/SelectMenu';

const StatusSelect = ({
  id = 'statusSelected',
  name = 'statusSelected',
  onChange,
  defaultValue = '',
  className = ''
}) => {
  const options = reactSelectOptionsFromEnum(answerEnum);
  const defaultVal = reactSelectDefaultValue(defaultValue, options);

  return (
    <SelectMenu
      id={id}
      name={name}
      className={className}
      onChange={onChange}
      defaultValue={defaultVal}
      placeholder="Select status..."
      isClearable
      options={options}
    />
  );
};

export default StatusSelect;
