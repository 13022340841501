import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { ICON_GROUP } from '@apprentage/constants';
import { FaRegObjectGroup } from 'react-icons/fa';
import { resetCourses } from '../../../../actions/Courses';
import { withAuthorization } from '../../../Session';
import { ORG_CONSOLE_DASHBOARD } from '../../../../constants/routes';
import { ICON_COURSES } from '../../../../constants/assets';
import withOrgConsole from '../../../App/withOrgConsole';
import GroupsList from '../../../GroupsList';
import DotMenu from './DotMenu';
import EmptyData from '../../../ManageContent/EmptyData';
import AddGroupButton from '../../../btns/AddGroupButton';
import Tabs from '../Tabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';

const Groups = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courseGroups = useSelector((state) => state.courseGroups);
  // Misc
  const courseGroupIds = organization?.courseGroupIds || null;

  const emptyDataOptions = [
    {
      contentType: 'group',
      subtitle: 'Organize similar Courses.',
      icon: ICON_GROUP
    }
  ];

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCourses());
    };
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Courses"
            image={ICON_COURSES}
            imageSize={25}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <AddGroupButton />
          </OrgConsoleHeader>

          <Tabs active="groups" />

          <div className="bg-white p-3 border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle="Course Groups"
              iconComponent={() => <FaRegObjectGroup className="mr-2" />}
            >
              {courseGroups.list && <DotMenu />}
            </OrgConsoleSubheader>

            <div className="card border-0 mt-3">
              <div className="card-body p-0">
                <GroupsList courseGroupIds={courseGroupIds} />
                {!courseGroups.list && (
                  <EmptyData
                    disabled={false}
                    className="m-3"
                    options={emptyDataOptions}
                    data={courseGroups.list}
                  >
                    <AddGroupButton className="btn btn-md btn-outline-primary">
                      Create
                    </AddGroupButton>
                  </EmptyData>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Groups);
