import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ORG_CONSOLE_SETTINGS, ORG_MANAGE } from '../../constants/routes';
import { routeWithOrgId } from '../../services/organizations';

const Tabs = ({ active, className = 'mt-3' }) => {
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  // misc
  const defaultLocation =
    locations.list && locations.list.find((l) => l.defaultLocation === true);
  const orgId = organization?.id || null;
  const generalSettingsRoute = routeWithOrgId({
    route: ORG_MANAGE,
    urlParams: {
      orgId,
      manageType: 'edit',
      contentType: 'organization'
    }
  });

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item mr-1">
          <Link
            className={`nav-link ${active === 'general' ? 'active' : ''}`}
            to={generalSettingsRoute}
            title="General Settings"
          >
            General
          </Link>
        </li>
        <li className="nav-item mr-1">
          <Link
            className={`nav-link ${active === 'location' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_SETTINGS}/location/${defaultLocation.id}`,
              orgId
            })}
            title="Location Settings"
          >
            Location
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
