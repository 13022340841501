import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import UserSignaturePad from './UserSignaturePad';
import './style.css';

const UserSignature = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Misc
  const modalTitle = currentModal?.data?.modalTitle || 'Signature';
  const textAboveSignature = currentModal?.data?.textAboveSignature || '';
  const textBelowSignature = currentModal?.data?.textBelowSignature || '';

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={() => {
        dispatch(resetCurrentModal());
      }}
      pos="middle"
    >
      <div className="card">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold">{modalTitle}</h5>
        </div>
        <UserSignaturePad
          textAboveSignature={textAboveSignature}
          textBelowSignature={textBelowSignature}
          onChange={(newUserSignature) => {
            if (currentModal?.callbackPrimaryAction) {
              currentModal.callbackPrimaryAction(newUserSignature);
            }

            dispatch(resetCurrentModal());
          }}
        />
      </div>
    </Modal>
  );
};

export default UserSignature;
