import { QUIZ_OUTCOMES_PAGINATION_LIMIT } from '../constants/api';
import {
  serializeQuizOutcome,
  serializeQuizOutcomes
} from '../serializers/quizOutcomes';
import { fetchQuizOutcome, fetchQuizOutcomes } from '../services/quizOutcomes';
import { setCurrentModal } from './Modals';
import {
  RESET_QUIZ_OUTCOME,
  RESET_QUIZ_OUTCOMES,
  SET_QUIZ_OUTCOME,
  SET_QUIZ_OUTCOMES,
  SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID,
  SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID,
  // Content Flow
  SET_CURRENT_CLASS_QUIZ_OUTCOMES,
  SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
  RESET_CURRENT_CLASS_QUIZ_OUTCOMES,
  RESET_CURRENT_TOPIC_QUIZ_OUTCOMES
} from './types';

export const manageQuizOutcomes = (quizId) => {
  return (dispatch) => {
    dispatch(
      setCurrentModal({
        key: 'manageQuizOutcomes',
        data: {
          quizId
        }
      })
    );
  };
};

export const getQuizOutcomes = ({
  orgId,
  orgIds,
  locationId,
  quizId,
  userId,
  classId,
  limit = QUIZ_OUTCOMES_PAGINATION_LIMIT,
  page = 1
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchQuizOutcomes({
        limit,
        page,
        quizId,
        userId,
        classId,
        locationId,
        orgId,
        orgIds
      })
        .then((rsp) => {
          const items = Array.isArray(rsp?.items) ? rsp?.items : [];
          const list = items.length > 0 ? serializeQuizOutcomes(items) : null;

          const data = {
            list,
            pagination: {
              limit,
              total: rsp?.total,
              page: rsp?.page || 1,
              rangeFrom: rsp?.rangeFrom,
              rangeTo: rsp?.rangeTo
            }
          };

          dispatch({
            type: SET_QUIZ_OUTCOMES,
            ...data
          });

          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getQuizOutcome = (quizOutcomeId) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      fetchQuizOutcome(quizOutcomeId).then((response) => {
        const quizOutcome = response ? serializeQuizOutcome(response) : null;

        dispatch({
          type: SET_QUIZ_OUTCOME,
          quizOutcome
        });

        resolve(quizOutcome);
      });
    });
  };
};

export const setQuizOutcomesActiveOrgId = (activeOrgId, activeLocationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID,
      activeOrgId,
      activeLocationId
    });
  };
};

export const setQuizOutcomesActiveLocationId = (activeLocationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID,
      activeLocationId
    });
  };
};

// Content Flow
export const getCurrentClassQuizOutcomes = ({
  orgId,
  classId,
  userId,
  limit = QUIZ_OUTCOMES_PAGINATION_LIMIT
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        classId,
        limit
      };

      if (userId) {
        config.userId = userId;
      }

      fetchQuizOutcomes(config)
        .then((rsp) => {
          const items = Array.isArray(rsp?.items) ? rsp?.items : [];
          const list = items.length > 0 ? items : null;

          dispatch({
            type: SET_CURRENT_CLASS_QUIZ_OUTCOMES,
            list
          });
          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getCurrentTopicQuizOutcomes = ({ orgId, topicId, userId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        topicId
      };

      if (userId) {
        config.userId = userId;
      }

      fetchQuizOutcomes(config)
        .then((rsp) => {
          const items = Array.isArray(rsp?.items) ? rsp?.items : [];
          const list = items.length > 0 ? items : null;

          dispatch({
            type: SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
            list
          });
          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};
// /Content Flow

export const resetCurrentClassQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZ_OUTCOMES });
  };
};

export const resetQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_QUIZ_OUTCOMES });
  };
};

export const resetCurrentTopicQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC_QUIZ_OUTCOMES });
  };
};

export const resetQuizOutcome = () => {
  return (dispatch) => {
    dispatch({ type: RESET_QUIZ_OUTCOME });
  };
};
