import React from 'react';
import { FaThumbtack } from 'react-icons/fa';
import Switch from '../../../Switch';

const NotificationConfig = ({
  notificationConfig,
  setNotificationConfig,
  handleSubmitConfig,
  handleClose
}) => {
  return (
    <>
      <div className="card-body">
        <div className="mb-3">
          <label
            htmlFor="limit"
            className="mb-1 font-weight-bold"
          >
            Limit
          </label>
          <input
            type="number"
            className="form-control"
            name="limit"
            min={1}
            max={10}
            step={1}
            value={notificationConfig.limit}
            onChange={(e) => {
              const { valueAsNumber } = e.currentTarget;
              setNotificationConfig((prevState) => ({
                ...prevState,
                limit: valueAsNumber
              }));
            }}
            style={{
              width: '200px'
            }}
            autoFocus
          />
          <div className="small text-muted mt-1">
            The number of notifications shown.
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex align-items-center mb-1 font-weight-bold">
            <FaThumbtack
              className={
                notificationConfig.showOnlyPinned
                  ? 'text-primary'
                  : 'text-muted'
              }
            />
            <span className="ml-1">Pinned</span>
          </div>
          <Switch
            id="showOnlyPinned"
            label="Only show Pinned"
            value={notificationConfig.showOnlyPinned}
            style={{ width: '200px' }}
            onChange={() => {
              setNotificationConfig((prevState) => ({
                ...prevState,
                showOnlyPinned: !prevState.showOnlyPinned
              }));
            }}
          />
          <div className="small text-muted mt-1">
            Only show Pinned notifications.
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <div>
          <button
            className="btn btn-primary mr-2"
            onClick={handleSubmitConfig}
            title="Save"
            type="button"
          >
            Save
          </button>

          <button
            className="btn btn-sm btn-link"
            onClick={handleClose}
            title="Cancel"
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
export default NotificationConfig;
