import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaPlus } from 'react-icons/fa';
import { canManageApprenticeship } from '../../../../../permissions/manage';
import { setCurrentModal } from '../../../../../actions/Modals';
// import { checkIfDuplicateExists, findClassificationIdsToDelete, updateApprenticeship } from '../../../../../services/apprenticeships';
// import { getApprenticeship } from '../../../../../actions/Apprenticeships';
// import { toast } from 'react-toastify';

const DotMenuCompetencies = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current User
  const role = currentUser?.role || [];
  // Apprenticeship
  // const competencyGroups = apprenticeship?.competencyGroups || [];
  // Permissions
  const manageApprenticeship = canManageApprenticeship(role);

  if (!manageApprenticeship) {
    return null;
  }

  const manageCompetencyGroup = () => {
    dispatch(
      setCurrentModal({
        key: 'manageCompetencyGroup',
        data: {
          modalTitle: 'Add Competency Group'
        }
      })
    );
  };

  // const handleFixData = () => {
  //   if (!apprenticeship.competencies) {
  //     toast.error('No competencies');
  //     return;
  //   }

  //   const compIds = Object.keys(apprenticeship.competencies);
  //   // Construction data to store on apprenticeship
  //   const dataToSave = {
  //     // Create UUID Name Space on Training Program to use when making UUIDs from Competency Strings
  //     competencyGroups: apprenticeship.competencyGroups,
  //     competencies: apprenticeship.competencies,
  //     classifications: apprenticeship.classifications
  //   };

  //   let unusedCompetencyIds = [];
  //   let compGroupIdsHaveDupes = false;

  //   apprenticeship.competencyGroups.forEach((compGroup) => {
  //     if (compGroup.competencyIds && Array.isArray(compGroup.competencyIds) && compGroup.length !== 0) {
  //       if (checkIfDuplicateExists(compGroup.competencyIds)) {
  //         compGroupIdsHaveDupes = true;
  //       }

  //       compGroup.competencyIds.forEach((cId) => {
  //         if (apprenticeship.competencies[cId] === undefined) {
  //           unusedCompetencyIds.push(cId);
  //         }
  //       })
  //     }
  //   });

  //   if (!unusedCompetencyIds.length) {
  //     toast.warning('Data is good :)');
  //     return;
  //   }

  //   const [compId] = unusedCompetencyIds;

  //   // Construct competency data to save to competencyGroups
  //   const competency = apprenticeship.competencies[compId];

  //   if (competency.classificationIds && Object.keys(competency.classificationIds).length) {
  //     const classificationIdsToDelete = findClassificationIdsToDelete({
  //       classificationIds: competency.classificationIds,
  //       competencyId: competency.id,
  //       competencies: apprenticeship.competencies
  //     });

  //     // Delete apprenticeship classifications based on ID
  //     classificationIdsToDelete.forEach((classificationId) => {
  //       delete dataToSave.classifications[classificationId];
  //     });
  //   }

  //   // Remove active competency classifications
  //   // This is safe because we will generate new ones
  //   delete dataToSave.competencies[competency.id];

  //   updateApprenticeship(dataToSave, apprenticeship?.id).then(() => {
  //     dispatch(getApprenticeship(apprenticeship?.id));
  //     toast.success('Data fixed!');
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // };

  // const manageCompetencyGroups = () => {
  //   dispatch(setCurrentModal({
  //     key: 'manageCompetencyGroups',
  //     data: {
  //       modalTitle: 'Competency Groups'
  //     }
  //   }));
  // };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />

          <button
            className="dropdown-item py-2"
            onClick={manageCompetencyGroup}
            type="button"
            title="Add Competency Group"
          >
            <span className="d-flex align-items-center">
              <FaPlus />
              <span className="ml-2">Competency Group</span>
            </span>
          </button>

          {/* <button
            className="dropdown-item py-2"
            onClick={handleFixData}
            type="button"
            title='Inspect Data'
          >
            Inspect Data
          </button> */}

          {/* <button
            className="dropdown-item py-2"
            onClick={manageCompetencyGroups}
            type="button"
            title='Manage Competencies'
            disabled={competencyGroups.length === 0}
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-2'>
                Competency Groups
              </span>
            </span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default DotMenuCompetencies;
