import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogbookRecords } from '../../../actions/LogbookRecords';
import Loading from '../../Loading';
import List from './List';
import './style.css';

const LogbookRecordsList = ({
  className = '',
  listConfig,
  handleRefreshRecords,
  onSelectionChange,
  theme = 'dark'
}) => {
  const dispatch = useDispatch();
  // Redux
  const logbookRecords = useSelector((state) => state.logbookRecords);

  useEffect(() => {
    dispatch(getLogbookRecords(listConfig));
  }, [dispatch, listConfig]);

  if (!logbookRecords.fetched && !logbookRecords.list) {
    return <Loading text="Loading Logbook Records..." />;
  }

  return (
    <div className={`LogbookRecordsList ${className}`}>
      <List
        handleRefreshRecords={handleRefreshRecords}
        onSelectionChange={onSelectionChange}
        data={logbookRecords.list}
        theme={theme}
      />
    </div>
  );
};

export default LogbookRecordsList;
