import React, { useCallback, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { FaCircleArrowLeft } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { FaChartLine, FaCog, FaColumns, FaPaintBrush } from 'react-icons/fa';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import {
  ORG_CONSOLE_PAGE_BASE,
  ORG_CONSOLE_PAGES
} from '../../../../constants/routes';
import './style.css';

const LeftNav = ({ id, collapseNav, setCollapseNav }) => {
  // Redux
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  // Window
  const pathname = window?.location?.pathname || null;
  // Local State

  const isActive = useCallback(
    (route, dynamic) => {
      if (!pathname) return null;

      if (dynamic && pathname.includes(route)) {
        return true;
      }

      return pathname === route;
    },
    [pathname]
  );

  const linkClassName = useCallback(
    (route, dynamic = true) => {
      return `nav-link ${isActive(route, dynamic) ? 'active' : ''}`;
    },
    [isActive]
  );

  const PAGE_ROUTE = `${ORG_CONSOLE_PAGE_BASE}/${page?.id}`;

  const toggleCollapse = () => {
    const newCollapseNav = !collapseNav;
    setCollapseNav(newCollapseNav);
    window.localStorage.setItem('turbine-pages-nav-collapse', newCollapseNav);
  };

  useEffect(() => {
    // https://www.npmjs.com/package/react-tooltip/v/4.5.1#3-tooltip-not-binding-to-dynamic-content
    ReactTooltip.rebuild();
  }, [collapseNav, pageLink?.enablePageContent]);

  return (
    <>
      <nav
        id={id}
        className={`sidebar d-md-block bg-light ${collapseNav ? 'sidebar-collapsed' : 'sidebar-expanded'}`} // col-md-3 col-lg-2 sidebar collapse
      >
        <div className="sidebar-sticky pt-3">
          <div
            className="pt-0 pb-3 px-3 border-bottom d-flex justify-content-center"
            style={{
              height: '55px'
            }}
          >
            {collapseNav ? (
              <Link
                to={ORG_CONSOLE_PAGES}
                className="btn-link text-ships-officer d-flex align-items-center"
                style={{
                  height: '38px',
                  background: 'none',
                  padding: 0
                }}
                // data-tooltip-content="All Pages"
                // data-tooltip-id="PageLeftNav"
                data-tip="All Pages"
                data-for="PageLeftNav"
              >
                <FaCircleArrowLeft size={20} />
              </Link>
            ) : (
              <Link
                to={ORG_CONSOLE_PAGES}
                className="btn btn-white w-100"
              >
                <span className="d-flex align-items-center">
                  <FaCircleArrowLeft />
                  <span className="ml-3">All Pages</span>
                </span>
              </Link>
            )}
          </div>
          <ul className="nav flex-column">
            {pageLink?.enablePageContent ? (
              <li className="nav-item text-nowrap">
                <Link
                  to={`${ORG_CONSOLE_PAGE_BASE}/${page?.id}`}
                  className={`${linkClassName(PAGE_ROUTE, false)} ${pageLink?.enablePageContent ? '' : 'opacity-25'}`}
                  // data-tooltip-content="Content"
                  // data-tooltip-id="PageLeftNav"
                  {...(collapseNav ? { 'data-tip': 'Content' } : {})}
                  {...(collapseNav ? { 'data-for': 'PageLeftNav' } : {})}
                >
                  <span className="d-flex align-items-center">
                    <FaColumns />
                    <span className="ml-3">Content</span>
                  </span>
                </Link>
              </li>
            ) : (
              <div className="nav-item text-nowrap">
                <div
                  className={`nav-link ${pageLink?.enablePageContent ? '' : 'opacity-25'}`}
                  // data-tooltip-content="Content"
                  // data-tooltip-id="PageLeftNav"
                  {...(!pageLink?.enablePageContent
                    ? {
                        'data-tip': `${collapseNav ? '(Disabled) Content' : 'Disabled'}`
                      }
                    : {})}
                  {...(!pageLink?.enablePageContent
                    ? { 'data-for': 'PageLeftNav' }
                    : {})}
                >
                  <span className="d-flex align-items-center">
                    <FaColumns />
                    <span className="ml-3">Content</span>
                  </span>
                </div>
              </div>
            )}

            {pageLink?.enablePageContent ? (
              <li className="nav-item text-nowrap">
                <Link
                  to={`${PAGE_ROUTE}/appearance`}
                  className={linkClassName(`${PAGE_ROUTE}/appearance`, false)}
                  // data-tooltip-content="Appearance"
                  // data-tooltip-id="PageLeftNav"
                  {...(collapseNav ? { 'data-tip': 'Appearance' } : {})}
                  {...(collapseNav ? { 'data-for': 'PageLeftNav' } : {})}
                >
                  <span className="d-flex align-items-center">
                    <FaPaintBrush />
                    <span className="ml-3">Appearance</span>
                  </span>
                </Link>
              </li>
            ) : (
              <div className="nav-item text-nowrap">
                <div
                  className={`nav-link ${pageLink?.enablePageContent ? '' : 'opacity-25'}`}
                  // data-tooltip-content="Content"
                  // data-tooltip-id="PageLeftNav"
                  {...(!pageLink?.enablePageContent
                    ? {
                        'data-tip': `${collapseNav ? '(Disabled) Appearance' : 'Disabled'}`
                      }
                    : {})}
                  {...(!pageLink?.enablePageContent
                    ? { 'data-for': 'PageLeftNav' }
                    : {})}
                >
                  <span className="d-flex align-items-center">
                    <FaPaintBrush />
                    <span className="ml-3">Appearance</span>
                  </span>
                </div>
              </div>
            )}

            <li className="nav-item text-nowrap">
              <Link
                to={`${PAGE_ROUTE}/analytics`}
                className={linkClassName(`${PAGE_ROUTE}/analytics`, false)}
                // data-tooltip-content="Analytics"
                // data-tooltip-id="PageLeftNav"
                {...(collapseNav ? { 'data-tip': 'Analytics' } : {})}
                {...(collapseNav ? { 'data-for': 'PageLeftNav' } : {})}
              >
                <span className="d-flex align-items-center">
                  <FaChartLine />
                  <span className="ml-3">Analytics</span>
                </span>
              </Link>
            </li>

            <li className="nav-item text-nowrap">
              <Link
                to={`${PAGE_ROUTE}/settings`}
                className={linkClassName(`${PAGE_ROUTE}/settings`, false)}
                // data-tooltip-content="Settings"
                // data-tooltip-id="PageLeftNav"
                {...(collapseNav ? { 'data-tip': 'Settings' } : {})}
                {...(collapseNav ? { 'data-for': 'PageLeftNav' } : {})}
              >
                <span className="d-flex align-items-center">
                  <FaCog />
                  <span className="ml-3">Settings</span>
                </span>
              </Link>
            </li>
          </ul>

          <div
            className="nav-item text-nowrap w-100 border-top position-absolute"
            style={{
              bottom: 0
            }}
          >
            <button
              type="button"
              className="nav-link w-100"
              aria-label="Collapse"
              onClick={toggleCollapse}
              style={{
                height: '46px'
              }}
              // data-tooltip-content="Content"
              // data-tooltip-id="PageLeftNav"
              {...(collapseNav ? { 'data-tip': 'Expand' } : {})}
              {...(collapseNav ? { 'data-for': 'PageLeftNav' } : {})}
            >
              {collapseNav ? (
                <span className="d-flex align-items-center">
                  <FiChevronsRight size={20} />
                </span>
              ) : (
                <span className="d-flex align-items-center">
                  <span className="d-flex align-items-center">
                    <FiChevronsLeft size={20} />
                  </span>
                  <span className="ml-3">Collapse</span>
                </span>
              )}
            </button>
          </div>
        </div>
      </nav>

      <ReactTooltip id="PageLeftNav" />
    </>
  );
};

export default LeftNav;
