// LOGOS
export const IMAGE_TURBINE_WHITE =
  'https://turbine-content.s3.us-east-2.amazonaws.com/turbine-white.png';
export const IMAGE_TURBINE_BLACK =
  'https://turbine-content.s3.us-east-2.amazonaws.com/turbine-black.png';
export const IMAGE_TURBINE_RETRO_ICON_WHITE =
  'https://turbine-content.s3.us-east-2.amazonaws.com/turbine-retro-icon-white.png';
export const IMAGE_TURBINE_RETRO_ICON_BLACK =
  'https://turbine-content.s3.us-east-2.amazonaws.com/turbine-retro-icon-black.png';
export const IMAGE_APPRENTAGE_ICON_BLACK =
  'https://turbine-content.s3.us-east-2.amazonaws.com/apprentage-icon-black.png';
export const IMAGE_APPRENTAGE_ICON_WHITE =
  'https://turbine-content.s3.us-east-2.amazonaws.com/apprentage-icon-white.png';
// VELA
export const VELA_HANDOFF_QRCODE_CONTAINER =
  'https://turbine-content.s3.us-east-2.amazonaws.com/mobile-handoff-qrcode-container.jpg';
export const QRCODE_VELA =
  'https://turbine-content.s3.us-east-2.amazonaws.com/QRCode-VELA.jpg';
//
export const IMAGE_TOOLKIT_EMPTY =
  'https://turbine-content.s3.us-east-2.amazonaws.com/toolkit-empty.png';
// APPS ICONS
export const ICON_AGENTS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-agents.png';
export const ICON_COURSES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-courses.png';
export const ICON_INSTITUTIONS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-institutions.png';
export const ICON_LOCATIONS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-locations.png';
export const ICON_PAGES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-pages.png';
export const ICON_PROJECTS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-projects.png';
export const ICON_RESOURCES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-resources.png';
export const ICON_SEARCHES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-searches.png';
export const ICON_SKILLS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-skills.png';
export const ICON_TRAINING_PROGRAMS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-training-programs.png';
export const ICON_USER_DIRECTORY =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-user-directory.png';
// PAGES
export const IMAGE_PAGES_DIAGRAM =
  'https://turbine-content.s3.us-east-2.amazonaws.com/pages-diagram.jpg';
// RESOURCE ICONS
export const ICON_FILE =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-file.png';
export const ICON_TEXT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-text.png';
export const ICON_PDF =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-pdf.png';
export const ICON_CSV =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-excel.png';
export const ICON_WORD =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-word.png';
export const ICON_POWERPOINT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/icon-powerpoint.png';
// MOVE FILES
export const IMAGE_MOVE_FILE_LEFT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--left.jpg';
export const IMAGE_MOVE_FILE_CENTER_ARROWS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--center-arrows.jpg';
export const IMAGE_MOVE_FILE_CENTER_ARROW_LEFT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--center-arrow-left.jpg';
export const IMAGE_MOVE_FILE_CENTER_ARROW_RIGHT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--center-arrow-right.jpg';
export const IMAGE_MOVE_FILE_RIGHT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/move-file--right.jpg';
// TITLE SCREENS
export const IMAGE_RESUME_SCREENER =
  'https://turbine-content.s3.us-east-2.amazonaws.com/resume-screener-no-background.png';
// INTEGRATIONS
export const DUPLICATE_ICON =
  'https://turbine-content.s3.us-east-2.amazonaws.com/duplicate-1x.png';
// CARDS
export const IMAGE_CARD_DEFAULT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-default.png';
export const IMAGE_CARD_EMPLOYERS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-employers.png';
export const IMAGE_CARD_JOB_PROFILES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-job-profile.png';
export const IMAGE_CARD_INSTRUCTOR_PROVIDERS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-instruction-provider.png';
export const IMAGE_CARD_LOGBOOKS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-logbook.png';
export const IMAGE_CARD_PATHWAYS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-pathways.png';
export const IMAGE_CARD_RESOURCES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-resources.png';
export const IMAGE_CARD_TRAINING_PROGRAMS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-training-program.png';

export const IMAGE_CARD_WIDGETS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-widgets.png';
export const IMAGE_CARD_USERS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-users.png';
export const IMAGE_CARD_SKILLS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-skills.png';
export const IMAGE_CARD_PROJECTS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-projects.png';
export const IMAGE_CARD_COURSES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-courses.png';
export const IMAGE_CARD_CHALLENGES =
  'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-challenges.png';
export const IMAGE_CARD_ASSISTANTS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/card-assistants.jpg';
export const IMAGE_CARD_TURBINE_COURSE_GEN =
  'https://turbine-content.s3.us-east-2.amazonaws.com/Card-Turbine-Course-Generator.jpg';

export const IMAGE_KNOWLEDGE_OPS_OVERVIEW =
  'https://turbine-content.s3.us-east-2.amazonaws.com/knowledgeOps-overview.png';
export const IMAGE_VELA_OVERVIEW =
  'https://turbine-content.s3.us-east-2.amazonaws.com/vela-overview.png';

// COURSES
export const IMAGE_LINK_EXTERNAL =
  'https://turbine-content.s3.us-east-2.amazonaws.com/course-external.jpg';
export const IMAGE_COURSES_INTERNAL =
  'https://turbine-content.s3.us-east-2.amazonaws.com/course-internal.jpg';
export const IMAGE_RESOURCES_ALT =
  'https://turbine-content.s3.us-east-2.amazonaws.com/Card-Resource-Alt.jpg';

export const IMAGE_BUILD_OWN_TRAININGS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/training-program-create-build-1x.png';
export const IMAGE_CLONE_SUCCESSFUL_TRAININGS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/training-program-create-clone-1x.png';
export const IMAGE_NO_DOCUMENTS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/document-agreement-sign-icon.png';

// DASHBOARD
export const IMAGE_MODULE_MATERIAL =
  'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-module-material.jpg';
export const IMAGE_MODULE_LINKS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-module-links.jpg';
export const IMAGE_MODULE_MATERIALS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-module-materials.jpg';
export const IMAGE_MODULE_MEDIA =
  'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-module-media.jpg';
export const IMAGE_MODULE_NOTIFICATIONS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-module-notifications.jpg';
export const IMAGE_MODULE_PROJECTS =
  'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-module-project.jpg';
