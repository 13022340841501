import React from 'react';
import { ICON_ROCKET } from '@apprentage/constants';

const NoEmployerProfileNextStep = ({ className = '' }) => {
  return (
    <div className={`card w-100 ${className}`}>
      <div className="card-header font-weight-bold">
        <i className={`${ICON_ROCKET} mr-2`} /> Workforce Information
      </div>
      <div className="card-body">
        <p className="mb-2">
          <strong>Next step:</strong> Add a Point of Contact
        </p>
        <div>
          To generate an Employer Agreement, refer to the Workforce Information
          Checklist below for a list of actions to provide information needed
          for reporting to government agencies.
        </div>
      </div>
    </div>
  );
};

export default NoEmployerProfileNextStep;
