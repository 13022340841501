import { toast } from 'react-toastify';
import { TURBINE_MARKETING_SITE } from '../constants/urls';
import * as twApi from './tw-api';
import {
  API_APPRENTAGE_USERS_AT_RISK,
  API_APPRENTAGE_USERS_NO_LOGBOOKS,
  GMAIL_SEND
} from '../constants/api';
import { fetchSupabaseEntries } from './supabaseProxy';

export const userRolesEnum = ({ orgType, enableWorkforceSuite }) => ({
  student: orgType !== 'workforce' ? 'Trainee' : 'Users',
  teacher: orgType !== 'workforce' ? 'Instructor' : 'Experts',
  admin: 'Admin',
  owner: 'Owner',
  ...(enableWorkforceSuite
    ? {
        programManager: 'Program Manager',
        programTrainer: 'Program Trainer'
      }
    : {})
});

export const instructorTerminology = ({ orgType }) => {
  return orgType !== 'workforce' ? 'Instructor' : 'Expert';
};

export const sendEmail = ({
  subject,
  message,
  replyTo,
  to,
  toName,
  fromName,
  orgName
}) => {
  return new Promise((resolve, reject) => {
    const emailParts = {
      to,
      from: {
        name: orgName
      },
      replyTo,
      subject,
      body: '',
      htmlBody: `
        <h1>
          ${orgName}
        </h1>
        <p>
          Hi ${toName},
        </p>
        <p>
          ${message}
        </p>
        <p>
          Best,
          <br />
          ${fromName}
        </p>
        <br />
        <br />
        <font color='#888888'>--</font>
        <br />
        <p>
          Powered by <a href="${TURBINE_MARKETING_SITE}?track=sendEmail" target="_blank" title="Turbine Workforce">Turbine Workforce</a>
        </p>
      `
    };

    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          toast.error(response.message); // ex: 'Something went wrong, try again'
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 *
 * @param {array} userIds // array of user ids
 * @param {string} searchValue
 * @param {string} orgId // contentful ID
 * @param {number} limit // number of records per page, max is 200
 * @param {string} order // default sort order
 * @returns {Promise} // array of users
 */

export const fetchUsers = async ({
  count,
  select,
  userIds, // TODO change to ids
  supervisorIds,
  title,
  email,
  searchValue,
  orgId,
  limit = 200,
  page = 1,
  order = 'name',
  skillIds,
  isApprentice,
  apprenticeshipId,
  locationId,
  roles,
  excludeRoles,
  hasCertificate,
  excludeTestUsers,
  excludeInactive,
  excludeAcceptedCertificates,
  omitCourse,
  acceptedCertificateCourseId,
  courseId,
  cohortId
}) => {
  const config = {
    limit,
    order,
    page,
    ...(count ? { count } : {}),
    orgId,
    'f.orgId[eq]': orgId
  };

  if (Array.isArray(select)) {
    config.select = select.join(',');
  }

  if (searchValue) {
    config['f.name[ilike]'] = searchValue;
  }

  if (title) {
    config['f.title[ilike]'] = title;
  }

  if (email) {
    config['f.email[ilike]'] = email;
  }

  if (apprenticeshipId) {
    config['f.apprenticeshipIds[ov]'] = apprenticeshipId;
  }

  if (skillIds) {
    config['f.skillIds[ov]'] = skillIds.join(',');
  }

  if (Array.isArray(supervisorIds)) {
    config['f.supervisorIds[ov]'] = supervisorIds.join(',');
  }

  if (Array.isArray(roles) && roles.length !== 0) {
    config['f.role[ov]'] = roles.join(',');
  }

  if (Array.isArray(excludeRoles) && excludeRoles.length !== 0) {
    config['f.role[!ov]'] = excludeRoles.join(',');
  }

  if (Array.isArray(userIds) && userIds.length !== 0) {
    config.ids = userIds.join(',');
  }

  if (locationId) {
    config['f.locationId[eq]'] = locationId;
  }

  if (hasCertificate !== undefined) {
    config['f.uniqueCertificateIds[!is]'] = null;
  }

  if (excludeTestUsers !== undefined) {
    config['f.testUser[is]'] = null;
  }

  if (excludeInactive !== undefined) {
    config['f.membership[!ov]'] = 'inactive';
  }

  if (courseId && !omitCourse) {
    config['f.courseIds[ov]'] = courseId;

    if (excludeAcceptedCertificates && acceptedCertificateCourseId) {
      config['f.acceptedCertificateCourseIds[or]'] = courseId;
    }
  }

  if (cohortId) {
    config['f.cohortIds[ov]'] = cohortId;
  }

  if (isApprentice !== undefined) {
    if (isApprentice === null) {
      config['f.isApprentice[is]'] = null;
    } else if (isApprentice !== '') {
      config['f.isApprentice[eq]'] = isApprentice;
    }
  }

  try {
    const response = await fetchSupabaseEntries(config, 'users');

    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const fetchUsersNoLogbook = async ({
  count,
  select,
  limit,
  orgId,
  apprenticeshipId,
  locationId,
  version = 'v1'
}) => {
  const params = {
    orgId,
    ...(count ? { count } : {}),
    ...(select ? { select } : {}),
    ...(limit ? { limit } : {}),
    ...(locationId ? { locationId } : {}),
    ...(apprenticeshipId ? { apprenticeshipId } : {})
  };

  try {
    const urlParams = new URLSearchParams(params);
    const url = `/${version}${API_APPRENTAGE_USERS_NO_LOGBOOKS}`;
    const response = await twApi.get(`${url}?${urlParams.toString()}`);

    return response;
  } catch {
    console.error('Error fetching users no logbook');
    return new Error('Error fetching users no logbook');
  }
};

export const fetchAtRiskUsers = async ({
  count,
  limit,
  orgId,
  apprenticeshipId,
  locationId,
  version = 'v1'
}) => {
  const params = {
    orgId,
    ...(count ? { count } : {}),
    ...(limit ? { limit } : {}),
    ...(locationId ? { locationId } : {}),
    ...(apprenticeshipId ? { apprenticeshipId } : {})
  };

  try {
    const urlParams = new URLSearchParams(params);
    const url = `/${version}${API_APPRENTAGE_USERS_AT_RISK}`;
    const response = await twApi.get(`${url}?${urlParams.toString()}`);

    return response;
  } catch {
    console.error('Error fetching users no logbook');
    return new Error('Error fetching users no logbook');
  }
};

const userSelectFields = [
  'id',
  'role',
  'name',
  'title',
  'locationId',
  'slackUserId',
  'email',
  'profilePicture',
  'groupIds',
  'phone',
  'phoneExt'
];

export const fetchUsersById = ({
  userIds,
  orgId,
  excludeInactive,
  excludeTestUsers
}) => {
  return new Promise((resolve, reject) => {
    fetchUsers({
      orgId,
      userIds,
      select: userSelectFields,
      excludeTestUsers,
      excludeInactive
    })
      .then((response) => {
        resolve({ users: response?.items });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchUsersByName = ({
  name,
  orgId,
  excludeInactive,
  excludeTestUsers
}) => {
  return new Promise((resolve, reject) => {
    fetchUsers({
      orgId,
      searchValue: name,
      select: userSelectFields,
      excludeTestUsers,
      excludeInactive
    })
      .then((response) => {
        resolve({ users: response?.items });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchUsersByTitle = ({
  title,
  orgId,
  excludeInactive,
  excludeTestUsers
}) => {
  return new Promise((resolve, reject) => {
    fetchUsers({
      orgId,
      title,
      select: userSelectFields,
      excludeTestUsers,
      excludeInactive
    })
      .then((response) => {
        resolve({ users: response?.items });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchUsersByEmail = ({
  email,
  orgId,
  excludeInactive,
  excludeTestUsers
}) => {
  return new Promise((resolve, reject) => {
    fetchUsers({
      orgId,
      email,
      select: userSelectFields,
      excludeTestUsers,
      excludeInactive
    })
      .then((response) => {
        resolve({ users: response?.items });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchUsersBySkillIds = ({
  skillIds,
  orgId,
  excludeInactive,
  excludeTestUsers
}) => {
  return new Promise((resolve, reject) => {
    fetchUsers({
      orgId,
      skillIds,
      select: userSelectFields,
      excludeTestUsers,
      excludeInactive
    })
      .then((response) => {
        resolve({ users: response?.items });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
