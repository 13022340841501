import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { isSuperAdmin, roleReadable } from '@apprentage/utils';
import { FaRegObjectGroup, FaTags } from 'react-icons/fa';
import pluralize from 'pluralize';
import {
  getGroups,
  createUserGroup,
  createCourseGroup,
  getOrgUserGroupIds
} from '../../../actions/Groups';
import {
  createResourceGroup,
  getResourceGroup,
  getResourceGroups
} from '../../../actions/ResourceGroups';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  ORG_CONSOLE_RESOURCES,
  ORG_CONSOLE_RESOURCES_TAGS
} from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import { setCurrentEntry } from '../../../actions/Entry';
import { updateGroup } from '../../../services/groups';
import { canManageResources } from '../../../services/currentUser';
import { updateResourceGroup } from '../../../services/resourceGroups';
import UserRoleDefinitions from '../../UserRoleDefinitions';
import RadioGroup from '../../ManageContent/RadioGroup';
import KeyValueItem from '../../KeyValueItem';
import SelectMenu from '../../ManageContent/SelectMenu';
import Modal from '../../Modal';

const ManageGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  const resourceGroupTags = useSelector((state) => state.resourceGroupTags);
  // Group
  const group = currentModal?.data?.group || {};
  const groupType = group?.type || currentModal?.data?.type;
  // Local State
  const [loading, setLoading] = useState(false);
  const [visibleToAllUsers, setVisibleToAllUsers] = useState(!group?.userRoles);
  // Fields
  const [title, setTitle] = useState(group?.title || '');
  const [userRoles, setUserRoles] = useState(group?.userRoles || []);
  const [groupTags, setGroupTags] = useState(group?.groupTags || []);
  // User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // Misc
  const editMode = currentModal?.data?.editMode;

  const options = [
    {
      value: 'student',
      label: pluralize(roleReadable(['student'], orgType), 2)
    },
    {
      value: 'teacher',
      label: pluralize(roleReadable(['teacher'], orgType), 2)
    },
    { value: 'admin', label: 'Admins' }
    // { value: 'owner', label: 'Owners' }
  ];

  const optionsCopy = options.slice(0);
  let currentUserRoles = null;

  if (userRoles) {
    currentUserRoles = optionsCopy.filter((option) => {
      if (userRoles.includes(option.value)) return option;
      return null;
    });
  }

  // ORG Group Tags
  let tagsOptions = [];
  let currentTags = null;

  if (groupType === 'resource' && resourceGroupTags.list) {
    tagsOptions = resourceGroupTags.list.map((resourceGroupTag) => ({
      value: resourceGroupTag.id,
      label: resourceGroupTag.label
    }));

    const tagsOptionsCopy = tagsOptions.slice(0);

    if (groupTags) {
      currentTags = tagsOptionsCopy.filter((option) => {
        if (groupTags.includes(option.value)) return option;
        return null;
      });
    }
  }

  const handleClose = () => {
    setLoading(false); // TODO move to handleClose
    dispatch(resetCurrentModal());
  };

  const createTags = () => {
    history.push(
      routeWithOrgId({
        route: ORG_CONSOLE_RESOURCES_TAGS,
        orgId
      })
    );
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const searchParams = new URLSearchParams(window.location.search);
    const paramGroupTags = searchParams.get('groupTags');
    const paramGroupIds = searchParams.get('groupIds');
    const dataToSave = {
      title,
      userRoles:
        Array.isArray(userRoles) && userRoles.length !== 0 ? userRoles : null,
      groupTags:
        Array.isArray(groupTags) && groupTags.length !== 0 ? groupTags : null
    };
    const isChild = Boolean(resourceGroup?.id);

    setLoading(true);

    if (editMode) {
      toast.info(`Updating ${title}...`);

      if (groupType === 'resource') {
        updateResourceGroup(dataToSave, group?.id)
          .then(() => {
            // User is editing a topLevel Group
            if (!isChild) {
              dispatch(
                getResourceGroups({
                  orgId,
                  topLevel: true
                })
              ).then(() => {
                toast.success(`${title} Updated!`);

                handleClose();
              });
              return;
            }

            dispatch(
              getResourceGroup({
                groupId: resourceGroup?.id,
                orgId
              })
            ).then(() => {
              toast.success(`${title} Updated!`);

              handleClose();
            });
          })
          .catch(() => {
            toast.error(`${title} Error!`);
          });
        return;
      }

      updateGroup(dataToSave, group?.id).then(() => {
        const config = {
          orgId,
          type: groupType
        };

        if (paramGroupTags) {
          config.groupTags = paramGroupTags.split(',');
        }

        if (paramGroupIds) {
          config.groupIds = paramGroupIds.split(',');
        }

        dispatch(getGroups(config)).then(() => {
          dispatch(setCurrentEntry(group?.id)); // TODO change to setCurrentGroup
          toast.success(`${title} Updated!`);

          handleClose();
        });
      });

      return;
    }

    /**
     *
     * NEW GROUP
     *
     */

    toast.info(`Adding ${title}...`);

    // Resource Group
    if (groupType === 'resource') {
      dispatch(
        createResourceGroup({
          ...dataToSave,
          ...(isChild ? {} : { topLevel: true }),
          orgId
        })
      )
        .then((response) => {
          const newGroupIds = paramGroupIds ? paramGroupIds.split(',') : [];
          const routeConfig = {
            route: ORG_CONSOLE_RESOURCES,
            urlParams: {
              orgId,
              groupIds: [...newGroupIds, response?.id].join(',')
            }
          };

          toast.success(`${title} Created!`);

          // Take user to the newly created resource group
          // In the past, location.assign fixed infinite loader when adding a new folder from Org level
          // window.location.assign(routeWithOrgId(routeConfig));
          history.push(routeWithOrgId(routeConfig));
          handleClose();
        })
        .catch((error) => {
          console.error('createResourceGroup', error);
        });
      return;
    }

    // User Group
    if (groupType === 'user') {
      dispatch(
        createUserGroup({
          ...dataToSave,
          type: groupType,
          orgId
        })
      ).then(() => {
        toast.success(`${title} Created!`);
        dispatch(getGroups({ orgId, type: 'user' }));
        dispatch(getOrgUserGroupIds({ orgId }));
        handleClose();
      });
      return;
    }

    // Course Group
    if (groupType === 'course') {
      dispatch(
        createCourseGroup({
          ...dataToSave,
          type: groupType,
          orgId
        })
      ).then(() => {
        toast.success(`${title} Created!`);
        handleClose();
      });
    }
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      pos="middle"
    >
      <div className="card">
        <form
          id="manage-group"
          name="manage-group"
          onSubmit={handleSubmit}
        >
          <div className="card-header">
            <h1 className="h5 m-0 text-capitalize d-flex align-items-center">
              <FaRegObjectGroup />
              <span className="ml-1">
                {groupType || ''}{' '}
                {groupType === 'resource' ? 'Folder' : 'Group'}
              </span>
            </h1>
          </div>

          <div className="card-body">
            <div
              id="group-name"
              className="mb-4"
            >
              <label htmlFor="title">
                <b>Title:</b> <span className="text-danger">*</span>
              </label>
              <input
                data-cy="ManageGroup-input-title"
                type="text"
                className="form-control"
                name="title"
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setTitle(value);
                }}
                defaultValue={title}
                autoFocus
                required
              />
            </div>

            {groupType === 'resource' && (
              <div
                id="resource-groupTags"
                className="mb-4"
              >
                <label
                  className="mb-0"
                  htmlFor="groupTags"
                >
                  <b>Tags:</b> <span className="text-danger">*</span>
                </label>

                <div className="text-muted mb-2">
                  Organize {groupType === 'resource' ? 'folders' : 'groups'}{' '}
                  with tags to make it easier to find what you need.
                </div>
                {resourceGroupTags.list && tagsOptions ? (
                  <SelectMenu
                    id="groupTags"
                    name="groupTags"
                    options={tagsOptions}
                    isMulti
                    defaultValue={editMode ? currentTags : undefined}
                    onChange={({ groupTags: newGroupTags }) => {
                      if (newGroupTags && newGroupTags.length === 0) {
                        // null converted to undefined when data is formatted before sent to BE
                        // data.groupTags = null;
                        setGroupTags(null);
                      }
                      setGroupTags(newGroupTags);
                    }}
                  />
                ) : (
                  <div className="bg-light border p-3 d-flex">
                    <h6 className="mb-0 mr-2 d-flex align-items-center">
                      <FaTags />
                      <span className="ml-1">No Tags.</span>
                    </h6>
                    <button
                      className="btn-link px-0 h6 mb-0"
                      title="Create a tag"
                      onClick={createTags}
                      type="button"
                    >
                      Create a tag
                    </button>
                  </div>
                )}
              </div>
            )}

            <div
              id="group-user-access"
              className="form-group mb-3"
            >
              <label
                className="mb-1"
                htmlFor="visibleToAllUsers"
              >
                <b>User Access:</b> <span className="text-danger">*</span>
              </label>
              <div className="text-muted medium mb-2">
                <span className="text-capitalize">{groupType || ''}</span>{' '}
                {groupType === 'resource' ? 'Folder' : 'Group'} can be accessed
                by any user.
              </div>
              <RadioGroup
                id="visibleToAllUsers"
                name="visibleToAllUsers"
                value={visibleToAllUsers}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  if (value === 'true') {
                    // null converted to undefined when data is formatted before sent to BE
                    setUserRoles(null); // newState.userRoles = null;
                  } else {
                    // When a user toggles "User Access" to "no"
                    // set all available roles to keep data consistent
                    // with what is presented to the user in the multi-select field
                    const optionValues = options.map((option) => option.value);
                    setUserRoles(optionValues);
                  }

                  setVisibleToAllUsers(value === 'true');
                }}
                disabled={!canManageResources(role)}
              />
            </div>

            {!visibleToAllUsers && (
              <div
                id="group-user-roles"
                className="mb-4"
                style={{
                  borderLeft: '5px #dee2e6 solid',
                  paddingLeft: '1rem'
                }}
              >
                <label
                  className="mb-0"
                  htmlFor="userRoles"
                >
                  <b>User Roles:</b> <span className="text-danger">*</span>
                </label>

                <div className="text-muted mb-2">
                  Choose all user roles that can view this group.
                </div>
                <SelectMenu
                  id="userRoles"
                  name="userRoles"
                  options={options}
                  isMulti
                  defaultValue={editMode ? currentUserRoles : options}
                  onChange={({ userRoles: userRolesNew }) => {
                    if (userRolesNew && userRolesNew.length === 0) {
                      // null converted to undefined when data is formatted before sent to BE
                      // data.userRoles = null;
                      setUserRoles(null);
                    }
                    setUserRoles(userRolesNew);
                  }}
                />
                <UserRoleDefinitions />
              </div>
            )}
          </div>

          <div className="card-footer d-flex align-items-center justify-content-between">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            {isSuperAdmin(role) && group?.id && (
              <KeyValueItem
                title="ID"
                value={group?.id}
              />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageGroup;
