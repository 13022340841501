import React, { useMemo } from 'react';
import Table from './Table';
import TitleCell from './TitleCell';
import SelectBtnCell from './SelectBtnCell';
import TableLoading from '../../../../ManageContent/Tables/TableLoading';

const List = ({ data, handleOnClickRow, isSingleSelect }) => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        style: { width: '45px' },
        accessor: 'selectBtnCol',
        onSelect: handleOnClickRow,
        isSingleSelect,
        disableSortBy: true,
        Cell: SelectBtnCell
      },
      {
        Header: 'Title',
        accessor: 'title',
        Cell: TitleCell
      }
    ],
    [handleOnClickRow, isSingleSelect]
  );

  if (Array.isArray(data) && data.length > 0) {
    return (
      <Table
        columns={columns}
        data={data}
      />
    );
  }

  return <TableLoading />;
};

export default List;
