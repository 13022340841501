import React, { Fragment, useMemo, useState } from 'react';
import { ICON_QUIZ } from '@apprentage/constants';
import { useSelector } from 'react-redux';
import Outcome from './Outcome';

const OutcomesHeader = ({ className = '' }) => (
  <thead className={`${className} border-0`}>
    <tr>
      <th scope="col">Submitted</th>
      <th scope="col">Score</th>
      <th scope="col">Percentage</th>
      <th scope="col">Attempts</th>
      <th scope="col">Points</th>
      <th scope="col">Feedback</th>
    </tr>
  </thead>
);

const Outcomes = ({ className = '', showHeader = false }) => {
  // Redux
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Local State
  const [showPrevScores, setShowPrevScores] = useState(false);

  const hasQuizOutcomes =
    Array.isArray(quizOutcomes.list) && quizOutcomes.list.length > 0;

  const hasPrevScores = useMemo(() => {
    let prevScores = [];

    if (hasQuizOutcomes) {
      prevScores = quizOutcomes.list[0].prevScores;
    }

    return Array.isArray(prevScores) && prevScores.length > 0;
  }, [hasQuizOutcomes, quizOutcomes.list]);

  if (!hasQuizOutcomes) {
    return null;
  }

  return (
    <div
      className={`CurrentQuizOutcomes-container border-right border-left border-bottom ${showHeader ? 'border-top' : ''} ${className}`}
    >
      {showHeader && (
        <div className="card-header bg-light p-3 border-0">
          <h5 className="m-0">
            <i className={ICON_QUIZ} /> Outcomes
          </h5>
        </div>
      )}

      <div className="card-body p-0">
        <table className="table table-striped m-0">
          <OutcomesHeader />
          <tbody>
            {quizOutcomes.list.map((outcome, i) => {
              return (
                <Fragment key={i}>
                  <Outcome
                    key={`${outcome.id}-${i}`}
                    data={outcome}
                  />
                  {outcome.prevScores &&
                    outcome.prevScores.map((prevOutcome, prevOutcomeIndex) => (
                      <Outcome
                        key={`${outcome.id}-prevOutcome-${prevOutcomeIndex}`}
                        data={prevOutcome}
                        className={showPrevScores ? '' : 'd-none'}
                        prevOutcome
                      />
                    ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {hasPrevScores && (
        <div className="card-footer d-flex justify-content-center">
          <button
            className="btn-link p-0"
            type="button"
            onClick={() => {
              setShowPrevScores(!showPrevScores);
            }}
          >
            {showPrevScores ? 'Hide' : 'View'} Previous Scores
          </button>
        </div>
      )}
    </div>
  );
};

export default Outcomes;
