import React, { useCallback, useEffect, useState } from 'react';
import { percentComplete } from '@apprentage/utils';
import ProgressBars from '../../ProgressBars';

const FakeLoader = ({
  loading,
  interval = 250,
  size = 'sm',
  defaultValueNow = 0,
  valueMax = 100
}) => {
  const [valueNow, setValueNow] = useState(defaultValueNow);

  const percentage = percentComplete({
    current: valueNow,
    total: valueMax
  });

  const fakeLoader = useCallback(
    (val = 0) => {
      if (loading) {
        const newValue = val + 1;
        setValueNow(newValue);

        if (loading && newValue <= valueMax - 1) {
          setTimeout(fakeLoader, interval, newValue);
        }
      }
    },
    [loading, valueMax, interval]
  );

  useEffect(() => {
    fakeLoader();
  }, [fakeLoader]);

  return (
    <ProgressBars
      size={size}
      className="w-100 opacity-50 position-absolute"
      style={{
        top: 0,
        left: 0
      }}
      data={[
        {
          className: 'progress-bar-striped progress-bar-animated bg-info',
          valueNow,
          valueMax,
          valueMin: 0,
          style: { width: percentage },
          text: ''
          // text: `${valueNow} / ${valueMax}`
        }
      ]}
    />
  );
};

export default FakeLoader;
