import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

const OrgLocationCell = ({ value: locationId }) => {
  const locations = useSelector((state) => state.locations);

  if (!locations.hash) {
    return null;
  }

  const locationName = pathOr(null, ['name'], locations.hash[locationId]);

  return (
    <div
      className="overflow-hidden text-nowrap"
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '250px'
      }}
    >
      {locationName}
    </div>
  );
};

export default OrgLocationCell;
