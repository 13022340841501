import React from 'react';

const Tabs = ({
  className = '',
  numFolders = 0,
  showUsageTab,
  activeTab,
  setActiveTab,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'settings' && 'active'}`}
            type="button"
            disabled={activeTab === 'settings'}
            onClick={() => {
              setActiveTab('settings');
            }}
          >
            Settings
          </button>
        </li>

        {showUsageTab && (
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'usage' && 'active'}`}
              type="button"
              disabled={activeTab === 'usage'}
              onClick={() => {
                setActiveTab('usage');
              }}
            >
              <span className="d-flex align-items-center">
                <span className="mr-2">Usage</span>
                <span className="badge bg-white border">
                  <span>{numFolders}</span>
                </span>
              </span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Tabs;
