import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SLASH,
  syndicatedCourseAccessibleRoutes
} from '../../constants/routes';
import { PERM_ROLE_TEACHER_ADMIN } from '../../constants/errors';
import { goToElem } from '../../services/manageContent';
import { setCurrentClass, resetCurrentClass } from '../../actions/Class';
import { setCurrentTopic, resetCurrentTopic } from '../../actions/Topic';
import { resetCohorts, resetCurrentCohort } from '../../actions/Cohorts';
import { resetUsers } from '../../actions/Users';
import {
  userOrgIsCourseOrg,
  isStudent,
  userOrgInCourseOrgIds
} from '../../services/currentUser';
import { redirectToAuth } from '../../services/auth';
import Sidebar from '../Console/Sidebar';
import Loading from '../Loading';
import CourseHeader from '../Console/CourseHeader';
import isPublished from '../../utils/isPublished';

const withConsole = (Component) => {
  const WithConsole = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // Redux
    const currentUser = useSelector((state) => state.currentUser);
    const organization = useSelector((state) => state.organization);
    const course = useSelector((state) => state.course);
    const currentTopic = useSelector((state) => state.currentTopic);
    // Search Params
    const searchParams = new URLSearchParams(window.location.search);
    const classId = searchParams.get('classId');
    const topicId = searchParams.get('topicId');
    const scrollToId = searchParams.get('scrollToId');
    // Organization
    const orgSlug = organization?.slug || '';
    // Current User
    const role = useMemo(() => {
      return currentUser?.role || [];
    }, [currentUser?.role]);

    useEffect(() => {
      // Prevent Workforce employees from accessing
      if (isStudent(role)) {
        redirectToAuth({
          organizationSlug: orgSlug,
          error: {
            code: PERM_ROLE_TEACHER_ADMIN
          }
        });

        return null;
      }
    }, [orgSlug, role]);

    useEffect(() => {
      // Deep link into section of Form
      // URL param ex: scrollToId=topic-instructionNotes
      if (scrollToId) {
        setTimeout(() => {
          goToElem(scrollToId);
        }, 500);
      }
    }, [scrollToId]);

    useEffect(() => {
      if (classId) {
        dispatch(setCurrentClass({ classId }));
      }
    }, [classId, dispatch]);

    useEffect(() => {
      if (topicId) {
        dispatch(setCurrentTopic({ topicId }));
      }
    }, [dispatch, topicId]);

    useEffect(() => {
      return function cleanup() {
        dispatch(resetUsers()); // TODO is this needed?
        dispatch(resetCurrentClass());
        dispatch(resetCurrentCohort());
        dispatch(resetCohorts());
        dispatch(resetCurrentTopic());
      };
    }, [dispatch]);

    // Waiting for Current User and Organization to load
    if (!currentUser?.id || !organization?.id) {
      return <Loading className="position-fixed" />;
    }

    // Waiting for Course to load
    if (classId && !course?.id) {
      return null;
    }

    // Course loaded, but is unpublished
    if (course?.id && !isPublished(course)) {
      history.replace(SLASH);
      return null;
    }

    // Waiting for Topic to load
    if (topicId && !currentTopic?.id) {
      return null;
    }

    // Admin accessing class outside of their organization, send to '/'
    if (!userOrgIsCourseOrg({ course, currentUser })) {
      // Course is syndicated, allow admin to access certain features
      if (
        !syndicatedCourseAccessibleRoutes.includes(window.location.pathname)
      ) {
        history.replace(SLASH);

        return null;
      }
      // Course is syndicated, allow admin to access certain features
      if (!userOrgInCourseOrgIds({ course, currentUser })) {
        history.replace(SLASH);

        return null;
      }
    }

    return (
      <>
        <div className="h-100">
          <Sidebar />
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-10"
          >
            <CourseHeader showPreviewBtn />
            <Component />
            {/* <Footer /> */}
          </main>
        </div>
      </>
    );
  };

  return WithConsole;
};

export default withConsole;
