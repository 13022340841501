import { fetchDashboard } from '../services/dashboards';
import { SET_ENTRY_ORG_ID, SET_DASHBOARD, RESET_DASHBOARD } from './types';

export const getDashboard = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchDashboard(id)
        .then((dashboard) => {
          if (!dashboard?.id) {
            reject(new Error('No Dashboard found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: dashboard?.orgId || null
          });

          dispatch({
            type: SET_DASHBOARD,
            dashboard: dashboard || null
          });
          resolve(dashboard);
        })
        .catch((error) => {
          console.error('get dashboard', error);
          reject(error);
        });
    });
  };
};

export const resetDashboard = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DASHBOARD });
  };
};
