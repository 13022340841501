import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { withAuthorization } from '../Session';
import { routeWithOrgId } from '../../services/organizations';
import withOrgConsole from '../App/withOrgConsole';
import {
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_LOCATIONS,
  ORG_CONSOLE_LOCATION_BASE,
  ORG_CONSOLE_WORKFORCE,
  SLASH
} from '../../constants/routes';
import {
  canAccessOrgLocations,
  canCreateLocation
} from '../../services/currentUser';
import { locationTerminology } from '../../services/locations';
import { resetLocation } from '../../actions/Locations';
import { ICON_LOCATIONS } from '../../constants/assets';
import LocationsList from '../LocationsList';
import OrgConsoleHeader from './OrgConsoleHeader';
import EmployersList from '../EmployersList';

const Locations = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  // misc
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  const role = currentUser?.role || [];
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const locationTerm = locationTerminology({ orgType });

  useEffect(() => {
    dispatch(resetLocation());
  }, [dispatch]);

  if (!canAccessOrgLocations(role, orgType)) {
    history.replace(SLASH);
  }

  const handleClickRow = (orgLocation) => {
    const route = routeWithOrgId({
      route: `${ORG_CONSOLE_LOCATION_BASE}/${orgLocation.id}`,
      orgId
    });

    history.push(route);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={`${locationTerm.text}s`}
            image={ICON_LOCATIONS}
            route={
              enableWorkforceSuite
                ? ORG_CONSOLE_WORKFORCE
                : ORG_CONSOLE_DASHBOARD
            }
            badge={() => (
              <span className="ml-2 badge bg-white border">
                {Array.isArray(locations.list) ? locations.list.length : 0}
              </span>
            )}
          >
            {canCreateLocation(role) && (
              <Link
                className="btn btn-sm btn-primary"
                to={routeWithOrgId({
                  route: `${ORG_CONSOLE_LOCATIONS}/manage`,
                  orgId
                })}
              >
                <span className="d-flex align-items-center">
                  <FaPlus />
                  <span className="ml-1">New</span>
                </span>
              </Link>
            )}
          </OrgConsoleHeader>

          {enableWorkforceSuite && orgType === 'community' ? (
            <div className="mt-3 bg-white shadow rounded">
              <div className="p-3">
                <EmployersList
                  handleClickRow={handleClickRow}
                  hiddenColumns={[
                    'employerProfileId',
                    'createdAt',
                    'updatedAt'
                  ]}
                />
              </div>
              <div className="card-footer text-center border-left border-right border-bottom">
                <span>
                  Users will be auto-assigned an Employer when registering.
                </span>
              </div>
            </div>
          ) : (
            <>
              <LocationsList
                className="mt-3"
                excludeDefaultLocation
                showHeader
              />
              {/* <div className="card-footer text-center border-left border-right border-bottom">
                <span>
                  Users will choose or auto-assigned a Location when registering.
                </span>
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Locations);
