import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { fetchUserProfile } from '../../../services/userProfiles';

const WageScheduleCell = ({ row }) => {
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  //
  const userProfileId = row?.original?.userProfileId || null;
  const [hasWageSchedule, setHasWageSchedule] = useState(false);

  useEffect(() => {
    if (userProfileId) {
      fetchUserProfile(userProfileId).then((userProfile) => {
        if (userProfile?.wageSchedules) {
          const wageSchedulesArr = Object.keys(userProfile.wageSchedules);

          if (
            wageSchedulesArr.length &&
            userProfile.wageSchedules[apprenticeshipId] !== undefined
          ) {
            setHasWageSchedule(true);
          }
        }
      });
    }
  }, [apprenticeshipId, userProfileId]);

  if (hasWageSchedule) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <FaCheckCircle className="text-keppel mr-1" />
        <span>Completed</span>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center text-nowrap">
      <FaExclamationTriangle className="text-danger mr-1" />
      <span>Incomplete</span>
    </div>
  );
};

export default WageScheduleCell;
