import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canAccessCourses } from '../../../services/currentUser';
import { setCurrentModal } from '../../../actions/Modals';
import {
  IMAGE_CARD_TURBINE_COURSE_GEN,
  IMAGE_COURSES_INTERNAL
} from '../../../constants/assets';
import { MODAL_KEY_CREATE_COURSE } from '../../../constants/modals';

const DashboardCourses = ({
  title = 'Knowledge Containers',
  className = ''
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];

  return (
    <div className={`DashboardCourses ${className}`}>
      <div className="row pb-2">
        <div className="col">
          <div className="h3 m-0 font-weight-bold">{title}</div>
        </div>
      </div>

      <div className="mt-3 row">
        {canAccessCourses(role) && (
          <div className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="DashboardCard card shadow border rounded overflow-hidden w-100 h-100">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  Turbine Course Writer
                </div>
              </div>
              <div className="card-body border-left border-right d-flex justify-content-center align-items-center">
                <img
                  src={IMAGE_CARD_TURBINE_COURSE_GEN}
                  alt="Turbine Course"
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                <div
                  data-tip="Coming Soon"
                  data-for="OrgDashboard"
                >
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => {
                      // setEnableCourseContent(true);
                    }}
                    disabled
                    type="button"
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {canAccessCourses(role) && (
          <div className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="DashboardCard card shadow border rounded overflow-hidden w-100 h-100">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  Link External Course
                </div>
              </div>
              <div
                className="card-body border-left border-right"
                style={{ backgroundColor: '#f3f3f3' }}
              >
                <img
                  src="https://turbine-content.s3.us-east-2.amazonaws.com/course-external.jpg"
                  alt="External Course"
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    dispatch(
                      setCurrentModal({
                        key: MODAL_KEY_CREATE_COURSE,
                        data: {
                          enableCourseContent: false
                        }
                      })
                    );
                  }}
                  type="button"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        )}

        {canAccessCourses(role) && (
          <div className="col-12 col-sm-6 col-md-4 mb-4">
            <div className="DashboardCard card shadow border rounded overflow-hidden w-100 h-100">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  Custom Course
                </div>
              </div>
              <div className="card-body border-left border-right">
                <img
                  src={IMAGE_COURSES_INTERNAL}
                  alt="Turbine Course"
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    // setEnableCourseContent(true);
                    dispatch(
                      setCurrentModal({
                        key: MODAL_KEY_CREATE_COURSE,
                        data: {
                          enableCourseContent: true,
                          currentStep: 1
                        }
                      })
                    );
                  }}
                  type="button"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCourses;
