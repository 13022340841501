import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchCronUsers } from '../services/cronUsers';
import { SET_ENTRY_ORG_ID, SET_CRON_USERS, RESET_CRON_USERS } from './types';

export const getCronUsers = ({
  orgId,
  apprenticeshipId,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchCronUsers({
        orgId,
        apprenticeshipId,
        page,
        limit
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_CRON_USERS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetCronUsers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CRON_USERS });
  };
};
