import React from 'react';

const Layout = ({ layout }) => {
  return (
    <div className="row g-2 p-3">
      {layout.map((size, index) => (
        <div className={`col-${size} border border-1 rounded p-5`}>
          <h6 className="text-center">{index + 1}</h6>
        </div>
      ))}
    </div>
  );
};

export default Layout;
