import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_PATHWAYS } from '../../../constants/routes';

const NavTabs = ({ activeTab = 'pathway', className = 'mt-3' }) => {
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const pathwayId = pathOr(null, ['pathwayId'], params);
  const jobProfileId = pathOr(null, ['jobProfileId'], pathway);
  const isPublic = pathOr(false, ['isPublic'], pathway);

  return (
    <div className={className}>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === 'pathway' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_PATHWAYS}/${pathwayId}`,
              orgId
            })}
            title="All Pathways"
          >
            Pathway
          </Link>
        </li>

        {jobProfileId && (
          <li className="nav-item">
            <Link
              className={`nav-link ml-2 ${activeTab === 'jobProfile' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${ORG_CONSOLE_PATHWAYS}/${pathwayId}/job-profile/${jobProfileId}`,
                orgId
              })}
              title="Job Profile"
            >
              Job Profile
            </Link>
          </li>
        )}

        {jobProfileId && (
          <li className="nav-item">
            <Link
              className={`nav-link ml-2 ${activeTab === 'certificates' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${ORG_CONSOLE_PATHWAYS}/${pathwayId}/certificates`,
                orgId
              })}
              title="Certificates"
            >
              Certificates
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link
            className={`nav-link ml-2 d-flex align-items-center ${activeTab === 'publish' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_PATHWAYS}/${pathwayId}/publish`,
              orgId
            })}
            title="Publish Settings"
          >
            <span>Publish</span>
            <span className="ml-1 h6 m-0">
              <span
                className={`ml-1 badge badge-${isPublic ? 'success' : 'warning'}`}
              >
                {isPublic ? 'Public' : 'Private'}
              </span>
            </span>
          </Link>
        </li>

        {/* <li className="nav-item">
          <Link
            className={`nav-link ml-2 ${activeTab === 'courses' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: ORG_CONSOLE_JOB_PROFILES,
              orgId
            })}
            title="Courses"
          >
            Courses
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default NavTabs;
