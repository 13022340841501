import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { withAuthorization } from '../../../components/Session';
import LeftNav from '../components/LeftNav';
import TopNav from '../components/TopNav';
import withOrgConsole from '../../../components/App/withOrgConsole';
import ContextQuestionsList from './ContextQuestionsList';
import { getContextQuestions } from '../../../actions/ContextQuestions';
import LocationSelect from '../../../components/ManageContent/LocationSelect';
import Tabs from './Tabs';
import { fetchInputCounts } from '../../../services/contextInputs';
import { fetchUnansweredQuestions } from '../../../services/contextQuestions';
import { setSideSheet } from '../../../actions/SideSheets';
import { SHEET_KEY_MANAGE_CONTEXT_QUESTION } from '../../../constants/sideSheets';

const ContextDashboard = () => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const dictionary = useSelector((state) => state.dictionary);
  const locations = useSelector((state) => state.locations);
  const contextQuestions = useSelector((state) => state.contextQuestions);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  // Local State
  const turbinePagesNavCollapse = window?.localStorage?.getItem(
    'turbine-pages-nav-collapse'
  );
  const [collapseNav, setCollapseNav] = useState(
    turbinePagesNavCollapse || false
  );
  const [activeTab, setActiveTab] = useState('system');
  const [inputCounts, setInputCounts] = useState(null);

  // Pagination
  const initialListConfig = {
    orgId: null,
    page: 1,
    limit: 1000
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  const onClickCreateNewQuestion = () => {
    dispatch(
      setSideSheet({
        key: SHEET_KEY_MANAGE_CONTEXT_QUESTION,
        data: {
          editMode: true
        }
      })
    );
  };

  useEffect(() => {
    if (listConfig) {
      dispatch(getContextQuestions(listConfig));
    }
  }, [dispatch, listConfig]);

  useEffect(() => {
    if (orgId) {
      fetchInputCounts({ orgId }).then((inputs) => {
        console.log('inputs', inputs);
        setInputCounts(inputs?.inputCounts || null);
      });
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId && userId) {
      fetchUnansweredQuestions({ orgId, userId }).then((questions) => {
        console.log('unanswered questions', questions);
      });
    }
  }, [orgId, userId]);

  return (
    <div
      id="body-row"
      className="row"
    >
      <LeftNav
        id="sidebar-container"
        collapseNav={collapseNav}
        setCollapseNav={setCollapseNav}
      />

      <div
        role="main"
        className="col p-3"
        style={{
          marginLeft: collapseNav ? '50px' : '230px'
        }}
      >
        <TopNav title="Dashboard" />

        <Tabs
          cardStyle
          className="pt-3"
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
          setListConfig={setListConfig}
          // setLocationIds={setLocationIds}
        />

        <div className="container-fluid mb-4 bg-white border-left border-right border-bottom pt-3">
          <div className="row">
            <div className="col">
              {activeTab === 'system' && (
                <ContextQuestionsList list={contextQuestions?.list} />
              )}

              {activeTab === 'org' && (
                <div>
                  {/* <div className="p-3">
                    <SearchBox
                      className="w-100"
                      onSubmit={(value) => {
                        if (setDataConfig) {
                          setDataConfig({
                            page: 1,
                            limit: MODULES_PAGINATION_LIMIT,
                            searchValue: value
                          });
                        }
                      }}
                      onClear={() => {
                        const searchBox = document.querySelector('input[name="moduleDataSearch"]');
                        searchBox.focus();
                      }}
                      name="moduleDataSearch"
                      value=""
                      placeholder="Search by title..."
                      autoFocus
                    />
                  </div> */}
                  <div className="py-4 list-group-item">
                    <div className="mb-2 font-weight-bold d-flex align-items-center">
                      <i className={dictionary?.locationIcon} />
                      <span className="ml-2">{dictionary?.locations}</span>
                    </div>
                    {/* When locations include multiple orgs, follow syndicatedCourseOrgs aggregated patterns */}
                    {locations.list &&
                      Array.isArray(locations.list) &&
                      locations.list.length !== 0 && (
                        <>
                          <LocationSelect
                            id="selectedLocationIds"
                            name="selectedLocationIds"
                            className="mr-0 mr-sm-2 flex-fill"
                            defaultValue={listConfig.locationIds || []}
                            data={locations?.list}
                            isMulti
                            onChange={({ selectedLocationIds }) => {
                              const newValues =
                                selectedLocationIds.length === 0
                                  ? null
                                  : selectedLocationIds;

                              // setLocationIds(newValues);

                              setListConfig((prev) => ({
                                ...prev,
                                page: 1,
                                orgId,
                                locationIds: newValues
                              }));
                              // setLocationIds(selectedLocationIds);

                              // if (selectedLocationId === null) {
                              //   dispatch(setQuizOutcomesActiveOrgId(activeOrgId, selectedLocationId));
                              // } else {
                              //   dispatch(setQuizOutcomesActiveLocationId(selectedLocationId));
                              // }
                            }}
                          />
                        </>
                      )}
                  </div>
                  <ContextQuestionsList
                    inputCounts={inputCounts}
                    list={contextQuestions?.list}
                  />

                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={onClickCreateNewQuestion}
                  >
                    <span className="d-flex align-items-center">
                      <FaPlus />
                      <span>New</span>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className={`d-none col-12 ${collapseNav ? 'col-lg-4 d-lg-block' : 'col-xl-4 d-xl-block'} `}>
            <h6>Insights</h6>
            <p>
              Something
            </p>
          </div> */}
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  ContextDashboard,
  true
);
