import { toast } from 'react-toastify';
import {
  USER_DISABILITY_ENUM,
  USER_EDUCATION_ENUM,
  USER_EMPLOYMENT_ENUM,
  USER_ETHNICITY_ENUM,
  USER_GENDER_ENUM,
  USER_RACE,
  USER_VETERAN_ENUM
  // USER_REFERRAL_ENUM,
  // USER_CITIZEN_ENUM
} from '@apprentage/constants';
import { fetchUserProfiles } from '../../../../../../services/userProfiles';
import { fetchWageSchedules } from '../../../../../../services/employers';
import sortArrByKeys from '../../../../../../utils/sortArrByKeys';
import { mdySimple } from '../../../../../../utils/date';
import { decodePayload } from '../../../../../../utils/payload';

const decryptData = async (userProfiles) => {
  if (
    !userProfiles ||
    (Array.isArray(userProfiles) && userProfiles.length === 0)
  ) {
    return;
  }

  const promises = userProfiles.map(async (userProfile) => {
    if (!userProfile?.ssn) {
      return userProfile;
    }

    const ssn = await decodePayload(userProfile.ssn);

    return {
      ...userProfile,
      ssn
    };
  });

  const results = await Promise.all(promises);
  return results;
};

const serializeData = async ({
  usersList,
  userProfiles,
  wageSchedules,
  apprenticeshipId
}) => {
  const decryptedData = await decryptData(userProfiles);

  const serializedUsers = usersList.reduce((users, user) => {
    const userProfile = decryptedData.find(
      (up) => up?.id === user.userProfileId
    );
    let wageSchedule = {};
    let updatedUser = {};

    if (!userProfile) {
      users.push(user);
      return users;
    }

    if (
      userProfile.wageSchedules &&
      userProfile.wageSchedules[apprenticeshipId]
    ) {
      wageSchedule = wageSchedules.find(
        (ws) => ws.id === userProfile.wageSchedules[apprenticeshipId]
      );
    }

    const {
      // prevent overriding duplicate keys in the user
      id,
      createdAt,
      updatedAt,
      ...remainingUserProfileData
    } = userProfile;

    updatedUser = {
      ...user,
      ...remainingUserProfileData
    };

    if (wageSchedule?.id) {
      updatedUser.entryWages = '';
      if (wageSchedule?.wageRates && wageSchedule?.wageRates[0]) {
        updatedUser.entryWages = `$${wageSchedule?.wageRates[0].wageAmount}`;
      }

      updatedUser.entryWagesUnits = 'USD'; // TODO make dynamic
      updatedUser.wageScheduleTitle = wageSchedule?.title;
    }

    users.push(updatedUser);

    return users;
  }, []);

  return serializedUsers;
};

const fullStreetAddress = (user) => {
  let streetAddress = user?.addressLine1 || '';

  if (streetAddress && user?.addressLine2) {
    streetAddress += ` ${user?.addressLine2}`;
  }
  return streetAddress;
};

const mobileOrLandlinePhone = (user) => {
  let telephone = user?.mobilePhone || '';

  if (!telephone) {
    telephone = user?.phone;

    if (telephone) {
      telephone = ` ${user?.phoneExt}`;
    }
  }

  return telephone;
};

const racesAsColumns = (user) => {
  const userRaces = user?.races || [];
  const { noAnswer, ...remainingRaces } = USER_RACE;
  const races = Object.keys(remainingRaces).reduce((raceObj, raceKey) => {
    raceObj[`*${remainingRaces[raceKey]}`] = userRaces.includes(raceKey)
      ? 'true'
      : '';

    return raceObj;
  }, {});

  return races;
};

const createApprenticeData = async ({
  orgId,
  users,
  apprenticeship,
  locationsHash
}) => {
  const userProfileIds = users.list.reduce((ids, user) => {
    if (user?.userProfileId) {
      ids.push(user?.userProfileId);
    }

    return ids;
  }, []);

  let serializedData = users.list;

  if (userProfileIds.length > 0) {
    toast.info('Decrypting data...');

    const userProfiles = await fetchUserProfiles({
      ids: userProfileIds,
      orgId
    });
    const wageSchedules = await fetchWageSchedules({
      apprenticeshipId: apprenticeship?.id
    });

    serializedData = await serializeData({
      usersList: users.list,
      userProfiles,
      wageSchedules,
      apprenticeshipId: apprenticeship?.id
    });
  }

  const data = sortArrByKeys(serializedData, 'name').map((user) => {
    const userEmployer = locationsHash[user.locationId] || {};
    const dataObj = {
      '*Date Training Program Begins': apprenticeship?.startDate
        ? mdySimple(apprenticeship.startDate)
        : '',
      '*First Name': user?.firstName || '',
      '*Last Name': user?.lastName || '',
      'Middle Name': user?.middleName || '',
      Suffix: user?.suffix || '',
      'Social Security Number': user?.ssn || '',
      '*Date of Birth': user?.dob ? mdySimple(user.dob) : '',
      '*Gender': user?.gender ? `${USER_GENDER_ENUM[user.gender]}` : '',
      // 'Citizenship Status': user?.citizenStatus ? `${USER_CITIZEN_ENUM[user.citizenStatus]}` : '',
      '*Employment Status': user?.employmentStatus
        ? `${USER_EMPLOYMENT_ENUM[user.employmentStatus]}`
        : '',
      '*Street Address': fullStreetAddress(user),
      '*City': user?.addressCity || '',
      '*State': user?.addressState || '',
      '*Zip Code': user?.addressPostalCode || '',
      // Country: user?.addressCountry || '',
      Telephone: mobileOrLandlinePhone(user) || '',
      Email: user?.email || '',
      Employer: userEmployer?.name || '',
      '*Hispanic/Latino Ethnicity': user?.ethnicity
        ? USER_ETHNICITY_ENUM[user.ethnicity]
        : '',
      // Convert each race to a Column, so it's value (Ex: Asian) becomes a header
      // include true (could be another value ) on any selected races
      ...racesAsColumns(user),
      '*Veteran Status': user?.veteranStatus
        ? USER_VETERAN_ENUM[user.veteranStatus]
        : '',
      '*Disability': user?.disability
        ? USER_DISABILITY_ENUM[user.disability]
        : '',
      '*Education': user?.educationLevel
        ? USER_EDUCATION_ENUM[user.educationLevel]
        : '',
      '*Occupation': apprenticeship?.occupationName,
      // 'Referring Organization': user?.orgReferral ? USER_REFERRAL_ENUM[user.orgReferral] : '',
      '*Related Technical Instruction Provider': '',
      '*Credit for previous experience': '',
      'Credit for Previous Related Training In Hours': '',
      '*Prior Wages': '',
      '*Prior Wages Units': '',
      '*Entry Wages': user?.entryWages || '',
      '*Entry Wages Units': user?.entryWagesUnits || '',
      '*Wage Schedule': user?.wageScheduleTitle || '',
      'Previously Completed Career Lattice Levels': ''
    };

    return dataObj;
  });

  return data;
};

export default createApprenticeData;
