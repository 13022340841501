import * as twApi from './tw-api';
import {
  API_CONTEXT_INSIGHTS_CREATE,
  DEFAULT_PAGINATION_LIMIT
} from '../constants/api';
import { fetchSupabaseEntries } from './supabaseProxy';

const table = 'contextInsights';

export const createContextInsight = async ({
  contextScope,
  apprenticeshipId,
  userId,
  userName,
  userRole,
  orgId,
  gteDate,
  daysAgo,
  version = 'v1'
}) => {
  const body = {
    orgId,
    contextScope,
    gteDate,
    daysAgo,
    ...(apprenticeshipId ? { apprenticeshipId } : {}),
    ...(userId ? { userId } : {}),
    ...(userName ? { userName } : {}),
    ...(userRole ? { userRole } : {})
  };

  const url = `/${version}${API_CONTEXT_INSIGHTS_CREATE}`;

  const response = await twApi.post(url, '', JSON.stringify(body), true);

  return response;
};

export const fetchContextInsights = async ({
  orgId,
  userId,
  locationId,
  apprenticeshipId,
  contextScope,
  order = 'createdAt',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (locationId) {
    params['f.locationId[eq]'] = locationId;
  }

  if (contextScope) {
    params['f.contextScope[eq]'] = contextScope;
  }

  if (apprenticeshipId) {
    params['f.apprenticeshipId[eq]'] = apprenticeshipId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};
