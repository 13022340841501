import React from 'react';
import { ICON_GROUP } from '@apprentage/constants';

const TitleCell = ({ value: title }) => {
  return (
    <div
      data-cy="groupsList-title"
      className="py-2"
    >
      <i className={`${ICON_GROUP} mr-1`} /> {title}
    </div>
  );
};

export default TitleCell;
