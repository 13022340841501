import React from 'react';
import { useHistory } from 'react-router-dom';
import ResourceIcon from './ResourceIcon';
import { addFileIdParamToURL } from '../../../../../utils/addFileIdParamToURL';

const NameCell = ({ value: name, row }) => {
  const history = useHistory();
  // Resource
  const id = row?.original?.id || '';
  const type = row?.original?.type || '';
  const url = row?.original?.url || '';
  const isEmbedded = row?.original?.isEmbedded || null;

  const onClickName = () => {
    addFileIdParamToURL(row.original.id, history);
  };

  if (type === 'link') {
    return (
      <div className="d-flex align-items-center">
        <ResourceIcon
          type={type}
          url={url}
          name={name}
          isEmbedded={isEmbedded}
        />
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link ml-2 text-left"
          title="View in new tab"
          style={{
            minWidth: '230px'
          }}
          aria-label="View in new tab"
          data-cy="CdnFile-Name"
          data-filename={name}
          data-id={id}
        >
          {name}
        </a>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <ResourceIcon
        type={type}
        url={url}
        name={name}
        isEmbedded={isEmbedded}
      />

      <button
        type="button"
        onClick={onClickName}
        className="btn-link ml-2 text-left"
        style={{
          minWidth: '230px'
        }}
        title="Preview"
        aria-label="Preview"
        data-cy="CdnFile-Name"
        data-filename={name}
        data-id={id}
      >
        {name}
      </button>
    </div>
  );
};

export default NameCell;
