import React from 'react';
import { useDispatch } from 'react-redux';
import { FaEllipsisH, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { setCurrentModal } from '../../../actions/Modals';

const DotMenu = ({
  wagePeriodIndex,
  wageSchedule,
  addressState = '',
  removeWageSchedule,
  canDelete = false
}) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link text-white"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
          tabIndex="-1"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark p-1" />
          <button
            className="dropdown-item border-bottom py-2"
            type="button"
            onClick={() => {
              dispatch(
                setCurrentModal({
                  key: 'manageWageSchedule',
                  data: {
                    currentStep: 1,
                    addressState,
                    ...wageSchedule
                  }
                })
              );
            }}
          >
            <span className="d-flex align-items-center">
              <FaPencilAlt />
              <span className="ml-2">Edit</span>
            </span>
          </button>

          {canDelete && removeWageSchedule !== undefined && (
            <button
              className="dropdown-item py-2"
              type="button"
              onClick={() => {
                removeWageSchedule(wagePeriodIndex);
              }}
            >
              <span className="d-flex align-items-center">
                <FaTrashAlt className="text-danger" />
                <span className="ml-2">Delete</span>
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
