import React from 'react';
import { percentComplete } from '@apprentage/utils';
import ProgressBars from '../../../ProgressBars';
import NumberStat from '../../../NumberStat';

const RelevanceCell = ({ value: similarity }) => {
  const valueMax = 100;
  const valueNow = Math.ceil(similarity * 100);

  const percentage = percentComplete({
    current: valueNow,
    total: valueMax
  });

  return (
    <div className="CdnFiles-RelevanceCell">
      <div>
        <NumberStat
          num={percentage}
          size="xs"
        />
        <ProgressBars
          size="sm"
          className="w-100 mt-2"
          data={[
            {
              className: 'badge-success',
              dataTip: similarity,
              valueNow,
              valueMax,
              valueMin: 0,
              style: { width: percentage },
              text: ''
            }
          ]}
        />
      </div>
    </div>
  );
};

export default RelevanceCell;
