import {
  RESET_LOCATION,
  SET_LOCATION,
  SET_ORG_LOCATION_FILES
} from '../../actions/types';
import locationState from '../initialState/locationState';

const initialState = {
  ...locationState,
  fetched: false,
  cdnFiles: null // [{}]
};

export default function orgLocation(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        ...action.orgLocation,
        cdnFiles: action.cdnFiles,
        fetched: true
      };
    case SET_ORG_LOCATION_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_LOCATION:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
