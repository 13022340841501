import React from 'react';
import { useSelector } from 'react-redux';
import { FaBroadcastTower } from 'react-icons/fa';
import OrgAuthor from '../../OrgAuthor';

const SyndicatedOrgAuthor = ({ value: courseOrgIds, row }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Group
  const courseOrgId = row?.original?.orgId || null;

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        {courseOrgIds ? (
          <>
            {courseOrgIds.includes(orgId) ? (
              <OrgAuthor
                orgId={courseOrgId}
                small
              />
            ) : (
              <div
                className="pr-2 font-weight-bold"
                title="Syndicated"
              >
                <span className="badge badge-info">
                  <span className="d-flex align-items-center">
                    <FaBroadcastTower />
                    <span className="ml-1">Syndicated</span>
                  </span>
                </span>
              </div>
            )}
          </>
        ) : (
          <div className="text-light font-weight-bold">None</div>
        )}
      </div>
    </>
  );
};

export default SyndicatedOrgAuthor;
