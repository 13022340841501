import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaRegClock } from 'react-icons/fa6';
import {
  ICON_CHECKBOX_EMPTY,
  ICON_CHECKBOX_SOME
} from '@apprentage/components/dist/constants/icons';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import Collapse from '@apprentage/components/dist/components/Collapse';
import { formatHours, sortArrByKey } from '@apprentage/utils';
import { dayMdyDate } from '../../../../utils/date';
import {
  // createLogbookRecord,
  updateLogbookRecord
} from '../../../../services/logbookRecords';
import CalendarDatePicker from '../../../ManageContent/CalendarDatePicker';
import heapAnalytics from '../../../../utils/heapAnalytics';
import './style.css';

const RecordForm = ({
  onSuccessCallback,
  onCancelCallback,
  // onErrorCallback,
  theme = 'dark'
}) => {
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const userId = currentUser?.id || '';
  const locationId = currentUser?.locationId || '';
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || '';
  // Modal Data
  const modalData = currentModal?.data || {};
  const record = modalData?.record || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [date, setDate] = useState(record?.date || new Date().toISOString());
  const [hours, setHours] = useState(parseFloat(record?.hours, 10) || 0.25);
  const [transcript, setTranscript] = useState(record?.transcript || '');
  const [competencyIds, setCompetencyIds] = useState(
    record?.competencyIds || []
  );
  // Misc
  const allCompetencyIds = apprenticeship?.competencies
    ? Object.keys(apprenticeship?.competencies)
    : [];

  const whichCompetencies = useMemo(() => {
    let competencies = Object.values(apprenticeship?.competencies || {});

    if (competencies.length === 0) {
      return [];
    }

    competencies = sortArrByKey(competencies, 'description');

    if (searchValue) {
      // return competencies.filter((comp) => filterBySearchTerm(comp));
      return competencies.filter((comp) =>
        comp.description.toLowerCase().includes(searchValue.toLocaleLowerCase())
      );
    }

    return competencies;
  }, [allCompetencyIds, searchValue, apprenticeship?.competencies]);

  const handleSelectCompetency = ({ competencyId, type }) => {
    let newCompetencyIds = [];

    if (type === 'remove') {
      newCompetencyIds = competencyIds.filter((id) => id !== competencyId);
    }

    if (type === 'add') {
      newCompetencyIds = [...competencyIds, competencyId];
    }

    setCompetencyIds(newCompetencyIds);
  };

  const createRecordTrackingEvent = (eventName, data) => {
    heapAnalytics.track(eventName, {
      type: 'manual',
      ...data,
      orgId,
      apprenticeshipId,
      userId,
      locationId,
      employerId: locationId,
      logbookId: record?.logbookId
    });
  };

  const updateRecord = () => {
    const dataToSave = {
      date,
      hours,
      competencyIds,
      transcript
    };

    // Update Record
    createRecordTrackingEvent('Record - Update - Preflight', {
      ...dataToSave,
      logbookRecordId: record?.id
    });

    updateLogbookRecord(dataToSave, record?.id).then(() => {
      toast.success('Record updated!');

      createRecordTrackingEvent('Record - Update - Success', {
        logbookRecordId: record?.id
      });

      if (onSuccessCallback && typeof onSuccessCallback === 'function') {
        onSuccessCallback(dataToSave);
      }
    });
  };

  const onSubmit = () => {
    if (!transcript || transcript.trim() === '') {
      toast.error('A task description of at least 20 characters is required');
      return;
    }

    if (!date) {
      toast.error('A date is required');
    }

    if (!hours) {
      toast.error('Hours are required');
    }

    if (competencyIds.length === 0) {
      toast.error('Select at least 1 competency');
      return;
    }

    setLoading(true);

    if (record?.id) {
      updateRecord();
    } else {
      // createRecord();
    }
  };

  const onSubmitSearch = (searchTerm = '') => {
    if (!searchTerm) {
      setSearchValue('');
    }

    setSearchValue(searchTerm.toLowerCase());
  };

  if (!record?.logbookId) {
    toast.error('No Logbook associated with this Record', { autoClose: false });
    return null;
  }

  if (record && !apprenticeship?.id) {
    return null;
  }

  return (
    <div
      id="RecordForm"
      className={`card RecordForm ${theme ? 'theme-dark' : 'theme-light'}`}
    >
      <div className="card-header py-3">
        <h5 className="font-weight-bold m-0 text-capitalize">
          {record?.id ? 'Edit' : 'Create'} Record
        </h5>
      </div>

      <div className="card-body">
        <div
          className="" // list-group-item
        >
          <label className="mb-1">
            <b>Training Program:</b> <span className="text-danger">*</span>
          </label>
          <input
            name="trainingProgram"
            type="text"
            className="form-control"
            defaultValue={apprenticeship?.title}
            readOnly
          />
        </div>

        <div
          className="mt-3" // list-group-item
        >
          <label className="mb-0">
            <b>Task Description:</b> <span className="text-danger">*</span>
          </label>
          <div className="text-muted medium">
            Detailed description of the task you worked on.
          </div>
          <textarea
            id="RecordForm-description"
            name="description"
            className="form-control mt-2"
            value={transcript}
            style={{
              minHeight: '130px'
            }}
            autoFocus
            required
            placeholder="Today I worked on..."
            onChange={(e) => {
              const { value } = e.currentTarget;

              setTranscript(value);
            }}
          />
        </div>

        <Collapse
          id="recordDate"
          title="Date"
          className="Collapse my-3"
          badge={() => {
            return (
              <span className="h6 m-0">
                <span className="ml-1 badge bg-white border py-1 px-2">
                  {dayMdyDate(date)}
                </span>
              </span>
            );
          }}
        >
          <label className="mb-0">
            <b>Date:</b> <span className="text-danger">*</span>
          </label>
          <div className="medium mb-2">Date task was worked on.</div>
          <CalendarDatePicker
            selected={date ? new Date(date) : null}
            id="date"
            name="date"
            className="form-control mt-2"
            onChange={(selectedDate) => {
              setDate(selectedDate.toISOString());
            }}
            inline
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            required
          />
        </Collapse>

        <div className="list-group-item">
          <label className="mb-0">
            <b>Duration:</b> <span className="text-danger">*</span>
          </label>
          <div className="medium text-muted">
            Number of hours spent on this task.
          </div>
          <div className="input-group mt-2">
            <input
              aria-label="Record's duration"
              id="hours"
              name="hours"
              type="number"
              className="form-control"
              min=".25"
              step="0.25"
              required
              value={hours}
              onChange={(e) => {
                const { valueAsNumber } = e.currentTarget;

                setHours(valueAsNumber);
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <FaRegClock className="mr-1" /> Hours
              </span>
            </div>
          </div>

          <div className="mt-2 text-muted">
            I spent <strong>{formatHours(hours)}</strong> on this task.
          </div>
        </div>

        {/* {(whichCompetencies.length > 0) && ( */}
        <Collapse
          id="recordCompetencies"
          title="Competencies"
          className="Collapse mt-3"
          badge={() => {
            return (
              <span className="h6 m-0">
                <span className="ml-1 badge badge-primary border py-1 px-2">
                  {competencyIds.length}
                </span>
              </span>
            );
          }}
        >
          {Array.isArray(allCompetencyIds) && allCompetencyIds.length > 0 && (
            <div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <button
                  type="button"
                  className="d-flex align-items-center ml-2 mr-2 mr-sm-4"
                  onClick={() => {
                    setCompetencyIds([]);
                  }}
                  style={{ fontSize: '.8rem' }}
                  disabled={competencyIds.length === 0}
                >
                  <i
                    className={`small ${competencyIds.length === 0 ? ICON_CHECKBOX_EMPTY : ICON_CHECKBOX_SOME}`}
                    style={{ fontSize: '.9rem', margin: 0 }}
                  />
                  {/* <span className="ml-2">Deselect</span> */}
                  {/* <span className='mr-1 d-none d-sm-inline'>All</span> */}
                </button>
                <SearchBox
                  value={searchValue}
                  className="w-100 bg-none"
                  onSubmit={onSubmitSearch}
                  loading={loading}
                  autoFocus
                />
              </div>

              {Array.isArray(whichCompetencies) &&
              whichCompetencies.length > 0 ? (
                whichCompetencies.map(({ id: competencyId, description }) => (
                  <label
                    key={`comp-${competencyId}`}
                    className={`h6 border rounded mb-3 d-flex align-items-center position-relative ${competencyIds && competencyIds.includes(competencyId) ? 'bg-lightblue' : 'bg-white'}`}
                  >
                    <div className="mr-2 p-2 d-flex align-items-center justify-content-center">
                      <input
                        id={`comp-${competencyId}`}
                        type="checkbox"
                        value={competencyId}
                        checked={
                          competencyIds && competencyIds.includes(competencyId)
                        }
                        onChange={(e) => {
                          const { checked } = e.currentTarget;

                          handleSelectCompetency({
                            competencyId,
                            type: checked ? 'add' : 'remove'
                          });
                        }}
                      />
                    </div>

                    <div className="p-2 border-left">{description}</div>
                  </label>
                ))
              ) : (
                <div className="text-white">No results, try a new search.</div>
              )}
            </div>
          )}
        </Collapse>
        {/* )} */}
      </div>

      <div className="card-footer py-3">
        <button
          className="btn btn-primary btn-md"
          type="submit"
          disabled={loading}
          onClick={onSubmit}
        >
          Save
        </button>
        <button
          className="btn btn-link btn-md ml-2"
          type="button"
          disabled={loading}
          onClick={onCancelCallback}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RecordForm;
