import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearches } from '../../../actions/Searches';
import { canManageSearches } from '../../../permissions/manage';
import Loading from '../../Loading';
import List from './List';
import './style.css';

const SearchesList = ({
  data = null,
  config,
  className = '',
  hideUser = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const searches = useSelector((state) => state.searches);
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (hideUser) {
      cols.push('userId');
    }

    if (!canManageSearches(role)) {
      cols.push('menuCol');
    }

    return cols;
  }, [hideUser, role]);

  useEffect(() => {
    if (!data || (Array.isArray(data) && data.length > 0)) {
      dispatch(getSearches(config));
    }
  }, [config, data, dispatch]);

  if (!searches.list && !searches.fetched) {
    return <Loading text="Loading Searches..." />;
  }

  return (
    <div className={`searches-list overflow-x-hidden ${className}`}>
      <List
        data={data || searches.list || []}
        hiddenColumns={hiddenColumns}
        config={config}
      />
    </div>
  );
};

export default SearchesList;
