import React from 'react';
import ordinal from 'ordinal';
import { FaEllipsisH, FaTrashAlt } from 'react-icons/fa';

const DotMenuWageRate = ({
  wagePeriodIndex,
  wageAmount,
  duration,
  manageWageRate,
  removeWagePeriod,
  handleSave,
  canDelete = false
}) => {
  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
          tabIndex="-1"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark pl-4 py-1 text-white text-nowrap small font-weight-bold">
            {ordinal(wagePeriodIndex + 1)} Period
          </div>
          <button
            className="dropdown-item border-bottom py-2"
            type="button"
            onClick={() => {
              manageWageRate({
                editMode: true,
                wagePeriodIndex,
                wageAmount,
                duration,
                handleSave
              });
            }}
          >
            Edit
          </button>

          {canDelete && (
            <button
              className="dropdown-item py-2"
              type="button"
              onClick={() => {
                removeWagePeriod(wagePeriodIndex);
              }}
            >
              <span className="d-flex align-items-center">
                <FaTrashAlt className="text-danger" />
                <span className="ml-2">Trash</span>
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DotMenuWageRate;
