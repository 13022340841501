import React from 'react';
import SkillsTags from '../../SkillsTags';

const SkillsCell = ({ value: skillIds }) => {
  if (Array.isArray(skillIds) && skillIds.length > 0) {
    return (
      <SkillsTags
        ids={skillIds}
        showTagIcon
        size="sm"
      />
    );
  }

  return null;
};

export default SkillsCell;
