import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ordinal from 'ordinal';
import ReactTooltip from 'react-tooltip';
import {
  ICON_INFO_CIRCLE,
  ICON_PLUS,
  ICON_TRIANGLE_BANG
} from '@apprentage/constants';
import { formatCurrency } from '../../../utils/currency';
import { US_FED_MINIMUM_WAGE } from '../../../constants/wageSchedules';
import { canEditLocation } from '../../../services/currentUser';
import percentComplete from '../../../utils/percentComplete';
import DotMenu from './DotMenu';
import DotMenuWageRate from './DotMenuWageRate';
import Modal from '../../Modal';

const WageSchedule = ({
  ojtHours,
  addressState = '',
  wageSchedule,
  onChange,
  className = 'mt-3 mb-0',
  showMenu = false,
  readOnly = false
}) => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  // misc
  const [loading, setLoading] = useState(false);
  const [showDialogManageWageRate, setShowDialogManageWageRate] =
    useState(false);
  const role = currentUser?.role || [];
  const manageLocation = canEditLocation(role);

  const title = wageSchedule?.title || '';
  const entryWage = wageSchedule?.entryWage || 0;
  const currentWageRates = wageSchedule?.wageRates || [];

  // local state
  const [wageRates, setWageRates] = useState(
    currentWageRates.length ? currentWageRates : []
  ); // defaultWageRates

  const removeWagePeriod = (wagePeriodIndex) => {
    const updatedWageRates = [...wageRates];
    updatedWageRates.splice(wagePeriodIndex, 1);

    setWageRates(updatedWageRates);
    onChange(updatedWageRates);
  };

  const totalDurationHours = useMemo(() => {
    return wageRates.reduce(
      (prev, curr) => Math.ceil(prev) + Math.ceil(curr.duration),
      0
    );
  }, [wageRates]);

  const showTotalHoursWarning = useMemo(() => {
    if (totalDurationHours !== ojtHours) {
      toast.error(
        `Total hours (${totalDurationHours}) must equal OJT hours (${ojtHours}).`,
        { autoClose: false, toastId: 'showTotalHoursWarning' }
      );

      return true;
    }

    toast.dismiss('showTotalHoursWarning');
    return false;
  }, [totalDurationHours, ojtHours]);

  /**
   *
   * WAGE RATES
   *
   */

  const [editMode, setEditMode] = useState(false);
  const [wagePeriodIndex, setWagePeriodIndex] = useState(0);
  const [prevWageAmount, setPrevWageAmount] = useState(null);
  const [nextWageAmount, setNextWageAmount] = useState(null);

  const wagePeriodOrdinal = useMemo(() => {
    return wagePeriodIndex + 1;
  }, [wagePeriodIndex]);

  /**
   * WAGE AMOUNT LOGIC
   */
  const wageAmountMin = useMemo(() => {
    return prevWageAmount ? prevWageAmount + 0.01 : US_FED_MINIMUM_WAGE;
  }, [prevWageAmount]);

  const wageAmountMax = useMemo(() => {
    return nextWageAmount ? nextWageAmount - 0.01 : entryWage;
  }, [nextWageAmount, entryWage]);

  // Get wage amount from last period
  const lastWageAmount = useMemo(() => {
    return wageRates ? wageRates[wageRates.length - 1].wageAmount : 0;
  }, [wageRates]);

  // Get difference between last wage and entry wage
  const halfDiffLastWageAndEntryWage = useMemo(() => {
    return (entryWage - lastWageAmount) / 2;
  }, [entryWage, lastWageAmount]);

  // Default is half the difference of the last wage and entry wage
  const wageAmountDefaultValue = useMemo(() => {
    let val = lastWageAmount
      ? lastWageAmount + halfDiffLastWageAndEntryWage
      : 0;
    // If default is less than or equal to entry wage, set to max allowed value
    if (val && val >= entryWage) {
      val = entryWage - 0.01;
    }

    return parseFloat(val.toFixed(2));
  }, [entryWage, lastWageAmount, halfDiffLastWageAndEntryWage]);

  const wageRateAmountInvalid = useMemo(() => {
    return wageAmountMin - 0.01 === wageAmountMax;
  }, [wageAmountMin, wageAmountMax]);

  /**
   * HOURS LOGIC
   */
  const remainingOjtHours = ojtHours - totalDurationHours;
  const durationDefaultValue = remainingOjtHours > 0 ? remainingOjtHours : 0;

  // local state
  const [wageAmount, setWageAmount] = useState(null);
  const [duration, setDuration] = useState(null);

  const handleClose = () => {
    setShowDialogManageWageRate(false);
    setLoading(false);
  };

  const onSubmitWageRateForm = (e) => {
    e.preventDefault();

    const dataToSave = {
      wageAmount: parseFloat(wageAmount.toFixed(2)),
      duration
    };
    let updatedWageRates = [];

    setLoading(true);

    if (editMode) {
      updatedWageRates = [...wageRates];
      updatedWageRates[wagePeriodIndex] = dataToSave;

      setWageRates(updatedWageRates);
      onChange(updatedWageRates);
    } else {
      updatedWageRates = [...wageRates, dataToSave];

      setWageRates(updatedWageRates);
      onChange(updatedWageRates);
    }

    handleClose();
  };

  const wagePercentage = useMemo(() => {
    return percentComplete({
      current: wageAmount,
      total: entryWage
    });
  }, [wageAmount, entryWage]);

  const manageWageRate = (wageRateData) => {
    const index = !wageRateData?.editMode
      ? wageRates.length
      : wageRateData?.wagePeriodIndex;
    const newPrevWageAmount =
      wageRates[index - 1] === undefined
        ? null
        : wageRates[index - 1].wageAmount;
    const newNextWageAmount =
      wageRates[index + 1] === undefined
        ? null
        : wageRates[index + 1].wageAmount;

    setWagePeriodIndex(index);
    setEditMode(wageRateData?.editMode);
    setPrevWageAmount(newPrevWageAmount);
    setNextWageAmount(newNextWageAmount);
    setWageAmount(wageRateData?.wageAmount || wageAmountDefaultValue);
    setDuration(wageRateData?.duration || durationDefaultValue);

    setShowDialogManageWageRate(true); // Show Modal
  };

  /**
   *
   * /WAGE RATES
   *
   */

  return (
    <div className={`WageSchedule-container card ${className}`}>
      <div className="card-header bg-secondary d-flex justify-content-between align-items-center">
        <div className="text-white h6 m-0">{title}</div>

        {manageLocation && showMenu && (
          <DotMenu
            canDelete={wageRates.length >= 2}
            wageSchedule={wageSchedule}
            title={title}
            addressState={addressState}
          />
        )}
      </div>

      <div className="card-body p-1">
        <table className="table border m-0">
          <thead>
            <tr>
              <th scope="col">Period</th>
              <th
                scope="col"
                className="text-nowrap"
              >
                Wage
              </th>
              <th
                scope="col"
                className="text-nowrap"
              >
                Duration
              </th>
            </tr>
          </thead>
          <tbody>
            {wageRates &&
              wageRates.length &&
              wageRates.map((wageRate, i) => {
                const wageRatePercentage = percentComplete({
                  current: wageRate?.wageAmount,
                  total: entryWage
                });

                return (
                  <tr key={`wageRate-${i}`}>
                    <th scope="row">{ordinal(i + 1)}</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <div
                          className="text-nowrap"
                          // data-tooltip-content={`${wageRatePercentage} of JW Entry Wage`}
                          // data-tooltip-id="WageSchedule"
                          data-tip={`${wageRatePercentage} of JW Entry Wage`}
                          data-for="WageSchedule"
                        >
                          {formatCurrency(wageRate?.wageAmount)}
                        </div>
                        <span className="d-none d-sm-block ml-2">
                          <i
                            className={`${ICON_INFO_CIRCLE} text-muted opacity-50`}
                            // data-tooltip-content={`${wageRatePercentage} of JW Entry Wage`}
                            // data-tooltip-id="WageSchedule"
                            data-tip={`${wageRatePercentage} of JW Entry Wage`}
                            data-for="WageSchedule"
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          {Math.ceil(wageRate?.duration)}{' '}
                          <span className="text-muted">hrs.</span>
                        </div>
                        {manageLocation && !readOnly && (
                          <DotMenuWageRate
                            canDelete={wageRates.length >= 3}
                            wagePeriodIndex={i}
                            wageAmount={wageRate?.wageAmount}
                            duration={wageRate?.duration}
                            manageWageRate={manageWageRate}
                            removeWagePeriod={removeWagePeriod}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}

            <tr>
              <td
                colSpan="2"
                className="bg-light"
                style={{
                  borderTop: '1px solid #c0c0c0',
                  borderBottom: '1px solid #c0c0c0'
                }}
              >
                {!readOnly && (
                  <button
                    className="btn btn-link p-0"
                    onClick={() => {
                      manageWageRate({ editMode: false });
                    }}
                    type="button"
                  >
                    <i className={`${ICON_PLUS} mr-1`} /> Add Period
                  </button>
                )}
              </td>
              <td
                className={`bg-light font-weight-bold ${showTotalHoursWarning ? 'text-danger' : 'text-muted'}`}
                style={{
                  borderTop: '1px solid #c0c0c0',
                  borderBottom: '1px solid #c0c0c0'
                }}
              >
                {totalDurationHours} hrs.
              </td>
            </tr>

            <tr>
              <td> </td>
              <td>
                <strong>{formatCurrency(entryWage)}</strong>
                <span className="text-muted">/hr.</span>
                <div className="small">
                  <abbr
                    data-tip="Journeyworker"
                    data-for="WageSchedule"
                    title="Journeyworker"
                  >
                    JW
                  </abbr>{' '}
                  Entry Wage
                </div>
              </td>
              <td>
                <strong>{ojtHours}</strong>
                <div className="small text-nowrap">
                  Total{' '}
                  <abbr
                    data-tip="On the Job Training"
                    data-for="WageSchedule"
                    title="On the Job Training"
                  >
                    OJT
                  </abbr>{' '}
                  hours
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {showDialogManageWageRate && (
        <Modal
          cssClassName="turbine-modal--style-card turbine-modal--manageWageRate"
          visible={showDialogManageWageRate}
          close={false}
          pos="middle"
          theme="dark"
        >
          <div className="card border-0">
            <div className="card-header bg-dark">
              <div className="h5 m-0 text-white">
                {ordinal(wagePeriodOrdinal)} Wage Period
              </div>
            </div>
            <form onSubmit={onSubmitWageRateForm}>
              <div className="card-body">
                <div>
                  Apprentices shall be paid a progressively increasing schedule
                  of wages based on a percentage of the{' '}
                  <abbr
                    data-tip="Journeyworker"
                    data-for="WageSchedule"
                    title="Journeyworker"
                  >
                    JW
                  </abbr>{' '}
                  entry wage, {formatCurrency(entryWage)}/hr.
                </div>

                {!wageRateAmountInvalid && (
                  <div className="mt-2">
                    Wage amount must be greater than{' '}
                    <strong>{formatCurrency(wageAmountMin - 0.01)}</strong> and
                    less than or equal to{' '}
                    <strong>{formatCurrency(wageAmountMax)}</strong>.
                  </div>
                )}

                {wageRateAmountInvalid && (
                  <div className="mt-2 font-weight-bold">
                    <i className={`${ICON_TRIANGLE_BANG} text-warning mr-1`} />
                    <span>
                      Reduce the {ordinal(wagePeriodOrdinal - 1)} Wage Period
                      amount before adding a {ordinal(wagePeriodOrdinal)} Wage
                      Period.
                    </span>
                  </div>
                )}

                <table className="table border mt-3 mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Wage Amount</th>
                      <th scope="col">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="wageAmount"
                            >
                              $
                            </span>
                          </div>

                          <input
                            type="number"
                            name="wageAmount"
                            className="form-control m-0"
                            value={wageAmount}
                            minLength={2}
                            min={wageAmountMin}
                            max={wageAmountMax}
                            step="0.01"
                            required
                            autoFocus
                            // placeholder='14.32'
                            aria-label="Username"
                            aria-describedby="wageAmount"
                            style={{ maxWidth: '100px' }}
                            onChange={(e) => {
                              const { valueAsNumber } = e.currentTarget;

                              setWageAmount(valueAsNumber);
                            }}
                          />
                        </div>
                        <div className="mt-1 text-muted">Ex: {entryWage}</div>
                      </td>
                      <td>
                        <div className="input-group">
                          <input
                            type="number"
                            name="duration"
                            className="form-control m-0"
                            minLength={1}
                            min={1}
                            max={ojtHours}
                            required
                            value={Math.ceil(duration)}
                            onChange={(e) => {
                              const { valueAsNumber } = e.currentTarget;

                              setDuration(Math.ceil(valueAsNumber));
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">hrs.</span>
                          </div>
                        </div>
                        <div className="mt-1 text-muted">Ex: 200</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  {wageAmount > 0 && (
                    <div className="mt-2">
                      <i className={`${ICON_INFO_CIRCLE} text-info mr-1`} />
                      <span className="font-weight-bold">
                        {wagePercentage}
                      </span>{' '}
                      of{' '}
                      <abbr
                        data-tip="Journeyworker"
                        data-for="WageSchedule"
                        title="Journeyworker"
                      >
                        JW
                      </abbr>{' '}
                      entry wage
                    </div>
                  )}

                  {totalDurationHours !== ojtHours && (
                    <div className="mt-2">
                      <i className={`${ICON_TRIANGLE_BANG} text-danger mr-1`} />
                      <span>
                        Total hours{' '}
                        {totalDurationHours < ojtHours
                          ? 'fall short of'
                          : 'exceed'}{' '}
                        <abbr
                          data-tip="On the Job Training"
                          data-for="WageSchedule"
                          title="On the Job Training"
                        >
                          OJT
                        </abbr>{' '}
                        Hours by{' '}
                        <strong>
                          {Math.abs(ojtHours - totalDurationHours)} hrs
                        </strong>
                        .
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-primary btm-sm"
                  type="submit"
                  disabled={loading || wageRateAmountInvalid}
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
                <button
                  className="btn btn-link ml-2"
                  type="button"
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      <ReactTooltip id="WageSchedule" />
    </div>
  );
};

export default WageSchedule;
