import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import ReactTooltip from 'react-tooltip';
import { percentComplete } from '@apprentage/utils';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import Collapse from '@apprentage/components/dist/components/Collapse';
import {
  FaCalendarAlt,
  FaClock,
  FaCog,
  FaFan,
  FaThumbsUp,
  FaUser
} from 'react-icons/fa';
import {
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { dayMdyDate, hrMin, mdySimple } from '../../../utils/date';
import { setCurrentModal } from '../../../actions/Modals';
import { canReviewLogbookRecords } from '../../../permissions/manage';
import { fetchUsers } from '../../../services/users';
import { recordManageApproval } from '../../../services/logbookRecords';
import { fetchFlags } from '../../../services/flagsmith';
import { MODAL_KEY_MANAGE_RECORD } from '../../../constants/modals';
import groupDataByDate from '../../../utils/groupDataByDate';
import { locationTerminology } from '../../../services/locations';
import KeyValueItem from '../../KeyValueItem';
import CompetenciesCollapse from '../../OrgConsole/LogbookRecord/CompetenciesCollapse';
import ProgressBars from '../../ProgressBars';
import NumberStat from '../../NumberStat';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import OrgLocation from '../../OrgLocation';

const RecordsList = ({
  className = '',
  list,
  onClickName,
  callbackPrimaryAction,
  ariaExpanded = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Current user
  const role = currentUser?.role || [];
  const userId = currentUser?.id || null;
  const userName = currentUser?.name || null;
  // Apprenticeship
  const competencies = apprenticeship?.competencies || [];
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserProfilePicture =
    apprenticeshipUser?.profilePicture || DEFAULT_PROFILE_PIC;
  // Local State
  const [recordUsers, setRecordUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordIdToRemoveApproval, setRecordIdToRmApproval] = useState(null);
  const [showConfirmationRmApproval, setShowConfirmationRmApproval] =
    useState(false);

  const records = useMemo(() => {
    if (list) {
      return list;
    }

    if (Array.isArray(logbookRecords?.list)) {
      return logbookRecords.list;
    }

    return [];
  }, [list, logbookRecords.list]);

  const userIds = useMemo(() => {
    const userIdEnum = {};

    // List of records can contain records from many different users,
    // gather userIds to fetch user's profile pictures for NameBlock
    if (records.length) {
      records.forEach((r) => {
        userIdEnum[r.userId] = true;
      });
    }

    return Object.keys(userIdEnum);
  }, [records]);

  const recordUsersEnum = useMemo(() => {
    const result = {};

    if (recordUsers.length) {
      recordUsers.forEach((u) => {
        result[u?.id] = u?.profilePicture || DEFAULT_PROFILE_PIC;
      });
    }

    return result;
  }, [recordUsers]);

  const recordGroups = useMemo(() => {
    if (records) {
      return groupDataByDate(records);
    }
    return [];
  }, [records]);

  useEffect(() => {
    if (userIds.length) {
      fetchUsers({
        userIds,
        orgId,
        select: ['id', 'profilePicture']
      }).then((response) => {
        setRecordUsers(response?.items || []);
      });
    }
  }, [orgId, userIds]);

  const handleEditRecord = (record) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_RECORD,
        data: {
          record
        },
        callbackPrimaryAction
      })
    );
  };

  const onClickRemoveApprove = () => {
    setLoading(true);
    fetchFlags().then((fetchedFlags) => {
      if (
        fetchedFlags?.logbooks_readonly?.enabled &&
        fetchedFlags?.logbooks_readonly?.value
      ) {
        dispatch(
          showFeatureFlagNoticeModal({
            modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
            modalBody: NOTICE_MAINTENANCE_MODE_BODY
            // preventClose: true
          })
        );
        setRecordIdToRmApproval(null);
        setShowConfirmationRmApproval(false);
        setLoading(false);
        return;
      }

      recordManageApproval({
        recordId: recordIdToRemoveApproval,
        orgId,
        isApproved: false
      })
        .then(() => {
          if (callbackPrimaryAction) {
            callbackPrimaryAction();
          }
        })
        .finally(() => {
          setRecordIdToRmApproval(null);
          setShowConfirmationRmApproval(false);
          setLoading(false);
        });
    });
  };

  const onClickApprove = (record) => {
    setLoading(true);
    fetchFlags().then((fetchedFlags) => {
      if (
        fetchedFlags?.logbooks_readonly?.enabled &&
        fetchedFlags?.logbooks_readonly?.value
      ) {
        dispatch(
          showFeatureFlagNoticeModal({
            modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
            modalBody: NOTICE_MAINTENANCE_MODE_BODY
            // preventClose: true
          })
        );
        setLoading(false);
        return;
      }

      recordManageApproval({
        recordId: record?.id,
        orgId,
        isApproved: true,
        currentUserId: userId,
        currentUserName: userName
      })
        .then(() => {
          if (callbackPrimaryAction) {
            callbackPrimaryAction();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <div className={`RecordsList ${className}`}>
      {records && records.length && recordGroups.length ? (
        <div>
          {recordGroups.map((recordGroup, i) => {
            const hasRecords =
              recordGroup?.records && recordGroup?.records.length > 0;
            const approvedRecords = hasRecords
              ? recordGroup?.records.filter((r) => r.isApproved)
              : 0;
            const hasApprovedRecords = approvedRecords.length > 0;

            const valueMax = hasRecords ? recordGroup?.records.length : 0;
            const valueNow = hasApprovedRecords ? approvedRecords.length : 0;

            const percentage = percentComplete({
              current: valueNow,
              total: valueMax
            });

            return (
              <Fragment key={`record-${i}`}>
                <Collapse
                  id={`record-${i}`}
                  title={dayMdyDate(recordGroup?.date)}
                  className="mb-3"
                  ariaExpanded={ariaExpanded}
                  p={3}
                  badge={() => {
                    if (!hasRecords) {
                      return null;
                    }

                    return (
                      <div
                        className="d-none d-md-inline-flex ml-2"
                        style={{ minWidth: '200px' }}
                      >
                        <ProgressBars
                          size="sm"
                          className="w-100"
                          data={[
                            {
                              className: 'badge-success',
                              dataTip: 'Records Approved',
                              valueNow,
                              valueMax,
                              valueMin: 0,
                              style: { width: percentage },
                              text: `${valueNow} / ${valueMax}`
                            }
                          ]}
                        />
                      </div>
                    );
                  }}
                >
                  {recordGroup?.records && recordGroup?.records.length ? (
                    <div>
                      {recordGroup.records.map((record, i2) => {
                        const dateReadable = hrMin(record.date);

                        return (
                          <div
                            key={`record-${i}-${i2}`}
                            className="m-3"
                          >
                            <Collapse
                              id={`record-${i}-${i2}`}
                              title={dateReadable}
                              p={3}
                              ariaExpanded={recordGroup?.records.length === 1}
                              className={
                                i2 === recordGroup?.records.length - 1
                                  ? ''
                                  : 'mb-1'
                              }
                              badge={() => {
                                if (record?.hours === undefined) {
                                  return null;
                                }

                                return (
                                  <span className="ml-2 h6 m-0">
                                    <span
                                      className={`badge ${record?.isApproved ? 'badge-success' : 'bg-white border'}`}
                                    >
                                      <span className="d-flex align-items-center">
                                        <FaClock />
                                        <span className="ml-1">
                                          {pluralize(
                                            'Hour',
                                            record?.hours,
                                            true
                                          )}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                );
                              }}
                            >
                              <div className="p-3">
                                <div className="h5 d-flex align-items-center justify-content-between">
                                  <NameBlock
                                    profilePicture={
                                      list
                                        ? recordUsersEnum[record?.userId]
                                        : apprenticeshipUserProfilePicture
                                    }
                                    name={record?.userName || logbook?.userName}
                                    title={record?.locationId}
                                    nameSize="sm"
                                    pictureSize="sm"
                                    onClick={
                                      onClickName
                                        ? () => {
                                            onClickName(record?.userId);
                                          }
                                        : undefined
                                    }
                                  />
                                  <div>
                                    <NumberStat
                                      size="md"
                                      num={record?.hours}
                                      numTitle={pluralize(
                                        'Hour',
                                        record?.hours || 0
                                      )}
                                    />
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div className="font-weight-bold rounded">
                                    Task Description:
                                  </div>
                                  <div className="h5 mt-1 py-5 px-3 bg-light text-center position-relative">
                                    <sup className="text-muted">
                                      <i
                                        className="fa fa-quote-left"
                                        aria-hidden="true"
                                        style={{ fontSize: '.5rem' }}
                                      />
                                    </sup>
                                    <span className="px-1">
                                      {record?.transcript}
                                    </span>
                                    <sup className="text-muted">
                                      <i
                                        className="fa fa-quote-right"
                                        aria-hidden="true"
                                        style={{ fontSize: '.5rem' }}
                                      />
                                    </sup>
                                    {record?.isApproved && (
                                      <div
                                        className="position-absolute bg-keppel p-2"
                                        style={{
                                          borderRadius: '100%',
                                          right: '-10px',
                                          top: '-10px'
                                        }}
                                      >
                                        <FaThumbsUp
                                          size={15}
                                          className="text-white"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="my-3">
                                  <CompetenciesCollapse
                                    theme="light"
                                    competencyIds={record?.competencyIds}
                                    competencies={competencies}
                                  />
                                </div>

                                {record?.isApproved && (
                                  <div className="bg-keppel-light mb-3 p-3 rounded border-success">
                                    <h5 className="font-weight-bold">
                                      Approved
                                    </h5>
                                    <div className="d-flex align-items-center">
                                      <FaCalendarAlt />
                                      <span className="ml-2">
                                        {mdySimple(record?.approvedAt)}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center mt-2">
                                      <FaUser />
                                      <span className="ml-2">
                                        {record?.approvedByName}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        className="btn btn-sm btn-white small"
                                        type="button"
                                        disabled={showConfirmationRmApproval}
                                        onClick={() => {
                                          setRecordIdToRmApproval(record?.id);
                                          setShowConfirmationRmApproval(true);
                                        }}
                                      >
                                        Remove Approval
                                      </button>
                                    </div>
                                  </div>
                                )}

                                <div className="mb-3">
                                  <div className="font-weight-bold rounded mb-1">
                                    {locationTerminology({ orgType }).text}:
                                  </div>

                                  <OrgLocation
                                    locationId={record?.locationId}
                                  />
                                </div>

                                <KeyValueItem
                                  title="ID"
                                  value={record?.id}
                                />
                              </div>

                              <div className="card-footer d-flex align-items-center justify-content-between">
                                <div>
                                  <div className="d-flex align-items-center">
                                    <FaCalendarAlt className="text-muted" />
                                    <span className="ml-1 font-weight-bold">
                                      {mdySimple(record?.date)}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex mt-1">
                                  <div
                                    // data-tooltip-content={record?.isApproved ? 'Remove Approval to Edit' : undefined}
                                    // data-tooltip-id="RecordsList"
                                    data-tip={
                                      record?.isApproved
                                        ? 'Remove Approval to Edit'
                                        : undefined
                                    }
                                    data-for="RecordsList"
                                  >
                                    <button
                                      className="btn btn-sm btn-outline-primary"
                                      type="button"
                                      disabled={
                                        record?.isApproved ||
                                        showConfirmationRmApproval ||
                                        loading ||
                                        !canReviewLogbookRecords(role)
                                      }
                                      onClick={() => {
                                        handleEditRecord(record);
                                      }}
                                    >
                                      <span className="d-inline-flex align-items-center">
                                        <FaCog />
                                        <span className="ml-1">Edit</span>
                                      </span>
                                    </button>
                                  </div>

                                  {!record?.isApproved && (
                                    <button
                                      className="btn btn-sm btn-outline-success ml-2"
                                      title="Approve"
                                      type="button"
                                      disabled={
                                        loading ||
                                        !canReviewLogbookRecords(role)
                                      }
                                      onClick={() => {
                                        onClickApprove(record);
                                      }}
                                    >
                                      <span className="d-inline-flex align-items-center">
                                        {loading ? (
                                          <FaFan className="fa-spin" />
                                        ) : (
                                          <FaThumbsUp />
                                        )}
                                        <span className="ml-1">Approve</span>
                                      </span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>No Records.</div>
                  )}
                </Collapse>
              </Fragment>
            );
          })}
          <ReactTooltip
            html
            id="RecordsList"
          />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: '50px'
          }}
        >
          <span>No records</span>
        </div>
      )}
      {showConfirmationRmApproval && (
        <ConfirmationToast
          title="Are you sure?"
          primaryActionBtnText="Yes, remove approval"
          onConfirm={onClickRemoveApprove}
          handleCancel={() => {
            setRecordIdToRmApproval(null);
            setShowConfirmationRmApproval(false);
          }}
          handleClose={() => {
            setRecordIdToRmApproval(null);
            setShowConfirmationRmApproval(false);
          }}
        />
      )}
    </div>
  );
};

export default RecordsList;
