import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaPencilAlt } from 'react-icons/fa';
import { canEditLocation } from '../../../../../services/currentUser';
import { setCurrentModal } from '../../../../../actions/Modals';

const DotMenuWorkforceInfo = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || '';
  // Current User
  const role = currentUser?.role || [];
  // Permissions
  const manageLocation = canEditLocation(role);

  const manageEmployerProfile = () => {
    dispatch(
      setCurrentModal({
        key: 'manageEmployerProfile'
      })
    );
  };

  return (
    <div className="btn-group align-items-center">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap" />

        {manageLocation && orgType === 'community' && (
          <button
            className="dropdown-item py-2"
            onClick={manageEmployerProfile}
            type="button"
            title="Edit"
          >
            <span className="d-flex align-items-center">
              <FaPencilAlt />
              <span className="ml-2">Edit</span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DotMenuWorkforceInfo;
