import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { GLOBAL_PLATFORM_NAME } from '@apprentage/constants';
import { FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  getApprenticeshipUser,
  getApprenticeshipUserWageSchedule,
  resetApprenticeship,
  resetApprenticeshipUser
} from '../../../../../actions/Apprenticeships';
import { resetLogbook } from '../../../../../actions/Logbooks';
import {
  getEmployer,
  getWageSchedules,
  resetWageSchedules
} from '../../../../../actions/Employers';
import { updateUserProfile } from '../../../../../services/userProfiles';
import { canEditLocation } from '../../../../../services/currentUser';
import { routeWithOrgId } from '../../../../../services/organizations';
import { setCurrentModal } from '../../../../../actions/Modals';
import { TURBINE } from '../../../../../constants/urls';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import UserTabs from './UserTabs';
import WageSchedules from '../../../../WageSchedules';
import OrgLocation from '../../../../OrgLocation';
import WageScheduleSelect from '../../../../ManageContent/WageScheduleSelect';
import UserOrgConsoleSubheader from './UserOrgConsoleSubheader';

const LoadingContainer = styled.div`
  minheight: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const ApprenticeshipUserWageSchedule = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employer = useSelector((state) => state.employer);
  const employerProfile = useSelector((state) => state.employerProfile);
  const wageSchedules = useSelector((state) => state.wageSchedules);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Employer
  const addressState = employer?.addressState || '';
  // Training Program
  const apprenticeshipId = params?.apprenticeshipId || '';
  const ojtHours = apprenticeship?.ojtHours || 0;
  // Training Program User
  const apprenticeshipUserId = params?.apprenticeshipUserId || '';
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserProfile = apprenticeship?.userProfile || null;
  const apprenticeshipUserWageSchedule =
    apprenticeship?.userWageSchedule || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  const selectedWageScheduleId = useMemo(() => {
    return apprenticeshipUserWageSchedule?.id || '';
  }, [apprenticeshipUserWageSchedule?.id]);

  useEffect(() => {
    dispatch(resetWageSchedules());

    // Given: No apprenticeship
    // And: apprenticeshipId is in params
    // And: apprenticeshipUserId is in params
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    return function cleanup() {
      const { href } = window.location;

      // Only reset if not going to apprentice user route
      if (
        !href.includes(`${apprenticeshipRoute}/users/${apprenticeshipUserId}`)
      ) {
        dispatch(resetApprenticeshipUser());
        dispatch(resetLogbook());
      }

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, []);

  useEffect(() => {
    if (apprenticeship?.id && apprenticeshipUserId) {
      dispatch(
        getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId)
      ).then((user) => {
        // Get User Employer
        dispatch(getEmployer(user?.locationId)).then((responseEmployer) => {
          if (responseEmployer?.employerProfileId) {
            // Get Employer Wage Schedules
            dispatch(
              getWageSchedules({
                apprenticeshipId,
                employerProfileId: responseEmployer.employerProfileId
              })
            );
          }
          // Employer Profile Not Setup
        });
      });
    }
  }, [apprenticeship?.id, apprenticeshipId, apprenticeshipUserId, dispatch]);

  if (!apprenticeship?.id) {
    return <Loading text="Loading Training Program" />;
  }

  if (!apprenticeship?.user) {
    return <Loading text="Loading Training Program User" />;
  }

  const updateUserProfileWageSchedules = ({ wageScheduleId }) => {
    const userWageSchedules = apprenticeshipUserProfile?.wageSchedules || {};
    const userWageSchedulesArr = Object.keys(userWageSchedules);
    const updatedWageSchedules = {
      ...(userWageSchedulesArr.length !== 0 ? userWageSchedules : {})
    };

    if (wageScheduleId) {
      updatedWageSchedules[apprenticeshipId] = wageScheduleId;
    } else {
      delete updatedWageSchedules[apprenticeshipId];
    }

    updateUserProfile(
      {
        wageSchedules: updatedWageSchedules
      },
      apprenticeshipUserProfile?.id
    ).then(() => {
      dispatch(getApprenticeshipUserWageSchedule({ wageScheduleId }));
    });
  };

  const messageUser = () => {
    dispatch(
      setCurrentModal({
        key: 'sendEmail',
        data: {
          name: apprenticeshipUser?.name,
          email: apprenticeshipUser?.email,
          message: `Friendly reminder to fill out required personal information in ${GLOBAL_PLATFORM_NAME} for your participation in the ${apprenticeship.title} Training Program.

Use this link to enter the required information: ${TURBINE}
        `,
          fromName: currentUser?.name,
          replyTo: currentUser?.email,
          subject: 'Reminder to fill out required personal information',
          hideSubject: true,
          disableSubject: true
        }
      })
    );
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="users" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <UserOrgConsoleSubheader />

            <UserTabs active="wage-schedule" />

            <div className="border-bottom border-left border-right">
              <div className="card-body pb-0">
                <OrgLocation
                  locationId={employer?.id}
                  showOnlyImage
                  imageHeight={25}
                  className="float-right"
                />
                <h6 className="font-weight-bold">Trainee Wage Schedule</h6>
              </div>

              <div className="card-body pb-0">
                {employer?.name} Wage schedules set {apprenticeshipUser.name}'s
                compensation as they progress and gain skills in the{' '}
                {apprenticeship?.title} Training Program
              </div>

              <div
                className={`m-0 bg-light border ${selectedWageScheduleId ? 'border-success' : 'border-primary'} rounded p-3 m-3 position-relative`}
              >
                {apprenticeshipUserProfile?.id ? (
                  <>
                    {wageSchedules?.fetched && wageSchedules.list ? (
                      <WageScheduleSelect
                        data={wageSchedules?.list || []}
                        key={selectedWageScheduleId}
                        defaultValue={selectedWageScheduleId}
                        onChange={updateUserProfileWageSchedules}
                      />
                    ) : (
                      <>
                        {!wageSchedules.fetched && !wageSchedules.list ? (
                          <LoadingContainer>
                            Checking Wage Schedules...
                          </LoadingContainer>
                        ) : (
                          <LoadingContainer>
                            <div>No {employer?.name} Wage Schedules.</div>
                            <Link
                              className="btn btn-primary btn-sm text-nowrap mt-3"
                              to={routeWithOrgId({
                                route: `${apprenticeshipRoute}/employer/${employer?.id}/wage-schedules`,
                                orgId
                              })}
                              title="Create Wage Schedule"
                            >
                              <span className="d-flex align-items-center">
                                <span>Wage Schedules</span>
                                <FaChevronRight className="ml-2 d-none d-md-inline-block" />
                              </span>
                            </Link>
                          </LoadingContainer>
                        )}
                      </>
                    )}

                    {selectedWageScheduleId && (
                      <div
                        className="position-absolute bg-white rounded"
                        style={{
                          top: -5,
                          right: -5,
                          height: '20px',
                          width: '20px'
                        }}
                      >
                        <FaCheckCircle
                          className="text-keppel"
                          size={20}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <LoadingContainer>
                    <div className="h6 mb-3 ml-2">
                      <span className="badge badge-warning px-2 py-1">
                        Personal Information Needed
                      </span>
                    </div>
                    <div className="mb-3">
                      {apprenticeshipUser?.name} must enter their personal
                      information before being assigned a Wage Schedule.
                    </div>
                    <button
                      className="btn btn-primary btn-sm text-nowrap"
                      type="button"
                      onClick={messageUser}
                    >
                      <span>Send Reminder</span>
                    </button>
                  </LoadingContainer>
                )}
              </div>
            </div>

            <WageSchedules
              className="pt-0"
              addressState={addressState}
              helpText=""
              data={
                selectedWageScheduleId && wageSchedules?.list
                  ? wageSchedules.list
                  : null
              }
              ojtHours={ojtHours}
              canCreateNew={!canEditLocation(role) || !employerProfile?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUserWageSchedule);
