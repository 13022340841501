import { fetchInvites } from '../services/invites';
import { SET_INVITES, RESET_INVITES, SET_ENTRY_ORG_ID } from './types';

export const getInvites = ({
  ids,
  locationId,
  employerProfileId,
  apprenticeshipId,
  inviteType
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchInvites({
        ids,
        locationId,
        employerProfileId,
        apprenticeshipId,
        inviteType
      })
        .then((list) => {
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_INVITES,
            list
          });
          resolve(list);
        })
        .catch((error) => {
          console.error('getInvites', error);
          reject(error);
        });
    });
  };
};

export const resetInvites = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INVITES });
  };
};
