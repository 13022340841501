import React from 'react';
import { useSelector } from 'react-redux';
import { canDirectMessageUser } from '../../../../services/currentUser';
import DirectMessageButton from '../../../btns/DirectMessageButton';
import SendEmailButton from '../../../btns/SendEmailButton';
import TeamsMessageButton from '../../../btns/TeamsMessageButton';
import './style.css';

const ContactCell = ({ row }) => {
  // value: id
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // User
  const currentUserSlackUserId = currentUser.slackUserId ?? '';
  const role = currentUser.role ?? [];
  // Organization
  const integrateSlack = organization.integrateSlack ?? false;
  const integrateMicrosoftTeams = organization.integrateMicrosoftTeams ?? false;
  // Row
  const original = row.original ?? {};
  const name = original.name ?? '';
  const slackUserId = original.slackUserId ?? '';
  const email = original.email ?? '';

  return (
    <div className="d-flex justify-content-end">
      {integrateSlack &&
        slackUserId &&
        currentUserSlackUserId &&
        canDirectMessageUser(role) && (
          <DirectMessageButton
            name={name}
            organization={organization}
            slackUserId={slackUserId}
            buttonText="Slack"
            className="btn btn-sm btn-white mr-2"
            showIcon
          />
        )}

      {integrateMicrosoftTeams && (
        <TeamsMessageButton
          email={email}
          text="Chat"
          className="chat-button mr-2"
        />
      )}

      {!integrateMicrosoftTeams && (
        <SendEmailButton
          userName={name}
          userEmail={email}
          className="btn btn-sm btn-outline-primary"
          showIcon
        />
      )}
    </div>
  );
};

export default ContactCell;
