import React from 'react';
import { compose } from 'redux';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import TopicsInsights from './TopicsInsights';
import UsersAndSkillsInsights from './UsersAndSkillsInsights';

const Dashboard = () => {
  return (
    <>
      <UsersAndSkillsInsights />
      <TopicsInsights />
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withConsole)(Dashboard);
