import React from 'react';
import DateCell from '../../../ManageContent/Tables/DateCell';
import Table from './Table';
import MenuCell from './MenuCell';
import UserCell from './UserCell';
import KnowledgeGapCell from './KnowledgeGapCell';

const List = ({ data, config, hiddenColumns = [] }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Query',
        accessor: 'query',
        disableSortBy: true
      },
      {
        Header: 'Knowledge',
        accessor: 'resourceIds',
        Cell: KnowledgeGapCell
      },
      {
        Header: 'User',
        accessor: 'userId',
        Cell: UserCell
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: DateCell
      },
      {
        Header: '',
        accessor: 'menuCol',
        config,
        Cell: MenuCell,
        disableSortBy: true
      }
    ],
    [config]
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Searches.</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
    />
  );
};

export default List;
