import { toast } from 'react-toastify';
import { getClient, flattenItem } from '../services/contentful';
import { createEntry } from '../services/entry';
import formatData from '../services/formatData';
import { fetchGroups } from '../services/groups';
import { updateOrganization } from '../services/organizations';
import sortArrByArr from '../utils/sortArrByArr';
import {
  RESET_COURSE_GROUPS,
  RESET_USER_GROUPS,
  SET_COURSE_GROUP,
  RESET_COURSE_GROUP,
  SET_ORG_COURSE_GROUP_IDS,
  SET_COURSE_GROUPS,
  SET_USER_GROUPS,
  SET_ORG_USER_GROUP_IDS,
  CREATE_COURSE_GROUP,
  CREATE_USER_GROUP,
  SET_ENTRY_ORG_ID
} from './types';

function actionFromType(type) {
  // Construct Dispatch Data based on type [course, resource, user (everything else)]
  switch (type) {
    case 'course':
      return SET_COURSE_GROUPS;
    case 'user':
      return SET_USER_GROUPS;
    default:
      return SET_COURSE_GROUPS;
  }
}

export const getGroups = ({
  orgId,
  sortByIds, // string[]
  type = 'course',
  groupTags = null,
  groupIds = null,
  limit = 1000
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchGroups({
        orgId,
        type,
        groupTags,
        groupIds,
        limit
      })
        .then((groups) => {
          let list = groups;

          if (sortByIds && list && type === 'course') {
            list = sortArrByArr(list, sortByIds, 'id');
          }

          if (sortByIds && list && type === 'user') {
            list = sortArrByArr(list, sortByIds, 'id');
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          const data = {
            type: actionFromType(type),
            groupType: type,
            list,
            groupTags,
            groupIds
          };

          dispatch(data);
          resolve(data);
        })
        .catch((error) => {
          console.error('getGroups', error);
          reject(error);
        });
    });
  };
};

export const getOrgCourseGroupIds = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return getClient()
        .getEntry(orgId, {
          select: 'fields.courseGroupIds',
          content_type: 'organization'
        })
        .then((response) => {
          const flattenedResponse =
            response && response.fields ? flattenItem(response) : null;
          const courseGroupIds = flattenedResponse?.courseGroupIds || null;

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: flattenedResponse?.orgId || null
          });

          dispatch({
            type: SET_ORG_COURSE_GROUP_IDS,
            courseGroupIds
          });

          resolve(courseGroupIds);
        })
        .catch((error) => {
          console.error('getOrgCourseGroupIds', error);
          reject(error);
        });
    });
  };
};

export const getOrgUserGroupIds = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return getClient()
        .getEntry(orgId, {
          select: 'fields.userGroupIds',
          content_type: 'organization'
        })
        .then((response) => {
          const flattenedResponse = response?.fields
            ? flattenItem(response)
            : null;
          const userGroupIds = flattenedResponse?.userGroupIds || null;

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: flattenedResponse?.orgId || null
          });

          dispatch({
            type: SET_ORG_USER_GROUP_IDS,
            userGroupIds
          });

          resolve(userGroupIds);
        })
        .catch((error) => {
          console.error('getOrgCourseGroupIds', error);
          reject(error);
        });
    });
  };
};

export const getCourseGroup = ({ groupId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return getClient()
        .getEntry(groupId)
        .then((response) => {
          const courseGroup = response ? flattenItem(response) : null;

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: courseGroup?.orgId || null
          });

          dispatch({
            type: SET_COURSE_GROUP,
            courseGroup
          });

          resolve(courseGroup);
        })
        .catch((error) => {
          console.error('getCourseGroup', error);
          reject(error);
        });
    });
  };
};

export const resetCourseGroups = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COURSE_GROUPS });
  };
};

export const resetCourseGroup = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COURSE_GROUP });
  };
};

export const resetUserGroups = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_GROUPS });
  };
};

export const createCourseGroup = (data) => {
  return (dispatch, getState) => {
    const { organization, courseGroups, currentUser } = getState();
    const orgId = organization?.id || null;
    const userId = currentUser?.id || null;
    const userName = currentUser?.name || null;
    const courseGroupIds = organization?.courseGroupIds || [];
    const courseGroupsList = courseGroups?.list || [];

    return new Promise((resolve, reject) => {
      const preppedData = formatData(
        {
          ...data,
          authorUserId: userId,
          authorUserName: userName
        },
        'group'
      );

      createEntry({
        contentType: 'group',
        data: preppedData
      })
        .then((newGroup) => {
          /**
           * Currently at the Organization level
           */
          const newCourseGroupIds = [...courseGroupIds, newGroup.sys.id];
          const list = [...courseGroupsList, data];

          toast.info('Organizing course groups...');

          return updateOrganization(
            {
              courseGroupIds: newCourseGroupIds
            },
            orgId
          )
            .then(() => {
              dispatch({
                type: CREATE_COURSE_GROUP,
                courseGroupIds: newCourseGroupIds,
                list
              });

              dispatch(getGroups({ orgId, type: 'course' })).then(() => {
                resolve(newGroup);
              });
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const createUserGroup = (data) => {
  return (dispatch, getState) => {
    const { organization, userGroups, currentUser } = getState();
    const orgId = organization?.id || null;
    const userId = currentUser?.id || null;
    const userName = currentUser?.name || null;
    const userGroupIds = organization?.userGroupIds || [];
    const userGroupsList = userGroups?.list || [];

    return new Promise((resolve, reject) => {
      const preppedData = formatData(
        {
          ...data,
          authorUserId: userId,
          authorUserName: userName
        },
        'group'
      );

      createEntry({
        contentType: 'group',
        data: preppedData
      })
        .then((newGroup) => {
          /**
           * Currently at the Organization level, update (userGroupIds)
           */
          const newUserGroupIds = [newGroup.sys.id, ...userGroupIds];
          const list = [...userGroupsList, data];

          toast.info('Organizing user groups...');

          return updateOrganization(
            {
              userGroupIds: newUserGroupIds
            },
            orgId
          )
            .then(() => {
              dispatch({
                type: CREATE_USER_GROUP,
                userGroupIds: newUserGroupIds,
                list
              });

              dispatch(getGroups({ orgId, type: 'user' })).then(() => {
                // TODO is this needed?
                resolve(newGroup);
              });
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};
