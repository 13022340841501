import React from 'react';

const LocationImage = ({ src, theme, alt, height }) => {
  if (!src) {
    return null;
  }

  return (
    <>
      {theme === 'dark' ? (
        <div className="bg-light mr-2 d-flex">
          <img
            src={src}
            height={height}
            style={{
              height: `${height}px`
            }}
            alt={alt}
          />
        </div>
      ) : (
        <img
          src={src}
          height={height}
          style={{
            height: `${height}px`
          }}
          alt={alt}
          className="mr-2"
        />
      )}
    </>
  );
};

export default LocationImage;
