import {
  getClient,
  getClientManagement,
  CSPACE,
  flattenItems,
  referenceType
} from './contentful';
import removeObjectFromArray from '../utils/removeObjectFromArray';
import formatData from './formatData';
import { createEntry, updateEntry } from './entry';
import isPublished from '../utils/isPublished';
import { TURBINE_PAGE } from '../constants/urls';
import sortArrByArr from '../utils/sortArrByArr';

export const createCourse = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'class');

    createEntry({
      contentType: 'class',
      data: preppedData
    })
      .then((newCourse) => {
        resolve(newCourse);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const publicCourseUrl = ({ embed, courseSlug, classId, orgSlug }) => {
  let url = `${TURBINE_PAGE}/o/${orgSlug}/courses`;

  if (courseSlug) {
    url += `/view/${courseSlug}`;
  } else {
    url += `/id/${classId}`;
  }

  if (embed) {
    url += '?embed=true';
  }

  return url;
};

/**
 * Checks if courseCohorts exists and has eUnitTracking
 * @param {array of objects} courseCohorts
 */
export const hasContinueEducationSettings = ({ courseCohorts }) => {
  if (
    courseCohorts.list &&
    Array.isArray(courseCohorts.list) &&
    courseCohorts.list.length > 0
  ) {
    // TODO this could be simplified
    const [firstCohort] = courseCohorts.list;

    if (isPublished(firstCohort)) {
      if (firstCohort.eUnitTracking) {
        // TODO cohort flatten
        return true;
      }
    }
  }

  return false;
};

export const routeWithClassId = ({ route, classId, urlParams }) => {
  const query = {
    ...(classId ? { classId } : {}),
    ...(urlParams || {})
  };
  const params = new URLSearchParams(query);

  return `${route}?${params.toString()}`;
};

export const addTopicToClass = ({ topicId, classId }) => {
  return new Promise((resolve, reject) => {
    // Update Class
    return getClientManagement()
      .getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(classId))
      .then((entry) => {
        if (entry.fields.subjects === undefined) {
          // TODO course flatten
          entry.fields.subjects = {
            'en-US': []
          };
        }

        entry.fields.subjects = {
          // TODO course flatten
          'en-US': [...entry.fields.subjects['en-US'], referenceType(topicId)]
        };

        if (entry.fields.topicIds === undefined) {
          entry.fields.topicIds = { 'en-US': [] };
        }

        entry.fields.topicIds = {
          'en-US': [...entry.fields.topicIds['en-US'], topicId]
        };

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeTopicFromClass = ({ topicId, classId }) => {
  return new Promise((resolve, reject) => {
    // Update entry
    return getClientManagement()
      .getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(classId))
      .then((entry) => {
        const classSubjects = entry.fields.subjects['en-US'];
        const subjects = removeObjectFromArray(
          classSubjects,
          'sys.id',
          topicId
        );
        // TODO consider case when removing last topic from class
        entry.fields.subjects = {
          // TODO course flatten
          'en-US': subjects
        };

        // TODO course flatten
        if (subjects && Array.isArray(subjects) && subjects.length > 0) {
          if (entry.fields.topicIds === undefined) {
            entry.fields.topicIds = { 'en-US': [] };
          }

          // TODO course flatten
          entry.fields.topicIds['en-US'] = subjects.map(
            (subject) => subject.sys.id
          );
        } else {
          entry.fields.topicIds['en-US'] = undefined;
        }

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        setTimeout(() => {
          resolve(entry);
        }, 250);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCourse = (data) => {
  return new Promise((resolve, reject) => {
    const { classId, orgId, ...remainingData } = data;
    const preppedData = formatData({ ...remainingData }, 'class');

    if (orgId) {
      console.warn('updateCourse: orgId not allowed', orgId);
    }

    updateEntry(preppedData, classId)
      .then((updatedClass) => {
        setTimeout(() => {
          resolve(updatedClass);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchCourses = ({
  orgId,
  locationIds,
  syndicatedSharing,
  title,
  overview,
  skillIds,
  courseIds,
  userId,
  select,
  limit
}) => {
  const config = {
    content_type: 'class',
    include: 1
  };

  if (limit) {
    config.limit = limit;
  }

  if (orgId) {
    config['fields.orgId'] = orgId;
  }

  if (locationIds) {
    config['fields.locationIds[in]'] = locationIds.join(',');
  }

  if (userId) {
    config['fields.userId[eq]'] = userId;
  }

  if (title) {
    config['fields.title[match]'] = title;
  }

  if (overview) {
    config['fields.overview[match]'] = overview;
  }

  if (syndicatedSharing) {
    config['fields.orgIds[exists]'] = true;
  }

  if (skillIds) {
    config['fields.skillIds[in]'] = skillIds.join(',');
  }

  if (courseIds && Array.isArray(courseIds) && courseIds.length) {
    config['sys.id[in]'] = courseIds.join(',');
  }

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    getClient()
      .getEntries(config)
      .then(({ items }) => {
        let courses = items.length ? flattenItems(items) : null;

        if (
          Array.isArray(courses) &&
          courses.length > 0 &&
          Array.isArray(courseIds) &&
          courseIds.length > 0
        ) {
          courses = sortArrByArr(courses, courseIds, 'id');
        }

        resolve(courses);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchCoursesBySkillIds = ({ skillIds, orgId }) => {
  return new Promise((resolve, reject) => {
    fetchCourses({
      orgId,
      skillIds,
      select: ['sys.id', 'fields.title', 'fields.skillIds', 'sys.createdAt']
    })
      .then((response) => {
        resolve({ courses: response }); // TODO responses?.items when migrated to Supabase
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchCoursesBySearchValue = ({
  orgId,
  locationIds,
  title,
  overview
}) => {
  return new Promise((resolve) => {
    let allCourses = [];

    fetchCourses({
      orgId,
      locationIds,
      title,
      overview
    })
      .then((courses) => {
        if (courses && Array.isArray(courses) && courses.length > 0) {
          allCourses = [...courses];
        }

        fetchSyndicatedCourses({ orgIds: [orgId], title, overview }).then(
          (syndicatedCourses) => {
            if (
              syndicatedCourses &&
              Array.isArray(syndicatedCourses) &&
              syndicatedCourses.length > 0
            ) {
              allCourses = [...allCourses, ...syndicatedCourses];
            }

            resolve({ courses: allCourses.length > 0 ? allCourses : null });
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

export const fetchSyndicatedCourses = ({ orgIds, select, title, overview }) => {
  const config = {
    content_type: 'class',
    include: 1,
    'fields.orgIds[in]': orgIds.join(',')
  };

  if (title) {
    config['fields.title[match]'] = title;
  }

  if (overview) {
    config['fields.overview[match]'] = overview;
  }

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    getClient()
      .getEntries(config)
      .then(({ items }) => {
        const syndicatedCourses = items.length ? flattenItems(items) : null;

        resolve(syndicatedCourses);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const classPreReqs = ({ courses }) => {
  const hash = {};

  if (!courses) return hash;

  courses.forEach((course) => {
    if (course.prerequisite) {
      hash[course.sys.id] = course.prerequisite.sys.id;
    }
  });

  return hash;
};

export const topicPreReqs = ({ courses }) => {
  const hash = {};

  if (!courses) return hash;

  courses.forEach((course) => {
    const { subjects } = course;

    if (subjects) {
      subjects.forEach((subject) => {
        if (isPublished(subject) && subject.fields.prerequisite) {
          // TODO course flatten
          hash[subject.sys.id] = subject.fields.prerequisite.sys.id;
        }
      });
    }
  });

  return hash;
};
