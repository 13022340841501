import React from 'react';
import { ICON_MAP_LOCATION } from '@apprentage/constants';
import AddressReadable from '../AddressReadable';
import CurrentWeather from '../Weather/CurrentWeather';
import LocationDotMenu from './LocationDotMenu';
import Alert from '../Alert';

const Location = ({
  data,
  className = '',
  showHeader = true,
  headerTitle = 'Location',
  headerSubtitle = '',
  showWeather = false,
  showMenuOptionSettings = false,
  currentWeather = null
}) => {
  // Location
  const name = data?.name || null;
  const subtitle = data?.subtitle || '';
  const locationImage = data?.locationImage || null;
  // Address : Location
  const addressLine1 = data?.addressLine1 || '';
  const addressLine2 = data?.addressLine2 || '';
  const addressCity = data?.addressCity || '';
  const addressState = data?.addressState || '';
  const addressPostalCode = data?.addressPostalCode || '';
  // const addressCountry = data?.addressCountry || '';

  return (
    <div className={`card ${className}`}>
      {showHeader && (
        <div className="card-header bg-white px-3 py-2 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="ml-1 text-left">
              <div className="h5 font-weight-bold m-0">{headerTitle}</div>
              {headerSubtitle && <div>{headerSubtitle}</div>}
            </div>
          </div>
          <LocationDotMenu
            data={data}
            showMenuOptionSettings={showMenuOptionSettings}
          />
        </div>
      )}

      <div className="card-body">
        <div className="row">
          {locationImage && (
            <div className="col-md-5">
              <img
                className="featurette-image img-fluid mx-auto"
                src={locationImage}
                alt={name + subtitle}
              />
            </div>
          )}
          <div className="col-md-7">
            <div className="">
              {!showHeader && (
                <div className="m-0 h4 font-weight-bold">
                  <div className="d-block text-muted">{name}</div>
                  {subtitle && <small>{subtitle}</small>}
                </div>
              )}

              {showWeather &&
                currentWeather?.current &&
                currentWeather?.location && (
                  <CurrentWeather
                    className="py-3 px-1"
                    current={currentWeather.current}
                    location={currentWeather.location}
                    iconHeight={60}
                    icon
                  />
                )}

              <div className="mt-2">
                <Alert
                  type=""
                  icon={ICON_MAP_LOCATION}
                  className="m-0 mb-5 w-100 border bg-light"
                >
                  <AddressReadable
                    addressLine1={addressLine1}
                    addressLine2={addressLine2}
                    addressCity={addressCity}
                    addressState={addressState}
                    addressPostalCode={addressPostalCode}
                    // addressCountry={addressCountry}
                  />
                </Alert>
              </div>
            </div>

            {!showHeader && <LocationDotMenu location={data} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
