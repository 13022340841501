import React from 'react';
import { FaCube } from 'react-icons/fa';
import Table from './Table';
import DescriptionCell from './DescriptionCell';

const List = ({ data, handleClickRow, showClassifications }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <span className="d-flex align-items-center">
            <FaCube className="mr-1" />
            <span>Competency</span>
          </span>
        ),
        accessor: 'description',
        disableSortBy: true,
        Cell: (props) => (
          <DescriptionCell
            {...props}
            showClassifications={showClassifications}
          />
        )
      },
      {
        Header: 'Target Hours',
        accessor: 'totalHours',
        disableSortBy: true
      }
    ],
    [showClassifications]
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Competencies.</div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
    />
  );
};

export default List;
