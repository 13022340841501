import { SET_CURRENT_PAGE, SET_LOADING, LOGOUT } from './types';

export const setCurrentPage = (currentPage) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_PAGE,
      currentPage
    });
  };
};

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      loading
    });
  };
};

export const logout = () => {
  localStorage.removeItem('turbine_token');

  return (dispatch) => {
    dispatch({
      type: LOGOUT
    });
  };
};
