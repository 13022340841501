import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { FaDownload, FaFilter, FaPlus } from 'react-icons/fa';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import {
  reactSelectDefaultValues,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import { resetCurrentClass } from '../../../actions/Class';
import { resetCurrentCohort } from '../../../actions/Cohorts';
import {
  getGroups,
  getOrgUserGroupIds,
  resetUserGroups
} from '../../../actions/Groups';
import { withAuthorization } from '../../Session';
import {
  canAccessAllUsers,
  canExportUserData,
  canViewTestUsers
} from '../../../services/currentUser';
import {
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_USERS,
  SLASH
} from '../../../constants/routes';
import { userRolesEnum } from '../../../services/users';
import { ICON_USER_DIRECTORY } from '../../../constants/assets';
import withOrgConsole from '../../App/withOrgConsole';
import exportData from './exportData';
import Loading from '../../Loading';
import MembersList from '../../MembersList';
import InviteUserButton from '../../btns/InviteUserButton';
import OrgConsoleHeader from '../OrgConsoleHeader';
import Tabs from './Tabs';
import Switch from '../../Switch';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import SkillsSelectMenu from '../Skills/SkillsSelectMenu';
import './style.css';

const initialListConfig = {
  excludeTestUsers: true,
  excludeInactive: true,
  roles: [
    'student',
    'teacher',
    'admin',
    'owner',
    'programManager',
    'programTrainer'
  ]
};

const Users = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const users = useSelector((state) => state.users);
  const userGroups = useSelector((state) => state.userGroups);
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);
  const [excludeTestUsers, setExcludeTestUsers] = useState(
    initialListConfig.excludeTestUsers
  );
  const [excludeInactive, setExcludeInactive] = useState(
    initialListConfig.excludeInactive
  );
  const [userRoles, setUserRoles] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [skillIds, setSKillIds] = useState(null);
  const [showSkillsColumn, setShowSkillsColumn] = useState(false);

  const toggleSkillsColumn = () => {
    setShowSkillsColumn(!showSkillsColumn);
  };

  // User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';
  const orgType = organization?.type || '';
  const locationsHash = locations?.hash || {};
  const userGroupsHash = userGroups?.hash || {};

  // User Roles
  const userRolesOptions = reactSelectOptionsFromEnum(
    userRolesEnum({ orgType })
  );
  const userRolesDefaultValues = reactSelectDefaultValues(
    userRoles,
    userRolesOptions
  );

  const filterList = useCallback((key, value) => {
    if (key === 'excludeTestUsers') {
      setExcludeTestUsers(value);
    }

    if (key === 'excludeInactive') {
      setExcludeInactive(value);
    }

    if (key === 'searchValue') {
      setSearchValue(value);
    }

    if (key === 'skillIds') {
      setSKillIds(value);
    }

    if (key === 'userRoles') {
      setUserRoles(!value ? initialListConfig.roles : value);
    }

    setListConfig((prev) => {
      const newState = {
        ...prev,
        page: 1, // Prevent limiting query to specific offset of records
        ...(key === 'excludeTestUsers' ? { excludeTestUsers: value } : {}),
        ...(key === 'excludeInactive' ? { excludeInactive: value } : {}),
        ...(key === 'searchValue' ? { searchValue: value } : {}),
        ...(key === 'userRoles'
          ? { roles: !value ? initialListConfig.roles : value }
          : {})
      };

      if (key === 'skillIds') {
        if (newState.skillIds) {
          delete newState.skillIds;
        }

        if (Array.isArray(value) && value.length > 0) {
          newState.skillIds = value;
        }
      }

      return newState;
    });
  }, []);

  useEffect(() => {
    if (orgId) {
      dispatch(getOrgUserGroupIds({ orgId }));
      dispatch(getGroups({ orgId, type: 'user' }));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(resetCurrentClass());
    dispatch(resetCurrentCohort());

    $(document).on('click', '.userFilters-stop-propagation', (e) => {
      e.stopPropagation();
    });

    return function cleanup() {
      dispatch(resetCurrentClass());
      dispatch(resetCurrentCohort());
      dispatch(resetUserGroups());
    };
  }, [dispatch]);

  if (!organization?.id || !locations?.fetched || !currentUser?.id) {
    return <Loading text="Loading organization..." />;
  }

  if (!canAccessAllUsers(role, orgType)) {
    return <Redirect to={SLASH} />;
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="User Directory"
            image={ICON_USER_DIRECTORY}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <InviteUserButton>
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="font-weight-bold ml-1">New</span>
              </span>
            </InviteUserButton>
          </OrgConsoleHeader>

          <Tabs
            active="all"
            showSkillsColumn={showSkillsColumn}
            toggleSkillsColumn={toggleSkillsColumn}
          />

          <div className="card-body bg-white border-left border-right border-bottom shadow">
            <div className="mb-3">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <SearchBox
                  className="w-100"
                  onSubmit={(value) => {
                    filterList('searchValue', value);
                  }}
                  onClear={() => {
                    const searchBox = document.querySelector(
                      'input[name="userName"]'
                    );

                    searchBox.focus();
                  }}
                  name="userName"
                  value={searchValue}
                  placeholder="Search by name..."
                  autoFocus
                />

                <div className="d-flex align-items-center">
                  <div className="dropdown">
                    <button
                      className={`btn btn-sm ${excludeTestUsers || excludeInactive || userRoles ? 'btn-primary' : 'btn-outline-secondary'} dropdown-toggle d-flex align-items-center`}
                      type="button"
                      id="userFilters"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-label="Filters"
                      title="Filters"
                      style={{
                        minHeight: '31px'
                      }}
                    >
                      <FaFilter />
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right p-3"
                      aria-labelledby="userFilters"
                    >
                      <h5>Filters</h5>
                      <div className="border p-3 mb-2">
                        {canViewTestUsers(role) && (
                          <Switch
                            id="excludeTestUsers"
                            label="Exclude Test Users"
                            className="mb-3"
                            value={excludeTestUsers}
                            onChange={() => {
                              filterList('excludeTestUsers', !excludeTestUsers);
                            }}
                          />
                        )}

                        <Switch
                          id="excludeInactive"
                          label="Exclude Inactive Users"
                          value={excludeInactive}
                          onChange={() => {
                            filterList('excludeInactive', !excludeInactive);
                          }}
                        />
                      </div>

                      <div className="userFilters-stop-propagation">
                        <h5>User Roles:</h5>
                        <div className="border p-3">
                          <SelectMenu
                            className="user-roles-select-menu"
                            id="userRoles"
                            name="userRoles"
                            options={userRolesOptions}
                            placeholder="Choose Role"
                            isMulti
                            defaultValue={userRolesDefaultValues}
                            onChange={(values) => {
                              const newValues =
                                values.length === 0
                                  ? null
                                  : values.map((tag) => tag.value);

                              filterList('userRoles', newValues);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {canExportUserData(role, orgType) && (
                    <div className="d-none d-sm-flex ml-2">
                      <button
                        className="btn btn-sm btn-outline-primary d-flex align-items-center"
                        onClick={() => {
                          exportData({
                            users: users.list,
                            orgName,
                            orgType,
                            locationsHash,
                            userGroupsHash
                          });
                        }}
                        title="Export Data"
                        type="button"
                        disabled={!users.list}
                      >
                        <FaDownload className="mr-1" />
                        <span>Export</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-100 d-flex justify-content-between align-items-center mt-3">
                <SkillsSelectMenu
                  className="position-relative w-100"
                  ids={skillIds}
                  onChange={(values) => {
                    const newValues = values.map((skill) => skill.value);

                    filterList('skillIds', newValues);
                  }}
                />
              </div>
            </div>

            <MembersList
              route={ORG_CONSOLE_USERS}
              listConfig={listConfig}
              showSkillsCol={showSkillsColumn}
              showContactCol
              showGroupsCol
            />

            {Array.isArray(users.list) && users.list.length > 0 && (
              <PaginationSupabase
                items={users?.list}
                pagination={users?.pagination}
                page={users?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: users?.pagination?.page + 1
                  }));
                  // goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: users?.pagination?.page - 1
                  }));
                  // goToElem('root');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Users);
