import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { FaDownload, FaListOl } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import QuizOutcomesList from '../../QuizOutcomesList';
import {
  getQuiz,
  resetCurrentQuiz,
  setCurrentClassQuizzes,
  resetCurrentClassQuizzes
} from '../../../actions/Quizzes';
import {
  getQuizOutcomes,
  setQuizOutcomesActiveOrgId,
  setQuizOutcomesActiveLocationId,
  resetQuizOutcomes
} from '../../../actions/QuizOutcomes';
import {
  canExportUserData,
  userOrgIsCourseOrg
} from '../../../services/currentUser';
import { routeWithClassId } from '../../../services/courses';
import { CLASS_CONSOLE_QUIZZES } from '../../../constants/routes';
import {
  getSyndicatedCourseOrgs,
  resetSyndicatedCourseOrgs
} from '../../../actions/Organizations';
import OrganizationSelect from '../../ManageContent/OrganizationSelect';
import LocationSelect from '../../ManageContent/LocationSelect';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import Loading from '../../Loading';
import OrgConsoleSubheader from '../../OrgConsole/OrgConsoleSubheader';
import Tabs from './Tabs';
import exportData from './exportData';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';

const Quiz = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  const currentUser = useSelector((state) => state.currentUser);
  const syndicatedCourseOrgs = useSelector(
    (state) => state.syndicatedCourseOrgs
  );
  // Params
  const { quizId, userId } = params;
  // Course
  const classId = course?.id || null;
  const orgIds = course?.orgIds || null;
  // Organization
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;
  const orgType = organization?.type || null;
  // Quiz
  const title = currentQuiz?.title || '';
  // Current User
  const role = currentUser?.role || [];
  // Quiz Outcomes
  const activeOrgId = quizOutcomes?.activeOrgId || '';
  const activeLocationId = quizOutcomes?.activeLocationId || undefined;
  // Local State
  const [locationId, setLocationId] = useState(activeLocationId);
  const [organizationId, setOrganizationId] = useState(activeOrgId || orgId);
  // Pagination
  const initialListConfig = {
    limit: 1000,
    page: 1,
    orgId: organizationId,
    quizId,
    ...(classId ? { classId } : {}),
    ...(quizId ? { quizId } : {}),
    ...(locationId ? { locationId } : {})
  };
  const [listConfig, setListConfig] = useState(initialListConfig);

  useEffect(() => {
    if (quizId) {
      dispatch(getQuiz({ quizId }));
    }

    if (classId) {
      dispatch(setCurrentClassQuizzes({ classId }));
    }
  }, [classId, dispatch, quizId]);

  useEffect(() => {
    dispatch(getQuizOutcomes(listConfig));
  }, [dispatch, listConfig]);

  useEffect(() => {
    if (orgIds) {
      dispatch(getSyndicatedCourseOrgs({ orgId, orgIds }));
    }
  }, [dispatch, orgId, orgIds]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCurrentQuiz());
      dispatch(resetCurrentClassQuizzes());
      dispatch(resetQuizOutcomes());
      dispatch(resetSyndicatedCourseOrgs());
    };
  }, [dispatch]);

  if (!currentQuiz.fetched && !currentQuiz.id) {
    return <Loading text="Loading Quiz..." />;
  }

  return (
    <>
      <OrgConsoleHeader
        pageTitle={title}
        iconComponent={() => <FaListOl size={20} />}
        className="my-3"
        rawRoute={routeWithClassId({
          route: CLASS_CONSOLE_QUIZZES,
          classId
        })}
      />

      <Tabs active="outcomes" />

      <div className="bg-white border-left border-right border-bottom shadow mb-3 p-3">
        <OrgConsoleSubheader className="mb-3">
          {syndicatedCourseOrgs.list &&
            course?.id &&
            userOrgIsCourseOrg({ course, currentUser }) && (
              <OrganizationSelect
                className="mr-0 mr-sm-2 flex-fill"
                defaultValue={organizationId}
                data={[
                  { name: orgName, id: orgId },
                  ...syndicatedCourseOrgs.list
                ]}
                onChange={({ value }) => {
                  setListConfig((prev) => ({
                    ...prev,
                    orgId: value,
                    locationId: null,
                    page: 1
                  }));
                  setOrganizationId(value);
                  setLocationId(null);
                  dispatch(setQuizOutcomesActiveOrgId(value, null));
                }}
              />
            )}

          {/* When locations include multiple orgs, follow syndicatedCourseOrgs aggregated patterns */}
          {locations.list &&
            Array.isArray(locations.list) &&
            locations.list.length !== 0 &&
            organizationId === orgId &&
            course.id && (
              <LocationSelect
                className="mr-0 mr-sm-2 flex-fill"
                defaultValue={locationId}
                onChange={({ selectedLocationId }) => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: 1,
                    orgId,
                    locationId: selectedLocationId
                  }));
                  setLocationId(selectedLocationId);

                  if (selectedLocationId === null) {
                    dispatch(
                      setQuizOutcomesActiveOrgId(
                        activeOrgId,
                        selectedLocationId
                      )
                    );
                  } else {
                    dispatch(
                      setQuizOutcomesActiveLocationId(selectedLocationId)
                    );
                  }
                }}
                data={locations.list}
              />
            )}

          <button
            className="btn btn-sm btn-outline-primary text-nowrap"
            onClick={() => {
              exportData({
                orgName,
                course,
                currentQuiz,
                quizOutcomes,
                syndicatedCourseOrgs,
                syndicatedCourseOrgId: organizationId
              });
            }}
            title="Export Data"
            type="button"
            disabled={!canExportUserData(role, orgType)}
          >
            <span className="d-flex align-items-center">
              <FaDownload />
              <span className="ml-2">Export</span>
            </span>
          </button>
        </OrgConsoleSubheader>

        <QuizOutcomesList
          className="border"
          list={quizOutcomes?.list}
          quizId={quizId}
          userId={userId}
          showUserNameAsLink={!userId}
          showQuizTitleAsLink={!!userId}
          showOutcomeButton
        />

        {Array.isArray(quizOutcomes?.list) && quizOutcomes.list.length > 0 && (
          <PaginationSupabase
            items={quizOutcomes?.list}
            pagination={quizOutcomes?.pagination}
            page={quizOutcomes?.pagination?.page}
            onClickNext={() => {
              setListConfig((prev) => ({
                ...prev,
                page: quizOutcomes?.pagination?.page + 1
              }));
              // goToElem('root');
            }}
            onClickPrev={() => {
              setListConfig((prev) => ({
                ...prev,
                page: quizOutcomes?.pagination?.page - 1
              }));
              // goToElem('root');
            }}
          />
        )}
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withConsole)(Quiz);
