import React, { useEffect, useMemo, useState } from 'react';
// import UserRolesCell from '../../../ManageContent/Tables/UserRolesCell';
import reactTableSortCaseInsensitive from '../../../../utils/reactTableSortCaseInsensitive';
import { fetchResourceGroups } from '../../../../services/resourceGroups';
import Table from './Table';
import TitleCell from './TitleCell';
import TagsCell from './TagsCell';
import EditCell from './EditCell';
import DateCell from '../../../ManageContent/Tables/DateCell';
import FolderCell from '../../../ManageContent/Tables/FolderCell';
import SelectBtnCell from './SelectBtnCell';

const List = ({
  data,
  editItem,
  uploadFiles,
  orgId,
  groupIds,
  groupTags,
  editMenu = false,
  hideTagsCol,
  hideUpdatedDateCol,
  onClick,
  onSelect,
  children,
  limit = 200
}) => {
  const [folders, setFolders] = useState([]);
  const [foldersLoading, setFoldersLoading] = useState(false);
  // Resources
  const parentIds = useMemo(() => {
    return Array.isArray(data) ? data.map((item) => item?.parentId) : [];
  }, [data]);
  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!onSelect) {
      cols.push('selectBtnCol');
    }

    if (hideTagsCol) {
      cols.push('groupTags');
    }

    if (hideUpdatedDateCol) {
      cols.push('updatedAt');
    }

    if (!editMenu) {
      cols.push('id');
    }

    if (!Array.isArray(groupTags)) {
      cols.push('parentId');
    }

    return cols;
  }, [editMenu, groupTags, hideTagsCol, hideUpdatedDateCol, onSelect]);

  useEffect(() => {
    if (!hiddenColumns.includes('parentId')) {
      setFoldersLoading(true);
      fetchResourceGroups({
        ids: parentIds,
        orgId,
        select: ['id', 'title', 'parentId'],
        limit
      }).then((response) => {
        const parentsHash = {};

        if (Array.isArray(response?.items) && response?.items.length !== 0) {
          response?.items.forEach((item) => {
            if (parentsHash[item?.id] === undefined) {
              parentsHash[item?.id] = item;
            }
          });
        }

        setFolders(parentsHash);
        setFoldersLoading(false);
      });
    }
  }, [groupTags, hiddenColumns, limit, orgId, parentIds]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
        sortType: reactTableSortCaseInsensitive,
        Cell: (props) => (
          <TitleCell
            orgId={orgId}
            groupIds={groupIds}
            onClick={onClick}
            {...props}
          />
        )
      },
      {
        Header: 'Tags',
        accessor: 'groupTags',
        disableSortBy: true,
        Cell: (props) => (
          <TagsCell
            orgId={orgId}
            groupIds={groupIds}
            {...props}
          />
        )
      },
      // {
      //   Header: 'Access',
      //   accessor: 'userRoles',
      //   disableSortBy: true,
      //   Cell: (props) => (
      //     <UserRolesCell {...props} />
      //   )
      // },
      {
        Header: 'Folder',
        accessor: 'parentId',
        disableSortBy: true,
        Cell: (props) => (
          <FolderCell
            {...props}
            folders={folders}
            foldersLoading={foldersLoading}
          />
        )
      },
      {
        Header: 'Last modified',
        accessor: 'updatedAt',
        Cell: DateCell
      },
      {
        Header: '',
        accessor: 'selectBtnCol',
        onSelect,
        disableSortBy: true,
        Cell: SelectBtnCell
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: (props) => (
          <EditCell
            orgId={orgId}
            onClickEdit={editItem}
            onClickUploadFiles={uploadFiles}
            editMenu={editMenu}
            {...props}
          />
        )
      }
    ],
    [
      editItem,
      editMenu,
      folders,
      foldersLoading,
      groupIds,
      onClick,
      onSelect,
      orgId,
      uploadFiles
    ]
  );

  if (!data || (data && data.length === 0)) {
    return null;
  }

  return (
    <div data-cy="ResourcesList">
      <Table
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
      />

      {children || null}
    </div>
  );
};

export default List;
