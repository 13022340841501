import React from 'react';

const Badge = ({ children, className, type }) => {
  return (
    <span className={className || 'h5'}>
      <span className={`badge badge-${type || 'secondary'}`}>{children}</span>
    </span>
  );
};

export default Badge;
