import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CLASS_CONSOLE_CHALLENGES_SUBMISSIONS,
  ORG_CONSOLE_CHALLENGES
} from '../../../../constants/routes';
import { routeWithClassId } from '../../../../services/courses';
import { routeWithOrgId } from '../../../../services/organizations';

const Breadcrumb = ({ children, className }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const organization = useSelector((state) => state.organization);
  // Params
  const classId = queryParams.get('classId');
  if (!organization.id) {
    return null;
  }

  const { id: orgId } = organization;

  return (
    <nav
      className={`mt-3 breadcrumb d-flex align-items-center justify-content-between ${className}`}
      aria-label="breadcrumb"
    >
      <ol className="breadcrumb m-0 p-0">
        <li className="breadcrumb-item">
          {classId ? (
            <Link
              to={routeWithClassId({
                route: CLASS_CONSOLE_CHALLENGES_SUBMISSIONS,
                classId
              })}
              title="View all Course Submissions"
            >
              <i className="fas fa-arrow-circle-left mr-1" /> Course Submissions
            </Link>
          ) : (
            <Link
              to={routeWithOrgId({
                route: ORG_CONSOLE_CHALLENGES,
                orgId
              })}
              title="View all Submissions"
            >
              <i className="fas fa-arrow-circle-left mr-1" /> All Submissions
            </Link>
          )}
        </li>
      </ol>

      {children && <div>{children}</div>}
    </nav>
  );
};

export default Breadcrumb;
