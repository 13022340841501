import {
  RESET_APPRENTICE_AGREEMENTS,
  SET_APPRENTICE_AGREEMENTS
} from '../../actions/types';

const initialState = {
  fetched: false,
  list: null
};

export default function employerAgreements(state = initialState, action) {
  switch (action.type) {
    case SET_APPRENTICE_AGREEMENTS:
      return {
        ...state,
        fetched: true,
        list: action.list
      };
    case RESET_APPRENTICE_AGREEMENTS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
