import {
  API_EMBED_MATERIAL,
  OPEN_API_CREATE_COMPLETION,
  OPEN_API_SUMMARIZE_TEXT,
  OPEN_API_VISION,
  API_LLM_COMPLETIONS_CHAT_ENGINE,
  SUPABASE_EMBED_DOC,
  SUPABASE_QUERY_ENGINE,
  SUPABASE_VECTOR_SEARCH,
  API_LLM_COMPLETIONS_CREATE
} from '../constants/api';
import * as twApi from './tw-api';

export const createCompletion = ({ orgId, systemPrompt, prompt }) => {
  return new Promise((resolve, reject) => {
    const data = {
      prompt,
      orgId,
      ...(systemPrompt ? { systemPrompt } : {})
    };

    return twApi
      .post(API_LLM_COMPLETIONS_CREATE, '', JSON.stringify(data))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * @summary creates pdf from template & JSON
 * @param {object} prompt // json
 *
 */
export const legacyCreateCompletion = ({ prompt }) => {
  return new Promise((resolve, reject) => {
    const data = {
      prompt
    };

    return twApi
      .post(OPEN_API_CREATE_COMPLETION, '', JSON.stringify(data))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const embedMaterial = ({
  orgId = '',
  materialId = '',
  resourceId = ''
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      materialId,
      resourceId,
      orgId
    };

    return twApi
      .post(API_EMBED_MATERIAL, '', JSON.stringify(data))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * @summary creates embedding of a single file
 * @param {object} prompt // json
 * @note see: "summarizeDocTypes" for supported file types
 *
 */
export const embedDoc = ({ refTable = '', orgId = '', id = '', url = '' }) => {
  return new Promise((resolve, reject) => {
    const data = {
      files: [{ [id]: url }],
      refTable,
      orgId
    };

    return twApi
      .post(SUPABASE_EMBED_DOC, '', JSON.stringify(data))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * @summary search similar emeddings with prompt
 */
export const vectorSearch = ({
  refIds = null,
  orgId = '',
  locationId = '',
  userId = '',
  parentIds = null,
  contentTypes = null,
  parentType = 'resourceGroup', // TODO change to resourceGroups
  saveSearch = false,
  prompt = '',
  searchText = '',
  threshold = 0.3,
  count = 20
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      orgId,
      locationId,
      userId,
      contentTypes,
      saveSearch,
      threshold,
      count,
      parentType
    };

    if (prompt) {
      data.prompt = prompt;
    }

    if (searchText) {
      data.searchText = searchText;
    }

    if (refIds) {
      data.refIds = refIds;
    }

    if (parentIds) {
      data.parentIds = parentIds;
    }

    return twApi
      .get(SUPABASE_VECTOR_SEARCH, data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * @summary search similar emeddings with summary or Q&A
 */
export const queryEngine = ({
  prompt = '',
  promptType = 'explanation',
  orgId = '',
  refId = '',
  bodyMaxChars = undefined
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      ...(prompt ? { prompt } : {}),
      ...(bodyMaxChars ? { bodyMaxChars } : {}),
      promptType,
      refId,
      orgId
    };

    return twApi
      .get(SUPABASE_QUERY_ENGINE, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * @summary search similar emeddings with summary or Q&A
 */
export const chatEngine = async ({
  prompt = '',
  orgId = '',
  refId = '',
  resourceVecIds = undefined,
  promptType,
  includeCitations = false,
  model,
  searchId
}) => {
  const streaming = true;
  const body = {
    ...(prompt ? { prompt } : {}),
    ...(searchId ? { searchId } : {}),
    ...(refId ? { refId } : {}),
    ...(resourceVecIds ? { resourceVecIds } : {}),
    ...(promptType ? { promptType } : {}),
    ...(model ? { model } : {}),
    includeCitations,
    orgId
  };

  try {
    const response = await twApi.post(
      API_LLM_COMPLETIONS_CHAT_ENGINE,
      '',
      JSON.stringify(body),
      streaming
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * @summary creates pdf from template & JSON
 * @param {object} prompt // json
 * @note see: "summarizeDocTypes" for supported file types
 *
 */
export const summarizeText = ({ topic, rawText, maxCharCount }) => {
  return new Promise((resolve, reject) => {
    const data = {
      ...(topic ? { topic } : {}),
      rawText,
      maxCharCount
    };

    return twApi
      .post(OPEN_API_SUMMARIZE_TEXT, '', JSON.stringify(data))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const summarizeImages = ({ prompt = '', orgId = '', urls }) => {
  return new Promise((resolve, reject) => {
    const data = {
      ...(prompt ? { prompt } : {}),
      orgId,
      urls
    };

    return twApi
      .post(OPEN_API_VISION, '', JSON.stringify(data))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const vectorSearchDocTypes = [
  'material',
  'materials',
  'text/csv',
  // 'application/vnd.ms-excel', // EXCEL (.xls)
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // EXCEL (.xlsx)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // WORD (.docx)
  // 'application/msword', // WORD (.doc)
  'application/pdf'
];

export const summarizeDocTypes = [
  'image/jpeg',
  'image/png',
  'text/csv',
  'application/json',
  'application/x-gzip', // .gz
  'application/vnd.ms-excel', // EXCEL (.xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // EXCEL (.xlsx)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // WORD (.docx)
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPT
  'application/vnd.ms-powerpoint', // PPT
  'application/msword', // WORD (.doc)
  'application/pdf'
];
