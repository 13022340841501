import { SET_PAGE_LINK, RESET_PAGE_LINK } from '../../actions/types';
import pageLinkState from '../initialState/pageLinkState';

const initialState = {
  ...pageLinkState,
  fetched: false
};

export default function pageLink(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_LINK:
      return {
        ...state,
        ...action.pageLink,
        fetched: true
      };
    case RESET_PAGE_LINK:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
