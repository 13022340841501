import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaFolder } from 'react-icons/fa';
import { ORG_CONSOLE_RESOURCES } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import shortenString from '../../../utils/shortenString';

const FolderCell = ({ value: parentId, row }) => {
  const organization = useSelector((state) => state.organization);
  const search = useSelector((state) => state.search);
  // Organization
  const orgId = organization?.id || '';
  // Resource
  const parentIds = Array.isArray(row?.original?.parentIds)
    ? row?.original?.parentIds
    : [];
  const folders = useMemo(() => {
    return search.resourceGroupsHash || {};
  }, [search.resourceGroupsHash]);

  const groupIds = [
    ...(parentId ? parentIds : [])
    // row?.original?.id
  ];

  const routeConfig = {
    route: ORG_CONSOLE_RESOURCES,
    urlParams: {
      orgId,
      ...(parentId ? { groupIds: groupIds?.join(',') } : {})
    }
  };

  const folderText = useMemo(() => {
    let text = '. . . . .';

    text =
      folders[parentId] && folders[parentId]?.title
        ? folders[parentId]?.title
        : parentId;

    return text;
  }, [folders, parentId]);

  if (!parentId) {
    return null;
  }

  return (
    <div style={{ fontSize: '.65rem', minWidth: '170px' }}>
      <Link
        title={folderText}
        style={{ fontSize: '.65rem' }}
        className="badge badge-pill badge-light border p-2 mr-1 mb-1 mr-1 mb-1 text-nowrap py-1"
        to={routeWithOrgId(routeConfig)}
      >
        <span className="d-flex align-items-center">
          <FaFolder className="text-primary" />
          <span className="ml-1">{shortenString(folderText, 25)}</span>
        </span>
      </Link>
    </div>
  );
};

export default FolderCell;
