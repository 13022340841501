import React, { useEffect } from 'react';
import { FaLaptopCode } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses, resetCourses } from '../../../actions/Courses';
import { TURBINE } from '../../../constants/urls';
import CourseImageColor from '../../CourseImageColor';

const LearningModulesCard = ({
  orgId,
  classIds = [],
  className = '',
  skipUnmount = false
}) => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const coursesList = courses?.list || [];

  useEffect(() => {
    if (classIds.length > 0) {
      dispatch(getCourses({ orgId, courseIds: classIds }));
    }
  }, [classIds, dispatch, orgId]);

  useEffect(() => {
    return () => {
      if (!skipUnmount) {
        dispatch(resetCourses());
      }
    };
  }, [dispatch, skipUnmount]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header text-center">
        <span className="d-flex justify-content-start align-items-center">
          <FaLaptopCode size={18} />
          <span className="h6 m-0 ml-2 font-weight-bold">Courses</span>
        </span>
      </div>
      <ul className="list-group list-group-flush">
        {Array.isArray(coursesList) &&
          coursesList?.map((classItem) => {
            // eslint-disable-next-line prefer-template
            const courseUrl = TURBINE + '/classes/' + classItem?.id;

            return (
              <li
                className="list-group-item row-clickable"
                key={classItem?.id}
                aria-hidden
                onClick={() => {
                  window.location = courseUrl;
                }}
              >
                <div className="d-flex align-items-center font-weight-bold">
                  <CourseImageColor
                    title={classItem?.title}
                    classColor={classItem?.classColor || null}
                    classImage={classItem?.classImage || null}
                    height={50}
                    width={80}
                    className="mr-2"
                  />
                  <div>{classItem?.title}</div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LearningModulesCard;
