import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import DocumentsList from '@apprentage/components/dist/components/DocumentsList';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  getApprenticeshipUser,
  resetApprenticeship,
  resetApprenticeshipUser
} from '../../../../../actions/Apprenticeships';
import {
  getApprenticeAgreements,
  resetApprenticeAgreement,
  resetApprenticeAgreements
} from '../../../../../actions/ApprenticeAgreements';
import {
  ICON_TRAINING_PROGRAMS,
  IMAGE_NO_DOCUMENTS
} from '../../../../../constants/assets';
import { setCurrentModal } from '../../../../../actions/Modals';
import { generateDocumentLink } from '../../../../../services/consoleDocuments';
// import { generatePdfFromUrlLink } from '../../../../../services/createPdf';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import UserOrgConsoleSubheader from './UserOrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import UserTabs from './UserTabs';

const ApprenticeDocuments = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeAgreements = useSelector(
    (state) => state.apprenticeAgreements
  );
  // Organization
  const orgId = organization?.id || null;
  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipUserId = params?.apprenticeshipUserId || null;
  // Employer

  // Employer Profile
  const employerProfileId = employerProfile?.id || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, [apprenticeshipId, dispatch]);

  useEffect(() => {
    if (apprenticeshipUserId) {
      dispatch(
        getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId, orgId)
      ).then((response) => {
        if (response?.userProfileId && apprenticeshipId) {
          dispatch(
            getApprenticeAgreements({
              userProfileId: response?.userProfileId,
              userId: apprenticeshipUserId,
              apprenticeshipId
            })
          );
        }
      });
    }

    return function cleanup() {
      const href = window?.location?.href || '';

      // Only reset if not going to apprentice user route
      if (
        !href.includes(`${apprenticeshipRoute}/users/${apprenticeshipUserId}`)
      ) {
        dispatch(resetApprenticeshipUser());
        dispatch(resetApprenticeAgreement());
        dispatch(resetApprenticeAgreements());
      }

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [
    apprenticeshipId,
    apprenticeshipRoute,
    apprenticeshipUserId,
    dispatch,
    orgId
  ]);

  if (apprenticeshipId && !apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  if (apprenticeshipUserId && !apprenticeship?.user?.id) {
    return <Loading text="Loading Employer..." />;
  }

  if (employerProfileId && !employerProfile.id) {
    return <Loading text="Loading Workforce Info..." />;
  }

  const documentLink = (collection, id, accessCode) => {
    return generateDocumentLink({
      collection,
      id,
      accessCode,
      preview: true
    });
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="docs" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <UserOrgConsoleSubheader />

            <UserTabs active="docs" />

            <div className="border-left border-right border-bottom">
              {apprenticeAgreements.list == null ||
              apprenticeAgreements.list?.length === 0 ? (
                <div className="p-3 d-flex flex-column align-items-center justify-content-center">
                  <div>
                    <img
                      src={IMAGE_NO_DOCUMENTS}
                      alt="No Documents"
                      width="65"
                      className="my-3"
                    />
                  </div>
                  <div>No Documents</div>
                </div>
              ) : (
                <DocumentsList
                  tableData={apprenticeAgreements.list ?? []}
                  handlePreview={(item) => {
                    dispatch(
                      setCurrentModal({
                        key: 'fullScreenIframe',
                        data: {
                          modalTitle: item.original.name,
                          iframeSrc: documentLink(
                            'apprenticeAgreements',
                            item.original.id,
                            item.original.accessCode
                          )
                        }
                      })
                    );
                  }}
                  // handleDownload={(item) => {
                  //   const link = generatePdfFromUrlLink({
                  //     url: documentLink('apprenticeAgreements', item.original.id, item.original.accessCode),
                  //     name: item.original.name
                  //   });
                  //   toast.info('Generating PDF...');
                  //   setTimeout(() => {
                  //     window.open(link);
                  //   }, 500);
                  // }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeDocuments);
