import React from 'react';
import {
  quizFeedback,
  quizTotalPoints,
  quizTotalScore
} from '../../../services/contentFlow/quizzes';
import { mdyDate } from '../../../utils/date';

const Outcome = ({ data, className, prevOutcome }) => {
  if (!data) {
    return null;
  }

  const { createdAt, score, maxScore, difficulty, attempts, isPractice, key } =
    data;

  return (
    <tr
      key={key}
      className={className || ''}
    >
      <td className="border-right text-center">{mdyDate(createdAt)}</td>
      <td className="border-right text-center">
        {score}/{maxScore}
      </td>
      <td className="border-right text-center">
        {quizTotalScore({ score, maxScore })}
      </td>
      <td className="border-right text-center">{attempts || '-'}</td>
      <td className="border-right text-center">
        {prevOutcome
          ? '-'
          : quizTotalPoints({
              score,
              maxScore,
              difficulty,
              attempts
            })}
      </td>
      <td
        className="border-right"
        colSpan="1"
      >
        {quizFeedback({
          score,
          maxScore,
          iconOnly: false,
          isPractice
        })}
      </td>
      {/* <td className="border-right">
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            showOutcomeResult(outcomeId);
          }}
        >
          Review
        </button>
      </td> */}
    </tr>
  );
};

export default Outcome;
