import React, { useMemo, useState } from 'react';
import { FaCloudUploadAlt, FaFan } from 'react-icons/fa';
import { percentComplete } from '@apprentage/utils';
import ProgressBars from '../../ProgressBars';

const ProcessDoc = ({ onClick, loading }) => {
  // First Bar
  const [bar1ValueNow] = useState(100);
  const [bar2ValueNow] = useState(75);
  const [bar3ValueNow] = useState(25);
  // Hours In Review

  const bar1Percent = useMemo(() => {
    return percentComplete({
      current: bar1ValueNow,
      total: 100
    });
  }, [bar1ValueNow]);

  const bar2Percent = useMemo(() => {
    return percentComplete({
      current: bar2ValueNow,
      total: 100
    });
  }, [bar2ValueNow]);

  const bar3Percent = useMemo(() => {
    return percentComplete({
      current: bar3ValueNow,
      total: 100
    });
  }, [bar3ValueNow]);

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <FaCloudUploadAlt size={30} />
        <div className="ml-2">
          <div>Upload a document</div>
          <h1 className="h6 m-0 font-weight-bold text-purple-light">
            Capturing Knowledge
          </h1>
        </div>
      </div>

      <p className="font-weight-bold text-dark">
        Our robots are hard at work creating your first learning module from
        this document.
      </p>

      <div className="bg-light p-4 mb-3 rounded">
        <div className="mb-2">
          <div className="mb-2 font-weight-bold">Extracting Contents</div>
          <div className="bg-white p-3 border border-dashed border-2 rounded">
            <ProgressBars
              id="LogbookProgressBars"
              size="sm"
              style={{
                backgroundColor: 'rgb(98 98 98)'
              }}
              data={[
                {
                  className:
                    bar1ValueNow === 100
                      ? 'bg-success'
                      : 'progress-bar-striped progress-bar-animated bg-primary ',
                  dataTip: 'Approved Hours',
                  valueNow: bar1ValueNow,
                  style: {
                    width:
                      bar1ValueNow !== 0 && parseFloat(bar1Percent) < 10
                        ? '12%'
                        : bar1Percent
                  },
                  text: ''
                }
              ]}
            />
          </div>
        </div>

        <div className="mb-2">
          <div className="mb-2 font-weight-bold">
            Capturing Domain Expertise
          </div>
          <div className="bg-white p-3 border border-dashed border-2 rounded">
            <ProgressBars
              id="LogbookProgressBars"
              size="sm"
              style={{
                backgroundColor: 'rgb(98 98 98)'
              }}
              data={[
                {
                  className:
                    bar2ValueNow === 100
                      ? 'bg-success'
                      : 'progress-bar-striped progress-bar-animated bg-primary ',
                  dataTip: 'Approved Hours',
                  valueNow: bar2ValueNow,
                  style: {
                    width:
                      bar2ValueNow !== 0 && parseFloat(bar2Percent) < 10
                        ? '12%'
                        : bar2Percent
                  },
                  text: ''
                }
              ]}
            />
          </div>
        </div>

        <div className="mb-2">
          <div className="mb-2 font-weight-bold">Creating Learning Module</div>
          <div className="bg-white p-3 border border-dashed border-2 rounded">
            <ProgressBars
              id="LogbookProgressBars"
              size="sm"
              style={{
                backgroundColor: 'rgb(98 98 98)'
              }}
              data={[
                {
                  className:
                    bar3ValueNow === 100
                      ? 'bg-success'
                      : 'progress-bar-striped progress-bar-animated bg-primary ',
                  dataTip: 'Approved Hours',
                  valueNow: bar3ValueNow,
                  style: {
                    width:
                      bar3ValueNow !== 0 && parseFloat(bar3Percent) < 10
                        ? '12%'
                        : bar3Percent
                  },
                  text: ''
                }
              ]}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <p className="font-weight-bold mb-4 text-center">
          We're doing some heavy lifting, this will take a minute...
        </p>
      ) : (
        <p className="font-weight-bold mb-4 text-center">
          All done, hit continue to preview your Learning Module.
        </p>
      )}

      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          disabled={loading}
          className="btn bg-purple-light btn-md text-white"
          onClick={onClick}
        >
          <span className="d-flex align-items-center">
            {loading ? <FaFan className="fa-spin" /> : <FaCloudUploadAlt />}
            <span className="ml-2">
              {loading ? 'Processing...' : 'Preview Learning Module'}
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default ProcessDoc;
