import React from 'react';
import { FaCog, FaEllipsisH } from 'react-icons/fa';

const EditCell = ({
  value: id,
  row,
  onClickEdit,
  // onClickUploadFiles,
  editMenu
}) => {
  const title = row?.original?.title || '';

  if (!editMenu) return null;

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH size={15} />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6 className="m-0 text-nowrap">{title}</h6>
          </div>
          {/* <button
            className="dropdown-item py-2 border-bottom"
            type="button"
            title={`Upload files in ${title}`}
            onClick={() => {
              onClickUploadFiles(id);
            }}
          >
            <i className={`${UPLOAD} mr-1`} /> Upload files
          </button> */}
          <button
            className="dropdown-item py-2 d-flex align-items-center"
            type="button"
            title={`Edit ${title}`}
            onClick={() => {
              onClickEdit(id);
            }}
          >
            <FaCog size={15} />
            <span className="ml-1">Settings</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCell;
