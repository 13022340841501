export const DASHBOARD_DEFAULT_LAYOUT = 'layout1';

export const DASHBOARD_DEFAULT_ZONES = {
  zone1: [],
  zone2: [],
  zone3: []
};

export const LAYOUTS = {
  layout1: [6, 6, 12],
  layout2: [12, 6, 6]
};

export const DASHBOARD_MODULE_TYPE = {
  projects: 'PROJECT',
  notifications: 'NOTIFICATIONS',
  embeds: 'EMBED_WIDGETS_LIST',
  links: 'LINK_WIDGETS_LIST',
  material: 'MATERIAL',
  materialsList: 'MATERIALS_LIST'
};

export const DASHBOARD_MODULES = {
  PROJECT: 'Projects',
  NOTIFICATIONS: 'Notifications',
  EMBED_WIDGETS_LIST: 'Media',
  LINK_WIDGETS_LIST: 'Links',
  MATERIAL: 'Rich Text',
  MATERIALS_LIST: 'Materials'
};
