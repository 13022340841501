import { DASHBOARD_DEFAULT_LAYOUT } from '../../constants/dashboard';

const dashboardState = {
  id: null,
  orgId: null,
  activeLayout: DASHBOARD_DEFAULT_LAYOUT,
  type: null,
  createdAt: null,
  updatedAt: null,
  zone1: [],
  zone2: [],
  zone3: [],
  zone4: [],
  zone5: [],
  zone6: []
};

export default dashboardState;
