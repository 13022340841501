import {
  RESET_CONTEXT_QUESTION,
  RESET_CONTEXT_QUESTIONS,
  SET_CONTEXT_QUESTION,
  SET_CONTEXT_QUESTIONS,
  SET_ENTRY_ORG_ID
} from './types';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { getResourcesByParentId } from './Resources';
import {
  fetchContextQuestion,
  fetchContextQuestions
} from '../services/contextQuestions';

const table = 'contextQuestions';

export const getContextQuestions = ({
  orgId,
  locationIds,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt'
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchContextQuestions({
        orgId,
        locationIds,
        page,
        limit,
        order
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_CONTEXT_QUESTIONS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getContextQuestion = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchContextQuestion(id)
        .then((contextQuestion) => {
          if (!contextQuestion?.id) {
            reject(new Error(`No ${table} found (id)`));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: contextQuestion?.orgId || null
          });

          dispatch({
            type: SET_CONTEXT_QUESTION,
            contextQuestion: contextQuestion?.id
              ? { ...contextQuestion, cdnFiles: null }
              : null
          });
          dispatch(getResourcesByParentId(contextQuestion?.id, table));
          resolve(contextQuestion);
        })
        .catch((error) => {
          console.error(`get ${table}`, error);
          reject(error);
        });
    });
  };
};

export const resetContextQuestion = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CONTEXT_QUESTION });
  };
};

export const resetContextQuestions = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CONTEXT_QUESTIONS });
  };
};
