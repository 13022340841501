import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inviteTypeEnum } from '@apprentage/constants';
import { FaEllipsisH, FaPaperPlane } from 'react-icons/fa';
import { canEditLocation } from '../../services/currentUser';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_USER_INVITATION } from '../../constants/modals';

const DotMenuInvitesBlock = ({
  className = '',
  data // employer || orgLocation
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const orgType = organization?.type || '';
  const role = currentUser?.role || [];
  const locationId = data?.id || '';
  const manageLocation = canEditLocation(role);

  /**
   * Remove User Invite Options
   *
   * User Registration
   * Apprentice Signature
   */

  const { registration, apprenticeAgreementSignerApprentice, ...inviteTypes } =
    inviteTypeEnum;

  const openUserInvitationModal = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_USER_INVITATION,
        data: {
          modalTitle: 'New Request',
          locationId,
          singleUserInvite: true,
          includeName: true,
          includeTitle: true,
          inviteTypes
        }
      })
    );
  };

  return (
    <div className={className}>
      <button
        className="btn btn-sm btn-white"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap" />

        {manageLocation && orgType === 'community' && (
          <button
            type="button"
            className="dropdown-item py-2 px-3"
            onClick={openUserInvitationModal}
          >
            <span className="d-flex align-items-center">
              <FaPaperPlane />
              <span className="ml-2">New Request</span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DotMenuInvitesBlock;
