import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useExpanded } from 'react-table'; // useFilters

function Table({
  columns,
  data,
  hiddenColumns = [],
  sortByAccessorId = 'name',
  sortByAccessorDesc = false,
  renderRowSubComponent
}) {
  const initialState = {
    sortBy: [
      {
        id: sortByAccessorId,
        desc: sortByAccessorDesc
      }
    ],
    hiddenColumns
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns
    // state: { expanded }
  } = useTable(
    {
      columns,
      initialState,
      data
    },
    useSortBy,
    useExpanded
  );

  // console.log('expanded', expanded);

  return (
    <table
      className="table table-striped table-border-top-0 m-0"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                style={{ verticalAlign: 'center' }}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="text-nowrap"
              >
                {column.render('Header')}
                {column.canSort && (
                  <span className="ml-1">
                    {column.isSorted ? (
                      <>
                        {column.isSortedDesc ? (
                          <i className="fas fa-sort-down" />
                        ) : (
                          <i className="fas fa-sort-up" />
                        )}
                      </>
                    ) : (
                      <i
                        className="fas fa-sort"
                        style={{ opacity: '.25' }}
                      />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Fragment key={`CdnFiles-tr-container-${row?.id}`}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
              {/*
                If the row is in an expanded state, render a row with a
                column that fills the entire length of the table.
              */}
              {renderRowSubComponent && row.isExpanded && (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
