import { getClient, flattenItem, flattenItems } from '../services/contentful';
import { setCurrentModal } from './Modals';
import {
  SET_CURRENT_ANSWER,
  RESET_CURRENT_ANSWER,
  SET_CURRENT_ANSWERS,
  RESET_CURRENT_ANSWERS,
  SET_CURRENT_CLASS_ANSWERS,
  RESET_CURRENT_CLASS_ANSWERS,
  SET_CURRENT_TOPIC_ANSWERS,
  RESET_CURRENT_TOPIC_ANSWERS
} from './types';
import { MODAL_KEY_MANAGE_SUBMISSIONS } from '../constants/modals';

export const manageSubmissions = (challengeId) => {
  return (dispatch) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_SUBMISSIONS,
        data: {
          challengeId
        }
      })
    );
  };
};

export const setCurrentAnswer = (answerId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return getClient()
        .getEntry(answerId)
        .then((response) => {
          const currentAnswer = response ? flattenItem(response) : null;
          const cdnFiles = currentAnswer?.integration?.cdnFiles || null;

          dispatch({
            type: SET_CURRENT_ANSWER,
            currentAnswer,
            cdnFiles
          });

          resolve(currentAnswer);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetCurrentAnswer = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ANSWER });
  };
};

export const setCurrentAnswers = ({
  status,
  skip,
  limit,
  userId,
  challengeId,
  classId,
  orgId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'answer',
        order: '-sys.createdAt'
      };

      if (status) {
        if (status === 'all') {
          config['fields.status[exists]'] = true;
        } else if (status === 'acceptedAndChanges') {
          config['fields.status[nin]'] = 'inReview,rejected';
        } else {
          config['fields.status'] = status;
        }
      }

      if (challengeId) {
        config['fields.challengeId'] = challengeId;
      }

      if (skip) {
        config.skip = skip;
      }

      if (limit) {
        config.limit = limit;
      }

      if (userId) {
        config['fields.userId'] = userId;
      }

      if (orgId && !userId) {
        // TODO may not be necessary to check !userId
        config['fields.orgId'] = orgId;
      }

      if (classId) {
        config['fields.classId'] = classId;
      }

      return getClient()
        .getEntries(config)
        .then(({ items, limit: entriesLimit, skip: entriesSkip, total }) => {
          const currentAnswers = items.length ? flattenItems(items) : null;
          const pagination = {
            status,
            limit: entriesLimit,
            skip: entriesSkip,
            total
          };

          dispatch({
            type: SET_CURRENT_ANSWERS,
            currentAnswers,
            pagination
          });
          resolve({ currentAnswers, pagination });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const setCurrentClassAnswers = (classId, userId) => {
  return (dispatch) => {
    const config = {
      content_type: 'answer',
      'fields.classId': classId
    };

    if (userId) {
      config['fields.userId'] = userId;
    }

    return getClient()
      .getEntries(config)
      .then(({ items }) => {
        const currentClassAnswers = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_CLASS_ANSWERS,
          currentClassAnswers
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const setCurrentTopicAnswers = (topicId, userId) => {
  return (dispatch) => {
    const config = {
      content_type: 'answer',
      'fields.topicId': topicId
    };

    if (userId) {
      config['fields.userId'] = userId;
    }

    return getClient()
      .getEntries(config)
      .then(({ items }) => {
        const currentTopicAnswers = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_TOPIC_ANSWERS,
          currentTopicAnswers
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const resetCurrentAnswers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ANSWERS });
  };
};

export const resetCurrentClassAnswers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_ANSWERS });
  };
};

export const resetCurrentTopicAnswers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC_ANSWERS });
  };
};
