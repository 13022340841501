import React, { useRef, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

const SearchBox = ({
  className = '',
  onSubmit,
  name = 'searchValueInput',
  value,
  loading = false,
  placeholder = 'Search...',
  autoFocus = false
}) => {
  const searchBoxFormBtn = useRef(null);
  const [searchInputValue, setSearchInputValue] = useState(value || '');

  const onFocus = (e) => {
    const element = e.currentTarget;

    element.style.height = '1px';
    element.style.height = `${25 + element.scrollHeight}px`;

    setSearchInputValue(element.value);
  };

  const onChange = (e) => {
    const element = e.currentTarget;

    element.style.height = '1px';
    element.style.height = `${25 + element.scrollHeight}px`;

    setSearchInputValue(element.value);
  };

  const onKeyUp = (e) => {
    const element = e.currentTarget;

    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      const newValue = element.value.trim();
      setSearchInputValue(newValue);

      if (newValue && !loading) {
        searchBoxFormBtn.current.click();
      }
    }
  };

  return (
    <>
      <div className={`${className} bg-white`}>
        <form onSubmit={onSubmit}>
          <div className="d-flex">
            <div className="position-relative w-100">
              <textarea
                type="text"
                className="form-control form-control-lg w-100"
                name={name}
                value={searchInputValue}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onFocus={onFocus}
                placeholder={placeholder}
                autoFocus={autoFocus}
                style={{
                  paddingRight: '3.5rem',
                  height: '71px'
                }}
              />

              <button
                ref={searchBoxFormBtn}
                type="submit"
                className={`btn btn-lg btn-white p-2 text-primary ${searchInputValue ? 'd-flex' : 'd-none'} align-items-center justify-content-center position-absolute`}
                disabled={loading}
                aria-label="Submit"
                style={{
                  borderRadius: '100%',
                  border: 0,
                  bottom: '10px',
                  right: '10px',
                  width: '50px',
                  height: '50px'
                }}
                // data-tooltip-content="Submit"
                // data-tooltip-id="SearchBox"
                data-tip="Submit"
                data-for="SearchBox"
              >
                <FaPaperPlane size={25} />
              </button>
            </div>
          </div>
        </form>
      </div>
      <ReactTooltip
        id="SearchBox"
        place="bottom"
      />
    </>
  );
};

export default SearchBox;
