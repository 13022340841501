import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { deleteIntegration } from '../../../services/integrations';
import { canDeleteEntry } from '../../../services/currentUser';
import { ORG_CONSOLE_ASSISTANTS } from '../../../constants/routes';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';

const DeleteAssistant = ({
  className = '',
  contentId,
  name,
  contentType = 'assistant',
  buttonText = 'Delete Assistant'
}) => {
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const assistant = useSelector((state) => state.assistant);
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [contentId]);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteEntry(role)) {
      return false;
    }

    if (Array.isArray(assistant.cdnFiles) && assistant.cdnFiles.length !== 0) {
      return false;
    }

    return true;
  }, [assistant.cdnFiles, role]);

  const handleDelete = () => {
    setLoading(true);
    toast.info('Processing...', { toastId: 'deleteInfo' });
    deleteIntegration(contentId)
      .then(() => {
        toast.dismiss('deleteInfo');
        toast.success(`${name} Deleted!`);
        history.replace(ORG_CONSOLE_ASSISTANTS);
      })
      .catch((error) => {
        console.error(`deleteEntry Entry: ${contentType}`, error);
      });
  };

  return (
    <DeleteContent
      id={contentId}
      title={name}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className={className}
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all of this Assistant's Images{' '}
          <TaskStatus
            statusType="files"
            complete={!assistant.cdnFiles || assistant.cdnFiles.length === 0}
          />
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteAssistant;
