import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import ChangeDashboardLayout from '../../Dashboard/ChangeDashboardLayout';

const ChangeDashboardLayoutModal = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Current Modal
  const activeLayout = currentModal?.data?.activeLayout || '';
  const selectedLayout = currentModal?.data?.selectedLayout || '';

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSelectLayout = (layout) => {
    currentModal?.callbackPrimaryAction(layout);
    handleClose();
  };

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className="m-0 d-flex align-items-center">
            <RiLayoutMasonryFill className="mr-2" />
            <span>Layout</span>
            <span className="badge badge-secondary ml-2">Settings</span>
          </h5>
        </div>
        <div className="card-body container">
          <ChangeDashboardLayout
            activeLayout={activeLayout}
            selectedLayout={selectedLayout}
            handleSelectLayout={handleSelectLayout}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ChangeDashboardLayoutModal;
