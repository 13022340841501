import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Layout from './Layout';

const LayoutWrapper = ({
  layoutData,
  activeLayout,
  selected,
  layoutName,
  handleLayoutSelect
}) => {
  return (
    <div className="p-3 rounded rounded-4 border border-dashed border-black">
      <Layout layout={layoutData} />
      {activeLayout !== layoutName && !selected && (
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-md btn-primary"
            onClick={() => {
              handleLayoutSelect(layoutName);
            }}
          >
            Select
          </button>
        </div>
      )}
      {activeLayout === layoutName && (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="mr-2 position-relative d-flex align-items-center"
            style={{ height: '38px' }}
          >
            <FaCheckCircle
              className="text-success"
              size={20}
            />
            <input
              type="checkbox"
              className="opacity-0 position-absolute"
              checked={activeLayout === layoutName && selected}
              onClick={() => handleLayoutSelect(layoutName)}
              style={{ height: 0, width: 0 }}
            />
          </div>
          <div>Current</div>
        </div>
      )}

      {selected && activeLayout !== layoutName && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="mr-2">
            <input
              type="checkbox"
              checked
            />
          </div>
          <div>Selected</div>
        </div>
      )}
    </div>
  );
};

export default LayoutWrapper;
