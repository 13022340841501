import React from 'react';
import { useSelector } from 'react-redux';
import { TURBINE, TURBINE_ADMIN, TURBINE_VELA } from '../../constants/urls';
import { locationTerminology } from '../../services/locations';

const UserRoleDefinitions = () => {
  const organization = useSelector((state) => state.organization);
  const enableWorkforceSuite = organization?.enableWorkforceSuite;
  const orgType = organization?.type || '';

  return (
    <div>
      <h2 className="mb-0">
        <button
          className="btn btn-link text-left text-dark p-0"
          type="button"
          data-toggle="collapse"
          data-target="#collapseRoleDefinitions"
          aria-expanded="false"
          aria-controls="collapseRoleDefinitions"
        >
          <i className="fas fa-caret-right" />
          <i className="fas fa-caret-down" />
          <small>Role Definitions</small>
        </button>
      </h2>

      <div
        id="collapseRoleDefinitions"
        className="collapse"
      >
        <div className="bg-light border p-2 mt-2">
          <dl>
            <dt>{orgType !== 'workforce' ? 'Trainee' : 'User'}:</dt>
            <dd>
              Access to{' '}
              <a
                title="Turbine Workforce"
                href={TURBINE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Turbine Workforce
              </a>
              .
              {enableWorkforceSuite && (
                <span className="ml-1">
                  Access to{' '}
                  <a
                    title={TURBINE_VELA}
                    href={TURBINE_VELA}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    VELA
                  </a>
                  .
                </span>
              )}
            </dd>

            {enableWorkforceSuite && (
              <>
                <dt>Program Trainer</dt>
                <dd>
                  Access Trainees they've been assigned.
                  <br />
                  Approve and Edit their Trainee's Logbook Records.
                  <br />
                  Approve Logbook competencies where their Trainees have logged
                  the Target Hours.
                </dd>
                <dt>Program Manager</dt>
                <dd>
                  Program Trainer permissions, Manage Trainee's Trainers.
                  <br />
                  Access all Trainees with the same{' '}
                  {locationTerminology({ orgType }).text}.
                  <br />
                  Approve Logbook competencies when Trainee has NOT met Target
                  Hours.
                </dd>
              </>
            )}

            <dt>Expert:</dt>
            <dd>
              User permissions, access to{' '}
              <a
                title={TURBINE_ADMIN}
                href={TURBINE_ADMIN}
                target="_blank"
                rel="noopener noreferrer"
              >
                Turbine Workforce Admin
              </a>
              , create Course Topics & Content.
            </dd>
            <dt>Admin</dt>
            <dd>Expert permissions, delete Course Topics & Content.</dd>
            <dt>Owner</dt>
            <dd>Admin permissions, Organization and User management.</dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserRoleDefinitions;
