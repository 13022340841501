import React from 'react';
import { useSelector } from 'react-redux';
import { FaDownload, FaEllipsisH } from 'react-icons/fa';
import { canExportUserData } from '../../../../../services/currentUser';
import { canManageProgramUsers } from '../../../../../permissions/manage';
import exportData from './exportData/exportTraineeData';

const DotMenuUsers = () => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const users = useSelector((state) => state.users);
  const locations = useSelector((state) => state.locations);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Current User
  const role = currentUser?.role || [];
  // Locations
  const locationsHash = locations?.hash || {};

  if (!canManageProgramUsers(role)) {
    return;
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />

          {canExportUserData(role, orgType) && (
            <button
              className="dropdown-item py-2"
              onClick={() => {
                exportData({
                  orgId,
                  users,
                  apprenticeship,
                  locationsHash
                });
              }}
              title="Export Data"
              type="button"
              disabled={!users.list}
            >
              <span className="d-flex align-items-center">
                <FaDownload />
                <span className="ml-2">Export Data</span>
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DotMenuUsers;
