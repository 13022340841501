import React from 'react';
import InfoField from '../../InfoField';
import AddressReadable from '../../AddressReadable';
import DotMenuInfoBlock from './DotMenuInfoBlock';

const InfoBlock = ({
  title = 'Information',
  className = 'card mt-3',
  data,
  showMenu = false
}) => {
  return (
    <div className={className}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="font-weight-bold">{title}</div>
        {showMenu && <DotMenuInfoBlock data={data} />}
      </div>

      <div className="card-body">
        <InfoField
          className="mb-2"
          name="Name"
          value={data.name}
        />

        {data?.externalId && (
          <InfoField
            className="mb-2"
            name="External ID"
            value={data.externalId}
          />
        )}

        {data?.url && (
          <div className="mb-2">
            <div className="mr-1 font-weight-bold">Website:</div>
            <div data-demo="disabled">
              <div className="text-nowrap">
                <a
                  href={data.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="View in new tab"
                >
                  {data.url}
                </a>
              </div>
            </div>
          </div>
        )}

        {data?.phone && (
          <InfoField
            className="mb-2"
            name="Phone"
            value={data.phone}
          />
        )}

        <div className="mb-3">
          <div className="font-weight-bold">Address:</div>
          <div className="mt-2">
            <AddressReadable
              addressLine1={data.addressLine1}
              addressLine2={data.addressLine2}
              addressCity={data.addressCity}
              addressState={data.addressState}
              addressPostalCode={data.addressPostalCode}
              // addressCountry={data.addressCountry}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
