const sortArrByDate = (arr = [], desc = true) => {
  return arr.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    if (desc) {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    }

    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
};

export default sortArrByDate;
