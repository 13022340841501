import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../actions/Users';
import List from './List';
import TableLoading from '../ManageContent/Tables/TableLoading';

const UsersList = ({
  handleClickRow,
  hiddenColumns = [],
  showRole = false,
  noDataText = 'No Users.',
  excludeTestUsers = false,
  showLogbookRecords = false,
  excludeInactive = false,
  className = '',
  listConfig
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const users = useSelector((state) => state.users);
  const logbooks = useSelector((state) => state.logbooks);
  const cronUsers = useSelector((state) => state.cronUsers);
  // Organization
  const orgId = organization?.id || null;

  const data = useMemo(() => {
    let results = [];

    if (users.fetched && showLogbookRecords && logbooks.list && users.list) {
      results = users.list.map((user) => {
        const userLogbook = logbooks.list.find((l) => l.userId === user.id);

        user.recordsCount = userLogbook?.recordsCount
          ? userLogbook?.recordsCount
          : 0;
        user.recordsHours = userLogbook?.recordsHours
          ? userLogbook?.recordsHours
          : 0;
        user.recordsHoursApproved = userLogbook?.recordsHoursApproved
          ? userLogbook?.recordsHoursApproved
          : 0;

        return user;
      });
    } else {
      results = users.list;
    }

    return results;
  }, [users.fetched, users.list, showLogbookRecords, logbooks.list]);

  const showReminders = useMemo(() => {
    return (
      !hiddenColumns ||
      (Array.isArray(hiddenColumns) && !hiddenColumns.includes('remindersCell'))
    );
  }, [hiddenColumns]);

  const retrieveUsers = useCallback(() => {
    dispatch(getUsers(listConfig));
  }, [dispatch, listConfig]);

  useEffect(() => {
    retrieveUsers();
  }, [retrieveUsers]);

  if (!users.fetched && !users.list) {
    return <TableLoading data-testid="TableLoading-FetchingUsers" />;
  }

  if (users.fetched && !users.list) {
    return <div className="p-4">{noDataText}</div>;
  }

  if (showLogbookRecords && !logbooks.fetched) {
    // logbooks.list
    return <TableLoading data-testid="TableLoading-FetchingLogbookRecords" />;
  }

  if (showReminders && !cronUsers.fetched) {
    // cronUsers.list
    return <TableLoading data-testid="TableLoading-FetchingReminders" />;
  }

  if (!orgId) {
    return null;
  }

  return (
    <div className={`users-list ${className}`}>
      <List
        handleClickRow={handleClickRow}
        data={data}
        excludeInactive={excludeInactive}
        excludeTestUsers={excludeTestUsers}
        hiddenColumns={[
          ...hiddenColumns,
          ...(!showLogbookRecords
            ? ['recordsCount', 'recordsHours', 'recordsHoursApproved']
            : [])
        ]}
        showRole={showRole}
      />
    </div>
  );
};

export default UsersList;
