import React from 'react';
import { FaBell } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notifications from './Modules/Notifications';
import { ORG_CONSOLE_NOTIFICATIONS } from '../../../constants/routes';
import ProjectModule from './Modules/ProjectModule';
import LinkWidgetModule from './Modules/LinkWidgetModule';
import EmbedWidgetModule from './Modules/EmbedWidgetModule';
import MaterialsModule from './Modules/MaterialsModule';
import MaterialModule from './Modules/MaterialModule';

const Module = ({ type, className, isConfig = false, moduleData }) => {
  const organization = useSelector((state) => state.organization);
  const orgNotifications = organization?.notifications?.list || [];
  // Organization
  const orgId = organization?.id || null;

  let module = null;
  switch (type) {
    case 'PROJECT':
      module = (
        <ProjectModule
          isConfig={isConfig}
          projectIds={moduleData?.selectedIds || []}
        />
      );
      break;
    case 'LINK_WIDGETS_LIST':
      module = (
        <LinkWidgetModule
          orgId={orgId}
          widgetIds={moduleData.selectedIds}
          styling={moduleData?.styling}
        />
      );
      break;
    case 'MATERIAL':
      module = <MaterialModule ids={moduleData.selectedIds} />;
      break;
    case 'MATERIALS_LIST':
      module = (
        <MaterialsModule
          orgId={orgId}
          ids={moduleData.selectedIds}
        />
      );
      break;
    case 'EMBED_WIDGETS_LIST':
      module = <EmbedWidgetModule widgetIds={moduleData.selectedIds} />;
      break;
    case 'NOTIFICATIONS':
      module = (
        <Notifications
          showMenu={!isConfig}
          className="mb-4"
          showOnlyPinned={moduleData?.showOnlyPinned}
          limit={moduleData?.limit}
        >
          {orgNotifications ? (
            <div className="d-flex align-items-center justify-content-center">
              <Link
                to={ORG_CONSOLE_NOTIFICATIONS}
                title="View All Company News"
                className="btn btn-white d-flex align-items-center"
              >
                <FaBell size={15} />
                <span className="ml-2">All Company News</span>
              </Link>
            </div>
          ) : (
            <span />
          )}
        </Notifications>
      );
      break;
    default:
      module = null;
  }
  return (
    <div
      className={className}
      style={isConfig ? { pointerEvents: 'none' } : {}}
    >
      {module}
    </div>
  );
};

export default Module;
