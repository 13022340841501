import { toast } from 'react-toastify';
import {
  createSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'apprenticeships';

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const fetchApprenticeships = ({
  order = 'title',
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  ids,
  orgId,
  employerId,
  select,
  instructionProviderId
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      page,
      limit,
      orgId
    };

    if (employerId) {
      config['f.employerIds[ac]'] = employerId;
    }

    if (instructionProviderId) {
      config['f.instructionProviderIds[ac]'] = instructionProviderId;
    }

    if (orgId) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids) {
      config.ids = ids.join(',');
    }

    if (select) {
      config.select = select.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`fetch (many) ${table}`, error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchApprenticeship = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const apprenticeship = response || null;

        resolve(apprenticeship);
      })
      .catch((error) => {
        console.error(`fetch (single) ${table}`, error);
        reject(error);
      });
  });
};

export const updateApprenticeship = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};

export const createApprenticeship = (data) => {
  return new Promise((resolve) => {
    createSupabaseEntry({ data, table })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${table}`, error);
      });
  });
};

export const findClassificationIdsToDelete = ({
  classificationIds,
  competencyId,
  competencies
}) => {
  const currentClassificationIds = Object.keys(classificationIds);
  const currentCompValues = Object.values(competencies);

  // Compile list of classificationIds that are reused across other competencies
  let reusedClassificationIds = [];

  currentCompValues.forEach((cv) => {
    // Looking for reused classificationIds from the active competency across other competencies
    // so we can safely remove any classificationIds that are only present in the active competency
    if (cv.id !== competencyId && cv.classificationIds) {
      const filteredIds = currentClassificationIds.filter(
        (ccId) => cv.classificationIds[ccId] !== undefined
      );

      if (filteredIds.length) {
        reusedClassificationIds = [...filteredIds];
      }
    }
  });

  const classificationIdsToDelete = currentClassificationIds.filter(
    (ccId) => !reusedClassificationIds.includes(ccId)
  );

  return classificationIdsToDelete;
};

export const checkIfDuplicateExists = (arr) => {
  return new Set(arr).size !== arr.length;
};

export const findRecordsByDate = (data, dataKey) => {
  const dataByDate = data.find((d) => d.date === dataKey);

  return dataByDate?.records;
};
