// import { sortCaseInsensitive } from '@apprentage/utils';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchChallengesBySkillIds } from './challenges';
import { fetchProjectsBySkillIds } from './projects';
import { fetchUsersBySkillIds } from './users';
import { fetchCoursesBySkillIds } from './courses';
import { fetchQuizzesBySkillIds } from './courseDashboard/quizzes';

const table = 'skills';

export const checkSkillAssociations = async ({ orgId, skillIds }) => {
  const promises = [
    await fetchUsersBySkillIds({
      skillIds,
      orgId,
      excludeInactive: false,
      excludeTestUsers: false
    }),
    await fetchProjectsBySkillIds({
      skillIds,
      orgId
    }),
    await fetchCoursesBySkillIds({
      skillIds,
      orgId
    }),
    await fetchChallengesBySkillIds({
      skillIds,
      orgId
    }),
    await fetchQuizzesBySkillIds({
      skillIds,
      orgId
    })
  ];

  const response = await Promise.all(promises);

  return response;
};

export const fetchSkills = async ({
  orgId,
  name,
  difficulty,
  ids,
  order = 'name',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (name) {
    params['f.name[ilike]'] = name;
  }

  if (difficulty) {
    params['f.difficulty[eq]'] = difficulty;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchSkill = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateSkill = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createSkill = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteSkill = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
