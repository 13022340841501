import {
  EDITING_DIRECTORY_USER_PROFILE,
  RESET_DIRECTORY_USER,
  SET_DIRECTORY_USER
} from '../../actions/types';
import { getMembershipReadable } from '../../services/currentUser';
import currentUserState from '../initialState/currentUserState';

const initialState = {
  ...currentUserState,
  membershipReadable: '',
  directoryUserEditing: false,
  fetched: false
};

export default function directoryUser(state = initialState, action) {
  switch (action.type) {
    case SET_DIRECTORY_USER: {
      const membership = action?.directoryUser?.membership || [];

      return {
        ...state,
        ...action.directoryUser,
        membershipReadable: membership
          ? getMembershipReadable(membership)
          : null,
        fetched: true
      };
    }
    case EDITING_DIRECTORY_USER_PROFILE: {
      return {
        ...state,
        directoryUserEditing: action?.directoryUserEditing
      };
    }
    case RESET_DIRECTORY_USER:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
