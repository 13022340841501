import React from 'react';
import { FaLink, FaYoutubeSquare } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import { MODAL_KEY_MANAGE_WIDGET } from '../../../constants/modals';
import Modal from '../../Modal';
import './style.css';

const WidgetTypeForm = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal
  const filterAll = currentModal?.data?.filterAll || false;

  const widgetTypes = [
    {
      title: 'Link',
      icon: <FaLink size={35} />,
      type: 'link'
    },
    {
      title: 'Media',
      icon: <FaYoutubeSquare size={35} />,
      type: 'embed'
    }
  ];

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName="turbine-modal--style-card"
      visible={currentModal.visible}
      close={handleClose}
      pos="top"
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h6 className="font-weight-bold m-0">Choose type of widget</h6>
        </div>
        <div className="card-body px-0">
          <div className="d-flex justify-content-center">
            {widgetTypes.map((widgetType) => (
              <WidgetType
                title={widgetType.title}
                icon={widgetType.icon}
                handleSelect={() => {
                  dispatch(
                    setCurrentModal({
                      key: MODAL_KEY_MANAGE_WIDGET,
                      data: {
                        filterAll,
                        app: {
                          type: widgetType?.type
                        }
                      }
                    })
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const WidgetType = ({ title = '', icon, handleSelect }) => {
  return (
    <div className="card rounded rounded-2 text-center widget-type-card shadow w-100 mx-3">
      <div className="border p-3 bg-light">
        <h5 className="m-0">{title}</h5>
      </div>
      <div className="card-body d-flex justify-content-center p-5">{icon}</div>
      <div className="card-footer">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default WidgetTypeForm;
