import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { ICON_TAGS } from '@apprentage/constants';
import { useFlags } from 'flagsmith/react';
import { FaPlus } from 'react-icons/fa';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import {
  FLAG_RESOURCE_TAGS_READONLY,
  NOTICE_RESOURCE_TAGS_READONLY_TITLE
} from '../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../../actions/Modals';
import { withAuthorization } from '../../../Session';
import { canCreateResourceTag } from '../../../../services/currentUser';
import { getResourceGroupTags } from '../../../../actions/ResourceGroupTags';
import { ICON_RESOURCES } from '../../../../constants/assets';
import { MODAL_KEY_MANAGE_RESOURCE_GROUP_TAG } from '../../../../constants/modals';
import { RESOURCE_GROUP_TAGS_PAGINATION_LIMIT } from '../../../../constants/api';
import withOrgConsole from '../../../App/withOrgConsole';
import TagsList from './List';
import EmptyData from '../../../ManageContent/EmptyData';
import Loading from '../../../Loading';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import Tabs from '../Tabs';
import Switch from '../../../Switch';
import PaginationSupabase from '../../../ManageContent/PaginationSupabase';

const ResourcesTags = () => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_RESOURCE_TAGS_READONLY]);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const resourceGroupTags = useSelector((state) => state.resourceGroupTags);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Current User
  const role = currentUser?.role || [];
  // Resource Group Tags
  const initialListConfig = {
    limit: RESOURCE_GROUP_TAGS_PAGINATION_LIMIT,
    page: 1,
    orgId
  };
  const [listConfig, setListConfig] = useState(initialListConfig);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showIdColumn, setShowIdColumn] = useState(false);

  useEffect(() => {
    if (listConfig) {
      dispatch(getResourceGroupTags(listConfig));
    }
  }, [dispatch, listConfig]);

  const addTag = () => {
    if (
      flags?.resource_tags_readonly?.enabled &&
      flags?.resource_tags_readonly?.value
    ) {
      dispatch(
        showFeatureFlagNoticeModal({
          modalTitle: NOTICE_RESOURCE_TAGS_READONLY_TITLE
        })
      );
      return;
    }

    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_RESOURCE_GROUP_TAG,
        callbackPrimaryAction: () => {
          setListConfig((prev) => ({
            ...prev,
            page: 1
          }));
        }
      })
    );
  };

  if (!resourceGroupTags.list && !resourceGroupTags.fetched) {
    return <Loading text="Loading Tags..." />;
  }

  const emptyDataOptions = [
    {
      contentType: 'group',
      title: 'Resource Tag',
      subtitle: 'Keep resources organized with tags.',
      icon: ICON_TAGS
    }
  ];

  const handleSearchTags = (newSearchValue) => {
    setSearchValue(newSearchValue);
    setLoading(true);
    setListConfig((prev) => ({
      ...prev,
      label: newSearchValue
    }));
    setLoading(false);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Resources"
            image={ICON_RESOURCES}
          />

          <Tabs active="tags" />

          <div className="card-body border-left border-right border-bottom bg-white">
            <div className="card">
              <div className="card-header px-3 d-flex align-items-center justify-content-between">
                <h5 className="m-0">
                  <i className={`${ICON_TAGS} mr-1`} /> All Tags
                </h5>

                {canCreateResourceTag(role, orgType) && (
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    title="Add Group"
                    onClick={addTag}
                  >
                    <span className="d-flex align-items-center">
                      <FaPlus />
                      <span className="font-weight-bold ml-1">New</span>
                    </span>
                  </button>
                )}
              </div>

              <div className="card-body p-0">
                {!resourceGroupTags.list && (
                  <EmptyData
                    disabled={false}
                    className="m-3"
                    options={emptyDataOptions}
                    data={resourceGroupTags.list}
                  >
                    <button
                      type="button"
                      className="btn btn-md btn-primary"
                      onClick={addTag}
                      disabled={!canCreateResourceTag(role)}
                    >
                      <span className="d-flex align-items-center">
                        <FaPlus />
                        <span className="font-weight-bold ml-1">New</span>
                      </span>
                    </button>
                  </EmptyData>
                )}

                {Array.isArray(resourceGroupTags?.list) &&
                  resourceGroupTags?.list.length > 0 && (
                    <div className="p-3 border-bottom d-flex align-items-center justify-content-between">
                      <SearchBox
                        className="w-100"
                        onSubmit={handleSearchTags}
                        onClear={() => {
                          setSearchValue('');
                          const searchBox = document.querySelector(
                            'input[name="tabLabel"]'
                          );

                          searchBox.focus();
                        }}
                        name="tabLabel"
                        value={searchValue}
                        loading={loading}
                        placeholder="Search by name..."
                        autoFocus
                      />

                      <div className="d-none d-sm-block">
                        <Switch
                          id="showResourceGroupTagIdCol"
                          label="Show IDs"
                          value={showIdColumn}
                          onChange={() => {
                            setShowIdColumn((prev) => !prev);
                          }}
                        />
                      </div>
                    </div>
                  )}

                <TagsList
                  data={resourceGroupTags.list}
                  setListConfig={setListConfig}
                  showIdColumn={showIdColumn}
                />

                <PaginationSupabase
                  items={resourceGroupTags?.list}
                  pagination={resourceGroupTags?.pagination}
                  page={resourceGroupTags?.pagination?.page}
                  onClickNext={() => {
                    setListConfig((prev) => ({
                      ...prev,
                      page: resourceGroupTags?.pagination?.page + 1
                    }));
                    // goToElem('root');
                  }}
                  onClickPrev={() => {
                    setListConfig((prev) => ({
                      ...prev,
                      page: resourceGroupTags?.pagination?.page - 1
                    }));
                    // goToElem('root');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ResourcesTags);
