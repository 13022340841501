import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import {
  reactSelectDefaultValue,
  reactSelectOptionsFromArray,
  sortArrByKey
} from '@apprentage/utils';
import React from 'react';

const OrganizationSelect = ({
  id = 'selectedOrgId',
  name = 'selectedOrgId',
  onChange,
  data,
  className = '',
  defaultValue = '',
  isClearable = false
}) => {
  const sortedData = sortArrByKey(data, 'name');
  const options = reactSelectOptionsFromArray(sortedData, 'name');
  const defaultVal = reactSelectDefaultValue(defaultValue, options);

  return (
    <div className={className}>
      <SelectMenu
        id={id}
        name={name}
        options={options}
        defaultValue={defaultVal}
        placeholder="Choose Organization"
        // isMulti
        isClearable={isClearable}
        onChange={onChange}
      />
    </div>
  );
};

export default OrganizationSelect;
