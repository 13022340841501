import React, { useEffect, useState } from 'react';
import RenderMarkdown from './ManageContent/RenderMarkdown';

const TypingText = ({ text, ms = 50, className, style = {} }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [transcript] = useState(text);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    if (text && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, ms);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, ms, text]);

  return (
    <RenderMarkdown
      source={currentText}
      className={className}
      style={style}
    />
  );
};

export default TypingText;
