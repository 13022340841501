import React from 'react';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';

const TagInput = ({ id, name, tags = [], onChange, disabled }) => {
  // const [currentTags, setTags] = useState(tags);

  // const pasteSplit = (data, separator = ',') => {
  //   return data.split(separator).map((str) => str.trim());
  // }

  // const getClipboardData = (e) => {
  //   if (window.clipboardData) {
  //     return window.clipboardData.getData('Text');
  //   }

  //   if (e.clipboardData) {
  //     return e.clipboardData.getData('text/plain');
  //   }

  //   return '';
  // }

  // const inputPaste = (e) => {
  //   e.preventDefault();

  //   const data = getClipboardData(e);
  //   const tags = pasteSplit(data);
  //   addTags(tags);
  // }

  return (
    <div className={disabled ? 'pointer-events-none' : ''}>
      <ReactTagInput
        id={id || 'tags'}
        name={name || 'tags'}
        tags={tags}
        onChange={(newTags) => {
          // setTags(newTags);
          if (onChange) {
            onChange({ [name]: newTags });
          }
        }}
      />
    </div>
  );
};

export default TagInput;
