import React from 'react';

const PhoneExtCell = ({ value: phoneExt }) => {
  if (phoneExt) {
    return (
      <span className="h5">
        <span className="badge bg-white border m-1">{phoneExt}</span>
      </span>
    );
  }

  return null;
};

export default PhoneExtCell;
