import React from 'react';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZ_OUTCOMES } from '../../constants/routes';
import { routeWithClassId } from '../../services/courses';

const UserCell = ({ value: userName, row, classId, showUserNameAsLink }) => {
  const {
    original: { userId }
  } = row;

  if (showUserNameAsLink) {
    return (
      <Link
        title={`View ${userName}'s Quiz Submissions`}
        className="btn btn-sm btn-link p-0"
        data-demo="disabled"
        to={routeWithClassId({
          route: `${CLASS_CONSOLE_QUIZ_OUTCOMES}/${userId}`,
          urlParams: {
            classId,
            redirectUrl: window.location.pathname
          }
        })}
      >
        {userName}
      </Link>
    );
  }
  return <div data-demo="disabled">{userName}</div>;
};

export default UserCell;
