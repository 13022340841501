import React from 'react';
import { FaTh } from 'react-icons/fa';
import KnowledgeOpsApps from './KnowledgeOpsApps';
import ApprentageApps from './ApprentageApps';
import MoreApps from './MoreApps';
import './style.css';
import { canAccessTopNavApps } from '../../permissions/access';

const TopNavApps = ({
  role = [],
  orgType,
  enableResources,
  enableWorkforceSuite,
  enablePages,
  enableProjects
}) => {
  if (!canAccessTopNavApps(role)) {
    return;
  }

  return (
    <div className="dropdown">
      <button
        className="top-nav-trigger" // nav-link dropdown-toggle text-nowrap border-left
        type="button"
        data-toggle="dropdown"
        aria-expanded="false"
        aria-label="Menu"
        style={{
          width: '40px'
        }}
      >
        <FaTh size={25} />
      </button>
      <div
        id="topNav-apps-menu"
        className={`dropdown-menu dropdown-menu-right m-0 p-0 shadow ${enableWorkforceSuite ? 'workforce-menu-visible' : 'workforce-menu-hidden'}`}
      >
        <div
          className={`topNav-apps-menu-inner ${enableWorkforceSuite ? 'workforce-menu-visible' : 'workforce-menu-hidden'} m-0 p-0`}
        >
          <div
            className={`topNav-apps-main ${enableWorkforceSuite ? 'workforce-menu-visible' : 'workforce-menu-hidden'}`}
          >
            <KnowledgeOpsApps
              className={`${enableWorkforceSuite ? 'workforce-menu-visible' : 'workforce-menu-hidden'}`}
              role={role}
              orgType={orgType}
              enableResources={enableResources}
            />

            {enableWorkforceSuite ? (
              <ApprentageApps
                className={`${enableWorkforceSuite ? 'workforce-menu-visible' : 'workforce-menu-hidden'}`}
                role={role}
                orgType={orgType}
                enableResources={enableResources}
              />
            ) : (
              <MoreApps
                className={`${enableWorkforceSuite ? 'workforce-menu-visible' : 'workforce-menu-hidden'}`}
                role={role}
                orgType={orgType}
                enableResources={enableResources}
                enableWorkforceSuite={enableWorkforceSuite}
                enablePages={enablePages}
                enableProjects={enableProjects}
              />
            )}
          </div>

          {enableWorkforceSuite && (
            <MoreApps
              className={`${enableWorkforceSuite ? 'workforce-menu-visible' : ''}`}
              role={role}
              orgType={orgType}
              enableResources={enableResources}
              enableWorkforceSuite={enableWorkforceSuite}
              enablePages={enablePages}
              enableProjects={enableProjects}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNavApps;
