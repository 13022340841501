import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaExclamationTriangle } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import './style.css';

const ConfirmAction = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal
  const title = currentModal?.data?.title || 'Are you sure?';
  const subtitle = currentModal?.data?.subtitle || '';
  const primaryActionBtnText = currentModal?.data?.primaryActionBtnText || '';
  // Local State
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      pos="middle"
    >
      <div className="card">
        <div className="card-body">
          <div className="px-4">
            <span className="d-flex justify-content-center py-3 ">
              <FaExclamationTriangle
                className="text-warning"
                style={{ strokeWidth: '1.5' }}
                size={40}
              />
            </span>
            <p className="text-dark text-center">{title}</p>

            {subtitle && (
              <div className="text-dark text-center mb-3">{subtitle}</div>
            )}
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end">
          <button
            className="btn btn-sm btn-primary mr-2"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-outline-primary text-nowrap"
            type="button"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              if (currentModal?.callbackPrimaryAction) {
                currentModal?.callbackPrimaryAction();
              }
            }}
          >
            {primaryActionBtnText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmAction;
