import React from 'react';
// import { FaFileAlt } from 'react-icons/fa';
import { FaLink } from 'react-icons/fa';
import { fileType } from '../../../../../services/resources';
import {
  ICON_CSV,
  ICON_PDF,
  ICON_POWERPOINT,
  ICON_TEXT,
  ICON_WORD,
  ICON_FILE
} from '../../../../../constants/assets';
// import File from './file.png';

const NameIcon = ({ type, url, name }) => {
  // File type
  const fType = fileType(type);
  const fileTypeName = fType?.name;
  // const fileTypeIcon = fType?.icon;

  if (fileTypeName === 'material') {
    return (
      <div style={{ width: '30px' }}>
        <img
          src={ICON_TEXT}
          alt={name}
          className="w-100"
          width="30"
          style={{
            width: '30px'
          }}
        />
      </div>
      // <FaFileAlt size={30} className='text-muted' />
    );
  }

  if (fileTypeName === 'pdf') {
    return (
      <div style={{ width: '30px' }}>
        <img
          src={ICON_PDF}
          alt={name}
          className="w-100"
          width="30"
        />
      </div>
    );
  }

  if (fileTypeName === 'csv') {
    return (
      <div style={{ width: '30px' }}>
        <img
          src={ICON_CSV}
          alt={name}
          className="w-100"
          width="30"
        />
      </div>
    );
  }

  if (fileTypeName === 'word') {
    return (
      <div style={{ width: '30px' }}>
        <img
          src={ICON_WORD}
          alt={name}
          className="w-100"
          width="30"
        />
      </div>
    );
  }

  if (fileTypeName === 'powerpoint') {
    return (
      <div style={{ width: '30px' }}>
        <img
          src={ICON_POWERPOINT}
          alt={name}
          className="w-100"
          width="30"
        />
      </div>
    );
  }

  if (fileTypeName === 'link') {
    return (
      <div
        style={{
          width: '30px',
          height: '30px',
          padding: '.25rem',
          background: '#027bff'
        }}
        className="d-flex align-items-center justify-content-center rounded"
      >
        <FaLink
          size={18}
          className="text-white"
        />
      </div>
      // <i
      //   className={fileTypeIcon}
      //   style={{
      //     fontSize: '1.25rem',
      //     padding: '5px',
      //     background: '#027bff',
      //     color: 'white'
      //   }}
      // />
    );
  }

  if (fileTypeName === 'image') {
    return (
      <img
        className="rounded"
        src={url}
        alt={name}
        height="30"
        width="30"
        style={{
          height: '30px',
          width: '30px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, .5)'
        }}
      />
    );
  }

  // return (
  //   <FileType
  //     className={fileTypeIcon}
  //   />
  // );

  return (
    <div style={{ width: '30px' }}>
      <img
        src={ICON_FILE}
        alt={name}
        className="w-100"
        width="30"
      />
    </div>
  );
};

export default NameIcon;
