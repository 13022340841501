import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FaCog,
  FaImages,
  FaLayerGroup,
  FaPhotoVideo,
  FaUserCog,
  FaUsers
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { VELA_ICON } from '@apprentage/constants';
import {
  SEARCH_ADVANCED_FILTERS,
  SEARCH_CONTENT_TYPES,
  SEARCH_TYPE_RESOURCES,
  SEARCH_TYPE_USERS,
  SEARCH_TYPE_PROJECTS,
  SEARCH_TYPE_COURSES
} from '../../constants/globals';
import Switch from '../Switch';
import { canViewTestUsers } from '../../services/currentUser';

const SearchFilters = ({
  expanded = false,
  contentTypes = SEARCH_CONTENT_TYPES,
  filters = SEARCH_ADVANCED_FILTERS,
  className = '',
  onChangeSearchTypes,
  onChangeAdvancedFilters,
  searchGroupIds,
  children
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const role = currentUser?.role || [];
  // Content Types
  const [searchTypeResources, setSearchTypeResources] = useState(
    contentTypes.includes(SEARCH_TYPE_RESOURCES)
  );
  const [searchTypeUsers, setSearchTypeUsers] = useState(
    contentTypes.includes(SEARCH_TYPE_USERS)
  );
  const [searchTypeProjects, setSearchTypeProjects] = useState(
    contentTypes.includes(SEARCH_TYPE_PROJECTS)
  );
  const [searchTypeCourses, setSearchTypeCourses] = useState(
    contentTypes.includes(SEARCH_TYPE_COURSES)
  );

  // Advanced Filters
  const [excludeTestUsers, setExcludeTestUsers] = useState(
    filters?.excludeTestUsers
  );
  const [excludeInactive, setExcludeInactive] = useState(
    filters?.excludeInactive
  );

  const advancedFilters = useMemo(() => {
    return {
      ...(excludeTestUsers ? { excludeTestUsers } : {}),
      ...(excludeInactive ? { excludeInactive } : {})
    };
  }, [excludeTestUsers, excludeInactive]);

  // TODO check if org has access to resources
  // canAccessResources(role, enableResources, orgType)

  const searchTypes = useMemo(() => {
    return [
      ...(searchTypeResources ? [SEARCH_TYPE_RESOURCES] : []),
      ...(searchTypeUsers ? [SEARCH_TYPE_USERS] : []),
      ...(searchTypeProjects ? [SEARCH_TYPE_PROJECTS] : []),
      ...(searchTypeCourses ? [SEARCH_TYPE_COURSES] : [])
    ];
  }, [
    searchTypeResources,
    searchTypeUsers,
    searchTypeProjects,
    searchTypeCourses
  ]);

  const onChange = useCallback(() => {
    localStorage.setItem('searchContentTypes', searchTypes.join(','));
    localStorage.setItem(
      'searchAdvancedFilters',
      JSON.stringify(advancedFilters)
    );

    if (onChangeSearchTypes) {
      onChangeSearchTypes(searchTypes.join(','));
    }

    if (onChangeAdvancedFilters) {
      onChangeAdvancedFilters(advancedFilters);
    }
  }, [
    searchTypes,
    advancedFilters,
    onChangeSearchTypes,
    onChangeAdvancedFilters
  ]);

  useEffect(() => {
    onChange();
  }, [onChange]);

  return (
    <div className={className}>
      <div className="px-0 px-sm-3 d-flex align-items-center mb-2">
        <span>Search for</span>

        {searchTypes.length !== 0 &&
          searchTypes.map((contentType) => (
            <span
              key={contentType}
              className="ml-2 badge bg-white border text-capitalize"
            >
              {contentType}
            </span>
          ))}

        <button
          className="btn-link d-flex align-items-center ml-2"
          type="button"
          data-toggle="collapse"
          data-target="#searchContentTypes"
          aria-expanded={expanded}
          aria-controls="searchContentTypes"
          aria-label="Change"
        >
          <FaCog size={15} />
        </button>
      </div>

      <div
        className={`collapse ${expanded && 'show'}`}
        id="searchContentTypes"
      >
        <div className="p-1 border mb-3 mx-0 mx-sm-3">
          <div className="p-3 d-flex flex-column">
            <div className="py-2 border rounded d-flex align-items-center justify-content-between">
              <Switch
                id="searchTypeResources"
                slim
                className=""
                label="Resources"
                iconComponent={() => (
                  <FaPhotoVideo
                    size={15}
                    className={`mr-1 ${!searchTypeResources && 'text-muted'}`}
                  />
                )}
                value={searchTypeResources}
                onChange={(e) => {
                  const { checked } = e.currentTarget;
                  setSearchTypeResources(!!checked);
                }}
              />
              <div
                className={`d-flex align-items-center mr-2 ${!searchTypeResources && 'opacity-25'}`}
              >
                <img
                  src={VELA_ICON}
                  alt="VELA"
                  className="d-inline-block mr-2"
                  height={20}
                  style={{
                    height: '20px'
                  }}
                />
                <div>
                  <span className="d-none d-sm-inline">Ask </span>
                  <span>VELA</span>
                </div>
              </div>
            </div>

            {!searchGroupIds && (
              <Switch
                id="searchTypeUsers"
                className="mt-3"
                label="Users"
                value={searchTypeUsers}
                iconComponent={() => (
                  <FaUsers
                    size={15}
                    className={`mr-1 ${!searchTypeUsers && 'text-muted'}`}
                  />
                )}
                onChange={(e) => {
                  const { checked } = e.currentTarget;
                  setSearchTypeUsers(!!checked);
                }}
              />
            )}

            {!searchGroupIds && organization?.enableProjects && (
              <Switch
                id="searchTypeProjects"
                className="mt-3"
                label="Projects"
                value={searchTypeProjects}
                iconComponent={() => (
                  <FaImages
                    size={15}
                    className={`mr-1 ${!searchTypeProjects && 'text-muted'}`}
                  />
                )}
                onChange={(e) => {
                  const { checked } = e.currentTarget;
                  setSearchTypeProjects(!!checked);
                }}
              />
            )}

            {!searchGroupIds && (
              <Switch
                id="searchTypeCourses"
                className="mt-3"
                label="Courses"
                value={searchTypeCourses}
                iconComponent={() => (
                  <FaLayerGroup
                    size={15}
                    className={`mr-1 ${!searchTypeCourses && 'text-muted'}`}
                  />
                )}
                onChange={(e) => {
                  const { checked } = e.currentTarget;
                  setSearchTypeCourses(!!checked);
                }}
              />
            )}
          </div>

          {searchGroupIds && searchTypes.includes(SEARCH_TYPE_RESOURCES) && (
            <div className="px-3 mt-1">
              <h6 className="d-flex align-items-center m-0">
                <span className="font-weight-bold">
                  Search only Files & Folders in:
                </span>
              </h6>
              <div className="py-3 d-flex align-items-center flex-wrap">
                {children || 'No available settings'}
              </div>
            </div>
          )}

          {searchTypes.includes(SEARCH_TYPE_USERS) && (
            <div className="px-3 mt-1">
              <h6 className="d-flex align-items-center m-0">
                <FaUserCog />
                <span className="ml-2 font-weight-bold">
                  Advanced User Settings
                </span>
              </h6>
              <div className="py-3 d-flex align-items-center flex-wrap">
                {canViewTestUsers(role) && (
                  <Switch
                    id="excludeTestUsers"
                    label="Exclude Test Users"
                    className="mr-3 mb-2"
                    value={excludeTestUsers}
                    onChange={(e) => {
                      const { checked } = e.currentTarget;
                      setExcludeTestUsers(!!checked);
                    }}
                  />
                )}

                <Switch
                  id="excludeInactive"
                  label="Exclude Inactive Users"
                  className="mb-2"
                  value={excludeInactive}
                  onChange={(e) => {
                    const { checked } = e.currentTarget;
                    setExcludeInactive(!!checked);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
