import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenUserSideSheet } from '../../../../../actions/SideSheets';
import UsersAtRiskList from '../../../../lists/UsersAtRiskList';

const UsersAtRisk = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const onClickUser = (userId) => {
    dispatch(
      handleOpenUserSideSheet({
        userId,
        orgId,
        currentUserOrgId: currentUser?.orgId
        // route,
        // history
      })
    );
  };

  return (
    <div className={className}>
      <UsersAtRiskList onClickName={onClickUser} />
    </div>
  );
};

export default UsersAtRisk;
