const pageLinkState = {
  id: null,
  createdAt: null,
  updatedAt: null,
  accessCode: null,
  hasAccessCode: null,
  inactive: null,
  pageId: null,
  userId: null,
  orgId: null,
  dimensions: null,
  title: null,
  subtitle: null,
  showTitle: null,
  showOrgLogo: null,
  showSubtitle: null,
  url: null,
  enablePageContent: null
};

export default pageLinkState;
