import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { FaCog } from 'react-icons/fa';
import { deleteResourceGroupTag } from '../../../services/resourceGroupTags';
import canDeleteResourceGroupTag from '../../../permissions/manage/canDeleteResourceGroupTag';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';

const DeleteResourceGroupTag = ({
  title,
  authorUserId,
  groupTags,
  contentId,
  contentType,
  buttonText = 'Delete Tag',
  setActiveTab,
  onSuccess
}) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Tags
  const tagInUse = Array.isArray(groupTags) && groupTags.length > 0;
  // Current User
  const userId = currentUser?.id || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [contentId]);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteResourceGroupTag(role, userId, authorUserId)) {
      return false;
    }

    if (tagInUse) {
      return false;
    }

    return true;
  }, [authorUserId, role, tagInUse, userId]);

  const handleDelete = () => {
    setLoading(true);
    toast.info('Processing...', { toastId: 'processingDelete' });
    deleteResourceGroupTag(contentId)
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong, please refresh and try again.');
        console.error(`deleteResourceGroupTag: ${contentType}`, error);
      })
      .finally(() => {
        toast.dismiss('processingDelete');
      });
  };

  return (
    <DeleteContent
      id={contentId}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className="mt-2 mb-3"
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          <span className="mr-1">Remove tag from all folders</span>
          <TaskStatus
            statusType="files"
            complete={!tagInUse}
          />
          {tagInUse && (
            <ul className="mt-2 pl-3">
              <li className="font-weight-bold">
                <button
                  className="btn-link d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    setActiveTab('usage');
                  }}
                >
                  <FaCog
                    className="text-ships-officer mr-1"
                    size={15}
                  />
                  <span>Review tag usage</span>
                  <span className="ml-1">({groupTags.length})</span>
                </button>
              </li>
            </ul>
          )}
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteResourceGroupTag;
