import { pathOr } from 'ramda';
import {
  createFirebaseEntry,
  getFirebaseEntries,
  getFirebaseEntry
} from '../services/firebaseProxy';
import {
  SET_PATHWAYS,
  RESET_PATHWAYS,
  SET_PATHWAY,
  RESET_PATHWAY,
  SET_ENTRY_ORG_ID
} from './types';

export const getPathways = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntries({
        collection: 'pathways',
        orgId
      })
        .then(({ items }) => {
          const list = items || [];

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({ type: SET_PATHWAYS, list });
          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getPathway = (id, collection = 'pathways') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntry({ collection, id })
        .then((response) => {
          const pathway = response || null;

          dispatch({ type: SET_PATHWAY, pathway });
          resolve(pathway);
        })
        .catch((error) => {
          console.error('get pathway', error);
          reject(error);
        });
    });
  };
};

export const createPathway = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      createFirebaseEntry({
        data,
        collection: 'pathways'
      })
        .then((response) => {
          const pathwayId = pathOr(null, ['docId'], response);

          dispatch(getPathways({ orgId: data?.orgId })).then(() => {
            resolve(pathwayId);
          });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetPathway = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PATHWAY });
  };
};

export const resetPathways = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PATHWAYS });
  };
};
