import isProgramTrainer from '../isProgramTrainer';
import isProgramManager from '../isProgramManager';
import isAdminOwnerSuperAdmin from '../isAdminOwnerSuperAdmin';

export default function canAccessUserLogbook(role = []) {
  return (
    isProgramTrainer(role) ||
    isProgramManager(role) ||
    isAdminOwnerSuperAdmin(role)
  );
}
