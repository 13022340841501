import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaCog, FaCopy, FaEllipsisH, FaPlus } from 'react-icons/fa';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';
import { canManageApprenticeship } from '../../../../permissions/manage';
import {
  canCreateApprenticeship,
  canCreateNoteApprenticeship
} from '../../../../permissions/create';
import { routeWithOrgId } from '../../../../services/organizations';
import { setCurrentModal } from '../../../../actions/Modals';
import { createMaterial } from '../../../../services/materials';
import { getMaterial, getNoteMaterials } from '../../../../actions/Materials';
import { setSideSheet } from '../../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../../../constants/sideSheets';

const DotMenu = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const noteApprenticeshipMaterials = useSelector(
    (state) => state.noteApprenticeshipMaterials
  );
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = currentUser?.role || [];
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || '';
  // Permissions
  const manageApprenticeship = canManageApprenticeship(role);

  const hasApprenticeshipMaterials = useMemo(() => {
    return (
      Array.isArray(noteApprenticeshipMaterials?.list) &&
      noteApprenticeshipMaterials.list.length > 0
    );
  }, [noteApprenticeshipMaterials.list]);

  if (!manageApprenticeship) {
    return null;
  }

  const createNote = () => {
    const noteType = 'apprenticeship';
    const noteTypeId = apprenticeship?.id;

    if (!noteTypeId) {
      console.error('Missing required argument, noteTypeId');
      return;
    }

    const dataToSave = {
      title: `Internal Note: ${apprenticeship?.title}`,
      body: '',
      orgId,
      noteApprenticeshipId: noteTypeId,
      userId: currentUser?.id,
      enableNewEditor: true
    };

    createMaterial(dataToSave).then((response) => {
      setTimeout(() => {
        // Wait to make sure Material is created
        // a race condition was observed without timeout

        dispatch(getMaterial(response?.id)).then(() => {
          dispatch(
            setSideSheet({
              key: SHEET_KEY_MATERIAL,
              className: 'MaterialSideSheet z-index-100',
              data: {
                editMode: true,
                tinyMceEditor: {
                  uploadPdfButton: false,
                  embedMediaButton: false,
                  uploadImgButton: false
                },
                noteType,
                noteTypeId
              },
              callbackPrimaryAction: () => {
                if (apprenticeship?.id) {
                  dispatch(
                    getNoteMaterials({
                      noteType,
                      noteTypeId,
                      orgId
                    })
                  );
                }
              }
            })
          );
        });
      }, 250);
    });
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          {canCreateNoteApprenticeship(role) && !hasApprenticeshipMaterials && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              onClick={createNote}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2">Internal Note</span>
              </span>
            </button>
          )}

          {canCreateApprenticeship(role) && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              type="button"
              onClick={() => {
                dispatch(
                  setCurrentModal({
                    key: 'duplicateApprenticeship'
                  })
                );
              }}
            >
              <span className="d-flex align-items-center">
                <FaCopy />
                <span className="ml-2">Duplicate</span>
              </span>
            </button>
          )}

          <Link
            className="dropdown-item py-2 px-3"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/manage/${apprenticeshipId}`,
              orgId
            })}
          >
            <span className="d-flex align-items-center">
              <FaCog />
              <span className="ml-2">Settings</span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
