import React from 'react';
import { ICON_CITY } from '@apprentage/constants';
import InfoField from '../InfoField';
import AddressReadable from '../AddressReadable';
import LocationDotMenu from './LocationDotMenu';

const InfoBlock = ({
  title = 'Employer',
  icon = ICON_CITY,
  className = 'card mt-3',
  data,
  showMenuOptionNote,
  showMenu = false
}) => {
  return (
    <div className={className}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="font-weight-bold">
          <i className={`${icon} mr-1`} /> {title}
        </div>
        {showMenu && (
          <LocationDotMenu
            data={data}
            showMenuOptionNote={showMenuOptionNote}
            showMenuOptionCopy
            showMenuOptionMap
            showMenuOptionSettings
          />
        )}
      </div>

      <div className="card-body">
        <InfoField
          className="mb-2"
          name="Name"
          value={data.name}
        />

        <div className="mb-3">
          <div className="font-weight-bold">Address:</div>
          <div className="mt-2">
            <AddressReadable
              addressLine1={data.addressLine1}
              addressLine2={data.addressLine2}
              addressCity={data.addressCity}
              addressState={data.addressState}
              addressPostalCode={data.addressPostalCode}
              // addressCountry={data.addressCountry}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
