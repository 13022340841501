import { fetchCurrentWeather } from '../services/weather';
import {
  SET_LOCATIONS_WEATHER_CURRENT,
  SET_USER_WEATHER_CURRENT
} from './types';

export const getUserWeather = ({ postalCode }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchCurrentWeather({
        postalCodes: [postalCode]
      })
        .then((response) => {
          const weatherLocation = response?.location;
          const weatherCurrent = response?.current;

          dispatch({
            type: SET_USER_WEATHER_CURRENT,
            location: weatherLocation,
            current: weatherCurrent
          });
          resolve({
            location: weatherLocation,
            current: weatherCurrent
          });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getLocationsWeather = ({ postalCodes }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchCurrentWeather({
        postalCodes
      })
        .then((weatherCurrentList) => {
          dispatch({
            type: SET_LOCATIONS_WEATHER_CURRENT,
            weatherCurrentList
          });
          resolve(weatherCurrentList);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};
