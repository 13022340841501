import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_PENCIL_BOX, ICON_TOPIC } from '@apprentage/constants';
import { setCurrentClass } from '../../actions/Class';
import { mdySimple } from '../../utils/date';
import { updateCourse } from '../../services/courses';
import ReferencesDnd from '../ManageContent/ReferencesDnd';
import EmptyData from '../ManageContent/EmptyData';

const Form = () => {
  const dispatch = useDispatch();
  // redux
  const data = useSelector((state) => state.course);
  const classId = data?.id || null;
  const enableCourseContent = data?.enableCourseContent || undefined;
  const externalCourseUrl = data?.externalCourseUrl || undefined;
  // local state
  const [loading, setLoading] = useState(false);
  const [updatedAt] = useState(data.updatedAt);
  const [subjects, setSubjects] = useState(data.subjects || []);
  // const [eUnits] = useState(data.eUnits || []); // TODO is this needed?
  // const [eUnitTracking] = useState(data.eUnitTracking || null); // TODO is this needed?

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const dataToSave = {
      classId,
      subjects: subjects.length > 0 ? subjects : null, // Topics
      topicIds: null
    };

    // TODO update logic to not use subjects once topicIds and topic migration complete
    if (subjects.length > 0) {
      dataToSave.topicIds = subjects.map((subject) => subject.sys.id);
    }

    updateCourse(dataToSave).then(() => {
      dispatch(setCurrentClass({ classId }));
      toast.success('Course updated!');
      setLoading(false);
    });
  };

  const emptyDataOptions = [
    {
      contentType: 'topic',
      subtitle: 'Organize Materials, Challenges and Quizzes.',
      icon: ICON_TOPIC
    }
  ];

  const saveIsDisabled =
    !enableCourseContent ||
    (enableCourseContent === true && !subjects.length) ||
    (enableCourseContent === false && !externalCourseUrl);

  return (
    <div className="card mt-3 mb-5">
      <form onSubmit={onSubmit}>
        {enableCourseContent && (
          <div className="card-header p-3 d-flex align-items-center justify-content-between">
            <h1 className="h5 m-0 d-flex align-items-center">
              <i
                className={`${ICON_PENCIL_BOX} mr-1`}
                aria-hidden="true"
              />{' '}
              Content
            </h1>

            {updatedAt && (
              <div
                id="class-updated"
                className="text-right"
              >
                <div className="font-weight-bold small">Last Updated:</div>
                {mdySimple(updatedAt)}
              </div>
            )}
          </div>
        )}

        {enableCourseContent && (
          <div className="card-body">
            <div
              id="class-content"
              className="mb-4"
            >
              {subjects && (
                <ReferencesDnd
                  title="Topics"
                  items={subjects}
                  onUpdate={(newSubjects) => {
                    setSubjects(
                      newSubjects && newSubjects.length > 0 ? newSubjects : []
                    ); // TODO use null instead of []?
                  }}
                  required
                  // References props
                  classId={classId}
                  editContent
                />
              )}
              <EmptyData
                disabled={!classId}
                className="mt-3"
                options={emptyDataOptions}
                data={subjects}
                classId={classId}
                manageButton
              />
            </div>

            {/* TODO Move this to a Cohort Management Section */}
            {/* {(eUnitTracking || eUnits) && (
              <div id="topic-class-dashboard" className="card mb-4">
                <div className="card-header">
                  <h6 className="m-0 text-capitalize">
                    Continuing Education
                  </h6>
                </div>
                <div className="card-body">
                  {eUnitTracking && (
                    <div id="class-eUnitTracking" className="form-group mb-5">
                      <label className="mb-1">
                        <b>Education Unit Tracking:</b>
                      </label>
                      <div className="text-muted medium mb-2">
                        Enable Continuing Education features including Course & Semester codes, student check-in attendance and detailed reporting.
                      </div>
                      <References
                        contents={[eUnitTracking]}
                        classId={classId}
                        editContent
                      />
                    </div>
                  )}

                  {eUnits && (
                    <div id="class-eUnits" className="form-group mb-5">
                      <label className="mb-1">
                        <b>Education Units:</b>
                      </label>
                      <div className="text-muted medium mb-2">
                        Additional advanced Continuing Education features supporting Course Code variants and Session Credits.
                      </div>
                      <References
                        contents={eUnits}
                        classId={classId}
                        searchParams={queryParams}
                        // editContent={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            )} */}
          </div>
        )}

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={loading || saveIsDisabled}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
