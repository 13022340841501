import {
  isAdminOwnerSuperAdmin,
  isProgramManager,
  isProgramTrainer
} from '@apprentage/utils';

export default function canManageUserCompetencies(role = []) {
  return (
    isProgramManager(role) ||
    isProgramTrainer(role) ||
    isAdminOwnerSuperAdmin(role)
  );
}
