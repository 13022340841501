import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isProgramTrainer, isProgramManager } from '@apprentage/utils';
import { FaUsers } from 'react-icons/fa';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import {
  canAccessAllApprenticeshipUsers,
  canAccessUserWageSchedule
} from '../../../../../permissions/access';
import UsersList from '../../../../UsersList';

const TraineesBlock = ({ title = 'Trainees', className = 'card mt-3' }) => {
  const history = useHistory();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const locationId = currentUser?.locationId || null;
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserId = apprenticeshipUser?.id || null;
  const apprenticeshipUserSupervisorIds =
    apprenticeshipUser?.supervisorIds || [];

  const handleClickRow = (clickedUser) => {
    const route = `${apprenticeshipRoute}/users/${clickedUser.id}`;

    history.push(
      routeWithOrgId({
        route,
        orgId
      })
    );
  };

  const hiddenColumns = useMemo(() => {
    const cols = ['locationId', 'remindersCell'];

    if (isProgramManager(role) || isProgramTrainer(role)) {
      cols.push('userProfileId');
      cols.push('isApprentice');
    }

    if (!canAccessUserWageSchedule(role)) {
      cols.push('wageScheduleCell');
    }

    return cols;
  }, [role]);

  const userListConfig = useMemo(
    () => ({
      orgId,
      apprenticeshipId,
      supervisorIds: [apprenticeshipUserId],
      excludeTestUsers: true,
      excludeInactive: true,
      ...(!canAccessAllApprenticeshipUsers(role) ? { locationId } : {})
    }),
    [apprenticeshipId, apprenticeshipUserId, locationId, orgId, role]
  );

  const [listConfig] = useState(userListConfig);

  return (
    <div className={className}>
      <div className="card-header">
        <div className="h6 mb-0 d-flex align-items-center">
          <FaUsers
            size={20}
            className="mr-2"
          />
          <span>{title}</span>
        </div>
      </div>

      <UsersList
        key={apprenticeshipUserSupervisorIds.join(',')} // TODO SPIKE & remove
        handleClickRow={handleClickRow}
        hiddenColumns={hiddenColumns}
        listConfig={listConfig}
      />
    </div>
  );
};

export default TraineesBlock;
