function formatMaterial({ title, body, enableNewEditor }) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (body !== undefined) {
    data.body = {
      'en-US': body
    };
  }

  if (enableNewEditor !== undefined) {
    data.enableNewEditor = {
      'en-US': enableNewEditor
    };
  }

  return { fields: { ...data } };
}

export default formatMaterial;
