import {
  SET_EMPLOYER_PROFILES,
  RESET_EMPLOYER_PROFILES
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function employerProfiles(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER_PROFILES:
      return {
        ...state,
        list: action.list,
        fetched: true
      };
    case RESET_EMPLOYER_PROFILES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
