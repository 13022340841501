import { generateHashFromArray } from '@apprentage/utils';
import {
  SET_APPRENTICESHIP,
  RESET_APPRENTICESHIP,
  SET_APPRENTICESHIP_USER,
  RESET_APPRENTICESHIP_USER,
  SET_APPRENTICESHIP_USER_WAGE_SCHEDULE,
  SET_APPRENTICESHIP_USER_INSTRUCTION_PROVIDER,
  SET_APPRENTICESHIP_USER_PROFILE,
  SET_APPRENTICESHIP_USER_COMPETENCIES,
  RESET_APPRENTICESHIP_USER_COMPETENCIES
} from '../../actions/types';
import apprenticeshipState from '../initialState/apprenticeshipState';

const initialState = {
  ...apprenticeshipState,
  user: null,
  userProfile: null,
  userWageSchedule: null,
  userInstructionProvider: null,
  userCompetencies: null,
  userCompetenciesHash: null,
  fetched: false
};

export default function apprenticeship(state = initialState, action) {
  switch (action.type) {
    case SET_APPRENTICESHIP:
      return {
        ...state,
        classifications: null,
        competencyGroups: null,
        competencies: null,
        competencyNameSpace: '',
        ...action.apprenticeship,
        fetched: true
      };
    case RESET_APPRENTICESHIP:
      return {
        ...state,
        ...initialState
      };
    case SET_APPRENTICESHIP_USER:
      return {
        ...state,
        user: action.user,
        userProfile: initialState.userProfile,
        userWageSchedule: initialState.userWageSchedule
      };
    case RESET_APPRENTICESHIP_USER:
      return {
        ...state,
        user: initialState.user,
        userProfile: initialState.userProfile,
        userInstructionProvider: initialState.userInstructionProvider,
        userWageSchedule: initialState.userWageSchedule,
        userCompetencies: initialState.userCompetencies,
        userCompetenciesHash: initialState.userCompetenciesHash
      };
    case SET_APPRENTICESHIP_USER_WAGE_SCHEDULE:
      return {
        ...state,
        userWageSchedule:
          action?.userWageSchedule || initialState.userWageSchedule
      };
    case SET_APPRENTICESHIP_USER_COMPETENCIES:
      return {
        ...state,
        userCompetencies: action?.list || initialState.userCompetencies,
        userCompetenciesHash: generateHashFromArray(action?.list, 'compId')
      };
    case RESET_APPRENTICESHIP_USER_COMPETENCIES:
      return {
        ...state,
        userCompetencies: initialState.userCompetencies,
        userCompetenciesHash: initialState.userCompetenciesHash
      };
    case SET_APPRENTICESHIP_USER_INSTRUCTION_PROVIDER:
      return {
        ...state,
        userInstructionProvider:
          action?.userInstructionProvider ||
          initialState.userInstructionProvider
      };
    case SET_APPRENTICESHIP_USER_PROFILE:
      return {
        ...state,
        userProfile: action?.userProfile || initialState.userProfile
      };
    default:
      return state;
  }
}
