import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaBroadcastTower, FaClipboardList } from 'react-icons/fa';
import {
  ORG_CONSOLE_CHALLENGES,
  ORG_CONSOLE_COURSES,
  ORG_CONSOLE_COURSES_CATALOG,
  ORG_CONSOLE_COURSES_GROUPS,
  ORG_CONSOLE_COURSES_SYNDICATION
} from '../../../constants/routes';
import { canAccessCourseSyndication } from '../../../services/currentUser';
import { routeWithOrgId } from '../../../services/organizations';

const Tabs = ({ active, className = 'mt-3' }) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const orgId = pathOr(null, ['id'], organization);
  const role = pathOr([], ['role'], currentUser);

  return (
    <div className={className}>
      <div className="position-relative">
        <ul
          data-cy="CoursesSubNav"
          className="nav nav-tabs"
        >
          <li className="nav-item">
            <Link
              className={`nav-link ${active === 'all' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: ORG_CONSOLE_COURSES,
                orgId
              })}
              title="All Courses"
            >
              All
            </Link>
          </li>
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'catalog' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: ORG_CONSOLE_COURSES_CATALOG,
                orgId
              })}
              title="Course Catalog"
            >
              Catalog
            </Link>
          </li>
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'groups' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: ORG_CONSOLE_COURSES_GROUPS,
                orgId
              })}
              title="Course Groups"
            >
              Groups
            </Link>
          </li>
          {canAccessCourseSyndication(role) && (
            <li className="nav-item ml-2">
              <Link
                className={`nav-link d-flex align-items-center ${active === 'syndication' ? 'active' : ''}`}
                to={routeWithOrgId({
                  route: ORG_CONSOLE_COURSES_SYNDICATION,
                  orgId
                })}
                title="Course Syndication"
              >
                <span className="d-flex align-items-center">
                  <span>Syndication</span>
                  <span className="badge badge-info ml-1">
                    <FaBroadcastTower />
                  </span>
                </span>
              </Link>
            </li>
          )}
        </ul>

        <Link
          className="btn btn-sm btn-white position-absolute"
          to={ORG_CONSOLE_CHALLENGES}
          style={{
            right: '0px',
            bottom: '10px'
          }}
        >
          <span className="d-flex align-items-center">
            <FaClipboardList />
            <span className="ml-1">Challenges</span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Tabs;
