import {
  SET_ORG,
  RESET_ORG,
  SET_ORG_COURSE_GROUP_IDS,
  RESET_ORG_COURSE_GROUPS,
  SET_ORG_FILES,
  SET_ORG_USER_GROUP_IDS,
  RESET_ORG_USER_GROUP_IDS,
  CREATE_USER_GROUP,
  CREATE_COURSE_GROUP
} from '../../actions/types';
import organizationState from '../initialState/organizationState';

const initialState = {
  ...organizationState,
  fetched: false,
  userGroupIds: null, // string[]
  courseGroupIds: null, // string[]
  dashboardWidgetIds: null, // string[]
  cdnFiles: null // [{}]
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case SET_ORG:
      return {
        ...state,
        ...action.organization,
        userGroupIds: action.userGroupIds,
        courseGroupIds: action.courseGroupIds,
        dashboardWidgetIds: action.dashboardWidgetIds,
        cdnFiles: action.cdnFiles,
        fetched: true
      };

    // User GROUP
    case CREATE_USER_GROUP:
      return {
        ...state,
        userGroupIds: action.userGroupIds
      };
    case SET_ORG_USER_GROUP_IDS:
    case RESET_ORG_USER_GROUP_IDS:
      return {
        ...state,
        userGroupIds: action.userGroupIds
      };

    // COURSE GROUP
    case CREATE_COURSE_GROUP:
      return {
        ...state,
        courseGroupIds: action.courseGroupIds
      };
    case SET_ORG_COURSE_GROUP_IDS:
    case RESET_ORG_COURSE_GROUPS:
      return {
        ...state,
        courseGroupIds: action.courseGroupIds
      };
    // ORGANIZATION
    case SET_ORG_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_ORG:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
