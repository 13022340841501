import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ORG_CONSOLE_RESOURCES } from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';

const TagsCell = ({ value: groupTags, orgId }) => {
  // row groupIds
  const resourceGroups = useSelector((state) => state.resourceGroups);
  const resourceGroupTags = useSelector((state) => state.resourceGroupTags);
  const storedGroupTags = resourceGroups?.groupTags || null;

  if (!groupTags || !resourceGroupTags.list) {
    return null;
  }

  const routeConfig = {
    route: ORG_CONSOLE_RESOURCES,
    urlParams: {
      orgId
    }
  };

  // if (Array.isArray(resourceGroups?.groupIds)) {
  //   routeConfig.urlParams.groupIds = resourceGroups?.groupIds?.join(',');
  // }

  return (
    <div>
      {groupTags.map((tagId, i) => {
        routeConfig.urlParams.groupTags = storedGroupTags
          ? [...storedGroupTags, tagId].join(',')
          : tagId;

        const resourceGroupTag =
          resourceGroupTags.list &&
          resourceGroupTags.list.find(({ id }) => id === tagId);
        const isDisabled = storedGroupTags && storedGroupTags.includes(tagId);

        // TODO clean up tag data
        // There are a lot of IDs of deleted entries
        if (!resourceGroupTag?.id) {
          return;
        }

        return (
          <Link
            key={i}
            title={resourceGroupTag && resourceGroupTag.label}
            style={{ fontSize: '.65rem' }}
            className={`badge badge-pill badge-light border p-2 mr-1 mb-1 mr-1 mb-1 text-nowrap py-1 ${isDisabled ? 'disabled' : ''}`}
            to={routeWithOrgId(routeConfig)}
            disabled={isDisabled}
          >
            {resourceGroupTag && resourceGroupTag.label}
          </Link>
        );
      })}
    </div>
  );
};

export default TagsCell;
