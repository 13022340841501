import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ICON_COHORT } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import EmptyData from '../../ManageContent/EmptyData';
import ReferencesDnd from '../../ManageContent/ReferencesDnd';

class Cohorts extends Component {
  state = {
    reorderedCohorts: this.props.courseCohorts.list || []
  };

  onUpdateCohorts = (orderedCohorts) => {
    this.setState({
      reorderedCohorts:
        orderedCohorts && orderedCohorts.length > 0 ? orderedCohorts : [] // maybe null
    });
  };

  render() {
    const { course } = this.props;
    const { reorderedCohorts } = this.state;

    const { id: courseId } = course;

    const emptyDataOptions = [
      {
        contentType: 'cohort',
        subtitle: 'Group of users',
        icon: ICON_COHORT
      }
    ];

    return (
      <div className="card my-3">
        <div className="card-header p-3 d-flex justify-content-between align-items-center">
          <h1 className="h5 m-0">Cohorts</h1>
          {reorderedCohorts && <span>Total: {reorderedCohorts.length}</span>}
        </div>
        <div className="card-body">
          {reorderedCohorts && (
            <ReferencesDnd
              title="Cohorts"
              items={reorderedCohorts}
              onUpdate={this.onUpdateCohorts}
              required
              // References props
              classId={courseId}
              // searchParams={searchParams}
              editContent
            />
          )}
          <EmptyData
            disabled={false}
            className="mt-3"
            options={emptyDataOptions}
            data={reorderedCohorts}
            classId={courseId}
            manageButton
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ course, courseCohorts }) => ({
  course,
  courseCohorts
});
const condition = (user) => !!user.uid;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition),
  withConsole
)(Cohorts);
