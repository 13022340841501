import { toast } from 'react-toastify';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { deleteResource } from './resources';

const table = 'materials';

export const fetchMaterials = async ({
  orgId,
  userId,
  noteType,
  noteTypeId,
  isDraft,
  workflowType,
  title,
  skillIds,
  resourceId,
  body,
  ids,
  order = 'title',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    orgId,
    order,
    page,
    limit
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (resourceId !== undefined) {
    if (resourceId === null) {
      params['f.resourceId[is]'] = resourceId;
    } else if (resourceId === '!null') {
      params['f.resourceId[!is]'] = null;
    } else {
      params['f.resourceId[eq]'] = resourceId;
    }
  }

  if (noteTypeId && noteType) {
    if (noteType === 'apprenticeship') {
      params['f.noteApprenticeshipId[eq]'] = noteTypeId;
    }

    if (noteType === 'user') {
      params['f.noteUserId[eq]'] = noteTypeId;
    }

    if (noteType === 'location') {
      params['f.noteLocationId[eq]'] = noteTypeId;
    }

    if (noteType === 'instructionProvider') {
      params['f.noteInstructionProviderId[eq]'] = noteTypeId;
    }
  }

  if (isDraft) {
    params['f.isDraft[is]'] = isDraft;
  }

  if (workflowType !== undefined) {
    if (workflowType === null) {
      params['f.workflowType[is]'] = workflowType;
    } else {
      params['f.workflowType[eq]'] = workflowType;
    }
  }

  if (skillIds) {
    params['f.skillIds[ov]'] = skillIds.join(',');
  }

  if (title) {
    params['f.title[ilike]'] = title;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchMaterialsByTitle = ({ title, orgId }) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      title,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ materials: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by title ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterialsByBody = ({ body, orgId }) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      body,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ materials: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by body ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterialsBySkillIds = ({ skillIds, orgId }) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      skillIds,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ materials: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by skillIds ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterialByResourceId = ({ resourceId, orgId }) => {
  return new Promise((resolve, reject) => {
    fetchMaterials({
      orgId,
      resourceId
    })
      .then((response) => {
        const items =
          Array.isArray(response?.items) && response.items.length > 0
            ? response?.items
            : null;
        const material = items ? items[0] : null;

        resolve(material);
      })
      .catch((error) => {
        console.error(`fetch by resourceId ${table}`, error);
        reject(error);
      });
  });
};

export const fetchMaterial = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateMaterial = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const unpublishMaterial = ({ id, resourceId }) => {
  return new Promise((resolve, reject) => {
    if (!id || !resourceId) {
      toast.error('Something went wrong, try again');
      reject('Material ID or Resource ID is missing');
    }

    deleteResource(resourceId)
      .then(() => {
        updateMaterial(
          {
            resourceId: null,
            isDraft: true
          },
          id
        )
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error(`unpublish ${table}`, error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error('unpublishMaterial: delete resource', error);
        reject(error);
      });
  });
};

export const createMaterial = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteMaterial = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
