import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { ICON_CHALLENGE } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import {
  CLASS_CONSOLE_CHALLENGES,
  CLASS_CONSOLE_CHALLENGES_SUBMISSIONS
} from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';
import { setCurrentAnswers } from '../../../actions/Answers';
import { canManageCourseContent } from '../../../services/currentUser';
import ChallengesList from '../../lists/ChallengesList';

const CourseChallenges = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Course
  const classId = course?.id || null;
  // Pagination
  const pagination = currentAnswers?.pagination || null;
  const total = pagination?.total || 0;
  const limit = pagination?.limit || 200;
  // Misc
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  useEffect(() => {
    dispatch(
      setCurrentAnswers({
        classId,
        limit,
        ...(!manageCourse ? { orgId } : {})
      })
    );
  }, [classId, dispatch, limit, manageCourse, orgId]);

  return (
    <div className="card my-3">
      <div className="card-header p-3 d-flex justify-content-between align-items-center">
        <h1 className="h5 m-0">
          <i
            className={`${ICON_CHALLENGE} mr-2`}
            aria-hidden="true"
          />{' '}
          Challenges
        </h1>
      </div>
      <div className="pt-3 bg-light">
        <ul className="nav nav-tabs pl-3">
          <li className="nav-item">
            <Link
              className="nav-link active mr-2"
              to={routeWithClassId({
                route: CLASS_CONSOLE_CHALLENGES,
                classId
              })}
              title="All Challenges"
            >
              All
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={routeWithClassId({
                route: `${CLASS_CONSOLE_CHALLENGES_SUBMISSIONS}`,
                classId
              })}
              className="nav-link d-flex align-items-center"
            >
              <span>Submissions</span>
              <span className="badge bg-white border px-2 py-1 ml-2">
                {currentAnswers.list &&
                  currentAnswers.list.length !== total &&
                  `${currentAnswers.list.length} / `}
                {total}
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="card-content">
        <ChallengesList classId={classId} />
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(CourseChallenges);
