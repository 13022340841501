import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withAuthorization } from '../../components/Session';
import { ORG_CONSOLE_PAGE_BASE, SLASH } from '../../constants/routes';
import { getPage, resetPage } from '../../actions/Pages';
import { canAccessPages } from '../../permissions/access';
import withOrgConsole from '../../components/App/withOrgConsole';
import Loading from '../../components/Loading';
import './style.css';

const Page = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  // Page
  const pageId = params?.pageId || null;
  // Current User
  const role = currentUser?.role || [];
  // Local State

  const PAGE_ROUTE = `${ORG_CONSOLE_PAGE_BASE}/${pageId}`;

  useEffect(() => {
    if (pageId) {
      dispatch(getPage(pageId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }
  }, [dispatch, pageId]);

  useEffect(() => {
    return function cleanup() {
      const { href } = window.location;

      // Only reset if NOT going to Page route
      if (!href.includes(PAGE_ROUTE)) {
        dispatch(resetPage());
      }
    };
  }, [PAGE_ROUTE, dispatch, pageId]);

  if (!canAccessPages(role) || !pageId) {
    return <Redirect to={SLASH} />;
  }

  if (page?.id && pageLink?.id) {
    if (pageLink?.enablePageContent) {
      return <Redirect to={`${PAGE_ROUTE}/content`} />;
    }

    return <Redirect to={`${PAGE_ROUTE}/settings`} />;
  }

  return <Loading />;
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  Page,
  true
);
