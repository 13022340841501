import React from 'react';
import { percentComplete } from '@apprentage/utils';
import { useSelector } from 'react-redux';
import { FaCog } from 'react-icons/fa';
import ProgressBars from '../../ProgressBars';
import TimeBadge from '../../TimeBadge';
import { canManageUserCompetencies } from '../../../permissions/manage';

const CompetenceProgress = ({
  userTotalHours,
  totalHours,
  competencyId,
  onClickTargetHours
}) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const logbook = useSelector((state) => state.logbook);
  const logbookHours = useSelector((state) => state.logbookHours);
  // Current User
  const role = currentUser?.role || [];
  // Logbook
  const completedCompetencies = logbook?.completedCompetencies || {};
  const competencyIsComplete =
    completedCompetencies[competencyId] !== undefined;

  const hoursEarned = logbookHours?.competencyHours
    ? logbookHours?.competencyHours[competencyId]
    : 0;

  const percentage = percentComplete({
    current: hoursEarned,
    total: totalHours
  });

  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-2">
        <div className="small">
          <span>Hours Accrued</span>
          <TimeBadge
            badgeType="bg-light border"
            hours={hoursEarned || 0}
          />
        </div>
        <div className="small d-flex align-items-center">
          <span>Target Hours</span>
          <TimeBadge
            hours={userTotalHours !== undefined ? userTotalHours : totalHours}
            badgeType={`${userTotalHours !== undefined ? 'primary' : 'bg-light border'}`}
            {...(userTotalHours !== undefined
              ? {
                  'data-tip': 'User specific Target Hours',
                  'data-for': 'UserCompetenciesList'
                }
              : {})}
          />

          {canManageUserCompetencies(role) && onClickTargetHours && (
            <button
              type="button"
              data-tip="Change Target Hours"
              data-for="UserCompetenciesList"
              className="btn btn-sm btn-link text-ships-officer py-1"
              style={{
                fontSize: '.8rem'
              }}
              aria-label="Change Target Hours"
              onClick={() => {
                onClickTargetHours(competencyId);
              }}
            >
              <FaCog />
            </button>
          )}
        </div>
      </div>

      {(userTotalHours === undefined ||
        (userTotalHours !== undefined && userTotalHours > 0)) && (
        <div className="border-top pt-2">
          <ProgressBars
            size="sm"
            data={[
              {
                dataTip: 'Hours Accrued',
                className: `progress-bar ${competencyIsComplete ? 'badge-success' : 'bg-primary'}`,
                valueNow: hoursEarned,
                style: { width: percentage },
                text: `${hoursEarned} / ${userTotalHours || totalHours}`
              }
            ]}
          />
        </div>
      )}
    </>
  );
};

export default CompetenceProgress;
