import React from 'react';
import { compose } from 'redux';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import Form from './Form';

const CoursePublish = () => {
  return <Form />;
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(CoursePublish);
