import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../actions/Modals';
import { MODAL_KEY_MANAGE_WIDGET } from '../../../constants/modals';
import EmbedWidget from '../../ManageContent/EmbedWidget';
import Loading from '../../Loading';
import { IMAGE_TOOLKIT_EMPTY } from '../../../constants/assets';
import { getWidgets } from '../../../actions/Widgets';

const EmbedWidgetList = ({
  className = '',
  isFeatured,
  showSetting = false,
  showTitles = false,
  showFeaturedIcons = false,
  silentLoadData = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const widgetEmbeds = useSelector((state) => state.widgetEmbeds);

  useEffect(() => {
    dispatch(
      getWidgets({
        orgId: organization?.id,
        ...(isFeatured ? { isFeatured } : {}),
        type: 'embed'
      })
    );
  }, [dispatch, isFeatured, organization?.id]);

  const handleOnClickSetting = (app) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_WIDGET,
        data: {
          app,
          editMode: true,
          filterAll: !isFeatured
        }
      })
    );
  };

  const getEmbedClassName = (type, embedCode) => {
    if (type === 'embed') {
      if (embedCode.includes('youtube') || embedCode.includes('vimeo')) {
        return 'embed-container position-relative';
      }
    }
    return '';
  };

  if (!widgetEmbeds?.list && !widgetEmbeds?.fetched) {
    if (silentLoadData) {
      return null;
    }

    return (
      <Loading
        text="Loading..."
        className="bg-white h-100 py-5"
      />
    );
  }

  if (!widgetEmbeds?.list && widgetEmbeds?.fetched) {
    if (silentLoadData) {
      return null;
    }

    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center justify-content-center"
        style={{
          minHeight: '400px'
        }}
      >
        <img
          src={IMAGE_TOOLKIT_EMPTY}
          className="mb-3"
          height={150}
          style={{
            height: '150px'
          }}
          alt="No Media"
        />
        <p className="mt-1 mb-3">No media.</p>
      </div>
    );
  }

  return (
    <div className={`container ${className}`}>
      <div className="d-flex flex-column">
        {widgetEmbeds?.list?.map((widget) => {
          const embedClassName = getEmbedClassName(
            widget.type,
            widget?.embedCode
          );
          return (
            <div key={widget.id}>
              <EmbedWidget
                embedCode={widget.embedCode || ''}
                title={widget.title || ''}
                isFeatured={showFeaturedIcons && widget?.isFeatured}
                showTitle={showTitles || widget.showTitle}
                onClickSetting={() => handleOnClickSetting(widget)}
                showSetting={showSetting}
                className={embedClassName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmbedWidgetList;
