const removeKeysWithUndefinedFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object')
      removeKeysWithUndefinedFromObject(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });

  return obj;
};

export default removeKeysWithUndefinedFromObject;
