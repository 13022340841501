import { fetchWidgets } from '../services/widgets';
import {
  RESET_WIDGET_EMBEDS,
  RESET_WIDGET_LINKS,
  SET_ENTRY_ORG_ID,
  SET_WIDGET_EMBEDS,
  SET_WIDGET_LINKS
} from './types';

export const getWidgets = ({ orgId, sortByIds, type, isFeatured, ids }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        type,
        ids,
        isFeatured
      };

      if (type === 'link' && sortByIds) {
        config.sortByIds = sortByIds;
      }

      fetchWidgets(config)
        .then((response) => {
          const list = response?.list || [];

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: type === 'link' ? SET_WIDGET_LINKS : SET_WIDGET_EMBEDS,
            list
          });

          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetWidgets = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WIDGET_LINKS });
    dispatch({ type: RESET_WIDGET_EMBEDS });
  };
};

export const resetLinkWidgets = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WIDGET_LINKS });
  };
};

export const resetEmbedWidgets = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WIDGET_EMBEDS });
  };
};
