import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import Table from './Table';
import TitleCell from './TitleCell';
import UserRolesCell from '../../../ManageContent/Tables/UserRolesCell';
import ActionsCell from './ActionsCell';

const List = ({ data, hiddenColumns = [] }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const courseGroupIds = pathOr(null, ['courseGroupIds'], organization);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: (props) => <TitleCell {...props} />
      },
      {
        Header: 'Access',
        accessor: 'userRoles',
        Cell: (props) => <UserRolesCell {...props} />
      },
      {
        Header: '',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: (props) => <ActionsCell {...props} />
      }
    ],
    [courseGroupIds]
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No groups.</div>
      </div>
    );
  }

  return (
    <div data-cy="groupsList">
      <Table
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
};

export default List;
