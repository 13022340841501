import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withAuthorization } from '../../components/Session';
import { getAssistant, resetAssistant } from '../../actions/Assistants';
import { canManageAssistant } from '../../permissions/manage';
import { SLASH } from '../../constants/routes';
import { ICON_AGENTS } from '../../constants/assets';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import Loading from '../../components/Loading';
import Form from './Form';
// import CdnFiles from '../../ManageContent/CdnFiles';

const AssistantManage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const assistant = useSelector((state) => state.assistant);
  // Current User
  const role = currentUser?.role || [];
  // Assistant
  const assistantId = params?.assistantId || null;

  useEffect(() => {
    if (assistantId) {
      dispatch(getAssistant(assistantId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }
  }, [dispatch, organization?.id, assistantId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetAssistant());
    };
  }, [dispatch]);

  if (!canManageAssistant(role)) {
    return <Redirect to={SLASH} />;
  }

  if (assistantId && !assistant?.id) {
    return <Loading text="Loading Assistant..." />;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <div className="mb-5">
            <OrgConsoleHeader
              pageTitle={assistant?.id ? 'Assistants' : 'New Assistant'}
              image={ICON_AGENTS}
            />

            <Form data={assistant?.id ? assistant : {}} />

            {/* {assistant?.id && (
            <div
              className="shadow rounded mt-3"
            >
              <CdnFiles
                id="assistant-cdnFiles"
                contentId={assistant?.id}
                contentType="assistants"
                files={assistant?.cdnFiles}
                fileUpload
                multiple
                editMenu
              />
            </div>
          )} */}
          </div>
        </div>
      </div>
      <link
        rel="stylesheet"
        href={`https://console.turbinelms.com/turbine-assistants-v1.css?d=${new Date().toISOString()}`}
      />
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(AssistantManage);
