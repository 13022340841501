import React from 'react';
import IndeterminateCheckbox from '../../../ManageContent/Tables/IndeterminateCheckBox';

const calculateEnabledRows = (rows) => {
  let num = 0;
  rows.forEach((row) => {
    // Condition that determines if a row is selectable
    if (!row?.original?.isApproved) {
      num += 1;
    }
  });

  return num;
};

export default function IndeterminateCheckboxHeader({
  toggleRowSelected,
  rows,
  selectedFlatRows
}) {
  const numEnabledRows = calculateEnabledRows(rows);
  const checked =
    rows && numEnabledRows > 0 && numEnabledRows === selectedFlatRows.length;
  const title = 'Toggle All Rows Selected';
  const indeterminate = !checked && selectedFlatRows.length > 0;
  const disabled = numEnabledRows === 0;
  const style = { cursor: disabled ? 'cursor-not-allowed' : 'cursor-pointer' };

  const overriddenOnChange = (event) => {
    rows.forEach((row) => {
      // Condition that determines if a row is selectable
      if (!row?.original?.isApproved) {
        toggleRowSelected(row.id, event.currentTarget.checked);
      }
    });
  };

  const modifiedToggleAllRowsProps = {
    onChange: overriddenOnChange,
    style,
    checked,
    title,
    indeterminate,
    disabled
  };

  return <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />;
}
