import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import { FaCopy, FaEllipsisH, FaFileAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { FaCircleCheck } from 'react-icons/fa6';
import copyToClipBoard from '../../../../utils/copyToClipBoard';
import { createMaterial } from '../../../../services/materials';
import { getMaterial } from '../../../../actions/Materials';
import { setSideSheet } from '../../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../../../constants/sideSheets';
import {
  fetchUserPrompt,
  updateUserPrompt
} from '../../../../services/userPrompts';

const PromptDotMenu = ({
  className = '',
  output,
  outputTitle,
  resourceId,
  promptId,
  promptType,
  promptText
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  const locationId = currentUser?.locationId || null;
  // Local State
  const [userPrompt, setUserPrompt] = useState(null);
  const [userPromptFetched, setUserPromptFetched] = useState(false);

  const retrieveUserPrompt = useCallback(() => {
    fetchUserPrompt(promptId).then((response) => {
      setUserPrompt(response);
      setUserPromptFetched(true);
    });
  }, [promptId]);

  const copyText = () => {
    copyToClipBoard(output); // Copy Markdown format
    toast.success('Copied to clipboard!');
    updateUserPrompt(
      {
        userCopied: true
      },
      promptId
    );
  };

  const converter = new Showdown.Converter();

  const saveAsMaterial = () => {
    const body = converter.makeHtml(output); // Convert to HTML format
    toast.info('Saving...', { toastId: 'savingMaterial' });

    createMaterial({
      title: outputTitle || promptText,
      body: body || output,
      orgId,
      locationId,
      userId,
      enableNewEditor: true,
      isDraft: true,
      promptType,
      promptText: promptType === 'userPrompt' ? promptText : null,
      resourceIds: [resourceId]
    }).then((response) => {
      toast.dismiss('savingMaterial');
      toast.success('Material saved!', { toastId: 'savedMaterial' });

      updateUserPrompt(
        {
          userSaved: true,
          materialId: response?.id
        },
        promptId
      );

      setTimeout(() => {
        // Wait to make sure Material is created
        // a race condition was observed without timeout

        dispatch(getMaterial(response?.id)).then(() => {
          toast.dismiss('savedMaterial');
          dispatch(
            setSideSheet({
              key: SHEET_KEY_MATERIAL,
              className: 'MaterialSideSheet z-index-100'
            })
          );
        });
      }, 250);
    });
  };

  return (
    <div className={className}>
      <button
        className="btn btn-sm btn-white"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
        onClick={retrieveUserPrompt}
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <button
          type="button"
          title="Save as Material"
          className="dropdown-item border-bottom py-2 px-3"
          disabled={userPromptFetched && userPrompt?.materialId}
          onClick={saveAsMaterial}
        >
          <span className="d-flex align-items-center">
            {userPrompt?.materialId ? (
              <FaCircleCheck className="text-keppel" />
            ) : (
              <FaFileAlt className="text-muted" />
            )}
            <span className="ml-2">
              {userPromptFetched ? 'Save as Material' : 'Loading...'}
            </span>
          </span>
        </button>

        <button
          type="button"
          className="dropdown-item border-bottom py-2 px-3"
          onClick={copyText}
        >
          <span className="d-flex align-items-center">
            <FaCopy className="text-muted" />
            <span className="ml-2">Copy</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default PromptDotMenu;
