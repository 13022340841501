import React from 'react';

const InfoField = ({ className, name, value = '', children }) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <div className="mr-1 font-weight-bold">{name}:</div>
      <div data-demo="disabled">
        {value}
        {children || null}
      </div>
    </div>
  );
};

export default InfoField;
