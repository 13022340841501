import React from 'react';

const SearchBar = ({ onChange }) => {
  return (
    <div className="input-group w-50 mr-2">
      <input
        data-cy="courses-search-input"
        type="text"
        className="form-control"
        placeholder="Search courses"
        aria-label="Search courses"
        aria-describedby="button-addon2"
        autoFocus
        style={{ height: '2.5rem' }}
        onChange={(e) => {
          const { value } = e.currentTarget;

          onChange(value);
        }}
      />
    </div>
  );
};

export default SearchBar;
