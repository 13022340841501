import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { QuizDescriptionContainer, QuestionTitleContainer } from './style';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import './style.css';

const questionAnswerTextColor = ({ isCorrectAnswer }) => {
  if (isCorrectAnswer) {
    return 'guided-quiz--correct';
  }
  return 'guided-quiz--incorrect';
};

const QuizOutcome = () => {
  // redux
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcome = useSelector((state) => state.quizOutcome);
  // Quiz
  const enableNewEditor = pathOr(null, ['enableNewEditor'], currentQuiz);
  // Quiz Outcome
  const questions = pathOr([], ['questions'], quizOutcome);
  const userAnswers = pathOr([], ['userAnswers'], quizOutcome);

  if (!quizOutcome.id || !currentQuiz.id) {
    return null;
  }

  const determineDefaultChecked = (questionIndex, questionAnswerIndex) => {
    // TODO LMS-ADMIN #355 : Move QuizOutcomes to Firebase
    // https://github.com/upstreamcoding/lms-admin/issues/355
    //
    // Firebase data stores this as a string
    // if (typeof userAnswers[questionIndex] === 'string') {

    // Contentful data stores this as an Array
    if (Array.isArray(userAnswers[questionIndex])) {
      // If there are multiple correct answers, this data contains
      // numbers or strings that have the correct answer index as csv
      // Ex userAnswers: [3, '0,2,4', '1,3', 4]
      return userAnswers[questionIndex].includes(questionAnswerIndex);
    }

    return userAnswers[questionIndex] === questionAnswerIndex;
  };

  return (
    <div className="QuizOutcome">
      <div className="quiz-questions">
        {questions.map((question, questionIndex) => {
          const {
            id: questionId,
            title: questionTitle,
            questionNumber,
            description: questionDescription,
            hint: questionHint,
            answers: questionAnswers
          } = question;

          let hasMoreThanOneCorrectAnswer = false;

          if (questionAnswers) {
            const correctAnswers = questionAnswers.filter((q) => q.correct);
            const numCorrectAnswers = correctAnswers
              ? correctAnswers.length
              : 0;

            if (numCorrectAnswers > 1) {
              hasMoreThanOneCorrectAnswer = true;
            }
          }

          return (
            <div
              key={questionIndex}
              className="quiz-question d-flex align-items-start"
            >
              <div>
                <p className="m-0">{questionNumber || questionIndex + 1}.</p>
              </div>

              <div className="ml-3">
                <div
                  className={`mt-0 ${questionDescription ? 'mb-1' : 'pb-2 mb-2 border-bottom'}`}
                >
                  <QuestionTitleContainer>
                    <RenderMarkdown
                      source={questionTitle}
                      enableNewEditor={enableNewEditor}
                    />
                  </QuestionTitleContainer>
                </div>

                {questionDescription && (
                  <QuizDescriptionContainer className="mb-2 pb-2 border-bottom">
                    {questionDescription && (
                      <small>
                        <RenderMarkdown
                          source={questionDescription}
                          enableNewEditor={enableNewEditor}
                        />
                      </small>
                    )}
                  </QuizDescriptionContainer>
                )}

                {questionAnswers && (
                  <div className="RadioGroup--container">
                    {questionAnswers.map(
                      (questionAnswer, questionAnswerIndex) => {
                        const { text: questionAnswerText, incorrectResponse } =
                          questionAnswer;
                        const id = `${questionId}-${questionAnswerIndex}`; // serializeQuiz() may already handle this
                        const name = `${questionId}`;
                        const isCorrectAnswer = questionAnswer.correct === true;

                        return (
                          <div
                            key={questionAnswerIndex}
                            className="custom-control custom-radio block mb-2 small pointer-events-none"
                          >
                            <input
                              id={id}
                              name={name}
                              type={
                                hasMoreThanOneCorrectAnswer
                                  ? 'checkbox'
                                  : 'radio'
                              }
                              className="custom-control-input"
                              required={!hasMoreThanOneCorrectAnswer}
                              defaultValue={parseInt(questionAnswerIndex, 10)}
                              data-correct={isCorrectAnswer}
                              defaultChecked={determineDefaultChecked(
                                questionIndex,
                                questionAnswerIndex
                              )}
                            />
                            <label
                              className={`custom-control-label ${questionAnswerTextColor({ isCorrectAnswer })}`}
                              htmlFor={id}
                            >
                              <span>{questionAnswerText}</span>
                              {isCorrectAnswer && (
                                <span className="badge badge-success ml-2">
                                  Correct
                                </span>
                              )}
                            </label>

                            {incorrectResponse && (
                              <div className="incorrect-response border-danger p-1 mt-2 mr-2">
                                <b className="text-muted">Incorrect:</b>
                                <RenderMarkdown
                                  source={incorrectResponse}
                                  enableNewEditor={enableNewEditor}
                                />
                                {hasMoreThanOneCorrectAnswer && (
                                  <small className="text-muted d-block">
                                    Click to deselect incorrect answer.
                                  </small>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

                {questionHint && (
                  <div className="card-footer">
                    <RenderMarkdown
                      source={questionHint}
                      enableNewEditor={enableNewEditor}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuizOutcome;
