import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FaUndo } from 'react-icons/fa';
import {
  fetchResourceGroup,
  updateResourceGroup
} from '../../../services/resourceGroups';

const RemoveTagButton = ({ resourceGroup, resourceGroupTag }) => {
  // Local State
  const [loading, setLoading] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);

  const removeTagFromGroup = () => {
    const groupTags = Array.isArray(resourceGroup?.groupTags)
      ? [...resourceGroup?.groupTags]
      : [];
    const newGroupTags = groupTags.filter(
      (tag) => tag !== resourceGroupTag?.id
    );
    setLoading(true);

    updateResourceGroup(
      {
        groupTags: newGroupTags.length === 0 ? null : newGroupTags
      },
      resourceGroup?.id
    ).then(() => {
      toast.success(`Tag removed from ${resourceGroup?.title}`);
      setIsRemoved(true);
      setLoading(false);
    });
  };

  const undoRemoveTag = () => {
    setLoading(true);

    fetchResourceGroup(resourceGroup?.id).then((rsp) => {
      const currentGroupTags = rsp?.groupTags || [];

      updateResourceGroup(
        {
          groupTags: [...currentGroupTags, resourceGroupTag?.id]
        },
        resourceGroup?.id
      ).then(() => {
        toast.success(`Tag restored`);
        setLoading(false);
        setIsRemoved(false);
      });
    });
  };

  return (
    <>
      {isRemoved ? (
        <button
          onClick={undoRemoveTag}
          type="button"
          className="btn btn-sm btn-white"
        >
          <span className="d-flex align-items-center">
            <FaUndo
              size={10}
              className="mr-2"
            />
            <span>Undo</span>
          </span>
        </button>
      ) : (
        <button
          className="btn btn-sm btn-outline-danger"
          type="button"
          title="Remove Tag from Group"
          disabled={loading || isRemoved}
          data-id={resourceGroup?.id}
          onClick={removeTagFromGroup}
        >
          {loading ? 'Removing...' : 'Remove Tag'}
        </button>
      )}
    </>
  );
};

export default RemoveTagButton;
