import { referenceType } from '../contentful';

function formatCohort({ title, eUnitTracking, eUnits }) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (eUnits !== undefined && eUnits.length) {
    data.eUnits = {
      'en-US': eUnits.map(({ sys }) => referenceType(sys.id))
    };
  }

  if (eUnitTracking !== undefined && eUnitTracking.sys) {
    data.eUnitTracking = {
      'en-US': referenceType(eUnitTracking.sys.id)
    };
  }

  return { fields: { ...data } };
}

export default formatCohort;
