import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';
import {
  canAccessApprenticeshipEmployers,
  canAccessApprenticeshipInstructionProviders
} from '../../../../permissions/access';
import { routeWithOrgId } from '../../../../services/organizations';
import Dropdown from './Dropdown';

const Tabs = ({ active, className = 'mt-3' }) => {
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Current User
  const role = pathOr([], ['role'], currentUser);
  // Organization
  const orgId = pathOr('', ['id'], organization);
  // Params
  const apprenticeshipId = pathOr(null, ['apprenticeshipId'], params);
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  return (
    <>
      <div className={`${className} d-none d-md-block`}>
        <ul
          data-cy="CoursesSubNav"
          className="nav nav-tabs"
        >
          <li className="nav-item">
            <Link
              className={`nav-link ${active === 'dashboard' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: apprenticeshipRoute,
                orgId
              })}
              title="Dashboard"
            >
              Dashboard
            </Link>
          </li>
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'competencies' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/competencies`,
                orgId
              })}
              title="Competencies"
            >
              Competencies
            </Link>
          </li>
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'users' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/users`,
                orgId
              })}
              title="Users"
            >
              Users
            </Link>
          </li>

          {canAccessApprenticeshipEmployers(role) && (
            <li className="nav-item ml-2">
              <Link
                className={`nav-link ${active === 'employers' ? 'active' : ''}`}
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/employers`,
                  orgId
                })}
                title="Employers"
              >
                Employers
              </Link>
            </li>
          )}

          {canAccessApprenticeshipInstructionProviders(role) && (
            <li className="nav-item ml-2">
              <Link
                className={`nav-link ${active === 'instructionProviders' ? 'active' : ''}`}
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/instruction-providers`,
                  orgId
                })}
                title="Instruction Providers"
              >
                Instruction Providers
              </Link>
            </li>
          )}

          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'courses' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/courses`,
                orgId
              })}
              title="Courses"
            >
              Courses
            </Link>
          </li>
        </ul>
      </div>

      <Dropdown
        active={active}
        className="d-block d-md-none"
      />
    </>
  );
};

export default Tabs;
