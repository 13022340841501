import {
  getClient,
  getClientManagement,
  CSPACE,
  flattenItem
} from '../services/contentful';
import { dateTimestamp } from '../utils/date';
// import { formatData } from '../services/formatData';
import removeObjectFromArray from '../utils/removeObjectFromArray';
import removeStringFromArray from '../utils/removeStringFromArray';

import {
  SET_CURRENT_ENTRY,
  RESET_CURRENT_ENTRY,
  SET_COURSE_FILES,
  SET_TOPIC_FILES,
  SET_ORG_FILES,
  SET_CURRENT_ENTRY_FILES,
  SET_ORG_LOCATION_FILES,
  SET_RESOURCE_GROUP_FILES,
  SET_MATERIAL_FILES,
  SET_CHALLENGE_FILES,
  SET_QUIZ_FILES
} from './types';

export const setCurrentEntry = (entryId) => {
  return (dispatch) => {
    return getClient()
      .getEntry(entryId)
      .then((response) => {
        const currentEntry = response ? flattenItem(response) : null;
        const cdnFiles = currentEntry?.integration?.cdnFiles || null;
        const cdnFileNames = currentEntry?.cdnFileNames || null; // TODO remove, moved to resourceGroup

        dispatch({
          type: SET_CURRENT_ENTRY,
          currentEntry,
          cdnFiles,
          cdnFileNames
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const resetCurrentEntry = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ENTRY });
  };
};

/**
 *
 * @summary returns key to be used in dispatch event
 * @param {string} contentType class, topic, entry (material, challenge, everything else)
 */
function actionFromType({ contentType, groupType }) {
  // Construct Dispatch Data based on contentType [class, topic, entry (everything else)]

  switch (contentType) {
    case 'organization':
      return SET_ORG_FILES;
    case 'location':
      return SET_ORG_LOCATION_FILES;
    case 'class':
      return SET_COURSE_FILES;
    case 'topic':
      return SET_TOPIC_FILES;
    case 'material':
      return SET_MATERIAL_FILES;
    case 'challenge':
      return SET_CHALLENGE_FILES;
    case 'quiz':
      return SET_QUIZ_FILES;
    case 'group': {
      if (groupType && groupType === 'resource') {
        return SET_RESOURCE_GROUP_FILES;
      }

      return SET_CURRENT_ENTRY_FILES;
    }
    default:
      return SET_CURRENT_ENTRY_FILES;
  }
}

const fileIdName = (id = '', name = '') => {
  return `${id}:${name}`;
};

export const saveCdnFiles = ({ data, contentId, contentType, groupType }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { id: userId, name: userName } = currentUser;

    return new Promise((resolve, reject) => {
      // Update entry
      getClientManagement()
        .getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(contentId))
        .then((entry) => {
          if (data.fileObj) {
            // Add userId and userName for permissions & knowing who uploaded
            const newFilesObjectData = [];
            const newFilesObjectFileNames = [];
            const multipleFileUpload =
              Array.isArray(data.fileObj) && data.fileObj.length !== 0;

            if (multipleFileUpload) {
              data.fileObj.forEach((file) => {
                newFilesObjectData.push({
                  ...file,
                  userId,
                  userName,
                  createdAt: dateTimestamp()
                });

                newFilesObjectFileNames.push(fileIdName(file?.id, file.name));
              });
            } else {
              data.fileObj.userId = userId;
              data.fileObj.userName = userName;
              data.fileObj.createdAt = dateTimestamp();
            }

            // if no integration key, create default structure
            if (entry.fields.integration === undefined) {
              entry.fields.integration = { 'en-US': {} };
            }

            // if no cdnFiles key, create default structure
            if (entry.fields.integration['en-US'].cdnFiles === undefined) {
              entry.fields.integration['en-US'].cdnFiles = [];
            }

            /**
             * cdnFiles (START)
             * if no cdnFiles key, create default structure
             */

            let newFiles = [];

            if (multipleFileUpload) {
              newFiles = [
                ...entry.fields.integration['en-US'].cdnFiles,
                ...newFilesObjectData
              ];
            } else {
              newFiles = [
                ...entry.fields.integration['en-US'].cdnFiles,
                data.fileObj
              ];
            }

            entry.fields.integration['en-US'].cdnFiles = newFiles;

            // Redux, create default structure
            const dispatchConfig = {
              type: actionFromType({ contentType, groupType }),
              cdnFiles: newFiles
            };

            /**
             * cdnFileNames (START)
             */

            if (contentType === 'group') {
              let newFileNames = [];

              if (entry.fields.cdnFileNames === undefined) {
                entry.fields.cdnFileNames = { 'en-US': [] };
              }

              if (multipleFileUpload) {
                newFileNames = newFilesObjectFileNames;
              } else {
                newFileNames.push(
                  fileIdName(data.fileObj.id, data.fileObj.name)
                );
              }

              newFileNames.forEach((fileName) => {
                if (!entry.fields.cdnFileNames['en-US'].includes(fileName)) {
                  entry.fields.cdnFileNames['en-US'].push(fileName);
                }
              });

              dispatchConfig.cdnFileNames = entry.fields.cdnFileNames['en-US'];
            }

            dispatch(dispatchConfig);
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          resolve(entry);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const updateCdnFiles = ({
  fileObj,
  contentId,
  contentType,
  groupType,
  fileAction
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Update entry
      getClientManagement()
        .getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(contentId))
        .then((entry) => {
          if (fileObj) {
            // Has integration
            if (entry.fields.integration && entry.fields.integration['en-US']) {
              // Has cdnFiles
              if (entry.fields.integration['en-US'].cdnFiles) {
                const cdnFilesLen =
                  entry.fields.integration['en-US'].cdnFiles.length;

                /**
                 *
                 * EDIT
                 *
                 */
                if (fileAction === 'edit') {
                  const cdnFileIndex = entry.fields.integration[
                    'en-US'
                  ].cdnFiles.findIndex(({ id }) => id === fileObj.id);

                  if (cdnFileIndex !== -1) {
                    entry.fields.integration['en-US'].cdnFiles[cdnFileIndex] =
                      fileObj;
                  }

                  const dispatchConfig = {
                    type: actionFromType({ contentType, groupType }),
                    cdnFiles: entry.fields.integration['en-US'].cdnFiles
                  };

                  if (contentType === 'group') {
                    const cdnFileNameIndex = entry.fields.cdnFileNames[
                      'en-US'
                    ].findIndex((nameHash) => nameHash.includes(fileObj.id));

                    if (cdnFileNameIndex !== -1) {
                      entry.fields.cdnFileNames['en-US'][cdnFileNameIndex] =
                        `${fileObj.id}:${fileObj.name}`;
                    }

                    dispatchConfig.cdnFileNames =
                      entry.fields.cdnFileNames['en-US'];
                  }

                  dispatch(dispatchConfig);
                }

                /**
                 *
                 * DELETE
                 *
                 */
                if (fileAction === 'delete') {
                  if (cdnFilesLen === 1) {
                    // Only one file in cdnFiles, delete the key
                    delete entry.fields.integration['en-US'].cdnFiles;

                    const integrationKeys = Object.keys(
                      entry.fields.integration['en-US']
                    );

                    if (integrationKeys.length === 0) {
                      // No other keys in integration, delete key
                      delete entry.fields.integration;
                    }

                    const dispatchConfig = {
                      type: actionFromType({ contentType, groupType }),
                      cdnFiles: null
                    };

                    if (contentType === 'group') {
                      // Only one fileId:name in cdnFileNames, set value to undefined
                      if (entry.fields.cdnFileNames !== undefined) {
                        entry.fields.cdnFileNames['en-US'] = undefined;
                        dispatchConfig.cdnFileNames = null;
                      }
                    }

                    dispatch(dispatchConfig);
                  } else {
                    const newFiles = removeObjectFromArray(
                      entry.fields.integration['en-US'].cdnFiles,
                      'url',
                      fileObj.url
                    );

                    entry.fields.integration['en-US'].cdnFiles = newFiles;

                    const dispatchConfig = {
                      type: actionFromType({ contentType, groupType }),
                      cdnFiles: newFiles
                    };

                    if (contentType === 'group') {
                      if (entry.fields.cdnFileNames !== undefined) {
                        const newCdnFileNames = removeStringFromArray(
                          entry.fields.cdnFileNames['en-US'],
                          fileIdName(fileObj.id, fileObj.name)
                        );

                        entry.fields.cdnFileNames['en-US'] = newCdnFileNames;
                        dispatchConfig.cdnFileNames = newCdnFileNames;
                      }
                    }

                    dispatch(dispatchConfig);
                  }
                }
              }
            }
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          resolve(entry);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
