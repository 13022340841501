import React from 'react';
import { useSelector } from 'react-redux';
import { canManageCourseContent } from '../../../../services/currentUser';
import Table from './Table';
import TitleCell from './TitleCell';
import EditCell from './EditCell';
import TopicCell from './TopicCell';
import SubmissionsCell from './SubmissionsCell';
import ActiveCell from './ActiveCell';

const List = ({ data, editItem, orgId }) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  // Organization
  const orgType = organization?.type || '';
  // Misc
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: (props) => (
          <TitleCell
            orgId={orgId}
            {...props}
          />
        )
      },
      {
        Header: 'Topic',
        accessor: 'topicId',
        Cell: (props) => (
          <TopicCell
            orgId={orgId}
            {...props}
          />
        )
      },
      {
        Header: 'Submissions',
        accessor: 'updatedAt',
        disableSortBy: true,
        Cell: (props) => (
          <SubmissionsCell
            orgId={orgId}
            {...props}
          />
        )
      },
      {
        Header: 'Status',
        accessor: 'activeChallenge',
        Cell: ActiveCell
      },
      // {
      //   Header: 'Difficulty',
      //   accessor: 'difficulty'
      // },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        hideHeader: !!manageCourse,
        Cell: (props) => {
          return manageCourse ? (
            <EditCell
              orgId={orgId}
              onClickEdit={editItem}
              {...props}
            />
          ) : null;
        }
      }
    ],
    [manageCourse, orgId, editItem]
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Challenges.</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
    />
  );
};

export default List;
