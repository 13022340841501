import { SET_DASHBOARD, RESET_DASHBOARD } from '../../actions/types';
import dashboardState from '../initialState/dashboardState';

const initialState = {
  ...dashboardState,
  fetched: false
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD:
      return {
        ...state,
        ...action.dashboard,
        fetched: true
      };
    case RESET_DASHBOARD:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
