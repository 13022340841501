import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { withAuthorization } from '../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../actions/Apprenticeships';
import { ICON_TRAINING_PROGRAMS } from '../../../../constants/assets';
import withOrgConsole from '../../../App/withOrgConsole';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import Loading from '../../../Loading';
import Tabs from './Tabs';
import DotMenu from './DotMenu';
import Dashboard from './Dashboard';

const Apprenticeship = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  //  misc
  const apprenticeshipId = params?.apprenticeshipId || null;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    return function cleanup() {
      dispatch(resetApprenticeship());
    };
  }, [apprenticeshipId, dispatch]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="dashboard" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <Dashboard />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Apprenticeship);
