import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { percentComplete } from '@apprentage/utils';
import { useHistory } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';
import { withAuthorization } from '../../../components/Session';
import withOrgConsole from '../../../components/App/withOrgConsole';
import Tools from './Tools';
import Courses from './Courses';
import Knowledge from './Knowledge';
import People from './People';
// import Locations from './Locations';
import Groups from './Groups';
import Activity from './Activity';
import Skills from './Skills';
import ProgressBars from '../../../components/ProgressBars';
import Nav from './Nav';
import { ORG_CONSOLE_DASHBOARD } from '../../../constants/routes';
import { updateUser } from '../../../services/user';
import { getCurrentUser } from '../../../actions/Users';

const WorkforceSpace = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const workforceSpace = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const userId = currentUser?.id || null;
  // Organization
  const orgName = organization?.name || null;
  const orgLogo = organization?.orgLogo || null;
  // Local State
  const [totalSteps] = useState(6);
  const [currentStep] = useState(4);
  const [loading, setLoading] = useState(false);

  const percentage = percentComplete({
    current: currentStep,
    total: totalSteps
  });

  const onClickExplore = () => {
    setLoading(true);

    updateUser(
      {
        onboarded: true
      },
      userId
    ).then(() => {
      dispatch(getCurrentUser({ userId })).then(() => {
        history.push(ORG_CONSOLE_DASHBOARD);
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (workforceSpace?.current) {
        workforceSpace.current.classList.add('scale-up');
      }
    }, 250);
  }, []);

  return (
    <div className="container">
      <div
        className="position-fixed"
        style={{
          top: 0,
          width: '100%',
          left: 0
        }}
      >
        <ProgressBars
          size="sm"
          className="w-100 border-radius-0"
          data={[
            {
              className: 'bg-000',
              valueNow: currentStep,
              valueMax: totalSteps,
              valueMin: 0,
              style: { width: percentage },
              text: ''
            }
          ]}
        />
      </div>

      <h5 className="mt-4 mb-0 text-center">{orgName}</h5>
      <div className="mb-4 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center">
          <span className="h3 m-0 font-weight-bold">Workforce Space</span>
        </div>
      </div>

      <div
        ref={workforceSpace}
        className="workforce-space"
      >
        <Nav orgLogo={orgLogo} />

        <div className="row">
          <div className="col-12 col-sm-6">
            <People />
            <Groups />
            {/* <Locations /> */}
            <Skills />
          </div>
          <div className="col-12 col-sm-6">
            <Activity />
            <Tools />
            <Courses />
            <Knowledge />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn btn-lg bg-000 text-white position-relative overflow-hidden"
            type="button"
            onClick={onClickExplore}
            loading={loading}
          >
            <span
              style={{ zIndex: 2 }}
              className="d-flex align-items-center position-relative"
            >
              <span className="position-relative mr-2">Explore</span>
              <FaArrowCircleRight />
            </span>
            <span className="start-btn-pulse" />
          </button>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  WorkforceSpace,
  true
);
