import { getClient, flattenItem, flattenItems } from '../services/contentful';
import {
  SET_CURRENT_TOPIC,
  RESET_CURRENT_TOPIC,
  SET_PREREQUISITE_TOPICS
} from './types';

export const setCurrentTopic = ({ topicId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getClient()
        .getEntry(topicId)
        .then((response) => {
          const currentTopic = response ? flattenItem(response) : null;
          const cdnFiles = currentTopic?.integration?.cdnFiles || null;

          dispatch({
            type: SET_CURRENT_TOPIC,
            currentTopic,
            cdnFiles
          });
          resolve(currentTopic);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetCurrentTopic = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC });
  };
};

export const getPrerequisiteTopics = ({ topicId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'topic',
        'fields.prerequisite.sys.id': topicId // TODO topic flatten (prerequisiteTopicId)
      };

      return getClient()
        .getEntries(config)
        .then(({ items }) => {
          const prerequisiteTopics = items.length ? flattenItems(items) : null;

          dispatch({
            type: SET_PREREQUISITE_TOPICS,
            prerequisiteTopics
          });

          resolve(prerequisiteTopics);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
