import React from 'react';
import { FaAtom } from 'react-icons/fa';

const SkillsTag = ({
  id = null,
  name = '',
  showTagIcon = false,
  size = 'sm',
  onClick,
  badgeType = 'badge-light'
}) => {
  return (
    <span
      className={size === 'md' ? 'h6' : ''}
      aria-label={name}
      {...(onClick
        ? {
            onClick: () => {
              onClick(id);
            }
          }
        : {})}
    >
      <span
        className={`badge badge-pill ${badgeType} border mb-2 ml-1 py-2 px-3`}
      >
        <span
          className={`d-flex align-items-center m-0 ${size === 'md' ? 'h6' : ''}`}
        >
          {showTagIcon && (
            <FaAtom
              className={`text-muted ${size === 'md' ? 'mr-2' : 'mr-1'}`}
            />
          )}
          <span>{name}</span>
        </span>
      </span>
    </span>
  );
};

export default SkillsTag;
