import groupDataByDate from './groupDataByDate';
import sortArrByDate from './sortArrByDate';

const collateByGroupKeyAndDateKey = (
  data = [],
  groupKey = 'locationId',
  key = 'date'
) => {
  const groupData = data?.reduce((groups, record) => {
    if (!groups[record[groupKey]]) {
      groups[record[groupKey]] = [];
    }
    groups[record[groupKey]].push(record);
    return groups;
  }, {});

  const results = [];

  if (groupData) {
    // check if groupData is not null or undefined
    Object.keys(groupData).forEach((groupId) => {
      results.push({
        [groupKey]: groupId,
        data: sortArrByDate(groupDataByDate(groupData[groupId], key), false)
      });
    });
  }

  return results;
};

export default collateByGroupKeyAndDateKey;
