import React from 'react';
import AgentImage from './AgentImage';

const AssistantBadge = ({ className = '', agentImage, name, colorBtnBkg }) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      {agentImage && (
        <AgentImage
          imgSrc={agentImage}
          imgHeight={25}
          circleHeight={40}
          imgName={name}
          bkgColor={colorBtnBkg}
        />
      )}

      <span className="ml-2">{name}</span>
    </div>
  );
};

export default AssistantBadge;
