import React from 'react';
import styled from 'styled-components';

const CollapseTrigger = styled.div`
  cursor: pointer;

  &:hover {
    background-color: rgba(54, 162, 235, 0.2);
    border: 1px rgba(54, 162, 235, 1) solid;
  }
`;

const CollapseContainer = styled.div`
  .fa-plus,
  .fa-minus {
    display: inline-flex;
    justify-content: center;
    margin-right: 0.25rem !important;
    width: 12px;
  }

  [aria-expanded='true'] .fa-plus,
  [aria-expanded='false'] .fa-minus {
    display: none;
  }
`;

const Collapse = ({
  id,
  title,
  children,
  className = '',
  badge = false,
  ariaExpanded = false,
  p = '3'
}) => {
  return (
    <CollapseContainer
      key={id}
      className={className}
    >
      <CollapseTrigger
        className="border p-3 d-flex align-items-center justify-content-between"
        data-toggle="collapse"
        data-target={`#${id}`}
        aria-expanded={ariaExpanded}
        aria-controls={id}
      >
        <div className="font-weight-bold d-flex align-items-center pr-3">
          <span>{title}</span> {badge && badge()}
        </div>
        <div>
          <i className="fas fa-plus" />
          <i className="fas fa-minus" />
        </div>
      </CollapseTrigger>

      <div
        className={`collapse border-right border-left border-bottom ${p ? `p-${p}` : ''} ${ariaExpanded ? 'show' : ''}`}
        id={id}
      >
        {children}
      </div>
    </CollapseContainer>
  );
};

export default Collapse;
