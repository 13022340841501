import React from 'react';
import Table from './Table';
import TitleCell from './TitleCell';
import EditCell from './EditCell';
import OrgLocationCell from './OrgLocationCell';
import ArrLenCell from '../../ManageContent/Tables/ArrLenCell';
import DateCell from '../../ManageContent/Tables/DateCell';

const List = ({ data, editItem }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'OnetTitle',
        Cell: (props) => <TitleCell {...props} />
      },
      {
        Header: 'Location',
        accessor: 'occupationLocation'
      },
      {
        Header: 'Employer',
        accessor: 'locationId',
        Cell: (props) => <OrgLocationCell {...props} />
      },
      {
        Header: 'Certificates',
        accessor: 'selectedCerts',
        Cell: (props) => <ArrLenCell {...props} />
      },
      {
        Header: 'Skills',
        accessor: 'selectedSkills',
        Cell: (props) => <ArrLenCell {...props} />
      },
      {
        Header: 'Programs',
        accessor: 'selectedSchoolPrograms',
        Cell: (props) => <ArrLenCell {...props} />
      },
      {
        Header: 'Knowledge',
        accessor: 'selectedKnowledge',
        Cell: (props) => <ArrLenCell {...props} />
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: (props) => <DateCell {...props} />
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: (props) => <EditCell {...props} />
      }
    ],
    [editItem]
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Job Profiles.</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
    />
  );
};

export default List;
