import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { ICON_COURSE } from '@apprentage/constants';
import EntryButton from '../../btns/EntryButton';

const CourseHeader = ({ showPreviewBtn = false }) => {
  const course = useSelector((state) => state.course);
  const classId = pathOr(null, ['id'], course);
  const classTitle = pathOr(null, ['title'], course);

  return (
    <div>
      <div className="card-header bg-dark p-3 d-flex align-items-center justify-content-between rounded">
        <h5 className="m-0 text-white d-flex align-items-center">
          <i
            className={`${ICON_COURSE} mr-2`}
            aria-hidden="true"
          />
          <span className="mr-2">{classTitle}</span>
        </h5>
        {classId && showPreviewBtn && (
          <EntryButton
            title="Preview"
            classId={classId}
            className="btn btn-sm btn-outline-white text-nowrap"
          />
        )}
      </div>
    </div>
  );
};

export default CourseHeader;
