import {
  fetchApprenticeAgreements,
  findApprenticeAgreement
} from '../services/apprenticeAgreements';
import {
  SET_APPRENTICE_AGREEMENT,
  RESET_APPRENTICE_AGREEMENT,
  SET_APPRENTICE_AGREEMENTS,
  RESET_APPRENTICE_AGREEMENTS
} from './types';

export const setApprenticeAgreement = ({
  list,
  userProfileId,
  apprenticeshipId
}) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      const apprenticeAgreement = findApprenticeAgreement({
        list,
        userProfileId,
        apprenticeshipId
      });

      dispatch({
        type: SET_APPRENTICE_AGREEMENT,
        apprenticeAgreement
      });

      resolve(apprenticeAgreement);
    });
  };
};

export const getApprenticeAgreements = ({
  ids,
  userProfileId,
  userId,
  apprenticeshipId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeAgreements({
        ids,
        userProfileId,
        userId,
        apprenticeshipId
      })
        .then((list) => {
          dispatch({
            type: SET_APPRENTICE_AGREEMENTS,
            list
          });

          if (userProfileId && apprenticeshipId) {
            dispatch(
              setApprenticeAgreement({
                list,
                userProfileId,
                apprenticeshipId
              })
            );
          }

          resolve(list);
        })
        .catch((error) => {
          console.error('getApprenticeAgreements', error);
          reject(error);
        });
    });
  };
};

export const resetApprenticeAgreement = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICE_AGREEMENT });
  };
};

export const resetApprenticeAgreements = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICE_AGREEMENTS });
  };
};
