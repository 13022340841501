import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ORG_CONSOLE_USERS,
  ORG_CONSOLE_USERS_GROUPS
} from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import Switch from '../../Switch';

const Tabs = ({
  active,
  className = 'mt-3',
  toggleSkillsColumn,
  showSkillsColumn
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const users = useSelector((state) => state.users);
  const userGroups = useSelector((state) => state.userGroups);
  const dictionary = useSelector((state) => state.dictionary);
  // Misc
  const orgId = organization?.id || '';
  const enableUserGroups = organization?.enableUserGroups || false;

  return (
    <div className={`${className} position-relative`}>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link d-flex align-items-center ${active === 'all' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: ORG_CONSOLE_USERS,
              orgId
            })}
            title="All Users"
          >
            <span>Users</span>
            {users?.pagination && (
              <span className="badge bg-white border ml-2 text-ships-officer">
                {users?.pagination?.total || 0}
              </span>
            )}
          </Link>
        </li>

        {enableUserGroups && (
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'groups' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: ORG_CONSOLE_USERS_GROUPS,
                orgId
              })}
              title={dictionary?.userGroups}
            >
              <span>{dictionary?.userGroups}</span>
              <span className="badge bg-white border ml-2 text-ships-officer">
                {userGroups?.list ? userGroups?.list.length : 0}
              </span>
            </Link>
          </li>
        )}
      </ul>

      {active === 'all' && (
        <div
          className="position-absolute"
          style={{
            top: '-5px',
            right: '0'
          }}
        >
          <Switch
            id="showSkillsCol"
            className="mb-3"
            label="Show Skills"
            value={showSkillsColumn}
            onChange={() => {
              toggleSkillsColumn();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Tabs;
