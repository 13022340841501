import { getClient, flattenItem } from '../services/contentful';
import { RESET_E_UNIT_TRACKING, SET_E_UNIT_TRACKING } from './types';

export const setEUnitTracking = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getClient()
        .getEntry(id)
        .then((response) => {
          const eUnitTracking = response ? flattenItem(response) : null;
          const sessionCredits =
            eUnitTracking?.integration?.sessionCredits || null;
          const cdnFiles = eUnitTracking?.integration?.cdnFiles || null;

          dispatch({
            type: SET_E_UNIT_TRACKING,
            eUnitTracking,
            sessionCredits,
            cdnFiles
          });
          resolve(eUnitTracking);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetEUnitTracking = () => {
  return (dispatch) => {
    dispatch({ type: RESET_E_UNIT_TRACKING });
  };
};
