import {
  isAdminOwnerSuperAdmin,
  isProgramManager,
  isProgramTrainer
} from '@apprentage/utils';

export default function canReviewLogbookRecords(role = []) {
  return (
    isProgramTrainer(role) ||
    isProgramManager(role) ||
    isAdminOwnerSuperAdmin(role)
  );
}
