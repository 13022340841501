import { pathOr } from 'ramda';

const sanitize = (str) => {
  // Remove New Lines, Tabs, Returns
  // Example value of correct and incorrect feedback text
  // "<p class=\"MsoNormal\" style=\"margin: 0in 0in 10pt\"><span style=\"font-family: \"><font size=\"2\">Click on link to review the resource </font><a href=\"http://chemistry.about.com/od/chemistryglossary/g/Asphyxiant-Definition.htm\" target=\"_blank\"><font size=\"2\">HERE</font></a><font size=\"2\">. </font></span></p>\\n<p></p>\\n<p></p>\\n<p></p>"
  let sanitizedStr = str.replace(/(\r\n|\n|\r)/gm, ' ');
  sanitizedStr = sanitizedStr.replace(/\\n/g, '');

  return sanitizedStr;
};

const parseTrueFalse = (question) => {
  const { isTrue } = question;

  const correctFeedback = pathOr(
    undefined,
    ['correctFeedback', 'text'],
    question
  );
  const incorrectFeedback = pathOr(
    undefined,
    ['incorrectFeedback', 'text'],
    question
  );
  const correctResponse = correctFeedback
    ? sanitize(correctFeedback)
    : undefined;
  const incorrectResponse = incorrectFeedback
    ? sanitize(incorrectFeedback)
    : undefined;

  const data = [
    {
      text: 'True',
      ...(isTrue === true ? { correct: true } : {}),
      ...(isTrue === true && correctResponse ? { correctResponse } : {}),
      ...(isTrue === false && incorrectResponse ? { incorrectResponse } : {})
    },
    {
      text: 'False',
      ...(isTrue === false ? { correct: true } : {}),
      ...(isTrue === false && correctResponse ? { correctResponse } : {}),
      ...(isTrue === true && incorrectResponse ? { incorrectResponse } : {})
    }
  ];

  return data;
};

export default parseTrueFalse;
