import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import DocumentsList from '@apprentage/components/dist/components/DocumentsList';
import { toast } from 'react-toastify';
import { withAuthorization } from '../../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../../actions/Apprenticeships';
import {
  getEmployer,
  resetEmployer,
  resetWageSchedules
} from '../../../../../../actions/Employers';
import {
  getEmployerAgreements,
  resetEmployerAgreement,
  resetEmployerAgreements
} from '../../../../../../actions/EmployerAgreements';
import { generatePdfFromUrlLink } from '../../../../../../services/createPdf';
import { generateDocumentLink } from '../../../../../../services/consoleDocuments';
import { setCurrentModal } from '../../../../../../actions/Modals';
import {
  ICON_TRAINING_PROGRAMS,
  IMAGE_NO_DOCUMENTS
} from '../../../../../../constants/assets';
import withOrgConsole from '../../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../../OrgConsoleSubheader';
import Tabs from '../../Tabs';
import Loading from '../../../../../Loading';
import EmployerTabs from '../EmployerTabs';

const ApprenticeshipEmployerDocuments = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employer = useSelector((state) => state.employer);
  const employerProfile = useSelector((state) => state.employerProfile);
  const employerAgreements = useSelector((state) => state.employerAgreements);

  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipEmployerId = params?.apprenticeshipEmployerId || null;
  // Employer

  // Employer Profile
  const employerProfileId = employerProfile?.id || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    dispatch(resetWageSchedules());
    dispatch(resetEmployerAgreements());

    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    if (apprenticeshipEmployerId) {
      dispatch(getEmployer(apprenticeshipEmployerId)).then(
        (apprenticeshipEmployer) => {
          if (apprenticeshipEmployer?.employerProfileId && apprenticeshipId) {
            dispatch(
              getEmployerAgreements({
                employerProfileId: apprenticeshipEmployer?.employerProfileId,
                apprenticeshipId
              })
            );
          }
        }
      );
    }

    return function cleanup() {
      const { href } = window ? window.location : undefined;

      dispatch(resetEmployer());
      dispatch(resetEmployerAgreement());

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, []);

  useEffect(() => {
    if (employerProfileId && apprenticeshipId) {
      dispatch(
        getEmployerAgreements({
          employerProfileId,
          apprenticeshipId
        })
      );
    }
  }, [employerProfileId]);

  if (apprenticeshipId && !apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  if (apprenticeshipEmployerId && !employer.id) {
    return <Loading text="Loading Employer..." />;
  }

  if (employerProfileId && !employerProfile.id) {
    return <Loading text="Loading Workforce Info..." />;
  }

  const documentLink = (collection, id, accessCode) => {
    return generateDocumentLink({
      collection,
      id,
      accessCode,
      preview: true
    });
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="employers" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle={employer.name}
              route={`${apprenticeshipRoute}/employers`}
              locationImage={employer.locationImage}
            />

            <EmployerTabs active="docs" />

            <div className="border-left border-right border-bottom">
              {employerAgreements.list == null ||
              employerAgreements?.list?.length === 0 ? (
                <div className="p-3 d-flex flex-column align-items-center justify-content-center">
                  <div>
                    <img
                      src={IMAGE_NO_DOCUMENTS}
                      alt="No Documents"
                      width="65"
                      className="my-3"
                    />
                  </div>
                  <div>No Documents</div>
                </div>
              ) : (
                <DocumentsList
                  tableData={employerAgreements.list}
                  handlePreview={(item) => {
                    dispatch(
                      setCurrentModal({
                        key: 'fullScreenIframe',
                        data: {
                          modalTitle: item.original.name,
                          iframeSrc: documentLink(
                            'employerAgreements',
                            item.original.id,
                            item.original.accessCode
                          )
                        }
                      })
                    );
                  }}
                  handleDownload={(item) => {
                    const link = generatePdfFromUrlLink({
                      url: documentLink(
                        'employerAgreements',
                        item.original.id,
                        item.original.accessCode
                      ),
                      name: item.original.name
                    });
                    toast.info('Generating PDF...');
                    setTimeout(() => {
                      window.open(link);
                    }, 500);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipEmployerDocuments);
