import React from 'react';
import Table from './Table';
import SimilarityCell from './SimilarityCell';
import RawTextCell from './RawTextCell';
import PageNumberCell from './PageNumberCell';

const List = ({ data, handleClickRow, hiddenColumns = [] }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Similarity θ',
        accessor: 'similarity',
        Cell: SimilarityCell
      },
      {
        Header: 'Text',
        accessor: 'rawText',
        disableSortBy: true,
        Cell: RawTextCell
      },
      {
        Header: 'Page #',
        accessor: 'pageNumber',
        Cell: PageNumberCell
      },
      {
        Header: 'Keywords',
        accessor: 'metadata',
        disableSortBy: true
      }
    ],
    []
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No data.</div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
    />
  );
};

export default List;
