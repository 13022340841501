import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { VELA_ICON } from '@apprentage/constants';
import ReactTooltip from 'react-tooltip';
import { FaChevronUp } from 'react-icons/fa';
import { RiShieldCheckFill } from 'react-icons/ri';
import { canInviteUser } from '../../permissions/invite';
import { daysAgo as daysAgoDate } from '../../utils/date';
import { goToElem, scrollToTop } from '../../services/manageContent';
import {
  fetchContextInsights,
  createContextInsight
} from '../../services/contextInsights';
import calculateReadingTime from '../../utils/calculateReadingTime';
import MessageAvatar from '../MessageAvatar';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import TypingText from '../TypingText';
import NoPiiAi from './NoPiiAi';
import './style.css';

const VelaInsights = ({
  className = '',
  badgeText,
  badgeClassName = '',
  showPiiBadge,
  contextScope,
  apprenticeshipId,
  orgId,
  userId,
  userName,
  userRole,
  daysAgo = 30
}) => {
  const tooltip = useRef(null);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];
  const [contextBody, setContextBody] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  // const contextBodyTeaser = useMemo(() => {
  //   if (contextBody) {
  //     return contextBody.substring(0, 50);
  //   }
  //   return '';
  // }, [contextBody]);

  const retrieveInsights = useCallback(async () => {
    const contextScopes = {
      trainingUserDashboard: true
    };

    if (contextScopes[contextScope] !== undefined) {
      fetchContextInsights({
        orgId,
        userId,
        apprenticeshipId,
        contextScope
      }).then(async (rsp) => {
        if (Array.isArray(rsp.items) && rsp.items.length > 0) {
          setContextBody(rsp.items[0].body);
        } else {
          const response = await createContextInsight({
            contextScope,
            userId,
            userName,
            userRole,
            apprenticeshipId,
            orgId,
            gteDate: daysAgoDate(daysAgo).toISOString(),
            daysAgo
          });

          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();

          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { value, done } = await reader.read();
            if (done) {
              break;
            }

            setContextBody((prev) => {
              const newValue = prev + value;

              if (newValue && newValue.includes('[UserName]')) {
                return newValue.replace(/\[UserName\]/g, userName);
              }

              return newValue;
            });
          }
        }
      });
    }
  }, [apprenticeshipId, contextScope, userName, orgId, userId]);

  const onClick = () => {
    const newValue = !showDetails;

    if (newValue === false) {
      scrollToTop();
    }
    setShowDetails(newValue);
  };

  useEffect(() => {
    if (contextScope === 'trainingUserDashboard') {
      retrieveInsights();
    }
  }, [contextScope, retrieveInsights]);

  useEffect(() => {
    // https://www.npmjs.com/package/react-tooltip/v/4.5.1#3-tooltip-not-binding-to-dynamic-content
    if (showDetails) {
      ReactTooltip.rebuild();
    }
  }, [showDetails]);

  return (
    <>
      {canInviteUser(role) && (
        <div
          id="velaInsights"
          className={`VelaInsights ${showDetails ? '' : 'vela-insights-row-clickable'} border p-3 rounded ${className}`}
          onClick={showDetails ? undefined : onClick}
          aria-hidden
        >
          <div className="d-flex align-items-start">
            <MessageAvatar
              height={30}
              width={30}
              className="d-flex align-items-center justify-content-center mt-1 text-white"
              style={{
                background: 'black',
                border: '1px solid #4b4b4b'
              }}
            >
              <img
                src={VELA_ICON}
                alt="VELA"
                height={20}
                style={{
                  height: '20px',
                  flexGrow: 0,
                  flexShrink: 0
                }}
              />
            </MessageAvatar>
            <div className="ml-2">
              <div className="small">VELA</div>

              {showDetails && (
                <>
                  {contextBody ? (
                    <>
                      <RenderMarkdown
                        source={contextBody}
                        className="vela-insights-text"
                        {...(showDetails
                          ? {}
                          : { style: { height: '25px', overflow: 'hidden' } })}
                      />
                      <NoPiiAi />
                    </>
                  ) : (
                    <div className="three-dot-loader vertical-align-top m-0" />
                  )}
                </>
              )}

              {!showDetails && (
                <>
                  {contextBody ? (
                    <TypingText
                      text={contextBody}
                      ms={30}
                      className="vela-insights-text hide-details"
                      {...(showDetails
                        ? {}
                        : { style: { height: '25px', overflow: 'hidden' } })}
                    />
                  ) : (
                    <div className="three-dot-loader vertical-align-top m-0" />
                  )}
                </>
              )}

              {/* {!showDetails && (
                <button
                  className="btn btn-link text-ships-officer d-flex justify-content-center w-100 pt-3 pb-0"
                  type="button"
                  aria-label="View Project Details"
                  // onClick={() => {
                  //   setShowDetails(true);
                  // }}
                >
                  <FaChevronDown size={30} />
                </button>
              )} */}
              {showDetails && (
                <div className="position-relative">
                  <button
                    className="btn btn-link text-ships-officer d-flex justify-content-center w-100 pt-3 pb-0"
                    type="button"
                    aria-label="Hide Project Details"
                    onClick={onClick}
                    data-tip="Click to minimize"
                    data-for="VelaInsights"
                  >
                    <FaChevronUp size={30} />
                  </button>
                </div>
              )}
            </div>
          </div>

          {(showPiiBadge || badgeText) && (
            <div className="velaInsightsBadges d-flex align-items-center">
              {showPiiBadge && showDetails && (
                <div className="h6 mb-0 mr-2">
                  <div
                    className="jiggle-element badge border border-primary d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      goToElem('NoPiiAi');
                    }}
                    // data-tooltip-content
                    // data-tooltip-id="VelaInsights"
                    data-tip="Click to learn more"
                    data-for="VelaInsights"
                  >
                    <RiShieldCheckFill
                      // size={1}
                      className="text-primary mr-1"
                    />
                    <strong>No PII in AI</strong>
                  </div>
                </div>
              )}

              {badgeText && (
                <div className="velaInsightsBadge h6 m-0">
                  <div className={`badge ${badgeClassName}`}>
                    {calculateReadingTime(contextBody)} min. read
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <ReactTooltip
        id="VelaInsights"
        ref={tooltip}
        html
      />
    </>
  );
};

export default VelaInsights;
