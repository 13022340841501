import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FaArchive, FaEllipsisH, FaSyncAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ConfirmationToast from '../../../Toasts/ConfirmationToast';
import { updateSearch } from '../../../../services/searches';
import { getSearches } from '../../../../actions/Searches';
import { SEARCH_CONTENT } from '../../../../constants/routes';
import { dayMdyDate } from '../../../../utils/date';
import { resetSearch, setSearchValue } from '../../../../actions/Search';

const MenuCell = ({ row, column }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Searches
  const createdAt = row?.original?.createdAt || '';
  const query = row?.original?.query || '';
  const id = row?.original?.id || null;
  // Local State
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div className="btn-group align-items-center">
          <button
            className="btn btn-white rounded bg-white py-1 border border-1"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            aria-label="Menu"
            title="Menu"
          >
            <FaEllipsisH />
          </button>
          <div className="dropdown-menu dropdown-menu-right p-0">
            <div className="bg-dark px-4 py-2 text-white text-nowrap">
              {dayMdyDate(createdAt)}
            </div>
            <button
              className="dropdown-item p-2"
              type="button"
              onClick={() => {
                dispatch(resetSearch());
                dispatch(
                  setSearchValue({
                    searchValue: query
                  })
                ).then(() => {
                  history.push(SEARCH_CONTENT);
                });
              }}
            >
              <span className="d-flex align-items-center">
                <FaSyncAlt />
                <span className="ml-2">Repeat Search</span>
              </span>
            </button>
            <button
              className="dropdown-item p-2"
              type="button"
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
            >
              <span className="d-flex align-items-center">
                <FaArchive />
                <span className="ml-2">Archive</span>
              </span>
            </button>
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <ConfirmationToast
          title="Are you sure?"
          primaryActionBtnText="Yes, archive"
          onConfirm={() => {
            toast.info('Archiving...', { toastId: 'confirmDeleteSearchEntry' });
            updateSearch({ archived: true }, id).then(() => {
              toast.dismiss('confirmDeleteSearchEntry');

              dispatch(getSearches(column?.config));
              setShowDeleteConfirm(false);
            });
          }}
          handleCancel={() => {
            setShowDeleteConfirm(false);
          }}
          handleClose={() => {
            setShowDeleteConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default MenuCell;
