import React from 'react';
import { FaCircle } from 'react-icons/fa';
import NameIcon from './NameIcon';

const ResourceIcon = ({ type, url, name, isEmbedded }) => {
  return (
    <div className="position-relative">
      <NameIcon
        type={type}
        url={url}
        name={name}
      />

      {isEmbedded && (
        <div
          className="position-absolute bg-white ml-2"
          style={{
            padding: '2px',
            borderRadius: '100%',
            top: '-3px',
            left: '10px',
            lineHeight: 0
          }}
        >
          <FaCircle
            size={7}
            className="text-keppel d-inline"
            title="Context-Aware Search Engine"
          />
        </div>
      )}
    </div>
  );
};

export default ResourceIcon;
