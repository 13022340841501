import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MANAGE_CURRENT_ENTRY } from '../../../../constants/routes';
import { canManageCourseContent } from '../../../../services/currentUser';
import { manageEntry } from '../../../../services/entry';

const TopicCell = ({ value: topicId }) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const courseTopics = useSelector((state) => state.courseTopics);
  const course = useSelector((state) => state.course);
  // Organization
  const orgType = organization?.type || null;

  if (!courseTopics.list) {
    return null;
  }

  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  const topic = courseTopics.list.find((t) => t.id === topicId);
  let topicTitle = topicId;

  if (topic?.id) {
    topicTitle = topic?.title;
  }

  return (
    <>
      {manageCourse && course?.id ? (
        <Link
          to={{
            pathname: MANAGE_CURRENT_ENTRY,
            search: manageEntry({
              manageType: 'edit',
              entryId: topicId,
              topicId,
              classId: course?.id,
              contentType: 'topic'
            })
          }}
          target="_blank"
          rel="noopener noreferrer"
          title={`Edit ${topicTitle}`}
          className="btn-link d-flex align-items-center"
        >
          <div
            className="overflow-hidden text-nowrap text-ships-officer"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '250px'
            }}
          >
            {topicTitle}
          </div>
          <FaExternalLinkAlt
            size={12}
            className="ml-1 text-ships-officer"
          />
        </Link>
      ) : (
        <div
          className="overflow-hidden text-nowrap"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '250px'
          }}
        >
          {topicTitle}
        </div>
      )}
    </>
  );
};

export default TopicCell;
