import {
  RESET_SYNDICATED_COURSE_ORGS,
  SET_SYNDICATED_COURSE_ORGS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function syndicatedCourseOrgs(state = initialState, action) {
  switch (action.type) {
    case SET_SYNDICATED_COURSE_ORGS: {
      return {
        ...state,
        list: action.list,
        fetched: true
      };
    }
    case RESET_SYNDICATED_COURSE_ORGS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
