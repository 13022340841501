import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { RiInputField } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { createContextQuestion } from '../../../services/contextQuestions';
import DotMenu from './DotMenu';
import Form from './Form';
// import Tabs from './Tabs';
import './style.css';

const ManageContextQuestionSideSheet = ({ className = '' }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const contextQuestion = useSelector((state) => state.contextQuestion);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Side Sheet
  const modalTitle = sideSheet?.data?.modalTitle || 'Context Question';
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(contextQuestion?.title || '');
  const [questionType, setQuestionType] = useState(
    contextQuestion?.questionType || ''
  );
  const [locationIds, setLocationIds] = useState(null);
  const [userRoles, setUserRoles] = useState(null);

  const buttonText = useMemo(() => {
    if (loading) {
      return contextQuestion?.id ? 'Updating...' : 'Creating...';
    }

    return contextQuestion?.id ? 'Update' : 'Create';
  }, [contextQuestion?.id, loading]);

  const onClickSave = (data) => {
    const trimmedBody = (title || '').trim();
    if (!questionType) {
      toast.error('Question type is required');
      return;
    }

    if (!trimmedBody) {
      toast.error('Question is required');
      return;
    }

    if (contextQuestion?.id) {
      // Edit
      return;
    }

    setLoading(true);
    createContextQuestion({
      orgId,
      contextQuestion: data.contextQuestion,
      title: trimmedBody
    });
  };

  return (
    <div className={`card ${className}`}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <RiInputField size={20} />
            <span className="ml-2 text-nowrap">{modalTitle}</span>
          </span>
        </h6>
        <DotMenu />
      </div>

      {/* <Tabs
        cardStyle
        className='pt-3'
        activeTab={activeTab}
        setActiveTab={(tab) => {
          setActiveTab(tab);
        }}
      /> */}

      <div className="card-body position-relative overflow-y-scroll">
        <Form
          title={title}
          setTitle={setTitle}
          questionType={questionType}
          setQuestionType={setQuestionType}
          locationIds={locationIds}
          setLocationIds={setLocationIds}
          userRoles={userRoles}
          setUserRoles={setUserRoles}
        />
      </div>
      {/* <div>
        <QuestionsAnswersForm
          showNext={false}
          showPrevious={false}
          data={[
            {
              title: body,
              questionId: 'xyz',
              questionType: 'textarea'
            }
          ]}
        />
      </div> */}
      <div className="card-footer">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={onClickSave}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default ManageContextQuestionSideSheet;
