import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(relativeTime);
dayjs.extend(timezone);

export const todaysDate = () => {
  return dayjs().format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dateTimestamp = () => {
  // { utcHours }
  const date = new Date();
  const timezoneOffset = date.getMinutes() + date.getTimezoneOffset();
  const timestamp = date.getTime() + timezoneOffset * 1000;
  const correctDate = new Date(timestamp);

  // if (utcHours === true) {
  //   correctDate.setUTCHours(0, 0, 0, 0);
  // }

  return correctDate.toISOString();
};

export const fromNowDate = (timestamp) => {
  dayjs.extend(relativeTime);

  return dayjs(timestamp).fromNow();
};

export const isAfterToday = (timestamp) => {
  return dayjs().isBefore(dayjs(timestamp), 'day');
};

export const mdyDate = (timestamp) => {
  return dayjs(timestamp).format('MMM. D, YYYY', {
    timeZone: dayjs.tz.guess()
  }); // 'America/New_York'
};

export const MMMDate = (timestamp) => {
  return dayjs(timestamp).format('MMM', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const mdDate = (timestamp) => {
  return dayjs(timestamp).format('MMM. D', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dayMdyDate = (timestamp) => {
  return dayjs(timestamp).format('ddd., MMM. D, YYYY', {
    timeZone: dayjs.tz.guess()
  }); // 'America/New_York'
};

export const mdySimple = (timestamp, separator = '/') => {
  return dayjs(timestamp).format(`MM${separator}DD${separator}YYYY`); // '01/25/2020'
};

export const ySimple = (timestamp) => {
  return dayjs(timestamp).format('YYYY'); // '2020'
};

export const hrMin = (timestamp) => {
  return dayjs(timestamp).format('h:mm A', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const parseUnix = (unixTimestamp) => {
  return dayjs.unix(unixTimestamp);
};

export const numDaysTillUnixDate = (unixDate) => {
  const today = dayjs();
  const numDays = parseUnix(unixDate).diff(today, 'day', true); // 7.645161290322581

  return numDays;
};

export const daysAgo = (numDays) => {
  return new Date(Date.now() - numDays * 24 * 60 * 60 * 1000);
};
