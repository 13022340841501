import React from 'react';
import { Link } from 'react-router-dom';
import { FaPencilAlt } from 'react-icons/fa';
import { manageEntry } from '../../../services/entry';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';

const ManageButton = (props) => {
  const { children = null } = props;

  if (children) {
    return (
      <div className="btn-group">
        <LinkButton {...props} />
        <button
          type="button"
          className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        <div className="dropdown-menu m-0 p-0">{children}</div>
      </div>
    );
  }

  return <LinkButton {...props} />;
};

export default ManageButton;

const LinkButton = ({
  scrollToId,
  clickButtonId,
  manageType,
  buttonText = 'NEW',
  referenceIndex = 0,
  pointerId,
  classId,
  topicId,
  entryId,
  contentType,
  addContent,
  addTypes,
  className,
  disabled,
  iconComponent = () => <FaPencilAlt className="mr-1" />
}) => {
  return (
    <Link
      disabled={disabled}
      className={`${className || 'text-capitalize'} ${buttonText ? '' : 'text-capitalize'}`}
      to={{
        pathname: MANAGE_CURRENT_ENTRY,
        search: manageEntry({
          scrollToId,
          clickButtonId,
          manageType,
          referenceIndex,
          classId,
          topicId,
          pointerId,
          addContent,
          addTypes,
          entryId,
          contentType
        })
      }}
    >
      <span className="d-flex align-items-center">
        {iconComponent()}
        <span className="ml-2">{buttonText || manageType}</span>
      </span>
    </Link>
  );
};
