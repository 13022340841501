import React from 'react';
import { useSelector } from 'react-redux';

const Tabs = ({
  className = '',
  activeTab,
  setActiveTab,
  cardStyle = false,
  setListConfig
  // setLocationIds
}) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;

  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'system' && 'active'}`}
            type="button"
            disabled={activeTab === 'system'}
            onClick={() => {
              setActiveTab('system');
              setListConfig((prev) => ({
                ...prev,
                orgId: null,
                locationId: null
              }));
              // setLocationIds(null);
            }}
          >
            System
          </button>
        </li>

        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'org' && 'active'}`}
            type="button"
            disabled={activeTab === 'org'}
            onClick={() => {
              setActiveTab('org');
              setListConfig((prev) => ({
                ...prev,
                orgId,
                locationIds: null
              }));
              // setLocationIds(null);
            }}
          >
            Organization
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
