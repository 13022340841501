import * as twApi from './tw-api';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import {
  API_CONTEXT_QUESTIONS_UNANSWERED,
  DEFAULT_PAGINATION_LIMIT
} from '../constants/api';

const table = 'contextQuestions';

export async function fetchUnansweredQuestions({
  orgId,
  userId,
  version = 'v1'
}) {
  const params = {
    orgId,
    ...(userId ? { userId } : {})
  };
  const urlParams = new URLSearchParams(params);
  const url = `/${version}${API_CONTEXT_QUESTIONS_UNANSWERED}`;
  const response = await twApi.get(`${url}?${urlParams.toString()}`);

  return response;
}

export const fetchContextQuestions = async ({
  orgId,
  locationIds,
  userId,
  order = 'createdAt',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    orgId,
    order,
    page,
    limit
  };

  if (orgId !== undefined) {
    if (orgId === null) {
      params['f.orgId[is]'] = null;
    } else {
      params['f.orgId[eq]'] = orgId;
    }
  }

  if (locationIds !== undefined) {
    if (Array.isArray(locationIds) && locationIds.length > 0) {
      params['f.locationIds[ov]'] = locationIds.join(',');
    } else if (locationIds === null) {
      params.locationIds = null;
    }
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchContextQuestion = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateContextQuestion = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createContextQuestion = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteContextQuestion = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
