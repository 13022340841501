import {
  REMOVE_USER_FROM_LIST,
  RESET_USERS,
  SET_USERS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  pagination: null
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    case RESET_USERS:
      return {
        ...state,
        ...initialState
      };
    case REMOVE_USER_FROM_LIST:
      return {
        ...state,
        list: state.list.filter((user) => action.userId !== user.id)
      };
    default:
      return state;
  }
}
