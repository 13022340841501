import React from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';
import TitleCell from './TitleCell';
import ItemIdsCell from './ItemIdsCell';
import UserRolesCell from '../../ManageContent/Tables/UserRolesCell';

const List = ({ data, className = '' }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const courseGroupIds = organization?.courseGroupIds || null;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: (props) => <TitleCell {...props} />
      },
      {
        Header: 'Access',
        accessor: 'userRoles',
        Cell: (props) => <UserRolesCell {...props} />
      },
      {
        Header: 'Courses',
        accessor: 'itemIds',
        Cell: (props) => <ItemIdsCell {...props} />
      }
    ],
    [courseGroupIds]
  );

  if (!data || (data && data.length === 0)) {
    return null;
  }

  return (
    <div
      data-cy="groupsList"
      className={className}
    >
      <Table
        columns={columns}
        data={data}
      />
    </div>
  );
};

export default List;
