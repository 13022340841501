import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { VELA_ICON } from '@apprentage/constants';
import RecordsList from '../../../../lists/RecordsList';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import heapAnalytics from '../../../../../utils/heapAnalytics';
import groupDataByDate from '../../../../../utils/groupDataByDate';
import sortArrByDate from '../../../../../utils/sortArrByDate';
import { resetSideSheet } from '../../../../../actions/SideSheets';
import { findRecordsByDate } from '../../../../../services/apprenticeships';

const DailyRecords = ({ className }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Apprenticeship
  const apprenticeshipId = apprenticeship.id || '';
  const recordsDate = sideSheet?.data?.recordsDate || null;

  const onClickName = (userId) => {
    dispatch(resetSideSheet());
    heapAnalytics.track('Training Program - Dashboard - User Records - Open');

    history.push(
      `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/users/${userId}/records`
    );
  };

  const rawData = useMemo(() => {
    const allRecords = logbookRecords?.list || [];
    const groupedByDate = groupDataByDate(allRecords);

    return sortArrByDate(groupedByDate, false);
  }, [logbookRecords?.list]);

  const recordsByDateData = useMemo(() => {
    if (
      recordsDate &&
      Array.isArray(logbookRecords.list) &&
      logbookRecords.list.length > 0
    ) {
      return findRecordsByDate(rawData, recordsDate);
    }

    return [];
  }, [logbookRecords.list, rawData, recordsDate]);

  return (
    <div className={className}>
      <div className="card border-left-0 border-right-0 border-bottom-0 h-100">
        <div className="card-header d-flex align-items-center justify-content-between border-right border-left">
          <div className="h6 m-0 d-flex align-items-center font-weight-bold">
            <img
              src={VELA_ICON}
              alt="Voice Enabled Logbook Assistant"
              height="20"
              style={{
                height: '20px'
              }}
            />
            <span className="ml-2">Daily Records</span>
          </div>
        </div>
        <div className="card-body p-0 h-100">
          <RecordsList
            list={recordsByDateData || []}
            className="h-100 overflow-y-scroll"
            onClickName={onClickName}
            callbackPrimaryAction={sideSheet?.data?.callbackPrimaryAction}
            ariaExpanded
          />
        </div>
      </div>
    </div>
  );
};

export default DailyRecords;
