import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { OrgLogo } from '@apprentage/components';
import { toast } from 'react-toastify';
import Switch from '../../../components/Switch';
import { updatePage } from '../../../services/pages';

const Logo = ({ imageHeight = 45, onSuccessCallback }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const page = useSelector((state) => state.page);
  // Local State
  const [loading, setLoading] = useState(false);
  const [showOrgLogo, setShowOrgLogo] = useState(page?.showOrgLogo || false);

  const errorSaveAssistant = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.', {
      autoClose: false,
      toastId: 'errorSaveAssistant'
    });
    setShowOrgLogo(!showOrgLogo);
    setLoading(false);
  };

  const handleOnChange = () => {
    setLoading(true);
    const value = !showOrgLogo;

    setShowOrgLogo(value);

    updatePage({ showOrgLogo: value === false ? null : true }, page?.id)
      .then((response) => {
        if (response?.error) {
          errorSaveAssistant(response?.error);
          return;
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }
        toast.success('Logo visibility updated!');
        setLoading(false);
      })
      .catch((error) => {
        errorSaveAssistant(error);
      });
  };

  return (
    <div className="border p-3 mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <OrgLogo
          src={organization?.orgLogo}
          alt={organization?.name}
          height={imageHeight}
        />
        <Switch
          id="showOrgLogo"
          // label="Visible"
          disabled={loading}
          value={showOrgLogo}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default Logo;
