import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SearchResultsSkeleton = ({ className = 'mt-3' }) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-header">
        <h5 className="m-0">
          <Skeleton style={{ width: '100px' }} />
        </h5>
      </div>
      <div className="card-body p-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                <Skeleton />
              </th>
              <th scope="col">
                <Skeleton />
              </th>
              <th scope="col">
                <Skeleton />
              </th>
              <th scope="col">
                <Skeleton />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td colSpan="2">
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchResultsSkeleton;
