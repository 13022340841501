import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { FaUserEdit } from 'react-icons/fa';
import { getDirectoryUserProfile } from '../../../../actions/Directory';
import {
  canManageDirectoryUsers,
  canManageUserPII
} from '../../../../services/currentUser';
import { SLASH } from '../../../../constants/routes';
import Loading from '../../../Loading';
import Tabs from './Tabs';
import ProfileForm from './ProfileForm';
import PrivateInfoForm from './PrivateInfoForm';
import ManageSupervisors from './ManageSupervisors';
import './style.css'; // TODO use styled components

const EditForms = ({ handleCancel }) => {
  const params = useParams();
  const dispatch = useDispatch();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  const directoryUserProfile = useSelector(
    (state) => state.directoryUserProfile
  );
  // Params
  const deepLinkActiveTab = params?.deepLinkActiveTab || null;
  const directoryUserName = directoryUser?.name || null;
  // User
  const userId = currentUser?.id || null;
  const role = currentUser?.role || [];
  // Organization
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  // Misc
  const title =
    directoryUser?.id === userId ? 'My Information' : directoryUserName;
  // Local State
  const [activeTab, setActiveTab] = useState(deepLinkActiveTab || 'profile');

  useEffect(() => {
    if (directoryUser?.userProfileId && !directoryUserProfile?.id) {
      dispatch(getDirectoryUserProfile(directoryUser.userProfileId));
    }
  }, []);

  if (!directoryUser?.id) {
    return;
  }

  if (
    !canManageDirectoryUsers(role) &&
    !canManageUserPII(role, enableWorkforceSuite) &&
    directoryUser?.id !== userId
  ) {
    return <Redirect to={SLASH} />;
  }

  return (
    <div className="card h-100">
      <div className="card-header bg-light">
        <h6 className="m-0 font-weight-bold d-flex align-items-center">
          <FaUserEdit size={20} />
          <span className="ml-1">{title}</span>
        </h6>
      </div>

      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === 'profile' && (
        <div
          className="card-content"
          id="userProfileCollapse"
        >
          <ProfileForm handleCancel={handleCancel} />
        </div>
      )}

      {activeTab === 'supervisors' && (
        <div
          className="card-content"
          id="userSupervisorsCollapse"
          style={{
            overflowY: 'scroll'
          }}
        >
          <ManageSupervisors handleCancel={handleCancel} />
        </div>
      )}

      {activeTab === 'privateInfo' && (
        <div
          className="card-content"
          id="userApprenticeInfoCollapse"
        >
          {directoryUser?.userProfileId && !directoryUserProfile?.id ? (
            <Loading
              text="Decrypting..."
              className="bg-white text-muted text-md"
            />
          ) : (
            <PrivateInfoForm handleCancel={handleCancel} />
          )}
        </div>
      )}
    </div>
  );
};

export default EditForms;
