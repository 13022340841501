import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaChevronRight, FaPlus } from 'react-icons/fa';
import { getMaterial, getMaterials } from '../../actions/Materials';
import { setSideSheet } from '../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../constants/sideSheets';
import { createMaterial } from '../../services/materials';
import { mdySimple } from '../../utils/date';
import MaterialIcon from '../ManageContent/MaterialIcon';

const MyMaterials = ({
  listConfig,
  className = '',
  title,
  confirmMoveAfterCreate = false,
  selectable,
  onSelect,
  creatable = false,
  cardStyle = false,
  children
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const materials = useSelector((state) => state.materials);
  // Organization
  const orgId = organization?.id || null;

  const retrieveMaterials = useCallback(() => {
    dispatch(getMaterials(listConfig));
  }, [dispatch, listConfig]);

  const openMaterial = (material) => {
    dispatch(getMaterial(material?.id)).then(() => {
      dispatch(
        setSideSheet({
          key: SHEET_KEY_MATERIAL,
          className: 'MaterialSideSheet',
          callbackPrimaryAction: () => {
            retrieveMaterials();
          }
        })
      );
    });
  };

  useEffect(() => {
    retrieveMaterials();
  }, [retrieveMaterials]);

  return (
    <div className={cardStyle ? `card h-100 ${className}` : className}>
      {(title || creatable) && (
        <div
          className={`pb-2 h5 d-flex align-items-center justify-content-between ${cardStyle ? 'card-header' : 'mb-2'}`}
        >
          {title && (
            <div className="d-flex align-items-center">
              <span>{title}</span>

              {listConfig?.isDraft && (
                <span className="h6 mb-0 mx-2">
                  <span className="badge alert-warning alert py-1 px-2 m-0">
                    drafts
                  </span>
                </span>
              )}
            </div>
          )}

          {creatable && (
            <button
              className="btn btn-sm btn-primary"
              type="button"
              aria-label="New"
              onClick={() => {
                createMaterial({
                  orgId,
                  locationId: currentUser?.locationId,
                  userId: currentUser?.id,
                  title: `${mdySimple(new Date().toISOString())} Material`,
                  isDraft: true,
                  enableNewEditor: true
                }).then((responseMaterial) => {
                  dispatch(getMaterial(responseMaterial?.id)).then(() => {
                    dispatch(
                      setSideSheet({
                        key: SHEET_KEY_MATERIAL,
                        className: 'MaterialSideSheet',
                        data: {
                          editMode: true,
                          confirmMoveAfterCreate
                        },
                        callbackPrimaryAction: () => {
                          retrieveMaterials();
                        }
                      })
                    );
                  });
                });
              }}
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-1">New</span>
              </span>
            </button>
          )}
        </div>
      )}

      {Array.isArray(materials?.list) && materials.list.length > 0 && (
        <div className={cardStyle ? 'card-body' : ''}>
          {materials.list.map((material) => (
            <div
              className="bg-white mb-2 border rounded p-2"
              key={material?.id}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center"
                  style={{
                    maxWidth: '78%'
                  }}
                >
                  <MaterialIcon
                    className="ml-1"
                    promptType={material?.promptType}
                    fileUploadFlow={material?.fileUploadFlow}
                  />
                  {selectable && (
                    <span
                      className="text-ships-officer ml-2"
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {material?.title}
                    </span>
                  )}

                  {!selectable && (
                    <button
                      className="btn-link text-ships-officer ml-2"
                      type="button"
                      onClick={() => {
                        openMaterial(material);
                      }}
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {material?.title}
                    </button>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  {/* {material?.isDraft && (
                    <span className='h6 mb-0 mx-2'>
                      <span className='badge alert-warning alert py-1 px-2 m-0'>
                        draft
                      </span>
                    </span>
                  )} */}
                  {selectable && (
                    <button
                      className="btn btn-sm btn-outline-primary"
                      type="button"
                      aria-label="View"
                      onClick={() => {
                        onSelect(material);
                      }}
                    >
                      Select
                    </button>
                  )}

                  {!selectable && (
                    <button
                      className="btn btn-sm btn-white"
                      type="button"
                      aria-label="View"
                      onClick={() => {
                        openMaterial(material);
                      }}
                    >
                      <FaChevronRight />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {children || null}
    </div>
  );
};

export default MyMaterials;
