import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { FaBroadcastTower } from 'react-icons/fa';
import CoursesList from '../../../CoursesList';
import Collapse from '../../../ManageContent/Collapse';

const SharedOnTurbine = () => {
  // Redux
  const courses = useSelector((state) => state.courses);
  // Misc
  const syndicatedCoursesSharing = pathOr(
    [],
    ['syndicatedCoursesSharing'],
    courses
  );

  return (
    <Collapse
      title="Outbound"
      id="courseSyndicated"
      badge={() => {
        return (
          <span
            className="pl-2 font-weight-bold d-flex"
            title="Syndicated"
          >
            <span className="badge badge-info">
              <FaBroadcastTower />
            </span>
          </span>
        );
      }}
    >
      <CoursesList list={syndicatedCoursesSharing} />
    </Collapse>
  );
};

export default SharedOnTurbine;
