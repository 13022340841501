import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLogbooks } from '../../../actions/Logbooks';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_LOGBOOK_BASE } from '../../../constants/routes';
import Loading from '../../Loading';
import List from './List';

const LogbooksList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const logbooks = useSelector((state) => state.logbooks);
  // misc
  const orgId = organization?.id || '';

  const handleClickRow = (logbook) => {
    const route = routeWithOrgId({
      route: `${ORG_CONSOLE_LOGBOOK_BASE}/${logbook.id}`,
      orgId
    });

    history.push(route);
  };

  useEffect(() => {
    if (orgId) {
      dispatch(getLogbooks({ orgId }));
    }
  }, [dispatch, orgId]);

  if (!logbooks.fetched && !logbooks.list) {
    return <Loading text="Loading Logbooks..." />;
  }

  return (
    <div className="logbooks-list">
      <List
        handleClickRow={handleClickRow}
        data={logbooks.list}
      />
    </div>
  );
};

export default LogbooksList;
