import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ICON_CIRCLE_ARROW_LEFT } from '@apprentage/constants';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';
import { manageEntry } from '../../../services/entry';
import './style.css';

const Breadcrumb = ({ className = '', currentPageTitle, disableTopicLink }) => {
  // Redux
  const course = useSelector((state) => state.course);
  const currentTopic = useSelector((state) => state.currentTopic);

  if (!course.id) {
    // TODO Skeleton Loading
    return null;
  }

  return (
    <div
      className={`breadcrumb-container d-flex justify-content-between align-items-center d-print-none ${className}`}
    >
      <nav
        aria-label="breadcrumb"
        className="w-100"
      >
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link
              aria-current="page"
              to={{
                pathname: MANAGE_CURRENT_ENTRY,
                search: manageEntry({
                  manageType: 'edit',
                  classId: course.id,
                  contentType: 'class'
                })
              }}
              title={`Edit ${course.title}`}
            >
              <span className="m-0 text-nowrap">
                <i className={`${ICON_CIRCLE_ARROW_LEFT} text-muted mr-1`} />{' '}
                Content
              </span>
            </Link>
          </li>

          <li className="breadcrumb-item">
            {disableTopicLink ? (
              <>{currentTopic.title}</>
            ) : (
              <Link
                to={{
                  pathname: MANAGE_CURRENT_ENTRY,
                  search: manageEntry({
                    manageType: 'edit',
                    entryId: currentTopic.id,
                    topicId: currentTopic.id,
                    classId: course.id,
                    contentType: 'topic'
                  })
                }}
                title={`Edit ${currentTopic.title}`}
              >
                {currentTopic.title}
              </Link>
            )}
          </li>

          {currentPageTitle && (
            <li
              className="breadcrumb-item active"
              aria-current="page"
            >
              {currentPageTitle}
            </li>
          )}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
