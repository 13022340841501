import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import PostNotification from '../../btns/PostNotification';
import NotificationsList from '../../lists/NotificationsList';
import { canPostOrgNotifications } from '../../../services/currentUser';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_NOTIFICATIONS } from '../../../constants/routes';

const NotificationsMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const notificationsOrg = useSelector((state) => state.notificationsOrg);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Pagination
  const initialListConfig = {
    orgId,
    classId: null,
    limit: 3
  };
  // Local State
  const [listConfig] = useState(initialListConfig);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const goToNotifications = () => {
    dispatch(resetCurrentModal());
    history.push(
      routeWithOrgId({
        route: `${ORG_CONSOLE_NOTIFICATIONS}`,
        orgId
      })
    );
  };

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal?.visible}
        close={handleClose}
        theme="dark"
      >
        <div className="card">
          <div className="card-header bg-dark d-flex align-items-center">
            <div className="m-0 text-white h6 font-weight-bold mr-3">
              Notifications
            </div>
            {canPostOrgNotifications(role) && (
              <PostNotification
                notificationType="organization"
                listConfig={listConfig}
              />
            )}
          </div>
          <div className="card-body pb-0">
            {Array.isArray(notificationsOrg?.pinned) &&
              notificationsOrg?.pinned.length > 0 && (
                <NotificationsList
                  list={notificationsOrg?.pinned || []}
                  includePinned
                  showMenu
                />
              )}

            <NotificationsList
              listConfig={listConfig}
              showMenu
            />
          </div>

          {Array.isArray(notificationsOrg.list) &&
            notificationsOrg.list.length > 0 && (
              <div className="card-footer text-center">
                <button
                  className="btn btn-link p-0"
                  type="button"
                  onClick={goToNotifications}
                >
                  View all notifications
                </button>
              </div>
            )}
        </div>
      </Modal>
    </>
  );
};

export default NotificationsMenu;
