import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import { FaCopy, FaEllipsisH, FaFileAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { FaCircleCheck } from 'react-icons/fa6';
import copyToClipBoard from '../../../utils/copyToClipBoard';
import { createMaterial } from '../../../services/materials';
import { getMaterial } from '../../../actions/Materials';
import { setSideSheet } from '../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../../constants/sideSheets';
import { fetchSearch, updateSearch } from '../../../services/searches';
import { mdySimple } from '../../../utils/date';

const DotMenu = ({
  className = '',
  output,
  outputDone,
  resourceIds,
  searchId,
  searchValue
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  const locationId = currentUser?.locationId || null;
  // Local State
  const [search, setSearch] = useState(null);
  const [searchFetched, setSearchFetched] = useState(false);

  const retrieveUserPrompt = useCallback(() => {
    if (searchId) {
      fetchSearch(searchId).then((rsp) => {
        setSearch(rsp);
        setSearchFetched(true);
      });
    }
  }, [searchId]);

  const copyText = () => {
    copyToClipBoard(output); // Copy Markdown format
    toast.success('Copied to clipboard!');
    updateSearch(
      {
        userCopied: true
      },
      searchId
    );
  };

  const saveAsMaterialButtonDisabled =
    !outputDone || search?.materialId || !searchId;

  const converter = new Showdown.Converter();

  const saveAsMaterial = () => {
    const body = converter.makeHtml(output); // Convert to HTML format
    toast.info('Saving...', { toastId: 'savingMaterial' });
    const title =
      `${searchValue.substring(0, 30)}...` ||
      `New Material ${mdySimple(new Date())}`;

    createMaterial({
      title,
      body: body || output,
      orgId,
      locationId,
      userId,
      enableNewEditor: true,
      isDraft: true,
      promptType: 'search',
      promptText: searchValue,
      resourceIds
    }).then((rsp) => {
      toast.dismiss('savingMaterial');
      toast.success('Material saved!', { toastId: 'savedMaterial' });

      updateSearch(
        {
          materialId: rsp?.id,
          userSaved: true
        },
        searchId
      );

      setTimeout(() => {
        // Wait to make sure Material is created
        // a race condition was observed without timeout

        dispatch(getMaterial(rsp?.id)).then(() => {
          toast.dismiss('savedMaterial');
          dispatch(
            setSideSheet({
              key: SHEET_KEY_MATERIAL,
              className: 'MaterialSideSheet z-index-100'
            })
          );
        });
      }, 250);
    });
  };

  return (
    <div className={className}>
      <button
        className="btn btn-sm btn-white"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
        onClick={retrieveUserPrompt}
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <button
          type="button"
          title="Save as Material"
          className="dropdown-item border-bottom py-2 px-3"
          disabled={saveAsMaterialButtonDisabled}
          onClick={saveAsMaterial}
        >
          <span className="d-flex align-items-center">
            {search?.materialId ? (
              <FaCircleCheck className="text-keppel" />
            ) : (
              <FaFileAlt className="text-muted" />
            )}
            <span className="ml-2">
              {searchId && searchFetched ? 'Save as Material' : 'Loading...'}
            </span>
          </span>
        </button>

        <button
          type="button"
          className="dropdown-item border-bottom py-2 px-3"
          onClick={copyText}
          disabled={!searchId}
        >
          <span className="d-flex align-items-center">
            <FaCopy className="text-muted" />
            <span className="ml-2">Copy</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default DotMenu;
