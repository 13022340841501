import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  updateUserAttendance,
  totalSessionCredits
} from '../../../services/attendance';
import Modal from '../../Modal';
import SelectMenu from '../../ManageContent/SelectMenu';
import Collapse from '../../ManageContent/Collapse';
import Records from './Records';

const ManageAttendance = () => {
  const dispatch = useDispatch();
  const key = 'manageAttendance';
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const currentCohort = useSelector((state) => state.currentCohort);
  const courseTopics = useSelector((state) => state.courseTopics);
  const course = useSelector((state) => state.course);
  //
  const classId = pathOr(null, ['id'], course);
  const courseTitle = pathOr(null, ['title'], course);
  // Cohort
  const cohortId = pathOr(null, ['id'], currentCohort);
  const cohortTitle = pathOr(null, ['title'], currentCohort);
  // Org
  const orgType = pathOr(null, ['type'], organization);
  // eUnits
  const eUnits = pathOr(null, ['eUnits'], currentCohort); // code, timeDate
  // eUnitTracking
  const eUnitTracking = pathOr(
    null,
    ['eUnitTracking', 'fields'],
    currentCohort
  ); // TODO cohort flatten
  const eUnitCode = pathOr(null, ['code'], eUnitTracking);
  const trackingSemesterCode = pathOr(null, ['semesterCode'], eUnitTracking);
  const trackingSession = pathOr(null, ['session'], eUnitTracking);
  const attendancePerSession = pathOr(
    null,
    ['attendancePerSession'],
    eUnitTracking
  );
  const trackingSessionCredits = pathOr(
    null,
    ['integration', 'sessionCredits'],
    eUnitTracking
  );
  const trackingSessions = pathOr(null, ['sessions'], eUnitTracking);
  // Modal Data
  const courseAttendance = pathOr(
    null,
    ['data', 'courseAttendance'],
    currentModal
  );
  const courseAttendanceIndex = pathOr(
    undefined,
    ['data', 'courseAttendanceIndex'],
    currentModal
  );
  const attendanceRecord = pathOr(
    null,
    ['data', 'attendanceRecord'],
    currentModal
  );
  // Modal Data User
  const userId = pathOr(null, ['data', 'userId'], currentModal);
  // Sessions
  // If courseTopics are sync'd and courseTopics exist, use length for sessions
  const sessionsTopicSync = pathOr(null, ['sessionsTopicSync'], eUnitTracking); // TODO cohort flatten
  const numTopics =
    sessionsTopicSync && courseTopics.list ? courseTopics.list.length : null;
  const numSessions = numTopics || trackingSessions;
  // Selected Record of Attendance
  const [selectedRecord, setSelectedRecord] = useState({
    class: {
      id: classId,
      title: courseTitle
    },
    cohort: {
      id: cohortId,
      title: cohortTitle
    },
    date: '', // value added when saving.
    eUnitCode,
    eUnitCodes: [], // ['...'] prevSelectedUnitCodes({ course, currentUser })
    recordTaker: '', // 'Student' || handle recordTaker
    records: [], // ['...']
    recordsPerSession: attendancePerSession, // 3
    semesterCode: trackingSemesterCode,
    session: trackingSession, // 1
    sessionCredits: trackingSessionCredits, // [{ number: 3, type: 'etc' }]
    sessionTopic: null, // {...}
    sessions: numSessions, // 12
    ...attendanceRecord
  });

  const sessionTopic = pathOr(null, ['sessionTopic'], selectedRecord);
  const sessionCredits = pathOr(null, ['sessionCredits'], selectedRecord);
  const unitCode = pathOr(null, ['eUnitCode'], selectedRecord);
  const unitCodes = pathOr(null, ['eUnitCodes'], selectedRecord);
  const session = pathOr(null, ['session'], selectedRecord);
  const records = pathOr(null, ['records'], selectedRecord);
  const recordsPerSession = pathOr(null, ['recordsPerSession'], selectedRecord);
  const semesterCode = pathOr(null, ['semesterCode'], selectedRecord);
  const sessions = pathOr(null, ['sessions'], selectedRecord);

  const [loading, setLoading] = useState(false);

  const handleSelectSession = (newSession) => {
    const topicIndex = newSession - 1;
    const newSessionTopic =
      courseTopics.list && courseTopics.list[topicIndex] !== undefined
        ? courseTopics.list[topicIndex]
        : null;
    const sessionTopicId = pathOr(null, ['id'], newSessionTopic);
    const sessionTopicTitle = pathOr(null, ['title'], newSessionTopic);
    const sessionTopicLevel = pathOr(null, ['level'], newSessionTopic);
    const sessionTopicCategory = pathOr(null, ['category'], newSessionTopic);

    const dataToSave = {
      ...selectedRecord,
      session: newSession,
      sessionTopic: {
        id: sessionTopicId,
        title: sessionTopicTitle
      }
    };

    if (sessionTopicCategory) {
      dataToSave.sessionTopic.category = sessionTopicCategory.join(',');
    }

    if (sessionTopicLevel) {
      dataToSave.sessionTopic.level = sessionTopicLevel.join(',');
    }

    setSelectedRecord(dataToSave);
  };

  const handleSelectEUnitCodes = (updatedEUnitCodes) => {
    setSelectedRecord({
      ...selectedRecord,
      eUnitCodes: updatedEUnitCodes
    });
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSave = () => {
    if (selectedRecord.eUnitCodes.length === 0) {
      toast.error('Please choose a Session.');
      return false;
    }

    if (selectedRecord.eUnitCodes.length === 0) {
      toast.error('Please choose at least 1 Unit Code.');
      return false;
    }

    if (selectedRecord.records.length === 0) {
      toast.error('Please add at least 1 Record.');
      return false;
    }

    toast.info('Updating attendance...');

    setLoading(true);

    dispatch(
      updateUserAttendance({
        courseAttendanceIndex,
        selectedRecord,
        orgType,
        classId,
        userId
      })
    ).then(() => {
      toast.success('Attendance updated.');
      handleClose();
    });
  };

  const onUpdateRecords = (updatedRecords) => {
    setSelectedRecord({
      ...selectedRecord,
      records: updatedRecords
    });
  };

  if (currentModal && !currentModal.visible) return null;
  if (currentModal.key !== key) return null;

  // Construct options for Unit Codes select menu
  // TODO cohort flatten
  const unitCodesOptions = eUnits
    ? eUnits.map((eu) => {
        return {
          value: eu.fields.code,
          label: `(${eu.fields.code}) ${eu.fields.timeDate}`
        };
      })
    : null;

  // Construct options for session select menu
  let sessionOptions = sessions
    ? Array.from({ length: sessions }, (_, i) => {
        return { value: i + 1, label: i + 1 };
      })
    : null;
  // Array of all sessions already recorded on User
  const sessionsTaken = courseAttendance
    ? courseAttendance.map((ca) => ca.session)
    : null;

  if (sessionsTaken && sessionOptions) {
    // In Session Select Menu...
    // exclude sessions already recorded on User
    // except when editing existing attendanceRecord,
    // then also include the attendanceRecord.session
    sessionOptions = sessionOptions.filter(
      (so) =>
        !sessionsTaken.includes(so.value) ||
        (attendanceRecord && so.value === attendanceRecord.session)
    );
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h5>Manage Attendance</h5>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label
              htmlFor="startDate"
              className="mb-2"
            >
              <b>Session</b>
              <span className="text-danger">*</span>
            </label>
            {sessionOptions ? (
              <SelectMenu
                id="session"
                name="session"
                style={{ minWidth: '200px' }}
                options={sessionOptions}
                defaultValue={
                  session &&
                  sessionOptions.find((option) => session === option.value)
                }
                onChange={({ session: newSession }) => {
                  handleSelectSession(newSession);
                }}
                required
              />
            ) : (
              // TODO review this logic when scenario exists
              <input
                className="form-control"
                type="number"
                required
              />
            )}
          </div>

          {!attendanceRecord && session && unitCodesOptions && (
            <div className="mb-3">
              <label
                htmlFor="startDate"
                className="mb-2"
              >
                <b>Unit Codes</b>
                <span className="text-danger">*</span>
              </label>
              {unitCodesOptions ? (
                <SelectMenu
                  id="eUnitCodes"
                  name="eUnitCodes"
                  style={{ minWidth: '200px' }}
                  options={unitCodesOptions}
                  // defaultValue={session && sessionOptions.find((option) => session === option.value)}
                  onChange={({ eUnitCodes: newEUnitCodes }) => {
                    handleSelectEUnitCodes(newEUnitCodes);
                  }}
                  isMulti
                  required
                />
              ) : (
                // TODO review this logic when scenario exists
                <input
                  className="form-control"
                  type="number"
                  required
                />
              )}
            </div>
          )}

          {session && (
            <div className="mb-3">
              <Records
                parent={{
                  title: 'Records'
                }}
                data={records}
                maxItems={recordsPerSession}
                onUpdate={onUpdateRecords}
              />
            </div>
          )}

          {session && (
            <Collapse
              title="Session"
              id="sessionInfo"
              className="mb-3"
            >
              <ul className="m-0 pl-4">
                <li>
                  <b>Title:</b> {sessionTopic ? sessionTopic.title : '-'}
                </li>

                <li>
                  <b>Credits</b>:{' '}
                  {sessionCredits
                    ? totalSessionCredits({ sessionCredits })
                    : '-'}
                </li>

                <li>
                  <b>Level</b>: {sessionTopic ? sessionTopic.level : '-'}
                </li>

                <li>
                  <b>Category</b>: {sessionTopic ? sessionTopic.category : '-'}
                </li>
              </ul>
            </Collapse>
          )}

          {session && (
            <Collapse
              title="Certificate"
              id="certificateInfo"
            >
              <ul className="m-0 pl-4">
                <li>Unit Code: {unitCode || '-'}</li>

                <li>Unit Codes: {unitCodes ? unitCodes.join(', ') : '-'}</li>

                <li>Semester: {semesterCode || ''}</li>

                <li>Sessions: {sessions || '-'}</li>

                <li>Course: {courseTitle || '-'}</li>

                <li>Course ID: {classId || -''}</li>

                <li>Cohort Title: {cohortTitle || '-'}</li>

                <li>Cohort ID: {cohortId || '-'}</li>
              </ul>
            </Collapse>
          )}
        </div>
        <div className="card-footer d-flex align-items-center">
          <button
            onClick={handleSave}
            className="btn btn-primary btn-md mr-2"
            disabled={loading}
            type="button"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            onClick={handleClose}
            className="btn btn-link"
            disabled={loading}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageAttendance;
