import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './style.css';

const AgentImage = ({
  className = '',
  imgHeight = 33,
  circleHeight = 48,
  handleClick,
  imgSrc,
  imgName,
  activeImgSrc,
  bkgColor
}) => {
  return (
    <button
      className={`AgentImage ${className}`}
      style={{
        cursor: handleClick ? 'pointer' : 'default',
        height: `${circleHeight}px`,
        width: `${circleHeight}px`,
        ...(bkgColor ? { backgroundColor: bkgColor } : {})
      }}
      type="button"
      {...(handleClick
        ? {
            onClick: () => {
              handleClick(imgSrc);
            }
          }
        : {})}
    >
      <img
        src={imgSrc}
        alt={imgName}
        height={imgHeight}
        style={{ height: `${imgHeight}px` }}
      />

      {activeImgSrc !== undefined && activeImgSrc === imgSrc && (
        <div className="AgentImageCheckMark bg-keppel">
          <FaCheck className="text-white" />
        </div>
      )}
    </button>
  );
};

export default AgentImage;
