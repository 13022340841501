import React from 'react';
import { roleReadable } from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import NameBlock from '@apprentage/components/dist/components/NameBlock';

const NameCell = ({ value: name, row, column }) => {
  const user = row?.original;
  return (
    <NameBlock
      key={user?.userId}
      name={name}
      nameSize="sm"
      pictureSize="sm"
      title={user?.title || roleReadable(user?.role, column?.orgType)}
      profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
      {...(column.onClickName
        ? {
            onClick: () => {
              column.onClickName(user?.userId);
            }
          }
        : {})}
    />
  );
};

export default NameCell;
