import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_PAGES } from '../../../constants/assets';
import { createPage } from '../../../services/pages';
import { resetCurrentModal } from '../../../actions/Modals';
import { ORG_CONSOLE_PAGE_BASE } from '../../../constants/routes';
import { MODAL_KEY_CREATE_PAGE } from '../../../constants/modals';
import { createPageLink, updatePageLink } from '../../../services/pageLinks';
import { createDashboard } from '../../../services/dashboards';
import Modal from '../../Modal';
import ChoosePageType from './ChoosePageType';

const CreatePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  // Current Modal
  const modalData = currentModal?.data;
  // Local State
  const [currentState, setState] = useState({
    currentStep: modalData?.currentStep || 0,
    enablePageContent: true,
    url: null,
    title: 'My New Page'
  });
  const key = MODAL_KEY_CREATE_PAGE;

  const updateState = (newStateData) => {
    setState({
      ...currentState,
      ...newStateData
    });
  };

  const handleClose = useCallback(() => {
    dispatch(resetCurrentModal());
  }, [dispatch]);

  const handleError = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.');
    handleClose();
  };

  const successPageCreate = useCallback(
    (pageId, pageLinkId) => {
      if (pageId && pageLinkId) {
        updatePageLink({ pageId }, pageLinkId);
        toast.dismiss('savingLinkpage');
        toast.success('Linkpage created!');
        history.replace(`${ORG_CONSOLE_PAGE_BASE}/${pageId}`);

        setTimeout(() => {
          handleClose();
        }, 500);
      }
    },
    [handleClose, history]
  );

  const saveData = async () => {
    const { currentStep, ...stateData } = currentState;
    const dataToSave = {
      orgId,
      userId,
      title: currentState?.title,
      showTitle: true,
      showOrgLogo: true
    };

    toast.info('Saving Linkpage...', { toastId: 'savingLinkpage' });

    if (stateData.enablePageContent) {
      // TURBINE PAGE
      createPageLink({
        ...dataToSave,
        enablePageContent: true,
        url: null
      })
        .then((rspPageLink) => {
          if (rspPageLink?.id) {
            createDashboard({
              orgId,
              type: 'page',
              activeLayout: 'layout2'
            })
              .then((rspDashboard) => {
                if (rspDashboard?.id) {
                  createPage({
                    ...dataToSave,
                    enablePageContent: true,
                    pageLinkId: rspPageLink?.id,
                    dashboardId: rspDashboard?.id
                  })
                    .then((rspPage) => {
                      successPageCreate(rspPage?.id, rspPageLink?.id);
                    })
                    .catch((error) => {
                      handleError(error);
                    });
                } else {
                  handleError('Dashboard create failure.');
                }
              })
              .catch((error) => {
                handleError(error);
              });
          } else {
            handleError('PageLink create failure.');
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      // EXTERNAL WEBPAGE
      createPageLink({
        ...dataToSave,
        enablePageContent: null,
        url: currentState?.url
      })
        .then((rspPageLink) => {
          if (rspPageLink?.id) {
            createPage({
              ...dataToSave,
              enablePageContent: null,
              pageLinkId: rspPageLink?.id
            })
              .then((rspPage) => {
                successPageCreate(rspPage?.id, rspPageLink?.id);
              })
              .catch((error) => {
                handleError(error);
              });
          } else {
            handleError('PageLink create failure.');
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const { enablePageContent, url } = currentState;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      close={handleClose}
      theme="dark"
    >
      <div className="card border-0">
        <div className="card-header bg-dark text-white">
          <span className="d-flex align-items-center">
            <img
              src={ICON_PAGES}
              alt="Pages"
              height={30}
              width={30}
              style={{
                height: '30px',
                width: '30px'
              }}
            />
            <span className="h5 ml-2 mb-0">Turbine Page</span>
          </span>
        </div>

        <ChoosePageType
          updateState={updateState}
          data={{
            enablePageContent,
            url
          }}
          saveData={saveData}
        />
      </div>
    </Modal>
  );
};

export default CreatePage;
