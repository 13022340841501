import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';
import { resetSideSheet } from '../../../../actions/SideSheets';
import { fetchApprenticeships } from '../../../../services/apprenticeships';

const ApprenticeshipIdsCell = ({ value: apprenticeshipIds, row }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Row
  const user = row?.original;
  // Local State
  const [apprenticeshipsList, setApprenticeshipsList] = useState(null);
  const [fetched, setFetched] = useState(false);

  const onClickApprenticeshipRow = (apprenticeshipId) => {
    const url = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/users/${user?.id}`;

    history.push(url);
    dispatch(resetSideSheet());
  };

  console.log('apprenticeshipIds', apprenticeshipIds);

  useEffect(() => {
    if (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length > 0) {
      setFetched(false);
      fetchApprenticeships({
        orgId: user?.orgId,
        ids: apprenticeshipIds,
        select: ['id', 'title'],
        limit: apprenticeshipIds.length
      })
        .then((rsp) => {
          if (Array.isArray(rsp.items) && rsp.items.length > 0) {
            setApprenticeshipsList(rsp.items);
          }
        })
        .finally(() => {
          setFetched(true);
        });
    }
  }, [apprenticeshipIds, user?.orgId]);

  if (
    !apprenticeshipIds ||
    (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length === 0)
  ) {
    return null;
  }

  if (!fetched || (fetched && !apprenticeshipsList)) {
    return null;
  }

  return (
    <>
      {apprenticeshipsList.map((apprenticeship, index) => (
        <div
          key={`apprenticeship-${index}`}
          className={index !== 0 ? 'mt-3' : ''}
        >
          <button
            onClick={() => {
              onClickApprenticeshipRow(apprenticeship?.id);
            }}
            type="button"
            className="btn-link"
          >
            {apprenticeship?.title}
          </button>
        </div>
      ))}
    </>
  );
};

export default ApprenticeshipIdsCell;
