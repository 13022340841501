import React from 'react';
import { ICON_INFO_CIRCLE } from '@apprentage/constants';
import { mdySimple } from '../../utils/date';

const ActionTakenCell = ({ value: actionTaken, row }) => {
  const actionAt = row?.original?.actionAt || '';

  return (
    <span className="h6 m-0 d-flex align-items-center">
      <span className="mr-1 badge badge-success">{actionTaken}</span>
      <span className="d-none d-sm-inline-block ml-1">
        <i
          className={`${ICON_INFO_CIRCLE} text-muted opacity-50`}
          // data-tooltip-content={mdySimple(actionAt)}
          // data-tooltip-id="InvitesHistoryList"
          data-tip={mdySimple(actionAt)}
          data-for="InvitesHistoryList"
        />
      </span>
    </span>
  );
};

export default ActionTakenCell;
