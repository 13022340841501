import React, { useEffect, useState } from 'react';
import { SiLightning } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { percentComplete, roleReadable } from '@apprentage/utils';
import { NameBlock } from '@apprentage/components';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { toast } from 'react-toastify';
import { fetchUnansweredQuestions } from '../../../services/contextQuestions';
import DotMenu from './DotMenu';
// import Assistant from '../../Modals/ResourcePreview/Assistant';
import InputChangeValue from '../../../routes/Onboarding/InputChangeValue';
import ActionsList from './ActionsList';
import OrgConsoleSubheader from '../../OrgConsole/OrgConsoleSubheader';
import ProgressBars from '../../ProgressBars';
import Tabs from './Tabs';
import './style.css';
import { createContextInput } from '../../../services/contextInputs';

const PulseSideSheet = ({ className = '' }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const sideSheet = useSelector((state) => state.sideSheet);
  const title = sideSheet?.data?.title || 'PULSE';
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  // Local State
  const [contextQuestionsList, setContextQuestionsList] = useState(null);
  const [contextQuestion, setContextQuestion] = useState(null);
  const [activeTab, setActiveTab] = useState('context');
  // Progress
  const [totalSteps, setTotalSteps] = useState(7);
  const [currentStep] = useState(1);
  const percentage = percentComplete({
    current: currentStep,
    total: totalSteps
  });

  const onClickAction = (data) => {
    setContextQuestion(data);
  };

  useEffect(() => {
    fetchUnansweredQuestions({ orgId, userId }).then((rsp) => {
      if (Array.isArray(rsp.items)) {
        setTotalSteps(rsp.items.length);
      }
      setContextQuestionsList(rsp.items);
    });
  }, [orgId, userId]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <SiLightning />
            <span className="ml-1 text-nowrap">{title}</span>
          </span>
        </h6>
        <DotMenu projectId={currentUser?.id} />
      </div>

      <NameBlock
        profilePicture={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
        name={currentUser?.name}
        membership={currentUser?.membership}
        nameSize="md"
        pictureSize="sm"
        className="bg-light pt-3 px-3"
        title={currentUser?.title || roleReadable(currentUser?.role)}
        // onClick={() => {
        //   dispatch(handleOpenUserSideSheet({
        //     userId,
        //     orgId: organization?.id,
        //     currentUserOrgId: currentAnswer?.orgId
        //   }));
        // }}
      />

      <Tabs
        cardStyle
        className="pt-3"
        contextQuestionsList={contextQuestionsList}
        activeTab={activeTab}
        setActiveTab={(tab) => {
          setActiveTab(tab);
        }}
      />

      <div className="card-body position-relative overflow-y-scroll">
        <div className="row">
          <div
            className="position-absolute"
            style={{
              top: 0,
              width: '100%',
              left: 0
            }}
          >
            <ProgressBars
              size="sm"
              className="w-100 border-radius-0"
              data={[
                {
                  className: 'bg-000',
                  valueNow: currentStep,
                  valueMax: totalSteps,
                  valueMin: 0,
                  style: { width: percentage },
                  text: ''
                }
              ]}
            />
          </div>
        </div>
        <div className="action-box mt-3">
          {contextQuestion?.id ? (
            <div>
              <OrgConsoleSubheader
                className="mx-2 my-3 d-flex justify-content-between align-items-center"
                pageTitle="All tasks"
                onClick={() => {
                  setContextQuestion(null);
                }}
              />

              <InputChangeValue
                title={contextQuestion?.title}
                helpText={contextQuestion?.description}
                textarea
                autoFocus
                defaultValue=""
                primaryActionText="Submit"
                // tertiaryActionText="I'll do this later"
                callbackPrimaryAction={(value) => {
                  // setTeamContextProjects(value);
                  // setCurrentScreen('contextTeamGreat');
                  // setCurrentStep(2);
                  createContextInput({
                    questionId: contextQuestion?.id,
                    contextType: 'user',
                    orgId,
                    userId,
                    input: value
                  }).then(() => {
                    fetchUnansweredQuestions({ orgId, userId }).then(() => {
                      setContextQuestion(null);
                      toast.success('Answer saved!');
                    });
                  });
                }}
                // callbackTertiaryAction={() => {
                //   // setCurrentScreen('contextTeamGreat');
                //   // setCurrentStep(2);
                // }}
              />
            </div>
          ) : (
            <ActionsList
              list={contextQuestionsList}
              onClick={onClickAction}
            />
          )}
        </div>

        {/* <Assistant /> */}
      </div>
      <div className="card-footer">[Search] [Actions]</div>
    </div>
  );
};

export default PulseSideSheet;
