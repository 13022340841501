import React from 'react';

const ScoreCell = ({ value: score, row }) => {
  const {
    original: { maxScore }
  } = row;

  return (
    <div className="h6">
      <div>
        {score} / {maxScore}
      </div>
    </div>
  );
};

export default ScoreCell;
