import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { IMAGE_RESUME_SCREENER } from '../../../constants/assets';
import FolderInfo from '../../ManageContent/FolderInfo';

const TitleScreen = ({
  resourceGroupTitle,
  hasFiles,
  hasEmbeddedFiles,
  embeddedFiles
}) => {
  return (
    <>
      <div className="card-body overflow-y-scroll">
        <div className="mx-auto py-3 px-0 px-lg-5 d-block d-sm-none">
          <img
            className="w-100"
            src={IMAGE_RESUME_SCREENER}
            alt="Resume Screener"
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column justify-content-center">
              <div className="mx-auto py-3 px-0 px-lg-5 d-none d-sm-block">
                <img
                  className="w-100"
                  src={IMAGE_RESUME_SCREENER}
                  alt="Resume Screener"
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <h5 className="text-center font-weight-bold my-3">
                  Find top Candidates for an open role
                </h5>
                <p className="text-center mb-1">
                  Analyze a Job Description and Resumes in:
                </p>

                <FolderInfo
                  className="d-flex align-items-center justify-content-center"
                  resourceGroupTitle={resourceGroupTitle}
                  hasEmbeddedFiles={hasEmbeddedFiles}
                  embeddedFiles={embeddedFiles}
                  hasFiles={hasFiles}
                />

                {!hasFiles && (
                  <p className="font-weight-bold d-flex align-items-center justify-content-center">
                    <span className="alert-warning p-3 d-flex align-items-center rounded">
                      <FaExclamationTriangle className="mr-2 text-warning" />
                      <span>
                        Add resumes (.docx or .pdf) to this folder first
                      </span>
                    </span>
                  </p>
                )}

                {!hasEmbeddedFiles && (
                  <p className="font-weight-bold d-flex align-items-center justify-content-center">
                    <span className="alert-warning p-3 d-flex align-items-center rounded">
                      <FaExclamationTriangle className="mr-2 text-warning" />
                      <span>
                        Embed resume files before running Resume Screener
                      </span>
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="ResumeScreenerTitleScreen" />
    </>
  );
};

export default TitleScreen;
