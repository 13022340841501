/**
 * IE 11 does not support navigator.clipboard, so have to use window.clipboardData.setData('Text', text);
 * @param {String} text
 */

function copyToClipBoard(text) {
  if (navigator.clipboard) {
    // Chrome
    navigator.clipboard.writeText(text);
  } else if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData('Text', text);
  }
}

export default copyToClipBoard;
