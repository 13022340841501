import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table'; // useFilters

function Table({ columns, data, hiddenColumns = [] }) {
  const initialState = {
    sortBy: [
      {
        id: 'name',
        desc: false
      }
    ],
    hiddenColumns
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        initialState,
        data
      },
      useSortBy
    );

  return (
    <table
      className="table table-border-top-0 m-0"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="text-nowrap"
                style={{ verticalAlign: 'center' }}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.canSort && (
                  <span className="ml-1">
                    {column.isSorted ? (
                      <>
                        {column.isSortedDesc ? (
                          <i className="fas fa-sort-down" />
                        ) : (
                          <i className="fas fa-sort-up" />
                        )}
                      </>
                    ) : (
                      <i
                        className="fas fa-sort"
                        style={{ opacity: '.25' }}
                      />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
