import { onAuthStateChanged } from '@firebase/auth';
import { auth } from '../firebaseConfig';
import { fetchUserByUid } from '../services/user';

const onAuthUserListener = (next, fallback) => {
  return onAuthStateChanged(auth, (authUser) => {
    if (authUser) {
      let count = 0;
      let userExistsContentful = setInterval(() => {
        console.log('onAuthStateChanged', count);

        // TODO refactor using recursive pattern
        fetchUserByUid(authUser.uid).then((response) => {
          if (response) {
            // Login
            userExistsContentful = clearInterval(userExistsContentful);

            next({ authUser, currentUser: response });
          } else {
            // New Member Registration (User not in Contentful yet)
            count += 1;

            if (count >= 10) {
              userExistsContentful = clearInterval(userExistsContentful);
              // TODO stop loading
              console.error('CM:Account creation error');
              fallback();
            }
          }
        });
      }, 1000);
    } else {
      fallback();
    }
  });
};

export default onAuthUserListener;
