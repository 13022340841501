import React from 'react';
import { useSelector } from 'react-redux';
import { FaCalendarAlt, FaPhoneSquareAlt } from 'react-icons/fa';
import { IMG_LINKEDIN_ICON } from '@apprentage/constants';
import KeyValueItem from '../../../KeyValueItem';
import TeamsMessageButton from '../../../btns/TeamsMessageButton';

const ContactBlock = () => {
  const organization = useSelector((state) => state.organization);
  const directoryUser = useSelector((state) => state.directoryUser);
  // misc
  const integrateMicrosoftTeams = organization?.integrateMicrosoftTeams || null;
  const phone = directoryUser?.phone || null;
  const phoneExt = directoryUser?.phoneExt || null;
  const mobilePhone = directoryUser?.mobilePhone || null;
  const email = directoryUser?.email || null;
  const calendarLink = directoryUser?.calendarLink || null;
  const linkedInUrl = directoryUser?.linkedInUrl || null;

  return (
    <div className="d-flex justify-content-center text-center my-4">
      <div className="border-bottom pb-3">
        <div className="h6 font-weight-bold mb-3">Contact Information</div>

        {mobilePhone && (
          <KeyValueItem
            title="Mobile"
            value={mobilePhone}
            labelClassName="contact-label mr-2"
            className="contact-row"
            truncate={false}
          />
        )}

        {email && (
          <KeyValueItem
            title="Email"
            value={email}
            labelClassName="contact-label mr-2"
            className="contact-row"
            truncate={false}
          />
        )}

        {calendarLink && (
          <div className="contact-row border-top pt-3 mt-3 d-flex justify-content-center">
            <div>
              <a
                className="btn btn-sm btn-outline-primary"
                href={calendarLink}
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                title="Schedule Meeting"
              >
                <span className="d-flex align-items-center">
                  <FaCalendarAlt size={20} />
                  <span className="ml-1">Schedule Meeting</span>
                </span>
              </a>
            </div>
          </div>
        )}

        {linkedInUrl && (
          <div className="contact-row border-top pt-3 mt-3 d-flex justify-content-center">
            <div>
              <a
                className="btn btn-sm btn-outline-primary d-flex align-items-center"
                href={linkedInUrl}
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                title="View on LinkedIn"
              >
                <img
                  className="mr-2"
                  src={IMG_LINKEDIN_ICON}
                  alt="LinkedIn Icon"
                  height="20"
                  style={{
                    height: '20px'
                  }}
                />
                Connect on LinkedIn
              </a>
            </div>
          </div>
        )}

        {phone && (
          <div className="contact-row border-top pt-3 mt-3 d-flex align-items-center justify-content-center">
            <span className="text-muted">
              <FaPhoneSquareAlt color="#0984e3" />
            </span>
            <a
              className="ml-1"
              data-demo="disabled"
              href={`tel:${phone.trim()}${phoneExt && `;${phoneExt}`}`}
            >
              {`${phone}`}
            </a>

            {phoneExt && (
              <div className="h5 m-0">
                <div
                  className="badge bg-white border m-1 text-ships-officer font-weight-bold"
                  data-tip="Phone Extension"
                >
                  {phoneExt}
                </div>
              </div>
            )}
          </div>
        )}

        {integrateMicrosoftTeams && (
          <div className="contact-row border-top pt-3 mt-3 d-flex justify-content-center">
            <div>
              <TeamsMessageButton email={email} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactBlock;
