import React from 'react';
import AssistantBadge from '../../../Assistants/AssistantBadge';

const NameCell = ({ value: name, row }) => {
  const agentImage = row?.original?.agentImage || null;
  const colorBtnBkg = row?.original?.colorBtnBkg || null;

  return (
    <AssistantBadge
      agentImage={agentImage}
      name={name}
      colorBtnBkg={colorBtnBkg}
    />
  );
};

export default NameCell;
