import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { ICON_QUIZ, ICON_USER } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import { setCurrentClassQuizzes } from '../../../actions/Quizzes';
import {
  resetCurrentClassQuizOutcomes,
  getQuizOutcomes
} from '../../../actions/QuizOutcomes';
import { CLASS_CONSOLE_QUIZZES } from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';
import { QUIZ_OUTCOMES_PAGINATION_LIMIT } from '../../../constants/api';
import { fetchUser } from '../../../services/currentUser';
import withConsole from '../../App/withConsole';
import QuizOutcomesList from '../../QuizOutcomesList';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsole/OrgConsoleSubheader';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import Tabs from './Tabs';

const QuizOutcomes = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Search Params
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const userId = params?.userId || '';
  // Organization
  const orgId = organization?.id || null;
  // Course
  const classId = course?.id || null;
  // Quiz
  const quizId = currentQuiz?.id || null;
  // Quiz Outcomes
  const activeOrgId = quizOutcomes?.activeOrgId || '';
  // Local State
  const [quizUser, setQuizUser] = useState('');
  const [organizationId, setOrganizationId] = useState(activeOrgId || orgId);

  const initialListConfig = {
    limit: QUIZ_OUTCOMES_PAGINATION_LIMIT,
    page: 1,
    orgId: organizationId,
    userId,
    classId
  };
  const [listConfig, setListConfig] = useState(initialListConfig);

  const pageTitle = useMemo(() => {
    if (quizUser?.id) {
      return quizUser?.name || '';
    }

    return 'User';
  }, [quizUser?.id, quizUser?.name]);

  const subheaderRoute = useMemo(() => {
    if (redirectUrl) {
      return redirectUrl;
    }

    if (quizId) {
      return `${CLASS_CONSOLE_QUIZZES}/${quizId}`;
    }

    return CLASS_CONSOLE_QUIZZES;
  }, [redirectUrl, quizId]);

  useEffect(() => {
    if (userId) {
      fetchUser({
        userId,
        select: ['id', 'name', 'orgId']
      }).then((rsp) => {
        setQuizUser(rsp);
        setOrganizationId(rsp?.orgId);
      });
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (classId) {
      dispatch(setCurrentClassQuizzes({ classId }));
    }
  }, [classId, dispatch]);

  useEffect(() => {
    if (organizationId) {
      setListConfig((prev) => ({
        ...prev,
        orgId: organizationId
      }));
    }
  }, [organizationId]);

  useEffect(() => {
    dispatch(getQuizOutcomes(listConfig));
  }, [dispatch, listConfig]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCurrentClassQuizOutcomes());
    };
  }, [dispatch]);

  return (
    <>
      <OrgConsoleHeader
        pageTitle="Quiz Outcomes"
        icon={ICON_QUIZ}
        className="my-3"
        // rawRoute={routeWithClassId({
        //   route: CLASS_CONSOLE_QUIZZES,
        //   classId
        // })}
      />

      <div className="bg-white border rounded shadow p-3">
        <OrgConsoleSubheader
          pageTitle={pageTitle}
          icon={ICON_USER}
          className="mb-3"
          rawRoute={routeWithClassId({
            route: subheaderRoute,
            classId
          })}
          // data-demo={userName ? 'disabled' : ''}
        />

        <div>
          <Tabs
            // className='pt-3'
            active="outcomes"
            userId={userId}
          />

          <QuizOutcomesList
            className="border-left border-right border-bottom"
            list={quizOutcomes.list}
            userId={userId}
            showUserNameAsLink={!userId}
            showQuizTitleAsLink={!!userId}
            showOutcomeButton
          />

          {Array.isArray(quizOutcomes?.list) &&
            quizOutcomes.list.length > 0 && (
              <PaginationSupabase
                items={quizOutcomes?.list}
                pagination={quizOutcomes?.pagination}
                page={quizOutcomes?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: quizOutcomes?.pagination?.page + 1
                  }));
                  // goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: quizOutcomes?.pagination?.page - 1
                  }));
                  // goToElem('root');
                }}
              />
            )}
        </div>
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withConsole)(QuizOutcomes);
