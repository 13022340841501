import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import LocationSelect from '../../ManageContent/LocationSelect';
import { userRolesEnum } from '../../../services/users';
import UserRoleDefinitions from '../../UserRoleDefinitions';

const Form = ({
  body,
  setTitle,
  questionType,
  setQuestionType,
  locationIds,
  setLocationIds,
  userRoles,
  setUserRoles
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  // Organization
  const orgType = organization?.type || null;

  const questionTypeEnum = {
    checkbox: 'Checkbox',
    radio: 'Radio',
    text: 'Short Text',
    textarea: 'Long Text',
    date: 'Date'
  };

  const visibleToAllUsers = false;

  const questionTypeOptions = reactSelectOptionsFromEnum(questionTypeEnum);
  const questionTypeDefaultValue = reactSelectDefaultValue(
    questionType,
    questionTypeOptions
  );

  // User Roles
  const userRolesOptions = reactSelectOptionsFromEnum(
    userRolesEnum({ orgType })
  );
  const userRolesDefaultValues = reactSelectDefaultValues(
    userRoles,
    userRolesOptions
  );

  return (
    <div>
      <h3>Question for...</h3>
      {locations.list &&
        Array.isArray(locations.list) &&
        locations.list.length !== 0 && (
          <div className="mb-3">
            <LocationSelect
              id="selectedLocationIds"
              name="selectedLocationIds"
              className="mr-0 mr-sm-2 flex-fill"
              defaultValue={locationIds || []}
              data={locations?.list}
              isMulti
              onChange={({ selectedLocationIds }) => {
                const newValues =
                  selectedLocationIds.length === 0 ? null : selectedLocationIds;
                setLocationIds(newValues);
              }}
            />
          </div>
        )}

      {!visibleToAllUsers && (
        <div
          id="group-user-roles"
          className="mb-4"
          style={{
            borderLeft: '5px #dee2e6 solid',
            paddingLeft: '1rem',
            display: 'none'
          }}
        >
          <label
            className="mb-0"
            htmlFor="userRoles"
          >
            <b>User Roles:</b> <span className="text-danger">*</span>
          </label>

          <div className="text-muted mb-2">
            Choose all user roles that can view this notification.
          </div>

          <SelectMenu
            className="user-roles-select-menu zIndex-1000"
            id="userRoles"
            name="userRoles"
            placeholder="Choose Role"
            options={userRolesOptions}
            isMulti
            defaultValue={userRolesDefaultValues}
            onChange={(values) => {
              const newValues =
                values.length === 0 ? null : values.map((tag) => tag.value);

              setUserRoles(newValues);
            }}
          />

          {/* {showRoleSpecificAlert() && (
            <Alert
              type="warning"
              iconComponent={() => <FaTriangleExclamation />}
              className="mt-2 mb-0 w-100"
            >
              You will not be able to see this notification if <b>{roleReadable(role, orgType)}s</b> is <b>NOT</b> included in the list above.
            </Alert>
          )} */}

          <UserRoleDefinitions />
        </div>
      )}

      <div className="mb-3">
        <div className="mb-1">Question Type</div>
        <SelectMenu
          // id={id}
          // name={name}
          // className={className}
          onChange={({ value }) => {
            setQuestionType(value);
          }}
          defaultValue={questionTypeDefaultValue}
          placeholder="Select type..."
          options={questionTypeOptions}
        />
      </div>
      <div className="mb-3">
        <div className="mb-1">Question</div>
        <div>
          <textarea
            className="form-control"
            value={body}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setTitle(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
