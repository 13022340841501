import {
  RESET_CURRENT_CLASS_QUIZ_OUTCOMES,
  SET_CURRENT_CLASS_QUIZ_OUTCOMES
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function currentClassQuizOutcomes(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLASS_QUIZ_OUTCOMES:
      return {
        ...state,
        list: action.list,
        fetched: true
      };
    case RESET_CURRENT_CLASS_QUIZ_OUTCOMES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
