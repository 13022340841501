import {
  SET_ENTRY_ORG_ID,
  SET_APPRENTICESHIP_USER_COMPETENCIES,
  RESET_APPRENTICESHIP_USER_COMPETENCIES
} from './types';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchCompetencies } from '../services/competencies';

export const getApprenticeshipUserCompetencies = ({
  userId,
  apprenticeshipId,
  orgId,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1
}) => {
  const params = {
    userId,
    orgId,
    page,
    limit
  };

  if (apprenticeshipId) {
    params.apprenticeshipId = apprenticeshipId;
  }

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchCompetencies(params)
        .then((response) => {
          const list = response?.items || null;

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_APPRENTICESHIP_USER_COMPETENCIES,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetCompetencies = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIP_USER_COMPETENCIES });
  };
};
