import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { setCurrentModal } from '../../../actions/Modals';
import { canCreateCourse } from '../../../services/currentUser';
import { MODAL_KEY_CREATE_COURSE } from '../../../constants/modals';

const CreateCourseButton = ({
  className = 'btn btn-sm btn-primary',
  disabled = false,
  groupId
}) => {
  const dispatch = useDispatch();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || null;
  // User
  const role = currentUser?.role || [];

  function handleClick() {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_CREATE_COURSE,
        ...(groupId ? { data: { groupId } } : {})
      })
    );
  }

  return (
    <button
      className={className}
      title="Create Course"
      onClick={handleClick}
      disabled={disabled || !canCreateCourse(role, orgType)}
      type="button"
    >
      <span className="d-flex align-items-center">
        <FaPlus />
        <span className="ml-2">New</span>
      </span>
    </button>
  );
};

export default CreateCourseButton;
