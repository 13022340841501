import { getIdToken } from '@firebase/auth';
import { toast } from 'react-toastify';
import { TW_API } from '../constants/urls';
import { auth } from '../firebaseConfig';
import { redirectToAuth } from './auth';
import { AUTH_EXPIRED_TOKEN } from '../constants/errors';

const checkAuthToken = async () => {
  const idToken = await getIdToken(auth.currentUser, false);

  if (!idToken) {
    toast.error('Your session has expired.', { autoClose: false });
    setTimeout(() => {
      localStorage.removeItem('authUser');
      localStorage.removeItem('turbine_token');

      redirectToAuth({
        error: {
          code: AUTH_EXPIRED_TOKEN,
          message: 'Expired token.'
        }
      });
    }, 1000);

    return null;
  }

  return idToken;
};

async function request(method, endpoint, params, body) {
  if (typeof params === 'object') {
    const searchParams = new URLSearchParams(params);

    endpoint += `?${searchParams.toString()}`;
  }

  const idToken = await checkAuthToken();
  if (!idToken) {
    return;
  }

  const response = await fetch(TW_API + endpoint, {
    method: method || 'GET',
    body,
    headers: {
      Authorization: `FirebaseBearer ${idToken}`
    }
  });

  return response.json();
}

async function requestStreaming(method, endpoint, params, body) {
  if (typeof params === 'object') {
    const searchParams = new URLSearchParams(params);

    endpoint += `?${searchParams.toString()}`;
  }

  const idToken = await checkAuthToken();
  if (!idToken) {
    return;
  }

  const response = await fetch(TW_API + endpoint, {
    method: method || 'GET',
    body,
    headers: {
      Authorization: `FirebaseBearer ${idToken}`
    }
  });

  return response;
}

export async function get(endpoint, params) {
  return request('GET', endpoint, params);
}

export async function put(endpoint, params, body) {
  return request('PUT', endpoint, params, body);
}

export async function post(endpoint, params, body, streaming = false) {
  if (streaming) {
    return requestStreaming('POST', endpoint, params, body);
  }

  return request('POST', endpoint, params, body);
}

// "delete" is a JS keyword
export async function requestDelete(endpoint, params, body) {
  return request('DELETE', endpoint, params, body);
}
