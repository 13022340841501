import { getClient, flattenItem, flattenItems } from '../services/contentful';
import serializeQuiz from '../serializers/quiz';
import {
  SET_CURRENT_QUIZ,
  RESET_CURRENT_QUIZ,
  // Content Flow
  SET_CURRENT_CLASS_QUIZZES,
  RESET_CURRENT_CLASS_QUIZZES
} from './types';

// Content Flow

// TODO - implement similar function like "setCurrentAnswers"
// to create <Progress /> like Challenges
// setCurrentUserQuizOutcomes = () => {...}

export const setCurrentClassQuizzes = ({
  classId,
  activeQuiz,
  limit = 200
}) => {
  return (dispatch) => {
    const config = {
      content_type: 'quiz',
      'fields.classId': classId,
      limit
    };

    if (activeQuiz) {
      config['fields.activeQuiz'] = true;
    }

    return getClient()
      .getEntries(config)
      .then(({ items }) => {
        const currentClassQuizzes = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_CLASS_QUIZZES,
          currentClassQuizzes
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// end Content Flow

export const getQuiz = ({ quizId }) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      getClient()
        .getEntry(quizId)
        .then((response) => {
          const flattenResponse = response ? flattenItem(response) : null;
          const currentQuiz = flattenResponse
            ? serializeQuiz(flattenResponse)
            : null;
          const cdnFiles = flattenResponse?.integration?.cdnFiles || null;

          dispatch({
            type: SET_CURRENT_QUIZ,
            currentQuiz,
            cdnFiles
          });

          resolve(currentQuiz);
        });
    });
  };
};

export const resetCurrentClassQuizzes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZZES });
  };
};

export const resetCurrentQuiz = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_QUIZ });
  };
};
