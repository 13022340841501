import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RELOAD_IMG } from '@apprentage/constants';
import { setUserAppVersion } from '../../../services/organizations';
import { PLATFORM_NAME } from '../../../constants/globals';
import Modal from '../../Modal';

const Reload = () => {
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal
  const appVersion = currentModal?.data?.appVersion;
  // Local State
  const [loading, setLoading] = useState(false);

  return (
    <Modal visible={currentModal.visible}>
      <div className="reload-container text-center">
        <div>
          <div className="d-flex justify-content-center pt-4 pb-2">
            <img
              src={RELOAD_IMG}
              height="200"
              style={{
                height: '200px'
              }}
              alt="Reload"
            />
          </div>

          <h4 className="font-weight-bold">New version available!</h4>

          <h6 className="font-weight-bold mb-3">
            <span className="d-flex align-items-center justify-content-center">
              <span>{PLATFORM_NAME}</span>
              <span className="ml-1 border py-1 px-2 rounded small">
                turbine-admin
              </span>
              <span className="ml-1">v.{appVersion}</span>
            </span>
          </h6>

          <p className="small mb-4">
            To use the latest version click continue.
          </p>

          <div className="w-100 pb-5">
            <button
              className="btn btn-md btn-primary m-auto d-block"
              title="Update Version"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setUserAppVersion(appVersion);
                setTimeout(() => {
                  window.location.reload();
                }, 250);
              }}
              type="button"
            >
              {loading ? 'Processing...' : 'Continue'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Reload;
