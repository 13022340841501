import { fetchEmployerAgreements } from '../services/employerAgreements';
import { findEmployerAgreement } from '../services/employers';
import {
  SET_EMPLOYER_AGREEMENT,
  RESET_EMPLOYER_AGREEMENT,
  SET_EMPLOYER_AGREEMENTS,
  RESET_EMPLOYER_AGREEMENTS
} from './types';

export const setEmployerAgreement = ({
  list,
  employerProfileId,
  apprenticeshipId
}) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      const employerAgreement = findEmployerAgreement({
        list,
        employerProfileId,
        apprenticeshipId
      });

      dispatch({
        type: SET_EMPLOYER_AGREEMENT,
        employerAgreement
      });

      resolve(employerAgreement);
    });
  };
};

export const getEmployerAgreements = ({
  ids,
  employerProfileId,
  apprenticeshipId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchEmployerAgreements({
        ids,
        employerProfileId,
        apprenticeshipId
      })
        .then((list) => {
          dispatch({
            type: SET_EMPLOYER_AGREEMENTS,
            list
          });

          if (employerProfileId && apprenticeshipId) {
            dispatch(
              setEmployerAgreement({
                list,
                employerProfileId,
                apprenticeshipId
              })
            );
          }

          resolve(list);
        })
        .catch((error) => {
          console.error('getEmployerAgreements', error);
          reject(error);
        });
    });
  };
};

export const resetEmployerAgreement = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER_AGREEMENT });
  };
};

export const resetEmployerAgreements = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER_AGREEMENTS });
  };
};
