import React from 'react';
import { useSelector } from 'react-redux';
import DotMenuWorkforceInfo from './DotMenuWorkforceInfo';
import InfoField from '../../../../InfoField';

const EmployeesBlock = ({
  title = 'Employee Demographics',
  className = 'card m-3 w-100',
  showMenu
}) => {
  const employerProfile = useSelector((state) => state.employerProfile);

  return (
    <div className={className}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="font-weight-bold">{title}</div>
        {showMenu && <DotMenuWorkforceInfo />}
      </div>

      <div className="card-body h-100">
        <InfoField
          className="mb-2"
          name="Total"
          value={employerProfile?.totalEmployees}
        />
        <InfoField
          className="mb-2"
          name="Female"
          value={employerProfile?.totalEmployeesFemale}
        />
        <InfoField
          name="People of Color"
          value={employerProfile?.totalEmployeesColor}
        />
      </div>
    </div>
  );
};

export default EmployeesBlock;
