import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { updateNotification } from '../../../services/notifications';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import { getNotifications } from '../../../actions/Notifications';
import { NOTIFICATIONS_PAGINATION_LIMIT } from '../../../constants/api';
import { resetCurrentModal } from '../../../actions/Modals';

const DotMenu = ({ data, notificationType, className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const notificationsOrg = useSelector((state) => state.notificationsOrg);
  // Organization
  const orgId = organization?.id || '';
  // Course
  const classId = course?.id || '';
  // Notification
  const notificationId = data?.id || '';
  // Local State
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <div className={`btn-group align-items-center ${className}`}>
        <button
          className="btn-sm btn-white border text-muted"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Manage Menu"
          title="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="px-2 py-1 text-left text-nowrap border-bottom small">
            <span className="small">Change status to:</span>
          </div>

          <button
            type="button"
            className="dropdown-item border-bottom py-2"
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            <span className="d-flex align-items-center">Unpublished</span>
          </button>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationToast
          title="Are you sure?"
          primaryActionBtnText="Yes, unpublish"
          onConfirm={() => {
            // Unpublish Notification
            toast.info('Processing...', {
              toastId: 'confirmUnpublishNotification'
            });
            updateNotification({ isDraft: true }, data?.id).then(() => {
              toast.dismiss('confirmUnpublishNotification');

              dispatch(
                getNotifications({
                  isDraft: data?.isDraft || null,
                  orgId,
                  includePinned: true,
                  classId:
                    notificationType === 'course' && classId ? classId : null,
                  page: notificationId ? notificationsOrg?.pagination?.page : 1, // Refresh current page to show updated notification
                  limit:
                    notificationsOrg?.pagination?.limit ||
                    NOTIFICATIONS_PAGINATION_LIMIT
                })
              );

              dispatch(resetCurrentModal());
              setShowConfirmation(false);
            });
          }}
          handleCancel={() => {
            setShowConfirmation(false);
          }}
          handleClose={() => {
            setShowConfirmation(false);
          }}
        />
      )}
    </>
  );
};

export default DotMenu;
