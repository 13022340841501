import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaImage, FaMagic } from 'react-icons/fa';
import $ from 'jquery';
import { resetCurrentModal } from '../../../actions/Modals';
import { saveCdnFiles, updateCdnFiles } from '../../../actions/Entry';
import { fetchGroup } from '../../../services/groups';
import {
  autoGenerateDescriptionCaptionAndKeywords,
  autoGenerateTitleAndBody,
  coerceToResourceName,
  createResource,
  generateUniqueResourceId,
  updateResource
} from '../../../services/resources';
// import { hyphenToSpace } from '../../../utils/filenames';
import { getResourcesByParentId } from '../../../actions/Resources';
import { summarizeDocTypes } from '../../../services/openAi';
import { SUPABASE_CONTENT_TYPES } from '../../../constants/api';
import Modal from '../../Modal';
import Collapse from '../../ManageContent/Collapse';
import FileMetadata from './FileMetadata';
import ActualFilename from './ActualFilename';
import FieldTextArea from '../../ManageContent/FieldTextarea';
import TagInput from '../../ManageContent/TagInput';
import htmlToPlainText from '../../../utils/htmlToPlainText';
// import DotMenu from './DotMenu';

const ManageCdnFile = () => {
  const dispatch = useDispatch();
  const nameRef = useRef(null);
  // redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const currentUser = useSelector((state) => state.currentUser);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  const resourceGroups = useSelector((state) => state.resourceGroups);
  const material = useSelector((state) => state.material);
  // Organization
  const orgId = organization?.id || '';
  // Modal
  const modalData = currentModal?.data || {};
  const modalTitle = modalData?.modalTitle || 'Manage File';
  // Content (group, resourceGroup, course, etc...)
  const contentId = modalData?.contentId || '';
  const contentType = modalData?.contentType || '';
  // File
  const currentFile = modalData?.currentFile || null;
  const currentFileId = modalData?.currentFileId || '';
  const currentFileType = currentFile?.type || '';

  // File : Name
  const nameConfig = useMemo(
    () => ({
      fixCamelCase: true,
      fixHyphens: true,
      fixUnderScores: true,
      fixPlus: true
    }),
    []
  );
  // Local State
  const [loading, setLoading] = useState(false);
  const [summarizing, setSummarizing] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [name, setName] = useState(
    currentFile.name
      ? coerceToResourceName({ ...nameConfig, str: currentFile.name })
      : ''
  );
  const [url, setUrl] = useState(currentFile.url || '');
  const [body, setBody] = useState(currentFile.body || null);
  const [caption, setCaption] = useState(currentFile.caption || null);
  const [keywords, setKeywords] = useState(currentFile.keywords || null);

  useEffect(() => {
    let mounted = true;
    if (contentType === 'group' && contentId) {
      fetchGroup(contentId).then((group) => {
        if (mounted) {
          setCurrentGroup(group);
        }
      });
    }

    // TODO:
    // if (contentType === 'materials')
    // set Material, use title and body
    // as context for summarizingImage

    return function cleanup() {
      mounted = false;
    };
  }, [contentId, contentType]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const coercedFileName = useMemo(() => {
    return coerceToResourceName({
      str: name,
      ...nameConfig
    });
  }, [name, nameConfig]);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const dataToSave = {
      name: coercedFileName,
      body: body || null,
      url,
      caption: caption || null,
      keywords: Array.isArray(keywords) && keywords.length > 0 ? keywords : null
    };

    if (SUPABASE_CONTENT_TYPES.includes(contentType)) {
      // EDIT Resource
      if (currentFileId) {
        updateResource(dataToSave, currentFileId)
          .then(() => {
            dispatch(getResourcesByParentId(contentId, contentType)).then(
              () => {
                toast.success('File updated!');
                handleClose();
              }
            );
          })
          .catch(() => {
            toast.error('Something went wrong');
          });
        return;
      }

      // ADD Resource
      const dataToCreate = {
        ...dataToSave,
        orgId,
        userId: currentUser?.id,
        userName: currentUser?.name,
        type: currentFileType,
        parentType: contentType,
        parentId: contentId,
        parentIds: Array.isArray(resourceGroups?.groupIds)
          ? resourceGroups?.groupIds
          : null
      };

      createResource(dataToCreate)
        .then(() => {
          dispatch(getResourcesByParentId(contentId, contentType)).then(() => {
            toast.success(
              `${currentFileType === 'link' ? 'Link' : 'File'} added!`
            );
            handleClose();
          });
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
      return;
    }

    if (currentFileId) {
      // EDIT
      dispatch(
        updateCdnFiles({
          fileObj: {
            ...currentFile,
            name: coercedFileName,
            ...(body !== undefined ? { body } : {}),
            ...(currentFileType === 'link' ? { url } : {})
          },
          fileAction: 'edit',
          contentId,
          contentType,
          ...(contentType === 'group' ? { groupType: currentGroup.type } : {})
        })
      ).then(() => {
        toast.success('File updated!');
        handleClose();
      });
    } else {
      // ADD
      generateUniqueResourceId({ orgId }).then((uniqueResourceId) => {
        dispatch(
          saveCdnFiles({
            data: {
              fileObj: {
                id: uniqueResourceId,
                type: currentFileType,
                url,
                name,
                ...(body !== undefined ? { body } : {})
              }
            },
            contentId,
            contentType,
            ...(contentType === 'group' ? { groupType: currentGroup.type } : {})
          })
        ).then(() => {
          toast.success('Link Added!');
          handleClose();
        });
      });
    }
  };

  // const handleFilenameAction = (actionName) => {
  //   if (actionName === 'hyphenToSpace') {
  //     setName(hyphenToSpace(name, true));
  //   }
  // };

  const hideFileRestrictions = () => {
    $('#filename-restrictions').collapse('hide');
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h1 className="h5 m-0 text-white">{modalTitle}</h1>
        </div>

        <form onSubmit={onSubmit}>
          <div className="card-body">
            <div className="mb-3">
              <div className="font-weight-bold mb-1">
                Name: <span className="text-danger">*</span>
              </div>
              <div className="d-flex">
                <input
                  ref={nameRef}
                  className="form-control"
                  name="name"
                  type="text"
                  value={name}
                  maxLength="255"
                  required
                  autoFocus
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setName(value);
                  }}
                  onBlur={(e) => {
                    const { value } = e.currentTarget;

                    hideFileRestrictions();

                    setName(value);
                  }}
                />
                {/* <DotMenu
                  handleFilenameAction={handleFilenameAction}
                /> */}
              </div>

              {currentFileType !== 'link' && <ActualFilename filename={name} />}
            </div>

            {currentFileType === 'link' && (
              <div className="mb-3">
                <div className="font-weight-bold mb-1">
                  URL: <span className="text-danger">*</span>
                </div>
                <div className="text-muted mb-2">
                  Enter the URL to any file, website, form, etc.
                </div>
                <input
                  className="form-control"
                  type="text"
                  value={url}
                  required
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setUrl(value);
                  }}
                />
                <div className="text-muted small mt-1">
                  Ex: https://webiste.com/folder/filename.pdf
                </div>
              </div>
            )}

            {!currentFileType.includes('image/') && (
              <div className="mb-4">
                <div className="font-weight-bold mb-1">Description:</div>
                <div className="position-relative">
                  <FieldTextArea
                    disabled={summarizing}
                    value={body || ''}
                    maxCharCount={1000}
                    onChange={(newBody) => {
                      setBody(newBody);
                    }}
                    onFocus={() => {
                      hideFileRestrictions();
                    }}
                  />
                  {summarizeDocTypes.includes(currentFileType) && (
                    <button
                      type="button"
                      disabled={summarizing}
                      className="btn-sm btn-link position-absolute d-flex align-items-center bg-white"
                      style={{
                        bottom: '25px',
                        right: '10px'
                      }}
                      onClick={() => {
                        setSummarizing(true);
                        autoGenerateTitleAndBody({
                          isEmbedded: currentFile?.isEmbedded,
                          orgId,
                          id: currentFileId,
                          url: currentFile?.url
                        })
                          .then((response) => {
                            setBody(response?.body);

                            // File was NOT embedded & user is currently in resources
                            if (!currentFile?.isEmbedded && resourceGroup?.id) {
                              // Refresh resources list to show embedded icon on resource
                              dispatch(
                                getResourcesByParentId(resourceGroup?.id)
                              );
                            }
                          })
                          .catch((error) => {
                            console.error(error);
                            toast.error(error);
                          })
                          .finally(() => {
                            setSummarizing(false);
                          });
                      }}
                    >
                      <FaMagic />
                      <span className="ml-1">
                        {summarizing ? 'Generating...' : 'Auto-Generate'}
                      </span>
                    </button>
                  )}
                </div>
              </div>
            )}

            {currentFileType.includes('image/') && (
              <div className="card mb-4">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <FaImage />
                    <h6 className="mb-0 ml-2">Metadata</h6>
                  </div>
                  <button
                    type="button"
                    disabled={summarizing}
                    className="btn-sm text-primary border d-flex align-items-center btn-white"
                    onClick={() => {
                      setSummarizing(true);

                      let context = '';

                      if (material?.title) {
                        context += `${material.title}`;
                      }

                      if (material?.body) {
                        context += `. ${htmlToPlainText(material.body)}`;
                      }

                      autoGenerateDescriptionCaptionAndKeywords({
                        isEmbedded: currentFile?.isEmbedded,
                        orgId,
                        id: currentFileId,
                        url: currentFile?.url,
                        ...(context ? { context } : {})
                      })
                        .then((response) => {
                          if (
                            Array.isArray(response?.data) &&
                            response?.data.length === 1
                          ) {
                            setBody(response?.data[0]?.description);
                            setCaption(response?.data[0]?.caption);
                            setKeywords(response?.data[0]?.keywords);
                          }

                          // File was NOT embedded & user is currently in resources
                          if (!currentFile?.isEmbedded && resourceGroup?.id) {
                            // Refresh resources list to show embedded icon on resource
                            dispatch(getResourcesByParentId(resourceGroup?.id));
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                          toast.error(error);
                        })
                        .finally(() => {
                          setSummarizing(false);
                        });
                    }}
                  >
                    <FaMagic />
                    <span className="ml-1">
                      {summarizing ? 'Generating...' : 'Auto-Generate'}
                    </span>
                  </button>
                </div>
                <div className="card-body">
                  <div className="mb-1">
                    <div className="font-weight-bold mb-1">Description:</div>

                    <FieldTextArea
                      disabled={summarizing}
                      value={body || ''}
                      maxCharCount={1000}
                      onChange={(newBody) => {
                        setBody(newBody);
                      }}
                      onFocus={() => {
                        hideFileRestrictions();
                      }}
                    />
                  </div>
                  <div className="mb-1">
                    <div className="font-weight-bold mb-1">Caption:</div>
                    <FieldTextArea
                      disabled={summarizing}
                      value={caption || ''}
                      maxCharCount={1000}
                      onChange={(newCaption) => {
                        setCaption(newCaption);
                      }}
                    />
                  </div>
                  <div>
                    <div className="font-weight-bold mb-1">Keywords:</div>
                    <TagInput
                      id="keywords"
                      name="keywords"
                      tags={keywords || []}
                      disabled={summarizing}
                      onChange={({ keywords: newKeywords }) => {
                        setKeywords(
                          newKeywords.length === 0 ? null : newKeywords
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {currentFileId && (
              <Collapse
                className=""
                title="Metadata"
                id={`group-${contentId}`}
              >
                <FileMetadata {...currentFile} />
              </Collapse>
            )}
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button
              className="btn btn-link ml-2"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageCdnFile;
