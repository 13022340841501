import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../actions/Modals';
import { canCreatePathway } from '../../../services/currentUser';

const AddPathwayButton = ({ className = 'btn btn-sm btn-primary' }) => {
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const role = pathOr([], ['role'], currentUser);

  function handleClick() {
    dispatch(
      setCurrentModal({
        key: 'createPathway'
      })
    );
  }

  return (
    <button
      className={className}
      title="Add Pathway"
      onClick={handleClick}
      disabled={!canCreatePathway(role)}
      type="button"
    >
      Add Pathway
    </button>
  );
};

export default AddPathwayButton;
