export const PLATFORM_NAME = 'Turbine Workforce';
export const TURBINE_STRIPE_LIVE = process.env.REACT_APP_TURBINE_STRIPE_LIVE;
export const TURBINE_STRIPE_TEST = process.env.REACT_APP_TURBINE_STRIPE_TEST;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE;
// export const TURBINE_TOM_TOM_KEY = process.env.REACT_APP_TOM_TOM_KEY; // https://developer.tomtom.com/user/me/apps
export const TURBINE_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY; // https://console.cloud.google.com/apis/credentials?authuser=2&project=turbine-workforce
export const TURBINE_ADMIN_EMAIL = 'hello@turbineworkforce.com';
export const TURBINE_ADMIN_BCC_EMAILS =
  'alan@turbineworkforce.com,josh@turbineworkforce.com';
export const CLASS_COLOR_DEFAULT = '#b3bac5';
// INTEGRATIONS
export const MICROSOFT_TEAMS_CHAT_LINK_BASE =
  'https://teams.microsoft.com/l/chat/0/0?users=';
// SEARCH
export const SEARCH_TYPE_RESOURCES = 'resources';
export const SEARCH_TYPE_USERS = 'users';
export const SEARCH_TYPE_PROJECTS = 'projects';
export const SEARCH_TYPE_COURSES = 'courses';

export const SEARCH_CONTENT_TYPES = [
  SEARCH_TYPE_RESOURCES,
  SEARCH_TYPE_USERS,
  SEARCH_TYPE_PROJECTS,
  SEARCH_TYPE_COURSES
].join(',');

export const SEARCH_ADVANCED_FILTERS = {
  excludeInactive: true,
  excludeTestUsers: true
};
