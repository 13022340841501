import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { routeWithClassId } from '../../../../services/courses';
import {
  CLASS_CONSOLE_BASE,
  ORG_CONSOLE_CHALLENGES
} from '../../../../constants/routes';
import { goToElem } from '../../../../services/manageContent';

const UserNameCell = ({
  row,
  value: userName,
  userNamePlainText,
  hideCourse,
  hideChallenge
}) => {
  const params = useParams();
  // Answer
  const challengeId = row?.original?.challengeId;
  const classId = row?.original?.classId;
  const userId = row?.original?.userId;

  const answerUrl = useMemo(() => {
    if (hideChallenge) {
      return routeWithClassId({
        route: `${CLASS_CONSOLE_BASE}/challenge/${challengeId}/user-submissions/${userId}`,
        classId
      });
    }

    if (hideCourse) {
      return routeWithClassId({
        route: `${CLASS_CONSOLE_BASE}/challenges/user-submissions/${userId}`,
        classId
      });
    }

    return `${ORG_CONSOLE_CHALLENGES}/user-submissions/${userId}`;
  }, [challengeId, classId, hideChallenge, hideCourse, userId]);

  if (params?.userId || userNamePlainText) {
    return userName;
  }

  return (
    <>
      <Link
        to={answerUrl}
        onClick={() => {
          goToElem('root');
        }}
        title={userName}
        className="btn-link"
      >
        {userName}
      </Link>
    </>
  );
};

export default UserNameCell;
