import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { getUsers, resetUsers } from '../../actions/Users';
import { getGroups } from '../../actions/Groups';
import { setCurrentModal } from '../../actions/Modals';
import { fetchUser } from '../../services/currentUser';
import { entryOrgMatchesUserOrg } from '../../services/organizations';
import UsersList from '../lists/UsersList';

const MembersList = ({
  className,
  route,
  listConfig,
  showGroupsCol = false,
  showContactCol = false,
  showSkillsCol = false,
  // Course Action Handlers
  takeAttendance,
  manageCertificate
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const users = useSelector((state) => state.users);

  // Organization
  const orgId = organization?.id || '';

  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  const sendMsgUserId = searchParams.get('sendMsgUserId');

  // TODO move to /src/components/OrgConsole/Users
  // DEEP LINK SEND MESSAGE
  useEffect(() => {
    if (sendMsgUserId) {
      fetchUser({
        userId: sendMsgUserId,
        select: ['id', 'orgId', 'name', 'email']
      }).then((response) => {
        const fromName = currentUser?.name || '';
        const replyTo = currentUser?.email || '';
        const userName = response?.name || '';
        const userEmail = response?.email || '';

        if (entryOrgMatchesUserOrg(response?.orgId, currentUser?.orgId)) {
          dispatch(
            setCurrentModal({
              key: 'sendEmail',
              data: {
                name: userName,
                email: userEmail,
                fromName,
                replyTo,
                subject: `New message from ${fromName}`,
                hideSubject: true,
                disableSubject: true
              },
              callbackSecondaryAction: () => {
                history.push(`${route}/${sendMsgUserId}`);
              }
            })
          );
        }
      });
    }
  }, [
    currentUser?.email,
    currentUser?.name,
    currentUser?.orgId,
    dispatch,
    history,
    route,
    sendMsgUserId
  ]);
  // /DEEP LINK SEND MESSAGE

  useEffect(() => {
    if (listConfig) {
      dispatch(getUsers(listConfig));
    }
  }, [dispatch, listConfig]);

  useEffect(() => {
    if (orgId) {
      dispatch(getGroups({ orgId, type: 'user' }));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetUsers());
    };
  }, [dispatch]);

  return (
    <>
      <UsersList
        className={className}
        route={route}
        data={users?.list}
        showGroupsCol={showGroupsCol}
        showContactCol={showContactCol}
        showSkillsCol={showSkillsCol}
        // Course Action Handlers
        manageCertificate={manageCertificate}
        takeAttendance={takeAttendance}
      />

      <ReactTooltip id="MembersList" />
    </>
  );
};

export default MembersList;
