import { setCurrentModal } from './Modals';

export const showFeatureFlagNoticeModal = ({
  modalTitle = 'Read-only',
  modalBody = 'Routine maintenance will temporarily restrict this feature to read-only mode.',
  preventClose = false
}) => {
  return (dispatch) => {
    dispatch(
      setCurrentModal({
        key: 'featureFlagNotice',
        data: {
          modalTitle,
          modalBody,
          preventClose
        }
      })
    );
  };
};
