import React, { useState } from 'react';
import shortenString from '../../../../../utils/shortenString';
import htmlToPlainText from '../../../../../utils/htmlToPlainText';
import RenderMarkdown from '../../../../ManageContent/RenderMarkdown';
import './styles.css';

const RawTextCell = ({ value, row }) => {
  const [readMore, setReadMore] = useState(false);
  let body = value || '';
  const maxNumChars = 400;

  if (body && row?.original?.type === 'material') {
    body = htmlToPlainText(body);
  }

  if (body.length > maxNumChars) {
    body = shortenString(body || '', maxNumChars);
  }

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <div className="CdnFiles-RawTextCell">
      <RenderMarkdown
        source={readMore ? value : body}
        enableNewEditor
      >
        <>
          {body.length > maxNumChars && (
            <button
              onClick={toggleReadMore}
              className="btn-link ml-2"
              // style={{ float: 'left'}}
              type="button"
            >
              {readMore ? 'Read Less' : 'Read More'}
            </button>
          )}
        </>
      </RenderMarkdown>
    </div>
  );
};

export default RawTextCell;
