import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCog, FaEllipsisH, FaWaveSquare } from 'react-icons/fa';
import { setCurrentModal } from '../../../actions/Modals';
import { canCreatePathway } from '../../../services/currentUser';

const DotMenu = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const pathway = useSelector((state) => state.pathway);
  // Current User
  const role = currentUser?.role || [];

  const openPathwaySettingsModal = () => {
    dispatch(
      setCurrentModal({
        key: 'createPathway',
        data: {
          title: pathway?.title,
          pathwayId: pathway?.id,
          ...(pathway?.courseIds ? { courseIds: pathway?.courseIds } : {}),
          ...(pathway?.jobProfileId
            ? { jobProfileId: pathway?.jobProfileId }
            : {})
        }
      })
    );
  };

  return (
    <div className="btn-group align-items-center">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white">
          <span className="d-flex align-items-center">
            <FaWaveSquare />
            <span className="ml-2">{pathway?.title}</span>
          </span>
        </div>

        {canCreatePathway(role) && (
          <button
            type="button"
            className="dropdown-item border-bottom  py-2"
            onClick={openPathwaySettingsModal}
          >
            <span className="d-flex align-items-center">
              <FaCog />
              <span className="ml-2">Settings</span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DotMenu;
