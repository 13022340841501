import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { getPage, resetPage } from '../../../actions/Pages';
import { withAuthorization } from '../../../components/Session';
import LeftNav from '../components/LeftNav';
import TopNav from '../components/TopNav';
import withOrgConsole from '../../../components/App/withOrgConsole';
import Vitals from './Vitals';
import DailyUsage from './DailyUsage';
import { daysAgo } from '../../../utils/date';

import { getAnalytics } from '../../../actions/Analytics';
import collateByGroupKeyAndDateKey from '../../../utils/collateByGroupKeyAndDateKey';

const PageAnalytics = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const analytics = useSelector((state) => state.analytics);
  const pageLink = useSelector((state) => state.pageLink);
  // Organization
  const orgId = organization?.id || null;
  // Page
  const pageId = params?.pageId;
  // Page Link
  const pageLinkId = pageLink?.id;
  // Local State
  const turbinePagesNavCollapse = window?.localStorage?.getItem(
    'turbine-pages-nav-collapse'
  );
  const [collapseNav, setCollapseNav] = useState(
    turbinePagesNavCollapse || false
  );
  const [numDaysAgo, setNumDaysAgo] = useState(90);
  const [numDaysAgoDate, setNumDaysAgoDate] = useState(
    daysAgo(numDaysAgo).toISOString()
  );
  const [dataType, setDataType] = useState('Views');

  const onChangeUsageDate = (e) => {
    const { value } = e.currentTarget;

    const num = parseInt(value, 10);
    setNumDaysAgo(num);
    setNumDaysAgoDate(daysAgo(num).toISOString());
  };

  const callbackPrimaryAction = () => {
    console.log('callbackPrimaryAction');
  };

  const rawData = useMemo(() => {
    const allItems = analytics?.list || [];
    const filteredItems = collateByGroupKeyAndDateKey(
      allItems,
      'orgId',
      'createdAt'
    );

    return filteredItems.map((items) => {
      return {
        ...items,
        name: 'Views' // page?.title
      };
    });
  }, [analytics?.list]);

  useEffect(() => {
    if (pageId) {
      dispatch(getPage(pageId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }

    return function cleanup() {
      dispatch(resetPage());
    };
  }, [dispatch, pageId]);

  useEffect(() => {
    if (pageLinkId) {
      dispatch(
        getAnalytics({
          limit: 1000,
          orgId,
          refId: pageLinkId,
          type: dataType === 'Views' ? 'view' : 'click',
          refTable: 'pageLinks',
          gteDate: numDaysAgoDate
        })
      );
    }
  }, [dataType, dispatch, numDaysAgoDate, orgId, pageLinkId]);

  return (
    <div
      id="body-row"
      className="row"
    >
      <LeftNav
        id="sidebar-container"
        collapseNav={collapseNav}
        setCollapseNav={setCollapseNav}
      />

      <div
        role="main"
        className="col p-3"
        style={{
          marginLeft: collapseNav ? '50px' : '230px'
        }}
      >
        <TopNav />

        <div className="row mb-4">
          <div
            // className={`col-12 ${collapseNav ? 'col-lg-8' : 'col-xl-8'}`}
            className="col-12"
          >
            <Vitals size="md" />

            <DailyUsage
              dataType={dataType}
              setDataType={setDataType}
              collapseNav={collapseNav}
              rawData={rawData}
              numDaysAgo={numDaysAgo}
              onChangeUsageDate={onChangeUsageDate}
              callbackPrimaryAction={callbackPrimaryAction}
            />
          </div>
          {/* <div className={`d-none col-12 ${collapseNav ? 'col-lg-4 d-lg-block' : 'col-xl-4 d-xl-block'} `}>
            <h6>Insights</h6>
            <p>
              Something
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  PageAnalytics,
  true
);
