import React, { useMemo, useState } from 'react';
import PageType from '../../../routes/Page/Settings/PageType';

const ChoosePageType = ({ data, updateState, saveData }) => {
  const [loading, setLoading] = useState(false);
  const [currentState, setState] = useState(data);

  const handleSave = () => {
    setLoading(true);
    saveData();
  };

  const pageTypeSaveButtonDisabled = useMemo(() => {
    if (!data?.enablePageContent) {
      if (!data?.url) {
        return true;
      }

      if (!data?.url.includes('https://') || !data?.url.includes('.')) {
        return true;
      }
    }

    return false;
  }, [data?.enablePageContent, data?.url]);

  const handleChange = (newState) => {
    const dataToSave = {
      ...currentState,
      ...newState
    };

    if (dataToSave.enablePageContent) {
      // Turbine Page
      dataToSave.url = null;
    }

    setState(dataToSave);
    updateState(dataToSave);
  };

  return (
    <>
      <div className="card-body">
        <PageType
          showStepTitles
          enablePageContent={currentState.enablePageContent}
          setEnablePageContent={(newEnablePageContent) => {
            handleChange({ enablePageContent: newEnablePageContent });
          }}
          url={currentState.url}
          setUrl={(newUrl) => {
            handleChange({ url: newUrl });
          }}
        />
      </div>
      <div className="card-footer">
        <button
          className="btn btn-sm btn-primary"
          onClick={handleSave}
          type="button"
          disabled={pageTypeSaveButtonDisabled || loading}
        >
          {loading ? 'Creating..' : 'Create'}
        </button>
      </div>
    </>
  );
};

export default ChoosePageType;
