import React from 'react';
import { FaCheckCircle, FaEyeSlash } from 'react-icons/fa';

const InactiveCell = ({ value: inactive }) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ height: '40px' }}
    >
      {inactive ? (
        <>
          <FaEyeSlash />
          <span className="ml-1">Inactive</span>
        </>
      ) : (
        <>
          <FaCheckCircle className="text-keppel" />
          <span className="ml-1">Active</span>
        </>
      )}
    </div>
  );
};

export default InactiveCell;
