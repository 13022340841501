import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaEyeSlash } from 'react-icons/fa';
import { canManageUsers } from '../../../../services/currentUser';
import { removeUserFromList } from '../../../../actions/Users';
import KeyValueItem from '../../../KeyValueItem';

const CourseActionsCell = ({ row }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const user = row?.original;
  const userId = user?.id;
  const userName = user?.name;
  const userEmail = user?.email;
  // Current User
  const currentUserRole = currentUser?.role || [];

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="User Actions"
          title="User Actions"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6
              className="m-0 text-nowrap"
              data-demo="disabled"
            >
              {userName}
            </h6>
          </div>

          {canManageUsers(currentUserRole) && (
            <button
              className="dropdown-item border-bottom p-2 d-flex align-items-center"
              onClick={() => {
                dispatch(removeUserFromList({ userId }));
              }}
              type="button"
            >
              <FaEyeSlash size={15} />
              <span className="ml-2">Exclude from exported data</span>
            </button>
          )}

          <div className="card-footer p-2 small">
            <KeyValueItem
              title="Email"
              value={userEmail}
            />
            <KeyValueItem
              title="ID"
              value={userId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseActionsCell;
