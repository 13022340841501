import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { OrgLogo } from '@apprentage/components';
import Switch from '../../Switch';
import { updatePageLink } from '../../../services/pageLinks';

const OrgLogoSwitch = ({
  imageHeight = 45,
  className = '',
  defaultValue = false,
  onSuccessCallback
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const pageLink = useSelector((state) => state.pageLink);
  // Local State
  const [showOrgLogo, setShowOrgLogo] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const toastId = 'errorSavePageLink';

  const errorSaving = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.', {
      autoClose: false,
      toastId
    });
    setShowOrgLogo(!showOrgLogo);
    setLoading(false);
  };

  const onChangeShowOrgLogo = () => {
    const value = !showOrgLogo;

    setShowOrgLogo(value);
    setLoading(true);

    updatePageLink({ showOrgLogo: value === false ? null : true }, pageLink?.id)
      .then((response) => {
        if (response?.error) {
          errorSaving(response?.error);
          return;
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }

        toast.success('Logo visibility updated!');
        setLoading(false);
      })
      .catch((error) => {
        errorSaving(error);
      });
  };

  return (
    <div className={`${className} d-flex justify-content-between`}>
      <OrgLogo
        src={organization?.orgLogo}
        alt={organization?.name}
        height={imageHeight}
      />
      <Switch
        id="pageLinkShowOrgLogo"
        // label="Visible"
        disabled={loading}
        value={showOrgLogo}
        onChange={onChangeShowOrgLogo}
      />
    </div>
  );
};

export default OrgLogoSwitch;
