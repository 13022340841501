import {
  SET_LOCATIONS,
  RESET_LOCATIONS,
  SET_LOCATIONS_WEATHER_CURRENT
} from '../../actions/types';
import generateHashFromArray from '../../utils/generateHashFromArray';

const initialState = {
  list: null,
  hash: null, // {}
  weatherCurrentList: null,
  weatherCurrentListFetched: false,
  fetched: false
};

export default function locations(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATIONS: {
      return {
        ...state,
        list: action.list,
        hash: generateHashFromArray(action.list),
        fetched: true
      };
    }
    case SET_LOCATIONS_WEATHER_CURRENT: {
      return {
        ...state,
        weatherCurrentList: action.weatherCurrentList,
        weatherCurrentListFetched: true
      };
    }
    case RESET_LOCATIONS: // TODO could be RESET_ORG
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
