import React from 'react';
import KeyValueItem from '../../../../KeyValueItem';

const IdHashCell = ({ value: id }) => {
  return (
    <div className="d-flex align-items-center">
      <KeyValueItem
        value={id}
        copy
      />
    </div>
  );
};

export default IdHashCell;
