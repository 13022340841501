import React from 'react';

const Tabs = ({
  defaultTabName = 'Items',
  className = '',
  hasStyling = false,
  activeTab,
  setActiveTab,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'items' && 'active'}`}
            type="button"
            disabled={activeTab === 'items'}
            onClick={() => {
              setActiveTab('items');
            }}
          >
            {defaultTabName}
          </button>
        </li>
        {hasStyling && (
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'styling' && 'active'}`}
              type="button"
              disabled={activeTab === 'styling'}
              onClick={() => {
                setActiveTab('styling');
              }}
            >
              Styling
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Tabs;
