import {
  RESET_COURSES,
  RESET_SYNDICATED_COURSES,
  RESET_SYNDICATED_COURSES_SHARING,
  SET_COURSES,
  SET_SYNDICATED_COURSES,
  SET_SYNDICATED_COURSES_SHARING
} from '../../actions/types';
import { classPreReqs, topicPreReqs } from '../../services/courses';

const initialState = {
  list: null,
  fetched: false,
  classPreReqs: {},
  topicPreReqs: {}, // hash table of topic prerequisites.
  syndicatedCourses: null,
  syndicatedCoursesSharing: null
};

export default function courses(state = initialState, action) {
  switch (action.type) {
    case SET_COURSES: {
      return {
        ...state,
        list: action.courses,
        fetched: true,
        classPreReqs: classPreReqs({ courses: action.courses }),
        topicPreReqs: topicPreReqs({ courses: action.courses })
      };
    }
    case SET_SYNDICATED_COURSES: {
      return {
        ...state,
        syndicatedCourses: action.syndicatedCourses
      };
    }
    case RESET_SYNDICATED_COURSES: {
      return {
        ...state,
        syndicatedCourses: null
      };
    }
    case SET_SYNDICATED_COURSES_SHARING: {
      return {
        ...state,
        syndicatedCoursesSharing: action.syndicatedCoursesSharing
      };
    }
    case RESET_SYNDICATED_COURSES_SHARING: {
      return {
        ...state,
        syndicatedCoursesSharing: null
      };
    }
    case RESET_COURSES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
