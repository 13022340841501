import React from 'react';

const NumberCell = ({ value }) => {
  const strValue = `${value}`;

  return (
    <div className="d-flex align-items-center justify-content-center">
      <span className="px-2 py-1 bg-light border rounded">
        {strValue.includes('.') ? parseFloat(value).toFixed(2) : value}
      </span>
    </div>
  );
};

export default NumberCell;
