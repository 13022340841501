import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAssistants } from '../../../actions/Assistants';
import { ORG_CONSOLE_ASSISTANT_BASE } from '../../../constants/routes';
import Loading from '../../Loading';
import List from './List';

const AssistantsList = ({ list, listConfig, onClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const assistants = useSelector((state) => state.assistants);
  // misc
  const orgId = organization?.id || '';

  const handleClickRow = (assistant) => {
    if (onClick) {
      return onClick(assistant);
    }

    history.push(`${ORG_CONSOLE_ASSISTANT_BASE}/${assistant.id}`);
  };

  useEffect(() => {
    if (orgId && listConfig) {
      dispatch(getAssistants(listConfig));
    }
  }, [dispatch, listConfig, orgId]);

  if (!listConfig && !list) {
    return null;
  }

  if (listConfig && !assistants.fetched && !assistants.list) {
    return <Loading text="Loading Assistants..." />;
  }

  return (
    <div className="assistants-list">
      <List
        handleClickRow={handleClickRow}
        data={list || assistants?.list}
        pagination={assistants?.pagination}
      />
    </div>
  );
};

export default AssistantsList;
