import React from 'react';

const PagesTabs = ({
  className = '',
  listConfig,
  setListConfig,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${!listConfig?.archived && 'active'}`}
            type="button"
            disabled={!listConfig?.archived}
            onClick={() => {
              setListConfig((prev) => ({
                ...prev,
                archived: null,
                page: 1
              }));
            }}
          >
            Pages
          </button>
        </li>
      </ul>
    </div>
  );
};

export default PagesTabs;
