import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const IsEmbeddedCell = ({ value: isEmbedded }) => {
  if (!isEmbedded) {
    return null;
  }

  return (
    <div className="d-inline-flex align-items-center justify-content-center w-100">
      <FaCheckCircle className="text-keppel" />
      <div className="ml-2 small">Embedded</div>
    </div>
  );
};

export default IsEmbeddedCell;
