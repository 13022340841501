import isPublished from '../../utils/isPublished';

export const classAnswersEval = ({ catalyst, challenges, answers }) => {
  if (
    !challenges ||
    (challenges && Array.isArray(challenges) && challenges.length === 0)
  ) {
    console.log('classAnswersEval: no challenges', {
      catalyst,
      challenges,
      answers
    });
    return true;
  }

  if (challenges.length === 1 && catalyst === 'challenge') {
    // *******************************************************************
    //
    // This Logic Supports our optimistic UI
    // TODO take similar approach to quizzes and create a hash to eval
    //
    // ********************************************************************
    console.log(
      'classAnswersEval: approved optimistically (only 1 challenge)',
      { catalyst, challenges, answers }
    );
    // Only 1 Challenge in this Class, Answer is approved, Class complete
    return true;
  }

  if (challenges.length && answers && answers.length) {
    // *******************************************************************
    //
    // This Logic Supports our optimistic UI
    // TODO rm variableInteger, save answer, in callback trigger approval chain
    //
    // If this chain of approval evaluation was triggered by a teacher
    // reviewing an answer to a challenge, then we add 1 to represent the
    // newly approved answer
    const variableInteger = catalyst === 'challenge' ? 1 : 0;
    //
    // *******************************************************************

    // More than one Challenge in Class
    // At least one Answer already submitted to a Challenge in this Class
    const approvedAnswers = [];

    answers.forEach((answer) => {
      // TODO answer flatten
      if (answer && answer.status === 'accepted') {
        approvedAnswers.push(answer.id);
      }
    });

    // Evaluate total approved answers w/status accepted in class
    // left side of expression can be greater than if a user had submitted an answer that was previously requestChanges and now is approved
    if (approvedAnswers.length + variableInteger >= challenges.length) {
      // Add Class to user Completed Classes
      console.log('classAnswersEval: approved', {
        catalyst,
        challenges,
        answers
      });
      return true;
    }
    // Still more Challenges that need answers and/or approvals
    console.log('classAnswersEval: more answers / approvals needed', {
      catalyst,
      challenges,
      answers
    });
    return false;
  }

  console.log('classAnswersEval: has challenge(s), no answer(s)', {
    catalyst,
    challenges,
    answers
  });
  return false;
};

export const topicAnswersEval = ({ catalyst, challenges, answers }) => {
  if (
    !challenges ||
    (challenges && Array.isArray(challenges) && challenges.length === 0)
  ) {
    console.log('topicAnswersEval: no challenges', {
      catalyst,
      challenges,
      answers
    });
    return true;
  }

  if (challenges.length === 1 && catalyst === 'challenge') {
    // *******************************************************************
    //
    // This Logic Supports our optimistic UI
    // TODO take similar approach to quizzes and create a hash to eval
    //
    // ********************************************************************
    console.log(
      'topicAnswersEval: approved optimistically (only 1 challenge)',
      { catalyst, challenges, answers }
    );
    // Only 1 Challenge in this Topic, Answer is approved, Topic complete
    return true;
  }

  if (challenges.length && answers && answers.length) {
    // *******************************************************************
    //
    // This Logic Supports our optimistic UI
    // TODO rm variableInteger, save answer, in callback trigger approval chain
    //
    // If this chain of approval evaluation was triggered by a teacher
    // reviewing an answer to a challenge, then we add 1 to represent the
    // newly approved answer
    const variableInteger = catalyst === 'challenge' ? 1 : 0;
    //
    // *******************************************************************

    // More than one Challenge in Topic
    // At least one Answer already submitted to a Challenge in this Topic
    const approvedAnswers = [];

    answers.forEach((answer) => {
      if (isPublished(answer)) {
        const { id: answerId, status } = answer;

        if (status === 'accepted' || status === 'requestChanges') {
          approvedAnswers.push(answerId);
        }
      }
    });

    // Evaluate total topic Challenges against number of answers w/status accepted || requestChanges
    // left side of expression can be greater than if a user had submitted an answer that was previously requestChanges and now is approved
    if (approvedAnswers.length + variableInteger >= challenges.length) {
      // Add Topic to user Completed Topics
      console.log('topicAnswersEval: approved', {
        catalyst,
        challenges,
        answers
      });
      return true;
    }
    // Still more Challenges that need answers and/or approvals
    console.log('topicAnswersEval: more answers/approvals needed', {
      catalyst,
      challenges,
      answers
    });
    return false;
  }

  console.log('topicAnswersEval: has challenge(s), no answer(s)', {
    catalyst,
    challenges,
    answers
  });
  return false;
};
