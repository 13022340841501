import React from 'react';
import { FaEllipsisH, FaTrashAlt } from 'react-icons/fa';
import { dayMdyDate, mdySimple, hrMin } from '../../../utils/date';

const Item = ({ index, value, onClickEdit, onClickDelete }) => {
  const valueReadable = hrMin(value);

  return (
    <div className="reference d-flex justify-content-between align-items-center border rounded">
      <div className="mr-1 p-2">
        <div className="d-none d-sm-block">{dayMdyDate(value)}</div>
        <div className="d-block d-sm-none">{mdySimple(value)}</div>
      </div>
      <div>
        <span className="font-weight-bold">{valueReadable}</span>
        <div className="btn-group align-items-center p-2 ml-2 border-left">
          <button
            className="btn btn-link btn-sm rounded text-ships-officer"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Manage Menu"
            aria-label="Manage Menu"
          >
            <FaEllipsisH />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <button
              className="dropdown-item"
              type="button"
              title={`Edit ${valueReadable}`}
              onClick={() => {
                onClickEdit(index);
              }}
            >
              Edit
            </button>
            <div className="dropdown-divider" />
            <button
              className="dropdown-item"
              type="button"
              title={`Delete ${valueReadable}?`}
              onClick={() => {
                onClickDelete(index);
              }}
            >
              <span className="d-flex align-items-center">
                <FaTrashAlt className="text-danger" />
                <span className="ml-2">Delete</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
