import { toast } from 'react-toastify';
import { isLocalhost } from '../serviceWorker';
import {
  getClient,
  getClientManagement,
  CSPACE,
  flattenItems
} from '../services/contentful';
import { getLocations } from './Locations';
import {
  SET_ORG,
  SET_SYNDICATED_COURSE_ORGS,
  RESET_SYNDICATED_COURSE_ORGS,
  RESET_ORG
} from './types';
import { fetchOrg } from '../services/organizations';

export const updateIntegrationStripeData = (customerId) => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const { id: orgId } = organization;

    return new Promise((resolve, reject) => {
      getClientManagement()
        .getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(orgId))
        .then((entry) => {
          if (customerId) {
            // Has integration
            if (!entry.fields.integration) {
              entry.fields.integration = {
                'en-US': {}
              };
            }

            if (!entry.fields.integration['en-US'].stripe) {
              // Does NOT have a stripe
              entry.fields.integration['en-US'].stripe = {};
            }

            if (!entry.fields.integration['en-US'].stripe.customer_id) {
              entry.fields.integration['en-US'].stripe.customer_id = {};
            }

            if (
              !entry.fields.integration['en-US'].stripe.customer_id[
                isLocalhost ? 'test' : 'live'
              ]
            ) {
              entry.fields.integration['en-US'].stripe.customer_id[
                isLocalhost ? 'test' : 'live'
              ] = customerId;
            }
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          setTimeout(() => {
            dispatch(getOrg({ orgId }));

            resolve(entry);
          }, 250);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const getOrg = ({ orgId, include = 3 }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        const orgFailedError = 'An id is required to get an Organization';

        toast.error(orgFailedError);
        reject(new Error(orgFailedError));
      }

      // SET LOCATIONS by ORG ID
      dispatch(getLocations({ orgId }))
        .then(() => {
          // FETCH ORG
          fetchOrg({ orgId, include })
            .then((organization) => {
              const userGroupIds = organization?.userGroupIds || null;
              const courseGroupIds = organization?.courseGroupIds || null;
              const dashboardWidgetIds =
                organization?.dashboardWidgetIds || null;
              const cdnFiles = organization?.integration?.cdnFiles || null;
              const data = { organization };

              if (userGroupIds) data.userGroupIds = userGroupIds; // TODO refactor & remove from ORG state?
              if (courseGroupIds) data.courseGroupIds = courseGroupIds; // TODO refactor & remove from ORG state?
              if (dashboardWidgetIds)
                data.dashboardWidgetIds = dashboardWidgetIds; // TODO refactor & remove from ORG state?
              if (cdnFiles) data.cdnFiles = cdnFiles;

              dispatch({ type: SET_ORG, ...data });
              resolve(data);
            })
            .catch((error) => {
              toast.error(error.message);
              reject(error);
            });
        })
        .catch((error) => {
          toast.error(error.message);
          reject(error);
        });
    });
  };
};

export const getSyndicatedCourseOrgs = ({
  orgId,
  orgIds,
  include,
  select = ['fields.name']
}) => {
  // Prevent requesting the current user's Org if the current
  // Org's Id is present in the Course's orgIds.
  const sanitizedOrgIds = orgIds.filter((o) => o !== orgId);

  const config = {
    content_type: 'organization',
    include,
    'sys.id[in]': orgIds.join(','),
    select: select.join(',')
  };

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!sanitizedOrgIds.length) {
        dispatch({
          type: SET_SYNDICATED_COURSE_ORGS,
          list: null
        });

        resolve(null);

        return null;
      }

      getClient()
        .getEntries(config)
        .then(({ items }) => {
          const list = items.length ? flattenItems(items) : null;

          dispatch({
            type: SET_SYNDICATED_COURSE_ORGS,
            list
          });

          resolve(list);
        })
        .catch((error) => {
          console.error(error.message);
          reject(error);
        });
    });
  };
};

export const resetOrg = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ORG });
  };
};

export const resetSyndicatedCourseOrgs = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SYNDICATED_COURSE_ORGS });
  };
};
