import React, { useMemo } from 'react';
import Table from './Table';
import NameCell from './NameCell';
import ApprenticeshipTitleCell from './ApprenticeshipTitleCell';
import RiskRatingCell from './RiskRatingCell';

const List = ({ data, onClickName, orgType }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: NameCell,
        onClickName,
        orgType
      },
      {
        Header: 'Risk Rating',
        accessor: 'riskRatingRatio',
        Cell: RiskRatingCell
      },
      {
        Header: 'Hrs. Needed',
        accessor: 'hoursNeeded'
      },
      {
        Header: 'Hrs. Remaining',
        accessor: 'hoursRemaining'
      },
      {
        Header: 'Training Program',
        accessor: 'apprenticeshipTitle',
        Cell: ApprenticeshipTitleCell
      },
      {
        Header: 'OJT Hrs.',
        accessor: 'ojtHours'
      }
    ],
    [onClickName, orgType]
  );

  const hiddenColumns = useMemo(() => {
    const cols = [];

    return cols;
  }, []);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Users.</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
