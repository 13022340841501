import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import {
  createFirebaseEntry,
  deleteFirebaseEntry,
  getFirebaseEntriesNew,
  updateFirebaseEntry
} from './firebaseProxy';

const collection = 'apprenticeAgreements';

/**
 * @param {Array} ids
 */
export const fetchApprenticeAgreements = ({
  ids = [],
  userId,
  userProfileId,
  apprenticeshipId
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    // Training Program: Workforce
    // if (locationId) {
    //   config['f.locationId[eq]'] = locationId;
    // }

    if (userId) {
      config['f.userId[eq]'] = userId;
    }

    if (userProfileId) {
      config['f.userProfileId[eq]'] = userProfileId;
    }

    if (apprenticeshipId) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew({
      collection,
      ...config
    })
      .then(({ items }) => {
        const list = items.length ? items : null;

        resolve(list);
      })
      .catch((error) => {
        console.error(`fetch ${collection}`, error);
        reject(error);
      });
  });
};

export const createApprenticeAgreement = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection })
      .then((response) => {
        const docId = pathOr(null, ['docId'], response);

        resolve(docId);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${collection}`, error);
      });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const updateApprenticeAgreement = (data, id) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${collection}`, error);
      });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const deleteApprenticeAgreement = (id) => {
  return new Promise((resolve) => {
    deleteFirebaseEntry({
      collection,
      id
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`delete ${collection}`, error);
      });
  });
};

export const findApprenticeAgreement = ({
  list = [],
  userProfileId,
  apprenticeshipId
}) => {
  let employerAgreement;

  if (list && Array.isArray(list) && list.length) {
    employerAgreement = list.find(
      (eA) =>
        eA?.userProfileId === userProfileId &&
        eA.apprenticeshipId === apprenticeshipId
    );
  }

  return employerAgreement;
};
