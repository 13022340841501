import React, { useMemo } from 'react';
import { FaImage, FaThumbtack } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { constructWidgetUrl } from '../../../../services/widgets';

const WidgetLink = ({
  className = '',
  id = null,
  image = null,
  title = 'Widget',
  body = null,
  height = '40',
  width = '40',
  linkTarget,
  styling = 'box',
  url,
  isFeatured = false,
  includeEmail = null,
  includeExternalUserId = null,
  onClickSettings = undefined,
  showFeaturedIcons = false
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const widgetUrl = useMemo(() => {
    return constructWidgetUrl({
      email: currentUser?.email || null,
      externalUserId: currentUser?.externalUserId || null,
      includeEmail,
      includeExternalUserId,
      url
    });
  }, [
    currentUser?.email,
    currentUser?.externalUserId,
    includeEmail,
    includeExternalUserId,
    url
  ]);

  return (
    <div className={className}>
      <div
        className={`${styling === 'box' ? 'bg-white shadow rounded p-2' : ''} d-flex align-items-center justify-content-between`}
      >
        <div className="d-flex align-items-center w-100">
          <div
            className={`border ${styling === 'basic' ? 'shadow' : ''} bg-white rounded d-block mr-2 position-relative`}
          >
            {image ? (
              <div
                className="p-2"
                style={{
                  height: `${height}px`,
                  width: `${width}px`
                }}
              >
                <img
                  className="d-block w-100 h-100"
                  src={image}
                  alt={title}
                  height={height}
                  width={width}
                />
              </div>
            ) : (
              <FaImage
                size={height}
                className="p-2"
              />
            )}
            {isFeatured && showFeaturedIcons && (
              <div className="position-absolute widget-featured-marker">
                <FaThumbtack
                  className="text-primary opacity-50"
                  size={12}
                />
              </div>
            )}
          </div>
          <div
            className={`w-100 d-flex align-items-center justify-content-between flex-auto ${url && styling === 'basic' ? 'pb-2 ' : ''}`}
            style={
              url && styling === 'basic'
                ? { borderBottom: '1px solid rgba(0, 0, 0, .1)' }
                : {}
            }
          >
            <div>
              <div className="font-weight-bold widget-listView-title">
                {title}
              </div>
              <div className="widget-listView-body">{body}</div>
            </div>
            {(widgetUrl || Boolean(onClickSettings)) && (
              <div className="d-flex flex-column">
                {widgetUrl && (
                  <a
                    className="mx-1 btn btn-sm btn-white py-1"
                    target={linkTarget}
                    rel="noopener noreferrer"
                    href={widgetUrl}
                    title={title}
                    style={{
                      minWidth: '50px',
                      borderRadius: '1rem',
                      fontSize: '.75rem'
                    }}
                  >
                    GO
                  </a>
                )}

                {Boolean(onClickSettings) && (
                  <button
                    className="btn-link mt-1"
                    type="button"
                    onClick={() => {
                      onClickSettings({
                        id,
                        image,
                        title,
                        body,
                        linkTarget,
                        isFeatured,
                        url,
                        includeEmail,
                        includeExternalUserId
                      });
                    }}
                  >
                    Settings
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetLink;
