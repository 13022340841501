// import exportFromJSON from 'export-from-json';
import { utils, writeFile } from 'xlsx';
import createApprenticeData from './createApprenticeData';

const fixWidth = (worksheet) => {
  const data = utils.sheet_to_json(worksheet);
  const colLengths = Object.keys(data[0]).map((k) => k.toString().length);

  data.forEach((d) => {
    Object.values(d).forEach((element, index) => {
      const length = element != null ? element.toString().length : 0; // Handle null values

      if (colLengths[index] < length) {
        colLengths[index] = length;
      }
    });
  });

  worksheet['!cols'] = colLengths.map((l) => ({ wch: l }));
};

export const generateFileName = ({ apprenticeship }) => {
  let programName = 'Program';

  if (apprenticeship?.occupationName) {
    programName = apprenticeship.occupationName;
  }

  return `RAPIDS Apprentice Upload for ${programName}`;
};

const exportData = async ({ orgId, users, apprenticeship, locationsHash }) => {
  const fileName = generateFileName({ apprenticeship });
  const wb = utils.book_new();

  /**
   * Apprentice Data
   */
  const apprenticeData = await createApprenticeData({
    orgId,
    users,
    apprenticeship,
    locationsHash
  });
  const wsApprenticeData = utils.json_to_sheet(apprenticeData);
  fixWidth(wsApprenticeData);
  utils.book_append_sheet(wb, wsApprenticeData, 'Apprentice Data');
  /**
   * Employer
   */
  /**
   * Occupations
   */
  /**
   * RTI Providers
   */
  /**
   * Wage Schedules
   */

  writeFile(wb, `${fileName}.xlsx`);
};

export default exportData;
