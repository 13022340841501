import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CompetenceProgress from '../CompetenceProgress';

const DescriptionCell = ({ value: description, row, column }) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbookHours = useSelector((state) => state.logbookHours);
  // Apprenticeship User
  const userCompetenciesHash = apprenticeship?.userCompetenciesHash || null;

  const competencyId = row?.original?.id || null;
  const totalHours = row?.original?.totalHours || null;

  const userTotalHours = userCompetenciesHash
    ? userCompetenciesHash[row?.original?.id]?.totalHours
    : undefined;

  const hoursEarned = useMemo(() => {
    if (logbookHours?.competencyHours) {
      if (logbookHours?.competencyHours[competencyId] !== undefined) {
        const hours = logbookHours?.competencyHours[competencyId];
        const strValue = `${hours}`;

        return strValue.includes('.') ? hours.toFixed(2) : hours;
      }
    }

    return 0;
  }, [competencyId, logbookHours?.competencyHours]);

  return (
    <div>
      <div>
        {userTotalHours !== undefined && userTotalHours === 0 ? (
          <s>{description}</s>
        ) : (
          <span>{description}</span>
        )}
      </div>

      <CompetenceProgress
        onClickTargetHours={column?.onClickTargetHours}
        competencyId={competencyId}
        userTotalHours={userTotalHours}
        totalHours={totalHours}
        hoursEarned={hoursEarned}
      />
    </div>
  );
};

export default DescriptionCell;
