import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';

const KnowledgeGapCell = ({ row }) => {
  const resourceIds = row?.original?.resourceIds;

  const hasResources = Array.isArray(resourceIds) && resourceIds.length > 0;

  if (!hasResources) {
    return (
      <div className="d-flex align-items-center justify-content-start">
        <FaTriangleExclamation className="text-danger" />
        <span className="ml-1">Missing Resources</span>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-start">
      <FaCheckCircle className="text-keppel" />
    </div>
  );
};

export default KnowledgeGapCell;
