import { findObjectByIndex } from '@apprentage/utils';

function findObjectIndexByKey(objArray, key, value) {
  if (key.includes('.')) {
    // Ex: 'sys.id'
    const keys = key.split('.');
    return objArray.findIndex(
      (obj) => keys.reduce(findObjectByIndex, obj) === value
    );
  }

  return objArray.findIndex((obj) => obj[key] === value);
}

export default findObjectIndexByKey;
