import {
  RESET_CURRENT_CLASS_QUIZZES,
  SET_CURRENT_CLASS_QUIZZES
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function currentClassQuizzes(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLASS_QUIZZES:
      return {
        ...state,
        list: action.currentClassQuizzes,
        fetched: true
      };
    case RESET_CURRENT_CLASS_QUIZZES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
