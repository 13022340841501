import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { getJobProfiles } from '../../actions/JobProfiles';
import { resetCurrentModal } from '../../actions/Modals';
import { resetSideSheet } from '../../actions/SideSheets';
import * as ROUTES from '../../constants/routes';
import heapAnalytics from '../../utils/heapAnalytics';
// Global Components
import TopNav from '../TopNav';
import Login from '../auth/Login';
import SSO from '../auth/SSO';
import Assessment from '../Assessment';
import SearchContent from '../SearchContent';
import Account from '../Account';
import Billing from '../Billing';
import BillingNewSubscription from '../Billing/NewSubscription';
import AccountIntegrations from '../Account/Integrations';
// Org Console Components
import Organization from '../Organization'; // Settings
import OrganizationDefaultLocation from '../Organization/DefaultLocation';
import OrgConsoleDashboard from '../OrgConsole/Dashboard';
import OrgConsoleNotifications from '../OrgConsole/Notifications';
import OrgConsoleWidgets from '../../routes/Widgets';
import OrgConsoleLocations from '../OrgConsole/Locations';
import OrgConsoleLocationManage from '../OrgConsole/Location';
import OrgConsoleInstructionProviders from '../OrgConsole/InstructionProviders';
import OrgConsoleInstructionProvider from '../OrgConsole/InstructionProvider';
import OrgConsoleLocation from '../OrgConsole/LocationView';
import OrgConsoleChallenges from '../OrgConsole/Challenges';
import OrgConsoleProject from '../../routes/Project';
import ConsoleSkills from '../OrgConsole/Skills';
import ConsoleResources from '../OrgConsole/Resources';
import ConsoleResourcesTags from '../OrgConsole/Resources/Tags';
import ConsoleCourses from '../OrgConsole/Courses';
import ConsoleCoursesGroups from '../OrgConsole/Courses/Groups';
import ConsoleCoursesSyndication from '../OrgConsole/Courses/Syndication';
import ConsoleCoursesCatalog from '../OrgConsole/Courses/Catalog';
import ConsoleCoursesGroupsReorder from '../OrgConsole/Courses/ReorderGroups';
import ConsoleGroupCourses from '../OrgConsole/Courses/Group';
import ConsoleGroupCoursesReorder from '../OrgConsole/Courses/ReorderCourses';
import ConsoleGroupCoursesManage from '../OrgConsole/Courses/ManageCourses';
import ConsolePathways from '../OrgConsole/Pathways';
import ConsolePathway from '../OrgConsole/Pathway';
import ConsoleLogbooks from '../OrgConsole/Logbooks';
import ConsoleLogbook from '../OrgConsole/Logbook';
// Pages
import Pages from '../../routes/Pages';
import Page from '../../routes/Page';
import PageAnalytics from '../../routes/Page/Analytics';
import PageAppearance from '../../routes/Page/Appearance';
import PageSettings from '../../routes/Page/Settings';
import PageContent from '../../routes/Page/Content';
// Assistants
import Assistants from '../../routes/Assistants';
import Assistant from '../../routes/Assistant';
import AssistantManage from '../../routes/AssistantManage';
// Projects
import ConsoleProjects from '../OrgConsole/Projects';
import ConsoleProject from '../OrgConsole/Project';
// Pathways
import ConsolePathwayCerts from '../OrgConsole/Pathway/Certificates';
import ConsolePathwayPublish from '../OrgConsole/Pathway/Publish';
import ConsolePathwayJobProfile from '../OrgConsole/Pathway/JobProfile';
import ConsoleJobProfiles from '../OrgConsole/JobProfiles';
// Users
import ConsoleUsers from '../OrgConsole/Users';
import ConsoleUsersGroups from '../OrgConsole/Users/Groups';
import ConsoleUsersGroupsReorder from '../OrgConsole/Users/ReorderGroups';
// Apprentage
import OrgConsoleApprenticeshipEmployer from '../OrgConsole/WorkforceSuite/Apprenticeship/Employer';
import OrgConsoleApprenticeshipEmployerDocs from '../OrgConsole/WorkforceSuite/Apprenticeship/Employer/Documents';
import OrgConsoleApprenticeshipEmployerWages from '../OrgConsole/WorkforceSuite/Apprenticeship/Employer/WageSchedules';
import OrgConsoleApprenticeshipEmployers from '../OrgConsole/WorkforceSuite/Apprenticeship/Employers';
import OrgConsoleApprenticeshipCompetencies from '../OrgConsole/WorkforceSuite/Apprenticeship/Competencies';
import OrgConsoleApprenticeshipInstructionProviders from '../OrgConsole/WorkforceSuite/Apprenticeship/InstructionProviders';
import OrgConsoleApprenticeshipCourses from '../OrgConsole/WorkforceSuite/Apprenticeship/Courses';
import OrgConsoleApprenticeshipUser from '../OrgConsole/WorkforceSuite/Apprenticeship/User';
import OrgConsoleApprenticeshipUserDocs from '../OrgConsole/WorkforceSuite/Apprenticeship/User/Documents';
import OrgConsoleApprenticeshipUserLogbook from '../OrgConsole/WorkforceSuite/Apprenticeship/User/Logbook';
import OrgConsoleApprenticeshipUserRecords from '../OrgConsole/WorkforceSuite/Apprenticeship/User/Records';
import OrgConsoleApprenticeshipUserWageSchedule from '../OrgConsole/WorkforceSuite/Apprenticeship/User/WageSchedule';
import OrgConsoleApprenticeshipUsers from '../OrgConsole/WorkforceSuite/Apprenticeship/Users';
import ApprenticeshipUsersTrainers from '../OrgConsole/WorkforceSuite/Apprenticeship/Users/Trainers';
import ApprenticeshipUsersInvitations from '../OrgConsole/WorkforceSuite/Apprenticeship/Users/Invitations';
import OrgConsoleApprenticeship from '../OrgConsole/WorkforceSuite/Apprenticeship';
import OrgConsoleApprenticeshipManage from '../OrgConsole/WorkforceSuite/Apprenticeship/Manage';
import OrgConsoleApprenticeships from '../OrgConsole/WorkforceSuite/Apprenticeships';
import OrgConsoleWorkforce from '../OrgConsole/WorkforceSuite';
// Course Console Components
import ConsoleDashboard from '../Console/Dashboard';
import ConsoleSettings from '../Console/Settings';
import ConsoleCoursePacing from '../Console/Pacing';
import ConsoleCoursePublish from '../Console/Publish';
import ConsoleBranding from '../Console/Branding';
import ConsoleStreaming from '../Console/Streaming';
import ConsoleCourseUsers from '../Console/Users';
import ConsoleNotifications from '../Console/Notifications';
import ConsoleQuiz from '../Console/Quiz';
import ConsoleQuizzes from '../Console/Quizzes';
import ConsoleChallengesSubmissions from '../Console/Challenges/Submissions';
import ConsoleChallengeSubmissions from '../Console/Challenge/Submissions';
import ConsoleChallenges from '../Console/Challenges';
import ConsoleQuizOutcome from '../Console/QuizOutcome';
import ConsoleQuizOutcomes from '../Console/QuizOutcomes';
import ConsoleCohorts from '../Console/Cohorts';
import ConsoleUserRoles from '../Console/UserRoles';
import ConsoleCeuAttendance from '../Console/ContinuingEducation/Attendance';
import ConsoleCeuTracking from '../Console/ContinuingEducation/Tracking';
import ConsoleCeuSettings from '../Console/ContinuingEducation/Settings';
import ContextRoute from '../../routes/ContextRoute';
import ContextDashboard from '../../routes/ContextRoute/Dashboard';
import ContextSearch from '../../routes/ContextRoute/Search';
import ContextSettings from '../../routes/ContextRoute/Settings';
import Certificate from '../Certificate';
import ManageCurrentEntry from '../ManageCurrentEntry';
import Modals from '../Modals';
import Slash from '../Slash';
import SideSheets from '../SideSheets';
import WidgetReorder from '../../routes/Widgets/WidgetReorder';
import DashboardConfig from '../../routes/DashboardConfig';
import SearchesUser from '../../routes/SearchesUser';
import SearchesAll from '../../routes/SearchesAll';
// ONBOARDING
import Onboarding from '../../routes/Onboarding';
import OnboardingWelcome from '../../routes/Onboarding/Welcome';
import OnboardingTeamContext from '../../routes/Onboarding/TeamContext';
import OnboardingWorkforceSpace from '../../routes/Onboarding/WorkforceSpace';
import OrgContext from '../../routes/Onboarding/OrgContext';

const TurbineAdmin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    heapAnalytics.initialize();
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data) {
        try {
          const messageData = JSON.parse(e.data);

          if (messageData.action === 'jobProfileCreate') {
            console.log('jobProfileCreate', messageData);
            toast.success('Job Profile Created!');
            dispatch(getJobProfiles({ orgId: messageData.orgId }));
          }
        } catch (error) {
          // nothing
        }
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const handlePopState = () => {
      dispatch(resetCurrentModal());
      dispatch(resetSideSheet());
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [dispatch]);

  return (
    <Router>
      <>
        <TopNav />
        {/* <div className="container-fluid px-0 px-sm-3"> */}

        <Switch>
          <Route
            exact
            path={ROUTES.SSO}
            component={SSO}
          />
          <Route
            exact
            path={ROUTES.LOGIN_ORG}
            component={Login}
          />
          <Route
            path={ROUTES.LOGIN}
            component={Login}
          />

          {/* ONBOARDING */}
          <Route
            path={ROUTES.ONBOARDING_WELCOME}
            component={OnboardingWelcome}
          />
          <Route
            path={ROUTES.ONBOARDING_TEAM_CONTEXT}
            component={OnboardingTeamContext}
          />
          <Route
            path={ROUTES.ONBOARDING_WORKFORCE_SPACE}
            component={OnboardingWorkforceSpace}
          />
          <Route
            path={ROUTES.ONBOARDING_ORG_CONTEXT}
            component={OrgContext}
          />
          <Route
            path={ROUTES.ONBOARDING}
            component={Onboarding}
          />
          {/* /ONBOARDING */}

          <Route
            path={ROUTES.ACCOUNT_INTEGRATIONS}
            component={AccountIntegrations}
          />
          <Route
            exact
            path={ROUTES.ACCOUNT}
            component={Account}
          />
          <Route
            exact
            path={ROUTES.BILLING}
            component={Billing}
          />
          <Route
            exact
            path={ROUTES.BILLING_NEW_SUBSCRIPTION}
            component={BillingNewSubscription}
          />
          <Route
            path={ROUTES.ASSESSMENT}
            component={Assessment}
          />
          <Route
            exact
            path={ROUTES.SEARCHES_USER}
            component={SearchesUser}
          />
          <Route
            exact
            path={ROUTES.SEARCHES_ALL}
            component={SearchesAll}
          />
          <Route
            path={ROUTES.SEARCH_CONTENT}
            component={SearchContent}
          />
          {/* Organization Console Routes */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_SETTINGS_LOCATION}
            component={OrganizationDefaultLocation}
          />
          <Route
            path={ROUTES.ORG_MANAGE}
            component={Organization}
          />
          <Route
            path={ROUTES.ORG_CONSOLE_DASHBOARD}
            component={OrgConsoleDashboard}
          />
          <Route
            path={ROUTES.ORG_CONSOLE_CHALLENGES_USER}
            component={OrgConsoleChallenges}
          />
          <Route
            path={ROUTES.ORG_CONSOLE_CHALLENGES}
            component={OrgConsoleChallenges}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_NOTIFICATIONS}
            component={OrgConsoleNotifications}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WIDGETS}
            component={OrgConsoleWidgets}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WIDGETS_REORDER}
            component={WidgetReorder}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_LOCATION_MANAGE}
            component={OrgConsoleLocationManage}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_LOCATION}
            component={OrgConsoleLocation}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_LOCATIONS}
            component={OrgConsoleLocations}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_SKILLS}
            component={ConsoleSkills}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_RESOURCES}
            component={ConsoleResources}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_RESOURCES_TAGS}
            component={ConsoleResourcesTags}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES}
            component={ConsoleCourses}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_GROUP_REORDER}
            component={ConsoleGroupCoursesReorder}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_GROUP_MANAGE}
            component={ConsoleGroupCoursesManage}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_GROUP}
            component={ConsoleGroupCourses}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_GROUPS}
            component={ConsoleCoursesGroups}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_SYNDICATION}
            component={ConsoleCoursesSyndication}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_CATALOG}
            component={ConsoleCoursesCatalog}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_COURSES_GROUPS_REORDER}
            component={ConsoleCoursesGroupsReorder}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_JOB_PROFILES}
            component={ConsoleJobProfiles}
          />
          {/* Pathways */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PATHWAY_JOB_PROFILE}
            component={ConsolePathwayJobProfile}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PATHWAY_CERTS}
            component={ConsolePathwayCerts}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PATHWAY_PUBLISH}
            component={ConsolePathwayPublish}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PATHWAY}
            component={ConsolePathway}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PATHWAYS}
            component={ConsolePathways}
          />
          {/* Assistants */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_ASSISTANTS}
            component={Assistants}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_ASSISTANT_MANAGE}
            component={AssistantManage}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_ASSISTANT}
            component={Assistant}
          />
          {/* Context */}
          <Route
            exact
            path={ROUTES.CONTEXT_DASHBOARD}
            component={ContextDashboard}
          />
          <Route
            exact
            path={ROUTES.CONTEXT_SEARCH}
            component={ContextSearch}
          />
          <Route
            exact
            path={ROUTES.CONTEXT_SETTINGS}
            component={ContextSettings}
          />
          <Route
            exact
            path={ROUTES.CONTEXT_ROUTE}
            component={ContextRoute}
          />
          {/* Pages */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PAGES}
            component={Pages}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PAGE_APPEARANCE}
            component={PageAppearance}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PAGE_ANALYTICS}
            component={PageAnalytics}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PAGE_SETTINGS}
            component={PageSettings}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PAGE_CONTENT}
            component={PageContent}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PAGE}
            component={Page}
          />
          {/* Projects */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PROJECTS}
            component={ConsoleProjects}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PROJECT_MANAGE}
            component={ConsoleProject}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_PROJECT}
            component={OrgConsoleProject}
          />
          {/* Logbooks */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_LOGBOOK}
            component={ConsoleLogbook}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_LOGBOOKS}
            component={ConsoleLogbooks}
          />
          {/* Instruction Providers */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_INSTRUCTION_PROVIDER}
            component={OrgConsoleInstructionProvider}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_INSTRUCTION_PROVIDERS}
            component={OrgConsoleInstructionProviders}
          />
          {/* Users */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_USER}
            component={ConsoleUsers}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_USERS}
            component={ConsoleUsers}
          />
          <Route
            path={ROUTES.ORG_CONSOLE_USERS_GROUPS_REORDER}
            component={ConsoleUsersGroupsReorder}
          />
          <Route
            path={ROUTES.ORG_CONSOLE_USERS_GROUPS}
            component={ConsoleUsersGroups}
          />
          {/* Apprentage */}
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_DOCS}
            component={OrgConsoleApprenticeshipUserDocs}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYER_DOCS}
            component={OrgConsoleApprenticeshipEmployerDocs}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYER_WAGES}
            component={OrgConsoleApprenticeshipEmployerWages}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYER}
            component={OrgConsoleApprenticeshipEmployer}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYERS}
            component={OrgConsoleApprenticeshipEmployers}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_COMPETENCIES}
            component={OrgConsoleApprenticeshipCompetencies}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_COURSES}
            component={OrgConsoleApprenticeshipCourses}
          />
          <Route
            exact
            path={
              ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_INSTRUCTION_PROVIDERS
            }
            component={OrgConsoleApprenticeshipInstructionProviders}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_LOGBOOK}
            component={OrgConsoleApprenticeshipUserLogbook}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_RECORDS}
            component={OrgConsoleApprenticeshipUserRecords}
          />
          <Route
            exact
            path={
              ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_WAGE_SCHEDULE
            }
            component={OrgConsoleApprenticeshipUserWageSchedule}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER}
            component={OrgConsoleApprenticeshipUser}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS}
            component={OrgConsoleApprenticeshipUsers}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_TRAINERS}
            component={ApprenticeshipUsersTrainers}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_INVITES}
            component={ApprenticeshipUsersInvitations}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_MANAGE}
            component={OrgConsoleApprenticeshipManage}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}
            component={OrgConsoleApprenticeship}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            component={OrgConsoleApprenticeships}
          />
          <Route
            exact
            path={ROUTES.ORG_CONSOLE_WORKFORCE}
            component={OrgConsoleWorkforce}
          />
          {/* Course Console Routes */}
          <Route
            path={ROUTES.MANAGE_CURRENT_ENTRY}
            component={ManageCurrentEntry}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_DASHBOARD}
            component={ConsoleDashboard}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_SETTINGS}
            component={ConsoleSettings}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_PACING}
            component={ConsoleCoursePacing}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_PUBLISH}
            component={ConsoleCoursePublish}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_BRANDING}
            component={ConsoleBranding}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_STREAMING}
            component={ConsoleStreaming}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_NOTIFICATIONS}
            component={ConsoleNotifications}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_QUIZ_OUTCOME}
            component={ConsoleQuizOutcome}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_QUIZ_USER_OUTCOMES}
            component={ConsoleQuizOutcomes}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_QUIZ_OUTCOMES}
            component={ConsoleQuizOutcomes}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_QUIZ}
            component={ConsoleQuiz}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_QUIZZES}
            component={ConsoleQuizzes}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_CHALLENGE_SUBMISSIONS_USER}
            component={ConsoleChallengeSubmissions}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_CHALLENGE_SUBMISSIONS}
            component={ConsoleChallengeSubmissions}
          />
          <Route
            exact
            path={ROUTES.CLASS_CONSOLE_CHALLENGES_SUBMISSIONS_USER}
            component={ConsoleChallengesSubmissions}
          />
          <Route
            exact
            path={ROUTES.CLASS_CONSOLE_CHALLENGES_SUBMISSIONS}
            component={ConsoleChallengesSubmissions}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_CHALLENGES}
            component={ConsoleChallenges}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_COHORTS}
            component={ConsoleCohorts}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_USER_ROLES}
            component={ConsoleUserRoles}
          />
          <Route
            path={ROUTES.CLASS_CONSOLE_USERS}
            component={ConsoleCourseUsers}
          />
          <Route
            path={ROUTES.CONSOLE_CE_ATTENDANCE}
            component={ConsoleCeuAttendance}
          />
          <Route
            path={ROUTES.CONSOLE_CE_TRACKING}
            component={ConsoleCeuTracking}
          />
          <Route
            path={ROUTES.CONSOLE_CE_SETTINGS}
            component={ConsoleCeuSettings}
          />
          {/* Misc. Routes */}
          <Route
            path={ROUTES.CERTIFICATE_CLASS}
            component={Certificate}
          />
          <Route
            exact
            path={ROUTES.DASHBOARD_CONFIGURE}
            component={DashboardConfig}
          />
          <Route
            exact
            path={ROUTES.SLASH}
            component={Slash}
          />
        </Switch>
        <SideSheets />
        <Modals />
        <ToastContainer
          className="text-center"
          position="top-center"
          autoClose={3000}
          style={{ minWidth: '320px' }}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          // pauseOnFocusLoss
          // draggable
          // pauseOnHover
        />
      </>
    </Router>
  );
};

export default TurbineAdmin;
