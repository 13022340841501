import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaLayerGroup, FaPlus } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import {
  IMAGE_COURSES_INTERNAL,
  IMAGE_RESOURCES_ALT
} from '../../../constants/assets';
import { fetchCourses } from '../../../services/courses';
import { setCurrentModal } from '../../../actions/Modals';
import { MODAL_KEY_CREATE_COURSE } from '../../../constants/modals';
import Loading from '../../Loading';
import DashboardKnowledgeCapture from './DashboardKnowledgeCapture';
import DashboardCourses from './DashboardCourses';
import DashboardKnowledgeManagement from './DashboardKnowledgeManagement';
import DashboardDocs from './DashboardDocs';
import MyMaterials from '../../Dashboard/MyMaterials';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import NextActions from './NextActions';
import CoursesList from '../../CoursesList';
import './style.css';

const OrgDashboard = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const materials = useSelector((state) => state.materials);
  // Current User
  const userId = currentUser?.id || null;
  // Organization
  const orgId = organization?.id || null;

  const [coursesList, setCoursesList] = useState(null);

  const initialListConfig = {
    orgId,
    userId,
    limit: 5,
    page: 1,
    isDraft: true,
    // workflowType: null,
    order: '-createdAt'
  };
  const [listConfig, setListConfig] = useState(initialListConfig);

  useEffect(() => {
    document.body.classList.add('bkg-pattern-light');

    return function cleanup() {
      document.body.classList.remove('bkg-pattern-light');
    };
  }, []);

  useEffect(() => {
    fetchCourses({ orgId, userId, limit: 4 }).then((response) => {
      setCoursesList(response);
    });
  }, [orgId, userId]);

  if (!currentUser?.id || !organization?.id) {
    return <Loading />;
  }

  return (
    <>
      <div className="col-12 col-md-11 col-xl-9 m-auto">
        <NextActions
          className="mt-3"
          badgeText="beta"
          badgeClassName="text-muted border"
        />

        <DashboardKnowledgeManagement
          resources
          className="mt-3"
        />

        <div className="row">
          <div className="col-12 col-sm-6 mb-4">
            <MyMaterials
              title="My Resources"
              listConfig={listConfig}
              className="shadow position-relative"
              creatable
              cardStyle
            >
              {Array.isArray(materials?.list) && materials?.list.length > 0 ? (
                <PaginationSupabase
                  items={materials?.list}
                  pagination={materials?.pagination}
                  page={materials?.pagination?.page}
                  onClickNext={() => {
                    setListConfig((prev) => ({
                      ...prev,
                      page: materials?.pagination?.page + 1
                    }));
                    // goToElem('root');
                  }}
                  onClickPrev={() => {
                    setListConfig((prev) => ({
                      ...prev,
                      page: materials?.pagination?.page - 1
                    }));
                    // goToElem('root');
                  }}
                />
              ) : (
                <div className="d-flex flex-column justify-content-center text-center mb-3">
                  <img
                    src={IMAGE_RESOURCES_ALT}
                    alt="Resources"
                    className="my-2 mx-auto"
                    style={{ width: '75%' }}
                  />
                  <div>No Resource Materials.</div>
                </div>
              )}
            </MyMaterials>
          </div>

          <div className="col-12 col-sm-6 mb-4">
            {/* <DashboardCards
                title="Resources"
                onClickRoutePath={ORG_CONSOLE_RESOURCES}
                subTitle="Store team files, training materials, marketing assets and much more."
                imageUrl={IMAGE_CARD_RESOURCES}
              /> */}
            <div className="card h-100 shadow">
              <div
                className="card-header pl-3 h5 mb-0 d-flex align-items-center justify-content-between"
                style={{
                  height: '52px'
                }}
              >
                <div className="d-flex align-items-center">
                  <FaLayerGroup className="mr-2" />
                  <div>My Courses</div>
                </div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    // setEnableCourseContent(true);
                    dispatch(
                      setCurrentModal({
                        key: MODAL_KEY_CREATE_COURSE
                        // data: {
                        //   enableCourseContent: true,
                        //   currentStep: 1
                        // }
                      })
                    );
                  }}
                  type="button"
                >
                  <span className="d-flex align-items-center">
                    <FaPlus />
                    <span className="ml-1">New</span>
                  </span>
                </button>
              </div>
              <div className="card-body p-0 overflow-y-scroll">
                {!coursesList ? (
                  <div className="d-flex flex-column justify-content-center text-center mb-4">
                    <img
                      src={IMAGE_COURSES_INTERNAL}
                      alt="Courses Internal"
                      className="my-2 mx-auto"
                      style={{ width: '75%' }}
                    />
                    <div>No Courses.</div>
                  </div>
                ) : (
                  <CoursesList
                    list={coursesList}
                    showGroupsCol
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <DashboardKnowledgeCapture className="mt-2" />

        <DashboardCourses
          title="Courses"
          className="mt-2"
        />

        <DashboardDocs />
      </div>
      <ReactTooltip
        html
        id="OrgDashboard"
      />
    </>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(OrgDashboard);
