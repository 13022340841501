import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import WidgetLink from '../../../lists/WidgetsList/ListView/WidgetLink';

const StylingCard = ({ title = 'Option', selectedValue, value, onClick }) => {
  return (
    <div className={`card border ${value === selectedValue ? 'shadow' : ''}`}>
      <div className="card-header bg-dark text-white">
        <h6 className="m-0">{title}</h6>
      </div>
      <div
        className="card-body h-100 bkg-pattern-light pointer-events-none"
        style={{
          minHeight: '110px'
        }}
      >
        <WidgetLink
          className="pt-2 w-100"
          title="Link title"
          body="Link Subtitle"
          styling={value}
          url="#"
        />
      </div>
      <div
        className="card-footer d-flex justify-content-center align-items-center"
        style={{
          minHeight: '56px'
        }}
      >
        {value === selectedValue ? (
          <FaCheckCircle
            size={18}
            className="text-success"
          />
        ) : (
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => {
              onClick(value);
            }}
            type="button"
          >
            Select
          </button>
        )}
      </div>
    </div>
  );
};

export default StylingCard;
