import React from 'react';

const ActiveCell = ({ value: activeChallenge }) => {
  return (
    // row
    <div className="d-flex align-items-center">
      <span className="ml-1 h6 m-0">
        <span
          className={`ml-1 badge badge-${activeChallenge ? 'success' : 'danger'}`}
        >
          {activeChallenge ? 'Active' : 'Inactive'}
        </span>
      </span>
    </div>
  );
};

export default ActiveCell;
