import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { canAccessSkills, canCreateSkills } from '@apprentage/utils';
import { setCurrentModal } from '../../../actions/Modals';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_DASHBOARD, SLASH } from '../../../constants/routes';
import { fetchSkill } from '../../../services/skills';
import { DEFAULT_PAGINATION_LIMIT } from '../../../constants/api';
import { goToElem } from '../../../services/manageContent';
import { ICON_SKILLS } from '../../../constants/assets';
import withOrgConsole from '../../App/withOrgConsole';
import Loading from '../../Loading';
import SkillsList from './List';
import OrgConsoleHeader from '../OrgConsoleHeader';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';

const Skills = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const skills = useSelector((state) => state.skills);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Pagination
  const initialListConfig = {
    orgId,
    page: 1,
    limit: DEFAULT_PAGINATION_LIMIT,
    name: ''
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  const addSkill = () => {
    dispatch(
      setCurrentModal({
        key: 'manageSkill',
        data: {
          editMode: false,
          name: '',
          difficulty: 1,
          orgId
        }
      })
    );
  };

  const editSkill = (id) => {
    fetchSkill(id).then((skill) => {
      dispatch(
        setCurrentModal({
          key: 'manageSkill',
          data: {
            editMode: true,
            skill,
            id
          }
        })
      );
    });
  };

  if (!canAccessSkills(role, orgType)) {
    return <Redirect to={SLASH} />;
  }

  if (!organization.id || !currentUser.id) {
    return <Loading />;
  }

  // Domain-general and domain-specific skills.

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Skills"
            image={ICON_SKILLS}
            route={ORG_CONSOLE_DASHBOARD}
            badge={() => (
              <span className="badge bg-white border ml-2">
                {skills?.pagination?.total || 0}
              </span>
            )}
          >
            {canCreateSkills(role, orgType) && (
              <button
                className="btn btn-sm btn-primary"
                type="button"
                title="New Skill"
                onClick={addSkill}
              >
                <span className="d-flex align-items-center">
                  <FaPlus />
                  <span className="ml-2">New</span>
                </span>
              </button>
            )}
          </OrgConsoleHeader>

          <div className="mt-3 bg-white rounded border shadow px-3 pb-3 overflow-content">
            <SearchBox
              className="w-100 py-3"
              onSubmit={(value) => {
                setListConfig((prevState) => ({
                  ...prevState,
                  page: 1,
                  name: value
                }));
              }}
              name="searchSkills"
              placeholder="Search by name..."
              autoFocus
            />

            <SkillsList
              listConfig={listConfig}
              editItem={editSkill}
            />
          </div>

          {Array.isArray(skills.list) && skills.list.length > 0 && (
            <PaginationSupabase
              items={skills?.list}
              pagination={skills?.pagination}
              page={skills?.pagination?.page}
              onClickNext={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: skills?.pagination?.page + 1
                }));
                goToElem('root');
              }}
              onClickPrev={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: skills?.pagination?.page - 1
                }));
                goToElem('root');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Skills);
