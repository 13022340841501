import React from 'react';
import {
  GLOBAL_MICROSOFT_TEAMS_CHAT_LINK_BASE,
  MICROSOFT_TEAMS_ICON
} from '@apprentage/constants';

const TeamsMessageButton = ({
  email = '',
  className = '',
  text = 'Chat in Teams'
}) => {
  if (!email) {
    return null;
  }

  return (
    <a
      className={`btn btn-sm btn-white d-flex align-items-center ${className}`}
      href={GLOBAL_MICROSOFT_TEAMS_CHAT_LINK_BASE + email}
      role="button"
      target="_blank"
      rel="noopener noreferrer"
      title="Chat in Microsoft Teams"
    >
      <img
        src={MICROSOFT_TEAMS_ICON}
        alt="Microsoft Teams Icon"
        className=""
        height="20"
        style={{
          height: '20px'
        }}
      />
      <span className="ml-1">{text}</span>
    </a>
  );
};

export default TeamsMessageButton;
