import React, { useMemo } from 'react';

const NumberStat = ({
  num = '',
  size = 'xl',
  numTitle = '',
  moreDetail = '',
  className = '',
  style = {}
}) => {
  const numClassName = useMemo(() => {
    switch (size) {
      case 'xxs':
        return 'h6';
      case 'xs':
        return 'h5';
      case 'sm':
        return 'h4';
      case 'md':
        return 'h3';
      case 'lg':
        return 'h2';
      case 'xl':
      default:
        return 'h1';
    }
  }, [size]);

  const numTitleClassName = useMemo(() => {
    switch (size) {
      case 'sm':
        return '';
      case 'md':
        return 'h6';
      case 'lg':
        return 'h5';
      case 'xl':
      default:
        return 'h4';
    }
  }, [size]);

  return (
    <div
      className={`text-center ${className}`}
      style={style}
    >
      <div className={`${numClassName} m-0 font-weight-bolder`}>{num}</div>
      <div className={`${numTitleClassName} mt-1 mb-0`}>{numTitle}</div>
      <div className="font-weight-bolder">{moreDetail}</div>
    </div>
  );
};

export default NumberStat;
