import { sortArrByArr } from '@apprentage/utils';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'widgets';

export const constructWidgetUrl = ({
  email,
  externalUserId,
  includeEmail,
  includeExternalUserId,
  url
}) => {
  if (!url) {
    return null;
  }

  if (!includeEmail && !includeExternalUserId) {
    return url;
  }

  const splitUrl = url?.split('?') || [];
  const urlHref = splitUrl[0] || '';
  const urlSearch = splitUrl[1] || '';
  const searchParams = new URLSearchParams(url?.includes('?') ? urlSearch : '');

  if (includeEmail && email) {
    searchParams.append('email', email);
  }

  if (includeExternalUserId && externalUserId) {
    searchParams.append('externalUserId', externalUserId);
  }

  if (searchParams?.size > 0) {
    return `${urlHref}?${searchParams.toString()}`;
  }

  return urlHref;
};

export const fetchWidgets = async ({
  orgId,
  type,
  isFeatured = undefined,
  sortByIds,
  ids,
  order = 'title',
  page = 1,
  select,
  title = '',
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (type) {
    params['f.type[eq]'] = type;
  }

  if (isFeatured !== undefined) {
    if (isFeatured === null) {
      params['f.isFeatured[is]'] = null;
    } else if (isFeatured !== '') {
      params['f.isFeatured[eq]'] = isFeatured;
    }
  }

  if (title) {
    params['f.title[ilike]'] = title;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  const response = await fetchSupabaseEntries(params, table);

  let list =
    Array.isArray(response?.items) && response?.items?.length
      ? response?.items
      : null;

  if (sortByIds && list && type === 'link') {
    list = sortArrByArr(list, sortByIds, 'id');
  }

  return {
    list,
    pagination: {
      limit,
      total: response?.total,
      page: response?.page || 1,
      rangeFrom: response?.rangeFrom,
      rangeTo: response?.rangeTo
    },
    fetched: true
  };
};

export const fetchWidget = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateWidget = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createWidget = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteWidget = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
