import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { FaListOl } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { getQuizOutcomes } from '../../../actions/QuizOutcomes';
import { resetSyndicatedCourseOrgs } from '../../../actions/Organizations';
import { deleteQuizOutcomes } from '../../../services/quizOutcomes';
import Modal from '../../Modal';
import Loading from '../../Loading';
import QuizOutcomesList from '../../QuizOutcomesList';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';

const ManageQuizOutcomes = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const currentModal = useSelector((state) => state.currentModal);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Local State
  const [loading, setLoading] = useState(false);
  // Organization
  const orgId = organization?.id || null;
  // Current Modal
  const quizId = currentModal?.data?.quizId || null;

  const courseHasOrgIds =
    Array.isArray(course?.orgIds) && course?.orgIds?.length > 0;

  const initialListConfig = {
    limit: 20,
    page: 1,
    orgIds: courseHasOrgIds ? [orgId, ...course.orgIds] : [orgId],
    quizId
  };

  const [listConfig, setListConfig] = useState(initialListConfig);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const deleteEntries = () => {
    setLoading(true);

    const itemsToDelete = quizOutcomes.list.map((a) => a.id);

    deleteQuizOutcomes(itemsToDelete)
      .then(() => {
        setTimeout(() => {
          dispatch(
            getQuizOutcomes({
              ...(quizId ? { quizId } : {}),
              orgIds: courseHasOrgIds ? [orgId, ...course.orgIds] : [orgId]
            })
          ).then((response) => {
            toast.dismiss('pleaseReviewQuizOutcomes');
            console.log('response', response);

            if (response?.error) {
              toast.error('Something went wrong, try again.');
              console.error(response?.error);
              return handleClose();
            }

            if (response?.pagination?.total === 0) {
              toast.success('Quiz Outcomes deleted!');
              return handleClose();
            }

            toast.info('Please review and delete remaining Quiz Outcomes', {
              toastId: 'pleaseReviewQuizOutcomes',
              autoClose: false
            });
            setLoading(false);
          });
        }, 250);
      })
      .catch((error) => {
        toast.error('Something went wrong, try again.');
        console.error(error);
      });
  };

  const deleteVerbiage = ({ baseText }) => {
    return `${baseText} ${pluralize('Quiz Outcome', quizOutcomes?.list?.length, true)}`;
  };

  const confirmDelete = () => {
    const stringCheck = 'permanently delete';
    const promptText = `${deleteVerbiage({ baseText: 'Permanently delete' })}?\n\nType "permanently delete" in the text field and click OK.`;
    const confirmString = prompt(promptText);

    if (confirmString === null || confirmString === '') {
      // No action
      // Cancel or empty field
    } else if (confirmString !== stringCheck) {
      toast.info(
        'We found a typo, try again if you wish to delete Quiz Outcomes.',
        { autoClose: false }
      );
    } else {
      // Success
      // entered stringCheck value and clicked OK
      deleteEntries();
    }
  };

  useEffect(() => {
    return function cleanup() {
      toast.dismiss('pleaseReviewQuizOutcomes');
      dispatch(resetSyndicatedCourseOrgs());
      dispatch(
        getQuizOutcomes({
          ...(quizId ? { quizId } : {}),
          orgIds: courseHasOrgIds ? [orgId, ...course.orgIds] : [orgId],
          page: 1
        })
      );
    };
  }, [course.orgIds, courseHasOrgIds, dispatch, orgId, quizId]);

  useEffect(() => {
    dispatch(getQuizOutcomes(listConfig));
  }, [dispatch, listConfig]);

  return (
    <Modal
      cssClassName={`turbine-modal--style-fullscreen turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={loading ? false : handleClose}
      pos="middle"
    >
      <div className="card">
        <div className="card-header d-flex align-items-center">
          <FaListOl size={20} />
          <div className="h5 ml-2 mb-0">Quiz Outcomes</div>
        </div>

        <div className="card-body position-relative p-0">
          <div className="bg-white border-left border-right border-bottom shadow mb-3 p-3">
            <QuizOutcomesList
              className="border"
              list={quizOutcomes.list}
              quizId={quizId}
              reviewActive
            />
          </div>

          {loading && (
            <Loading
              text={deleteVerbiage({ baseText: 'Deleting' })}
              className="h-100"
            />
          )}
        </div>

        <div className="card-footer d-flex align-items-center justify-content-between">
          <div>
            <button
              onClick={() => {
                toast.dismiss();
                confirmDelete();
              }}
              className="btn btn-outline-danger btm-sm"
              type="button"
              disabled={loading || quizOutcomes?.list?.length === 0}
            >
              {loading ? (
                'Deleting...'
              ) : (
                <span className="d-flex align-items-center">
                  <span>Delete ({quizOutcomes?.list?.length})</span>
                  <span className="d-none d-sm-block ml-1">
                    {pluralize('Quiz Outcome', quizOutcomes?.list?.length)}
                  </span>
                </span>
              )}
            </button>
          </div>

          {Array.isArray(quizOutcomes?.list) &&
            quizOutcomes.list.length > 0 && (
              <PaginationSupabase
                className="bg-light"
                items={quizOutcomes?.list}
                pagination={quizOutcomes?.pagination}
                page={quizOutcomes?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: quizOutcomes?.pagination?.page + 1
                  }));
                  // goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: quizOutcomes?.pagination?.page - 1
                  }));
                  // goToElem('root');
                }}
              />
            )}
        </div>
      </div>
    </Modal>
  );
};

export default ManageQuizOutcomes;
