import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { withAuthorization } from '../../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../../actions/Apprenticeships';
import {
  getEmployer,
  getWageSchedules,
  resetEmployer,
  resetWageSchedules
} from '../../../../../../actions/Employers';
import {
  getEmployerAgreements,
  resetEmployerAgreement
} from '../../../../../../actions/EmployerAgreements';
import { routeWithOrgId } from '../../../../../../services/organizations';
import { canEditLocation } from '../../../../../../services/currentUser';
import { ICON_TRAINING_PROGRAMS } from '../../../../../../constants/assets';
import withOrgConsole from '../../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../../OrgConsoleSubheader';
import Tabs from '../../Tabs';
import Loading from '../../../../../Loading';
import EmployerTabs from '../EmployerTabs';
import WageSchedules from '../../../../../WageSchedules';

const ApprenticeshipEmployerWageSchedules = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const wageSchedules = useSelector((state) => state.wageSchedules);
  const employer = useSelector((state) => state.employer);
  const employerProfile = useSelector((state) => state.employerProfile);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipEmployerId = params?.apprenticeshipEmployerId || null;
  // Employer
  const addressState = employer?.addressState || '';
  const ojtHours = apprenticeship?.ojtHours || 0;

  // Employer Profile
  const employerProfileId = employerProfile?.id || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    dispatch(resetWageSchedules());

    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    if (apprenticeshipEmployerId) {
      dispatch(getEmployer(apprenticeshipEmployerId)).then(
        (apprenticeshipEmployer) => {
          if (apprenticeshipEmployer?.employerProfileId && apprenticeshipId) {
            dispatch(
              getEmployerAgreements({
                apprenticeshipId,
                employerProfileId: apprenticeshipEmployer?.employerProfileId
              })
            );

            dispatch(
              getWageSchedules({
                apprenticeshipId,
                employerProfileId: apprenticeshipEmployer?.employerProfileId
              })
            );
          }
        }
      );
    }

    return function cleanup() {
      const { href } = window.location;

      dispatch(resetEmployer());
      dispatch(resetEmployerAgreement());

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, []);

  useEffect(() => {
    if (employerProfileId && apprenticeshipId) {
      dispatch(
        getEmployerAgreements({
          employerProfileId,
          apprenticeshipId
        })
      );
    }
  }, [employerProfileId]);

  if (apprenticeshipId && !apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  if (apprenticeshipEmployerId && !employer.id) {
    return <Loading text="Loading Employer..." />;
  }

  if (employerProfileId && !employerProfile.id) {
    return <Loading text="Loading Workforce Info..." />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="employers" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle={employer.name}
              route={`${apprenticeshipRoute}/employers`}
              locationImage={employer.locationImage}
            />

            <EmployerTabs active="wages" />

            <div className="border-left border-right border-bottom">
              <WageSchedules
                className=""
                addressState={addressState}
                ojtHours={ojtHours}
                data={
                  wageSchedules.fetched && wageSchedules?.list
                    ? wageSchedules?.list
                    : []
                }
                showCreateNewButton
                canCreateNew={!canEditLocation(role) || !employerProfile?.id}
              />
            </div>

            {(!employerProfileId || !apprenticeshipId) && (
              <div className="card-footer border-bottom border-left border-right text-center">
                <span className="mr-1">Review</span>
                <Link
                  className="btn-link"
                  to={routeWithOrgId({
                    route: `${apprenticeshipRoute}/employer/${employer?.id}`,
                    orgId
                  })}
                  title="Trainees"
                >
                  Employer Onboarding Checklist
                </Link>
                <span className="ml-1">to add Wage Schedules.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipEmployerWageSchedules);
