import React from 'react';

function RadioGroup({
  name,
  value,
  showYes = true,
  showNo = true,
  onChange,
  disabled = false,
  labelYes = 'Yes',
  labelNo = 'No'
}) {
  return (
    <div className="RadioGroup--container">
      {showYes && (
        <div
          className="custom-control custom-radio d-inline-block mr-4"
          style={{ position: 'unset' }}
        >
          <input
            id={`${name}-true`}
            name={name}
            type="radio"
            className="custom-control-input"
            required
            defaultChecked={value === true}
            defaultValue
            onChange={onChange || undefined}
            disabled={disabled}
          />
          <label
            className="custom-control-label"
            htmlFor={`${name}-true`}
          >
            {labelYes}
          </label>
        </div>
      )}

      {showNo && (
        <div
          className="custom-control custom-radio d-inline-block position-unset"
          style={{ position: 'unset' }}
        >
          <input
            id={`${name}-false`}
            name={name}
            type="radio"
            className="custom-control-input"
            defaultChecked={value === false || value === undefined}
            defaultValue={false}
            onChange={onChange || undefined}
            disabled={disabled}
          />
          <label
            className="custom-control-label"
            htmlFor={`${name}-false`}
          >
            {labelNo}
          </label>
        </div>
      )}
    </div>
  );
}

export default RadioGroup;
