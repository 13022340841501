import React from 'react';
import { ICON_CHECK_CIRCLE } from '@apprentage/components/dist/constants/icons';
import {
  IMAGE_LINK_EXTERNAL,
  IMAGE_PAGES_DIAGRAM
} from '../../../constants/assets';

const PageType = ({
  showStepTitles,
  enablePageContent,
  setEnablePageContent,
  url,
  setUrl,
  children = null
}) => {
  return (
    <>
      <div className="">
        <div className="row">
          {showStepTitles && (
            <div className="col-sm-12">
              <h5 className="mb-3">
                <b>Step 1.</b> Choose External or Turbine Page
              </h5>
            </div>
          )}

          <div className="col-sm-12 col-md-6 mt-3 mt-md-0">
            <div className="card">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  Turbine Page
                </div>
              </div>
              <div className="card-body border-left border-right">
                <img
                  src={IMAGE_PAGES_DIAGRAM}
                  alt="Turbine Pages"
                  className="mx-auto"
                  style={{ height: '155px' }}
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                {enablePageContent ? (
                  <i
                    className={`${ICON_CHECK_CIRCLE} text-success`}
                    style={{ fontSize: '1.3rem' }}
                  />
                ) : (
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => {
                      setEnablePageContent(true);
                    }}
                    type="button"
                  >
                    Select
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="card">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  External Webpage
                </div>
              </div>
              <div
                className="card-body border-left border-right"
                style={{ backgroundColor: '#f3f3f3' }}
              >
                <img
                  src={IMAGE_LINK_EXTERNAL}
                  alt="External Webpage"
                  className="mx-auto"
                  style={{ height: '155px' }}
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                {!enablePageContent ? (
                  <i
                    className={`${ICON_CHECK_CIRCLE} text-success`}
                    style={{ fontSize: '1.3rem' }}
                  />
                ) : (
                  <button
                    className={`btn btn-sm ${enablePageContent ? 'btn-outline-primary' : 'btn-primary'}`}
                    onClick={() => {
                      setEnablePageContent(null);
                    }}
                    type="button"
                  >
                    Select
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!enablePageContent && (
        <div className="mt-3">
          {showStepTitles && (
            <h5 className="mb-3">
              <b>Step 2.</b> Enter a URL
            </h5>
          )}
          <div>
            <div id="class-title">
              <label
                htmlFor="title"
                className="mb-1"
              >
                <b>External Webpage URL:</b>{' '}
                <span className="text-danger">*</span>
              </label>
              {/* <div className="text-muted mb-2">
                Enter website URL.
              </div> */}
              <input
                type="text"
                className="form-control"
                name="url"
                defaultValue={url}
                placeholder="https://webiste.com/..."
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  console.log('value url', value);

                  setUrl(value);
                }}
                required
                autoFocus
              />
              {/* <div className="text-muted small mt-1">
                Ex: https://webiste.com/...
              </div> */}
            </div>
          </div>

          {children}
        </div>
      )}
    </>
  );
};

export default PageType;
