export const serializeQuizOutcome = (quizOutcome) => {
  // TODO use foreign keys once quizzes are migrated
  // TODO use foreign keys for user
  if (!quizOutcome) return null;

  const serializedQuizOutcome = {
    ...quizOutcome
  };

  const title = quizOutcome.title.split('-');
  const userName = title[title.length - 1];

  if (userName) {
    serializedQuizOutcome.userName = userName.trim();
  }

  return serializedQuizOutcome;
};

export const serializeQuizOutcomes = (quizOutcomes) => {
  if (!quizOutcomes) return null;

  return quizOutcomes.map((quizOutcome) => serializeQuizOutcome(quizOutcome));
};
