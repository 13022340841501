import React from 'react';
import { goToElem } from '../services/manageContent';

function isActive({ i, limit, skip }) {
  if (i === skip / limit) {
    return true;
  }

  return false;
}

const Pagination = ({
  items,
  paginationData, // { limit, skip, total }
  handleClickPage,
  scrollToId = 'root' // id of element to scroll up to when a user clicks a page number
}) => {
  if (!items || (items && !items.length) || !paginationData) {
    return null;
  }

  const { limit, skip, total } = paginationData;

  const numPages = total >= items.length ? Math.ceil(total / limit) : 1;

  if (numPages === 1) {
    return null;
  }

  const pages = [...Array(numPages).keys()];

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        background: 'white'
      }}
      className="d-flex justify-content-center py-3 border-top"
    >
      <nav>
        <ul className="pagination m-0">
          {/* <li className="page-item disabled">
            <button
              className="page-link"
              tabIndex="-1"
              aria-disabled="true"
              onClick={this.handleClickPrevious.bind()}
            >
              Previous
            </button>
          </li> */}

          {pages.map((page, i) => (
            <li
              key={`pagination-${i}`}
              className={`page-item ${isActive({ i, limit, skip }) ? 'active' : ''}`}
            >
              <button
                className="page-link"
                onClick={() => {
                  handleClickPage(i);
                  goToElem(scrollToId);
                }}
                type="button"
              >
                {i + 1}
              </button>
            </li>
          ))}

          {/* <li className="page-item">
            <button
              className="page-link"
              onClick={this.handleClickNext}
            >
              Next
            </button>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
