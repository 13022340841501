import { getClient, flattenItem, flattenItems } from '../services/contentful';
import {
  SET_CURRENT_COHORT,
  RESET_CURRENT_COHORT,
  SET_COHORTS,
  RESET_COHORTS
} from './types';

export const getCohorts = ({ classId, openCohortEnrollment }) => {
  return (dispatch) => {
    const config = {
      content_type: 'cohort',
      order: '-sys.createdAt',
      include: 1,
      'fields.classId': classId
    };

    if (openCohortEnrollment !== undefined) {
      config['fields.openCohortEnrollment'] = openCohortEnrollment;
    }

    return new Promise((resolve, reject) => {
      getClient()
        .getEntries(config)
        .then(({ items }) => {
          const courseCohorts = items.length ? flattenItems(items) : null;

          dispatch({
            type: SET_COHORTS,
            courseCohorts
          });

          resolve(courseCohorts);
        })
        .catch((error) => {
          console.error('getCohorts', error);
          reject(error);
        });
    });
  };
};

export const resetCohorts = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COHORTS });
  };
};

export const setCurrentCohort = ({ cohortId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'cohort',
        include: 2,
        'sys.id': cohortId
      };

      // TODO change to getEntry after refactoring cohort to have
      // all referenced entries like eUnitTracking
      getClient()
        .getEntries(config)
        .then(({ items }) => {
          const currentCohort =
            items[0] !== undefined ? flattenItem(items[0]) : null;

          dispatch({
            type: SET_CURRENT_COHORT,
            currentCohort
          });

          resolve(currentCohort);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const resetCurrentCohort = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_COHORT });
  };
};
