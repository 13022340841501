import React from 'react';
import {
  FaFileAlt,
  FaFileUpload,
  FaLinkedin,
  FaMagic,
  FaRegEnvelope,
  FaUserAlt
} from 'react-icons/fa';
import { HiMiniSpeakerWave } from 'react-icons/hi2';
import { VELA_ICON } from '@apprentage/constants';
import { defaultPrompts } from '../../constants/resources';

const MaterialIcon = ({
  className = '',
  promptType,
  fileUploadFlow,
  size = 15
}) => {
  const genericDefaultPrompts = defaultPrompts.map(
    (defaultPrompt) => defaultPrompt?.type
  );

  if (promptType === 'vela') {
    return (
      <img
        src={VELA_ICON}
        alt="VELA"
        className={`d-none d-sm-inline-block ${className}`}
        height={size}
        style={{
          height: `${size}px`
        }}
      />
    );
  }

  if (promptType === 'audio') {
    return (
      <HiMiniSpeakerWave
        size={size}
        className={className}
      />
    );
  }

  if (fileUploadFlow) {
    return (
      <FaFileUpload
        size={size}
        className={className}
      />
    );
  }

  // Default Prompts
  if (promptType === 'linkedInPost') {
    return (
      <FaLinkedin
        size={size}
        className={className}
      />
    );
  }
  if (promptType === 'email') {
    return (
      <FaRegEnvelope
        size={size}
        className={className}
      />
    );
  }
  if (genericDefaultPrompts.includes(promptType)) {
    return (
      <FaMagic
        size={size}
        className={className}
      />
    );
  }
  // /Default Prompts

  if (promptType === 'userPrompt') {
    return (
      <FaUserAlt
        size={size}
        className={className}
      />
    );
  }

  return (
    <FaFileAlt
      size={size}
      className={className}
    />
  );
};

export default MaterialIcon;
