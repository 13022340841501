import React from 'react';
import FakeLoader from './FakeLoader';

const InputFakeLoader = ({ children, loading, size = 'sm' }) => {
  return (
    <div className="position-relative">
      {children || null}
      {loading && (
        <FakeLoader
          size={size}
          loading={loading}
        />
      )}
    </div>
  );
};

export default InputFakeLoader;
