import {
  SET_EMPLOYER_PROFILE,
  RESET_EMPLOYER_PROFILE
} from '../../actions/types';
import employerProfileState from '../initialState/employerProfileState';

const initialState = {
  ...employerProfileState,
  fetched: false
};

export default function employerProfile(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER_PROFILE:
      return {
        ...state,
        ...action.employerProfile,
        fetched: true
      };
    case RESET_EMPLOYER_PROFILE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
