import React from 'react';
import { FaThumbtack } from 'react-icons/fa';

const NotificationsTabs = ({
  className = '',
  listConfig,
  setListConfig,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${!listConfig?.isDraft && !listConfig?.isPinned && 'active'}`}
            type="button"
            disabled={!listConfig?.isDraft && !listConfig?.isPinned}
            onClick={() => {
              setListConfig((prev) => ({
                ...prev,
                isDraft: null,
                isPinned: null,
                page: 1
              }));
            }}
          >
            Published
          </button>
        </li>
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${listConfig?.isPinned && 'active'}`}
            type="button"
            disabled={listConfig?.isPinned}
            onClick={() => {
              setListConfig((prev) => ({
                ...prev,
                isDraft: null,
                isPinned: true,
                page: 1
              }));
            }}
          >
            <span className="d-flex align-items-center">
              <FaThumbtack
                className={listConfig?.isPinned ? 'text-primary' : 'text-muted'}
              />
              <span className="ml-1">Pinned</span>
            </span>
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${listConfig?.isDraft && 'active'}`}
            type="button"
            disabled={listConfig?.isDraft}
            onClick={() => {
              setListConfig((prev) => ({
                ...prev,
                isDraft: true,
                isPinned: null,
                page: 1
              }));
            }}
          >
            My Drafts
          </button>
        </li>
      </ul>
    </div>
  );
};

export default NotificationsTabs;
