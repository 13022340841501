import { percentComplete } from '@apprentage/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { FaCircle, FaCheckCircle } from 'react-icons/fa';
import ProgressBars from '../../ProgressBars';

const LogbookProgress = ({ ojtHours = 0, className = '' }) => {
  // Redux
  const logbookHours = useSelector((state) => state.logbookHours);
  // Calculations
  const totalOjtHours = parseInt(ojtHours, 10);
  // Hours In Review
  const inReviewValueNow = logbookHours.totalHours;
  const inReviewPercent = percentComplete({
    current: inReviewValueNow,
    total: totalOjtHours
  });
  // Hours Approved
  const approvedValueNow = logbookHours.approvedHours;
  const approvedPercent = percentComplete({
    current: approvedValueNow,
    total: totalOjtHours
  });

  return (
    <div className={className}>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <div>
          <div className="mb-2 d-flex align-items-center">
            <span className="d-flex align-items-center small">
              <span>Hours Logged:</span>
            </span>
            <span className="h6 mb-0 ml-1">
              <span
                className="badge py-1 px-2 text-light"
                style={{
                  backgroundColor: 'rgba(0,0,0, .5)'
                }}
              >
                <span className="d-flex align-items-center">
                  <FaCircle className="text-primary mr-2" />
                  <span>{inReviewValueNow}</span>
                </span>
              </span>
            </span>
          </div>
          <div className="mb-2 d-flex align-items-center">
            <span className="d-flex align-items-center small">
              <span>Approved Hours:</span>
            </span>
            <span className="h6 mb-0 ml-1">
              <span
                className="badge py-1 px-2 text-light"
                style={{
                  backgroundColor: 'rgba(0,0,0, .5)'
                }}
              >
                <span className="d-flex align-items-center">
                  <FaCheckCircle className="text-success mr-2" />
                  <span>{approvedValueNow}</span>
                </span>
              </span>
            </span>
          </div>
        </div>

        <div className="">
          <div className="mb-2 d-flex align-items-center justify-content-end">
            <span className="mr-1 small">Completed</span>
            <span className="h6 m-0">
              <span
                className="badge py-1 px-2 text-light"
                style={{
                  backgroundColor: 'rgba(0,0,0, .5)'
                }}
              >
                {inReviewPercent}
              </span>
            </span>
          </div>
          <div className="mb-2 d-flex align-items-center justify-content-end">
            <span className="mr-1 small">Target OJT Hours:</span>
            <span className="h6 m-0">
              <span
                className="badge py-1 px-2 text-light"
                style={{
                  backgroundColor: 'rgba(0,0,0, .5)'
                }}
              >
                {ojtHours || 0}
              </span>
            </span>
          </div>
        </div>
      </div>

      <ProgressBars
        id="LogbookProgressBars"
        size="sm"
        style={{
          backgroundColor: 'rgb(98 98 98)'
        }}
        data={[
          {
            className: 'bg-success',
            dataTip: 'Approved Hours',
            valueNow: approvedValueNow,
            // valueMin: 0,
            style: {
              width:
                approvedValueNow !== 0 && parseFloat(approvedPercent) < 10
                  ? '12%'
                  : approvedPercent
            },
            text: ''
          },
          {
            className: 'progress-bar-striped progress-bar-animated bg-primary',
            dataTip: 'Hours Logged',
            valueNow: inReviewValueNow,
            // valueMin: 0,
            style: {
              width:
                inReviewValueNow !== 0 && parseFloat(inReviewPercent) < 10
                  ? '12%'
                  : inReviewPercent
            },
            text: '',
            valueMax: totalOjtHours
          }
        ]}
      />
    </div>
  );
};

export default LogbookProgress;
