import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccountLink } from '../../../services/stripe';

class Stripe extends Component {
  state = {
    loading: false
  };

  connectStripe = () => {
    this.setState({
      loading: true
    });

    getAccountLink().then((accountLink) => {
      document.location = accountLink;
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <div>
        <div className="mb-1 h6 font-weight-bold border-bottom pb-2">
          Stripe Connect <span className="badge badge-warning">beta</span>
        </div>
        <div>
          <p>
            To begin selling courses, connect your Stripe Account.
            <span className="small text-muted d-block">
              Don't have a Stripe Account? You can{' '}
              <a
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                create a free account
              </a>
              .
            </span>
          </p>
          <p>
            When a user purchases a Course, your business name, as configured in
            Stripe, will appear on their Credit Card statement.{' '}
            <small>
              <a
                href="https://stripe.com/connect"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </small>
          </p>

          <div>
            <button
              className="btn btn-md btn-outline-primary"
              onClick={this.connectStripe}
              disabled={loading}
              type="button"
            >
              Connect my Stripe Account
            </button>
            {loading && <span className="ml-2">Redirecting to Stripe...</span>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organization }) => ({ organization });

export default connect(mapStateToProps)(Stripe);
