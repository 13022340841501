import React from 'react';

const CurrentWeather = ({
  current,
  location,
  icon = false,
  iconHeight = 28,
  className = ''
}) => {
  return (
    <div className={className}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div className="h4 m-0">{current?.condition?.text}</div>
          <small>
            {location?.name}, {location?.region}
          </small>
        </div>

        {icon && current?.condition?.icon && (
          <img
            src={current?.condition?.icon}
            className="mr-1"
            alt={current?.condition?.text || 'Current weather'}
            height={iconHeight}
            style={{
              height: `${iconHeight}px`
            }}
          />
        )}

        <div className="d-flex flex-column justify-content-end">
          <div className="h3 font-weight-bold text-right">
            {current?.temp_f}
            <sup>&deg;</sup>
          </div>
          <small>
            Feels like {current?.feelslike_f}
            <sup>&deg;</sup>
          </small>
        </div>
      </div>
    </div>
  );
};

export default CurrentWeather;
