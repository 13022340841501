import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';

const EmployerTabs = ({ active, className = 'mt-3' }) => {
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const employer = useSelector((state) => state.employer);
  // Organization
  const orgId = pathOr('', ['id'], organization);
  // Training Program
  const apprenticeshipId = pathOr('', ['apprenticeshipId'], params);
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'info' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${apprenticeshipRoute}/employer/${employer?.id}`,
              orgId
            })}
            title="Employer Information"
          >
            Information
          </Link>
        </li>

        <li className="nav-item ml-2">
          <Link
            className={`nav-link  ${active === 'wages' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${apprenticeshipRoute}/employer/${employer?.id}/wage-schedules`,
              orgId
            })}
            title="Employer Wage Schedules"
          >
            <span className="d-block d-sm-none">Wages</span>
            <span className="d-none d-sm-block">Wage Schedules</span>
          </Link>
        </li>

        <li className="nav-item ml-2">
          <Link
            className={`nav-link ${active === 'docs' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${apprenticeshipRoute}/employer/${employer?.id}/docs`,
              orgId
            })}
            title="Employer Documents"
          >
            <span className="d-block d-sm-none">Docs</span>
            <span className="d-none d-sm-block">Documents</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default EmployerTabs;
