import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ICON_USERS } from '@apprentage/constants';
import { mdyDate } from '../../../utils/date';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';
import { withAuthorization } from '../../Session';
import { BILLING } from '../../../constants/routes';
import { TURBINE_TOS_URL } from '../../../constants/urls';
import {
  PRODUCT_COMMUNITY_STANDARD,
  PRODUCT_ENTERPRISE_BUSINESS
} from '../../../constants/billing';
import { formatCurrency, pricingCalculator } from '../../../services/billing';
import { isLocalhost } from '../../../serviceWorker';
import {
  getPrice,
  createCustomer,
  getCustomer,
  createSubscription
} from '../../../actions/Billing';
import { routeWithOrgId } from '../../../services/organizations';
import PaymentMethods from '../PaymentMethods';
import Customer from '../Customer';
import SubscriptionRow from './SubscriptionRow';
import './style.css';

const handleAttachPaymentMethod = ({ paymentMethod, customer }) => {
  // TODO build this functionality
  console.log('handleAttachPaymentMethod', {
    paymentMethod,
    customer
  });
};

class NewSubscription extends Component {
  state = {
    quantity: 15,
    selectedPaymentMethod: null,
    readyToAddBillingInfo: false,
    readyToCheckout: false,
    billingInfoEditMode: true,
    loading: false,
    address: {},
    coupon: null
  };

  componentDidMount() {
    const {
      location: { search: locationSearch },
      history,
      billing,
      organization
    } = this.props;
    const orgId = organization?.id || null;
    const products = billing?.products || null;
    const billingRoute = routeWithOrgId({
      route: BILLING,
      orgId
    });

    this.props.getCustomer().catch((error) => console.warn(error.message));

    let priceId = null;
    let productId = null;
    let product = null;

    if (locationSearch) {
      const searchParams = convertUrlParamsToObject(locationSearch);

      priceId = searchParams?.priceId || null;
      productId = searchParams?.productId || null;

      if (!products || !priceId) {
        history.push(billingRoute);
      }

      product = products ? products.find((p) => p.id === productId) : null;

      this.props.getPrice(priceId).catch((error) => {
        toast.error(error.message, { autoClose: false });
        history.push(billingRoute);
      });
    }

    const { currentUser } = this.props;
    const { email } = currentUser;
    const turbineProduct = product?.metadata?.turbine_product || null;
    const priceDescription = product?.metadata?.price_description || null;
    const priceMonthlyCost = product?.metadata?.price_monthlyCost || null;

    this.setState({
      email,
      product,
      turbineProduct,
      priceDescription,
      priceMonthlyCost
    });
  }

  saveQuantity = (e) => {
    const { value } = e.currentTarget;
    let inputQuantity = parseInt(value, 10);
    inputQuantity = inputQuantity < 12 ? 12 : parseInt(value, 10);

    this.setState({
      quantity: inputQuantity >= 12 ? inputQuantity : 12
    });
  };

  savePromoCode = (e) => {
    const { value } = e.currentTarget;

    // TODO temporary coupon implementation
    const validCoupon = isLocalhost ? '6Gtwhi2S' : '9Ae4oGRC';

    this.setState({
      coupon: value === validCoupon ? value : null
    });
  };

  handleReadyToCheckout = () => {
    const { address } = this.state;

    if (!address) {
      toast.error('Please enter a Billing Address');

      return false;
    }

    if (!address.line1) {
      toast.error('Please enter a Street Address');

      return false;
    }

    if (!address.city) {
      toast.error('Please enter a City');

      return false;
    }

    if (!address.country) {
      toast.error('Please select a Country');

      return false;
    }

    if (!address.postal_code) {
      toast.error('Please enter a Zip/Postal Code');

      return false;
    }

    if (!address.state) {
      toast.error('Please select a State');

      return false;
    }

    this.setState(
      {
        readyToCheckout: true,
        billingInfoEditMode: false
      },
      () => {
        window.scrollTo(0, document.body.scrollHeight);
      }
    );
  };

  handleReadyToAddBillingInfo = () => {
    this.setState(
      {
        readyToAddBillingInfo: true
      },
      () => {
        window.scrollTo(0, document.body.scrollHeight);
      }
    );
  };

  handleOnChange = (e) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value
    });
  };

  toggleBillingInfoEditMode = () => {
    const { toggleBillingInfoEditMode } = this.state;

    this.setState({
      billingInfoEditMode: !toggleBillingInfoEditMode
    });
  };

  handleAddressOnChange = (e) => {
    const { name, value } = e.currentTarget;
    const { address } = this.state;

    let newAddress = {};

    if (address && Object.keys(address).length) {
      newAddress = {
        ...address
      };
    }

    this.setState({
      address: {
        ...newAddress,
        [name]: value
      }
    });
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({
      selectedPaymentMethod: paymentMethod
    });
  };

  handleCreateCustomer = (paymentMethod) => {
    const { history, organization } = this.props;
    const orgId = organization?.id || null;
    const orgName = organization?.name || null;
    const billingRoute = routeWithOrgId({
      route: BILLING,
      orgId
    });
    const { email, address } = this.state;

    this.props
      .createCustomer({
        name: orgName,
        email,
        address,
        paymentMethod: paymentMethod.id
      })
      .catch((error) => {
        toast.error(error.message, { autoClose: false });
        history.push(billingRoute);
      });
  };

  handlePlaceOrder = () => {
    const { selectedPaymentMethod, coupon, quantity, turbineProduct } =
      this.state;
    const { history, billing, organization } = this.props;
    const orgId = organization?.id || null;
    const price = billing?.price || null;
    const billingRoute = routeWithOrgId({
      route: BILLING,
      orgId
    });

    this.setState({ loading: true });

    if (!selectedPaymentMethod) {
      toast.error('Select a payment method');

      this.setState({ loading: false });

      return false;
    }

    const data = {
      quantity,
      price: price.id
    };

    if (turbineProduct === PRODUCT_COMMUNITY_STANDARD) {
      data.quantity = 1;
    }

    if (coupon) {
      data.coupon = coupon;
    }

    this.props
      .createSubscription(data)
      .then(() => {
        // subscription
        toast.success('Thank you for using Turbine Workforce!', {
          autoClose: false
        });

        setTimeout(() => {
          history.push(billingRoute);
        }, 500);
      })
      .catch((error) => {
        toast.error(error.message, { autoClose: false });
        history.push(billingRoute);
      });
  };

  monthlyCost = () => {
    // {price && formatCurrency(this.state.quantity * (price.unit_amount / 100))} USD/month
    const { quantity } = this.state;

    return quantity ? pricingCalculator(quantity) : null;
  };

  render() {
    const {
      readyToCheckout,
      readyToAddBillingInfo,
      billingInfoEditMode,
      loading,
      address,
      selectedPaymentMethod,
      coupon,
      quantity,
      product,
      turbineProduct,
      priceDescription,
      priceMonthlyCost
    } = this.state;
    const { organization, billing } = this.props;
    const orgId = organization?.id || null;
    const customer = billing?.customer || null;
    const billingRoute = routeWithOrgId({
      route: BILLING,
      orgId
    });

    if (!product) {
      return null; // TODO use loading ui
    }

    const { name: productName, metadata, images } = product;

    if (!metadata) {
      return null; // TODO use loading ui
    }

    const costData = this.monthlyCost();

    return (
      <main
        role="main"
        className="px-3 my-3 bg-light border rounded container shadow"
      >
        <div className="row mb-2">
          <div className="col-sm-12">
            <h4 className="font-weight-bold">New Subscription</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <SubscriptionRow
              images={images}
              name={productName}
              metadata={metadata}
            >
              <Link
                className="btn btn-sm btn-link"
                to={billingRoute}
                title="Change Plan"
              >
                Change Plan
              </Link>
            </SubscriptionRow>

            {turbineProduct === PRODUCT_ENTERPRISE_BUSINESS && (
              <div className="card">
                <div className="card-body">
                  <p>
                    Leverage all the features of Turbine Workforce Enterprise:
                  </p>

                  <ul>
                    <li>
                      <b>Unlimited Courses</b>. Coverts your content into
                      powerful upskilling & trainings.
                    </li>
                    <li>
                      <b>Workforce resources</b>. Store files, images and
                      documents.
                    </li>
                    <li>
                      <b>AWS Integration</b>. Securely store any file uploaded
                      to Turbine Workforce on your AWS account.
                    </li>
                    <li>
                      <b>Attendance</b>. Instructors record attendance which can
                      be integrated into a traditional grading system, bonus
                      points or loyalty program.
                    </li>
                    <li>
                      <b>Lesson Plans</b>. Preserves a knowledge-base to support
                      experts and to scale up instruction.
                    </li>
                    <li>
                      <b>Completion Certificates</b>. Users can generate a
                      certificate of completion when issued.
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {turbineProduct === PRODUCT_COMMUNITY_STANDARD && (
              <div className="card">
                <div className="card-body">
                  <p>
                    Leverage all the features of Turbine Workforce Community:
                  </p>

                  <ul>
                    <li>
                      <b>Pathways</b>. Generate career pathways that link job
                      profiles, skills and courses.
                    </li>
                    <li>
                      <b>AWS Integration</b>. Securely store any file uploaded
                      to Turbine Workforce on your AWS account.
                    </li>
                    <li>
                      <b>Lesson Plans</b>. Preserves a knowledge-base to support
                      experts and to scale up instruction.
                    </li>
                    <li>
                      <b>Go Live</b>. Simplify sharing of urls to video
                      platforms like Zoom Meeting, Skype & Google Meet.
                    </li>
                    <li>
                      <b>
                        Attendance, Completion Certificates & CEU Statements
                      </b>
                      . Users can generate a certificate, or a CEU statement at
                      any time.
                    </li>
                    <li>
                      <b>Cohorts & Timestamped Check-ins</b>. Users can be
                      trained in cohorts for a small class experience, and
                      providing scalability and flexibility as to how trainings
                      are administered and reported.
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="payment-plan-container mt-4">
              <h5>Payment Plan</h5>

              <div className="card">
                <div className="card-body">
                  <p className="mb-1">Monthly</p>
                  <div className="mb-2">
                    Each month, you're billed for the total of the charges
                    listed below.
                  </div>
                  <ul>
                    <li>Pay each month, starting {mdyDate(new Date())}</li>
                    {turbineProduct === PRODUCT_COMMUNITY_STANDARD && (
                      <>
                        <li>
                          Pay for <strong>{productName}</strong> Platform Access
                        </li>
                        <li>Pay for user enrollments in course cohorts</li>
                      </>
                    )}
                    {turbineProduct === PRODUCT_ENTERPRISE_BUSINESS && (
                      <li>Pay for Licensed users</li>
                    )}
                  </ul>
                  <table className="table table-bordered mb-0">
                    <thead className="bg-secondary text-white">
                      <tr>
                        <th scope="col">Charges</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{productName}</strong> Platform Access
                        </td>
                        <td>
                          {turbineProduct === PRODUCT_COMMUNITY_STANDARD ? (
                            priceDescription
                          ) : (
                            <>
                              <strike className="text-muted">
                                $1,200 /per month
                              </strike>{' '}
                              <mark>No charge</mark>
                            </>
                          )}
                        </td>
                      </tr>

                      {turbineProduct === PRODUCT_COMMUNITY_STANDARD && (
                        <tr>
                          <td>User Enrollments (one-time)</td>
                          <td>$14 /per user enrollment in course cohorts</td>
                        </tr>
                      )}

                      {turbineProduct === PRODUCT_ENTERPRISE_BUSINESS && (
                        <tr>
                          <td>
                            <div className="mb-2 h6">Licensed Users</div>

                            <div>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text bg-white text-muted"
                                    style={{ fontSize: '1.15rem' }}
                                  >
                                    <i className={ICON_USERS} />
                                  </span>
                                </div>
                                <input
                                  className="form-control form-control-md"
                                  name="quantity"
                                  type="number"
                                  value={this.state.quantity}
                                  min={12}
                                  onChange={this.saveQuantity}
                                  required
                                />
                              </div>

                              {/* <select
                                className="form-control form-control-sm"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.saveQuantity}
                                >
                                  {licenseQuantity.map((lq, i) => (
                                    <option
                                      key={i}
                                      value={lq.value}
                                    >
                                      {lq.name}
                                    </option>
                                  ))}
                                </select> */}
                            </div>
                          </td>
                          <td>
                            {turbineProduct === PRODUCT_ENTERPRISE_BUSINESS &&
                              quantity &&
                              costData && (
                                <span>
                                  {formatCurrency(costData.monthlyCostPerSeat)}{' '}
                                  /per user/month
                                </span>
                              )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="p-3 border-top d-flex justify-content-between">
                  <div>
                    <div className="text-muted">Promo code:</div>
                    <div className="font-weight-bold mt-2">
                      <input
                        className="form-control form-control-sm"
                        name="quantity"
                        type="text"
                        minLength={8}
                        maxLength={8}
                        onChange={this.savePromoCode}
                      />
                    </div>
                  </div>

                  <div className="text-right">
                    <div className="text-muted mb-2">Monthly cost:</div>

                    {turbineProduct === PRODUCT_ENTERPRISE_BUSINESS &&
                      quantity &&
                      costData && (
                        <div className="font-weight-bold px-2">
                          {formatCurrency(costData.monthlyCost)} USD/month
                        </div>
                      )}

                    {turbineProduct === PRODUCT_COMMUNITY_STANDARD &&
                      priceMonthlyCost && (
                        <div className="font-weight-bold px-2">
                          {formatCurrency(parseInt(priceMonthlyCost, 10))}{' '}
                          USD/month
                        </div>
                      )}

                    {coupon && (
                      <>
                        <div className="text-muted mt-2">Promotion:</div>
                        <div className="font-weight-bold mt-2">
                          3 MO. TRIAL 50% OFF
                        </div>
                        <div className="text-muted">
                          (discount reflected on invoice)
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {readyToAddBillingInfo ? (
          <>
            <div className="row">
              <div className="col-sm-12">
                <Customer
                  onChange={this.handleOnChange}
                  onAddressChange={this.handleAddressOnChange}
                  toggleEditMode={this.toggleBillingInfoEditMode}
                  editMode={billingInfoEditMode}
                  address={address}
                />
              </div>
            </div>

            {!readyToCheckout && (
              <div className="row mt-3 mb-5">
                <div className="col-sm-12">
                  <h6>Next Step: Payment Method</h6>
                  <button
                    className="btn btn-md btn-primary mt-2"
                    onClick={this.handleReadyToCheckout}
                    title="Continue to Payment Method"
                    type="button"
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="row mt-3 mb-5">
            <div className="col-sm-12">
              <h6>Next Step: Billing Information</h6>
              <button
                className="btn btn-md btn-primary mt-2"
                onClick={this.handleReadyToAddBillingInfo}
                title="Continue to Billing Information"
                type="button"
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {readyToCheckout && (
          <>
            <div className="row">
              <div className="col-sm-12">
                <PaymentMethods
                  onPaymentMethodChange={this.handlePaymentMethodChange}
                  onCreateCustomer={this.handleCreateCustomer}
                  onAttachPaymentMethod={handleAttachPaymentMethod}
                  // hidePostalCode={true}
                />
              </div>
            </div>

            <div className="row mt-1 mb-5">
              <div className="col-sm-12">
                Any applicable taxes and discounts will be applied on your
                monthly invoice. Prices exclude applicable taxes and VAT (varies
                based on your country).
                <br />
                By clicking <b>Place Order</b>, you agree to the Turbine
                Workforce{' '}
                <a
                  href={TURBINE_TOS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
                . Invoices are issued by Yinz Technology LLC, dba Turbine
                Workforce.
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-sm-12 d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-md btn-primary"
                  onClick={this.handlePlaceOrder}
                  title="Place Order"
                  disabled={loading || !selectedPaymentMethod || !customer}
                  type="button"
                >
                  {loading ? 'Placing Order...' : 'Place Order'}
                </button>
                <img
                  src="https://turbine-content.s3.us-east-2.amazonaws.com/le-logo-wide.png"
                  className="opacity-50"
                  alt="Let's Encrypt"
                  height="40"
                  style={{
                    height: '40px'
                  }}
                />
              </div>
            </div>
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = ({ currentUser, billing, organization }) => ({
  currentUser,
  billing,
  organization
});

const condition = (user) => !!user.uid;

export default compose(
  connect(mapStateToProps, {
    createCustomer,
    getPrice,
    getCustomer,
    createSubscription
  }),
  withAuthorization(condition)
)(NewSubscription);
