import React from 'react';
import { useDispatch } from 'react-redux';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { setCurrentModal } from '../../../../actions/Modals';
import { MODAL_KEY_MANAGE_WIDGET } from '../../../../constants/modals';

const MenuCell = ({ row }) => {
  const dispatch = useDispatch();
  // Link
  const title = row?.original?.title || '';

  const onClickSettings = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_WIDGET,
        data: {
          app: row?.original,
          editMode: true,
          filterAll: true
        }
      })
    );
  };

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-white rounded bg-white py-1 border border-1"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Menu"
          title="Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white text-nowrap">
            {title}
          </div>
          <button
            className="dropdown-item p-2"
            type="button"
            onClick={onClickSettings}
          >
            <span className="d-flex align-items-center">
              <FaCog />
              <span className="ml-2">Settings</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuCell;
