import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routeWithOrgId } from '../../../../services/organizations';
import { ORG_CONSOLE_RESOURCES } from '../../../../constants/routes';

const GroupTagFilters = ({
  groupTags,
  orgId // groupIds
}) => {
  const resourceGroupTags = useSelector((state) => state.resourceGroupTags);

  return (
    <div>
      {resourceGroupTags.list &&
        groupTags.map((tagId, i) => {
          const routeConfig = {
            route: ORG_CONSOLE_RESOURCES,
            urlParams: {
              orgId
            }
          };

          // if (groupIds) {
          //   routeConfig.urlParams.groupIds = groupIds.join(',');
          // }

          if (groupTags) {
            let newTags = groupTags.slice(0);
            newTags = newTags.filter((t) => t !== tagId);

            if (newTags && Array.isArray(newTags) && newTags.length >= 1) {
              routeConfig.urlParams.groupTags = newTags.join(',');
            }
          }

          const resourceGroupTag =
            resourceGroupTags.list &&
            resourceGroupTags.list.find(({ id }) => id === tagId);

          return (
            <div
              key={i}
              className="badge badge-pill badge-light border p-2 mr-1 mb-1 mr-1 mb-1 text-nowrap py-1"
              style={{ fontSize: '.65rem' }}
            >
              <Link
                className="pr-1"
                to={routeWithOrgId(routeConfig)}
                title={`Clear ${resourceGroupTag && resourceGroupTag.label}`}
              >
                x
              </Link>
              <span className="border-left pl-1">
                {resourceGroupTag && resourceGroupTag.label}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default GroupTagFilters;
