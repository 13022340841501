import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FaAward, FaCheckCircle } from 'react-icons/fa';
import { mdySimple } from '../../../utils/date';
import { courseCompleteCertificate } from '../../../services/user';
import { TURBINE } from '../../../constants/urls';
import CertificateAcceptButton from '../../btns/CertificateAcceptButton';

const CertData = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 115px;
  white-space: nowrap;
  margin-bottom: 2px;

  @media (min-width: 576px) {
    max-width: initial;
  }
`;

const CompletionCertificateBadge = ({ user, manageCertificate }) => {
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);

  const completionCertificateData = courseCompleteCertificate({ course, user });
  const { slug: orgSlug } = organization;
  const { uniqueCertificateIds } = user;

  if (
    !uniqueCertificateIds ||
    (Array.isArray(uniqueCertificateIds) && !uniqueCertificateIds.length)
  ) {
    return null;
  }

  return (
    <div>
      {completionCertificateData && (
        <div>
          <div className="d-flex flex-column">
            <div className="mb-2">
              {!completionCertificateData.acceptedDate && manageCertificate && (
                <CertificateAcceptButton
                  acceptedCertificateCourseId={course.id}
                  certificateId={completionCertificateData.id}
                  userId={user.id}
                />
              )}

              {!manageCertificate && (
                <a
                  title="View Certificate"
                  className="btn btn-sm btn-outline-primary"
                  href={`${TURBINE}/completion-certificate/${orgSlug}/${completionCertificateData.id}`}
                  rel="noopener noreferrer"
                  target="blank"
                >
                  <span className="d-flex align-items-center">
                    <FaAward />
                    <span className="ml-1">Certificate</span>
                  </span>
                </a>
              )}
            </div>
            <div className="h6 mb-0">
              {completionCertificateData.id && (
                <CertData className="text-muted small">
                  ID: {completionCertificateData.id}
                </CertData>
              )}
              {completionCertificateData.issueDate && (
                <CertData className="text-muted small d-flex align-items-center">
                  <span className="mr-1">ISSUED:</span>
                  <span
                    className={
                      completionCertificateData.acceptedDate
                        ? ''
                        : 'font-weight-bold'
                    }
                  >
                    {mdySimple(completionCertificateData.issueDate, '-')}
                  </span>
                </CertData>
              )}

              {completionCertificateData.acceptedDate && (
                <CertData className="text-muted small d-flex align-items-center">
                  <span className="mr-1">ACCEPTED:</span>
                  <FaCheckCircle className="text-success" />
                  <span className="font-weight-bold ml-1">
                    {mdySimple(completionCertificateData.acceptedDate, '-')}
                  </span>
                </CertData>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompletionCertificateBadge;
