import React, { useCallback, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PopoverChooseColor from './PopoverChooseColor';
import Color from './Color';
import './style.css';

const colorsHash = {
  '#b3bac5': 'grey',
  '#344563': 'black',
  '#61bd4f': 'green',
  '#f2d600': 'yellow',
  '#ff9f1a': 'orange',
  '#eb5a46': 'red',
  '#c377e0': 'purple',
  '#0079bf': 'blue',
  '#00c2e0': 'sky',
  '#51e898': 'lime',
  '#ff78cb': 'pink'
};

const colors = Object.entries(colorsHash).map(([key, value]) => ({
  name: value,
  hex: key
}));

const ColorSwatches = ({
  handleClick,
  onReset,
  activeColor = '',
  chooseDefaultColors = false,
  chooseCustomColor = false
}) => {
  const isCustomHex = colorsHash[activeColor.toLowerCase()] === undefined;
  const [color, setColor] = useState(activeColor);
  const [customHex, setCustomHex] = useState(
    isCustomHex ? activeColor : undefined
  );

  const isValidHex = useCallback((value) => {
    return value && value.length === 7 && value.includes('#');
  }, []);

  const onClick = (value) => {
    if (handleClick) {
      handleClick(value);
    }
    setCustomHex('');
    setColor(value);
  };

  const handleReset = useCallback(() => {
    if (onReset) {
      onReset();
    }
    setColor('');
  }, [onReset]);

  const onClose = useCallback(
    (value) => {
      if (!isValidHex(value) && !activeColor) {
        handleReset();
      }

      setCustomHex(isValidHex(value) ? value : undefined);
    },
    [activeColor, handleReset, isValidHex]
  );

  const onChange = (value) => {
    setCustomHex((value || '').toLowerCase());

    if (isValidHex(value)) {
      if (handleClick) {
        handleClick((value || '').toLowerCase());
      }

      setColor((value || '').toLowerCase());
    } else {
      handleReset();
    }
  };

  useEffect(() => {
    // https://www.npmjs.com/package/react-tooltip/v/4.5.1#3-tooltip-not-binding-to-dynamic-content
    if (chooseCustomColor && customHex && isCustomHex) {
      ReactTooltip.rebuild();
    }
  }, [chooseCustomColor, customHex, isCustomHex]);

  return (
    <>
      <div className="ColorSwatches">
        {chooseDefaultColors &&
          colors.map((c, i) => (
            <Color
              key={i}
              color={c.hex}
              handleClick={onClick}
              activeColor={isCustomHex ? undefined : color}
              tooltipContent={c.hex}
              tooltipId="ColorSwatches"
            />
          ))}

        {chooseCustomColor && (
          <PopoverChooseColor
            activeColor={activeColor}
            color={customHex}
            onChange={onChange}
            onClose={onClose}
            tooltipContent={isCustomHex ? color : undefined}
            tooltipId="ColorSwatches"
          />
        )}
      </div>
      <ReactTooltip
        id="ColorSwatches"
        place="top"
      />
    </>
  );
};

export default ColorSwatches;
