import React from 'react';
import { ICON_SKILL } from '@apprentage/constants';

const NameCell = ({ value: name }) => {
  return (
    // row
    <div className="d-flex align-items-center">
      <i
        style={{ fontSize: '1.1rem' }}
        className={`${ICON_SKILL} text-muted mr-2`}
      />
      {name}
    </div>
  );
};

export default NameCell;
