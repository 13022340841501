import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../../actions/Modals';
import { setSideSheet } from '../../../../actions/SideSheets';
import { MODAL_KEY_RESOURCE_PREVIEW } from '../../../../constants/modals';
import { SHEET_KEY_MATERIAL } from '../../../../constants/sideSheets';
import { getResourceMaterial } from '../../../../actions/Materials';

const PageNumberCell = ({ row, value: pageNumber }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Resource
  const id = row?.original?.resource?.id || null;
  const name = row?.original?.resource?.name || null;
  const type = row?.original?.resource?.type || null;
  const url = row?.original?.resource?.url || null;

  const onClickName = () => {
    if (type === 'material') {
      dispatch(getResourceMaterial({ resourceId: id, orgId })).then(() => {
        dispatch(
          setSideSheet({
            key: SHEET_KEY_MATERIAL,
            className: 'MaterialSideSheet'
          })
        );
      });
    } else {
      dispatch(
        setCurrentModal({
          key: MODAL_KEY_RESOURCE_PREVIEW,
          data: {
            resource: row?.original?.resource,
            modalTitle: name,
            iframeSrc: `${url}#page=${pageNumber}`
          }
        })
      );
    }
  };

  if (!pageNumber) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="button"
        onClick={onClickName}
        className="btn btn-lg btn-outline-primary d-inline-flex shadow"
        style={{
          minWidth: '30px'
        }}
        title="Preview"
        aria-label="Preview"
        data-cy="CdnFile-PageNumber"
        data-filename={pageNumber}
        data-id={id}
      >
        <span className="h6 m-0">{pageNumber}</span>
      </button>
    </div>
  );
};

export default PageNumberCell;
