import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { reactSelectDefaultValue } from '@apprentage/utils';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import { FaCheckCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { IMAGE_CARD_ASSISTANTS } from '../../../constants/assets';
import {
  ORG_CONSOLE_ASSISTANT_BASE,
  ORG_CONSOLE_ASSISTANTS
} from '../../../constants/routes';
import { getAssistants } from '../../../actions/Assistants';
import { updatePage } from '../../../services/pages';
import Switch from '../../../components/Switch';
import AssistantBadge from '../../../components/Assistants/AssistantBadge';

const AssistantBlock = ({
  title = 'Ask AI',
  text = 'Provide instant answers for visitors, powered by folders in your Resources.',
  className = '',
  onSuccessCallback
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const page = useSelector((state) => state.page);
  const assistants = useSelector((state) => state.assistants);
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [integrationId, setIntegrationId] = useState(page?.integration?.id);
  const [enableAssistant, setEnableAssistant] = useState(integrationId);

  const retrieveAssistants = useCallback(() => {
    dispatch(
      getAssistants({
        orgId,
        select: ['id', 'name', 'agentImage', 'colorBtnBkg']
      })
    );
  }, [dispatch, orgId]);

  const selectMenuOnChange = (data) => {
    updatePage(
      {
        integration: !data ? null : data?.value
      },
      page?.id
    )
      .then((response) => {
        if (response?.error) {
          errorSaveAssistant(response?.error);
          return;
        }
        if (data) {
          setIntegrationId(data?.value);
          toast.success('Assistant added to Page!');
        } else {
          setIntegrationId(null);
          setEnableAssistant(false);
          toast.info('Assistant removed from Page');
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }
      })
      .catch((error) => {
        errorSaveAssistant(error);
      });
  };

  const switchOnChange = () => {
    toast.dismiss('errorSaveAssistant');

    retrieveAssistants();
    setEnableAssistant(!enableAssistant);
  };

  const formatOptionLabel = (option) => {
    // { context } // menu | value
    return (
      <AssistantBadge
        className="py-1"
        agentImage={option?.image}
        name={option?.label}
        colorBtnBkg={option?.colorBtnBkg}
      />
    );
  };

  const hasAssistants = useMemo(() => {
    return Array.isArray(assistants?.list) && assistants?.list.length > 0;
  }, [assistants?.list]);

  const options = useMemo(() => {
    if (hasAssistants) {
      return assistants?.list.map((a) => {
        return {
          value: a?.id,
          label: a?.name,
          image: a?.agentImage,
          colorBtnBkg: a?.colorBtnBkg
        };
      });
    }

    return [];
  }, [assistants?.list, hasAssistants]);

  const defaultValue = useMemo(() => {
    return reactSelectDefaultValue(
      integrationId || page?.integration?.id,
      options
    );
  }, [integrationId, options, page?.integration?.id]);

  const errorSaveAssistant = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.', {
      autoClose: false,
      toastId: 'errorSaveAssistant'
    });
    setEnableAssistant(false);
  };

  useEffect(() => {
    retrieveAssistants();
  }, [retrieveAssistants]);

  return (
    <div className={className}>
      <div
        className={`card ${page?.integration ? '' : 'border border-dashed border-black'}`}
      >
        <div className="card-body d-flex align-items-start">
          <div className="d-none d-sm-block mr-3">
            <img
              className="card-img-top"
              src={IMAGE_CARD_ASSISTANTS}
              alt="Chatbot"
              style={{
                width: '300px'
              }}
            />
          </div>
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="font-weight-bold mb-0">{title}</h5>

              {integrationId ? (
                <Link
                  className="btn btn-white"
                  to={`${ORG_CONSOLE_ASSISTANT_BASE}/${integrationId}`}
                  target="_blank"
                  style={{
                    paddingTop: '.25rem',
                    paddingBottom: '.25rem'
                  }}
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <span className="mr-2">Settings</span>
                    <FaExternalLinkAlt size={12} />
                  </span>
                </Link>
              ) : (
                <Switch
                  id="enableAssistant"
                  label="Enable"
                  value={enableAssistant}
                  onChange={switchOnChange}
                />
              )}
            </div>

            <div>
              {enableAssistant ? (
                <div>
                  <SelectMenu
                    key={defaultValue}
                    defaultValue={defaultValue}
                    options={options}
                    onChange={selectMenuOnChange}
                    placeholder="Choose Assistant"
                    formatOptionLabel={formatOptionLabel}
                    isClearable
                  />

                  {integrationId && (
                    <span className="d-flex align-items-center mt-2">
                      <span className="h6 mb-0 ml-2 d-flex align-items-center">
                        <FaCheckCircle
                          size={10}
                          className="text-keppel"
                        />

                        <span className="badge">Enabled</span>
                      </span>
                    </span>
                  )}

                  {!hasAssistants && (
                    <div className="mt-3">
                      <Link
                        className="btn-link mr-1"
                        to={ORG_CONSOLE_ASSISTANTS}
                        target="_blank"
                        onClick={() => {
                          setEnableAssistant(false);
                        }}
                      >
                        Create an Assistant
                      </Link>
                      to boost Page engagement with Ask AI.
                    </div>
                  )}
                </div>
              ) : (
                <p className="card-text">{text}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantBlock;
