import { toast } from 'react-toastify';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { RECORDS_PAGINATION_LIMIT } from '../constants/api';
import heapAnalytics from '../utils/heapAnalytics';
import { dateTimestamp } from '../utils/date';

const table = 'logbookRecords';

export const calculateLogbookRecordsHours = (records) => {
  if (!records || (Array.isArray(records) && records.length === 0)) {
    return 0;
  }

  return records.reduce((acc, curr) => {
    return acc + curr?.hours;
  }, 0);
};

export const recordManageApproval = ({
  recordId,
  isApproved,
  orgId,
  currentUserName,
  currentUserId
}) => {
  return new Promise((resolve, reject) => {
    if (!recordId) {
      heapAnalytics.track('Error - Record - Approve - No ID', {
        recordId,
        isApproved,
        orgId,
        currentUserName,
        currentUserId
      });
      toast.error('Something went wrong, please try again (No Record ID)', {
        autoClose: false
      });
      document.location.reload();
      return;
    }

    if (!orgId) reject(new Error('No OrgId'));
    if (isApproved && !currentUserName) reject(new Error('No Approver Name'));
    if (isApproved && !currentUserId) reject(new Error('No Approver ID'));

    const dataToSave = {};

    if (isApproved) {
      dataToSave.isApproved = isApproved;
      dataToSave.approvedByName = currentUserName;
      dataToSave.approvedByUserId = currentUserId;
      dataToSave.approvedAt = dateTimestamp();
    } else {
      dataToSave.isApproved = null;
      dataToSave.approvedByName = null;
      dataToSave.approvedByUserId = null;
      dataToSave.approvedAt = null;
    }

    const trackingMessage = isApproved
      ? 'Record - Approve - RecordsList'
      : 'Record - Unapproved - RecordsList';

    heapAnalytics.track(trackingMessage, {
      ...dataToSave,
      recordId
    });

    updateLogbookRecord(dataToSave, recordId)
      .then(() => {
        toast.success(
          isApproved ? 'Logbook Record approved!' : 'Logbook Record Unapproved!'
        );
        resolve();
      })
      .catch((error) => {
        heapAnalytics.track('Error - Record - Approve - Catch', {
          recordId,
          error: JSON.stringify(error)
        });
        toast.error('Something went wrong, please try again.', {
          autoClose: false
        });
        reject();
      });
  });
};

export const recordApproveMultiple = async ({
  records = [],
  currentUserName,
  currentUserId
}) => {
  return new Promise((resolve, reject) => {
    if (!records || (Array.isArray(records) && records.length === 0)) {
      reject();
      return;
    }

    const dataToSave = {
      isApproved: true,
      approvedByName: currentUserName,
      approvedByUserId: currentUserId,
      approvedAt: dateTimestamp()
    };

    // Build Promises to upload resources to Supabase
    const promises = records.map(async (record) => {
      return [await updateLogbookRecord(dataToSave, record?.id)];
    });

    if (Array.isArray(promises) && promises.length !== 0) {
      Promise.all(promises)
        .then((responses) => {
          resolve(responses);
        })
        .catch((error) => {
          console.error(error);
          throw new Error(error);
        });
    } else {
      throw new Error('Error approving multiple records.');
    }
  });
};

/**
 * @param {Array} ids
 * @param {String} orgId
 */
export const fetchLogbookRecords = ({
  ids = [],
  limit = RECORDS_PAGINATION_LIMIT,
  page = 1,
  order = '-date',
  count = undefined,
  logbookId = undefined,
  isApproved = undefined,
  approvedByUserId = undefined,
  gteDate = undefined,
  userId = undefined,
  competencyId = undefined,
  apprenticeshipId = undefined,
  locationId = undefined,
  orgId = undefined
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      page,
      limit
    };

    if (count !== undefined) {
      config.count = count;
    }

    if (gteDate !== undefined) {
      config['f.date[gte]'] = gteDate; // '2023-09-06T02:11:52.844Z'
    }

    if (isApproved !== undefined) {
      config['f.isApproved[is]'] = isApproved;
    }

    if (approvedByUserId !== undefined) {
      config['f.approvedByUserId[eq]'] = approvedByUserId;
    }

    if (competencyId !== undefined) {
      config['f.competencyIds[ov]'] = competencyId;
    }

    if (apprenticeshipId !== undefined) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (logbookId !== undefined) {
      config['f.logbookId[eq]'] = logbookId;
    }

    if (locationId !== undefined) {
      config['f.locationId[eq]'] = locationId;
    }

    if (userId !== undefined) {
      config['f.userId[eq]'] = userId;
    }

    if (orgId !== undefined) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`fetch ${table}`, error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchLogbookRecord = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const logbook = response || null;

        resolve(logbook);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const createLogbookRecord = (data) => {
  return new Promise((resolve) => {
    createSupabaseEntry({ data, table })
      .then((response) => {
        const id = response?.id || null;

        resolve(id);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const updateLogbookRecord = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const deleteLogbookRecord = (id) => {
  return new Promise((resolve) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`delete ${table}`, error);
      });
  });
};
