import React, { useMemo } from 'react';
import Table from './Table';
import TitleCell from './TitleCell';
import AssistantCell from './AssistantCell';
import InactiveCell from './InactiveCell';
import EnablePageContentCell from './EnablePageContentCell';

const List = ({ data, handleClickRow }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: TitleCell
      },
      {
        Header: 'Type',
        accessor: 'enablePageContent',
        Cell: EnablePageContentCell
      },
      {
        Header: 'Access',
        accessor: 'inactive',
        Cell: InactiveCell
      },
      {
        Header: 'Assistant',
        accessor: 'assistantCell',
        Cell: AssistantCell
      }
    ],
    []
  );

  const hiddenColumns = useMemo(() => {
    const cols = [];

    return cols;
  }, []);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Pages.</div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
