import {
  SET_E_UNIT_TRACKING,
  RESET_E_UNIT_TRACKING,
  SET_E_UNIT_TRACKING_FILES
} from '../../actions/types';
import eUnitTrackingState from '../initialState/eUnitTrackingState';

const initialState = {
  ...eUnitTrackingState,
  sessionCredits: null,
  cdnFiles: null
};

export default function eUnitTracking(state = initialState, action) {
  switch (action.type) {
    case SET_E_UNIT_TRACKING:
      return {
        ...state,
        ...action.eUnitTracking,
        sessionCredits: action.sessionCredits,
        cdnFiles: action.cdnFiles
      };
    case SET_E_UNIT_TRACKING_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_E_UNIT_TRACKING:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
