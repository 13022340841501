import { NOTIFICATIONS_PAGINATION_LIMIT } from '../constants/api';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'notifications';

export const fetchNotifications = async ({
  orgId,
  locationId,
  classId = null,
  select,
  isDraft = null,
  isPinned = null,
  page = 1,
  order = '-createdAt',
  limit = NOTIFICATIONS_PAGINATION_LIMIT
}) => {
  const params = {
    page,
    order,
    limit
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (locationId) {
    params['f.locationId[eq]'] = locationId;
  }

  if (classId === null) {
    params['f.classId[is]'] = null;
  } else if (classId !== '') {
    params['f.classId[eq]'] = classId;
  }

  if (isDraft === null) {
    params['f.isDraft[is]'] = null;
  } else if (isDraft !== '') {
    params['f.isDraft[eq]'] = isDraft;
  }

  if (isPinned === null) {
    params['f.isPinned[is]'] = null;
  } else if (isPinned !== '') {
    params['f.isPinned[eq]'] = isPinned;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchNotification = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateNotification = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((updatedResource) => {
        resolve(updatedResource);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createNotification = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((newResource) => {
        resolve(newResource);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteNotification = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((deletedResource) => {
        resolve(deletedResource);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
