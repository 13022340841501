import React from 'react';
import FolderInfo from '../../ManageContent/FolderInfo';

const Header = ({
  style = {},
  numCandidates,
  resourceGroupTitle,
  jobTitle,
  hasFiles,
  hasEmbeddedFiles,
  embeddedFiles
}) => {
  return (
    <div
      className="mb-3"
      style={style}
    >
      <h5>
        Identify the top{' '}
        <strong>
          {numCandidates > 1 && numCandidates} Candidate
          {numCandidates > 1 && 's'}
        </strong>
        {jobTitle ? ' for ' : ' '}
        {jobTitle ? <strong>{jobTitle}</strong> : ' '} from{' '}
        <strong>Resumes</strong> in:
      </h5>

      <div className="shadow p-3 py-4 border">
        <FolderInfo
          className="d-flex align-items-center justify-content-center"
          resourceGroupTitle={resourceGroupTitle}
          hasEmbeddedFiles={hasEmbeddedFiles}
          embeddedFiles={embeddedFiles}
          hasFiles={hasFiles}
        />
      </div>
    </div>
  );
};

export default Header;
