import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProjects } from '../../../actions/Projects';
import { ORG_CONSOLE_PROJECT_BASE } from '../../../constants/routes';
import Loading from '../../Loading';
import List from './List';

const ProjectsList = ({
  list,
  listConfig,
  onClick,
  showCreatedAtCol = false,
  showLearningModulesCol = false,
  showSkillsCol = false
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const projects = useSelector((state) => state.projects);
  // misc
  const orgId = organization?.id || '';

  const handleClickRow = (project) => {
    if (onClick) {
      return onClick(project);
    }

    history.push(`${ORG_CONSOLE_PROJECT_BASE}/${project.id}`);
  };

  useEffect(() => {
    if (orgId && listConfig) {
      dispatch(getProjects(listConfig));
    }
  }, [dispatch, listConfig, orgId]);

  if (!listConfig && !list) {
    return null;
  }

  if (listConfig && !projects.fetched && !projects.list) {
    return <Loading text="Loading Projects..." />;
  }

  return (
    <div className="projects-list">
      <List
        handleClickRow={handleClickRow}
        data={list || projects?.list}
        pagination={projects?.pagination}
        showCreatedAtCol={showCreatedAtCol}
        showLearningModulesCol={showLearningModulesCol}
        showSkillsCol={showSkillsCol}
      />
    </div>
  );
};

export default ProjectsList;
