export const goToElem = (id = '') => {
  const elem = document.getElementById(id);

  if (elem) {
    elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const scrollElem = (id, scrollTop = 0) => {
  const elem = document.getElementById(id);

  if (elem) {
    document.getElementById(id).scrollTop = scrollTop;
  }
};
