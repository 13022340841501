import React, { useCallback, useEffect, useState } from 'react';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { Redirect, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_DEFAULT_LAYOUT,
  DASHBOARD_DEFAULT_ZONES
} from '../../constants/dashboard';
import { withAuthorization } from '../../components/Session';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_DASHBOARD_CHANGE_LAYOUT } from '../../constants/modals';
import {
  fetchDashboardByType,
  updateDashboardData
} from '../../services/dashboards';
import { SLASH } from '../../constants/routes';
import { canManageOrgDashboard } from '../../permissions/manage';
import Loading from '../../components/Loading';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import DraggableWidgetsDashboard from '../../components/Dashboard/DraggableWidgetsDashboard';

const DashboardConfig = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = currentUser?.role || [];
  // Local State
  const [isLoading, setIsLoading] = useState(true);

  const [dashboardData, setDashboardData] = useState({});
  const [zones, setZones] = useState(DASHBOARD_DEFAULT_ZONES);

  const defaultLayout = dashboardData?.activeLayout || DASHBOARD_DEFAULT_LAYOUT;
  const [activeLayout, setActiveLayout] = useState(defaultLayout);
  const [selectedLayout, setSelectedLayout] = useState(defaultLayout);

  const getDashboardData = useCallback(() => {
    fetchDashboardByType({ orgId }).then((response) => {
      setIsLoading(false);
      if (response !== null) {
        setZones((prevZones) => ({
          ...prevZones,
          zone1: response?.zone1 || [],
          zone2: response?.zone2 || [],
          zone3: response?.zone3 || []
        }));
        setDashboardData({
          ...response
        });
        setActiveLayout(response?.activeLayout);
        setSelectedLayout(response?.activeLayout);
      }
    });
  }, [orgId]);

  const onClickSave = (zonesData) => {
    updateDashboardData(
      {
        ...zonesData,
        ...(selectedLayout !== activeLayout
          ? { activeLayout: selectedLayout }
          : {})
      },
      dashboardData.id
    ).then((response) => {
      if (response?.id) {
        history.push(SLASH);
      }
    });
  };

  const onClickCancel = () => {
    history.push(SLASH);
  };

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  if (!canManageOrgDashboard(role)) {
    return <Redirect to={SLASH} />;
  }

  return (
    <div className="container my-3">
      {isLoading && <Loading className="relative" />}
      <div className="row">
        <OrgConsoleHeader
          pageTitle="Configure Dashboard"
          route={SLASH}
          className="mb-2 mb-md-4 w-100"
        >
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            disabled={isLoading}
            onClick={() => {
              dispatch(
                setCurrentModal({
                  key: MODAL_KEY_DASHBOARD_CHANGE_LAYOUT,
                  data: {
                    activeLayout,
                    selectedLayout
                  },
                  callbackPrimaryAction: setSelectedLayout
                })
              );
            }}
          >
            <span className="d-flex align-items-center">
              <RiLayoutMasonryFill />
              <span className="ml-2">Change Layout</span>
            </span>
          </button>
        </OrgConsoleHeader>

        {!isLoading && (
          <DraggableWidgetsDashboard
            defaultZones={zones}
            selectedLayout={selectedLayout}
            onClickSave={onClickSave}
            onClickCancel={onClickCancel}
          />
        )}
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition))(DashboardConfig);
