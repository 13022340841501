import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchPageLink, fetchPageLinks } from '../services/pageLinks';
import {
  SET_ENTRY_ORG_ID,
  SET_PAGE_LINK,
  SET_PAGE_LINKS,
  RESET_PAGE_LINK,
  RESET_PAGE_LINKS
} from './types';

export const getPageLinks = ({
  orgId,
  locationId,
  inactive = null,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt',
  name = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchPageLinks({
        locationId,
        orgId,
        inactive,
        page,
        limit,
        order,
        name
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_PAGE_LINKS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getPageLink = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchPageLink(id)
        .then((pageLink) => {
          if (!pageLink?.id) {
            reject(new Error('No Page found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: pageLink?.orgId || null
          });

          dispatch({
            type: SET_PAGE_LINK,
            pageLink: pageLink?.id ? { ...pageLink } : null
          });
          resolve(pageLink);
        })
        .catch((error) => {
          console.error('get pageLink', error);
          reject(error);
        });
    });
  };
};

export const resetPageLink = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE_LINK });
  };
};

export const resetPageLinks = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE_LINKS });
  };
};
