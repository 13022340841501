import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from './Form';
import Loading from '../Loading';
import Breadcrumb from '../Console/Breadcrumb';
import CdnFiles from '../ManageContent/CdnFiles';
import DeleteTopic from '../ManageContent/DeleteContent/DeleteTopic';
import withConsole from '../App/withConsole';
import { setCurrentTopic } from '../../actions/Topic'; // resetCurrentTopic,

const Topic = () => {
  const dispatch = useDispatch();
  // Redux
  const currentTopic = useSelector((state) => state.currentTopic);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const topicId = queryParams.get('topicId');

  useEffect(() => {
    if (topicId) {
      dispatch(setCurrentTopic({ topicId }));
    }

    // return function cleanup() {
    //   dispatch(resetCurrentTopic());
    // }
  }, [dispatch, topicId]);

  if (topicId && !currentTopic.id) {
    return <Loading text="Loading Topic..." />;
  }

  return (
    <>
      <Breadcrumb
        className="my-3"
        disableTopicLink
        // currentPageTitle={currentTopic.title}
      />

      <Form />

      {currentTopic.id && (
        <DeleteTopic
          id={currentTopic.id}
          title={currentTopic.title}
          sections={currentTopic.sections}
        />
      )}

      {currentTopic.id && (
        <CdnFiles
          id="topic-integration-files"
          contentId={currentTopic.id}
          contentType="topic"
          files={currentTopic.cdnFiles}
          // fileUpload
          editMenu
        />
      )}
    </>
  );
};

export default withConsole(Topic);
