import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// import { toast } from 'react-toastify';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { toast } from 'react-toastify';
import { FaClock, FaTimes } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
// import { updateApprenticeship } from '../../../services/apprenticeships';
// import { getApprenticeship } from '../../../actions/Apprenticeships';
import Modal from '../../Modal';
import KeyValueItem from '../../KeyValueItem';
import { dateTimestamp } from '../../../utils/date';
import makeInitials from '../../../utils/makeInitials';
import CalendarDatePicker from '../../ManageContent/CalendarDatePicker';
import { isSuperAdmin } from '../../../services/currentUser';
import { updateLogbook } from '../../../services/logbooks';
import { getLogbook } from '../../../actions/Logbooks';

const Container = styled.div`
  background: none;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  min-width: initial;

  i {
    font-size: 1.1rem;
  }
`;

const CompetencyInitial = () => {
  const dispatch = useDispatch();
  // redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  // Current Modal
  const modalTitle = currentModal?.data?.modalTitle || 'Review Competence';
  // Organization
  const orgId = organization?.id || '';
  // Competency
  const competency = currentModal?.data?.competency || {};
  const competencyId = currentModal?.data?.competencyId || {};
  const hoursEarned = currentModal?.data?.hoursEarned || null;
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || {};
  const userCompetenciesHash = apprenticeship?.userCompetenciesHash || null;
  const userTotalHours =
    userCompetenciesHash && competencyId
      ? userCompetenciesHash[competencyId]?.totalHours
      : undefined;
  // Logbook
  const completedCompetencies = logbook?.completedCompetencies || {};
  // Current User
  const role = currentUser?.role || [];

  // Local State
  const [loading, setLoading] = useState(false);
  const [completedAt, setCompletedAt] = useState(new Date().toISOString());
  const [competencyApproved, setCompetencyApproved] = useState(false);

  if (!currentModal?.visible) {
    return;
  }

  if (!competency?.id || !logbook?.id) {
    toast.error('Something went wrong, try again.');
    handleClose();
    return;
  }

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const dataToSave = {
      completedCompetencies: {
        ...completedCompetencies,
        [competency?.id]: {
          approvedAt: dateTimestamp(),
          approvedByName: currentUser?.name,
          approvedByUserId: currentUser?.id,
          completedAt,
          approvedHours: hoursEarned
        }
      }
    };

    toast.info('Approving competency...');
    updateLogbook(dataToSave, logbook?.id)
      .then(() => {
        dispatch(
          getLogbook({
            id: logbook?.id,
            orgId,
            logbookHours: true,
            logbookCompHours: true
          })
        ).then(() => {
          toast.success('Competency Approved!');
          handleClose();
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    // approvedDate:  '2023-02-08T19:57:27.741Z'
    // approvedUserId: '7lERnrDtYeURyYNjKrWGRI'
    // approvedUserName: 'Alan Mabry'
  };

  const initials = makeInitials(
    `${currentUser?.firstName} ${currentUser?.lastName}`
  );

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark d-flex align-items-center justify-content-between">
          <h1 className="h5 m-0 text-white">{modalTitle}</h1>
          <button
            title="Close"
            aria-label="Close"
            type="button"
            className="close text-white"
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </div>

        <form
          onSubmit={onSubmit}
          className="position-relative"
        >
          <div className="card-body">
            <div className="d-flex justify-content-between mb-2">
              <div className="font-weight-bold pl-2">Competency</div>
              <div className="font-weight-bold pr-2">Target Hours</div>
            </div>
            <div className="shadow rounded border p-2 d-flex justify-content-between">
              <div className="pr-2">
                <div>{competency?.description}</div>
                {userTotalHours && (
                  <div className="d-flex align-items-center mt-2">
                    <FaClock className="text-primary mr-1" />
                    <div>User specific Target Hours</div>
                  </div>
                )}
              </div>
              <div className="rounded p-3">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="px-2 py-1 bg-light border rounded">
                    {userTotalHours || competency?.totalHours}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3 mb-2">
              <div className="font-weight-bold pl-2">Trainee</div>
              <div className="font-weight-bold pr-2">Hours Accrued</div>
            </div>
            <div className="shadow rounded border p-2 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={
                    apprenticeshipUser?.profilePicture || DEFAULT_PROFILE_PIC
                  }
                  alt={apprenticeshipUser?.name}
                  height={40}
                  style={{
                    height: '40px'
                  }}
                />
                <div className="ml-2">
                  {apprenticeshipUser?.firstName}
                  <br />
                  {apprenticeshipUser?.lastName}
                </div>
              </div>
              <div className="rounded p-3">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="px-2 py-1 bg-light border rounded">
                    {hoursEarned}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-3 border p-2 rounded">
              <Container className="d-flex align-items-center text-ships-officer mb-3">
                <div
                  className="custom-control custom-switch d-flex align-items-center"
                  // style={{ minHeight: 'auto', height: '1rem' }}
                >
                  <input
                    data-cy="courses-expanded-switch"
                    type="checkbox"
                    className="custom-control-input"
                    id="courses-expanded-switch"
                    value={competencyApproved}
                    onClick={(e) => {
                      const { checked } = e.currentTarget;

                      setCompetencyApproved(!!checked);
                    }}
                    checked={competencyApproved}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="courses-expanded-switch"
                  >
                    Trainee performs task properly, consistently and completed
                    final demonstration of competency.
                  </label>
                </div>
              </Container>

              {competencyApproved && (
                <>
                  <div className="mb-2">
                    <label
                      htmlFor="completedAt"
                      className="d-block font-weight-bold mb-0"
                    >
                      Completion Date <span className="text-danger">*</span>
                    </label>

                    <CalendarDatePicker
                      selected={completedAt ? new Date(completedAt) : null}
                      id="completedAt"
                      name="completedAt"
                      className="form-control"
                      placeholderText="MM/DD/YYYY"
                      onChange={(date) => {
                        setCompletedAt(date.toISOString());
                      }}
                      dropdownMode
                      // inline
                      // timeInputLabel="Time:"
                      // dateFormat="MM/dd/yyyy"
                      // showTimeInput
                      required
                    />

                    {/* <div>
                      Date trainee completed final demonstration of competency.
                    </div> */}
                  </div>

                  <div className="d-flex justify-content-between mt-3 mb-2">
                    <div className="font-weight-bold pl-2">Approver</div>
                    <div className="font-weight-bold pr-2">Initials</div>
                  </div>
                  <div className="shadow rounded border p-2 d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                      <img
                        src={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
                        alt={currentUser?.name}
                        height={40}
                        style={{
                          height: '40px'
                        }}
                      />
                      <div className="ml-2">
                        {currentUser?.firstName}
                        <br />
                        {currentUser?.lastName}
                      </div>
                    </div>
                    <div className="border p-3">
                      <i>{initials}</i>
                    </div>
                  </div>
                </>
              )}
            </div>

            {isSuperAdmin(role) && competency?.id && (
              <div className="d-flex justify-content-end mt-3">
                <small>
                  <KeyValueItem
                    title="ID"
                    value={competency?.id}
                  />
                </small>
              </div>
            )}
          </div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading || !competencyApproved}
            >
              {loading ? 'Approving...' : 'Initial & Approve'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CompetencyInitial;
