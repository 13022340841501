import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';
import UserCell from './UserCell';
import QuizCell from './QuizCell';
import ScoreCell from './ScoreCell';
// import TotalPointsCell from './TotalPointsCell';
// import AttemptsCell from './AttemptsCell';
// import DifficultyCell from './DifficultyCell';
import PercentageCell from './PercentageCell';
import DateCell from '../ManageContent/Tables/DateCell';
import ReviewCheckboxCell from '../ManageContent/Tables/ReviewCheckboxCell';
import OutcomeCell from './OutcomeCell';

const QuizOutcomesList = ({
  className = '',
  list,
  userId,
  quizId,
  reviewActive = false,
  showUserNameAsLink = false,
  showQuizTitleAsLink = false,
  showOutcomeButton = false
}) => {
  // Redux
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  // Course
  const classId = course?.id || null;
  // Quiz
  const currentQuizId = currentQuiz?.id || null;

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (currentQuizId) {
      cols.push('quizId');
    }

    if (!reviewActive) {
      cols.push('attempts');
    }

    if (!showOutcomeButton) {
      cols.push('id');
    }

    return cols;
  }, [currentQuizId, reviewActive, showOutcomeButton]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return (
            <input
              type="checkbox"
              checked
              readOnly
            />
          );
        },
        accessor: 'attempts',
        disableSortBy: true,
        Cell: (props) => <ReviewCheckboxCell {...props} />
      },
      {
        Header: 'Submitted',
        accessor: 'createdAt',
        Cell: (props) => <DateCell {...props} />
      },
      {
        Header: 'Score',
        accessor: 'score',
        Cell: (props) => <ScoreCell {...props} />
      },
      {
        Header: '',
        accessor: 'updatedAt', // react-table hack to use random accessor so there are no dupes
        disableSortBy: true,
        Cell: (props) => <PercentageCell {...props} />
      },
      // {
      //   Header: 'Attempts',
      //   accessor: 'attempts',
      //   Cell: (props) => (
      //     <AttemptsCell {...props} />
      //   )
      // },
      // {
      //   Header: 'Difficulty',
      //   accessor: 'difficulty',
      //   disableSortBy: true,
      //   Cell: (props) => (
      //     <DifficultyCell {...props} />
      //   )
      // },
      // {
      //   Header: 'Total Points',
      //   accessor: 'maxScore',
      //   Cell: (props) => (
      //     <TotalPointsCell {...props} />
      //   )
      // },
      {
        Header: 'User',
        accessor: 'userName',
        Cell: (props) => (
          <UserCell
            {...props}
            classId={classId}
            quizId={quizId}
            showUserNameAsLink={showUserNameAsLink}
          />
        )
      },
      {
        Header: 'Quiz',
        accessor: 'quizId',
        disableSortBy: !!userId,
        Cell: (props) => (
          <QuizCell
            {...props}
            classId={classId}
            showQuizTitleAsLink={showQuizTitleAsLink}
          />
        )
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: (props) => <OutcomeCell {...props} />
      }
    ],
    [classId, quizId, userId, showQuizTitleAsLink, showUserNameAsLink]
  );

  if (!list || (Array.isArray(list) && list.length === 0)) {
    return <div className="p-4">No Quiz Outcomes.</div>;
  }

  return (
    <div className={className}>
      <Table
        columns={columns}
        data={list}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
};

export default QuizOutcomesList;
