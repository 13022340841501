import React from 'react';
import { useDispatch } from 'react-redux';
import { FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import { updateSideSheet } from '../../../actions/SideSheets';
import FolderInfo from '../../ManageContent/FolderInfo';
import InputFakeLoader from '../../ManageContent/InputFakeLoader';

const ManualOrAutoInput = ({
  setCurrentScreen,
  publicUrl,
  setPublicUrl,
  loading,
  resourceGroupTitle,
  hasFiles,
  hasEmbeddedFiles,
  embeddedFiles
}) => {
  const dispatch = useDispatch();

  const onClickManual = () => {
    dispatch(
      updateSideSheet({
        className: 'MaterialSideSheet card-style'
      })
    );
    setCurrentScreen('jobInfo');
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-3">
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">Step 1</span>
                <span className="mx-2 font-weight-bold">Resumes</span>
                <FaCheckCircle
                  className="text-keppel"
                  size={15}
                />
              </span>
            </h5>
            <div className="shadow border p-3 rounded">
              <FolderInfo
                className="d-flex align-items-center justify-content-center"
                resourceGroupTitle={resourceGroupTitle}
                hasEmbeddedFiles={hasEmbeddedFiles}
                embeddedFiles={embeddedFiles}
                hasFiles={hasFiles}
              />
            </div>
          </div>

          <div className="mb-3">
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">Step 2</span>
                <span className="ml-2 font-weight-bold">Job Details</span>
              </span>
            </h5>
            <div className="shadow border p-3 rounded">
              <div style={{ fontSize: '1.1rem' }}>
                Enter the public URL of your job posting and{' '}
                <strong>VELA</strong> will enter all the Job details in a few
                seconds.
              </div>

              <div className="my-4">
                <div className="font-weight-bold mb-1">
                  Public Job Posting URL:
                </div>
                <div>
                  <InputFakeLoader
                    loading={loading}
                    size="lg"
                  >
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      value={publicUrl}
                      placeholder="https://..."
                      onChange={(e) => {
                        const { value } = e.currentTarget;
                        setPublicUrl(value);
                      }}
                      autoFocus
                    />
                  </InputFakeLoader>
                </div>
                <div
                  className="text-muted mt-1"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Ex: https://boards.greenhouse.io/company/jobs/1234567
                </div>
              </div>
            </div>
            <div className="mt-2 shadow border p-3 rounded">
              <button
                type="button"
                onClick={onClickManual}
                className="btn-link w-100"
                disabled={loading}
              >
                <span className="d-flex align-items-center justify-content-between">
                  <span>Enter Job Details manually</span>
                  <FaChevronRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualOrAutoInput;
