import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import NumberStat from '../../../../NumberStat';
import { getOccupationWages } from '../../../../../services/wageSchedules';
import { fetchWageSchedules } from '../../../../../services/employers';

const WageSchedules = ({ className = '' }) => {
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const userLocation = useSelector((state) => state.userLocation);
  const [loading, setLoading] = useState(false);

  const [wagesDetails, setWagesDetails] = useState({
    stateWageDetails: { median: 0, areaName: '' },
    nationWageDetails: { median: 0, areaName: '' }
  });

  const [employersWageDetail, setEmployersWageDetail] = useState({
    wage: 0,
    percentDifferenceInWage: 0
  });

  const getHourlyWageDetails = (wageList) => {
    const wageDetails = wageList?.filter((wage) => {
      return wage.RateType === 'Hourly';
    })[0];
    if (wageDetails) {
      return {
        median:
          Number(wageDetails.Median) % 1 === 0
            ? Number(wageDetails.Median)
            : Number(wageDetails.Median).toFixed(2),
        areaName: wageDetails.AreaName
      };
    }
    return { median: 0, areaName: '' };
  };

  const getOccupationWagesDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getOccupationWages({
        keyword: apprenticeship?.occupationONetCode,
        location: userLocation.addressPostalCode
      });

      // Notes: States and Nation median are returned in string format
      const response = await fetchWageSchedules({
        apprenticeshipId: apprenticeship?.id || ''
      });
      if (response?.length) {
        const averageWage = response.reduce((avg, currValue, _, { length }) => {
          return avg + currValue.entryWage / length;
        }, 0);
        setEmployersWageDetail((prevState) => ({
          ...prevState,
          wage:
            averageWage % 1 === 0 ? averageWage : Number(averageWage).toFixed(2)
        }));
      } else {
        setEmployersWageDetail((prevState) => ({
          ...prevState,
          wage: 0
        }));
      }
      setWagesDetails({
        stateWageDetails: getHourlyWageDetails(res.wages?.StateWagesList || []),
        nationWageDetails: getHourlyWageDetails(
          res.wages?.NationalWagesList || []
        )
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [
    apprenticeship?.id,
    apprenticeship?.occupationONetCode,
    userLocation.addressPostalCode
  ]);

  const getMoreDetails = (empWage, stateWage) => {
    if (stateWage === 0) return '';
    if (empWage !== stateWage && empWage > stateWage) {
      return `${employersWageDetail.percentDifferenceInWage}% above state wage.`;
    }
    if (empWage !== stateWage && empWage < stateWage) {
      return `${employersWageDetail.percentDifferenceInWage}% below state wage.`;
    }
    return '';
  };

  useEffect(() => {
    getOccupationWagesDetails();
  }, [getOccupationWagesDetails]);

  useEffect(() => {
    if (wagesDetails?.stateWageDetails?.median && employersWageDetail.wage) {
      const stateWage = wagesDetails?.stateWageDetails?.median;
      const empWage = employersWageDetail.wage;
      const diffInEmployerWages = Math.floor(
        ((empWage - stateWage) / stateWage) * 100
      );
      setEmployersWageDetail((prevState) => ({
        ...prevState,
        percentDifferenceInWage: diffInEmployerWages
      }));
    }
  }, [wagesDetails, employersWageDetail.wage]);

  return (
    <div
      className={`shadow p-4 pt-2 rounded rounded-2 border border-1 ${className}`}
    >
      <div className="h4 mb-3 mb-md-4 mb-lg-5">Wage Schedules</div>

      {loading && <WageScheduleSkeletonLoader />}

      {!loading && (
        <div>
          {employersWageDetail.wage > 0 && (
            <NumberStat
              num={`$${employersWageDetail?.wage}`}
              numTitle="Average Current Wage"
              size="lg"
              moreDetail={getMoreDetails(
                employersWageDetail.wage,
                wagesDetails.stateWageDetails.median
              )}
              className="mb-2 mb-sm-0"
            />
          )}

          {employersWageDetail.wage === 0 &&
            (wagesDetails?.stateWageDetails.median !== 0 ||
              wagesDetails?.stateWageDetails.median !== 0) && (
              <div className="d-flex flex-column flex-sm-row justify-content-between">
                <NumberStat
                  num={`$${wagesDetails?.stateWageDetails.median}`}
                  numTitle={wagesDetails?.stateWageDetails.areaName}
                  size="lg"
                  moreDetail=""
                  className="mb-2 mb-sm-0"
                />
                <NumberStat
                  num={`$${wagesDetails?.nationWageDetails.median}`}
                  numTitle={wagesDetails?.nationWageDetails.areaName}
                  size="lg"
                  moreDetail=""
                />
              </div>
            )}

          {employersWageDetail.wage === 0 &&
            wagesDetails?.stateWageDetails.median === 0 &&
            wagesDetails?.stateWageDetails.median === 0 && (
              <NumberStat
                num="$0.00"
                numTitle="Current Wage"
                moreDetail="(Add an employer to view wage data)"
                className="mb-2 mb-sm-0"
              />
            )}
        </div>
      )}
    </div>
  );
};

export default WageSchedules;

const WageScheduleSkeletonLoader = () => {
  return (
    <div className="d-flex flex-row justify-content-between">
      <div className="mr-3 w-50">
        <h1>
          <Skeleton />
        </h1>
        <Skeleton count={2} />
      </div>
      <div className="w-50">
        <h1>
          <Skeleton />
        </h1>
        <Skeleton count={2} />
      </div>
    </div>
  );
};
