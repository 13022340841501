import {
  RESET_CURRENT_CHALLENGE,
  SET_CHALLENGE_FILES,
  SET_CURRENT_CHALLENGE
} from '../../actions/types';
import challengeState from '../initialState/challengeState';

const initialState = {
  ...challengeState,
  cdnFiles: null,
  fetched: false
};

export default function currentChallenge(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CHALLENGE:
      return {
        ...state,
        ...action.currentChallenge,
        cdnFiles: action.cdnFiles,
        fetched: true
      };
    case SET_CHALLENGE_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_CURRENT_CHALLENGE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
