import * as twApi from './tw-api';
import { WEATHER_BASE_URL } from '../constants/api';

/**
 * Get Current Weather
 * @summary retrieve current weather forecast by postalCode(s)
 * @param {Array} postalCodes // customer id
 * @returns {Promise<object>} - A list of invoices
 */
export async function fetchCurrentWeather({ postalCodes = [] }) {
  const query = {
    location: postalCodes.join(',')
  };

  try {
    const response = await twApi.get(`${WEATHER_BASE_URL}/current`, query);

    // Multiple [{ location, current }, { location, current }] || null
    let currentWeather =
      response && Array.isArray(response) && response.length ? response : null;

    if (Array.isArray(postalCodes) && postalCodes.length === 1) {
      // Single   { location, current }
      [currentWeather] = response;
    }

    return currentWeather;
  } catch (error) {
    console.error(error);
  }
}
