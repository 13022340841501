/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getResourcesByParentId } from '../../../actions/Resources';
import {
  getMaterial,
  getMaterials,
  getNoteMaterials,
  resetMaterial
} from '../../../actions/Materials';
import {
  handleOpenUserSideSheet,
  resetSideSheet,
  setSideSheet
} from '../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL_ADD } from '../../../constants/sideSheets';
import DotMenu from './DotMenu';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Form from '../../Material/Form';
import DeleteMaterial from '../../ManageContent/DeleteContent/DeleteMaterial';
import CdnFiles from '../../ManageContent/CdnFiles';
import Tabs from './Tabs';
import MaterialIcon from '../../ManageContent/MaterialIcon';
import AudioFiles from '../../ManageContent/AudioFiles';
import Users from '../../ManageContent/Users';
import './style.css';

const MaterialSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const material = useSelector((state) => state.material);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Resources
  const resourceGroups = useSelector((state) => state.resourceGroups);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  const groupIds = resourceGroups?.groupIds || null;
  // Organization
  const orgId = organization?.id || null;
  // Side Sheet
  const confirmMoveAfterCreate =
    sideSheet?.data?.confirmMoveAfterCreate || false;
  const tinyMceEditor = sideSheet?.data?.tinyMceEditor || {}; // TODO default config
  const showDelete =
    sideSheet?.data?.showDelete !== undefined
      ? sideSheet?.data?.showDelete
      : true;
  const noteType = sideSheet?.data?.noteType || null;
  const noteTypeId = sideSheet?.data?.noteTypeId || null;
  const showTitleInternal = sideSheet?.data?.showTitleInternal || false;
  // Local State
  const [editMode, setEditMode] = useState(sideSheet?.data?.editMode || false);
  const [activeTab, setActiveTab] = useState('settings');

  const onSuccessDelete = () => {
    dispatch(resetSideSheet());

    if (noteTypeId && noteType) {
      dispatch(
        getNoteMaterials({
          noteType,
          noteTypeId,
          orgId
        })
      );

      return;
    }

    // TODO: needs conditional logic
    // checking if a Materials list is visible
    // if it's not visible, there's no reason to fetch Materials
    dispatch(
      getMaterials({
        orgId,
        userId: currentUser?.id,
        limit: 5,
        isDraft: true, // TODO conditional based on resourceGroup?
        order: '-createdAt'
      })
    );
  };

  const onSuccessCreate = () => {
    dispatch(getMaterial(material?.id)).then((responseMaterial) => {
      if (confirmMoveAfterCreate) {
        // Show Materials List
        dispatch(
          setSideSheet({
            key: SHEET_KEY_MATERIAL_ADD,
            data: {
              title: 'Add Material',
              moveToResources: true,
              confirmMoveAfterCreate: true,
              material: responseMaterial,
              resourceGroup,
              groupIds
            }
          })
        );
      } else {
        setEditMode(false);
      }
    });
  };

  const onClickTeamMember = (userId) => {
    dispatch(
      handleOpenUserSideSheet({
        userId,
        orgId,
        currentUserOrgId: currentUser?.orgId
        // route,
        // history
      })
    );
  };

  const hasFiles = useMemo(() => {
    return Array.isArray(material?.cdnFiles) && material?.cdnFiles.length > 0;
  }, [material?.cdnFiles]);

  const searchParams = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);
  const paramFileId = searchParams.get('fileId');

  useEffect(() => {
    return function cleanup() {
      dispatch(resetMaterial());
      if (history.location.search.includes('fileId')) history.goBack();
    };
  }, [dispatch, history, paramFileId, searchParams]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <div
          className="h6 m-0 font-weight-bold"
          style={{
            width: '72%'
          }}
        >
          <span className="d-flex align-items-center">
            <MaterialIcon
              className="d-none d-sm-block mr-2"
              promptType={material?.promptType}
              fileUploadFlow={material?.fileUploadFlow}
            />
            <span
              className="text-nowrap"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {material?.title}
            </span>
          </span>
        </div>
        <div className="d-flex align-items-center">
          {material?.isDraft && (
            <span className="h6 mb-0 mx-2">
              <span className="badge alert-warning alert py-1 px-2 m-0">
                draft
              </span>
            </span>
          )}
          <DotMenu
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </div>
      </div>

      {editMode && (
        <Tabs
          cardStyle
          hasFiles={hasFiles}
          isDraft={material?.isDraft}
          className="pt-3"
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
        />
      )}

      <div className="card-body overflow-y-scroll">
        {editMode ? (
          <>
            <div className={activeTab === 'settings' ? 'd-block' : 'd-none'}>
              <Form
                showTitleInternal={showTitleInternal}
                contentType="materials"
                onSuccess={onSuccessCreate}
                onCancel={() => {
                  setEditMode(false);
                }}
                tinyMceEditor={tinyMceEditor}
              />

              {showDelete && material?.id && (
                <DeleteMaterial
                  contentType="materials" // supabase
                  contentId={material?.id}
                  title={material?.title}
                  onSuccess={onSuccessDelete}
                />
              )}
            </div>

            {material?.id && (
              <div className={activeTab === 'files' ? 'd-block' : 'd-none'}>
                <CdnFiles
                  id="material-integration-files"
                  contentId={material?.id}
                  contentType="materials" // supabase
                  files={material?.cdnFiles}
                  editMenu
                  header={false}
                  removeFileCallback={() => {
                    dispatch(getResourcesByParentId(material?.id, 'materials'));
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <RenderMarkdown
              enableNewEditor
              source={material?.body}
            />
            <AudioFiles files={material?.cdnFiles} />
            {material?.id &&
              Array.isArray(material?.cdnFiles) &&
              material?.cdnFiles.length > 0 && (
                <CdnFiles
                  id="material-integration-files"
                  contentId={material?.id}
                  contentType="materials" // supabase
                  files={material?.cdnFiles}
                  // editMenu
                  // resourcePreview
                  header={false}
                  hiddenColumns={[
                    'expander',
                    'parentId',
                    'similarity',
                    'isEmbedded'
                  ]}
                  // removeFileCallback={() => {
                  //   dispatch(getResourcesByParentId(material?.id, 'materials'));
                  // }}
                />
              )}
            {material?.userId && (
              <div className="mt-4">
                <div className="mb-2 font-weight-bold">Author:</div>
                <Users
                  userIds={[material?.userId]}
                  onClick={onClickTeamMember}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MaterialSideSheet;
