import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { FaClock, FaCube, FaCubes } from 'react-icons/fa';

const CompetencyBreakdown = ({
  className = '',
  competencies,
  competencyGroups,
  ojtHours,
  tooltipContainerId
}) => {
  const totalCompetencies = useMemo(() => {
    let total = 0;

    if (Array.isArray(competencyGroups) && competencyGroups.length > 0) {
      competencyGroups.forEach((cg) => {
        if (Array.isArray(cg.competencyIds)) {
          total += cg.competencyIds.length;
        }
      });
    }

    return total;
  }, [competencyGroups]);

  const totalHours = useMemo(() => {
    let total = 0;

    if (Array.isArray(competencyGroups) && competencyGroups.length > 0) {
      competencyGroups.forEach((cg) => {
        if (Array.isArray(cg.competencyIds)) {
          cg.competencyIds.forEach((cId) => {
            total += parseInt(competencies[cId]?.totalHours, 10);
          });
        }
      });
    }

    return total;
  }, [competencies, competencyGroups]);

  return (
    <span className={`h5 m-0 d-inline-flex align-items-center ${className}`}>
      {Array.isArray(competencyGroups) && competencyGroups.length > 0 && (
        <span
          className="h6 m-0 ml-2"
          // data-tooltip-content={pluralize('Competency Group', competencyGroups.length, true)}
          // data-tooltip-id={tooltipContainerId}
          data-tip={pluralize(
            'Competency Group',
            competencyGroups.length,
            true
          )}
          data-for={tooltipContainerId}
        >
          <span className="badge bg-white border d-flex align-items-center">
            <FaCubes className="text-primary" />
            <span className="ml-1">{competencyGroups?.length}</span>
          </span>
        </span>
      )}

      {totalCompetencies > 0 && (
        <span
          className="h6 m-0 ml-2"
          // data-tooltip-content={pluralize('Competency', totalCompetencies, true)}
          // data-tooltip-id={tooltipContainerId}
          data-tip={pluralize('Competency', totalCompetencies, true)}
          data-for={tooltipContainerId}
        >
          <span className="badge bg-white border d-flex align-items-center">
            <FaCube size={10} />
            <span className="ml-1">{totalCompetencies}</span>
          </span>
        </span>
      )}

      {totalHours > 0 && (
        <span
          className="h6 m-0 ml-2"
          // data-tooltip-content={pluralize('Hour', totalHours, true)}
          // data-tooltip-id={tooltipContainerId}
          data-tip={pluralize('Competency Hour', totalHours, true)}
          data-for={tooltipContainerId}
        >
          <span className="badge bg-white border d-flex align-items-center">
            <FaClock
              size={10}
              className={
                ojtHours === totalHours && totalHours > 0
                  ? 'text-keppel'
                  : 'text-danger'
              }
            />
            <span className="ml-1">{totalHours}</span>
          </span>
        </span>
      )}
    </span>
  );
};

export default CompetencyBreakdown;
