import React from 'react';
import { FaLink, FaQrcode } from 'react-icons/fa';

const EnablePageContentCell = ({ value: enablePageContent }) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ height: '40px' }}
    >
      {enablePageContent ? (
        <>
          <FaQrcode />
          <span className="ml-1">Turbine Page</span>
        </>
      ) : (
        <span className="h6 m-0">
          <span className="badge badge-warning d-flex align-items-center">
            <FaLink className="" />
            <span className="ml-1">External</span>
          </span>
        </span>
      )}
    </div>
  );
};

export default EnablePageContentCell;
