import React from 'react';
import { useSelector } from 'react-redux';
import { IoChatbubbleEllipses } from 'react-icons/io5';

const ContextQuestionsList = ({ inputCounts, list, onClick }) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgName = organization?.name || null;
  // Local State

  if (Array.isArray(list) && list.length > 0) {
    return (
      <div>
        {list.map((question) => (
          <div
            className="row-clickable border p-3 mb-3 d-flex align-items-center justify-content-between"
            onClick={() => {
              onClick(question);
            }}
            aria-hidden
          >
            <div>{question.title.replace('{ORG_NAME}', orgName)}</div>
            <div className="d-flex align-items-center">
              <IoChatbubbleEllipses className="mr-1" />
              {inputCounts && inputCounts[question?.id] !== undefined
                ? inputCounts[question?.id]
                : 0}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default ContextQuestionsList;
