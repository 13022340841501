import { answerEnum } from '../constants/answers';
import { updateEntry } from './entry';
import formatData from './formatData';

export const updateAnswer = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'answer');

    updateEntry(preppedData, data?.answerId)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const statusFeedback = (status) => {
  if (status === 'accepted') {
    return {
      title: answerEnum[status],
      alertType: 'success',
      iconClass: 'fas fa-check'
    };
  }

  if (status === 'requestChanges') {
    return {
      title: answerEnum[status],
      alertType: 'warning',
      iconClass: 'fas fa-highlighter'
    };
  }

  if (status === 'rejected') {
    return {
      title: answerEnum[status],
      alertType: 'danger',
      iconClass: 'fas fa-flag'
    };
  }

  return {
    title: answerEnum.inReview,
    alertType: 'info',
    iconClass: 'far fa-clock'
  };
};
