import React, { useMemo } from 'react';
import {
  ICON_CHECK_CIRCLE,
  ICON_TRIANGLE_BANG
} from '@apprentage/components/dist/constants/icons';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';

const EmployerAgreementCell = ({ row }) => {
  const employerAgreements = useSelector((state) => state.employerAgreements);

  const locationId = pathOr('', ['original', 'id'], row);
  const employerAgreement = useMemo(() => {
    if (
      employerAgreements.list &&
      Array.isArray(employerAgreements.list) &&
      employerAgreements.list.length !== 0
    ) {
      return employerAgreements.list.find((eA) => eA.locationId === locationId);
    }

    return {};
  }, [employerAgreements.list]);

  if (!employerAgreement?.id) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_TRIANGLE_BANG} text-danger mr-1`} /> Incomplete
      </div>
    );
  }

  if (!employerAgreement?.signerSignature) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_TRIANGLE_BANG} text-warning mr-1`} /> Signature
        Requested
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center text-nowrap">
      <i className={`${ICON_CHECK_CIRCLE} text-success mr-1`} /> Signed
    </div>
  );
};

export default EmployerAgreementCell;
