import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { canAccessAssistants } from '../../permissions/access';
import {
  ORG_CONSOLE_INSTRUCTION_PROVIDERS,
  ORG_CONSOLE_LOCATIONS,
  ORG_CONSOLE_WORKFORCE,
  ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS
} from '../../constants/routes';
import {
  ICON_INSTITUTIONS,
  ICON_LOCATIONS,
  ICON_TRAINING_PROGRAMS,
  IMAGE_APPRENTAGE_ICON_WHITE
} from '../../constants/assets';
import {
  canAccessCourses,
  canAccessResources
} from '../../services/currentUser';
import AppsIcon from './AppsIcon';

const ApprentageApps = ({
  role = [],
  orgType,
  enableResources,
  className = ''
}) => {
  return (
    <div className={`topNav-apps-apprentage ${className}`}>
      <div className="nav-item">
        <Link
          to={ORG_CONSOLE_WORKFORCE}
          className="dropdown-item p-3 border-bottom w-100 bg-light"
          data-cy="topNavOrgLinkWorkforce"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="font-weight-bold d-flex align-items-center">
              <span
                className="rounded bg-000 mr-2 p-2 text-white d-flex align-items-center justify-content-center"
                style={{ width: '31px', height: '31px' }}
              >
                <span className="topNav-apprentage">
                  <img
                    src={IMAGE_APPRENTAGE_ICON_WHITE}
                    alt="Apprentage"
                    height={15}
                    style={{
                      height: '15px'
                    }}
                  />
                </span>
              </span>

              <span>
                <span className="d-block font-weight-bold ">Apprentage</span>
                {/* <span className='d-block font-weight-normal'>
                                On-the-job Training & Tracking
                              </span> */}
              </span>
            </div>
            <FaArrowCircleRight
              size={20}
              className="text-muted opacity-50"
            />
          </div>
        </Link>
      </div>
      <div className="topNav-apps py-3 bg-light border-bottom d-flex justify-content-around align-items-center bkg-pattern-light">
        <Link
          to={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
          className="btn btn-sm btn-white topNav-app-link"
          data-cy="topNavOrgLinkTrainingPrograms"
          disabled={!canAccessResources(role, enableResources, orgType)}
        >
          <span className="d-flex flex-column align-items-center">
            <span className="topNav-app-link-icon d-flex align-items-center">
              <AppsIcon
                src={ICON_TRAINING_PROGRAMS}
                name="Training Programs"
              />
            </span>
            <span className="mt-1 small">
              Training
              <br />
              Programs
            </span>
          </span>
        </Link>

        <Link
          className="btn btn-sm btn-white topNav-app-link"
          data-cy="topNavOrgLinkEmployers"
          to={ORG_CONSOLE_LOCATIONS}
          title="Employers"
          disabled={!canAccessCourses(role)}
        >
          <span className="d-flex flex-column align-items-center">
            <span className="topNav-app-link-icon d-flex align-items-center">
              <AppsIcon
                src={ICON_LOCATIONS}
                name="Employers"
              />
            </span>
            <span className="mt-1 small">Employers</span>
          </span>
        </Link>

        <Link
          to={ORG_CONSOLE_INSTRUCTION_PROVIDERS}
          className="btn btn-sm btn-white topNav-app-link"
          data-cy="topNavOrgLinkInstructionProviders"
          disabled={!canAccessAssistants(role)}
        >
          <span className="d-flex flex-column align-items-center">
            <span className="topNav-app-link-icon d-flex align-items-center">
              <AppsIcon
                src={ICON_INSTITUTIONS}
                name="Instruction Providers"
              />
            </span>
            <span className="mt-1 small">
              Instruction
              <br />
              Providers
            </span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ApprentageApps;
