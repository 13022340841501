import React from 'react';
import { getEntryId } from '../../../services/references';
import isPublished from '../../../utils/isPublished';
import AdminMenu from '../../AdminMenu';

const defaultContent = {
  title: 'No entries',
  id: undefined,
  contentType: 'None'
};

const Reference = ({
  referenceIndex,
  content,
  classId,
  topicId,
  addContent,
  editContent,
  addTypes
}) => {
  const {
    code,
    timeDate,
    currentSession,
    // Topic
    title,
    category,
    level,
    featured,
    locked,
    prerequisite,
    // Shared
    id: entryId, // TODO better naming, technically this can be topicId
    contentType
  } = content && isPublished(content) ? content : defaultContent;

  const noEntries = content === undefined;

  return (
    <div className="reference d-flex justify-content-between align-items-center border rounded p-3">
      <div className="reference-info">
        <div>
          <div className="text-muted text-capitalize">{contentType}</div>
        </div>

        {contentType.includes('eUnit') && (
          <h6>
            <span className="d-block my-1">
              <b>{code}</b>
            </span>
            {currentSession && (
              <span className="text-muted mb-1 d-block">
                Current Session:{' '}
                {currentSession ? (
                  <span className="h5">
                    <span className="badge border mr-1">{currentSession}</span>
                  </span>
                ) : (
                  'n/a'
                )}
              </span>
            )}
            {timeDate && <span className="d-block">{timeDate}</span>}
          </h6>
        )}

        {!contentType.includes('eUnit') && (
          <h6 className="mb-1">
            {title}{' '}
            {contentType === 'topic' && locked && (
              <i className="fas fa-lock m-2" />
            )}{' '}
            {contentType === 'topic' && featured && (
              <span className="badge badge-warning">Featured</span>
            )}
          </h6>
        )}

        {contentType === 'topic' && category && (
          <div className="text-muted mb-1">
            Category:{' '}
            {category ? (
              <span className="h6">
                {category.map((cat, i) => (
                  <span
                    key={`cat-${i}`}
                    className="badge border mr-1"
                  >
                    {cat}
                  </span>
                ))}
              </span>
            ) : (
              'n/a'
            )}
          </div>
        )}

        {contentType === 'topic' && level && (
          <div className="text-muted mb-1">
            Level:{' '}
            {level ? (
              <span className="h6">
                {level.map((lev, i) => (
                  <span
                    key={`level-${i}`}
                    className="badge border mr-1"
                  >
                    {lev}
                  </span>
                ))}
              </span>
            ) : (
              'n/a'
            )}
          </div>
        )}

        {contentType === 'topic' &&
          isPublished(prerequisite) && ( // TODO topic flatten
            <div className="text-muted">
              Prerequisite: <b>{prerequisite.fields.title}</b>
            </div>
          )}
      </div>
      <div className="reference-action">
        <AdminMenu
          referenceIndex={noEntries ? 0 : referenceIndex}
          id={`admin-menu-${classId}-${entryId || topicId}`}
          className="admin-menu--reference dropdown-menu-right"
          classId={classId}
          topicId={topicId}
          entryId={getEntryId({ entryId, content })}
          title={title}
          noEntries={noEntries}
          contentType={contentType}
          addContent={addContent}
          editContent={noEntries ? false : editContent}
          addTypes={addTypes}
        />
      </div>
    </div>
  );
};

export default Reference;
