import { fetchCourses, fetchSyndicatedCourses } from '../services/courses';
import {
  SET_COURSES,
  RESET_COURSES,
  SET_SYNDICATED_COURSES,
  RESET_SYNDICATED_COURSES,
  SET_SYNDICATED_COURSES_SHARING
} from './types';

export const getCourses = ({ orgId, locationIds, courseIds }) => {
  return (dispatch) => {
    let allCourses = [];

    if (courseIds) {
      fetchCourses({ courseIds }).then((courses) => {
        dispatch({
          type: SET_COURSES,
          courses
        });
      });
    } else {
      fetchCourses({ orgId, locationIds })
        .then((courses) => {
          if (courses && Array.isArray(courses) && courses.length > 0) {
            allCourses = [...courses];
          }

          fetchSyndicatedCourses({ orgIds: [orgId] }).then(
            (syndicatedCourses) => {
              if (
                syndicatedCourses &&
                Array.isArray(syndicatedCourses) &&
                syndicatedCourses.length > 0
              ) {
                allCourses = [...allCourses, ...syndicatedCourses];
              }

              dispatch({
                type: SET_COURSES,
                courses: allCourses.length > 0 ? allCourses : null
              });
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
};

export const getSyndicatedCourses = ({ orgIds }) => {
  return (dispatch) => {
    fetchSyndicatedCourses({ orgIds })
      .then((response) => {
        const syndicatedCourses = response !== null ? response : null;

        dispatch({
          type: SET_SYNDICATED_COURSES,
          syndicatedCourses
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getSyndicatedCoursesSharing = ({ orgId }) => {
  return (dispatch) => {
    fetchCourses({ orgId, syndicatedSharing: true })
      .then((response) => {
        const syndicatedCoursesSharing = response !== null ? response : null;

        dispatch({
          type: SET_SYNDICATED_COURSES_SHARING,
          syndicatedCoursesSharing
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const resetCourses = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COURSES });
  };
};

export const resetSyndicatedCourses = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SYNDICATED_COURSES });
  };
};
