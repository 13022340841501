import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { FaCog } from 'react-icons/fa';
import { deleteEntry } from '../../../services/entry';
import { canDeleteEntry } from '../../../services/currentUser';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';
import { removeEntryFromTopic } from '../../../services/topics';
import { setCurrentTopic } from '../../../actions/Topic';
import { manageSubmissions } from '../../../actions/Answers';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';

const DeleteChallenge = ({
  contentId,
  title,
  contentType = 'challenge',
  buttonText = 'Delete Challenge'
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const currentChallenge = useSelector((state) => state.currentChallenge);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  const currentTopic = useSelector((state) => state.currentTopic);
  // Course
  const classId = course?.id || null;
  // Topic
  const topicId = currentTopic?.id || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [contentId]);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteEntry(role)) {
      return false;
    }

    if (currentChallenge.cdnFiles && currentChallenge.cdnFiles.length !== 0) {
      return false;
    }

    if (
      currentAnswers.list &&
      Array.isArray(currentAnswers.list) &&
      currentAnswers.list.length
    ) {
      // Challenge Answers exist for this Challenge, do not delete
      return false;
    }

    return true;
  }, [currentAnswers.list, currentChallenge.cdnFiles, role]);

  const handleDelete = () => {
    // Materials, Challenges && Quizzes
    setLoading(true);
    toast.info('Processing...');
    removeEntryFromTopic({
      entryId: contentId,
      topicId,
      contentType
    })
      .then(() => {
        deleteEntry(contentId)
          .then(() => {
            // Update Topic
            dispatch(setCurrentTopic({ topicId })).then(() => {
              toast.success(`${title} Deleted!`);
              // Redirect User back to Topic Edit UI
              history.replace(
                `${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=topic&classId=${classId}&topicId=${topicId}&entryId=${topicId}`
              );
            });
          })
          .catch((error) => {
            console.error(`deleteEntry Entry: ${contentType}`, error);
          });
      })
      .catch((error) => {
        console.error('removeEntryFromTopic', error);
      });
  };

  return (
    <DeleteContent
      id={contentId}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className="mt-2 mb-3"
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all of this Challenges's Files
          <TaskStatus
            statusType="files"
            complete={
              !currentChallenge.cdnFiles ||
              currentChallenge.cdnFiles.length === 0
            }
          />
        </li>
        <li className="mb-2">
          <span className="mr-2">
            Delete all of this Challenge's Submissions
          </span>
          <TaskStatus
            statusType="submissions"
            complete={
              !currentAnswers.list && currentAnswers?.pagination?.total === 0
            }
          />
          {currentAnswers.list && currentAnswers?.pagination?.total !== 0 && (
            <ul className="mt-2 pl-3">
              <li className="font-weight-bold">
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => {
                    dispatch(manageSubmissions(currentChallenge.id));
                  }}
                >
                  <span className="d-flex align-items-center">
                    <FaCog
                      className="text-ships-officer mr-1"
                      size={15}
                    />
                    <span>
                      <span>
                        {pluralize(
                          'Review Submission',
                          currentAnswers?.pagination?.total || 0
                        )}
                      </span>
                      <span className="ml-1">
                        ({currentAnswers?.pagination?.total || 0})
                      </span>
                    </span>
                  </span>
                </button>
              </li>
            </ul>
          )}
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteChallenge;
