import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowsAlt } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { fetchWidgets } from '../../../services/widgets';
import Modal from '../../Modal';
import ItemsDnd from '../../ManageContent/Dnd/Items';

const ReorderLinkWidgetsModal = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization.id || '';
  // Current Modal
  const { data } = currentModal;
  // Local State
  const [widgetsList, setWidgetList] = useState([]);
  const [reorderedWidgetList, setReorderedWidgetList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  useEffect(() => {
    setLoading(true);
    const ids = data.selectedItemsList || [];
    fetchWidgets({
      orgId,
      type: 'link',
      ids,
      limit: ids.length,
      sortByIds: ids
    })
      .then((response) => {
        const newData =
          response?.list?.map((link) => ({
            title: link.title,
            id: link.id,
            ...(link?.image ? { url: link.image } : {})
          })) || [];

        setWidgetList(newData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data.selectedItemsList, orgId]);

  const onSave = () => {
    currentModal?.callbackPrimaryAction(reorderedWidgetList);
    handleClose();
  };

  return (
    <Modal
      visible={currentModal.visible}
      cssClassName={`turbine-modal--style-fullscreen turbine-modal--${currentModal?.key}`}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className="m-0 d-flex align-items-center">
            <FaArrowsAlt
              className="mr-2"
              size={15}
            />
            <span>Links Module</span>
            <span className="badge badge-secondary ml-2">Reorder</span>
          </h5>
        </div>
        <div className="card-body">
          {widgetsList?.length > 0 && (
            <ItemsDnd
              items={widgetsList}
              onUpdate={(itemsNewOrder) => {
                if (itemsNewOrder?.length) {
                  const ids = itemsNewOrder.map((widget) => widget.id);
                  setReorderedWidgetList(ids);
                }
              }}
            />
          )}
        </div>
        <div>
          <div className="card-footer">
            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={onSave}
              disabled={loading || reorderedWidgetList?.length === 0}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-link"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReorderLinkWidgetsModal;
