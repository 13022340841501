import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_RESOURCES_READONLY,
  NOTICE_RESOURCES_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_RESOURCES } from '../../../constants/routes';
import { resetResourceGroups } from '../../../actions/ResourceGroups';
import { deleteResourceGroup } from '../../../services/resourceGroups';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';
import { canDeleteEntry } from '../../../services/currentUser';

const DeleteResourceGroup = ({ id, title, contentType = 'group' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_RESOURCES_READONLY]);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Params
  const searchParams = new URLSearchParams(window.location.search);
  const paramsGroupIds = searchParams.get('groupIds');
  const groupIds = paramsGroupIds ? paramsGroupIds.split(',') : [];
  // Local State
  const [loading, setLoading] = useState(false);

  const groupHasChildFolders = useMemo(() => {
    return (
      Array.isArray(resourceGroup?.list) && resourceGroup?.list.length !== 0
    );
  }, [resourceGroup?.list]);

  const groupHasFiles = useMemo(() => {
    return (
      Array.isArray(resourceGroup?.cdnFiles) &&
      resourceGroup?.cdnFiles.length !== 0
    );
  }, [resourceGroup?.cdnFiles]);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteEntry(role)) {
      return false;
    }

    return !groupHasChildFolders && !groupHasFiles;
  }, [groupHasChildFolders, groupHasFiles, role]);

  const deleteGroupSuccess = () => {
    const newGroupIds = groupIds.filter((gId) => gId !== id);
    const redirectRoute = routeWithOrgId({
      route: ORG_CONSOLE_RESOURCES,
      urlParams: {
        orgId,
        ...(newGroupIds.length ? { groupIds: newGroupIds.join(',') } : {})
      }
    });

    dispatch(resetResourceGroups());

    history.replace(redirectRoute);
    $('#collapse-deleteContentHowTo').collapse('hide');
    toast.dismiss('deletingFolder');
    toast.success('Folder deleted!');
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    toast.info('Deleting folder...', { toastId: 'deletingFolder' });

    deleteResourceGroup(resourceGroup?.id).then(() => {
      deleteGroupSuccess();
    });
  };

  const showFeatureFlagModal = () => {
    dispatch(
      showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      })
    );
  };

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [id]);

  return (
    <DeleteContent
      id={id}
      title={title}
      contentType={contentType}
      buttonText="Delete Folder"
      handleDisableDelete={
        flags?.resources_readonly?.enabled && flags?.resources_readonly?.value
          ? showFeatureFlagModal
          : undefined
      }
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className="mt-2 mb-3"
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all Files in this Folder{' '}
          <TaskStatus
            statusType="files"
            complete={!groupHasFiles}
          />
        </li>

        <li className="mb-2">
          Delete all Folders in this Folder{' '}
          <TaskStatus
            statusType="folders"
            complete={!groupHasChildFolders}
          />
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteResourceGroup;
