import { utils, writeFile } from 'xlsx/dist/xlsx.mini.min';
import { quizTotalScore } from '../../../services/contentFlow/quizzes';
import { mdySimple } from '../../../utils/date';

const generateFileName = ({ currentQuiz, syndicatedCourseOrg, orgName }) => {
  let name = 'Quiz Outcomes';

  if (currentQuiz?.title) {
    name += ` ${currentQuiz.title}`;
  }

  name += ` ${syndicatedCourseOrg?.name || orgName}`;

  name = name.replace(/\s/g, '-');

  name += `--${mdySimple(new Date(), '-')}`;

  return name;
};

/* This function creates gap rows */
function createGapRows(ws, nRows) {
  const ref = utils.decode_range(ws['!ref']); // get original range
  ref.e.r += nRows; // add to ending row
  ws['!ref'] = utils.encode_range(ref); // reassign row
}

const exportData = ({
  orgName,
  course,
  currentQuiz,
  quizOutcomes,
  syndicatedCourseOrgs,
  syndicatedCourseOrgId
}) => {
  const numQuestions = currentQuiz.questions.length;
  let quizOutcomesData = [];
  const numberOfQuestions = Array.from(Array(numQuestions), (_, index) =>
    (index + 1).toString()
  );

  const quizOutcomeDataHeader = [
    [
      'Name',
      'Submitted',
      'Score',
      'Percentage',
      'Attempts',
      '',
      ...numberOfQuestions
    ]
  ];

  const quizOutcomesUserDataFinal = [];

  quizOutcomes.list.forEach((quizOutcome) => {
    const { userName, score, maxScore, attempts, createdAt } = quizOutcome;
    const result = [];

    result.push(userName); // Name
    result.push(mdySimple(createdAt)); // Sumitted
    result.push(`${score}/${maxScore}`); // Score
    result.push(quizTotalScore({ score, maxScore })); // Percentage
    result.push(`${attempts}`); // Attempts
    result.push(''); // Empty Cell

    quizOutcome.userAnswers.forEach((_, i) => {
      result.push(quizOutcome.evaluation[i] ? 'Correct' : 'x');
    });

    quizOutcomesUserDataFinal.push(result);
  });

  quizOutcomesData = [...quizOutcomeDataHeader, ...quizOutcomesUserDataFinal];

  const syndicatedCourseOrg = syndicatedCourseOrgs.list
    ? syndicatedCourseOrgs.list.find((o) => o.id === syndicatedCourseOrgId)
    : null;

  const fileName = generateFileName({
    currentQuiz,
    orgName,
    syndicatedCourseOrg
  });

  const wb = utils.book_new();

  const metadata = [
    ['Organization:', syndicatedCourseOrg?.name || orgName, '', '', ''],
    ['Course:', course?.title, '', '', ''],
    ['Quiz:', currentQuiz.title, '', '', '']
  ];

  /**
   * User Meta Data
   * User Answers
   */
  // https://docs.sheetjs.com/docs/api/utilities/#array-of-arrays-input
  const wsUser = utils.aoa_to_sheet(metadata);

  createGapRows(wsUser, 1);
  utils.sheet_add_aoa(
    wsUser,
    [['', '', '', '', '', '', 'Questions / Evaluations']],
    { origin: -1 }
  );
  utils.sheet_add_aoa(wsUser, quizOutcomesData, { origin: -1 });
  createGapRows(wsUser, 2);
  utils.sheet_add_aoa(wsUser, [[`Exported: ${mdySimple(new Date(), '/')}`]], {
    origin: -1
  });
  utils.sheet_add_aoa(wsUser, [['Powered by Turbine Workforce']], {
    origin: -1
  });

  const questionCols = numberOfQuestions.map(() => {
    return {
      width: 10
    };
  });

  wsUser['!cols'] = [
    { width: 20 },
    { width: 15 },
    { width: 11 },
    { width: 11 },
    { width: 11 },
    { width: 5 },
    ...questionCols
  ];

  utils.book_append_sheet(wb, wsUser, 'Cohort Basis');

  writeFile(wb, `${fileName}.xlsx`);
};

export default exportData;
