import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaCog,
  FaCopy,
  FaEllipsisH,
  FaExternalLinkAlt,
  FaMapMarkedAlt,
  FaPaperPlane,
  FaPlus
} from 'react-icons/fa';
import { ORG_CONSOLE_LOCATIONS } from '../../constants/routes';
import { routeWithOrgId } from '../../services/organizations';
import { canEditLocation } from '../../services/currentUser';
import { getFullAddress, locationTerminology } from '../../services/locations';
import { setCurrentModal } from '../../actions/Modals';
import copyToClipBoard from '../../utils/copyToClipBoard';
import { createMaterial } from '../../services/materials';
import { getMaterial, getNoteMaterials } from '../../actions/Materials';
import { setSideSheet } from '../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../constants/sideSheets';
import { canCreateNoteLocation } from '../../permissions/create';

// DotMenu (new)

const LocationDotMenu = ({
  className = '',
  data,
  showMenuOptionNote = false,
  showMenuOptionSettings = false,
  showMenuOptionCopy = false,
  showMenuOptionMap = false,
  showInviteJobProfile = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const noteLocationMaterials = useSelector(
    (state) => state.noteLocationMaterials
  );
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // Current User
  const role = currentUser?.role || [];
  // Location
  const locationTerm = locationTerminology({ orgType });
  const locationId = data?.id || '';
  const locationName = data?.name || '';
  const mapLink = data?.mapLink || '';
  const fullAddress = getFullAddress({ location: data });
  // Location Materials
  const hasLocationMaterials = useMemo(() => {
    return (
      Array.isArray(noteLocationMaterials?.list) &&
      noteLocationMaterials.list.length > 0
    );
  }, [noteLocationMaterials.list]);

  const inviteToCreateJobProfile = () => {
    dispatch(
      setCurrentModal({
        key: 'inviteUserCreateJobProfile',
        data: {
          locationId
        }
      })
    );
  };

  const createNote = () => {
    const noteType = 'location';
    const noteTypeId = locationId;

    if (!noteTypeId) {
      console.error('Missing required argument, noteTypeId');
      return;
    }

    const dataToSave = {
      title: `Internal Note: ${locationName}`,
      body: '',
      orgId,
      noteLocationId: noteTypeId,
      userId: currentUser?.id,
      enableNewEditor: true
    };

    createMaterial(dataToSave).then((response) => {
      setTimeout(() => {
        // Wait to make sure Material is created
        // a race condition was observed without timeout

        dispatch(getMaterial(response?.id)).then(() => {
          dispatch(
            setSideSheet({
              key: SHEET_KEY_MATERIAL,
              className: 'MaterialSideSheet z-index-100',
              data: {
                editMode: true,
                tinyMceEditor: {
                  uploadPdfButton: false,
                  embedMediaButton: false,
                  uploadImgButton: false
                },
                noteType,
                noteTypeId
              },
              callbackPrimaryAction: () => {
                dispatch(
                  getNoteMaterials({
                    noteType,
                    noteTypeId,
                    orgId
                  })
                );
              }
            })
          );
        });
      }, 250);
    });
  };

  return (
    <div className={className}>
      <button
        className="btn btn-sm btn-white"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white d-flex align-items-center">
          <small>
            <i className={`${locationTerm.icon} mr-2`} />
          </small>
          <span className="text-nowrap">{locationName}</span>
        </div>

        {showMenuOptionNote &&
          canCreateNoteLocation(role) &&
          !hasLocationMaterials && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              onClick={createNote}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2">Internal Note</span>
              </span>
            </button>
          )}

        {showMenuOptionMap && mapLink && (
          <a
            className="dropdown-item border-bottom py-2 px-3"
            href={mapLink}
            role="button"
            title="View on map"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="d-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center">
                <FaMapMarkedAlt />
                <span className="ml-2">Open Map</span>
              </span>
              <FaExternalLinkAlt
                size={10}
                className="text-muted"
              />
            </span>
          </a>
        )}

        {showMenuOptionCopy && (
          <button
            type="button"
            title="Copy Address"
            className="dropdown-item border-bottom py-2 px-3"
            onClick={() => {
              copyToClipBoard(fullAddress);
              toast.success('Address Copied!');
            }}
          >
            <span className="d-flex align-items-center">
              <FaCopy />
              <span className="ml-2">Copy Address</span>
            </span>
          </button>
        )}

        {showInviteJobProfile &&
          canEditLocation(role) &&
          orgType === 'community' && (
            <button
              type="button"
              className="dropdown-item border-bottom py-2 px-3"
              onClick={inviteToCreateJobProfile}
            >
              <span className="d-flex align-items-center">
                <FaPaperPlane />
                <span className="ml-2">Invite: Create Job Profile</span>
              </span>
            </button>
          )}

        {showMenuOptionSettings && canEditLocation(role) && (
          <Link
            className="dropdown-item py-2 px-3"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_LOCATIONS}/manage/${locationId}`,
              orgId
            })}
            title={`Edit ${locationTerm.text}`}
          >
            <span className="d-flex align-items-center">
              <FaCog />
              <span className="ml-2">Settings</span>
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default LocationDotMenu;
