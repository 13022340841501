import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ICON_CHECK_CIRCLE,
  ICON_TRIANGLE_BANG
} from '@apprentage/components/dist/constants/icons';
import { pathOr } from 'ramda';
import { INVITE_TYPE_EMPLOYER_PROFILE_UPDATE } from '@apprentage/constants';
import { employerInviteStatuses } from '../../../services/employers';

const WageSchedulesCell = ({ row, wageSchedulesEnum = {} }) => {
  // Redux
  const invites = useSelector((state) => state.invites);
  const employerProfiles = useSelector((state) => state.employerProfiles);
  // Employer Profile
  const employerProfileId = pathOr(
    null,
    ['original', 'employerProfileId'],
    row
  );
  const employerProfile = useMemo(() => {
    if (!employerProfiles.list) {
      return {};
    }

    return employerProfiles.list.find(({ id }) => id === employerProfileId);
  }, [employerProfiles.list]);
  // Invites
  const inviteStatuses = useMemo(() => {
    const invitesList = pathOr([], ['list'], invites);
    const inviteHistory = pathOr([], ['inviteHistory'], employerProfile);
    const statuses = employerInviteStatuses({
      invitesList,
      inviteHistory
    });

    return statuses;
  }, [employerProfile?.id, invites]);

  if (
    employerProfileId &&
    wageSchedulesEnum &&
    wageSchedulesEnum[employerProfileId] !== undefined
  ) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_CHECK_CIRCLE} text-success mr-1`} /> Completed
      </div>
    );
  }

  if (inviteStatuses[INVITE_TYPE_EMPLOYER_PROFILE_UPDATE]) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_TRIANGLE_BANG} text-warning mr-1`} /> Data
        Requested
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center text-nowrap">
      <i className={`${ICON_TRIANGLE_BANG} text-danger mr-1`} /> Incomplete
    </div>
  );
};

export default WageSchedulesCell;
