import React from 'react';

const ArrLenCell = ({ value: arr }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <span className="px-2 py-1 bg-light border rounded">
        {arr && Array.isArray(arr) ? arr.length : 0}
      </span>
    </div>
  );
};

export default ArrLenCell;
