import React from 'react';
import { pathOr } from 'ramda';
import { FaPencilAlt } from 'react-icons/fa';
import usStates from './usStates';
import countries from '../../../constants/countries';
import AddressReadable from '../../AddressReadable';

const Address = ({
  title = '',
  address,
  editMode,
  onChange,
  toggleEditMode,
  inputFieldStyle = {},
  autoFocus,
  children,
  cardStyle,
  className = 'mt-2',
  required
}) => {
  const addressLine1 = pathOr('', ['line1'], address);
  const addressLine2 = pathOr('', ['line2'], address);
  const addressCity = pathOr('', ['city'], address);
  const addressState = pathOr('', ['state'], address);
  const addressPostalCode = pathOr('', ['postal_code'], address);
  const addressCountry = pathOr('', ['country'], address);

  if (!editMode) {
    return (
      <div className="card mt-2">
        <div className="card-body p-2">
          <div className="d-flex align-items-center">
            <b>{title || 'Address'}:</b>
            {toggleEditMode && (
              <button
                className="btn-link ml-2"
                onClick={toggleEditMode}
                type="button"
                aria-label="Edit"
              >
                <FaPencilAlt />
              </button>
            )}
          </div>

          <AddressReadable
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            addressCity={addressCity}
            addressState={addressState}
            addressPostalCode={addressPostalCode}
            addressCountry={addressCountry}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={`${cardStyle ? 'card' : ''} ${className}`}>
      {title && (
        <div className="card-header bg-secondary text-white font-weight-bold">
          {title || 'Address'}
        </div>
      )}
      <div className={cardStyle ? 'card-body' : ''}>
        <div className="mb-3">
          <label htmlFor="line1">
            Street {required && <span className="text-danger">*</span>}
          </label>
          <input
            type="text"
            className="form-control"
            id="line1"
            style={inputFieldStyle}
            name="line1"
            required={required}
            defaultValue={addressLine1}
            onChange={onChange}
            autoFocus={autoFocus}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="line2">
            Street <span className="text-muted">(line 2)</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="line2"
            style={inputFieldStyle}
            name="line2"
            defaultValue={addressLine2}
            onChange={onChange}
          />
        </div>

        <div className="row">
          <div className="col-md-5 mb-3">
            <label htmlFor="line2">
              City {required && <span className="text-danger">*</span>}
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              style={inputFieldStyle}
              name="city"
              defaultValue={addressCity}
              onChange={onChange}
              required={required}
            />
          </div>

          <div className="col-md-4 mb-3">
            <label htmlFor="state">
              State/Province{' '}
              {required && <span className="text-danger">*</span>}
            </label>
            <select
              className="custom-select d-block w-100"
              id="state"
              name="state"
              required={required}
              defaultValue={addressState}
              onChange={onChange}
            >
              <option value="">Choose...</option>
              {usStates.map(({ name, abbreviation }) => (
                <option
                  key={abbreviation}
                  value={abbreviation}
                >
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3 mb-3">
            <label htmlFor="zip">
              Zip/Postal {required && <span className="text-danger">*</span>}
            </label>
            <input
              type="text"
              className="form-control"
              id="postal_code"
              style={inputFieldStyle}
              name="postal_code"
              required={required}
              defaultValue={addressPostalCode}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="country">
            Country {required && <span className="text-danger">*</span>}
          </label>
          <select
            className="custom-select d-block w-100"
            id="country"
            name="country"
            required={required}
            defaultValue={addressCountry}
            onChange={onChange}
          >
            <option value="">Choose...</option>

            {countries.map(({ name, code }) => (
              <option
                key={code}
                value={code}
              >
                {name}
              </option>
            ))}
          </select>
        </div>

        {children || null}
      </div>
    </div>
  );
};

export default Address;
