import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { routeWithOrgId } from '../../../../../services/organizations';
import { setCurrentModal } from '../../../../../actions/Modals';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import DotMenuCourses from './DotMenu';
import DotMenu from '../DotMenu';
import InstructionProvidersList from '../../../../lists/InstructionProvidersList';

const ApprenticeshipInstructionProviders = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || '';
  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  // Training Program
  const instructionProviderIds = apprenticeship?.instructionProviderIds || [];

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    return function cleanup() {
      const { href } = window.location;
      const apprenticeshipRoute = `org/workforce/apprenticeships/${apprenticeshipId}`;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId, dispatch]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }
  const hasInstructionProviders =
    Array.isArray(apprenticeship?.instructionProviderIds) &&
    apprenticeship.instructionProviderIds.length > 0;

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="instructionProviders" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle="Instruction Providers"
              className="mb-3"
            >
              <DotMenuCourses />
            </OrgConsoleSubheader>

            {hasInstructionProviders && (
              <InstructionProvidersList
                instructionProviderIds={instructionProviderIds}
              />
            )}

            <div className="py-4 d-flex justify-content-center flex-column">
              <div className="mt-3 w-100 text-center">
                {!hasInstructionProviders && (
                  <div className="mb-3">No Instruction Providers selected.</div>
                )}
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="mr-1">Add Instruction Provider</span>
                  </button>
                  <div className="dropdown-menu p-0">
                    <button
                      className="dropdown-item py-2 border-bottom"
                      type="button"
                      title="Add New Instruction Provider"
                      onClick={() => {
                        dispatch(
                          setCurrentModal({
                            key: 'changePinInstructionProvider',
                            data: {}
                          })
                        );
                      }}
                    >
                      <span className="d-flex align-items-center">
                        <FaPlus />
                        <span className="ml-2">Add New</span>
                      </span>
                    </button>
                    <Link
                      className="dropdown-item py-2"
                      to={routeWithOrgId({
                        route: `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/manage/${apprenticeshipId}`,
                        urlParams: {
                          orgId,
                          scrollToId: 'apprenticeshipInstructionProvidersSelect'
                        }
                      })}
                      title="Add Existing Instruction Providers"
                    >
                      Add Existing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipInstructionProviders);
