import React from 'react';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import Skeleton from 'react-loading-skeleton';
import UserProfilePicture from '../../../components/UserProfilePicture';
import SearchBoxLauncher from '../../../components/TopNav/SearchBoxLauncher';

const Nav = ({ orgLogo, loadingLogo }) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <>
      <div
        className="mb-3 rounded bg-white d-flex justify-content-between"
        style={{
          border: '1px dashed #dee2e6'
        }}
      >
        <div className="d-flex align-items-center">
          <div className="p-3">
            {orgLogo || !loadingLogo ? (
              <OrgLogo
                src={orgLogo}
                alt={organization?.name}
                height={30}
              />
            ) : (
              <Skeleton style={{ width: '70px', height: '25px' }}>
                LOGO
              </Skeleton>
            )}
          </div>
          <SearchBoxLauncher
            id="workforceSpaceSearch"
            placeholder="Ask or Search..."
            className="bg-white border-left border-right"
          />
        </div>
        <div className="border-left p-3">
          <UserProfilePicture
            src={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
            alt={currentUser?.name}
            height="30"
            width="30"
          />
        </div>
      </div>
      {/* <Steps
        enabled={stepsEnabled}
        steps={introSteps}
        ref={(steps) => (this.steps = steps)}
      /> */}
    </>
  );
};

export default Nav;
