import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { isLocalhost } from '../serviceWorker';

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'

// group
import courseGroup from './reducers/group/courseGroup';
import resourceGroup from './reducers/group/resourceGroup';
// groups
import courseGroups from './reducers/groups/courseGroups';
import resourceGroups from './reducers/groups/resourceGroups';
// tags
import resourceGroupTags from './reducers/tags/resourceGroupTags';
//
import analytics from './reducers/analytics';
import app from './reducers/app'; // import rootReducer from './reducers/index';
import apprenticeAgreement from './reducers/apprenticeAgreement';
import apprenticeAgreements from './reducers/apprenticeAgreements';
import apprenticeship from './reducers/apprenticeship';
import apprenticeships from './reducers/apprenticeships';
import assistant from './reducers/assistant';
import assistants from './reducers/assistants';
import billing from './reducers/billing';
import billingAgreement from './reducers/billingAgreement';
import challenges from './reducers/challenges';
import classCertificate from './reducers/classCertificate';
import contextQuestions from './reducers/contextQuestions';
import course from './reducers/course';
import courseCohorts from './reducers/courseCohorts';
import courseTopics from './reducers/courseTopics';
import courses from './reducers/courses';
import cronUsers from './reducers/cronUsers';
import currentAnswer from './reducers/currentAnswer';
import currentAnswers from './reducers/currentAnswers';
import currentChallenge from './reducers/currentChallenge';
import currentClassAnswers from './reducers/currentClassAnswers';
import currentClassQuizOutcomes from './reducers/currentClassQuizOutcomes';
import currentClassQuizzes from './reducers/currentClassQuizzes';
import currentCohort from './reducers/currentCohort';
import currentEntry from './reducers/currentEntry';
import currentModal from './reducers/currentModal';
import currentQuiz from './reducers/currentQuiz';
import currentTopic from './reducers/currentTopic';
import currentTopicAnswers from './reducers/currentTopicAnswers';
import currentTopicQuizOutcomes from './reducers/currentTopicQuizOutcomes';
import currentUser from './reducers/currentUser';
import dashboard from './reducers/dashboard';
import dictionary from './reducers/dictionary';
import directoryUser from './reducers/directoryUser';
import directoryUserProfile from './reducers/directoryUserProfile';
import employer from './reducers/employer';
import employerAgreement from './reducers/employerAgreement';
import employerAgreements from './reducers/employerAgreements';
import employerProfile from './reducers/employerProfile';
import employerProfiles from './reducers/employerProfiles';
import employers from './reducers/employers';
import eUnitTracking from './reducers/eUnitTracking';
import instructionProvider from './reducers/instructionProvider';
import instructionProviders from './reducers/instructionProviders';
import invites from './reducers/invites';
import jobProfile from './reducers/jobProfile';
import jobProfiles from './reducers/jobProfiles';
import locations from './reducers/locations';
import logbook from './reducers/logbook';
import logbooks from './reducers/logbooks';
import logbookHours from './reducers/logbookHours';
import logbookRecord from './reducers/logbookRecord';
import logbookRecords from './reducers/logbookRecords';
import material from './reducers/material';
import materials from './reducers/materials';
import noteApprenticeshipMaterials from './reducers/noteApprenticeshipMaterials';
import noteInstructionProviderMaterials from './reducers/noteInstructionProviderMaterials';
import noteLocationMaterials from './reducers/noteLocationMaterials';
import noteUserMaterials from './reducers/noteUserMaterials';
import notification from './reducers/notification';
import notificationsCourse from './reducers/notificationsCourse';
import notificationsOrg from './reducers/notificationsOrg';
import organization from './reducers/organization';
import orgLocation from './reducers/orgLocation';
import page from './reducers/page';
import pageLink from './reducers/pageLink';
import pages from './reducers/pages';
import pathway from './reducers/pathway';
import pathways from './reducers/pathways';
import project from './reducers/project';
import projects from './reducers/projects';
import quizOutcome from './reducers/quizOutcome';
import quizOutcomes from './reducers/quizOutcomes';
import search from './reducers/search';
import searches from './reducers/searches';
import sideSheet from './reducers/sideSheet';
import skills from './reducers/skills';
import syndicatedCourseOrgs from './reducers/syndicatedCourseOrgs';
import user from './reducers/user';
import userGroups from './reducers/groups/userGroups';
import userLocation from './reducers/userLocation'; // currentUser location
import userWeather from './reducers/userWeather';
import users from './reducers/users'; // currentUser location
import wageSchedules from './reducers/wageSchedules';
import widgetEmbeds from './reducers/widgetEmbeds';
import widgetLinks from './reducers/widgetLinks';

const addPromiseSupport = (store) => {
  const rawDispatch = store.dispatch;
  return (action) => {
    if (typeof action.then === 'function') {
      return action.then(rawDispatch);
    }
    return rawDispatch(action);
  };
};

export const rootReducer = combineReducers({
  // group
  courseGroup,
  resourceGroup,
  // groups
  courseGroups,
  resourceGroups,
  userGroups,
  // tags
  resourceGroupTags,
  //
  analytics,
  app,
  apprenticeAgreement,
  apprenticeAgreements,
  apprenticeship,
  apprenticeships,
  assistant,
  assistants,
  billing,
  billingAgreement,
  challenges,
  classCertificate,
  contextQuestions,
  course,
  courseCohorts,
  courseTopics,
  courses,
  cronUsers,
  currentAnswer,
  currentAnswers,
  currentChallenge,
  currentClassAnswers,
  currentClassQuizOutcomes,
  currentClassQuizzes,
  currentCohort,
  currentEntry,
  currentModal,
  currentQuiz,
  currentTopic,
  currentTopicAnswers,
  currentTopicQuizOutcomes,
  currentUser,
  dashboard,
  dictionary,
  directoryUser,
  directoryUserProfile,
  userWeather,
  employer,
  employerAgreement,
  employerAgreements,
  employerProfile,
  employerProfiles,
  employers,
  eUnitTracking,
  instructionProvider,
  instructionProviders,
  invites,
  jobProfile,
  jobProfiles,
  locations,
  logbook,
  logbooks,
  logbookHours,
  logbookRecord,
  logbookRecords,
  material,
  materials,
  noteApprenticeshipMaterials,
  noteInstructionProviderMaterials,
  noteLocationMaterials,
  noteUserMaterials,
  notification,
  notificationsCourse,
  notificationsOrg,
  organization,
  orgLocation,
  page,
  pageLink,
  pages,
  pathway,
  pathways,
  project,
  projects,
  quizOutcome,
  quizOutcomes,
  search,
  searches,
  sideSheet,
  skills,
  syndicatedCourseOrgs,
  user,
  userLocation,
  users,
  wageSchedules,
  widgetEmbeds,
  widgetLinks
});

export function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer]; // monitorReducersEnhancer
  const composedEnhancers = isLocalhost
    ? composeWithDevTools(...enhancers)
    : middlewareEnhancer;

  const store = createStore(rootReducer, preloadedState, composedEnhancers); // rootReducer

  store.dispatch = addPromiseSupport(store);

  return store;
}
