import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'chart.js/auto';
import Skeleton from 'react-loading-skeleton';
import { Chart, getElementAtEvent } from 'react-chartjs-2';
import { FaCalendarAlt, FaChartLine, FaPrint } from 'react-icons/fa';
import * as ChartUtils from '../../../utils/chartjsUtils';
import { mdDate } from '../../../utils/date';
import heapAnalytics from '../../../utils/heapAnalytics';
import { setSideSheet } from '../../../actions/SideSheets';
import { SHEET_KEY_DAILY_RECORDS } from '../../../constants/sideSheets';
import analytics from '../../../store/reducers/analytics';

const DailyUsage = ({
  collapseNav,
  dataType,
  setDataType,
  rawData,
  numDaysAgo = 60,
  onChangeUsageDate,
  callbackPrimaryAction
}) => {
  const chartRef = useRef();
  const dispatch = useDispatch();
  // Redux
  const page = useSelector((state) => state.page);
  // Records
  const isDataPresent = Array.isArray(rawData) && rawData?.length > 0;

  const preppedData = useMemo(() => {
    const labels = [];
    const dataset = rawData?.map((items) => {
      return {
        ...items,
        lineData: items.data.map((dateData) => {
          const formattedDate = mdDate(dateData.date);

          if (!labels.includes(formattedDate)) {
            labels.push(formattedDate);
          }

          return {
            x: formattedDate,
            y: dateData.records.length,
            dataKey: formattedDate
          };
        })
      };
    });

    return {
      labels,
      dataset
    };
  }, [rawData]);

  const chartData = useMemo(() => {
    return {
      labels: preppedData?.labels,
      datasets: preppedData?.dataset.map((data, index) => ({
        label: data.name,
        data: data.lineData,
        fill: false,
        borderColor: ChartUtils.namedColor(index),
        backgroundColor: ChartUtils.transparentize(
          ChartUtils.namedColor(index),
          0.5
        ),
        tension: 0.1
      }))
    };
  }, [preppedData]);

  // const legendText = useMemo(() => {
  //   let text = `Logbook ${dataType}`;

  //   if (canAccessAllLocations(role)) {
  //     text += ` per ${locationTerminology({ orgType }).text}`;
  //   }

  //   return text;
  // }, [dataType, orgType, role]);

  const onClickPlotLine = (event) => {
    const eventData = getElementAtEvent(chartRef.current, event);

    heapAnalytics.track(
      'Training Program - Dashboard - VELA Usage - Daily Records - Open'
    );

    if (eventData.length) {
      const dataObj =
        chartData.datasets[eventData[0].datasetIndex].data[eventData[0].index];

      dispatch(
        setSideSheet({
          key: SHEET_KEY_DAILY_RECORDS,
          data: {
            recordsDate: dataObj?.dataKey,
            callbackPrimaryAction
          }
        })
      );
    }
  };

  const handlePrint = () => {
    // https://www.chartjs.org/docs/latest/developers/api.html#tobase64image-type-quality
    const url = chartRef.current.toBase64Image();
    const myWindow = window.open('', 'myWindow', 'width=1000,height=600');

    heapAnalytics.track('Training Program - Dashboard - VELA Usage - Print');

    myWindow.document.write(
      `<h3 style="text-align:center">${page?.title} Analytics</h3><img src="${url}" onload="window.print()" width="100%" />`
    );
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
        // ticks: {
        //   stepSize: 1
        // }
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div
          className="card"
          style={{ height: '400px' }}
        >
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="h6 m-0 d-flex align-items-center font-weight-bold">
              <FaCalendarAlt />
              <span className="ml-2 d-none d-sm-flex">
                {/* Views & Clicks */}
                Views
              </span>
            </div>

            <div className="d-flex align-items-center">
              <select
                name="gteDate"
                value={numDaysAgo}
                className="form-control ml-2"
                style={{
                  width: '140px'
                }}
                onChange={onChangeUsageDate}
              >
                <option value={30}>Last 30 days</option>
                <option value={60}>Last 60 days</option>
                <option value={90}>Last 90 days</option>
              </select>

              <select
                name="dataType"
                value={dataType}
                className="form-control ml-2"
                disabled
                style={{
                  width: '110px'
                }}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setDataType(value);
                }}
              >
                <option value="Views">Views</option>
                <option value="Clicks">Clicks</option>
              </select>
            </div>

            <button
              className="btn btn-sm btn-outline-primary ml-2"
              onClick={handlePrint}
              disabled={!isDataPresent}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPrint />
                <span className="ml-1 d-none d-lg-inline">Print</span>
              </span>
            </button>
          </div>
          <div className="card-body h-100 position-relative">
            {isDataPresent ? (
              <Chart
                key={collapseNav}
                type="line"
                ref={chartRef}
                data={chartData}
                options={options}
                onClick={onClickPlotLine}
              />
            ) : (
              <>
                {analytics?.fetched ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center mt-4 mb-2">
                      <FaChartLine
                        size={70}
                        className="text-muted"
                      />
                    </div>
                    <h5 className="text-center font-weight-bold mb-4">
                      This chart is empty!
                    </h5>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyUsage;
