import React from 'react';

const ItemIdsCell = ({ value: itemIds = [] }) => {
  return (
    // row
    <div>{itemIds.length}</div>
  );
};

export default ItemIdsCell;
