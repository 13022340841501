import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ORG_CONSOLE_RESOURCES } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';

const TitleCell = ({ value: title, row }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const groupTags = useSelector((state) => state.groupTags);
  // Organization
  const orgId = organization?.id || null;
  // Row
  const tagId = row?.original?.id || null;

  const routeConfig = {
    route: `${ORG_CONSOLE_RESOURCES}`,
    urlParams: {
      orgId,
      groupTags: groupTags ? [...groupTags, tagId].join(',') : tagId
    }
  };

  return (
    <div className="d-flex align-items-center">
      <i
        style={{ fontSize: '1.1rem' }}
        className="fas fa-tag text-muted"
      />
      <Link
        title={title}
        className="btn btn-sm btn-link d-flex align-items-center text-ships-officer"
        to={routeWithOrgId(routeConfig)}
      >
        {title}
      </Link>
    </div>
  );
};

export default TitleCell;
