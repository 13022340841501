import {
  SET_MATERIAL,
  RESET_MATERIAL,
  SET_MATERIAL_FILES,
  RESET_MATERIAL_FILES
} from '../../actions/types';
import materialState from '../initialState/materialState';

const initialState = {
  ...materialState,
  cdnFiles: null
};

export default function material(state = initialState, action) {
  switch (action.type) {
    case SET_MATERIAL:
      return {
        ...state,
        ...action.material,
        cdnFiles: action.cdnFiles
      };
    case SET_MATERIAL_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_MATERIAL_FILES:
      return {
        ...state,
        cdnFiles: initialState.cdnFiles
      };
    case RESET_MATERIAL:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
