import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromArray
} from '@apprentage/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import SelectMenu from './SelectMenu';

const LocationSelect = ({
  id = 'selectedLocationId',
  name = 'selectedLocationId',
  onChange,
  data,
  className = '',
  defaultValue = '',
  isMulti
}) => {
  // Redux
  const dictionary = useSelector((state) => state.dictionary);
  // Options
  const options = reactSelectOptionsFromArray(data, 'name');
  const defaultVal = reactSelectDefaultValue(defaultValue, options);
  const defaultVals = reactSelectDefaultValues(defaultValue, options);

  return (
    <div className={className}>
      <SelectMenu
        id={id}
        name={name}
        options={options}
        defaultValue={isMulti ? defaultVals : defaultVal}
        isClearable
        placeholder={`Choose ${dictionary?.location}...`}
        onChange={onChange}
        isMulti={isMulti}
      />
    </div>
  );
};

export default LocationSelect;
