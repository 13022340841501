import React from 'react';
import { withAuthentication } from '../Session';
import TurbineAdmin from './TurbineAdmin';
import '../../styles/colors.css';
import '../../styles/cards.css';
import '../../styles/buttons.css';
import '../../styles/forms.css';
import '../../styles/tables.css';
import '../../styles/navs.css';
import '../../styles/tailwind-borders.css';
import '../../styles/modals.css';
import '../../styles/demoMode.css';
import '../../styles/embed.css';
import '../../styles/print.css';
import '../../styles/manageContent.css';
import '../../styles/timeline.css';
import '../../styles/three-dot-loader.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './style.css';

const App = () => {
  return <TurbineAdmin />;
};

export default withAuthentication(App);
