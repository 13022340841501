import React, { useState } from 'react';
import UsersAtRisk from './UsersAtRisk';
import UsersNoLogbook from './UserNoLogbook';
import Tabs from './Tabs';

const Monitoring = ({ title }) => {
  const [activeTab, setActiveTab] = useState('atRisk');
  return (
    <>
      {title && (
        <div className="my-4">
          <div className="row">
            <div className="col">
              <div className="h3 m-0 font-weight-bold">{title}</div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Tabs
          className="d-flex justify-content-between"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <div className="row mb-4">
          <div className="col-12 mb-4">
            {activeTab === 'atRisk' && (
              <UsersAtRisk className="bg-white shadow p-3 border-left border-right border-bottom rounded" />
            )}

            {activeTab === 'noLogbook' && (
              <UsersNoLogbook className="bg-white shadow p-3 border-left border-right border-bottom rounded" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Monitoring;
