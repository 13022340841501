import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { isProgramManager, isProgramTrainer } from '@apprentage/utils';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  getApprenticeshipUser,
  resetApprenticeship,
  resetApprenticeshipUser
} from '../../../../../actions/Apprenticeships';
import withOrgConsole from '../../../../App/withOrgConsole';

import { getLogbook, resetLogbook } from '../../../../../actions/Logbooks';
import TraineesBlock from './TraineesBlock';
import {
  canAccessNoteUser,
  canAccessProgramTrainees,
  canHaveLogbook,
  canHaveTrainers
} from '../../../../../permissions/access';
import canHaveTrainees from '../../../../../permissions/access/canHaveTrainees';
import { getEmployerAgreements } from '../../../../../actions/EmployerAgreements';
import { fetchLocation } from '../../../../../services/locations';
import {
  getApprenticeAgreements,
  resetApprenticeAgreement
} from '../../../../../actions/ApprenticeAgreements';
import { getNoteMaterials } from '../../../../../actions/Materials';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import UserOrgConsoleSubheader from './UserOrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import InfoBlock from './InfoBlock';
import UserTabs from './UserTabs';
import TrainersBlock from './TrainersBlock';
import UserChecklist from './UserChecklist';
import Vitals from './Records/Vitals';
import LastActivity from './Vitals/LastActivity';
import Notes from '../../../../Notes';
import VelaInsights from '../../../../VelaInsights';

const ApprenticeshipUser = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const noteUserMaterials = useSelector((state) => state.noteUserMaterials);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Training Program User
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipUserId = params?.apprenticeshipUserId || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserRole = useMemo(() => {
    return apprenticeshipUser?.role || [];
  }, [apprenticeshipUser?.role]);
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Note
  const noteType = 'user';
  const noteTypeId = apprenticeshipUser?.id;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, [apprenticeshipId, apprenticeshipUserId, dispatch]);

  useEffect(() => {
    if (
      apprenticeshipId &&
      apprenticeshipUserId &&
      canHaveLogbook(apprenticeshipUserRole)
    ) {
      dispatch(
        getLogbook({
          apprenticeshipId,
          userId: apprenticeshipUserId,
          orgId,
          logbookHours: true,
          logbookCompHours: true
        })
      );
    }
  }, [
    apprenticeshipId,
    apprenticeshipUserId,
    apprenticeshipUserRole,
    dispatch,
    orgId
  ]);

  useEffect(() => {
    if (apprenticeshipUserId) {
      dispatch(
        getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId, orgId)
      ).then((response) => {
        // Apprentice Agreements
        if (response?.userProfileId && apprenticeshipId) {
          dispatch(
            getApprenticeAgreements({
              userProfileId: response?.userProfileId,
              userId: apprenticeshipUserId,
              apprenticeshipId
            })
          );
        }
        // TODO add appropriate resets
        if (response?.locationId) {
          fetchLocation({ locationId: response.locationId }).then(
            (responseLocation) => {
              if (responseLocation?.employerProfileId) {
                dispatch(
                  getEmployerAgreements({
                    employerProfileId: responseLocation.employerProfileId,
                    apprenticeshipId
                  })
                );
              }
            }
          );
        }
      });
    }

    return function cleanup() {
      const { href } = window.location;

      // Only reset if not going to apprentice user route
      if (
        !href.includes(`${apprenticeshipRoute}/users/${apprenticeshipUserId}`)
      ) {
        dispatch(resetApprenticeshipUser());
        dispatch(resetApprenticeAgreement());
        dispatch(resetLogbook());
      }

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [
    apprenticeshipId,
    apprenticeshipRoute,
    apprenticeshipUserId,
    dispatch,
    orgId
  ]);

  const retrieveNoteMaterials = useCallback(() => {
    if (noteTypeId && canAccessNoteUser(role)) {
      dispatch(
        getNoteMaterials({
          orgId,
          noteTypeId,
          noteType
        })
      );
    }
  }, [dispatch, noteTypeId, orgId, role]);

  const showNotes = useMemo(() => {
    if (canAccessNoteUser(role)) {
      return (
        Array.isArray(noteUserMaterials?.list) &&
        noteUserMaterials?.list.length > 0
      );
    }

    return false;
  }, [noteUserMaterials?.list, role]);

  useEffect(() => {
    retrieveNoteMaterials();
  }, [retrieveNoteMaterials]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program" />;
  }

  if (apprenticeshipUserId && !apprenticeship.user) {
    return <Loading text="Loading Training Program User" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <VelaInsights
            className="mb-3"
            badgeText="beta"
            badgeClassName="badge-dark py-1"
            showPiiBadge
            contextScope="trainingUserDashboard"
            apprenticeshipId={apprenticeshipId}
            orgId={orgId}
            userId={apprenticeship?.user?.id}
            userName={apprenticeship?.user?.name}
            userRole={apprenticeship?.user?.role.join(',')}
            daysAgo={30}
          />

          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="users" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <UserOrgConsoleSubheader showMenu />

            <UserTabs active="info" />

            <div className="border-left border-right border-bottom p-3">
              <div className="row d-flex">
                <div className="col-12 col-md-6 mb-3">
                  <InfoBlock className="" />
                  {showNotes && (
                    <>
                      <LastActivity
                        className="mt-3"
                        slim
                      />
                      {/* {!isProgramManager(apprenticeshipUserRole) && !isProgramTrainer(apprenticeshipUserRole) && (
                        <Vitals
                          className='mt-3'
                          size="sm"
                          showHours
                        />
                      )} */}
                    </>
                  )}
                </div>

                {showNotes ? (
                  <div className="col-12 col-md-6">
                    <Notes
                      noteType={noteType}
                      noteTypeId={noteTypeId}
                      list={noteUserMaterials?.list}
                      pagination={noteUserMaterials?.pagination}
                    />
                  </div>
                ) : (
                  <div className="col-12 col-md-6">
                    <LastActivity className="mb-3" />

                    {!isProgramManager(apprenticeshipUserRole) &&
                      !isProgramTrainer(apprenticeshipUserRole) && (
                        <Vitals
                          size="md"
                          showHours
                        />
                      )}
                  </div>
                )}
              </div>
            </div>

            {!isProgramManager(apprenticeshipUserRole) &&
              !isProgramTrainer(apprenticeshipUserRole) && <UserChecklist />}

            {canHaveTrainers(apprenticeshipUserRole) && <TrainersBlock />}
            {canHaveTrainees(apprenticeshipUserRole) &&
              canAccessProgramTrainees(role) && <TraineesBlock />}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUser);
