import { RESET_JOB_PROFILE, SET_JOB_PROFILE } from '../../actions/types';
import jobProfileState from '../initialState/jobProfileState';

const initialState = {
  ...jobProfileState,
  fetched: false
};

export default function jobProfile(state = initialState, action) {
  switch (action.type) {
    case SET_JOB_PROFILE:
      return {
        ...state,
        ...action.jobProfile,
        fetched: true
      };
    case RESET_JOB_PROFILE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
