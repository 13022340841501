import React, { useState } from 'react';
import { ICON_CHECK_CIRCLE } from '@apprentage/components/dist/constants/icons';
import { updateUser } from '../../../services/user';

const CertificateAcceptButton = ({
  userId,
  certificateId,
  acceptedCertificateCourseId
}) => {
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const onClick = () => {
    setLoading(true);

    updateUser(
      {
        certificateId,
        acceptedCertificateCourseId
      },
      userId
    )
      .then(() => {
        setAccepted(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  if (accepted) {
    return (
      <div
        className="btn btn-sm btn-success"
        disabled
      >
        <i className={`${ICON_CHECK_CIRCLE} mr-1`} /> Accepted
      </div>
    );
  }

  return (
    <button
      onClick={onClick}
      className="btn btn-sm btn-outline-success"
      disabled={loading}
      type="button"
    >
      {loading ? (
        'Updating...'
      ) : (
        <span>
          <i className={`${ICON_CHECK_CIRCLE} mr-1`} /> Accept
        </span>
      )}
    </button>
  );
};

export default CertificateAcceptButton;
