import React from 'react';
import { useSelector } from 'react-redux';

const ClassificationTags = ({ data = {}, showAutoGenTag = false }) => {
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const classificationIds = data ? Object.keys(data) : [];

  if (classificationIds.length === 0) {
    return null;
  }

  return (
    <div className="mt-2">
      {showAutoGenTag && (
        <span className="badge badge-info mr-2">
          <i
            className="fa fa-bolt"
            style={{ fontSize: '.7rem ' }}
            aria-hidden="true"
          />
        </span>
      )}

      {classificationIds.map((classificationId, i) => (
        <span
          key={`classificationId-${i}`}
          style={{ fontSize: '.8rem' }}
        >
          <span className="text-muted badge badge-light border mr-2 mb-2">
            {apprenticeship?.classifications[classificationId]}
          </span>
        </span>
      ))}
    </div>
  );
};

export default ClassificationTags;
