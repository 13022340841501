import React, { useEffect, useState } from 'react';
import { fetchMaterials } from '../../../../services/materials';
import { IMAGE_TOOLKIT_EMPTY } from '../../../../constants/assets';
import ListView from '../../../lists/MaterialsList/ListView';

const MaterialsModule = ({ orgId, ids }) => {
  // Local State
  const [materialsList, setMaterialsList] = useState([]);
  const [showNoDataUi, setShowNoDataUi] = useState(false);

  useEffect(() => {
    if (Array.isArray(ids) && ids?.length > 0) {
      fetchMaterials({
        orgId,
        ids,
        sortByIds: ids
      }).then((response) => {
        const items = response?.items || [];
        if (items.length > 0) {
          setShowNoDataUi(false);
        } else {
          setShowNoDataUi(true);
        }
        setMaterialsList(items);
      });
    } else {
      setMaterialsList([]);
      setShowNoDataUi(true);
    }
  }, [orgId, ids?.length, ids]);

  if (showNoDataUi) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center justify-content-center"
        style={{
          minHeight: '400px'
        }}
      >
        <img
          src={IMAGE_TOOLKIT_EMPTY}
          className="mb-3"
          height={150}
          style={{
            height: '150px'
          }}
          alt="No Tools"
        />
        <p className="mt-1 mb-3">No Tools.</p>
      </div>
    );
  }
  return <ListView data={materialsList} />;
};

export default MaterialsModule;
