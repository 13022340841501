import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaLock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { canManagePage } from '../../../permissions/manage';
import { updatePageLink } from '../../../services/pageLinks';
import { getPageLink } from '../../../actions/PageLinks';
import { MODAL_KEY_MANAGE_ACCESS_CODE } from '../../../constants/modals';
import { setCurrentModal } from '../../../actions/Modals';

const DotMenuLink = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const pageLink = useSelector((state) => state.pageLink);
  // Current User
  const role = currentUser?.role || [];
  // Permissions
  const managePage = canManagePage(role);

  if (!managePage) {
    return null;
  }

  const onClickManageAccessCode = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_ACCESS_CODE,
        data: {
          accessCode: pageLink?.accessCode,
          accessCodeLength: 6
        },
        callbackPrimaryAction: (newAccessCode) => {
          updatePageLink(
            {
              accessCode: newAccessCode,
              hasAccessCode: true
            },
            pageLink?.id
          ).then(() => {
            toast.success('Access Code updated!');
            dispatch(getPageLink(pageLink?.id));
          });
        },
        callbackSecondaryAction: () => {
          updatePageLink(
            {
              accessCode: null,
              hasAccessCode: null
            },
            pageLink?.id
          ).then(() => {
            toast.success('Access Code removed!');
            dispatch(getPageLink(pageLink?.id));
          });
        }
      })
    );
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-white rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />

          <button
            className="dropdown-item border-bottom py-2 px-3"
            type="button"
            onClick={onClickManageAccessCode}
          >
            <span className="d-flex align-items-center">
              <FaLock />
              <span className="ml-2">Edit Access Code</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DotMenuLink;
