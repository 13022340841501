import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import { fetchFlags } from '../../../services/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import {
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../../constants/flagsmith';
import Modal from '../../Modal';
import RecordForm from './RecordForm';
import './style.css';

const ManageRecord = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal Data
  const modalData = currentModal?.data || {};
  const record = modalData?.record || null;
  // Local State
  const [checkingFlags, setCheckingFlags] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onErrorCallback = () => {
    handleClose();
  };

  const onSuccessCallback = (data) => {
    if (currentModal?.callbackPrimaryAction) {
      currentModal.callbackPrimaryAction(data);
    }

    handleClose();
  };

  const checkFlags = useCallback(() => {
    if (!checkingFlags) {
      setCheckingFlags(true);
      fetchFlags().then((fetchedFlags) => {
        if (
          fetchedFlags?.logbooks_readonly?.enabled &&
          fetchedFlags?.logbooks_readonly?.value
        ) {
          dispatch(
            showFeatureFlagNoticeModal({
              modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
              modalBody: NOTICE_MAINTENANCE_MODE_BODY
              // preventClose: true
            })
          );
        }
      });
    }
  }, [checkingFlags, dispatch]);

  useEffect(() => {
    checkFlags();
  }, [checkFlags]);

  if (!record?.id || !record?.logbookId || !record?.apprenticeshipId) {
    return null;
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <RecordForm
        onSuccessCallback={onSuccessCallback}
        onCancelCallback={handleClose}
        onErrorCallback={onErrorCallback}
      />
    </Modal>
  );
};

export default ManageRecord;
