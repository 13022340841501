import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_COURSE } from '@apprentage/constants';
import { createCourse, routeWithClassId } from '../../../services/courses';
import { resetCurrentModal } from '../../../actions/Modals';
import { CLASS_COLOR_DEFAULT } from '../../../constants/globals';
import {
  CLASS_CONSOLE_SETTINGS,
  MANAGE_CURRENT_ENTRY
} from '../../../constants/routes';
import { manageEntry } from '../../../services/entry';
import Modal from '../../Modal';
import ChooseCourseType from './ChooseCourseType';
import TitleDescription from './TitleDescription';
import Branding from './Branding';
import { MODAL_KEY_CREATE_COURSE } from '../../../constants/modals';

const CreateCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  // Current Modal
  const modalData = currentModal?.data;
  // Local State
  const [currentState, setState] = useState({
    currentStep: modalData?.currentStep || 0,
    enableCourseContent:
      modalData?.enableCourseContent !== undefined
        ? modalData?.enableCourseContent
        : undefined,
    externalCourseUrl: undefined,
    title: undefined,
    description: undefined,
    classColor: CLASS_COLOR_DEFAULT,
    classImage: undefined
  });
  const key = MODAL_KEY_CREATE_COURSE;

  const updateState = (newStateData) => {
    setState({
      ...currentState,
      ...newStateData
    });
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const saveData = () => {
    const { currentStep, ...stateData } = currentState;
    const dataToSave = {
      orgId,
      courseAdminUserIds: [userId],
      isPublic: false,
      locked: false,
      openEnrollment: true,
      trackAttendance: false,
      userId,
      ...stateData
    };

    toast.info('Saving Course...');

    createCourse(dataToSave).then((newCourse) => {
      toast.success('Course Saved!');

      const classId = newCourse?.sys?.id || null;
      let route =
        MANAGE_CURRENT_ENTRY +
        manageEntry({
          manageType: 'edit',
          classId,
          contentType: 'class'
        });

      // External Course
      if (stateData.enableCourseContent === false && externalCourseUrl) {
        // Redirect to Course Settings
        route = routeWithClassId({
          route: CLASS_CONSOLE_SETTINGS,
          classId
        });
      }

      history.replace(route);

      setTimeout(() => {
        handleClose();
      }, 500);
    });
  };

  const {
    currentStep,
    // Step 1
    enableCourseContent,
    externalCourseUrl,
    // Step 2
    title,
    description,
    // Step 3
    classColor,
    classImage
  } = currentState;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      close={handleClose}
      theme="dark"
    >
      <div className="card border-0">
        <div className="card-header bg-dark">
          <h5>
            <i className={`mr-1 ${ICON_COURSE}`} /> Create Course
          </h5>
        </div>

        {currentStep === 0 && (
          <ChooseCourseType
            updateState={updateState}
            data={{
              enableCourseContent,
              externalCourseUrl
            }}
          />
        )}

        {currentStep === 1 && (
          <TitleDescription
            updateState={updateState}
            data={{
              enableCourseContent,
              externalCourseUrl,
              title,
              description
            }}
          />
        )}

        {currentStep === 2 && (
          <Branding
            saveData={saveData}
            updateState={updateState}
            data={{
              enableCourseContent,
              externalCourseUrl,
              title,
              classImage,
              classColor
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default CreateCourse;
