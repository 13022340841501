import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { FaGear } from 'react-icons/fa6';
import { deleteEntry } from '../../../services/entry';
import { canDeleteEntry } from '../../../services/currentUser';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';
import { removeEntryFromTopic } from '../../../services/topics';
import { setCurrentTopic } from '../../../actions/Topic';
import { manageQuizOutcomes } from '../../../actions/QuizOutcomes';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';

const DeleteQuiz = ({
  contentId,
  title,
  contentType = 'quiz',
  buttonText = 'Delete Quiz'
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  const currentTopic = useSelector((state) => state.currentTopic);
  // Course
  const classId = course?.id || null;
  const topicId = currentTopic?.id || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteEntry(role)) {
      return false;
    }

    if (currentQuiz.cdnFiles && currentQuiz.cdnFiles.length !== 0) {
      return false;
    }

    if (
      quizOutcomes.list &&
      Array.isArray(quizOutcomes.list) &&
      quizOutcomes.list.length
    ) {
      // Quiz Outcomes exist for this Quiz, do not delete
      return false;
    }

    return true;
  }, [currentQuiz.cdnFiles, quizOutcomes.list, role]);

  const handleDelete = () => {
    // Materials, Challenges && Quizzes
    setLoading(true);
    toast.info('Processing...');
    removeEntryFromTopic({
      entryId: contentId,
      topicId,
      contentType
    })
      .then(() => {
        deleteEntry(contentId)
          .then(() => {
            // Update Topic
            dispatch(setCurrentTopic({ topicId })).then(() => {
              toast.success(`${title} Deleted!`);
              // Redirect User back to Topic Edit UI
              history.replace(
                `${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=topic&classId=${classId}&topicId=${topicId}&entryId=${topicId}`
              );
            });
          })
          .catch((error) => {
            console.error(`deleteEntry Entry: ${contentType}`, error);
          });
      })
      .catch((error) => {
        console.error('removeEntryFromTopic', error);
      });
  };

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [contentId]);

  return (
    <DeleteContent
      id={contentId}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className="mt-2 mb-3"
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all of this Quiz's Files{' '}
          <TaskStatus
            statusType="files"
            complete={
              !currentQuiz.cdnFiles || currentQuiz.cdnFiles.length === 0
            }
          />
        </li>
        <li className="mb-2">
          Delete all of this Quiz's Quiz Outcomes{' '}
          <TaskStatus
            statusType="submissions"
            complete={
              Array.isArray(quizOutcomes.list) && quizOutcomes.list.length === 0
            }
          />
          {quizOutcomes.list &&
            Array.isArray(quizOutcomes.list) &&
            quizOutcomes.list.length !== 0 && (
              <ul className="mt-2 pl-3">
                <li className="font-weight-bold">
                  <button
                    className="btn-link d-flex align-items-center"
                    type="button"
                    onClick={() => {
                      dispatch(manageQuizOutcomes(currentQuiz?.id));
                    }}
                  >
                    <FaGear
                      className="text-ships-officer mr-1"
                      size={15}
                    />
                    <span>
                      {pluralize(
                        'Review Quiz Outcome',
                        quizOutcomes.list.length
                      )}
                    </span>
                    <span className="ml-1">({quizOutcomes.list.length})</span>
                  </button>
                </li>
              </ul>
            )}
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteQuiz;
