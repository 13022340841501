import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { isOwner, percentComplete } from '@apprentage/utils';
import { FaArrowCircleRight } from 'react-icons/fa';
import { IMAGE_TURBINE_RETRO_ICON_WHITE } from '../../../constants/assets';
import { withAuthorization } from '../../../components/Session';
import {
  ONBOARDING_ORG_CONTEXT,
  ONBOARDING_WORKFORCE_SPACE
} from '../../../constants/routes';
import withOrgConsole from '../../../components/App/withOrgConsole';
import ProgressBars from '../../../components/ProgressBars';
import InputChangeValue from '../InputChangeValue';
import Apps from './Apps';
import { appsHash } from './constants';
import { updateUser } from '../../../services/user';
import {
  DASHBOARD_DEFAULT_LAYOUT,
  DASHBOARD_MODULE_TYPE
} from '../../../constants/dashboard';
import { createWidget, fetchWidgets } from '../../../services/widgets';
import {
  createDashboard,
  fetchDashboardByType
} from '../../../services/dashboards';
import {
  createNotification,
  fetchNotifications
} from '../../../services/notifications';

/**
 *
 * Total Onboarding Steps: 6
 *
 * (1) Welcome
 * (5) Team Context
 * (5) Org Context
 * (1) Workforce Space
 *
 */

const TeamContext = () => {
  const history = useHistory();
  const turbineLogo = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Local State
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('contextIsKing');
  const [totalSteps] = useState(7);
  const [currentStep, setCurrentStep] = useState(1);
  // const [hasWebsite, setHasWebsite] = useState(false);
  // const [hasLogo, setHasLogo] = useState(false);
  const [selectedApps, setSelectedApps] = useState(
    currentUser?.contextTeamApps || []
  );
  const [showAppsTertiaryLink, setShowAppsTertiaryLink] = useState(false);
  const [hasWidgetLinks, setHasWidgetLinks] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);

  const percentage = percentComplete({
    current: currentStep,
    total: totalSteps
  });
  // Context
  const [contextTeamProjects, setTeamContextProjects] = useState(
    currentUser?.contextTeamProjects
  );
  const [contextTeamGreat, setTeamContextGreat] = useState(
    currentUser?.contextTeamGreat
  );
  const [contextTeamStruggle, setTeamContextStruggle] = useState(
    currentUser?.contextTeamStruggle
  );
  // Current User
  const userId = currentUser?.id || null;
  const firstName = currentUser?.firstName || null;
  const role = currentUser?.role || [];
  // // Organization
  const orgId = organization?.id || null;

  const appsList = Object.entries(appsHash).map(([key, value]) => ({
    id: key,
    ...value
  }));

  const timeoutAppsTertiaryLink = useCallback(() => {
    if (currentScreen === 'contextTeamApps') {
      if (!showAppsTertiaryLink) {
        setTimeout(() => {
          setShowAppsTertiaryLink(true);
        }, 2000);
      }
    }
  }, [currentScreen, showAppsTertiaryLink]);

  const currentScreenTitle = useMemo(() => {
    switch (currentScreen) {
      case 'contextIsKing':
        return `Hi ${firstName}`;
      case 'contextTeamProjects':
      case 'contextTeamGreat':
      case 'contextTeamStruggle':
      case 'contextTeamApps':
        return 'Team Context';
      default:
        return 'Turbine Workforce';
    }
  }, [currentScreen, firstName]);

  const saveAppsAsWidgetLinks = (selectedApp) => {
    return new Promise((resolve) => {
      const dataToSave = {
        type: 'link',
        title: selectedApp?.title,
        body: selectedApp?.body,
        image: selectedApp?.image || null,
        linkTarget: '_blank',
        url: selectedApp?.url,
        isFeatured: true
      };

      createWidget({
        ...dataToSave,
        orgId
      }).then((response) => {
        console.log('widget', response);
        resolve(response);
      });
    });
  };

  const saveContext = (value) => {
    // TODO save context to user
    const dataToSave = {
      ...(contextTeamProjects ? { contextTeamProjects } : {}),
      ...(contextTeamGreat ? { contextTeamGreat } : {}),
      // If the user is not an Owner, value is passed as argument
      // to get around react state cycles not saving fast enough
      ...(contextTeamStruggle
        ? { contextTeamStruggle: value || contextTeamStruggle }
        : {}),
      ...(!hasWidgetLinks && selectedApps.length > 0
        ? { contextTeamApps: selectedApps }
        : {})
    };

    const hasData = Object.entries(dataToSave);

    if (hasData.length > 0) {
      setLoading(true);

      updateUser(dataToSave, userId).then(() => {
        if (isOwner(role)) {
          /**
           *
           * Create Notification
           * if NONE exist
           *
           */
          if (!hasNotifications) {
            createNotification({
              orgId,
              authorId: currentUser?.id,
              authorUid: currentUser?.uid,
              authorName: currentUser?.name,
              postAsOrg: true,
              message: 'Our new Turbine Workforce Space is live!'
            });
          }

          /**
           *
           * Create Widgets
           * if NONE exist AND user selected some from list
           *
           */

          if (!hasWidgetLinks && selectedApps.length > 0) {
            const promises = selectedApps.map(
              async (selectedAppId) =>
                await saveAppsAsWidgetLinks(appsHash[selectedAppId])
            );

            Promise.all(promises).then(async (responses) => {
              /**
               *
               * Create Dashboard
               * if dashboard feature is enabled AND NONE exist
               *
               */
              if (organization?.dashboard) {
                let selectedIds = [];

                if (Array.isArray(responses)) {
                  selectedIds = responses.map((response) => response?.id);
                }

                const dashboard = await fetchDashboardByType({
                  orgId,
                  type: 'organization'
                });

                if (!dashboard) {
                  createDashboard({
                    orgId,
                    type: 'organization',
                    activeLayout: DASHBOARD_DEFAULT_LAYOUT,
                    zone1: [
                      {
                        moduleType: DASHBOARD_MODULE_TYPE.notifications,
                        id: uuid(),
                        showOnlyPinned: false,
                        limit: 3
                      }
                    ],
                    zone2: [
                      {
                        moduleType: DASHBOARD_MODULE_TYPE.links,
                        id: uuid(),
                        selectedIds
                      }
                    ]
                  }).finally(() => {
                    setLoading(false);
                    history.push(ONBOARDING_WORKFORCE_SPACE);
                  });
                  return;
                }
              }

              /**
               *
               * Redirect to Organization Context
               *
               */
              setLoading(false);
              if (
                !organization?.url ||
                !organization?.orgIndustry ||
                !organization?.orgLogo
              ) {
                history.push(ONBOARDING_ORG_CONTEXT);
                return;
              }

              history.push(ONBOARDING_WORKFORCE_SPACE);
            });
          }
          /**
           *
           * Redirect to Organization Context
           *
           */
          setLoading(false);
          if (
            !organization?.url ||
            !organization?.orgIndustry ||
            !organization?.orgLogo
          ) {
            history.push(ONBOARDING_ORG_CONTEXT);
            return;
          }
          history.push(ONBOARDING_WORKFORCE_SPACE);
        } else {
          /**
           *
           * Redirect to Workforce Space Screen
           *
           */
          setLoading(false);
          history.push(ONBOARDING_WORKFORCE_SPACE);
        }
      });
    } else {
      /**
       *
       * Redirect to Workforce Space Screen
       *
       */
      history.push(ONBOARDING_WORKFORCE_SPACE);
    }
  };

  useEffect(() => {
    document.body.classList.add('onboarding');

    return function cleanup() {
      document.body.classList.remove('onboarding');
    };
  }, []);

  useEffect(() => {
    if (turbineLogo?.current) {
      setTimeout(() => {
        // scale-up
        turbineLogo.current.classList.add('scale-up');
      }, 250);
    }
  }, []);

  useEffect(() => {
    timeoutAppsTertiaryLink();
  }, [timeoutAppsTertiaryLink]);

  useEffect(() => {
    fetchWidgets({ orgId, type: 'link' }).then((response) => {
      setHasWidgetLinks(Boolean(response?.pagination?.total || 0));
    });
  }, [orgId]);

  useEffect(() => {
    fetchNotifications({ orgId }).then((response) => {
      setHasNotifications(Boolean(response?.total || 0));
    });
  }, [orgId]);

  return (
    <div className="h-100 row">
      <div
        className="position-fixed"
        style={{
          top: 0,
          width: '100%',
          left: 0
        }}
      >
        <ProgressBars
          size="sm"
          className="w-100 border-radius-0"
          data={[
            {
              className: 'bg-000',
              valueNow: currentStep,
              valueMax: totalSteps,
              valueMin: 0,
              style: { width: percentage },
              text: ''
            }
          ]}
        />
      </div>
      <div className="col">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <span
            ref={turbineLogo}
            className="turbine-logo overflow-hidden shadow"
            style={{
              borderRadius: '1.5rem'
            }}
          >
            <img
              src={IMAGE_TURBINE_RETRO_ICON_WHITE}
              height={80}
              style={{
                height: '80px'
              }}
              alt="Turbine"
            />
          </span>
          <h5 className="my-4 font-weight-bold">{currentScreenTitle}</h5>

          <div className="action-box">
            {currentScreen === 'contextIsKing' && (
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <div className="action-box">
                  <div>
                    <p className="m-0 text-center">Welcome to Turbine, where</p>

                    <h4 className="text-center mb-4">
                      <span>
                        <strong>context</strong>{' '}
                        <s className="opacity-50">content</s> is King.
                      </span>
                    </h4>

                    <p className="text-center">
                      Context is the secret sauce that
                      <br />
                      makes information useful to you.
                    </p>

                    <p className="text-center m-0">
                      Let's configure your context.
                    </p>

                    <div className="mt-4 d-flex justify-content-center text-nowrap">
                      <button
                        className="btn btn-lg bg-000 text-white position-relative overflow-hidden"
                        type="button"
                        onClick={() => {
                          setCurrentScreen('contextTeamProjects');
                          setCurrentStep(2);
                        }}
                      >
                        <span
                          style={{ zIndex: 2 }}
                          className="d-flex align-items-center position-relative"
                        >
                          <span className="position-relative mr-2">
                            Continue
                          </span>
                          <FaArrowCircleRight />
                        </span>
                        <span className="start-btn-pulse" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {currentScreen === 'contextTeamProjects' && (
              <div>
                <InputChangeValue
                  title="What kind of projects does your team work on?"
                  helpText="Ex: Civil Engineering on-site design."
                  textarea
                  autoFocus
                  defaultValue={contextTeamProjects}
                  primaryActionText="Continue"
                  tertiaryActionText="I'll do this later"
                  callbackPrimaryAction={(value) => {
                    setTeamContextProjects(value);
                    setCurrentScreen('contextTeamGreat');
                    setCurrentStep(2);
                  }}
                  callbackTertiaryAction={() => {
                    setCurrentScreen('contextTeamGreat');
                    setCurrentStep(2);
                  }}
                />
              </div>
            )}

            {currentScreen === 'contextTeamGreat' && (
              <div>
                <InputChangeValue
                  title="What is your team great at doing?"
                  helpText="Ex: We're a creative powerhouse and great at customer success"
                  textarea
                  autoFocus
                  defaultValue={contextTeamGreat}
                  primaryActionText="Continue"
                  tertiaryActionText="I'll do this later"
                  callbackPrimaryAction={(value) => {
                    setTeamContextGreat(value);
                    setCurrentScreen('contextTeamStruggle');
                    setCurrentStep(3);
                  }}
                  callbackTertiaryAction={() => {
                    setCurrentScreen('contextTeamStruggle');
                    setCurrentStep(3);
                  }}
                />
              </div>
            )}

            {currentScreen === 'contextTeamStruggle' && (
              <div>
                <InputChangeValue
                  title="What does your team struggle with?"
                  helpText="Ex: We're not great at debriefing or disseminating lessons learned"
                  textarea
                  defaultValue={contextTeamStruggle}
                  primaryActionText="Continue"
                  tertiaryActionText="I'll do this later"
                  callbackPrimaryAction={(value) => {
                    setTeamContextStruggle(value);
                    if (isOwner(role) && !hasWidgetLinks) {
                      setCurrentScreen('contextTeamApps');
                      setCurrentStep(4);
                    } else {
                      saveContext(value);
                    }
                  }}
                  callbackTertiaryAction={() => {
                    if (isOwner(role) && !hasWidgetLinks) {
                      setCurrentScreen('contextTeamApps');
                      setCurrentStep(4);
                    } else {
                      saveContext();
                    }
                  }}
                />
              </div>
            )}

            {currentScreen === 'contextTeamApps' && (
              <>
                <div>
                  <h5 className="InputChangeValue-title my-4">
                    What tools does your team use?
                  </h5>

                  <Apps
                    loading={loading}
                    list={appsList}
                    selected={selectedApps}
                    setSelected={(index) => {
                      if (selectedApps.includes(index)) {
                        setSelectedApps((prev) =>
                          prev.filter((a) => a !== index)
                        );
                      } else {
                        setSelectedApps((prev) => [...prev, index]);
                      }
                    }}
                  />

                  <div className="mt-4 d-flex align-items-center justify-content-center">
                    <button
                      className={`btn ${selectedApps.length === 0 ? 'btn-white' : 'bg-000 text-white'} px-5 py-2 text-nowrap position-relative overflow-hidden`}
                      type="button"
                      onClick={() => {
                        setCurrentStep(5);
                        saveContext();
                      }}
                      disabled={selectedApps.length === 0 || loading}
                    >
                      <span
                        style={{ zIndex: 2 }}
                        className="d-flex align-items-center position-relative"
                      >
                        <span className="position-relative mr-2">Continue</span>
                        <FaArrowCircleRight />
                      </span>
                      {selectedApps.length > 0 && (
                        <span className="start-btn-pulse" />
                      )}
                    </button>
                  </div>

                  <div
                    className="mt-3 d-flex align-items-center justify-content-center"
                    style={{
                      opacity:
                        showAppsTertiaryLink && selectedApps.length === 0
                          ? 1
                          : 0
                    }}
                  >
                    <button
                      onClick={() => {
                        setCurrentStep(5);
                        saveContext();
                      }}
                      disabled={
                        (!showAppsTertiaryLink || loading) &&
                        selectedApps.length === 0
                      }
                      className="btn btn-sm btn-link"
                      type="button"
                    >
                      None of these
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  TeamContext,
  true
);
