import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fetchMaterial } from '../../../../services/materials';
import { IMAGE_TOOLKIT_EMPTY } from '../../../../constants/assets';
import MaterialBlock from '../../MaterialBlock';

const MaterialModule = ({ ids, showNoDataUi = false }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  const hasDataIds = useMemo(() => {
    return Array.isArray(ids) && ids?.length > 0;
  }, [ids]);

  useEffect(() => {
    if (hasDataIds) {
      setLoading(true);
      fetchMaterial(ids[0])
        .then((response) => {
          if (response?.id) {
            setData(response);
          }
        })
        .finally(() => {
          setDataFetched(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [hasDataIds, ids]);

  if (loading) {
    return <Skeleton style={{ height: '105px' }} />;
  }

  if (hasDataIds && dataFetched && !data?.id) {
    if (showNoDataUi) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-center justify-content-center"
          style={{
            minHeight: '400px'
          }}
        >
          <img
            src={IMAGE_TOOLKIT_EMPTY}
            className="mb-3"
            height={150}
            style={{
              height: '150px'
            }}
            alt="No Tools"
          />
          <p className="mt-1 mb-3">No Tools.</p>
        </div>
      );
    }

    return;
  }

  return (
    <MaterialBlock
      id={ids[0]}
      body={data?.body}
    />
  );
};

export default MaterialModule;
