import React from 'react';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import Users from '../../ManageContent/Users';

const UsersListAndSelect = ({
  title = '',
  className = '',
  onClickSelectUsers,
  userIds,
  noDataText,
  selectUsersButtonText,
  footerText = ''
}) => {
  return (
    <div className={className}>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <div>
            <div className="h5 m-0">{title}</div>
          </div>

          {onClickSelectUsers && (
            <div className="btn-group align-items-center">
              <button
                className="btn btn-link btn-sm rounded text-ships-officer"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="Manage Menu"
                aria-label="Manage Menu"
              >
                <FaEllipsisH />
              </button>
              <div className="dropdown-menu dropdown-menu-right p-0">
                {/* {canManageLocationManagers(role) && ( */}
                <button
                  className="dropdown-item py-2 d-flex align-items-center"
                  type="button"
                  title={selectUsersButtonText}
                  onClick={onClickSelectUsers}
                >
                  <FaCog />
                  <span className="ml-2">{selectUsersButtonText}</span>
                </button>
                {/* )} */}
              </div>
            </div>
          )}
        </div>

        <div className="card-body">
          <Users
            key={userIds.join(',')}
            userIds={userIds}
            noDataText={noDataText}
            selectUsersButtonText={selectUsersButtonText}
          />
        </div>

        {footerText && <div className="card-footer">{footerText}</div>}
      </div>
    </div>
  );
};

export default UsersListAndSelect;
