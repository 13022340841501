import * as contentful from 'contentful';
import * as contentfulManagement from 'contentful-management';

export const CSPACE = process.env.REACT_APP_CSPACE;

export const referenceType = (id) => {
  return {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id
    }
  };
};

export const referenceTypes = (references) => {
  return references.map((reference) => referenceType(reference.sys.id));
};

export const getClient = () => {
  if (!process.env.REACT_APP_CSPACE || !process.env.REACT_APP_CDT) {
    throw new Error(
      'Missing required environment variables for Contentful client'
    );
  }
  return contentful.createClient({
    space: process.env.REACT_APP_CSPACE,
    accessToken: process.env.REACT_APP_CDT,
    environments: 'master'
  });
};

export const getClientManagement = () => {
  if (!process.env.REACT_APP_CSPACE || !process.env.REACT_APP_CMT) {
    throw new Error(
      'Missing required environment variables for Contentful Management client'
    );
  }
  return contentfulManagement.createClient({
    accessToken: process.env.REACT_APP_CMT
  });
};

/**
 *
 * @param {object} item  // ex, { fields: {...}, sys: {...} }
 */
export const flattenItem = (response) => {
  const fields = response?.fields || null;
  const sys = response?.sys || null;
  const id = response?.sys?.id || null;
  const createdAt = response?.sys?.createdAt || null;
  const updatedAt = response?.sys?.updatedAt || null;
  const contentType = response?.sys?.contentType?.sys?.id || null;
  const data = {
    ...(fields || {})
  };

  if (id) data.id = id;
  if (createdAt) data.createdAt = createdAt;
  if (updatedAt) data.updatedAt = updatedAt;
  if (contentType) data.contentType = contentType;
  if (sys) data.sys = sys; // TODO Deprecate

  return data;
};
/**
 *
 * @param {array of objects} arr // ex, [ { fields: {...}, sys: {...} }, { fields: {...}, sys: {...} } ]
 */
export const flattenItems = (arr) => {
  const flattenedArr = arr.map((item) => flattenItem(item));

  return flattenedArr;
};

/**
 *
 * @param {array of strings} arr ex: ['contentfulIDxYZ']
 * @param {string} type ex: 'Entry', 'Asset'
 *
 */
export const unpublish = (arr, type) => {
  const items = arr.map((id) => referenceType(id, type));

  const unpublishPayload = {
    entities: {
      sys: { type: 'Array' },
      items
    }
  };

  return new Promise((resolve, reject) => {
    getClientManagement()
      .getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) =>
        environment.createUnpublishBulkAction(unpublishPayload)
      )
      .then((bulkActionInProgress) => bulkActionInProgress.waitProcessing())
      .then((bulkActionCompleted) => {
        resolve(bulkActionCompleted);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
