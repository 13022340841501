import { pathOr } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSubscriptions } from '../../../actions/Billing';
import Subscription from './Subscription';

class Subscriptions extends Component {
  componentDidMount() {
    this.props.getSubscriptions().catch((error) => console.warn(error.message));
  }

  render() {
    const { billing } = this.props;
    const subscriptions = pathOr(null, ['subscriptions'], billing);
    const products = pathOr(null, ['products'], billing);

    if (!subscriptions || !products) {
      return null;
    }

    return (
      <div className="invoices-container mt-4">
        <h5>Subscriptions</h5>
        <div className="card overflow-hidden">
          <div className="card-body bg-light">
            {subscriptions.map(
              ({ current_period_end: currentPeriodEnd, plan, quantity }, i) => (
                <Subscription
                  key={i}
                  quantity={quantity}
                  currentPeriodEnd={currentPeriodEnd}
                  productId={plan.product}
                  priceId={plan.price}
                  amount={plan.amount}
                  interval={plan.interval}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ billing }) => ({ billing });

export default connect(mapStateToProps, { getSubscriptions })(Subscriptions);
