import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withAuthorization } from '../Session';
import { setEUnitTracking } from '../../actions/EUnitTracking';
import withConsole from '../App/withConsole';
import Loading from '../Loading';
import Form from './Form';
import CdnFiles from '../ManageContent/CdnFiles';

const ManageEducationUnitTracking = () => {
  const dispatch = useDispatch();
  // redux
  const eUnitTracking = useSelector((state) => state.eUnitTracking);
  // misc
  const queryParams = new URLSearchParams(window.location.search);
  const entryId = queryParams.get('entryId');

  useEffect(() => {
    if (entryId) {
      dispatch(setEUnitTracking(entryId));
    }
  }, []);

  if (entryId && !eUnitTracking?.id) {
    return <Loading text="Loading Tracking Settings..." />;
  }

  return (
    <>
      <Form />

      {eUnitTracking.id && (
        <CdnFiles
          id="eUnitTracking-integration-files"
          contentId={eUnitTracking.id}
          contentType="eUnitTracking"
          files={eUnitTracking.cdnFiles}
          editMenu
        />
      )}
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(ManageEducationUnitTracking);
