import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCloudUploadAlt, FaFan, FaMagic } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import { FaArrowRightLong } from 'react-icons/fa6';
import { VELA_ICON } from '@apprentage/constants';
import {
  templateExecSummary,
  templateExecSummaryTitle,
  templateExplainer,
  templateExplainerTitle,
  templateHowTo,
  templateHowToTitle,
  templateReference,
  templateReferenceTitle,
  templateTutorial,
  templateTutorialTitle
} from '../../../constants/resources';
import { setCurrentModal } from '../../../actions/Modals';
import { MODAL_KEY_UPLOAD_FILES } from '../../../constants/modals';
import { createMaterial, updateMaterial } from '../../../services/materials';
import { mdySimple } from '../../../utils/date';
import {
  queryEngine,
  embedDoc,
  vectorSearchDocTypes
} from '../../../services/openAi';
import { setSideSheet } from '../../../actions/SideSheets';
import { getMaterial } from '../../../actions/Materials';
import { SHEET_KEY_MATERIAL } from '../../../constants/sideSheets';
import { fetchDocuments } from '../../../services/documents';
import { mockRawText } from '../../../constants/mockRawText';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Switch from '../../Switch';
import DotMenu from './DotMenu';
import '../../../styles/scanningAnimation.css';
import './style.css';

const MaterialTemplateSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Current User
  const userId = currentUser?.id || null;
  const locationId = currentUser?.locationId || null;
  // Organization
  const orgId = organization?.id || null;
  // Side Sheet
  const sideSheetData = sideSheet?.data || null;
  const promptType = sideSheetData?.promptType || 'howTo';
  // Local State
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // Animation
  const [showScanningAnimation, setShowScanningAnimation] = useState(false);
  const [animationActionStep, setAnimationActionStep] = useState('loading');
  const [rawTitle, setRawTitle] = useState(
    '01001100 01101111 01110010 01100101 01101101'
  );
  const [rawText, setRawText] = useState(mockRawText);
  // Form Fields
  // const [title, setTitle] = useState('');
  // const [titleAutoGen, setTitleAutoGen] = useState(true);
  const [description, setDescription] = useState('');
  const [descriptionAutoGen, setDescriptionAutoGen] = useState(true);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [additionalInfoAutoGen, setAdditionalInfoAutoGen] = useState(true);

  const onError = (error) => {
    // setEmbedding(false);
    toast.dismiss();
    console.error(error);
    toast.error('Something went wrong, try again.', { autoClose: false });
  };

  const promptTemplateTitle = useMemo(() => {
    switch (promptType) {
      case 'tutorial':
        return templateTutorialTitle;
      case 'explanation':
        return templateExplainerTitle;
      case 'reference':
        return templateReferenceTitle;
      case 'execSummary':
        return templateExecSummaryTitle;
      case 'howTo':
        return templateHowToTitle;
      default:
        return promptType;
    }
  }, [promptType]);

  const promptTemplate = useMemo(() => {
    switch (promptType) {
      case 'tutorial':
        return templateTutorial;
      case 'explanation':
        return templateExplainer;
      case 'reference':
        return templateReference;
      case 'execSummary':
        return templateExecSummary;
      case 'howTo':
      default:
        return templateHowTo;
    }
  }, [promptType]);

  const uploadFile = async () => {
    let promptText = '';
    // if (title) {
    //   promptText += `Title: ${title || promptTemplateTitle}.`;
    // }

    if (description) {
      promptText += `Description: ${description}.`;
    }

    if (additionalInfo) {
      promptText += ` Additional Information: ${additionalInfo}.`;
    }

    const dataToSave = {
      // title: title || `${promptTemplateTitle} ${mdySimple(new Date().toISOString())}`,
      title: `${promptTemplateTitle} ${mdySimple(new Date().toISOString())}`,
      body: null,
      orgId,
      locationId,
      userId,
      enableNewEditor: true,
      promptType,
      promptText: promptText || null,
      isDraft: true,
      fileUploadFlow: true
    };

    createMaterial(dataToSave).then((materialResponse) => {
      dispatch(
        setCurrentModal({
          key: MODAL_KEY_UPLOAD_FILES,
          data: {
            title: 'Upload File',
            contentId: materialResponse?.id,
            contentType: 'materials',
            integration: false,
            multiple: false,
            accept: vectorSearchDocTypes.join(', '),
            callback: (cdnFiles) => {
              const cdnFileIds = cdnFiles.map((cdnFile) => cdnFile.id);

              // Note: Currently only supporting 1 resource
              const resource = cdnFiles.length > 0 ? cdnFiles[0] : null;
              const resourceId = resource?.id || null;

              // Show animation with mock data
              setShowScanningAnimation(true);

              if (resource?.name) {
                setRawTitle(resource?.name);
              }

              updateMaterial(
                {
                  // Note: Currently only supporting 1 resource
                  resourceIds: cdnFileIds.length > 0 ? cdnFileIds : null
                },
                materialResponse?.id
              );

              // Note: Currently only supporting 1 resource
              const promises = cdnFiles.map(async (cdnFile) => [
                await embedDoc({
                  refTable: 'resources',
                  orgId,
                  id: cdnFile?.id,
                  url: cdnFile?.url
                })
              ]);

              if (Array.isArray(promises) && promises.length !== 0) {
                toast.info('Converting...', { toastId: 'convertingFile' });
                Promise.all(promises)
                  .then(async () => {
                    fetchDocuments({
                      orgId,
                      refId: resourceId, // Note: Currently only supporting 1 resource
                      select: ['rawText', 'id'],
                      limit: 1000
                    }).then((responseDocs) => {
                      const docsList =
                        Array.isArray(responseDocs?.items) &&
                        responseDocs?.items.length > 0
                          ? responseDocs?.items
                          : null;

                      let docsRawText = '';

                      if (Array.isArray(docsList) && docsList.length > 0) {
                        docsRawText = docsList.reduce((acc, curr) => {
                          return `${acc} ${curr?.rawText}`;
                        }, '');
                      }

                      const rawTextTrimmed = docsRawText.trim();

                      if (rawTextTrimmed.length > 0) {
                        // Update animation with real data
                        setRawText(rawTextTrimmed);
                        setAnimationActionStep('scanning');
                      }
                    });

                    const response = await queryEngine({
                      ...(dataToSave.promptText
                        ? { prompt: dataToSave.promptText }
                        : {}),
                      promptType,
                      refId: resourceId, // Note: Currently only supporting 1 resource
                      orgId
                    });

                    if (!response?.data?.answer) {
                      toast.dismiss('convertingFile');
                      setRawText(null);
                      setShowScanningAnimation(false);
                      toast.error('Something went wrong, please try again.');
                      setLoading(false);
                      return;
                    }

                    const answer = JSON.parse(response?.data?.answer);

                    const converter = new Showdown.Converter();
                    const body = answer?.body
                      ? converter.makeHtml(answer?.body)
                      : null;

                    toast.dismiss('convertingFile');

                    if (!body) {
                      toast.error('Something went wrong, please try again.');
                      return;
                    }

                    toast.success(`${dataToSave.title} created!`, {
                      toastId: 'savedMaterial'
                    });

                    updateMaterial(
                      {
                        ...(answer?.title ? { title: answer?.title } : {}),
                        body
                      },
                      materialResponse?.id
                    )
                      .then(() => {
                        dispatch(getMaterial(materialResponse?.id)).then(() => {
                          dispatch(
                            setSideSheet({
                              key: SHEET_KEY_MATERIAL,
                              className: 'MaterialSideSheet'
                            })
                          );
                        });
                      })
                      .catch(onError);
                  })
                  .catch(onError);
              } else {
                onError(new Error('Something went wrong (no embed promises)'));
              }
            }
          }
        })
      );
    });
  };

  return (
    <div className={`card ${className}`}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <FaMagic />
            <span className="ml-2 text-nowrap">
              Transform Files with Template
            </span>
          </span>
        </h6>
        <DotMenu
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </div>

      <div className="card-body overflow-y-scroll">
        <div className="row">
          {showScanningAnimation && (
            <div className="col-12 col-sm-6">
              <div className=" shadow p-3 border mb-3">
                <div className="d-flex justify-content-center">
                  <p
                    className="transform-file-title m-0"
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '95%',
                      overflow: 'hidden'
                    }}
                  >
                    {rawTitle}
                  </p>
                </div>
              </div>

              <div className="ocr-container">
                <div className={`ocr ${animationActionStep}`}>
                  <p>{animationActionStep}</p>
                  <em />
                  <span />
                </div>

                <div className="raw-text-container">
                  <div className="raw-text-content">{rawText}</div>
                </div>
              </div>
            </div>
          )}

          <div
            className="col-12 col-sm-6 mb-3"
            style={showScanningAnimation ? { display: 'none' } : {}}
          >
            <div className="shadow py-3 border mb-3">
              <div className="d-flex justify-content-center">
                <p className="transform-file-title m-0 font-weight-bold">
                  <span className="d-flex align-items-center justify-content-center">
                    <span>Files</span>
                    <FaArrowRightLong className="mx-2" />
                    <span>{promptTemplateTitle}</span>
                  </span>
                  {/* <FaMagic className='d-none d-sm-block mr-2' />
                  <span>
                    Transform File to {promptTemplateTitle}
                  </span> */}
                </p>
              </div>
            </div>

            <div>
              <h5>
                <span className="d-flex align-items-center">
                  <span className="badge bg-dark text-white">1</span>
                  <span className="ml-2">
                    Add <strong>Context</strong> for readers
                  </span>
                </span>
              </h5>
              <div className="shadow p-3 border mb-3">
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <div className="font-weight-bold mb-1">
                      Brief Description:
                    </div>
                    <Switch
                      id="descriptionAutoGen"
                      label="Auto-Generate"
                      className=""
                      slim
                      value={descriptionAutoGen}
                      onChange={() => {
                        setDescriptionAutoGen(!descriptionAutoGen);
                      }}
                    />
                  </div>
                  <textarea
                    className="form-control mb-1"
                    value={description}
                    disabled={descriptionAutoGen}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setDescription(value);
                    }}
                  />
                  <div className="text-muted small">
                    What will the reader learn by reading this Material?
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <div className="font-weight-bold">
                      Additional information:
                    </div>
                    <Switch
                      id="additionalInfoAutoGen"
                      label="Auto-Generate"
                      className=""
                      slim
                      value={additionalInfoAutoGen}
                      onChange={() => {
                        setAdditionalInfoAutoGen(!additionalInfoAutoGen);
                      }}
                    />
                  </div>
                  <textarea
                    className="form-control mb-1"
                    value={additionalInfo}
                    disabled={additionalInfoAutoGen}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setAdditionalInfo(value);
                    }}
                  />
                  <div className="text-muted small">
                    Ex: On average it takes two weeks to complete.
                  </div>
                  <div className="text-muted small">
                    Ex: It is crucial to follow the order of operations to
                    ensure success.
                  </div>
                </div>
              </div>

              <div
                className="mb-3"
                style={{
                  display: showScanningAnimation ? 'none' : 'block'
                }}
              >
                <h5>
                  <span className="d-flex align-items-center">
                    <span className="badge bg-dark text-white">2</span>
                    <span className="ml-2">
                      Choose <strong>Files</strong> to transform
                    </span>
                  </span>
                </h5>
                <div className="shadow p-3 py-4 border">
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      disabled={showScanningAnimation}
                      className="btn bg-primary btn-md text-white"
                      onClick={uploadFile}
                    >
                      <span className="d-flex align-items-center">
                        {loading ? (
                          <FaFan className="fa-spin" />
                        ) : (
                          <FaCloudUploadAlt />
                        )}
                        <span className="ml-2">Choose File</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <h5>
                  <span className="d-flex align-items-center">
                    <span>
                      <img
                        src={VELA_ICON}
                        alt="VELA"
                        height={20}
                        style={{
                          height: '20px'
                        }}
                      />
                    </span>
                    <span className="ml-2">
                      <strong>VELA</strong> auto-magically transforms your file
                    </span>
                  </span>
                </h5>
                <div className="shadow p-3 py-3 border">
                  <p className="m-0">
                    VELA, your Voice-Enabled Learning Assistant, deconstructs
                    the file & transforms pertinent information into an
                    effective <strong>{promptTemplateTitle}</strong>.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6">
            <div className="shadow p-3 border position-relative">
              <RenderMarkdown
                // enableNewEditor
                source={promptTemplate}
              />
              <span
                className="h6 position-absolute"
                style={{
                  top: '10px',
                  right: '10px'
                }}
              >
                <span className="badge bg-dark text-white">template</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTemplateSideSheet;
