import { pathOr } from 'ramda';
import { getClient, flattenItem, flattenItems } from '../services/contentful';
import {
  SET_CURRENT_CHALLENGE,
  RESET_CURRENT_CHALLENGE,
  SET_CHALLENGES,
  RESET_CHALLENGES
} from './types';

export const getChallenges = ({ classId, orgId, limit = 200 }) => {
  return (dispatch) => {
    const config = {
      content_type: 'challenge',
      limit
    };

    if (orgId) config['fields.orgId'] = orgId;
    if (classId) config['fields.classId'] = classId;

    return getClient()
      .getEntries(config)
      .then(({ items }) => {
        const challenges = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CHALLENGES,
          challenges
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const setCurrentChallenge = (challengeId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getClient()
        .getEntry(challengeId)
        .then((response) => {
          const currentChallenge = response ? flattenItem(response) : null;
          const cdnFiles = pathOr(
            null,
            ['integration', 'cdnFiles'],
            currentChallenge
          );

          dispatch({
            type: SET_CURRENT_CHALLENGE,
            currentChallenge,
            cdnFiles
          });
          resolve(currentChallenge);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetChallenges = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CHALLENGES });
  };
};

export const resetCurrentChallenge = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CHALLENGE });
  };
};
