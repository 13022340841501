function formatQuiz({
  title,
  description,
  difficulty,
  questions,
  classId,
  topicId,
  orgId,
  guidedQuiz,
  activeQuiz,
  enableNewEditor,
  allowPracticeAfterSubmission,
  quizSkills
}) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (difficulty !== undefined) {
    data.difficulty = {
      'en-US':
        typeof difficulty === 'number' ? difficulty.toString() : difficulty
    };
  }

  if (questions !== undefined) {
    data.questions = {
      'en-US': questions
    };
  }

  if (classId !== undefined) {
    data.classId = {
      'en-US': classId
    };
  }

  if (topicId !== undefined) {
    data.topicId = {
      'en-US': topicId
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  if (guidedQuiz !== undefined) {
    data.guidedQuiz = {
      'en-US': guidedQuiz
    };
  }

  if (activeQuiz !== undefined) {
    data.activeQuiz = {
      'en-US': activeQuiz
    };
  }

  /**
   * {array of strings || undefined || null } quizSkills
   * FE will send null which is then converted to undefined
   */
  if (quizSkills !== undefined) {
    data.quizSkills = {
      // BE needs the value to be undefined when there are no quizSkills
      'en-US': quizSkills === null ? undefined : quizSkills
    };
  }

  if (enableNewEditor !== undefined) {
    data.enableNewEditor = {
      'en-US': enableNewEditor
    };
  }

  if (allowPracticeAfterSubmission !== undefined) {
    data.allowPracticeAfterSubmission = {
      'en-US': allowPracticeAfterSubmission
    };
  }

  return { fields: { ...data } };
}

export default formatQuiz;
