import React from 'react';
import { FaImage } from 'react-icons/fa';

const ImageCell = ({ value: image, row, height = 40, width = 40 }) => {
  const title = row?.original?.title || '';

  return (
    // row
    <div className="d-flex">
      <div className="border shadow bg-white rounded d-block mr-2 position-relative">
        {image ? (
          <div
            className="p-2"
            style={{
              height: `${height}px`,
              width: `${width}px`
            }}
          >
            <img
              className="d-block w-100 h-100"
              src={image}
              alt={title}
              height={height}
              width={width}
            />
          </div>
        ) : (
          <FaImage
            size={height}
            className="p-2"
          />
        )}
      </div>
    </div>
  );
};

export default ImageCell;
