import React from 'react';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_CHALLENGE_BASE } from '../../../../constants/routes';
import { routeWithClassId } from '../../../../services/courses';

const ChallengeCell = ({ row, value: challengeId }) => {
  const {
    original: { classId, challengeTitle }
  } = row;

  return (
    <Link
      to={routeWithClassId({
        route: `${CLASS_CONSOLE_CHALLENGE_BASE}/${challengeId}/submissions`,
        classId
      })}
      title={`Edit ${challengeTitle}`}
      className="btn-link"
    >
      {challengeTitle}
    </Link>
  );
};

export default ChallengeCell;
