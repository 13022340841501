import React from 'react';
import { LAYOUTS } from '../../../constants/dashboard';
import LayoutWrapper from './LayoutWrapper';

const ChangeDashboardLayout = ({
  className = '',
  activeLayout,
  selectedLayout,
  handleSelectLayout
}) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          {Object.keys(LAYOUTS).map((layout) => {
            return (
              <div className="col-12 col-md-6 mb-3">
                <LayoutWrapper
                  key={layout}
                  layoutName={layout}
                  layoutData={LAYOUTS[layout]}
                  activeLayout={activeLayout}
                  selected={selectedLayout === layout}
                  handleLayoutSelect={handleSelectLayout}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChangeDashboardLayout;
