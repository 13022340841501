import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaFolder, FaTag } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  createResourceGroupTag,
  updateResourceGroupTag
} from '../../../services/resourceGroupTags';
import { fetchResourceGroupsByTags } from '../../../services/resourceGroups';
import DeleteResourceGroupTag from '../../ManageContent/DeleteContent/DeleteResourceGroupTag';
import Modal from '../../Modal';
import RemoveTagButton from './RemoveTagButton';
import Tabs from './Tabs';

const ManageResourceGroupTag = () => {
  const dispatch = useDispatch();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Current User
  const userId = currentUser?.id || null;
  const userName = currentUser?.name || null;
  // misc
  const orgId = organization?.id || null;
  const resourceGroupTag = currentModal?.data?.resourceGroupTag || null;
  const currentLabel = resourceGroupTag?.label || null;
  // local state
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(currentLabel || '');
  const [resourceGroupsList, setResourceGroupsList] = useState([]);
  const [activeTab, setActiveTab] = useState('settings');

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleCancel = () => {
    if (currentModal?.callbackSecondaryAction) {
      currentModal?.callbackSecondaryAction();
    }

    handleClose();
  };

  const tagInUse =
    Array.isArray(resourceGroupsList) && resourceGroupsList.length > 0;

  const handleSuccess = (toastText) => {
    toast.success(toastText);

    if (currentModal?.callbackPrimaryAction) {
      currentModal?.callbackPrimaryAction();
    }

    handleClose();
  };

  const retrieveGroups = useCallback(() => {
    if (orgId && resourceGroupTag?.id) {
      fetchResourceGroupsByTags({
        orgId,
        groupTags: [resourceGroupTag?.id]
      }).then((rsp) => {
        setResourceGroupsList(rsp.groups || []);
      });
    }
  }, [orgId, resourceGroupTag?.id]);

  useEffect(() => {
    retrieveGroups();
  }, [retrieveGroups]);

  const onClickSave = () => {
    if (!label || (label || '').trim() === '') {
      toast.error('Label is required.');
      return;
    }

    setLoading(true);

    if (resourceGroupTag?.id) {
      updateResourceGroupTag(
        {
          label,
          ...(resourceGroupTag?.authorUserId ? { authorUserId: userId } : {}),
          ...(resourceGroupTag?.authorUserName
            ? { authorUserName: userName }
            : {})
        },
        resourceGroupTag?.id
      )
        .then(() => {
          handleSuccess(`${label} updated!`);
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }

    createResourceGroupTag({
      label,
      orgId,
      authorUserId: userId,
      authorUserName: userName
    })
      .then(() => {
        handleSuccess(`${label} created!`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal?.visible}
        close={loading ? undefined : handleCancel}
        pos="middle"
        theme="dark"
      >
        <div className="card border-0">
          <div className="card-header bg-dark">
            <h5 className="mb-0 d-flex align-items-center">
              <FaTag />
              <span className="ml-2">{label || 'Resource Group Tag'}</span>
            </h5>
          </div>
          <Tabs
            cardStyle
            className="pt-3"
            numFolders={resourceGroupsList.length}
            showUsageTab={Boolean(resourceGroupTag?.id)}
            activeTab={activeTab}
            setActiveTab={(tab) => {
              setActiveTab(tab);
              if (tab === 'settings') {
                retrieveGroups();
              }
            }}
          />

          {activeTab === 'settings' && (
            <>
              <div className="card-body">
                <div
                  id="group-name"
                  className="mb-4"
                >
                  <label htmlFor="label">
                    <b>Label:</b> <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="label"
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setLabel(value);
                    }}
                    defaultValue={label}
                    autoFocus
                    required
                  />
                </div>

                {resourceGroupTag?.id && (
                  <DeleteResourceGroupTag
                    authorUserId={resourceGroupTag?.authorUserId}
                    title={resourceGroupTag?.label}
                    groupTags={resourceGroupsList}
                    contentId={resourceGroupTag?.id}
                    contentType="resourceGroupTags"
                    setActiveTab={setActiveTab}
                    onSuccess={() => {
                      handleSuccess(`${label} deleted!`);
                    }}
                  />
                )}
              </div>
              <div className="card-footer d-flex justify-content-between">
                <div>
                  <button
                    type="button"
                    onClick={onClickSave}
                    className="btn btn-md btn-primary"
                    disabled={loading || !label || label === currentLabel}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </>
          )}

          {activeTab === 'usage' && (
            <div className="card-body">
              {tagInUse ? (
                <>
                  <h6 className="font-weight-bold mt-2 mb-3">
                    <span>Folders using this tag:</span>
                  </h6>
                  <div>
                    {resourceGroupsList.map((resourceGroup, i) => (
                      <div
                        className={`p-2 border rounded d-flex align-items-center justify-content-between ${i === 0 ? '' : 'mt-2'}`}
                      >
                        <div className="d-flex align-items-center">
                          <FaFolder className="mr-2 text-warning" />
                          <span>{resourceGroup.title}</span>
                        </div>
                        <div>
                          <RemoveTagButton
                            resourceGroup={resourceGroup}
                            resourceGroupTag={resourceGroupTag}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    height: '190px'
                  }}
                >
                  <div>Tag is not associated with any folders.</div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ManageResourceGroupTag;
