import React from 'react';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { isSuperAdmin } from '@apprentage/utils';
import { FaLifeRing, FaPlus } from 'react-icons/fa';
import { HiDocumentSearch } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';
import {
  canAccessBilling,
  canAccessOrgSettings,
  canActivateDemoMode
} from '../../services/currentUser';
import { isDemoModeActive, toggleDemoMode } from '../../services/demoMode';
import { logUserOut, redirectToTurbineApp } from '../../services/auth';
import {
  TURBINE,
  TURBINE_ADMIN_DOCS,
  TURBINE_TRAINING_PROGRAM_DOCS
} from '../../constants/urls';
import {
  canAccessAdminDocs,
  canAccessProgramTrainingDocs
} from '../../permissions/access';
import { BILLING, ORG_MANAGE } from '../../constants/routes';
import { manageEntry } from '../../services/entry';
import { logout } from '../../actions/Session';
import { resetUser } from '../../actions/Users';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_COURSE_CREATE_FROM_DOC } from '../../constants/modals';
import {
  FLAG_APP_TURBINE_ADMIN,
  FLAG_MAINTENANCE_MODE
} from '../../constants/flagsmith';
import UserDetailsBlock from './UserDetailsBlock';

const UserMenu = () => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_APP_TURBINE_ADMIN, FLAG_MAINTENANCE_MODE]);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  // Current User
  const role = currentUser?.role || [];
  const userName = currentUser?.name || '';
  const userEmail = currentUser?.email || '';
  const profilePicture = currentUser?.profilePicture || '';

  const handleLogout = () => {
    logUserOut().then(() => {
      dispatch(logout());
      dispatch(resetUser());
    });
  };

  const openLLMCourseCreate = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_COURSE_CREATE_FROM_DOC
      })
    );
  };

  const openReportIssueModal = () => {
    dispatch(
      setCurrentModal({
        key: 'sendEmail',
        data: {
          modalTitle: 'Report Issue',
          name: 'Turbine Workforce Support',
          fromName: userName,
          subject: `⚠️ Issue Reported from ${userName}`,
          hideSubject: true,
          disableSubject: true,
          replyTo: userEmail,
          notifyTurbineEmail: true,
          notifyTurbineSlack: true
        }
      })
    );
  };

  return (
    <ul className="navbar-nav">
      <div className="nav-item dropdown">
        <div
          className="top-nav-trigger nav-link dropdown-toggle text-nowrap border-left"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="user-profile">
            <img
              src={profilePicture || DEFAULT_PROFILE_PIC}
              alt={userName}
            />
          </span>
        </div>
        <div
          className="dropdown-menu my-0 py-0 dropdown-menu-right"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <UserDetailsBlock>
            <a
              className="btn btn-sm btn-white border mt-2"
              href={redirectToTurbineApp(`${TURBINE}/profile`)}
              title="My Profile"
            >
              My Profile
            </a>
          </UserDetailsBlock>

          {canActivateDemoMode(role) && (
            <div className="py-2 px-3 border-top">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="privateMode"
                  defaultChecked={isDemoModeActive()}
                  onClick={toggleDemoMode}
                />
                <label
                  className="custom-control-label text-nowrap"
                  htmlFor="privateMode"
                >
                  Private Mode
                </label>
              </div>
            </div>
          )}

          {isSuperAdmin(role) && (
            <button
              className="dropdown-item border-top py-2"
              onClick={openLLMCourseCreate}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPlus size={20} />
                <span className="ml-2">LLM Course Create</span>
              </span>
            </button>
          )}

          <button
            className="dropdown-item border-top py-2"
            onClick={openReportIssueModal}
            type="button"
          >
            <span className="d-flex align-items-center">
              <FaLifeRing size={20} />
              <span className="ml-2">Report Issue</span>
            </span>
          </button>

          {(canAccessAdminDocs(role) ||
            canAccessProgramTrainingDocs(role, enableWorkforceSuite)) && (
            <div className="nav-item bg-light py-2 pl-4 border-bottom border-top font-weight-bold">
              Documentation
            </div>
          )}

          {canAccessAdminDocs(role) && (
            <a
              className="dropdown-item border-bottom py-2 d-flex align-items-center"
              href={TURBINE_ADMIN_DOCS}
              target="_blank"
              title="Documentation"
              rel="noreferrer noopener"
            >
              <HiDocumentSearch
                size={20}
                className="mr-2"
              />
              <span>Turbine Admin Guide</span>
            </a>
          )}

          {canAccessProgramTrainingDocs(role, enableWorkforceSuite) && (
            <a
              className="dropdown-item border-bottom py-2 d-flex align-items-center"
              href={TURBINE_TRAINING_PROGRAM_DOCS}
              target="_blank"
              title="Documentation"
              rel="noreferrer noopener"
            >
              <HiDocumentSearch
                size={20}
                className="mr-2"
              />
              <span>Apprentage Guide</span>
            </a>
          )}

          <button
            className="dropdown-item border-top py-2"
            onClick={handleLogout}
            type="button"
          >
            <i className="fas fa-sign-out-alt text-danger" /> Sign out
          </button>

          <div className="card-footer py-2">
            <div>
              <div className="small text-muted font-weight-bold">
                Turbine Workforce
              </div>
              <div className="text-muted small">
                <span className="text-capitalize">{orgType} Edition</span> v
                {flags?.app_turbine_admin?.value || 0}
              </div>
            </div>
            <div className="d-flex">
              {canAccessBilling(role) && (
                <Link
                  className="btn btn-link pl-0 mr-3"
                  style={{
                    textDecoration: 'underline'
                  }}
                  to={BILLING}
                >
                  Billing
                </Link>
              )}

              {canAccessOrgSettings(role) && (
                <Link
                  className="btn btn-link pl-0"
                  style={{
                    textDecoration: 'underline'
                  }}
                  data-cy="topNavOrgLinkBilling"
                  to={{
                    pathname: ORG_MANAGE,
                    search: manageEntry({
                      manageType: 'edit',
                      orgId,
                      contentType: 'organization'
                    })
                  }}
                  title="Organization Settings"
                >
                  Settings
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default UserMenu;
