import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
// import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { withAuthorization } from '../../components/Session';
import {
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_ASSISTANT_BASE,
  SLASH
} from '../../constants/routes';
import { DEFAULT_PAGINATION_LIMIT } from '../../constants/api';
import { goToElem } from '../../services/manageContent';
import { resetAssistants } from '../../actions/Assistants';
import { canAccessAssistants } from '../../permissions/access';
import { ICON_AGENTS } from '../../constants/assets';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import AssistantsList from '../../components/lists/AssistantsList';
import PaginationSupabase from '../../components/ManageContent/PaginationSupabase';
import AssistantsTabs from '../../components/tabs/AssistantsTabs';

const Assistants = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const assistants = useSelector((state) => state.assistants);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Pagination
  const initialListConfig = {
    orgId,
    page: 1,
    archived: null,
    limit: DEFAULT_PAGINATION_LIMIT,
    name: ''
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetAssistants());
    };
  }, [dispatch]);

  if (!canAccessAssistants(role)) {
    return <Redirect to={SLASH} />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Assistants"
            image={ICON_AGENTS}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <Link
              type="button"
              className="btn btn-sm btn-primary"
              to={`${ORG_CONSOLE_ASSISTANT_BASE}/manage`}
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2">New</span>
              </span>
            </Link>
          </OrgConsoleHeader>

          <AssistantsTabs
            listConfig={listConfig}
            setListConfig={setListConfig}
            className="pt-3"
          />

          <div className="bg-white border-right border-left border-bottom shadow p-3 overflow-content">
            {/* <SearchBox
              className="w-100 py-3"
              onSubmit={(value) => {
                setListConfig((prevState) => ({
                  ...prevState,
                  title: value
                }));
              }}
              name="searchAssistants"
              placeholder="Search by name..."
              autoFocus
            /> */}
            <AssistantsList listConfig={listConfig} />

            {Array.isArray(assistants?.list) && assistants?.list.length > 0 && (
              <PaginationSupabase
                items={assistants?.list}
                pagination={assistants?.pagination}
                page={assistants?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: assistants?.pagination?.page + 1
                  }));
                  goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: assistants?.pagination?.page - 1
                  }));
                  goToElem('root');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Assistants);
