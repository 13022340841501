import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { DEFAULT_PROFILE_PIC, ICON_UPLOAD } from '@apprentage/constants';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import { getCurrentUser } from '../../../actions/Users';
import { resetCurrentModal } from '../../../actions/Modals';
import { updateUser } from '../../../services/user';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import dataURLToBlob from '../../../utils/dataURLToBlob';
import Modal from '../../Modal';
import 'react-image-crop/dist/ReactCrop.css';

const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 15px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 60%) inset;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
    border-radius: 100%;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }
`;

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth, // number
  mediaHeight, // number
  aspect // number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCrop = () => {
  const key = 'imageCrop';
  const dispatch = useDispatch();
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = pathOr(null, ['id'], organization);
  // Modal
  const currentModalData = pathOr(null, ['data'], currentModal);
  const userData = pathOr(currentUser, ['userData'], currentModalData);

  // Current User
  const profilePicture = userData?.profilePicture || DEFAULT_PROFILE_PIC;
  const hasProfilePicture = Boolean(userData?.profilePicture);
  // Local State
  const aspectRatio = 1 / 1; // 16 / 9 || 4 / 3
  const [imgSrc, setImgSrc] = useState();
  const uploadProfilePictureBtn = useRef(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate] = useState(0); // setRotate
  const [xAxis, setXAxis] = useState(0);
  const [yAxis, setYAxis] = useState(0);
  const [aspect, setAspect] = useState(aspectRatio);
  const [loading, setLoading] = useState(false);
  const canDisableAspectRatio = false;

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      setXAxis(0);
      setYAxis(0);
      const reader = new FileReader();

      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
          xAxis,
          yAxis
        );
      }
    },
    100,
    [completedCrop, scale, rotate, xAxis, yAxis]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;

      setAspect(aspectRatio);
      setCrop(centerAspectCrop(width, height, aspectRatio));
    }
  }

  if (currentModal && !currentModal.visible) return null;
  if (currentModal.key !== key) return null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSaveImage = () => {
    setLoading(true);
    toast.info('Processing image...');

    const dataURL = previewCanvasRef.current.toDataURL();
    const blob = dataURLToBlob(dataURL);

    aws
      .uploadFile({
        prefix: `${AWS_USER_PREFIX}/${orgId}`,
        file: blob,
        fileExt: 'png',
        orgId
      })
      .then(({ url }) => {
        toast.dismiss();
        toast.info('Saving image...');

        // Delete existing Profile Picture
        if (hasProfilePicture) {
          aws.deleteFile({
            url: profilePicture,
            orgId
          });
        }

        updateUser(
          {
            profilePicture: url
          },
          userData?.id
        ).then(() => {
          // SUCCESS
          toast.success('Image saved!');
          dispatch(getCurrentUser({ userId: userData.id }));
          handleClose();
        });
      });
  };

  const handleDeleteImage = () => {
    setLoading(true);
    toast.info('Deleting image...');

    aws.deleteFile({
      url: profilePicture,
      orgId
    });

    updateUser(
      {
        profilePicture: null
      },
      userData?.id
    ).then(() => {
      // SUCCESS
      toast.success('Image deleted!');

      dispatch(getCurrentUser({ userId: userData.id }));
      handleClose();
    });
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      // close={handleClose}
    >
      <div className="card">
        <div className="d-flex align-items-center justify-content-between border-bottom p-3">
          {!imgSrc && (
            <div>
              <img
                src={profilePicture}
                alt={userData?.firstName}
                height={80}
                width={80}
                style={{
                  height: '80px',
                  width: '80px'
                }}
              />
              {hasProfilePicture && (
                <button
                  className="btn btn-sm btn-link"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    handleDeleteImage();
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          )}

          {Boolean(completedCrop) && (
            <div className="d-flex align-items-center">
              <div className="position-relative overflow-hidden">
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    objectFit: 'cover',
                    width: completedCrop.width,
                    height: completedCrop.height,
                    maxHeight: '80px',
                    maxWidth: '80px'
                  }}
                />
                <div
                  className="position-absolute"
                  style={{
                    height: '120px',
                    width: '120px',
                    border: '20px solid white',
                    borderRadius: '100%',
                    zIndex: 1,
                    top: '-20px',
                    left: '-20px'
                  }}
                />
              </div>
              <div className="ml-2">
                <div>{userData?.firstName}</div>
                <div>{userData?.lastName}</div>
              </div>
            </div>
          )}

          <div>
            <button
              className="btn btn-sm btn-white"
              type="button"
              disabled={loading}
              onClick={() => {
                uploadProfilePictureBtn.current.click();
              }}
            >
              <i className={ICON_UPLOAD} />{' '}
              <span className="d-none d-sm-inline">Upload</span>
            </button>
            <input
              ref={uploadProfilePictureBtn}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              style={{
                float: 'right',
                height: '1px',
                opacity: 0,
                width: '1px'
              }}
            />
          </div>
        </div>

        {imgSrc && (
          <div className="position-relative">
            <button
              className="btn btn-sm btn-white position-absolute"
              type="button"
              aria-label="Up"
              disabled={loading}
              onClick={() => {
                setYAxis((prevY) => Number(prevY) + 1);
              }}
              style={{
                top: '7px',
                left: 'calc(50% - 19px)'
              }}
            >
              <i className="fa fa-caret-square-o-up" />
            </button>
            <button
              className="btn btn-sm btn-white position-absolute"
              type="button"
              aria-label="Down"
              disabled={loading}
              onClick={() => {
                setYAxis((prevY) => Number(prevY) - 1);
              }}
              style={{
                bottom: '7px',
                left: 'calc(50% - 19px)'
              }}
            >
              <i className="fa fa-caret-square-o-down" />
            </button>
            <button
              className="btn btn-sm btn-white position-absolute"
              type="button"
              aria-label="Right"
              disabled={loading}
              onClick={() => {
                setXAxis((prevX) => Number(prevX) - 1);
              }}
              style={{
                right: '7px',
                top: 'calc(50% - 19px)'
              }}
            >
              <i className="fa fa-caret-square-o-right" />
            </button>
            <button
              className="btn btn-sm btn-white position-absolute"
              type="button"
              aria-label="Left"
              disabled={loading}
              onClick={() => {
                setXAxis((prevX) => Number(prevX) + 1);
              }}
              style={{
                left: '7px',
                top: 'calc(50% - 19px)'
              }}
            >
              <i className="fa fa-caret-square-o-left" />
            </button>
            <div className="card-body p-5">
              <div className="d-flex justify-content-center">
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  maxWidth={160}
                  maxHeight={160}
                  circularCrop
                >
                  <img
                    ref={imgRef}
                    alt={userData?.firstName}
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg) translateX(${xAxis}px) translateY(${yAxis}px)`,
                      maxHeight: '300px'
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
          </div>
        )}

        {imgSrc && (
          <div className="border-top p-3">
            <div className="d-flex align-items-center">
              <label
                htmlFor="scale-input"
                className="mr-2 mb-0"
                style={{
                  minWidth: '50px'
                }}
              >
                Zoom
              </label>

              <Slider
                id="scale-input"
                type="range"
                min={1}
                max={3}
                step="0.1"
                value={scale}
                // disabled={!imgSrc}
                onChange={(e) => setScale(Number(e.target.value))}
              />
            </div>
            {/* <div
              className='d-flex align-items-center'
            >
              <label
                htmlFor="rotate-input"
                className='mr-2 mb-0'
                style={{
                  minWidth: '50px'
                }}
              >
                Rotate
              </label>
              <Slider
                id="rotate-input"
                type="range"
                min={0}
                max={100}
                value={rotate}
                // disabled={!imgSrc}
                onChange={(e) =>
                  setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
              />
            </div> */}
          </div>
        )}

        <div className="card-footer d-flex align-items-center justify-content-between">
          <div>
            <button
              className={`btn btn-sm ${!imgSrc || loading ? 'btn-secondary' : 'btn-primary'}`}
              type="button"
              onClick={handleSaveImage}
              disabled={!imgSrc || loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button
              className="btn btn-link btn-sm ml-3"
              type="button"
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </button>
          </div>

          {canDisableAspectRatio && (
            <button
              onClick={handleToggleAspectClick}
              className="btn btn-secondary btn-sm"
              type="button"
            >
              Toggle aspect {aspect ? 'off' : 'on'}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImageCrop;
