import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { roleReadable } from '@apprentage/utils';
import { FaTriangleExclamation } from 'react-icons/fa6';

const ManagersCell = ({ value: managerIds }) => {
  const organization = useSelector((state) => state.organization);
  const users = useSelector((state) => state.users);

  const managersEnum = useMemo(() => {
    const results = {};

    if (users.list && Array.isArray(users.list) && users.list.length !== 0) {
      users.list.forEach((u) => {
        results[u?.id] = u;
      });
    }

    return results;
  }, [users.list]);

  const hasManagers = useMemo(() => {
    if (!managerIds || !Array.isArray(managerIds) || managerIds.length === 0) {
      return false;
    }

    const managersEnumArr = Object.keys(managersEnum);

    if (
      !managersEnumArr ||
      !Array.isArray(managersEnumArr) ||
      managersEnumArr.length === 0
    ) {
      return false;
    }

    return true;
  }, [managerIds, users.list]);

  if (!hasManagers) {
    return (
      <div className="d-flex align-items-center">
        <FaTriangleExclamation
          size={15}
          className="text-danger mr-2"
        />
        <span>No manager</span>
      </div>
    );
  }

  return (
    <div>
      {managerIds.map((managerId) => (
        <NameBlock
          key={managerId}
          name={managersEnum[managerId]?.name}
          nameSize="sm"
          title={roleReadable(
            managersEnum[managerId]?.role,
            organization?.type
          )}
          pictureSize="sm"
          className="my-1"
        />
      ))}
    </div>
  );
};

export default ManagersCell;
