import React from 'react';
import markdownCheatsheet from './markdown-cheatsheet-online.pdf';
import './style.css';

const Markdown = () => {
  return (
    <div
      className="modal fade"
      id="modalMarkdownCheatsheet"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalMarkdownCheatsheetTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="modalMarkdownCheatsheetTitle"
            >
              Markdown Cheatsheet
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <embed
              src={markdownCheatsheet}
              width="800px"
              height="2100px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markdown;
