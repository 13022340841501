import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
// import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { withAuthorization } from '../../components/Session';
import { ORG_CONSOLE_DASHBOARD, SLASH } from '../../constants/routes';
import { DEFAULT_PAGINATION_LIMIT } from '../../constants/api';
import { goToElem } from '../../services/manageContent';
import { resetPages } from '../../actions/Pages';
import { canAccessPages } from '../../permissions/access';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import PagesList from '../../components/lists/PagesList';
import PaginationSupabase from '../../components/ManageContent/PaginationSupabase';
import PagesTabs from '../../components/tabs/PagesTabs';
import { ICON_PAGES } from '../../constants/assets';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_CREATE_PAGE } from '../../constants/modals';

const Pages = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const pages = useSelector((state) => state.pages);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Pagination
  const initialListConfig = {
    orgId,
    page: 1,
    // inactive: null,
    limit: DEFAULT_PAGINATION_LIMIT,
    select: ['*', 'integration(id,name,agentImage,colorBtnBkg)'],
    title: ''
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetPages());
    };
  }, [dispatch]);

  if (!canAccessPages(role)) {
    return <Redirect to={SLASH} />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Pages"
            image={ICON_PAGES}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                dispatch(
                  setCurrentModal({
                    key: MODAL_KEY_CREATE_PAGE
                    // data: {
                    //   enableCourseContent: true,
                    //   currentStep: 1
                    // }
                  })
                );
              }}
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2">New</span>
              </span>
            </button>
          </OrgConsoleHeader>

          <PagesTabs
            listConfig={listConfig}
            setListConfig={setListConfig}
            className="pt-3"
          />

          <div className="bg-white border-right border-left border-bottom shadow p-3 overflow-content">
            {/* <SearchBox
              className="w-100 py-3"
              onSubmit={(value) => {
                setListConfig((prevState) => ({
                  ...prevState,
                  title: value
                }));
              }}
              name="searchPages"
              placeholder="Search by name..."
              autoFocus
            /> */}
            <PagesList listConfig={listConfig} />

            {Array.isArray(pages?.list) && pages?.list.length > 0 && (
              <PaginationSupabase
                items={pages?.list}
                pagination={pages?.pagination}
                page={pages?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: pages?.pagination?.page + 1
                  }));
                  goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: pages?.pagination?.page - 1
                  }));
                  goToElem('root');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Pages);
