import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPages } from '../../../actions/Pages';
import { ORG_CONSOLE_PAGE_BASE } from '../../../constants/routes';
import TableLoading from '../../ManageContent/Tables/TableLoading';
import List from './List';

const PagesList = ({ list, listConfig, onClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const pages = useSelector((state) => state.pages);
  // misc
  const orgId = organization?.id || '';

  const handleClickRow = (page) => {
    if (onClick) {
      return onClick(page);
    }

    history.push(`${ORG_CONSOLE_PAGE_BASE}/${page.id}`);
  };

  useEffect(() => {
    if (orgId && listConfig) {
      console.log('listConfig', listConfig);
      dispatch(getPages(listConfig));
    }
  }, [dispatch, listConfig, orgId]);

  if (!listConfig && !list) {
    return null;
  }

  if (listConfig && !pages.fetched && !pages.list) {
    return <TableLoading />;
  }

  return (
    <div className="pages-list">
      <List
        handleClickRow={handleClickRow}
        data={list || pages?.list}
        pagination={pages?.pagination}
      />
    </div>
  );
};

export default PagesList;
