import { toast } from 'react-toastify';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'cronUsers';

/**
 * @param {Array} ids
 * @param {String} orgId
 */
export const fetchCronUsers = ({
  ids = [],
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt',
  userId = undefined,
  apprenticeshipId = undefined,
  orgId = undefined
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      page,
      limit
    };

    if (apprenticeshipId !== undefined) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (userId !== undefined) {
      config['f.userId[eq]'] = userId;
    }

    if (orgId !== undefined) {
      config.orgId = orgId;
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`fetch (many) ${table}`, error);
        reject(error);
      });
  });
};

export const checkCronUserExists = (data) => {
  return new Promise((resolve) => {
    fetchCronUsers(data).then((response) => {
      resolve(Boolean(response.total !== 0));
    });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchCronUser = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const logbook = response || null;

        resolve(logbook);
      })
      .catch((error) => {
        console.error(`fetch (single) ${table}`, error);
        reject(error);
      });
  });
};

export const createCronUser = (data) => {
  return new Promise((resolve) => {
    createSupabaseEntry({ data, table })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const updateCronUser = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};

/**
 *
 * @param {string} id
 * @param {object} data
 */
export const deleteCronUser = (id) => {
  return new Promise((resolve) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`delete ${table}`, error);
      });
  });
};
