import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { QRCodeSVG } from 'qrcode.react';
import { FaCircle, FaLink, FaLock, FaQrcode } from 'react-icons/fa';
import Collapse from '@apprentage/components/dist/components/Collapse';
import LeftNav from '../components/LeftNav';
import TopNav from '../components/TopNav';
import { getPage, resetPage } from '../../../actions/Pages';
import { withAuthorization } from '../../../components/Session';
import { IMAGE_TURBINE_RETRO_ICON_WHITE } from '../../../constants/assets';
import { SLASH } from '../../../constants/routes';
import { canManagePage } from '../../../permissions/manage';
import { handleOpenUserSideSheet } from '../../../actions/SideSheets';
import { TURBINE_PAGE } from '../../../constants/urls';
import { canAccessPages } from '../../../permissions/access';
import withOrgConsole from '../../../components/App/withOrgConsole';
import Users from '../../../components/ManageContent/Users';
import KeyValueItem from '../../../components/KeyValueItem';
import DotMenuLink from '../components/DotMenuLink';
import Loading from '../../../components/Loading';
import PageType from './PageType';
import Switch from '../../../components/Switch';
import { updatePageLink } from '../../../services/pageLinks';
import { getPageLink } from '../../../actions/PageLinks';
import { updatePage } from '../../../services/pages';
import { createDashboard } from '../../../services/dashboards';
import Title from './TItle';

const PageSettings = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = currentUser?.role || [];
  // Page
  const pageId = params?.pageId;
  const pageLinkId = page?.pageLinkId || null;
  // Local State
  const turbinePagesNavCollapse = window?.localStorage?.getItem(
    'turbine-pages-nav-collapse'
  );
  const [loading, setLoading] = useState(false);
  const [collapseNav, setCollapseNav] = useState(
    turbinePagesNavCollapse || false
  );
  const [enablePageContent, setEnablePageContent] = useState(
    pageLink?.enablePageContent || null
  );
  const [url, setUrl] = useState(pageLink?.url || null);

  const onClickTeamMember = (userId) => {
    dispatch(
      handleOpenUserSideSheet({
        userId,
        orgId,
        currentUserOrgId: currentUser?.orgId
        // route,
        // history
      })
    );
  };

  const handleSuccess = useCallback(() => {
    toast.dismiss('savePageType');
    toast.success('Page type updated!');
    dispatch(getPageLink(pageLink?.id));
    dispatch(getPage(page?.id));
  }, [dispatch, page?.id, pageLink?.id]);

  const handleError = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.');
  };

  const handleSaveEnablePageContent = () => {
    if (enablePageContent === true) {
      setEnablePageContent(null);
    } else {
      setLoading(true);
      setEnablePageContent(true);
      toast.info('Saving...', { toastId: 'savePageType' });

      updatePageLink(
        {
          enablePageContent: true
        },
        pageLink?.id
      )
        .then(() => {
          if (page?.dashboardId) {
            updatePage({ enablePageContent: true }, page?.id)
              .then(() => {
                handleSuccess();
              })
              .catch((error) => {
                handleError(error);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            // Page was created as external originally
            // User changed to Turbine Page
            createDashboard({
              orgId,
              type: 'page',
              activeLayout: 'layout2'
            })
              .then((rspDashboard) => {
                if (rspDashboard?.id) {
                  updatePage(
                    {
                      enablePageContent: true,
                      dashboardId: rspDashboard?.id
                    },
                    page?.id
                  )
                    .then(() => {
                      handleSuccess();
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } else {
                  handleError('Failure create Dashboard');
                }
              })
              .catch((error) => {
                handleError(error);
              });
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const savePageType = () => {
    toast.info('Saving...', { toastId: 'savePageType' });

    updatePageLink(
      {
        url,
        enablePageContent: null
      },
      pageLink?.id
    ).then(() => {
      updatePage({ enablePageContent: null }, page?.id).then(() => {
        handleSuccess();
      });
    });
  };

  const onClickToggleInactive = () => {
    setLoading(true);
    if (pageLink?.inactive) {
      toast.info('Activating page...', { toastId: 'pageInactiveToggleMsg' });
    } else {
      toast.info('Deactivating page...');
    }

    if (pageLink?.id) {
      const newInactive = pageLink?.inactive === true ? null : true;

      updatePageLink(
        {
          inactive: newInactive
        },
        pageLink?.id
      ).then(() => {
        // TODO better way to keep these two in sync
        updatePage({ inactive: newInactive }, page?.id).then(() => {
          toast.dismiss('pageInactiveToggleMsg');
          toast.success(`Page ${pageLink?.inactive ? 'Active!' : 'Inactive!'}`);
          dispatch(getPageLink(pageLink?.id));
          dispatch(getPage(page?.id));
        });
      });
    }
  };

  const onSaveTitle = () => {
    dispatch(getPage(page?.id));
  };

  const qrCodeUrl = useMemo(() => {
    if (pageLinkId && pageLink?.id) {
      return `${TURBINE_PAGE}/link/${pageLinkId}`;
    }

    return null;
  }, [pageLink?.id, pageLinkId]);

  const pageTypeSaveButtonDisabled = useMemo(() => {
    if (!enablePageContent) {
      if (!url) {
        return true;
      }

      if (!url.includes('https://') || !url.includes('.')) {
        return true;
      }
    }

    return false;
  }, [enablePageContent, url]);

  useEffect(() => {
    if (pageId) {
      dispatch(getPage(pageId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }

    return function cleanup() {
      dispatch(resetPage());
    };
  }, [dispatch, organization?.id, pageId]);

  if (!canAccessPages(role)) {
    return <Redirect to={SLASH} />;
  }

  if (pageId && !page?.id) {
    return <Loading text="Loading Page..." />;
  }

  return (
    <div
      id="body-row"
      className="row"
    >
      <LeftNav
        id="sidebar-container"
        collapseNav={collapseNav}
        setCollapseNav={setCollapseNav}
      />

      <div
        role="main"
        className="col p-3"
        style={{
          marginLeft: collapseNav ? '50px' : '230px'
        }}
      >
        <TopNav />

        <div className="row mb-4">
          <div className="col">
            {!pageLink?.enablePageContent && (
              <Title
                className="mb-3"
                onSuccessCallback={onSaveTitle}
              />
            )}

            <div className="card mb-4">
              <div
                className="card-header d-flex align-items-center justify-content-between"
                style={{
                  height: '55px'
                }}
              >
                <div className="font-weight-bold d-flex align-items-center">
                  <span className="h6 m-0 d-flex align-items-center">
                    <FaLock />
                    <span className="ml-2">Access</span>
                  </span>

                  {canManagePage(role) ? (
                    <Switch
                      id="inactive"
                      className="ml-3 small text-keppel"
                      disabled={loading}
                      value={!pageLink?.inactive}
                      onChange={onClickToggleInactive}
                    />
                  ) : (
                    <span className="badge bg-white border d-flex align-items-center ml-2">
                      <FaCircle
                        className={`${pageLink?.inactive ? 'text-danger' : 'text-keppel'} mr-1`}
                        size={10}
                      />
                      {pageLink?.inactive ? (
                        <span>inactive</span>
                      ) : (
                        <span>active</span>
                      )}
                    </span>
                  )}
                </div>
                {canManagePage(role) && <DotMenuLink />}
              </div>

              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>
                      {qrCodeUrl ? (
                        <QRCodeSVG
                          id="page-qrcode"
                          value={qrCodeUrl}
                          size={150}
                          bgColor="#ffffff"
                          fgColor="#000000"
                          level="L"
                          imageSettings={{
                            src: IMAGE_TURBINE_RETRO_ICON_WHITE,
                            height: 40,
                            width: 40,
                            // Whether or not to "excavate" the modules around the embedded image. This
                            // means that any modules the embedded image overlaps will use the background
                            // color.
                            excavate: true
                          }}
                        />
                      ) : (
                        <FaQrcode size={40} />
                      )}
                    </div>
                    <div className="ml-2">
                      <div className="mb-2">
                        <KeyValueItem
                          className="text-nowrap mb-0"
                          title="Access Code"
                          value={pageLink?.accessCode || 'n/a'}
                          copy={Boolean(pageLink?.accessCode)}
                        />
                      </div>
                      <div>
                        <KeyValueItem
                          className="text-nowrap mb-0"
                          title="ID"
                          value={pageLink?.id || 'n/a'}
                          copy={Boolean(pageLink?.id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Collapse
              id="choosePageType"
              title="Page Type"
              className="mb-3"
              badge={() => {
                return (
                  <>
                    {!enablePageContent && (
                      <span className="badge badge-warning d-flex align-items-center ml-1">
                        <FaLink />
                        <span className="ml-1">External</span>
                      </span>
                    )}
                  </>
                );
              }}
            >
              {pageLink?.id && (
                <div className="p-0 p-md-3">
                  <PageType
                    enablePageContent={enablePageContent}
                    setEnablePageContent={handleSaveEnablePageContent}
                    url={url}
                    setUrl={setUrl}
                  >
                    <div className="mt-3">
                      <button
                        disabled={pageTypeSaveButtonDisabled}
                        className="btn btn-sm btn-primary"
                        type="button"
                        onClick={savePageType}
                      >
                        Save
                      </button>
                    </div>
                  </PageType>
                </div>
              )}
            </Collapse>

            {page?.userId && (
              <div className="mt-4">
                <div className="mb-2 font-weight-bold">Created by:</div>
                <Users
                  userIds={[page?.userId]}
                  onClick={onClickTeamMember}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  PageSettings,
  true
);
