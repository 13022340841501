import React from 'react';
import { FaBook } from 'react-icons/fa6';
import { FaDownload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import OrgLocation from '../../OrgLocation';
import { canExportUserData } from '../../../services/currentUser';
import exportData from './exportData';

const ProgramContainer = ({
  className = '',
  apprenticeshipId,
  apprenticeshipTitle,
  logbookId,
  logbookLocationId,
  children
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const logbookHours = useSelector((state) => state.logbookHours);
  const logbook = useSelector((state) => state.logbook);
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const shortLogbookId = logbookId
    ? logbookId.substring(logbookId.length - 12)
    : null;
  // Organization
  const orgType = organization?.type || '';
  const orgName = organization?.name || '';
  // Current User
  const role = currentUser?.role || [];
  // Apprenticeship
  const ojtHours = apprenticeship?.ojtHours || 0;
  const competencies = apprenticeship?.competencies || {};
  // Location
  const locationHash = locations?.hash || {};
  const location = locationHash[logbookLocationId] || {};
  const locationName = location?.name || '';

  return (
    <div
      key={apprenticeshipId}
      className={`Row ${className}`}
      style={{
        minHeight: 'auto'
      }}
    >
      {logbookId && (
        <div className="RowHeader d-flex align-items-center justify-content-between px-3 py-2">
          <span className="d-flex align-items-center">
            <FaBook size={15} />
            <span className="h6 m-0 ml-2">Logbook</span>
          </span>

          {logbook?.id && canExportUserData(role, orgType) ? (
            <div className="ml-2 d-none d-sm-flex">
              <button
                className="btn btn-sm btn-outline-white"
                onClick={() => {
                  exportData({
                    logbookHours,
                    logbookId,
                    orgName,
                    apprenticeshipId,
                    apprenticeshipTitle,
                    ojtHours,
                    competencies,
                    locationName,
                    userName: logbook?.userName,
                    userId: logbook?.userId
                  });
                }}
                title="Export Data"
                type="button"
                // disabled={}
              >
                <span className="d-flex align-items-center">
                  <FaDownload />
                  <span className="ml-2">Export</span>
                </span>
              </button>
            </div>
          ) : (
            <span className="ml-2 text-muted small">ID #{shortLogbookId}</span>
          )}
        </div>
      )}

      <div className="p-3 h-100 px-1">
        <div className="d-flex align-items-center justify-content-between ">
          <div className="BoxText h-100 small">{apprenticeshipTitle}</div>
        </div>

        {logbookLocationId && (
          <div className="bg-light px-3 py-1 d-inline-block small rounded mt-2">
            <OrgLocation locationId={logbookLocationId} />
          </div>
        )}
      </div>

      {children && (
        <div className="RowFooter border-top d-flex align-items-center justify-content-between p-3">
          {children}
        </div>
      )}
    </div>
  );
};

export default ProgramContainer;
