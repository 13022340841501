import React from 'react';
import ReactTooltip from 'react-tooltip';
import Table from './Table';
import InviteTypeCell from './InviteTypeCell';
import StatusCell from './StatusCell';
import ActionsCell from './ActionsCell';
import FullNameCell from '../../ManageContent/Tables/FullNameCell';
import LocationCell from '../../ManageContent/Tables/LocationCell';
import RoleCell from '../../ManageContent/Tables/RoleCell';

const InvitesList = ({
  list,
  hiddenColumns,
  noDataText = 'No Active Invitations.'
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: (props) => (
          <FullNameCell
            {...props}
            tooltipId="InvitesList"
          />
        )
      },
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: (props) => <RoleCell {...props} />
      },
      {
        Header: 'Type',
        accessor: 'inviteType',
        Cell: (props) => <InviteTypeCell {...props} />
      },
      {
        Header: 'Employer',
        accessor: 'locationId',
        Cell: (props) => <LocationCell {...props} />
      },
      {
        Header: 'Status',
        accessor: 'id',
        Cell: (props) => <StatusCell {...props} />
      },
      {
        Header: '',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: (props) => <ActionsCell {...props} />
      }
    ],
    []
  );

  if (!list || (Array.isArray(list) && !list.length)) {
    return <div className="p-4">{noDataText}</div>;
  }

  return (
    <>
      <Table
        columns={columns}
        data={list}
        hiddenColumns={hiddenColumns}
      />
      <ReactTooltip id="InvitesList" />
    </>
  );
};

export default InvitesList;
