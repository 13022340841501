export const SHEET_KEY_USER_DETAILS = 'userDetails';
export const SHEET_KEY_DAILY_RECORDS = 'dailyRecords';
export const SHEET_KEY_PROJECT = 'project';
export const SHEET_KEY_MATERIAL = 'material';
export const SHEET_KEY_MATERIAL_ADD = 'materialAdd';
export const SHEET_KEY_CHOOSE_RESOURCE_FOLDER = 'chooseResourceFolder';
export const SHEET_KEY_MANAGE_USER_COMPETENCIES = 'manageUserCompetencies';
export const SHEET_KEY_MATERIAL_TEMPLATE = 'materialTemplate';
export const SHEET_KEY_IMPORT_MATERIAL = 'importMaterial';
export const SHEET_KEY_IMPORT_RESOURCE_MATERIAL = 'importResourceMaterial';
export const SHEET_KEY_RESUME_SCREENER = 'resumeScreener';
export const SHEET_KEY_ACTIONS = 'actions';
export const SHEET_KEY_MANAGE_CONTEXT_QUESTION = 'manageContextQuestion';
