import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { canCreateResourceTag } from '../../../../../services/currentUser';
import Table from './Table';
import LabelCell from './LabelCell';
import EditCell from './EditCell';
import IdCell from './IdCell';

const TagsList = ({ data, showIdColumn, setListConfig }) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!showIdColumn) {
      cols.push('id');
    }

    if (!canCreateResourceTag(role, orgType)) {
      cols.push('actions');
    }

    return cols;
  }, [orgType, role, showIdColumn]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Tag',
        accessor: 'label',
        Cell: LabelCell
      },
      {
        Header: 'ID',
        accessor: 'id',
        showIdColumn,
        Cell: IdCell
      },
      {
        Header: '',
        accessor: 'actions',
        disableSortBy: true,
        setListConfig,
        Cell: EditCell
      }
    ],
    [setListConfig, showIdColumn]
  );

  if (!data || (data && data.length === 0)) {
    return null;
  }

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default TagsList;
