import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchTopics } from '../../../services/topics';
import TopicInsights from './TopicInsights';

const TopicRow = styled.div`
  cursor: pointer;

  &:hover {
    background-color: rgba(54, 162, 235, 0.2);
    border: 1px rgba(54, 162, 235, 1) solid;
  }
`;

const TopicsInsights = () => {
  const course = useSelector((state) => state.course);
  // Local State
  const [topicsList, setTopicsList] = useState(null);
  const [topic, setTopic] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [topicsTotal, setTopicsTotal] = useState(0);
  // Course
  const classId = course?.id || '';
  const topicIds = course?.topicIds || null;

  useEffect(() => {
    let mounted = true;

    if (
      Array.isArray(topicIds) &&
      topicIds.length > 0 &&
      !Array.isArray(topicsList)
    ) {
      fetchTopics({
        classId,
        topicIds
      }).then(({ topics, total }) => {
        if (mounted) {
          setTopicsList(topics);
          setTopicsTotal(total);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  });

  const showTopicInsights = (t) => {
    if (t.id === topicId) {
      setTopic(null);
      setTopicId(null);

      return null;
    }

    setTopic(t);
    setTopicId(t.id);
  };

  if (!course.id) {
    return null;
  }

  return (
    <div className="card mt-3">
      <div className="card-header d-flex align-items-center justify-content-between">
        <span className="h5 m-0 font-weight-bold">Topic Insights</span>
        <span className="h5 m-0">
          <span className="py-2 px-3 badge bg-light border">{topicsTotal}</span>
        </span>
      </div>

      {topicsList ? (
        <div className="card-body">
          {topicsList.map((t, i) => {
            return (
              <TopicRow
                className="border mb-3 p-2"
                key={i}
                onClick={() => {
                  showTopicInsights(t);
                }}
              >
                <div className="h6 m-0 font-weight-bold d-flex align-items-center">
                  <i
                    className={`fas fa-caret-${t.id === topicId ? 'down' : 'right'} mr-1`}
                  />
                  <span>{t.title}</span>
                </div>
                {t.id === topicId && (
                  <div className="card-body">
                    <TopicInsights topic={topic} />
                  </div>
                )}
              </TopicRow>
            );
          })}
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '200px' }}
        >
          No topics.
        </div>
      )}
    </div>
  );
};

export default TopicsInsights;
