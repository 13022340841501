import React from 'react';
import { Link } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';

const ApprenticeshipTitleCell = ({ value: apprenticeshipTitle, row }) => {
  const apprenticeshipId = row?.original?.apprenticeshipId || null;
  const userId = row?.original?.userId || null;

  return (
    <Link
      to={`${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/users/${userId}/records`}
      className="btn-link"
    >
      {apprenticeshipTitle}
    </Link>
  );
};

export default ApprenticeshipTitleCell;
