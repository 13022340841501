import React from 'react';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { mdySimple } from '../../utils/date';
import Users from '../ManageContent/Users';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import DotMenu from './DotMenu';
import canManageNote from '../../permissions/manage/canManageNote';
import './style.css';

const Note = ({
  className = '',
  materialId,
  authorId,
  createdAt,
  body,
  onClickTeamMember,
  onClickEdit,
  onClickView
}) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className={className}>
      <div
        data-demo="disabled"
        className="Apprenticeship-Note m-0"
      >
        <div
          style={{
            wordBreak: 'break-word'
          }}
        >
          <div className="d-flex align-items-center justify-content-between px-3 pt-3">
            <div
              className="text-muted"
              data-demo="disabled"
              data-author-id={authorId}
            >
              {mdySimple(createdAt)}
            </div>
            {canManageNote(
              currentUser?.role || [],
              currentUser?.userId,
              authorId
            ) && (
              <DotMenu
                className="ml-2"
                materialId={materialId}
                createdAt={createdAt}
                onClickEdit={onClickEdit}
                onClickView={onClickView}
              />
            )}
          </div>

          <div className="position-relative">
            <RenderMarkdown
              source={body}
              enableNewEditor
              className="MaterialSideSheet-body p-3"
            />

            <div className="MaterialSideSheet-actions p-3 d-flex align-items-center justify-content-between">
              <div>
                <Users
                  userIds={[authorId]}
                  onClick={() => {
                    onClickTeamMember(authorId);
                  }}
                />
              </div>
              <button
                className="btn btn-outline-primary btn-sm text-nowrap"
                type="button"
                aria-label="View Project Details"
                onClick={() => {
                  onClickView(materialId);
                }}
              >
                <span className="d-flex align-items-center">
                  <FaEye />
                  <span className="ml-2">View</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
