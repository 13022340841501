import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCube } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { modulesTypesHash } from './constants';
import { DashboardCards } from '../../OrgConsole/WorkforceSuite/Dashboard/DashboardCards';
import Modal from '../../Modal';
import './style.css';

const moduleTypes = Object.entries(modulesTypesHash).map(([key, value]) => ({
  ...value,
  moduleType: key
}));

const CreateModuleModal = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal
  const moduleOptions = currentModal?.data?.options || null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };
  const handleSelectModule = (type) => {
    currentModal.callbackPrimaryAction(type);
  };

  const allowedModuleTypes = useMemo(() => {
    if (
      Array.isArray(moduleOptions?.moduleTypes) &&
      moduleOptions?.moduleTypes.length > 0
    ) {
      return moduleTypes.filter((mt) =>
        moduleOptions?.moduleTypes.includes(mt?.type)
      );
    }
    return moduleTypes;
  }, [moduleOptions?.moduleTypes]);

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className="m-0 d-flex align-items-center">
            <FaCube
              className="mr-2"
              size={15}
            />
            <span>Module</span>
            <span className="badge badge-secondary ml-2">New</span>
          </h5>
        </div>
        <div className="card-body bg-light">
          <div className="row">
            {allowedModuleTypes.map((moduleTypeData) => (
              <div className="mb-3 col-12 col-sm-6">
                <DashboardCards
                  className="dashboard-module"
                  title={moduleTypeData.title}
                  badgeText={moduleTypeData.badgeText || undefined}
                  onClick={() => {
                    handleSelectModule(moduleTypeData.type);
                  }}
                  subTitle={moduleTypeData.body}
                  bkgImageUrl={moduleTypeData.image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateModuleModal;
