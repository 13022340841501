import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ICON_AWARD, ICON_USER } from '@apprentage/constants';
import { setClassCertificate, setCurrentClass } from '../../../actions/Class';
import { setCurrentModal } from '../../../actions/Modals';
import { CERTIFICATE } from '../../../constants/routes';
import { generateCourseRecord } from '../../../services/attendance';
import {
  canViewUserCourseCertificate,
  isActiveMembership
} from '../../../services/currentUser';
import isPublished from '../../../utils/isPublished';
import UserCeAttendance from '../../UserCeAttendance';
import Loading from '../../Loading';

const Rows = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const users = useSelector((state) => state.users);
  const locations = useSelector((state) => state.locations);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const currentCohort = useSelector((state) => state.currentCohort);
  // Misc
  const membershipReadable = currentUser?.membershipReadable || null;
  const role = currentUser?.role || [];
  const classId = course?.id || null;
  const classTitle = course?.title || null;

  const showCertificateButton = ({ attendance }) => {
    // TODO cohort flatten (eUnitTrackingId) merge into cohort
    const eUnitTracking = currentCohort?.eUnitTracking || null;

    if (!attendance) return false;
    if (isPublished(eUnitTracking) && eUnitTracking.fields.certificate) {
      // TODO cohort flatten
      return true;
    }
  };

  const manageAttendance = ({ student }) => {
    const userId = student?.id || null;

    dispatch(
      setCurrentModal({
        key: 'manageAttendance',
        data: {
          userId
        }
      })
    );
  };

  const openCertificate = ({ student, attendance }) => {
    const courseCohortRecord = generateCourseRecord({
      student,
      attendance,
      classId,
      classTitle,
      // TODO is courseCohorts needed?
      currentCohort
    });

    dispatch(setCurrentClass({ classId }));

    dispatch(setClassCertificate(courseCohortRecord)).then(() => {
      history.push(`${CERTIFICATE}/${courseCohortRecord.id}`);
    });
  };

  if (!users.fetched) {
    return (
      <tr>
        <td aria-hidden>
          <Loading text="Loading users..." />
        </td>
      </tr>
    );
  }

  return (
    <>
      {users.list && users.list.length ? (
        users.list.map((student, i) => {
          const {
            name,
            membership,
            locationId,
            attendance // TODO user flatten (move to firebase)
          } = student;
          const studentLocation = locations.hash && locations.hash[locationId];

          return (
            <tr
              className={`page-break-after d-print-block ${!isActiveMembership(membership) ? 'bg-danger-light' : ''}`}
              key={i}
            >
              <td className="border-right pr-0 d-print-table-row">
                <h6
                  data-demo="disabled"
                  className="pb-2 m-0 print-p-2"
                >
                  <b>{name}</b>
                </h6>

                <div className="border-top py-1 text-muted text-capitalize d-block d-md-none">
                  <i className={ICON_USER} /> {membershipReadable}
                  {!isActiveMembership(membership) && (
                    <i className="fas fa-exclamation-circle text-danger ml-2" />
                  )}
                </div>

                {studentLocation && (
                  <div className="border-top py-1 text-muted small w-100 print-p-2">
                    <i className="fas fa-map-marker-alt mr-1" />
                    {studentLocation.name}
                  </div>
                )}

                {canViewUserCourseCertificate(role) &&
                  showCertificateButton({ attendance }) && (
                    <div className="mt-2 d-print-none">
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => {
                          openCertificate({
                            student,
                            attendance
                          });
                        }}
                        type="button"
                      >
                        <i className={ICON_AWARD} /> Certificate
                      </button>
                    </div>
                  )}
              </td>
              <td className="d-none d-md-table-cell d-print-table-row">
                {attendance && attendance[classId] ? (
                  <UserCeAttendance
                    student={student}
                    attendance={attendance[classId]}
                  />
                ) : (
                  <div>
                    <p>No records.</p>

                    <div className="d-flex justify-content-end d-print-none border-top px-2 pt-3 pb-1">
                      <button
                        className="btn btn-sm btn-white"
                        onClick={() => {
                          manageAttendance({ student });
                        }}
                        type="button"
                      >
                        <i className="fas fa-plus" /> Attendance Record
                      </button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="4">No users enrolled.</td>
        </tr>
      )}
    </>
  );
};

export default Rows;
