import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchPage, fetchPages } from '../services/pages';
import { getPageLink } from './PageLinks';
import { getDashboard } from './Dashboards';
import { getResourcesByParentId } from './Resources';
import {
  SET_PAGES,
  RESET_PAGES,
  RESET_PAGE,
  SET_PAGE,
  SET_ENTRY_ORG_ID,
  SET_PAGE_FILES,
  RESET_PAGE_LINK,
  RESET_DASHBOARD
} from './types';

export const getPages = ({
  orgId,
  locationId,
  inactive = undefined,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = 'title',
  name = '',
  select
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchPages({
        locationId,
        orgId,
        inactive,
        page,
        limit,
        order,
        name,
        select
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_PAGES,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getPage = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchPage(id)
        .then((page) => {
          if (!page?.id) {
            reject(new Error('No Page found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: page?.orgId || null
          });

          if (page?.pageLinkId) {
            dispatch(getPageLink(page?.pageLinkId));
          }

          if (page?.dashboardId) {
            dispatch(getDashboard(page?.dashboardId));
          }

          dispatch({
            type: SET_PAGE,
            page: page?.id ? { ...page, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(page?.id, 'pages'));
          resolve(page);
        })
        .catch((error) => {
          console.error('get page', error);
          reject(error);
        });
    });
  };
};

export const setPageFiles = (cdnFiles = []) => {
  return (dispatch, getState) => {
    const { page } = getState();
    return new Promise((resolve) => {
      const newCdnFiles = [
        ...(page?.cdnFiles ? page.cdnFiles : []),
        ...cdnFiles
      ];

      dispatch({
        type: SET_PAGE_FILES,
        cdnFiles: newCdnFiles
      });
      resolve(newCdnFiles);
    });
  };
};

export const resetPage = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DASHBOARD });
    dispatch({ type: RESET_PAGE });
    dispatch({ type: RESET_PAGE_LINK });
  };
};

export const resetPages = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGES });
  };
};
