import { RESET_ANALYTICS, SET_ANALYTICS, SET_ENTRY_ORG_ID } from './types';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchAnalytics } from '../services/analytics';

export const getAnalytics = ({
  orgId,
  refId,
  refTable,
  gteDate,
  isUnique,
  order,
  page = 1,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchAnalytics({
        limit,
        orgId,
        page,
        ...(order ? { order } : {}),
        ...(refId ? { refId } : {}),
        ...(refTable ? { refTable } : {}),
        ...(gteDate ? { gteDate } : {}),
        ...(isUnique !== undefined ? { isUnique } : {})
      })
        .then((response) => {
          const list = response?.items || [];

          const responseData = {
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          };

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_ANALYTICS,
            ...responseData
          });

          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetAnalytics = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ANALYTICS });
  };
};
