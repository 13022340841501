import isAdminOwnerSuperAdmin from '../isAdminOwnerSuperAdmin';
import isProgramTrainer from '../isProgramTrainer';
import isProgramManager from '../isProgramManager';

export default function canReviewCompetence(
  role = [],
  hoursTotal = 0,
  hoursEarned = 0,
  userId = '',
  apprenticeshipUserSupervisorIds = []
) {
  // Trainers must wait until hours earned meets or exceeds target hours (hoursTotal)
  // and the user must be associated with the trainer through their supervisorIds
  if (
    hoursEarned >= hoursTotal &&
    isProgramTrainer(role) &&
    apprenticeshipUserSupervisorIds.includes(userId)
  ) {
    return true;
  }

  return isProgramManager(role) || isAdminOwnerSuperAdmin(role);
}
