// Student / User Application
// https://app.turbinelms.com
export const LOCAL_TURBINE = process.env.REACT_APP_LOCAL_TURBINE_URL || '';
export const TURBINE = LOCAL_TURBINE || process.env.REACT_APP_TURBINE_URL_APP;

// Authentication Application
// https://auth.turbinelms.com/login/sandbox
export const LOCAL_TURBINE_AUTH =
  process.env.REACT_APP_LOCAL_TURBINE_URL_AUTH || '';
export const TURBINE_AUTH =
  LOCAL_TURBINE_AUTH || process.env.REACT_APP_TURBINE_URL_AUTH;

// API
// https://api.turbineworkforce.com
export const LOCAL_TW_API = process.env.REACT_APP_LOCAL_TW_URL_API || '';
export const TW_API = LOCAL_TW_API || process.env.REACT_APP_TW_URL_API;

// API DOC Generation
// https://docs.turbinelms.com
export const LOCAL_TURBINE_DOCS_API =
  process.env.REACT_APP_LOCAL_TURBINE_URL_DOCS_API || '';
export const TURBINE_DOCS_API =
  LOCAL_TURBINE_DOCS_API || process.env.REACT_APP_TURBINE_URL_DOCS_API;

// Admin / Expert / Owner Application
// https://admin.turbine.is
export const LOCAL_TURBINE_ADMIN =
  process.env.REACT_APP_LOCAL_TURBINE_URL_ADMIN || '';
export const TURBINE_ADMIN =
  LOCAL_TURBINE_ADMIN || process.env.REACT_APP_TURBINE_URL_ADMIN;

// Page (fka Console) External / Internal Integrations Application
// NEW: https://page.turbine.is
// OLD: https://console.turbinelms.com
export const LOCAL_TURBINE_PAGE =
  process.env.REACT_APP_LOCAL_TURBINE_URL_PAGE || '';
export const TURBINE_PAGE =
  LOCAL_TURBINE_PAGE || process.env.REACT_APP_TURBINE_URL_PAGE;

// Voice Driven Employee Application
// https://vela.turbine.us.com
export const LOCAL_TURBINE_VELA =
  process.env.REACT_APP_LOCAL_TURBINE_URL_VELA || '';
export const TURBINE_VELA =
  LOCAL_TURBINE_VELA || process.env.REACT_APP_TURBINE_URL_VELA;

export const TURBINE_ADMIN_DOCS =
  'https://console.turbinelms.com/o/turbine-courses/courses/view/turbine-admin-docs';
export const TURBINE_TRAINING_PROGRAM_DOCS =
  'https://console.turbinelms.com/o/turbine-courses/courses/view/turbine-training-program-docs';
export const VELA_DOCS =
  'https://console.turbinelms.com/o/turbine-courses/courses/view/turbine-vela-docs';
export const TURBINE_MARKETING_SITE = 'https://turbineworkforce.com';
export const TURBINE_TOS_URL = `${TURBINE_MARKETING_SITE}/tos`;
export const TURBINE_PRIVACY_URL = `${TURBINE_MARKETING_SITE}/privacy`;
