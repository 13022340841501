import {
  RESET_CURRENT_TOPIC,
  SET_CURRENT_TOPIC,
  SET_PREREQUISITE_TOPICS,
  SET_TOPIC_FILES
} from '../../actions/types';
import topicState from '../initialState/topicState';

const initialState = {
  ...topicState,
  fetched: false,
  cdnFiles: null,
  prerequisiteTopics: null // [{}] Array of Topics that are prerequisites of the currentTopic
};

export default function currentTopic(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TOPIC:
      return {
        ...state,
        ...action.currentTopic,
        cdnFiles: action.cdnFiles,
        fetched: true
      };
    case SET_TOPIC_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_CURRENT_TOPIC:
      return {
        ...state,
        ...initialState
      };
    case SET_PREREQUISITE_TOPICS:
      return {
        ...state,
        prerequisiteTopics: action.prerequisiteTopics
      };
    default:
      return state;
  }
}
