import { RESET_CURRENT_COHORT, SET_CURRENT_COHORT } from '../../actions/types';

const initialState = {
  id: '', // TODO list all keys
  title: ''
};

export default function currentCohort(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_COHORT:
      return {
        ...state,
        ...action.currentCohort
      };
    case RESET_CURRENT_COHORT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
