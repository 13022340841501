import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canAccessAllLocations } from '@apprentage/utils';
import {
  getLogbookRecords,
  resetLogbookRecords
} from '../../../../../../actions/LogbookRecords';
import { RECORDS_PAGINATION_LIMIT } from '../../../../../../constants/api';
import LogbookRecordsList from '../../../../../lists/LogbookRecordsList';
import PaginationSupabase from '../../../../../ManageContent/PaginationSupabase';
import RecordsFilters from './RecordsFilters';

const AllRecords = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  const locationId = currentUser?.locationId || '';

  const initialListConfig = {
    orgId,
    logbookId: logbook?.id,
    limit: RECORDS_PAGINATION_LIMIT,
    page: 1,
    // Program Manager or Trainer
    ...(canAccessAllLocations(role) ? {} : { locationId })
  };

  const [listConfig, setListConfig] = useState(initialListConfig);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const handleRefreshRecords = () => {
    dispatch(getLogbookRecords(listConfig));
  };

  const onSelectionChange = useCallback(
    (selectedRows) => {
      setSelectedRecords(selectedRows);
    },
    [setSelectedRecords]
  );

  useEffect(() => {
    return function cleanup() {
      dispatch(resetLogbookRecords());
    };
  }, [dispatch]);

  if (!logbook?.id && logbook.fetched) {
    return null;
  }

  if (!logbookRecords.list && logbookRecords.fetched) {
    return (
      <div className="mt-3 text-center">
        <p>No Logbook Records.</p>
      </div>
    );
  }

  return (
    <div className="position-relative">
      <RecordsFilters
        className="bg-light mb-3 p-1 border"
        onChange={setListConfig}
        selectedRecords={selectedRecords}
        handleRefreshRecords={handleRefreshRecords}
      />

      <LogbookRecordsList
        listConfig={listConfig}
        handleRefreshRecords={handleRefreshRecords}
        onSelectionChange={onSelectionChange}
        theme="light"
      />

      {Array.isArray(logbookRecords.list) && logbookRecords.list.length > 0 && (
        <PaginationSupabase
          items={logbookRecords?.list}
          pagination={logbookRecords?.pagination}
          page={logbookRecords?.pagination?.page}
          onClickNext={() => {
            setListConfig((prev) => ({
              ...prev,
              page: logbookRecords?.pagination?.page + 1
            }));
            // goToElem('root');
          }}
          onClickPrev={() => {
            setListConfig((prev) => ({
              ...prev,
              page: logbookRecords?.pagination?.page - 1
            }));
            // goToElem('root');
          }}
        />
      )}
    </div>
  );
};

export default AllRecords;
