import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { PRODUCT_ENTERPRISE_BUSINESS } from '../../../../constants/billing';
import { formatCurrency } from '../../../../services/billing';
import { numDaysTillUnixDate, mdDate, parseUnix } from '../../../../utils/date';
import './style.css';

function calculateTotalAmount({ amount, quantity }) {
  const totalBeforeTax = (amount / 100) * quantity;
  const totalAmount = totalBeforeTax;

  return totalAmount;
}

const Subscription = ({
  currentPeriodEnd,
  amount,
  quantity,
  productId,
  interval
}) => {
  const billing = useSelector((state) => state.billing);
  const products = pathOr(null, ['products'], billing);
  const product = products ? products.find((p) => p.id === productId) : null;

  if (!product) {
    return null;
  }

  const productName = pathOr(null, ['name'], product);
  const turbineProduct = pathOr(null, ['metadata', 'turbineProduct'], product);
  // Calculate number of days left till renewal
  const daysTillRenewal = numDaysTillUnixDate(currentPeriodEnd);
  // Divide daysTillRenewal by avg days in month, convert to percent
  const daysTillRenewalPercent = (daysTillRenewal / 30) * 10;
  const totalAmount = calculateTotalAmount({ amount, quantity });
  const renewalDate = mdDate(parseUnix(currentPeriodEnd));

  return (
    <div className="bg-white p-3 border d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex align-items-center justify-content-center">
        <div
          title={`Renews automatically on ${renewalDate}`}
          className="subscription-indicator d-flex align-items-center justify-content-center bg-success rounded"
        >
          <div className="indicator-interval-container d-flex align-items-center justify-content-center">
            <div
              className="indicator-interval"
              style={{
                background: `conic-gradient(rgb(255, 255, 255) ${daysTillRenewalPercent}%, transparent 0%)`
              }}
            />
          </div>
        </div>
        <div className="ml-2">
          <div className="d-flex align-items-center">
            <div className="mr-1">
              <strong>{productName}</strong> Platform
            </div>
            {turbineProduct === PRODUCT_ENTERPRISE_BUSINESS && (
              <div>({quantity} User Licenses)</div>
            )}
          </div>
          <div className="text-muted">
            Billing {interval ? `${interval}ly` : ''} • Next invoice on{' '}
            {renewalDate} for {formatCurrency(totalAmount)} + tax
          </div>
        </div>
      </div>
      {/* <div>
        { metadata.price_description }
      </div> */}
    </div>
  );
};

export default Subscription;
