import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaEllipsisH, FaLayerGroup } from 'react-icons/fa';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { canManageApprenticeship } from '../../../../../permissions/manage';
import { routeWithOrgId } from '../../../../../services/organizations';

const DotMenuCourses = () => {
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  // Current User
  const role = currentUser?.role || [];
  // Permissions
  const manageApprenticeship = canManageApprenticeship(role);

  if (!manageApprenticeship) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />

          <Link
            className="dropdown-item py-2"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/manage/${apprenticeshipId}`,
              urlParams: {
                orgId,
                scrollToId: 'apprenticeshipCoursesSelect'
              }
            })}
          >
            <span className="d-flex align-items-center">
              <FaLayerGroup />
              <span className="ml-2">Manage Courses</span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DotMenuCourses;
