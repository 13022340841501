import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../Modal';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import {
  IMAGE_BUILD_OWN_TRAININGS,
  IMAGE_CLONE_SUCCESSFUL_TRAININGS
} from '../../../constants/assets';
import {
  MODAL_KEY_CLONE_SUCCESSFUL_TRAININGS,
  MODAL_KEY_BUILD_YOUR_OWN_TRAINING
} from '../../../constants/modals';
import { resetApprenticeship } from '../../../actions/Apprenticeships';

const CreateTrainingProgram = () => {
  const dispatch = useDispatch();
  const currentModal = useSelector((state) => state.currentModal);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };
  const handleClickOnTraining = (modalKey) => {
    dispatch(setCurrentModal({ key: modalKey }));
  };
  useEffect(() => {
    dispatch(resetApprenticeship());
  }, []);

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      size="large"
    >
      <div className="p-3 p-md-5">
        <div className="text-center">
          <h2 className="font-weight-bold text-dark">
            Create Training Program
          </h2>
        </div>
        <div className="d-flex mt-5">
          <div className="row">
            <div
              className="col-12 col-md-6"
              aria-hidden
              onClick={() => {
                handleClickOnTraining(MODAL_KEY_BUILD_YOUR_OWN_TRAINING);
              }}
            >
              <div className="d-flex flex-column justify-content-between card shadow border rounded overflow-hidden card-hover-transition p-3 p-md-5 text-center h-100">
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={IMAGE_BUILD_OWN_TRAININGS}
                      alt="Build own trainings"
                    />
                  </div>
                  <h4 className="font-weight-bold text-dark mt-3 mt-md-4">
                    Build Your Own Training
                  </h4>
                  <p className="h6 mt-3">
                    Build your own work process and competency models
                  </p>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="btn btn-md bg-purple-light text-white px-3"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <div
                className="card shadow border rounded overflow-hidden card-hover-transition p-3 p-md-5 text-center h-100"
                aria-hidden
                onClick={() => {
                  handleClickOnTraining(MODAL_KEY_CLONE_SUCCESSFUL_TRAININGS);
                }}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      src={IMAGE_CLONE_SUCCESSFUL_TRAININGS}
                      alt="Customize Proven Frameworks"
                    />
                  </div>
                  <h4 className="font-weight-bold text-dark mt-3 mt-md-4">
                    Customize Proven Frameworks
                  </h4>
                  <p className="h6 mt-3">
                    Start training from a position of success.
                  </p>
                  <div className="text-dark font-weight-bold h6">
                    <div>RAPIDS codes</div>
                    <div>O*NET codified</div>
                    <div>Competency Models</div>
                    <div>Occupation</div>
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="btn btn-md bg-purple-light text-white px-3"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTrainingProgram;
