import * as twApi from './tw-api';
import { COS_OCCUPATIONS } from '../constants/api';

/**
 * Gets Occupations by keyword
 * @param {string} keyword - occupation user is searching for
 * @param {number} limit - max number of results
 * @returns {Promise<object>}
 */
export async function getOccupations({ keyword, limit = 20 }) {
  const response = await twApi.get(COS_OCCUPATIONS, {
    keyword,
    limit
  });

  return response;
}
