import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';
import formatData from '../../../services/formatData';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';
import { createEntry, manageEntry, updateEntry } from '../../../services/entry';
import { addEntriesToTopic } from '../../../services/topics';
import { setCurrentChallenge } from '../../../actions/Challenges';
import RenderTinyMceEditor from '../../ManageContent/Wysiwyg/RenderTinyMceEditor';
import RadioGroup from '../../ManageContent/RadioGroup';
import Questions from '../../Quiz/Questions';
import SkillsSelectMenu from '../../OrgConsole/Skills/SkillsSelectMenu';

const difficultyOptions = ['1', '2', '3', '4', '5'];
const challengeTypeOptions = ['form', 'url'];
const submissionPromptDefaultText = {
  url: '<p>Submit your work in the form of a URL to complete this challenge. Someone will review your submission.</p>',
  form: '<p>Complete the fields below. Someone will review your submission.</p>'
};

const Form = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const data = useSelector((state) => state.currentChallenge);
  //
  const orgId = organization?.id || null;
  const locationSearch = location?.search || null;
  const searchParams = convertUrlParamsToObject(locationSearch); // TODO use URLSearchParams
  const { classId, topicId, manageType, contentType } = searchParams;
  const [loading, setLoading] = useState(false);
  const [id] = useState(data.id);
  const [title, setTitle] = useState(data.title || '');
  const [difficulty, setDifficulty] = useState(
    data.difficulty || difficultyOptions[0]
  );
  const [challengeType, setChallengeType] = useState(
    data.challengeType || challengeTypeOptions[0]
  );
  const [activeChallenge, setActiveChallenge] = useState(
    data.activeChallenge || false
  );
  const [questions, setQuestions] = useState(data.questions);
  const [challengeSkills, setChallengeSkills] = useState(data.challengeSkills);

  const onChangeSkills = (options) => {
    setChallengeSkills(options.map((skill) => skill.value));
  };

  const solutionRef = useRef(null);
  const [solution, setSolution] = useState(data.solution || '');

  const instructorNotesRef = useRef(null);
  const [instructorNotes, setInstructorNotes] = useState(
    data.instructorNotes || ''
  );

  const questionRef = useRef(null);
  const [question, setQuestion] = useState(data.question || '');

  const submissionPromptTextRef = useRef(null);
  const [submissionPromptText, setSubmissionPromptText] = useState(
    data.submissionPromptText || submissionPromptDefaultText.form
  );

  // NEW EDITOR
  const [firstTimeConvertEditor, setFirstTimeConvertEditor] = useState(true);
  const [enableNewEditor] = useState(data.enableNewEditor || true);

  const convertOldEditorToNewEditor = () => {
    const converter = new Showdown.Converter();

    const solutionHTML = converter.makeHtml(data.solution);
    setSolution(solutionHTML);

    const instructorNotesHTML = converter.makeHtml(data.instructorNotes);
    setInstructorNotes(instructorNotesHTML);

    const questionHTML = converter.makeHtml(data.question);
    setQuestion(questionHTML);

    const submissionPromptTextHTML = converter.makeHtml(
      data.submissionPromptText
    );
    setSubmissionPromptText(submissionPromptTextHTML);
  };

  // If first time loading
  // If the new editor is not enabled
  // If user is editing an existing entry
  if (firstTimeConvertEditor && !data.enableNewEditor && data.id) {
    console.log('run conversion');
    setFirstTimeConvertEditor(false);
    convertOldEditorToNewEditor();
  }
  // /NEW EDITOR

  const showSuccess = () => {
    toast.success('Challenge saved!');
    setLoading(false);
  };

  const goBack = () => {
    history.push(
      `${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=topic&classId=${classId}&topicId=${topicId}&entryId=${topicId}`
    );
  };

  const editChallenge = (updatedData) => {
    updateEntry(updatedData, id).then(() => {
      showSuccess();
    });
  };

  const createChallenge = (dataToSave) => {
    createEntry({
      contentType,
      data: dataToSave
    }).then((entry) => {
      addEntriesToTopic({
        entryIds: entry?.sys?.id ? [entry.sys.id] : null,
        contentType,
        topicId
      }).then(() => {
        showSuccess();

        dispatch(setCurrentChallenge(entry.sys.id)).then(() => {
          const urlParams = manageEntry({
            manageType: 'edit',
            classId,
            topicId,
            entryId: entry.sys.id,
            contentType: 'challenge'
          });

          // Update URL to match same structure as if User was editing an existing Topic
          history.push(MANAGE_CURRENT_ENTRY + urlParams);
        });
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      title,
      challengeType,
      questions,
      difficulty,
      enableNewEditor,
      activeChallenge,
      classId,
      topicId,
      orgId
    };

    if (id) {
      if (challengeSkills) {
        dataToSave.challengeSkills = challengeSkills;
      }

      if (solutionRef && solutionRef.current && solutionRef.current.isDirty) {
        dataToSave.solution = solutionRef.current.getContent();
      }

      if (
        instructorNotesRef &&
        instructorNotesRef.current &&
        instructorNotesRef.current.isDirty
      ) {
        dataToSave.instructorNotes = instructorNotesRef.current.getContent();
      }

      if (questionRef && questionRef.current && questionRef.current.isDirty) {
        dataToSave.question = questionRef.current.getContent();
      }

      if (
        submissionPromptTextRef &&
        submissionPromptTextRef.current &&
        submissionPromptTextRef.current.isDirty
      ) {
        dataToSave.submissionPromptText =
          submissionPromptTextRef.current.getContent();
      }

      if (activeChallenge) {
        if (!instructorNotes && !dataToSave.instructorNotes) {
          toast.error('Reviewer notes are required.');
          return null;
        }

        if (!question && !dataToSave.question) {
          toast.error(
            `${challengeType === 'form' ? 'Form fields' : 'Questions'} are required.`
          );
          return null;
        }

        if (challengeType === 'form' && !questions && !dataToSave.questions) {
          toast.error('At least one form field is required.');
          return null;
        }
      }
    }

    const formattedData = formatData(dataToSave, 'challenge');

    setLoading(true);

    if (manageType === 'edit') {
      editChallenge(formattedData);
    } else {
      createChallenge(formattedData);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <p>Challenges require human-review of user submissions.</p>
          <div className="my-3">
            <label className="mb-2">
              <b>Title:</b> <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={title}
              autoFocus={!id}
              onChange={(e) => {
                const { value } = e.currentTarget;

                setTitle(value);
              }}
              required
            />
          </div>

          <div className="mb-3">
            <label className="mb-1">
              <b>Challenge Type:</b> <span className="text-danger">*</span>
            </label>
            <div className="text-muted mb-2">
              {challengeType === 'url' && (
                <>Users will submit a url in response.</>
              )}
              {challengeType === 'form' && (
                <>Users will fill out a form in response.</>
              )}
            </div>
            <div className="d-flex align-items-center">
              <select
                type="text"
                className="form-control"
                name="challengeType"
                defaultValue={challengeType}
                style={{ width: '100px' }}
                disabled={id}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setChallengeType(value);
                }}
                required
              >
                {challengeTypeOptions.map((value, i) => (
                  <option
                    key={i}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </select>
              {id && <i className="fas fa-lock ml-2" />}
            </div>
          </div>

          {id && (
            <>
              <div
                id="challenge-activeChallenge"
                className="form-group mb-4 border-top border-bottom py-3"
              >
                <label className="mb-1">
                  <b>Active:</b>
                </label>
                <div className="text-muted medium mb-2">
                  Allow users to submit answers to this challenge.
                </div>
                <RadioGroup
                  name="activeChallenge"
                  value={activeChallenge}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setActiveChallenge(value === 'true');
                  }}
                />
              </div>

              <SkillsSelectMenu
                showManageLink
                showHelpText
                className="form-group border-bottom pb-4 mb-4"
                ids={challengeSkills}
                onChange={onChangeSkills}
              />

              <div className="form-group pb-4 mb-4 border-bottom ">
                <label className="mb-1">
                  <b>Difficulty:</b> <span className="text-danger">*</span>
                </label>
                <div className="text-muted medium mb-2">
                  Rate the difficulty of this challenge from 1-5. (Used to
                  calculate points awarded for accepted answers)
                </div>
                <select
                  type="text"
                  className="form-control"
                  name="difficulty"
                  defaultValue={difficulty}
                  style={{ width: '100px' }}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setDifficulty(value);
                  }}
                  required
                >
                  {difficultyOptions.map((value, i) => (
                    <option
                      key={i}
                      value={value}
                    >
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              {enableNewEditor && (
                <RenderTinyMceEditor
                  id="challenge-question"
                  contentId={id}
                  contentType="challenge"
                  className="mb-3"
                  title={challengeType === 'form' ? 'Description' : 'Question'}
                  defaultValue={question}
                  editorRef={questionRef}
                  uploadPdfButton={false}
                  required
                />
              )}

              {enableNewEditor && (
                <RenderTinyMceEditor
                  id="challenge-submissionPromptText"
                  contentId={id}
                  contentType="challenge"
                  title="Submission Prompt"
                  subtitle="Shown above the form where users submit their answers."
                  className="mb-5"
                  defaultValue={
                    submissionPromptText ||
                    submissionPromptDefaultText[challengeType]
                  }
                  editorRef={submissionPromptTextRef}
                  // uploadImgButton={false}
                  uploadPdfButton={false}
                  embedMediaButton={false}
                  required
                />
              )}

              {challengeType === 'form' && (
                <Questions
                  contentId={id}
                  contentType="challenge"
                  className="mb-4"
                  parent={{
                    title: 'Form fields'
                  }}
                  child={{
                    title: 'Options'
                  }}
                  data={questions}
                  onUpdate={(updatedQuestions) => {
                    setQuestions(
                      updatedQuestions && updatedQuestions.length > 0
                        ? updatedQuestions
                        : []
                    );
                  }}
                  guidedQuiz={false}
                />
              )}

              {enableNewEditor && (
                <RenderTinyMceEditor
                  id="challenge-instructorNotes"
                  contentId={id}
                  contentType="challenge"
                  className="mb-5"
                  title="Reviewer Notes"
                  subtitle="List of criteria to evaluate user submissions."
                  defaultValue={instructorNotes}
                  editorRef={instructorNotesRef}
                  // uploadImgButton={false}
                  uploadPdfButton={false}
                  embedMediaButton={false}
                  required
                />
              )}

              {challengeType === 'url' && (
                <RenderTinyMceEditor
                  id="challenge-solution"
                  contentId={id}
                  contentType="challenge"
                  // className="list-group-item mb-3"
                  title="Hint"
                  subtitle="Helpful tips if users get stuck trying to solve this Challenge. (optional)"
                  defaultValue={solution}
                  editorRef={solutionRef}
                  uploadImgButton={false}
                  uploadPdfButton={false}
                  embedMediaButton={false}
                />
              )}
            </>
          )}
        </div>

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={!!loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            <button
              className="btn btn-sm btn-link"
              onClick={goBack}
              title="Cancel"
              type="button"
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
