import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChallenges } from '../../../actions/Challenges';
import List from './List';
import './style.css';

const AnswersList = ({
  data,
  className = '',
  classId,
  userNamePlainText = false,
  hideCourse = false,
  hideTopic = false,
  hideChallenge = false,
  reviewActive = false,
  orgId
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentAnswers = useSelector((state) => state.currentAnswers);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!reviewActive) {
      cols.push('reviewCheckboxCol');
    }

    if (hideCourse) {
      cols.push('classId');
    }

    if (hideTopic) {
      cols.push('topicId');
    }

    if (hideChallenge) {
      cols.push('challengeId');
    }

    return cols;
  }, [hideChallenge, hideCourse, hideTopic, reviewActive]);

  useEffect(() => {
    const config = {};

    if (orgId) config.orgId = orgId;
    if (classId) config.classId = classId;

    dispatch(getChallenges(config));
  }, [classId, dispatch, orgId]);

  return (
    <div className={`challenge-answers-list overflow-x-hidden ${className}`}>
      <List
        data={data || currentAnswers?.list || []}
        hiddenColumns={hiddenColumns}
        userNamePlainText={userNamePlainText}
        hideTopic={hideTopic}
        hideChallenge={hideChallenge}
        hideCourse={hideCourse}
        reviewActive={reviewActive}
      />
    </div>
  );
};

export default AnswersList;
