import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  createWageSchedule,
  updatedWageSchedule
} from '../../../services/employers';
import {
  getWageSchedules,
  resetWageSchedules
} from '../../../actions/Employers';
import { US_FED_MINIMUM_WAGE } from '../../../constants/wageSchedules';
import Modal from '../../Modal';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const ManageWageSchedule = () => {
  const dispatch = useDispatch();
  // redux
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // misc
  const currentModalKey = 'manageWageSchedule';
  const currentModal = useSelector((state) => state.currentModal);
  const modalKey = pathOr(null, ['key'], currentModal);
  const modalData = pathOr(null, ['data'], currentModal);
  const [loading, setLoading] = useState(false);

  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);
  const employerProfileId = pathOr(null, ['id'], employerProfile);
  const addressState = pathOr('', ['addressState'], modalData);
  const wageScheduleId = pathOr(null, ['id'], modalData);
  const entryWageMin = US_FED_MINIMUM_WAGE + 5.81; // Pad MIN WAGE so 1st period is at US FED MIN WAGE

  const [currentStep, setCurrentStep] = useState(modalData?.currentStep || 0);
  const [title, setTitle] = useState(modalData?.title || '');
  const [entryWage, setEntryWage] = useState(
    modalData?.entryWage || entryWageMin
  );
  const [wageRates, setWageRates] = useState(modalData?.wageRates || null);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const updateState = (data) => {
    if (data?.currentStep !== undefined) {
      setCurrentStep(data.currentStep);
    }

    if (data?.title) {
      setTitle(data.title);
    }

    if (data?.entryWage) {
      setEntryWage(data.entryWage);
    }

    if (data?.wageRates) {
      setWageRates(data.wageRates);
    }

    if (data?.loading) {
      setLoading(data.loading);
    }
  };

  const handleSuccess = () => {
    toast.success(`Wage Schedule ${wageScheduleId ? 'updated' : 'created'}!`);
  };

  const saveData = () => {
    const dataToSave = {
      entryWage: parseFloat(entryWage.toFixed(2)),
      title,
      wageRates
    };

    toast.info('Saving Wage Schedule...');

    if (wageScheduleId) {
      updatedWageSchedule(dataToSave, wageScheduleId).then(() => {
        dispatch(resetWageSchedules());
        dispatch(
          getWageSchedules({
            apprenticeshipId,
            employerProfileId
          })
        ).then(() => {
          handleSuccess();
          handleClose();
        });
      });
    } else {
      createWageSchedule({
        ...dataToSave,
        apprenticeshipId,
        employerProfileId
      }).then(() => {
        dispatch(
          getWageSchedules({
            apprenticeshipId,
            employerProfileId
          })
        ).then(() => {
          handleSuccess();
          handleClose();
        });
      });
    }
    handleClose();
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${modalKey}`}
      visible={modalKey === currentModalKey}
      close={handleClose}
      pos="middle"
      theme="dark"
    >
      <div className="card border-0">
        <div className="card-header bg-dark">
          <div className="h5 m-0 text-white">
            {wageScheduleId ? 'Edit' : 'New'} Wage Schedule
          </div>
        </div>

        {currentStep === 0 && (
          <StepOne
            key={currentStep}
            updateState={updateState}
            addressState={addressState}
            wageSchedule={{
              title,
              entryWage,
              id: wageScheduleId
            }}
          />
        )}

        {currentStep === 1 && (
          <StepTwo
            key={currentStep}
            updateState={updateState}
            saveData={saveData}
            loading={loading}
            addressState={addressState}
            wageSchedule={{
              wageRates,
              title,
              entryWage
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default ManageWageSchedule;
