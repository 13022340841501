import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_DASHBOARD } from '../../../constants/routes';
import { getCourses } from '../../../actions/Courses';
import { ICON_COURSES } from '../../../constants/assets';
import withOrgConsole from '../../App/withOrgConsole';
import CreateCourseButton from '../../btns/CreateCourseButton';
import CoursesList from '../../CoursesList';
import Tabs from './Tabs';
import OrgConsoleHeader from '../OrgConsoleHeader';

const Courses = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  // Organization
  const orgId = organization?.id || null;

  useEffect(() => {
    dispatch(getCourses({ orgId }));
  }, [dispatch, orgId]);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Courses"
            image={ICON_COURSES}
            imageSize={25}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <CreateCourseButton />
          </OrgConsoleHeader>

          <Tabs active="all" />

          <div className="card-body bg-white border-left border-right border-bottom overflow-content">
            <CoursesList
              list={courses?.list}
              showContentCol
              showSyndicationCol
              showGroupsCol
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Courses);
