import * as twApi from './tw-api';
import { ANALYTICS_TRACK, DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'analytics';

export const analyticsTracking = async ({
  refId,
  refTable,
  isUnique,
  userId,
  orgId
}) => {
  if (!refId) {
    throw new Error('refId is required');
  }

  if (!refTable) {
    throw new Error('refTable is required');
  }

  const language = window.navigator?.language;
  const platform = window.navigator?.platform;

  const data = {
    refId,
    refTable,
    ...(isUnique ? { isUnique } : {}),
    ...(userId ? { userId } : {}),
    ...(orgId ? { orgId } : {}),
    ...(language ? { language } : {}),
    ...(platform ? { platform } : {})
  };

  const dataToSave = JSON.stringify(data);

  try {
    const response = await twApi.post(ANALYTICS_TRACK, '', dataToSave);

    return response;
  } catch (error) {
    console.error(`analytics/track ${refTable}`, error);
    throw new Error(error);
  }
};

export const fetchAnalytics = ({
  ids = [],
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt',
  count = undefined,
  isUnique = undefined,
  gteDate = undefined,
  refId = undefined,
  refTable = undefined,
  orgId = undefined
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      page,
      limit
    };

    if (count !== undefined) {
      config.count = count;
    }

    if (gteDate !== undefined) {
      config['f.createdAt[gte]'] = gteDate; // '2023-09-06T02:11:52.844Z'
    }

    if (isUnique !== undefined) {
      config['f.isUnique[is]'] = isUnique;
    }

    if (refId !== undefined) {
      config['f.refId[eq]'] = refId;
    }

    if (refTable !== undefined) {
      config['f.refTable[eq]'] = refTable;
    }

    if (orgId !== undefined) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`fetch ${table}`, error);
        reject(error);
      });
  });
};

export const fetchAnalytic = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateAnalytic = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createAnalytic = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteIntegration = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
