import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import Showdown from 'showdown';
import { createEntry, updateEntry } from '../../services/entry';
import formatData from '../../services/formatData';
import RadioGroup from '../ManageContent/RadioGroup';
import Credits from './Credits';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';
import { routeWithClassId } from '../../services/courses';
import { CONSOLE_CE_TRACKING } from '../../constants/routes';
// import ManageButton from '../btns/ManageButton';

const Form = () => {
  const history = useHistory();
  const infoRef = useRef(null);
  // redux
  const data = useSelector((state) => state.eUnitTracking);
  const course = useSelector((state) => state.course);
  // misc
  const classId = pathOr(null, ['id'], course);
  // local state
  const [loading, setLoading] = useState(false);
  const [id] = useState(data.id || null);
  const [title, setTitle] = useState(data.title || '');
  const [code, setCode] = useState(data.code || '');
  const [semesterCode, setSemesterCode] = useState(data.semesterCode || '');
  const [currentSession, setCurrentSession] = useState(
    data.currentSession || ''
  );
  const [sessionsTopicSync, setSessionsTopicSync] = useState(
    data.sessionsTopicSync || true
  );
  const [sessions, setSessions] = useState(data.sessions || '');
  const [trackingInterval, setTrackingInterval] = useState(
    data.trackingInterval || ''
  );
  const [attendancePerSession, setAttendancePerSession] = useState(
    data.attendancePerSession || ''
  );
  const [info, setInfo] = useState(data.info); // TODO change to "body"
  const [sessionCredits, setSessionCredits] = useState(
    data.sessionCredits || []
  );
  const dangerZone = useMemo(() => {
    if (currentSession) {
      return true;
    }

    return false;
  }, [currentSession]);

  // NEW EDITOR
  const [firstTimeConvertEditor, setFirstTimeConvertEditor] = useState(true);
  const [enableNewEditor] = useState(data.enableNewEditor || true);

  const convertOldEditorToNewEditor = () => {
    const converter = new Showdown.Converter();

    const infoHTML = converter.makeHtml(data.info);
    setInfo(infoHTML);
  };

  useEffect(() => {
    // If first time loading
    // If the new editor is not enabled
    // If user is editing an existing entry
    if (firstTimeConvertEditor && !data.enableNewEditor && data.id) {
      console.log('run conversion');
      setFirstTimeConvertEditor(false);
      convertOldEditorToNewEditor();
    }
  }, []);
  // /NEW EDITOR

  const handleCancel = () => {
    history.goBack();
  };

  const createEUnitTracking = (formattedData) => {
    return createEntry({
      contentType: 'eUnitTracking',
      data: formattedData
    });
  };

  const editEUnitTracking = (formattedData) => {
    updateEntry(formattedData, id).then(() => {
      setLoading(false);
      toast.success('Settings updated!');
      history.push(
        routeWithClassId({
          route: CONSOLE_CE_TRACKING,
          classId
        })
      );
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!sessionCredits || (sessionCredits && sessionCredits.length === 0)) {
      toast.error('Add at least one Session Credit.');

      return false;
    }

    setLoading(true);

    const dataToSave = {
      classId,
      enableNewEditor,
      code,
      title,
      semesterCode,
      currentSession,
      sessionsTopicSync,
      trackingInterval,
      attendancePerSession,
      sessionCredits: sessionCredits.length > 0 ? sessionCredits : null,
      ...(sessions ? { sessions } : {})
    };

    // infoRef
    if (infoRef && infoRef.current && infoRef.current.isDirty) {
      dataToSave.info = infoRef.current.getContent();
    }

    const formattedData = formatData(dataToSave, 'eUnitTracking');

    if (id) {
      editEUnitTracking(formattedData);
    } else {
      createEUnitTracking(formattedData);
    }
  };

  return (
    <div className="card mt-3 mb-5">
      <form onSubmit={onSubmit}>
        <div className="card-header bg-light p-3">
          <h5 className="m-0 text-capitalize">
            {title || 'New Education Unit Tracking'}
          </h5>
        </div>
        <div className="card-body">
          <div
            id="class-currentSession"
            className="mb-3"
          >
            <label className="mb-2">
              <b>Current Session:</b> <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              min="1"
              className="form-control"
              name="currentSession"
              defaultValue={currentSession}
              autoFocus={id}
              onChange={(e) => {
                const { value } = e.currentTarget;

                setCurrentSession(parseInt(value, 10));
              }}
              required
            />
          </div>

          <div
            className={`bd-callout ${dangerZone ? 'bd-callout-danger' : ''}`}
          >
            {dangerZone && (
              <>
                <h5>
                  <b>Danger Zone</b>
                </h5>
                <div className="mb-3">
                  Once a class has begun, changing these settings can adversely
                  effect tracking attendance and reporting.
                </div>
                <hr />
              </>
            )}

            <div className="mb-3">
              <label className="mb-2">
                <b>Tracking Settings Title:</b>{' '}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                defaultValue={title}
                autoFocus={!id}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setTitle(value);
                }}
                required
              />
              <div className="text-muted medium mb-2">
                Ex: [Class Code] : [Semester Code]. For internal use only.
              </div>
            </div>
            <div className="mb-3">
              <label className="mb-2">
                <b>Class Code:</b> <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="code"
                defaultValue={code}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setCode(value);
                }}
                required
              />
              <div className="text-muted medium mb-2">
                Course Number, Class code, etc.
              </div>
            </div>
            <div
              id="class-semesterCode"
              className="mb-3"
            >
              <label className="mb-2">
                <b>Semester Code:</b> <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="semesterCode"
                defaultValue={semesterCode}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setSemesterCode(value);
                }}
                required
              />
            </div>
            <div
              id="class-sessionsTopicSync"
              className="form-group mb-3"
            >
              <label className="mb-1">
                <b>Sessions Topic Sync:</b>
              </label>
              <div className="text-muted medium mb-2">
                Synchronize Sessions with Topics for detailed reporting,
                including [number of] Sessions, Topic Name, Category and Level.
              </div>
              <RadioGroup
                name="sessionsTopicSync"
                value={sessionsTopicSync}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setSessionsTopicSync(value === 'true');
                }}
              />
            </div>

            {!sessionsTopicSync && (
              <div
                id="class-sessions"
                className="mb-3"
              >
                <label className="mb-2">
                  <b>Sessions:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="sessions"
                  defaultValue={sessions}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setSessions(value);
                  }}
                  required
                />
              </div>
            )}

            <div
              id="class-trackingInterval"
              className="mb-3"
            >
              <label className="mb-2">
                <b>Tracking Interval:</b> <span className="text-danger">*</span>
              </label>
              <div className="text-muted medium mb-2">
                Number of minutes between each student check in.
              </div>
              <input
                type="number"
                className="form-control"
                name="trackingInterval"
                defaultValue={trackingInterval}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setTrackingInterval(parseInt(value, 10));
                }}
                required
              />
            </div>
            <div
              id="class-attendancePerSession"
              className="mb-3"
            >
              <label className="mb-2">
                <b>Attendance Per Session:</b>{' '}
                <span className="text-danger">*</span>
              </label>
              <div className="text-muted medium mb-2">
                The number of times a students must check in per session.
              </div>
              <input
                type="text"
                className="form-control"
                name="attendancePerSession"
                defaultValue={attendancePerSession}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setAttendancePerSession(parseInt(value, 10));
                }}
                required
              />
            </div>

            {/* {isPublished(course) && course.eUnits && course.eUnits.length ? (
              <div className="my-3">
                <label className="mb-2">
                  <b>Session Credits:</b>
                </label>
                <div>
                  One or more <ManageButton
                    className="btn-link"
                    classId={classId}
                    manageType="edit"
                    buttonText="Edit Topics"
                    contentType="class"
                    scrollToId="class-eUnits"
                  >
                    Education Units
                  </ManageButton> exist which override this configuration of Session Credits.
                </div>
              </div>
            ) : (
              <Credits
                parent={{
                  title: "Session Credits"
                }}
                data={integration && integration.sessionCredits ? integration.sessionCredits : []}
                onUpdate={onUpdateCredits}
              />
            )} */}

            <Credits
              parent={{
                title: 'Session Credits'
              }}
              data={sessionCredits || []}
              onUpdate={(newSessionCredits) => {
                setSessionCredits(newSessionCredits);
              }}
            />
          </div>

          {enableNewEditor && (
            <RenderTinyMceEditor
              id="challenge-question"
              // contentId={id}
              contentType="eUnitTracking"
              className="my-3"
              title="Additional Information"
              subtitle="Shown to users when checking in for an attendance session."
              defaultValue={info}
              editorRef={infoRef}
              uploadImgButton={false}
              uploadPdfButton={false}
              embedMediaButton={false}
              maxCharCount={2500}
            />
          )}
        </div>

        <div className="card-footer d-flex align-items-center">
          <button
            className="btn btn-primary mr-2"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            className="btn btn-sm btn-link"
            onClick={handleCancel}
            title="Cancel"
            type="button"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
