import {
  RESET_CONTEXT_QUESTIONS,
  SET_CONTEXT_QUESTIONS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  pagination: null
};

export default function contextQuestions(state = initialState, action) {
  switch (action.type) {
    case SET_CONTEXT_QUESTIONS: {
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    }
    case RESET_CONTEXT_QUESTIONS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
