import { getClient, flattenItems } from '../contentful';

export const fetchChallenges = ({ orgId, classId }) => {
  const config = {
    content_type: 'challenge',
    include: 1,
    limit: 200,
    'fields.orgId': orgId,
    'fields.classId': classId
  };

  return new Promise((resolve) => {
    getClient()
      .getEntries(config)
      .then(({ items }) => {
        const list = items.length ? flattenItems(items) : null;

        resolve(list);
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

export const fetchAnswers = ({ orgId, classId, status }) => {
  const config = {
    content_type: 'answer',
    include: 1,
    limit: 200,
    'fields.orgId': orgId,
    'fields.classId': classId
  };

  if (status) {
    config['fields.status'] = status;
  }

  return new Promise((resolve) => {
    getClient()
      .getEntries(config)
      .then(({ items }) => {
        const list = items.length ? flattenItems(items) : null;

        resolve(list);
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
