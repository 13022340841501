import React from 'react';
import './style.css';

const TopNav = ({ title = 'Context' }) => {
  return (
    <div
      id="Page-TopNav"
      className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom"
    >
      <h1 id="turbine-page-title">{title}</h1>
    </div>
  );
};

export default TopNav;
