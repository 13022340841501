import { getClient, flattenItem, flattenItems } from '../services/contentful';
import { CLASSES } from '../constants/routes';
import { getCohorts } from './Cohorts';
import {
  SET_CURRENT_CLASS,
  RESET_CURRENT_CLASS,
  SET_CLASS_CERTIFICATE,
  RESET_CLASS_CERTIFICATE
} from './types';

export const setCurrentClass = ({ classId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'class',
      include: 2,
      'sys.id': classId
    };

    return getClient()
      .getEntries(config)
      .then(({ items }) => {
        const course = items[0] !== undefined ? flattenItem(items[0]) : null;
        const cdnFiles = course?.integration?.cdnFiles || null;
        const subjects = course?.subjects || null; // TODO course flatten ( use topicIds )
        const courseTopics = subjects ? flattenItems(subjects) : null; // TODO course flatten ( use topicIds )

        dispatch(getCohorts({ classId }))
          .then(() => {
            dispatch({
              type: SET_CURRENT_CLASS,
              course,
              courseTopics,
              currentClassDashboardRoute: `${CLASSES}/${classId}`,
              cdnFiles
            });
          })
          .catch((error) => {
            console.error(error);
          });
      });
  };
};

export const resetCurrentClass = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS });
  };
};

export const setClassCertificate = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_CLASS_CERTIFICATE,
        classCertificate: data
      });

      resolve(data);
    });
  };
};

export const resetClassCertificate = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CLASS_CERTIFICATE });
  };
};
