import React from 'react';
import Skeleton from 'react-loading-skeleton';

const WysiwygLoading = () => {
  return (
    <div
      className="position-absolute w-100"
      style={{ top: 0, left: 0 }}
    >
      <div className="border rounded">
        <div className="d-flex border-bottom">
          <div className="p-1">
            <Skeleton style={{ height: '35px', width: '50px' }} />
          </div>
          <div className="p-1 ml-1">
            <Skeleton style={{ height: '35px', width: '50px' }} />
          </div>
          <div className="p-1 ml-1">
            <Skeleton style={{ height: '35px', width: '50px' }} />
          </div>
        </div>
        <div className="d-flex border-bottom">
          <div className="p-1 border-right">
            <Skeleton style={{ height: '35px', width: '100px' }} />
          </div>
          <div className="p-1">
            <Skeleton style={{ height: '35px', width: '20px' }} />
          </div>
          <div className="p-1 ml-1">
            <Skeleton style={{ height: '35px', width: '20px' }} />
          </div>
          <div className="p-1 ml-1 border-right">
            <Skeleton style={{ height: '35px', width: '20px' }} />
          </div>
          <div className="p-1">
            <Skeleton style={{ height: '35px', width: '20px' }} />
          </div>
        </div>
        <div className="p-1">
          <Skeleton style={{ height: '102px' }} />
        </div>
      </div>
    </div>
  );
};

export default WysiwygLoading;
