const parseChoices = (choices) => {
  return choices.map((choice) => {
    const { isCorrect, weight, text, feedback } = choice;
    let correct = isCorrect;

    // Multiple Choice multiple right answers
    // Incorrect choices are -100
    // Correct choices are positive numbers, ex: 25, 50, etc.
    if (weight && weight > 0) {
      correct = true;
    }

    return {
      text: text.text,
      ...(!correct && feedback ? { incorrectResponse: feedback } : {}),
      ...(correct && feedback ? { correctResponse: feedback } : {}),
      ...(weight ? { weight } : {}),
      ...(correct ? { correct: true } : {})
    };
  });
};

export default parseChoices;
