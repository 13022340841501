import React from 'react';

const AppsIcon = ({
  src,
  name,
  background = false,
  bkgSize = 40,
  imgSize = 20,
  className = ''
}) => {
  if (background) {
    return (
      <span
        className={`${className} rounded mr-2 text-white d-flex align-items-center justify-content-center`}
        style={{ width: `${bkgSize}px`, height: `${bkgSize}px` }}
      >
        <img
          src={src}
          alt={name}
          height={imgSize}
          style={{
            height: `${imgSize}px`
          }}
        />
      </span>
    );
  }

  return (
    <span
      className={className}
      style={{ width: '30px' }}
    >
      <img
        src={src}
        alt={name}
        // className='w-100'
        height="30"
        style={{
          height: '30px'
        }}
      />
    </span>
  );
};

export default AppsIcon;
