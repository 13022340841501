import React from 'react';
import { useSelector } from 'react-redux';
import OverviewImg from './overview.png';
import WaveImg from './wave.png';

const Overview = ({ onClick, loading }) => {
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const firstName = currentUser?.firstName || '';
  // Local State
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <img
          height="30"
          width="30"
          src={WaveImg}
          style={{
            height: '30px',
            width: '30px'
          }}
          alt="Hello"
        />
        <div className="ml-2">
          <div>Good morning, {firstName}</div>
          <h1 className="h6 m-0 font-weight-bold text-purple-light">
            Turbine does a lot, here's the TLDR;
          </h1>
        </div>
      </div>
      <div className="h4 mt-4 mb-3">
        Everything in Turbine revolves around your private Knowledge Base.
      </div>
      <div>
        Everyone in your business contributes by documenting the processes and
        methods that make your business unique and run efficiently.
      </div>

      <div className="my-4 bg-light rounded px-3 py-4">
        <img
          className="w-100"
          src={OverviewImg}
          alt="Turbine Overview"
        />
      </div>

      <p className="font-weight-bold mb-4">
        Starting from scratch is never easy, so we'll get you started by
        creating the essentials from a few documents you upload.
      </p>

      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          disabled={loading}
          className="btn bg-purple-light btn-md text-white"
          onClick={onClick}
        >
          {loading ? 'Loading...' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default Overview;
