import React, { useMemo } from 'react';
import {
  coerceToResourceName,
  fileType,
  getFileSize
} from '../../../services/resources';

function UploadMultipleFiles({ file, uploadProgress, uploadStatus }) {
  const bgColor = useMemo(() => {
    if (uploadStatus === 'Not started') {
      return '';
    }

    if (uploadProgress > 0 && uploadProgress < 100) {
      return `linear-gradient(to right, #c9d8f0 ${uploadProgress}%, white 50%)`;
    }

    if (uploadProgress === 100) {
      return '#e5f2df';
    }
  }, [uploadStatus, uploadProgress]);

  const opacityStyle = useMemo(() => {
    if (uploadStatus === 'Not started') {
      return '';
    }

    if (uploadProgress <= 0) {
      return '.4';
    }

    return '';
  }, [uploadStatus, uploadProgress]);

  const fileTypeData = useMemo(() => {
    return fileType(file?.type);
  }, [file]);

  const renderFileUploadStatus = () => {
    if (uploadStatus !== 'Not started' && uploadProgress === 100) {
      return <i className="fas fa-check-circle text-success fa-lg mt-2 " />;
    }

    if (uploadProgress > 0) {
      return <b className="text-primary mt-2">{uploadProgress}%</b>;
    }
  };

  return (
    <div
      key={file?.lastModifiedDate}
      className="border rounded-lg p-2 mt-3"
      style={{
        background: bgColor,
        opacity: opacityStyle
      }}
    >
      <div className="row">
        <div className="col-1">
          <i className={`${fileTypeData?.icon} fa-2x mt-1`} />
        </div>
        <div className="col-9 pl-4">
          <small className="row">
            {coerceToResourceName({
              str: file?.name,
              fixCamelCase: true,
              fixHyphens: true,
              fixUnderScores: true,
              fixPlus: true
            })}
          </small>
          <small className="row">
            {getFileSize(file?.size)} | {fileTypeData.name}
          </small>
        </div>
        <div className="col-2 d-flex justify-content-end">
          {renderFileUploadStatus()}
        </div>
      </div>
    </div>
  );
}

export default UploadMultipleFiles;
