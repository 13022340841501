import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useRowSelect } from 'react-table'; // useFilters

function Table({
  columns,
  data,
  theme,
  hiddenColumns = [],
  handleHooks,
  onSelectionChange
  // handleClickRow
}) {
  const initialState = {
    sortBy: [
      {
        id: 'date',
        desc: true
      }
    ],
    hiddenColumns
  };

  // const onClickRow = (row) => {
  //   const { original } = row;
  //   handleClickRow(original);
  // };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { selectedRowIds }
  } = useTable(
    {
      columns,
      initialState,
      data
    },
    useSortBy,
    useRowSelect,
    (hooks) => handleHooks(hooks)
  );

  useEffect(() => {
    let selectedRows = [];

    if (Array.isArray(selectedFlatRows)) {
      selectedRows = selectedFlatRows.map((row) => row.original);
    }

    if (onSelectionChange) {
      onSelectionChange(selectedRows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectionChange, selectedRowIds]);

  return (
    <table
      className={`table ${theme === 'dark' && 'table-dark'} table-striped table-bordered m-0`}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return column.hideHeader ? null : (
                <th
                  className="text-nowrap"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <span className="ml-1">
                      {column.isSorted ? (
                        <>
                          {column.isSortedDesc ? (
                            <i className="fas fa-sort-down" />
                          ) : (
                            <i className="fas fa-sort-up" />
                          )}
                        </>
                      ) : (
                        <i
                          className="fas fa-sort"
                          style={{ opacity: '.25' }}
                        />
                      )}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              // onClick={() => { onClickRow(row); }}
              // className='row-clickable'
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    // eslint-disable-next-line react/no-unknown-property
                    toggleAllRowsSelected={toggleAllRowsSelected}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
