import { sortCaseInsensitive } from '@apprentage/utils';
import { actionFromParentType, fetchResources } from '../services/resources';
import { SET_ENTRY_ORG_ID, SET_RESOURCE_GROUP_FILES } from './types';

export const getResourcesByParentId = (
  parentId,
  parentType = 'resourceGroup' // TODO change to resourceGroups
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!parentId) {
        console.error('Missing parameter, parentId is required!');
        reject(new Error('Missing parameter, parentId is required!'));
      }

      if (!parentType) {
        console.error('Missing parameter, parentType is required!');
        reject(new Error('Missing parameter, parentType is required!'));
      }

      fetchResources({ parentId }).then((response) => {
        const list = Array.isArray(response?.items) ? response.items : null;
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: actionFromParentType(parentType),
          cdnFiles: list
        });

        resolve(list);
      });
    });
  };
};

export const setResourceGroupFiles = (cdnFiles = []) => {
  return (dispatch, getState) => {
    const { resourceGroup } = getState();
    return new Promise((resolve) => {
      const newCdnFiles = [
        ...(resourceGroup?.cdnFiles ? resourceGroup.cdnFiles : []),
        ...cdnFiles
      ];

      const sortedCdnFiles = newCdnFiles.sort((a, b) =>
        sortCaseInsensitive(a, b, 'name')
      );

      dispatch({
        type: SET_RESOURCE_GROUP_FILES,
        cdnFiles: sortedCdnFiles
      });
      resolve(sortedCdnFiles);
    });
  };
};
