import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { withAuthorization } from '../../components/Session';
import { SEARCH_CONTENT } from '../../constants/routes';
import { goToElem } from '../../services/manageContent';
import { ICON_SEARCHES } from '../../constants/assets';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import Tabs from '../../components/SearchContent/Tabs';
import SearchesList from '../../components/lists/SearchesList';
import PaginationSupabase from '../../components/ManageContent/PaginationSupabase';
// import OrgConsoleSubheader from '../OrgConsoleSubheader';

const SearchesUser = () => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const searches = useSelector((state) => state.searches);
  // Organization
  const orgId = organization?.id || null;
  // User
  const userId = currentUser?.id || null;
  // Local State
  const initialListConfig = {
    orgId,
    userId,
    archived: null
  };

  const [listConfig, setListConfig] = useState(initialListConfig);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Search"
            image={ICON_SEARCHES}
            route={SEARCH_CONTENT}
          />

          <Tabs
            active="searchesUser"
            className="mt-4"
          />

          <div className="bg-white border-left border-right border-bottom shadow rounded mb-3 p-3">
            <div className="row">
              <div className="col-12">
                <SearchesList
                  config={listConfig}
                  hideUser
                />

                {Array.isArray(searches.list) && searches.list.length > 0 && (
                  <PaginationSupabase
                    items={searches?.list}
                    pagination={searches?.pagination}
                    page={searches?.pagination?.page}
                    onClickNext={() => {
                      setListConfig((prev) => ({
                        ...prev,
                        page: searches?.pagination?.page + 1
                      }));
                      goToElem('root');
                    }}
                    onClickPrev={() => {
                      setListConfig((prev) => ({
                        ...prev,
                        page: searches?.pagination?.page - 1
                      }));
                      goToElem('root');
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(SearchesUser);
