import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import { FaArrowLeft } from 'react-icons/fa';
import { routeWithOrgId } from '../../services/organizations';

const OrgConsoleSubheader = ({
  className = '',
  pageTitle,
  route,
  rawRoute,
  icon,
  iconComponent,
  locationImage,
  iconStyle = {},
  badge,
  onClick,
  children
}) => {
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  // misc
  const orgId = organization?.id || '';

  const handleClick = () => {
    if (route) {
      history.push(
        routeWithOrgId({
          route,
          orgId
        })
      );
    }

    if (rawRoute) {
      history.push(rawRoute);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {(route || rawRoute || onClick) && (
        <CircleButton
          className="border p-2 bg-light shadow-none mr-3"
          size="sm"
          onClick={handleClick}
        >
          <FaArrowLeft
            size={17}
            className="text-primary"
          />
        </CircleButton>
      )}
      <div
        className="card-header bg-light px-3 border py-2 d-flex align-items-center justify-content-between flex-fill rounded"
        style={{
          minHeight: '40px'
        }}
      >
        {pageTitle && (
          <div className="m-0 d-flex align-items-center h6">
            {icon && (
              <i
                className={`${icon} mr-2`}
                aria-hidden="true"
                style={iconStyle}
              />
            )}

            {iconComponent && iconComponent()}

            {locationImage && (
              <img
                src={locationImage}
                className="mr-2"
                alt="Logo"
                height="30"
                width="30"
                style={{
                  height: '30px',
                  width: '30px'
                }}
              />
            )}

            <div className="d-flex align-items-center font-weight-bold">
              <span className="text-nowrap">{pageTitle}</span>
              {badge && badge()}
            </div>
          </div>
        )}

        {children || null}
      </div>
    </div>
  );
};

export default OrgConsoleSubheader;
