import React from 'react';
import { FaStar } from 'react-icons/fa';

const ProjectsTabs = ({
  className = '',
  listConfig,
  setListConfig,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${!listConfig?.isFeatured && 'active'}`}
            type="button"
            disabled={!listConfig?.isFeatured}
            onClick={() => {
              setListConfig((prev) => ({
                ...prev,
                isFeatured: null,
                page: 1
              }));
            }}
          >
            Published
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${listConfig?.isFeatured && 'active'}`}
            type="button"
            disabled={listConfig?.isFeatured}
            onClick={() => {
              setListConfig((prev) => ({
                ...prev,
                isFeatured: true,
                page: 1
              }));
            }}
          >
            <span className="d-flex align-items-center">
              <FaStar
                className={
                  listConfig?.isFeatured ? 'text-primary' : 'text-muted'
                }
              />
              <span className="ml-2">Featured</span>
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProjectsTabs;
