import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus, FaToolbox } from 'react-icons/fa';
import { compose } from 'redux';
import { FaArrowsUpDownLeftRight } from 'react-icons/fa6';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ORG_CONSOLE_BASE } from '../../../constants/routes';
import { canManageWidgets } from '../../../services/currentUser';
import { setCurrentModal } from '../../../actions/Modals';
import { withAuthorization } from '../../../components/Session';
import { getWidgets } from '../../../actions/Widgets';
import { MODAL_KEY_MANAGE_WIDGET } from '../../../constants/modals';
import { updateOrganization } from '../../../services/organizations';
import { getOrg } from '../../../actions/Organizations';
import withOrgConsole from '../../../components/App/withOrgConsole';
import ItemsDnd from '../../../components/ManageContent/Dnd/Items';
import OrgConsoleHeader from '../../../components/OrgConsole/OrgConsoleHeader';
import Loading from '../../../components/Loading';
import WidgetTabs from '../WidgetTabs';

const WidgetReorder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { widgetType = 'link' } = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const widgetLinks = useSelector((state) => state.widgetLinks);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  // Local State
  const [reorderDashboardIds, setReorderDashboardIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [widgetTab, setWidgetTab] = useState(widgetType);

  const handleCreateNewWidget = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_WIDGET,
        data: {
          filterAll: true
        }
      })
    );
  };

  const widgetsList = useMemo(() => {
    if (Array.isArray(widgetLinks?.list) && widgetLinks?.list.length) {
      return widgetLinks.list.map((widget) => ({
        id: widget?.id,
        title: widget.title,
        ...(widget?.image ? { url: widget.image } : {})
      }));
    }

    return [];
  }, [widgetLinks?.list]);

  const handleCancel = () => {
    history.push(`${ORG_CONSOLE_BASE}/widgets/${widgetTab}`);
  };

  const onSave = () => {
    setLoading(true);
    updateOrganization(
      {
        dashboardWidgetIds:
          reorderDashboardIds.length !== 0 ? reorderDashboardIds : null
      },
      orgId
    )
      .then(() => {
        dispatch(getOrg({ orgId })).then(() => {
          dispatch(
            getWidgets({
              orgId,
              type: widgetTab,
              sortByIds: reorderDashboardIds
            })
          ).then(() => {
            setLoading(false);
            toast.success('Successfully reordered widgets.');
            handleCancel();
          });
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error('Error reordering widgets.');
      });
  };

  const dashboardWidgetIds = useMemo(() => {
    return organization?.dashboardWidgetIds || [];
  }, [organization?.dashboardWidgetIds]);

  useEffect(() => {
    dispatch(
      getWidgets({
        orgId,
        ids: dashboardWidgetIds,
        ...(dashboardWidgetIds ? { sortByIds: dashboardWidgetIds } : {}),
        type: widgetTab
      })
    );
  }, [dashboardWidgetIds, dispatch, orgId, widgetTab]);

  if (!widgetLinks?.list && !widgetLinks?.fetched) {
    return <Loading />;
  }

  if (!widgetLinks?.list && widgetLinks?.fetched) {
    return <div>No tools.</div>;
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <OrgConsoleHeader
          pageTitle="Toolbox"
          iconComponent={() => (
            <FaToolbox
              size={20}
              className="mr-2"
            />
          )}
          route={`${ORG_CONSOLE_BASE}/widgets/${widgetTab}`}
        >
          {canManageWidgets(role) && (
            <button
              className="btn btn-sm btn-primary"
              title="Post Notification"
              onClick={handleCreateNewWidget}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2 font-weight-bold">New</span>
              </span>
            </button>
          )}
        </OrgConsoleHeader>
        <WidgetTabs
          className="mt-3"
          widgetTab={widgetTab}
          setWidgetTab={setWidgetTab}
        />
        <div
          className="p-3 bg-white border-left border-right border-bottom rounded rounded-2"
          style={{ minHeight: '400px' }}
        >
          <div className="card mt-3">
            <div className="card-header">
              <span className="d-flex h6 font-weight-bold align-items-center mb-0">
                <FaArrowsUpDownLeftRight className="mr-3" />
                <span>Reorder</span>
              </span>
            </div>
            <div className="card-body">
              <ItemsDnd
                items={widgetsList || []}
                onUpdate={(itemsNewOrder) => {
                  if (itemsNewOrder?.length) {
                    const ids = itemsNewOrder.map((widget) => widget.id);
                    setReorderDashboardIds(ids);
                  }
                }}
              />
            </div>
            <div className="card-footer">
              <button
                type="button"
                className="btn btn-primary mr-3"
                onClick={onSave}
                disabled={loading || reorderDashboardIds.length === 0}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
              <button
                type="button"
                className="btn btn-link"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(WidgetReorder);
