import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaDownload } from 'react-icons/fa';
import { getCourses } from '../../actions/Courses';
import { getChallenges, resetChallenges } from '../../actions/Challenges';
import {
  canExportUserData,
  fetchUser,
  userOrgIsCourseOrg
} from '../../services/currentUser';
import { setCurrentAnswers, resetCurrentAnswers } from '../../actions/Answers';
import {
  getSyndicatedCourseOrgs,
  resetSyndicatedCourseOrgs
} from '../../actions/Organizations';
import exportData from './exportData';
import AnswersList from '../lists/AnswersList';
import Pagination from '../Pagination';
import StatusSelect from './StatusSelect';
import OrganizationSelect from '../ManageContent/OrganizationSelect';
import Loading from '../Loading';
import OrgConsoleSubheader from '../OrgConsole/OrgConsoleSubheader';
import {
  CLASS_CONSOLE_BASE,
  ORG_CONSOLE_CHALLENGES
} from '../../constants/routes';
import { routeWithClassId } from '../../services/courses';

const Answers = ({ userId, classId, challengeId }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const courseTopics = useSelector((state) => state.courseTopics);
  const currentChallenge = useSelector((state) => state.currentChallenge);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  const syndicatedCourseOrgs = useSelector(
    (state) => state.syndicatedCourseOrgs
  );
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;
  const orgType = organization?.type || null;
  // Course
  const orgIds = course?.orgIds || undefined;
  // Answers List
  const hideCourse = Boolean(classId);
  const hideChallenge = Boolean(challengeId);
  // Pagination
  const pagination = currentAnswers?.pagination || null;
  // Local State
  const [limit] = useState(200); // setLimit
  const [organizationId, setOrganizationId] = useState(orgId);
  const [status, setStatus] = useState(pagination?.status || undefined);
  const [skip, setSkip] = useState(pagination?.skip || 0);
  const [answerUser, setAnswerUser] = useState(null);

  const retrieveAnswerUser = useCallback(() => {
    if (userId) {
      if (!answerUser?.id) {
        fetchUser({
          userId,
          select: [
            'id',
            'email',
            'name',
            'role',
            'title',
            'orgId',
            'profilePicture',
            'membership'
          ]
        }).then((user) => {
          setAnswerUser(user);

          return user;
        });
      }
    } else {
      setAnswerUser(null);
    }
  }, [answerUser?.id, userId]);

  useEffect(() => {
    retrieveAnswerUser();
  }, [retrieveAnswerUser]);

  useEffect(() => {
    if (orgId) {
      dispatch(getCourses({ orgId }));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(
      setCurrentAnswers({
        limit,
        skip,
        ...(status ? { status } : {}),
        ...(classId ? { classId } : {}),
        ...(userId ? { userId } : {}),
        ...(challengeId ? { challengeId } : {}),
        orgId: organizationId
      })
    );
  }, [
    status,
    limit,
    skip,
    organizationId,
    dispatch,
    classId,
    challengeId,
    userId
  ]);

  useEffect(() => {
    if (classId) {
      dispatch(getChallenges({ classId }));
    }
  }, [classId, dispatch]);

  useEffect(() => {
    if (orgIds) {
      dispatch(getSyndicatedCourseOrgs({ orgId, orgIds }));
    }
  }, [dispatch, orgId, orgIds]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetSyndicatedCourseOrgs());
      dispatch(resetChallenges());
      dispatch(resetCurrentAnswers());
    };
  }, [dispatch]);

  const handleClickPage = (page) => {
    const currentPageIndex = parseInt(page, 10);

    if (currentPageIndex !== skip) {
      setSkip(currentPageIndex * limit);
    }
  };

  const goBackUrl = useMemo(() => {
    if (hideChallenge) {
      return routeWithClassId({
        route: `${CLASS_CONSOLE_BASE}/challenge/${challengeId}/submissions`,
        classId
      });
    }

    if (hideCourse) {
      return routeWithClassId({
        route: `${CLASS_CONSOLE_BASE}/challenges/submissions`,
        classId
      });
    }

    return ORG_CONSOLE_CHALLENGES;
  }, [challengeId, classId, hideChallenge, hideCourse]);

  if (!currentUser.id) {
    return <Loading />;
  }

  return (
    <div className="challenges-container">
      {answerUser?.id && (
        <OrgConsoleSubheader
          className="mx-2 my-3 d-flex justify-content-between align-items-center"
          pageTitle={answerUser?.name}
          rawRoute={goBackUrl}
        />
      )}

      <OrgConsoleSubheader className="m-2 d-flex justify-content-between align-items-center">
        {syndicatedCourseOrgs.list &&
          course.id &&
          userOrgIsCourseOrg({ course, currentUser }) && (
            <OrganizationSelect
              className="mr-2 flex-fill"
              defaultValue={organizationId}
              data={[
                { name: orgName, id: orgId },
                ...syndicatedCourseOrgs.list
              ]}
              onChange={({ value }) => {
                setSkip(0);
                setStatus(null);
                setOrganizationId(value);
                // TODO implement similar logic for answers that exists for quizOutcomes
                // or abstract the logic globally for lists, like:
                // setLocationId(null);
                // dispatch(setQuizOutcomesActiveOrgId(selectedOrgId, null));
              }}
            />
          )}

        <StatusSelect
          id="statusSelected"
          name="statusSelected"
          className="flex-fill"
          defaultValue={status}
          onChange={({ statusSelected }) => {
            setSkip(0);
            setStatus(statusSelected);

            // TODO implement similar logic for answers that exists for quizOutcomes
            // or abstract the logic globally for lists, like:
            // setLocationId(null);
            // dispatch(setQuizOutcomesActiveOrgId(selectedOrgId, null));
          }}
        />

        {canExportUserData(role, orgType) && course?.id && (
          <div className="ml-2 d-none d-sm-flex">
            <button
              className="btn btn-sm btn-outline-primary text-nowrap"
              onClick={() => {
                exportData({
                  orgId,
                  currentAnswers,
                  course,
                  courseTopics,
                  currentChallenge
                });
              }}
              title="Export Data"
              type="button"
              disabled={!currentAnswers.list}
            >
              <span className="d-flex align-items-center">
                <FaDownload />
                <span className="ml-2">Export</span>
              </span>
            </button>
          </div>
        )}
      </OrgConsoleSubheader>

      {currentAnswers.list && (
        <AnswersList
          classId={classId}
          hideCourse={hideCourse}
          hideChallenge={hideChallenge}
          hideTopic={!hideCourse}
        />
      )}

      {currentAnswers.list && pagination && (
        <Pagination
          items={currentAnswers.list}
          paginationData={pagination}
          handleClickPage={handleClickPage}
        />
      )}

      {currentAnswers.fetched && !currentAnswers.list && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="my-5">No user submissions.</div>
        </div>
      )}
    </div>
  );
};

export default Answers;
