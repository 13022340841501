import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withAuthorization } from '../../../components/Session';
import { ORG_CONSOLE_PAGE_BASE, SLASH } from '../../../constants/routes';
import { getPage, resetPage } from '../../../actions/Pages';
import { canAccessPages } from '../../../permissions/access';
import { TURBINE_PAGE } from '../../../constants/urls';
import { DASHBOARD_DEFAULT_ZONES } from '../../../constants/dashboard';
import { updateDashboardData } from '../../../services/dashboards';
import { getDashboard } from '../../../actions/Dashboards';
import withOrgConsole from '../../../components/App/withOrgConsole';
import Loading from '../../../components/Loading';
import Phone from '../components/Phone';
import TopNav from '../components/TopNav';
import LeftNav from '../components/LeftNav';
import DraggableWidgetsDashboard from '../../../components/Dashboard/DraggableWidgetsDashboard';
import AssistantBlock from '../components/AssistantBlock';
import '../style.css';

const PageContent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  const dashboard = useSelector((state) => state.dashboard);
  // Organization
  const orgName = organization?.name || null;
  // Page
  const pageId = params?.pageId || null;
  const pageLinkId = useMemo(() => {
    return page?.pageLinkId || null;
  }, [page?.pageLinkId]);
  // Dashboard
  const dashboardId = dashboard?.id || null;
  // Current User
  const role = currentUser?.role || [];
  // Local State
  const turbinePagesNavCollapse = window?.localStorage?.getItem(
    'turbine-pages-nav-collapse'
  );
  const [collapseNav, setCollapseNav] = useState(
    turbinePagesNavCollapse || false
  );

  const qrCodeUrl = useMemo(() => {
    if (pageLinkId && pageLink?.id) {
      return `${TURBINE_PAGE}/link/${pageLinkId}`;
    }

    return null;
  }, [pageLink?.id, pageLinkId]);

  const zones = useMemo(() => {
    if (dashboard?.id) {
      return {
        zone1: dashboard?.zone1 || [],
        zone2: dashboard?.zone2 || [],
        zone3: dashboard?.zone3 || []
      };
    }

    return DASHBOARD_DEFAULT_ZONES;
  }, [dashboard?.id, dashboard?.zone1, dashboard?.zone2, dashboard?.zone3]);

  const refreshPreview = () => {
    const iframe = document.getElementById('page-preview');
    const src = iframe.getAttribute('src');

    iframe.setAttribute('src', '');
    iframe.setAttribute('src', src);
  };

  const onClickSaveDashboard = (zonesData) => {
    updateDashboardData(
      {
        ...zonesData
      },
      dashboard?.id
    ).then(() => {
      dispatch(getDashboard(dashboard?.id));
      toast.success('Changes saved!');
      refreshPreview();
    });
  };

  useEffect(() => {
    if (pageId) {
      dispatch(getPage(pageId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }
  }, [dispatch, pageId]);

  useEffect(() => {
    return function cleanup() {
      const { href } = window.location;
      const PAGE_ROUTE = `${ORG_CONSOLE_PAGE_BASE}/${pageId}`;

      // Only reset if NOT going to Page route
      if (!href.includes(PAGE_ROUTE)) {
        dispatch(resetPage());
      }
    };
  }, [dispatch, pageId]);

  if (!canAccessPages(role)) {
    return <Redirect to={SLASH} />;
  }

  if (pageId && !page?.id) {
    return <Loading text="Loading Page..." />;
  }

  if (dashboardId && !dashboard?.id) {
    return <Loading text="Loading Details..." />;
  }

  return (
    <div
      id="body-row"
      className="row"
    >
      <LeftNav
        id="sidebar-container"
        collapseNav={collapseNav}
        setCollapseNav={setCollapseNav}
      />

      <div
        role="main"
        className="col p-3"
        style={{
          marginLeft: collapseNav ? '50px' : '230px'
        }}
      >
        <TopNav />

        <div className="row mb-4">
          <div className={`col-12 ${collapseNav ? 'col-lg-8' : 'col-xl-8'}`}>
            {dashboard?.id && (
              <DraggableWidgetsDashboard
                defaultZones={zones}
                selectedLayout={dashboard?.activeLayout}
                onClickSave={onClickSaveDashboard}
                moduleTypes={[
                  'EMBED_WIDGETS_LIST',
                  'LINK_WIDGETS_LIST',
                  'MATERIAL',
                  'MATERIALS_LIST'
                ]}
              >
                <div className="row">
                  <AssistantBlock
                    text={`Provide instant answers for visitors, powered by folders in ${orgName}'s Resources.`}
                    className="col-12 col-md-12 w-100 p-1 p-md-3 rounded border border-black border-dashed"
                    onSuccessCallback={refreshPreview}
                  />
                </div>
              </DraggableWidgetsDashboard>
            )}
          </div>

          <div
            className={`d-none col-12 ${collapseNav ? 'col-lg-4 d-lg-block' : 'col-xl-4 d-xl-block'} `}
          >
            <Phone className="mt-3">
              <iframe
                id="page-preview"
                title={page?.name}
                src={qrCodeUrl}
                // src={`${TURBINE_PAGE}/o/${organization?.slug}/page/${pageId}`}
                style={{
                  width: '100%',
                  height: '100%',
                  paddingTop: '24px'
                }}
              />
            </Phone>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  PageContent,
  true
);
