import React from 'react';
import {
  FaBell,
  FaFile,
  FaImages,
  FaLink,
  FaYoutubeSquare
} from 'react-icons/fa';
import { MdTextFields } from 'react-icons/md';
import {
  DASHBOARD_MODULE_TYPE,
  DASHBOARD_MODULES
} from '../../../constants/dashboard';
import {
  IMAGE_MODULE_LINKS,
  IMAGE_MODULE_MATERIALS,
  IMAGE_MODULE_MEDIA,
  IMAGE_MODULE_NOTIFICATIONS,
  IMAGE_MODULE_PROJECTS,
  IMAGE_MODULE_MATERIAL
} from '../../../constants/assets';

export const modulesTypesHash = {
  [DASHBOARD_MODULE_TYPE.material]: {
    title: DASHBOARD_MODULES.MATERIAL,
    body: 'Add visuals, engaging videos, and beautifully formatted text.',
    badgeText: 'Most Popular',
    icon: <MdTextFields />,
    image: IMAGE_MODULE_MATERIAL,
    type: DASHBOARD_MODULE_TYPE.material
  },
  [DASHBOARD_MODULE_TYPE.materialsList]: {
    title: DASHBOARD_MODULES.MATERIALS_LIST,
    body: 'Provide easy access to internal resources and information.',
    icon: <FaFile />,
    image: IMAGE_MODULE_MATERIALS,
    type: DASHBOARD_MODULE_TYPE.materialsList
  },
  [DASHBOARD_MODULE_TYPE.links]: {
    title: DASHBOARD_MODULES.LINK_WIDGETS_LIST,
    body: 'Provide access to external articles, and information.',
    icon: <FaLink />,
    image: IMAGE_MODULE_LINKS,
    type: DASHBOARD_MODULE_TYPE.links
  },
  [DASHBOARD_MODULE_TYPE.notifications]: {
    title: DASHBOARD_MODULES.NOTIFICATIONS,
    body: 'Share announcements to keep visitors in the loop.',
    icon: <FaBell />,
    image: IMAGE_MODULE_NOTIFICATIONS,
    type: DASHBOARD_MODULE_TYPE.notifications
  },
  [DASHBOARD_MODULE_TYPE.projects]: {
    title: DASHBOARD_MODULES.PROJECT,
    body: 'Showcase your successes and demonstrate your expertise.',
    icon: <FaImages />,
    image: IMAGE_MODULE_PROJECTS,
    type: DASHBOARD_MODULE_TYPE.projects
  },
  [DASHBOARD_MODULE_TYPE.embeds]: {
    title: DASHBOARD_MODULES.EMBED_WIDGETS_LIST,
    body: 'Embed videos and dynamic media to boost engagement.',
    icon: <FaYoutubeSquare />,
    image: IMAGE_MODULE_MEDIA,
    type: DASHBOARD_MODULE_TYPE.embeds
  }
};
