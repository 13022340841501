import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { setUser, resetUser, setCurrentUser } from '../../actions/Users';
import { getOrg } from '../../actions/Organizations';
import { setLoading } from '../../actions/Session';
import { getUserLoggedIn, rmUserLoggedIn } from '../../services/organizations';
import heapAnalytics from '../../utils/heapAnalytics';
import onAuthUserListener from '../../utils/onAuthUserListener';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      this.props.setLoading(true);

      this.listener = onAuthUserListener(
        (response) => {
          localStorage.setItem('authUser', JSON.stringify(response.authUser));

          const userId = pathOr(null, ['currentUser', 'id'], response);
          const role = pathOr(null, ['currentUser', 'role'], response);
          const orgId = pathOr(null, ['currentUser', 'orgId'], response);

          this.props.setUser(response.authUser);

          heapAnalytics.identify(userId);

          this.props.getOrg({ orgId }).then((responseOrg) => {
            const orgName = pathOr(null, ['organization', 'name'], responseOrg);

            heapAnalytics.addUserProperties({
              orgId,
              orgName,
              orgRole: role.join(',')
            });
            this.props.setCurrentUser(response.currentUser);
            this.props.setLoading(false);
          });

          const userLoggedIn = getUserLoggedIn();

          if (userLoggedIn) {
            rmUserLoggedIn();
          }
        },
        () => {
          this.props.setLoading(false);
          localStorage.removeItem('authUser');
          localStorage.removeItem('turbine_token');
          this.props.resetUser();
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return compose(
    connect(null, {
      setUser,
      resetUser,
      getOrg,
      setCurrentUser,
      setLoading
    })
  )(WithAuthentication);
};

export default withAuthentication;
