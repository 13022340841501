import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import { ICON_PATHWAY } from '@apprentage/constants';
import { getCourses, resetCourses } from '../../../actions/Courses';
import { getJobProfiles } from '../../../actions/JobProfiles';
import { getPathway, resetPathway } from '../../../actions/Pathways';
import {
  CLASS_CONSOLE_PUBLISH,
  CLASS_CONSOLE_SETTINGS,
  ORG_CONSOLE_PATHWAYS
} from '../../../constants/routes';
import { publicCourseUrl, routeWithClassId } from '../../../services/courses';
import { withAuthorization } from '../../Session';
import isPublished from '../../../utils/isPublished';
import withOrgConsole from '../../App/withOrgConsole';
import Loading from '../../Loading';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import OrgConsoleHeader from '../OrgConsoleHeader';
import NavTabs from './NavTabs';
import DotMenu from './DotMenu';

const PathwayContainer = styled.div`
  background-image: url(https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png);
  background-repeat: repeat;
  background-color: lightgray;
`;

const Pathway = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  let whichCourses = null;
  const orgId = pathOr(null, ['id'], organization);
  const orgSlug = pathOr(null, ['slug'], organization);
  const pathwayId = pathOr(null, ['pathwayId'], params);
  const pathwayCourseIds = pathOr(null, ['courseIds'], pathway);

  useEffect(() => {
    const href = pathOr('', ['location', 'href'], window);
    const pathwayRoute = `org/pathways/${pathwayId}`;

    if (pathwayId) {
      dispatch(getPathway(pathwayId));
    }

    dispatch(getCourses({ orgId }));
    dispatch(getJobProfiles({ orgId }));

    return function cleanup() {
      dispatch(resetCourses());
      // Only reset pathway if not going to other pathway management pages
      if (!href.includes(pathwayRoute)) {
        dispatch(resetPathway());
      }
    };
  }, [pathwayId]);

  if (courses.list) {
    whichCourses = courses.list.filter((c) => isPublished(c));
    // Exclude test courses if they exist and the user is not a test user
    whichCourses = whichCourses.filter((c) => {
      if (c.testClass && !currentUser.testUser) return c;

      return c;
    });
  }

  if (!pathway.id || !whichCourses) {
    return <Loading />;
  }

  const pathwayCourses = pathwayCourseIds
    ? pathwayCourseIds.map((courseId) =>
        whichCourses.find((course) => course.id === courseId)
      )
    : null;

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={pathway.title}
            icon={ICON_PATHWAY}
            route={ORG_CONSOLE_PATHWAYS}
          >
            <DotMenu />
          </OrgConsoleHeader>

          <NavTabs activeTab="pathway" />

          <div className="bg-white border-bottom border-left border-right p-3">
            {/* <p>
              Course Pages are inaccessible if they are not made public or set to invite only.
            </p> */}

            <PathwayContainer>
              {pathwayCourses && (
                <ul className="timeline">
                  {pathwayCourses.map((pathwayCourse, i) => {
                    if (!pathwayCourse) return null;

                    const isPrivate =
                      !pathwayCourse.isPublic || pathwayCourse.inviteOnly;

                    const coursePageUrl = publicCourseUrl({
                      embed: false,
                      courseSlug: pathwayCourse.courseSlug,
                      classId: pathwayCourse.id,
                      orgSlug
                    });

                    return (
                      <li
                        key={`pathwayCourse-${i}`}
                        className="timeline-inverted"
                      >
                        <div className="timeline-badge bg-white">{i + 1}</div>
                        <div className="timeline-panel">
                          <div className="timeline-heading">
                            <h5 className="timeline-title font-weight-bold mb-2">
                              {pathwayCourse.title}
                            </h5>
                          </div>
                          <div>
                            <RenderMarkdown
                              source={pathwayCourse.description}
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <a
                              className={`btn btn-sm btn-primary ${isPrivate ? 'disabled' : ''}`}
                              href={isPrivate ? '#private' : coursePageUrl}
                              target="_blank"
                              rel="noreferrer noopener"
                              title="Learn more"
                              disabled={isPrivate}
                            >
                              Learn more
                            </a>
                            {isPrivate && (
                              <div
                                type="button"
                                className="btn btn-md btn-link my-2 btn-lg"
                                disabled
                              >
                                <i
                                  className="fas fa-lock"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>

                          {isPrivate && (
                            <div className="d-flex justify-content-end ">
                              {!pathwayCourse.isPublic && (
                                <div className="d-flex bg-light p-1 border">
                                  <div className="border-right px-1 font-weight-bold">
                                    Private
                                  </div>
                                  <Link
                                    className="btn-link px-2"
                                    to={routeWithClassId({
                                      route: CLASS_CONSOLE_PUBLISH,
                                      classId: pathwayCourse.id
                                    })}
                                  >
                                    Change
                                  </Link>
                                </div>
                              )}

                              {pathwayCourse.inviteOnly && (
                                <div className="d-flex bg-light p-1 border ml-1">
                                  <div className="border-right px-1 font-weight-bold">
                                    Invite Only
                                  </div>
                                  <Link
                                    className="btn-link px-2"
                                    to={routeWithClassId({
                                      route: CLASS_CONSOLE_SETTINGS,
                                      classId: pathwayCourse.id
                                    })}
                                  >
                                    Change
                                  </Link>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
                  {/* <li className="timeline-inverted">
                    <div className="timeline-badge bg-white">
                      {pathwayCourses.length + 1}
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Web Developer certificate
                        </h4>
                      </div>
                    </div>
                  </li> */}
                </ul>
              )}
            </PathwayContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Pathway);
