import { toast } from 'react-toastify';
import { updateEntry } from '../services/entry';
import formatData from '../services/formatData';
import { fetchLocation, fetchLocations } from '../services/locations';
import { getEmployerProfile } from './Employers';
import {
  SET_LOCATIONS,
  SET_LOCATION,
  RESET_LOCATION,
  SET_ENTRY_ORG_ID
} from './types';

export const getLocations = ({
  orgId,
  locationIds,
  include = 1,
  excludeDefaultLocation = false
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocations({
        orgId,
        locationIds,
        include,
        excludeDefaultLocation
      })
        .then((list) => {
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({ type: SET_LOCATIONS, list });
          resolve(list);
        })
        .catch((error) => {
          console.error(error.message);
          reject(error);
        });
    });
  };
};

export const getLocation = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocation({ locationId })
        .then((orgLocation) => {
          const cdnFiles = orgLocation?.integration?.cdnFiles || null;
          const employerProfileId = orgLocation?.employerProfileId || null;

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: orgLocation?.orgId || null
          });

          if (employerProfileId) {
            dispatch(getEmployerProfile(employerProfileId)).then(() => {
              dispatch({
                type: SET_LOCATION,
                orgLocation,
                cdnFiles
              });

              resolve({ orgLocation, cdnFiles });
            });
          } else {
            dispatch({
              type: SET_LOCATION,
              orgLocation,
              cdnFiles
            });

            resolve({ orgLocation, cdnFiles });
          }
        })
        .catch((error) => {
          toast.error(error.message);
          reject(error);
        });
    });
  };
};

export const updateLocation = (data, locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const preppedData = formatData(data, 'location');

      updateEntry(preppedData, locationId)
        .then(() => {
          setTimeout(() => {
            dispatch(getLocation(locationId)).then((updatedLocation) => {
              resolve(updatedLocation);
            });
          }, 250);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetLocation = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOCATION });
  };
};
