import { utils, writeFile } from 'xlsx';
import { answerEnum } from '../../constants/answers';
import { mdySimple } from '../../utils/date';
import sortArrByKeys from '../../utils/sortArrByKeys';
import { TURBINE, TURBINE_ADMIN } from '../../constants/urls';
import { fetchChallenges } from '../../services/challenges';

const generateFileName = ({ course, currentChallenge }) => {
  const todayDate = new Date();
  let name = 'Turbine--submission-data';

  if (course.id) {
    name += `--${course.title}`;
  }

  if (currentChallenge?.id) {
    name += `--${currentChallenge?.title}`;
  }

  name += `--${mdySimple(todayDate, '-')}`;

  name = name.replace(/\s/g, '-');

  return name;
};

/* This function creates gap rows */
function createGapRows(ws, nRows) {
  const ref = utils.decode_range(ws['!ref']); // get original range
  ref.e.r += nRows; // add to ending row
  ws['!ref'] = utils.encode_range(ref); // reassign row
}

const exportData = ({
  orgId,
  currentAnswers,
  course,
  courseTopics,
  currentChallenge
}) => {
  const wb = utils.book_new();
  const challengeLinks = {};
  const topicsHash = {};

  if (Array.isArray(courseTopics?.list) && courseTopics?.list.length > 0) {
    courseTopics?.list.forEach((t) => {
      topicsHash[t?.id] = t;
    });
  }

  fetchChallenges({
    orgId,
    classId: course?.id
  }).then((challenges) => {
    challenges.map((challenge) => {
      // Make Topic Link
      // Admin Link
      // const href = `${TURBINE_ADMIN}/classes/challenge/${challenge?.id}/submissions?classId=${course?.id}`;
      // LMS Link
      const href = `${TURBINE}/classes/${course?.id}/${challenge?.topicId}/${challenge?.id}`;
      // const hyperlink = `=HYPERLINK(${link}, ${challenge?.title})`;
      challengeLinks[challenge?.id] = { href, text: challenge?.title };
      return challenge;
    });

    const sortedData = sortArrByKeys(
      currentAnswers.list,
      'challengeTitle',
      'createdAt'
    );

    const data = sortedData.map((answer) => {
      const dataToExport = [
        mdySimple(answer.createdAt), // Submission Date
        answer.userName, // User
        answerEnum[answer.status] ? answerEnum[answer.status] : 'n/a' // Status
      ];

      if (answer?.challengeTitle) {
        dataToExport.push(answer.challengeTitle);
      } else {
        dataToExport.push('n/a');
      }

      if (answer?.topicId) {
        dataToExport.push(topicsHash[answer?.topicId]?.title);
      } else {
        dataToExport.push('n/a');
      }

      dataToExport.push('Review Submission');

      const feedback = answer?.feedback?.list || [];
      const feedbackList = !feedback.length
        ? 'n/a'
        : feedback.map((item) => {
            return `${item.body} - ${item.userName || ''} (${mdySimple(
              item.date
            )})`;
          });

      if (feedback.length > 0) {
        dataToExport.push(feedbackList.join('\n- - - - -\n'));
      }

      return dataToExport;
    });

    const metadata = [
      [
        'Submission Date',
        'User',
        'Status',
        'Challenge (link)',
        'Topic',
        'Review Submission (link)',
        'Feedback'
      ],
      ...data
    ];

    /**
     * User Meta Data
     * User Answers
     */
    // https://docs.sheetjs.com/docs/api/utilities/#array-of-arrays-input
    const wsUser = utils.aoa_to_sheet(metadata);

    // Calculate Widths
    const objectMaxLength = [];

    data.forEach((arr) => {
      Object.keys(arr).forEach((key) => {
        const value = arr[key] === null ? '' : arr[key];

        if (typeof value === 'number') {
          objectMaxLength[key] = 10;
        }

        objectMaxLength[key] =
          objectMaxLength[key] >= value.length
            ? objectMaxLength[key]
            : value.length;
      });
    });

    const worksheetCols = objectMaxLength.map((width) => {
      return {
        width: width < 20 ? 20 : width
      };
    });

    wsUser['!cols'] = worksheetCols;
    // /Calculate Widths

    createGapRows(wsUser, 2);
    utils.sheet_add_aoa(wsUser, [[`Total Submissions: ${data.length || 0}`]], {
      origin: -1
    });
    utils.sheet_add_aoa(
      wsUser,
      [[`Course: ${course?.id ? course?.title : 'n/a'}`]],
      { origin: -1 }
    );
    utils.sheet_add_aoa(wsUser, [[`Exported: ${mdySimple(new Date(), '/')}`]], {
      origin: -1
    });
    utils.sheet_add_aoa(wsUser, [['Powered by Turbine Workforce']], {
      origin: -1
    });

    // wsUser['!cols'] = [
    //   { width: 20 },
    //   { width: 20 },
    //   { width: 15 },
    //   { width: 30 },
    //   { width: 60 }
    // ];

    // Challenge Links
    sortedData.forEach((answer, i) => {
      wsUser[`D${i + 2}`].l = {
        Target: challengeLinks[answer?.challengeId]?.href,
        Tooltip: 'Click to View'
      };

      wsUser[`F${i + 2}`].l = {
        Target: `${TURBINE_ADMIN}/assessments/${answer?.id}/${answer?.classId}/${answer?.topicId}/${answer?.userId}/?redirectUrl=/classes/challenges/submissions`,
        Tooltip: 'View Answer'
      };
    });

    utils.book_append_sheet(wb, wsUser, 'Challenges Submissions');

    const fileName = generateFileName({ course, currentChallenge });

    writeFile(wb, `${fileName}.xlsx`);
  });
};

export default exportData;
