import React from 'react';
import './style.css';

const Phone = ({ children, className = '' }) => {
  return (
    <div className={`Phone-container ${className}`}>
      <div className="Phone">
        <div className="iphonex">
          <div className="front">
            <div className="screen">
              <div
                className="screen__view"
                style={{
                  paddingTop: '24px',
                  background: '#f8f9fa'
                }}
              >
                {children || null}
              </div>
              <div className="screen__front">
                <div className="screen__front-speaker" />
                <div className="screen__front-camera" />
              </div>
            </div>
            <div className="front__line" />
            <div className="front__line front__line-second" />
          </div>
          <div className="phoneButtons phoneButtons-right" />
          <div className="phoneButtons phoneButtons-left" />
          <div className="phoneButtons phoneButtons-left2" />
          <div className="phoneButtons phoneButtons-left3" />
          <div className="back" />
        </div>
      </div>
    </div>
  );
};

export default Phone;
