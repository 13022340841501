import { pathOr } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZZES } from '../../constants/routes';
import { routeWithClassId } from '../../services/courses';

const OutcomeCell = ({ value: quizOutcomeId, row }) => {
  // misc
  const quizOutcome = pathOr(null, ['original'], row);
  const quizId = pathOr(null, ['quizId'], quizOutcome);
  const classId = pathOr(null, ['classId'], quizOutcome);

  return (
    <div>
      <Link
        className="btn btn-outline-primary btn-sm"
        to={routeWithClassId({
          route: `${CLASS_CONSOLE_QUIZZES}/${quizId}/outcome/${quizOutcomeId}`,
          urlParams: {
            classId,
            redirectUrl: window.location.pathname
          }
        })}
      >
        Outcome
      </Link>
    </div>
  );
};

export default OutcomeCell;
