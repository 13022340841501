import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { fetchLogbookHours } from '../../../../services/logbooks';
import { fetchUsers } from '../../../../services/users';
import { fetchApprenticeships } from '../../../../services/apprenticeships';
import uniqueStringArray from '../../../../utils/uniqueStringArray';
import NumberStat from '../../../NumberStat';

const Vitals = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const dictionary = useSelector((state) => state.dictionary);
  //  Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const [totalLogbookHours, setLogbookHours] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalApprenticeships, setTotalApprenticeships] = useState(0);
  const [totalEmployers, setTotalEmployers] = useState(0);

  const formatWithK = useCallback((num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toString();
  }, []);

  useEffect(() => {
    fetchApprenticeships({
      orgId,
      count: true
    }).then((response) => {
      if (response?.total) {
        setTotalApprenticeships(response?.total);
      }
    });
  }, [orgId, role]);

  useEffect(() => {
    fetchLogbookHours({
      orgId,
      showCompHours: true
    }).then((response) => {
      if (response?.totalHours) {
        setLogbookHours(response?.totalHours);
      }
    });
  }, [orgId, role]);

  useEffect(() => {
    const config = {
      orgId,
      excludeRoles: ['superAdmin', 'admin', 'teacher'],
      isApprentice: true,
      excludeInactive: true,
      excludeTestUsers: true,
      select: ['id', 'locationId']
      // count: true
    };

    fetchUsers(config).then((response) => {
      if (response?.total) {
        const locationIds = response.items.map((i) => i.locationId);

        const uniqueLocations = uniqueStringArray(locationIds);

        setTotalEmployers(uniqueLocations.length);
        setTotalUsers(response?.total);
      }
    });
  }, [dispatch, orgId, role, userId]);

  return (
    <div className={`row ${className}`}>
      <div className="col-6 col-md-3 col-lg-3 mb-3">
        <NumberStat
          size="md"
          className="bg-white rounded p-3 shadow"
          num={totalUsers}
          numTitle={pluralize(dictionary?.user, totalUsers)}
        />
      </div>

      <div className="col-6 col-md-3 col-lg-3 mb-3">
        <NumberStat
          size="md"
          className="bg-white rounded p-3 shadow"
          num={totalApprenticeships}
          numTitle={pluralize('Training Program', totalApprenticeships)}
        />
      </div>

      <div className="col-6 col-md-3 col-lg-3 mb-3">
        <NumberStat
          size="md"
          className="bg-white rounded p-3 shadow"
          num={totalEmployers}
          numTitle={pluralize('Employers', totalEmployers)}
        />
      </div>

      <div className="col-6 col-md-3 col-lg-3 mb-3">
        <NumberStat
          size="md"
          className="bg-white rounded p-3 shadow"
          num={formatWithK(totalLogbookHours)}
          numTitle={pluralize('Logbook Hours', totalLogbookHours)}
        />
      </div>
    </div>
  );
};

export default Vitals;
