import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchLogbookHours } from '../../../../../services/logbooks';
import { daysAgo, MMMDate } from '../../../../../utils/date';
import NumberStat from '../../../../NumberStat';

const OjtTracking = () => {
  const { apprenticeshipId = null } = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization.id || '';
  // Local State
  const [ojtData, setOjtData] = useState({
    totalOjtHours: 0,
    totalOjtTasks: 0,
    changeInTotalHours: 0,
    changeInTotalTasks: 0
  });

  const retrieveLogbookHours = useCallback(async () => {
    try {
      const res = await fetchLogbookHours({
        orgId, // required
        apprenticeshipId,
        showCompHours: true
      });
      setOjtData((prevData) => ({
        ...prevData,
        totalOjtHours: res.totalHours || 0,
        totalOjtTasks: res.totalRecords || 0
      }));
    } catch (error) {
      console.error(error);
    }
  }, [apprenticeshipId, orgId]);

  const calcPercentDifference = useCallback(async () => {
    try {
      const currentMonth = await fetchLogbookHours({
        orgId, // required
        apprenticeshipId,
        gteDate: daysAgo(30).toISOString(),
        showCompHours: true
      });
      const prevMonth = await fetchLogbookHours({
        orgId, // required
        apprenticeshipId,
        gteDate: daysAgo(60).toISOString(),
        lteDate: daysAgo(30).toISOString(),
        showCompHours: true
      });

      const percentChangeHours =
        prevMonth?.totalHours > 0
          ? Math.floor(
              ((currentMonth.totalHours - prevMonth.totalHours) /
                prevMonth.totalHours) *
                100
            )
          : 0;
      const percentChangeTasks =
        prevMonth?.totalRecords > 0
          ? Math.floor(
              ((currentMonth.totalRecords - prevMonth.totalRecords) /
                prevMonth.totalRecords) *
                100
            )
          : 0;

      setOjtData((prevData) => ({
        ...prevData,
        changeInTotalHours: percentChangeHours,
        changeInTotalTasks: percentChangeTasks
      }));
    } catch (error) {
      console.error(error);
    }
  }, [apprenticeshipId, orgId]);

  useEffect(() => {
    retrieveLogbookHours();
    calcPercentDifference();
  }, [retrieveLogbookHours, calcPercentDifference]);

  const prevMonth = useMemo(() => {
    const x = new Date();
    // x.setDate(0); // 0 will result in the last day of the previous month
    x.setDate(1); // 1 will result in the first day of the month

    return MMMDate(x.toISOString()).toUpperCase();
  }, []);

  return (
    <div className="shadow p-4 pt-2 rounded rounded-2 h-100 border border-1">
      <div className="h4 mb-3 mb-md-4 mb-lg-5">OJT Tracking</div>
      <div className="d-flex flex-row justify-content-between align-content-center">
        <NumberStat
          num={ojtData.totalOjtTasks}
          numTitle="OJT Tasks"
          size="lg"
          moreDetail={
            ojtData.changeInTotalTasks > 0
              ? `+${ojtData.changeInTotalTasks}% since ${prevMonth}`
              : `${ojtData.changeInTotalTasks}% since ${prevMonth}`
          }
        />
        <NumberStat
          num={ojtData.totalOjtHours}
          numTitle="OJT Hours"
          size="lg"
          moreDetail={
            ojtData.changeInTotalHours > 0
              ? `+${ojtData.changeInTotalHours}% since ${prevMonth}`
              : `${ojtData.changeInTotalHours}% since ${prevMonth}`
          }
        />
      </div>
    </div>
  );
};

export default OjtTracking;
