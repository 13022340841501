import React from 'react';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_CHALLENGE_BASE } from '../../../../constants/routes';
import { routeWithClassId } from '../../../../services/courses';

const SubmissionsCell = ({ row }) => {
  const {
    original: { id: challengeId, classId }
  } = row;

  return (
    <Link
      title="Submissions"
      className="btn btn-sm btn-outline-primary text-nowrap"
      to={routeWithClassId({
        route: `${CLASS_CONSOLE_CHALLENGE_BASE}/${challengeId}/submissions/course`,
        classId
      })}
    >
      Submissions
    </Link>
  );
};

export default SubmissionsCell;
