import React from 'react';
import shortenString from '../../../../utils/shortenString';
import htmlToPlainText from '../../../../utils/htmlToPlainText';
import RenderMarkdown from '../../RenderMarkdown';
import './body.css';

const BodyCell = ({ value, row }) => {
  let body = value || '';

  if (body && row?.original?.type === 'material') {
    body = htmlToPlainText(body);
  }

  if (body.length > 140) {
    body = shortenString(body || '', 140);
  }

  return (
    <div className="CdnFiles-BodyCell">
      <RenderMarkdown
        source={body || ''}
        enableNewEditor
      />
    </div>
  );
};

export default BodyCell;
