import { referenceType } from '../contentful';

function formatClass({
  enableCourseContent,
  externalCourseUrl,
  isPublic,
  searchEnginesEnabled,
  orgId,
  title,
  overview, // long description
  schedule,
  subjects, // Topics reference // TODO course flatten
  topicIds,
  description, // short description
  topics, // courseTags
  prerequisiteId, // prerequisite
  integration,
  locked,
  openEnrollment,
  inviteOnly,
  courseReadOnly,
  trackAttendance,

  // EDUCATION
  eUnitTracking,
  eUnits,
  //

  // CONTINUING INTEGRATION
  classCatalogIds,
  classCatalogIdsInfo,
  // CONTINUING INTEGRATION

  // stripeSku, // deprecated
  // stripeSkuTest, // deprecated
  // duration,
  slackChannel,
  streamingLink,
  isLive,
  showStreamLink,
  classColor,
  classImage,
  teacherIds,
  courseVideo,
  orgIds,
  locationIds,
  skillIds,
  userId,
  userIds
}) {
  const data = {};

  data.enableNewEditor = {
    'en-US': true
  };

  if (enableCourseContent !== undefined) {
    data.enableCourseContent = {
      'en-US': enableCourseContent
    };
  }

  if (externalCourseUrl !== undefined) {
    data.externalCourseUrl = {
      'en-US': externalCourseUrl
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (overview !== undefined) {
    data.overview = {
      'en-US': overview
    };
  }

  if (schedule !== undefined) {
    data.schedule = {
      'en-US': schedule // string
    };
  }

  if (eUnits !== undefined && eUnits.length) {
    data.eUnits = {
      'en-US': eUnits.map(({ sys }) => referenceType(sys.id))
    };
  }

  if (eUnitTracking !== undefined && eUnitTracking.sys) {
    data.eUnitTracking = {
      'en-US': referenceType(eUnitTracking.sys.id)
    };
  }

  // TODO course flatten - remove subjects
  if (subjects !== undefined) {
    data.subjects = {
      'en-US':
        subjects === null
          ? undefined
          : subjects.map(({ sys }) => referenceType(sys.id))
    };
  }

  /**
   * {array of strings || undefined || null } topicIds
   * FE will send null which is then converted to undefined
   */
  if (topicIds !== undefined) {
    data.topicIds = {
      'en-US': topicIds === null ? undefined : topicIds
    };
  }

  if (teacherIds !== undefined) {
    data.teacherIds = {
      'en-US': teacherIds === null ? undefined : teacherIds
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (topics !== undefined) {
    // TODO more descriptive key would be courseTags
    data.topics = {
      'en-US': topics // string[]
    };
  }

  if (prerequisiteId !== undefined) {
    data.prerequisite = {
      'en-US':
        prerequisiteId === null ? undefined : referenceType(prerequisiteId) // TODO course flatten
    };
  }

  if (integration !== undefined) {
    data.integration = {
      'en-US': integration
    };
  }

  if (locked !== undefined) {
    data.locked = {
      'en-US': locked // boolean
    };
  }

  if (isPublic !== undefined) {
    data.isPublic = {
      'en-US': isPublic // boolean
    };
  }

  if (searchEnginesEnabled !== undefined) {
    data.searchEnginesEnabled = {
      'en-US': searchEnginesEnabled // boolean
    };
  }

  if (openEnrollment !== undefined) {
    data.openEnrollment = {
      'en-US': openEnrollment // boolean
    };
  }

  if (inviteOnly !== undefined) {
    data.inviteOnly = {
      'en-US': inviteOnly // boolean
    };
  }

  if (courseReadOnly !== undefined) {
    data.courseReadOnly = {
      'en-US': courseReadOnly // boolean
    };
  }

  if (trackAttendance !== undefined) {
    data.trackAttendance = {
      'en-US': trackAttendance // boolean
    };
  }

  // CONTINUING EDUCATION

  if (classCatalogIds !== undefined) {
    data.classCatalogIds = {
      'en-US': classCatalogIds // array
    };
  }

  if (classCatalogIdsInfo !== undefined) {
    data.classCatalogIdsInfo = {
      'en-US': classCatalogIdsInfo // string
    };
  }

  if (classColor !== undefined) {
    data.classColor = {
      'en-US': classColor // string
    };
  }

  if (classImage !== undefined) {
    data.classImage = {
      'en-US': classImage // string
    };
  }

  // /CONTINUING EDUCATION
  if (slackChannel !== undefined) {
    data.slackChannel = {
      'en-US': slackChannel
    };
  }

  if (streamingLink !== undefined) {
    data.streamingLink = {
      'en-US': streamingLink
    };
  }

  if (isLive !== undefined) {
    data.isLive = {
      'en-US': isLive // boolean
    };
  }

  if (showStreamLink !== undefined) {
    data.showStreamLink = {
      'en-US': showStreamLink // boolean
    };
  }

  if (courseVideo !== undefined) {
    data.courseVideo = {
      'en-US': courseVideo // boolean
    };
  }

  if (orgIds !== undefined) {
    data.orgIds = {
      'en-US': orgIds === null ? undefined : orgIds
    };
  }

  if (skillIds !== undefined) {
    data.skillIds = {
      'en-US': skillIds === null ? undefined : skillIds
    };
  }

  if (locationIds !== undefined) {
    data.locationIds = {
      'en-US': locationIds === null ? undefined : locationIds
    };
  }

  if (userId !== undefined) {
    data.userId = {
      'en-US': userId
    };
  }

  if (userIds !== undefined) {
    data.userIds = {
      'en-US': userIds === null ? undefined : userIds
    };
  }

  return { fields: { ...data } };
}

export default formatClass;
