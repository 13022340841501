import React, { useState } from 'react';
import { compose } from 'redux';
import { withAuthorization } from '../../../components/Session';
import LeftNav from '../components/LeftNav';
import TopNav from '../components/TopNav';
import withOrgConsole from '../../../components/App/withOrgConsole';
import Vitals from './Vitals';

const ContextSearch = () => {
  // Local State
  const turbinePagesNavCollapse = window?.localStorage?.getItem(
    'turbine-pages-nav-collapse'
  );
  const [collapseNav, setCollapseNav] = useState(
    turbinePagesNavCollapse || false
  );

  return (
    <div
      id="body-row"
      className="row"
    >
      <LeftNav
        id="sidebar-container"
        collapseNav={collapseNav}
        setCollapseNav={setCollapseNav}
      />

      <div
        role="main"
        className="col p-3"
        style={{
          marginLeft: collapseNav ? '50px' : '230px'
        }}
      >
        <TopNav />

        <div className="row mb-4">
          <div
            // className={`col-12 ${collapseNav ? 'col-lg-8' : 'col-xl-8'}`}
            className="col-9"
          >
            <Vitals size="md" />
          </div>
          <div
            // className={`col-12 ${collapseNav ? 'col-lg-8' : 'col-xl-8'}`}
            className="col-3"
          >
            <div className="card">
              <div className="card-header">Test</div>
              <div className="card-body p-0">blah</div>
            </div>
          </div>
          {/* <div className={`d-none col-12 ${collapseNav ? 'col-lg-4 d-lg-block' : 'col-xl-4 d-xl-block'} `}>
            <h6>Insights</h6>
            <p>
              Something
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  ContextSearch,
  true
);
