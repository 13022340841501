import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  GLOBAL_PLATFORM_NAME,
  INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE
} from '@apprentage/constants';
import styled from 'styled-components';
import Collapse from '@apprentage/components/dist/components/Collapse';
import {
  FaCheckCircle,
  FaChevronRight,
  FaExclamationTriangle
} from 'react-icons/fa';
import { setCurrentModal } from '../../../../../actions/Modals';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import { TURBINE } from '../../../../../constants/urls';
import { canManageProgramUsers } from '../../../../../permissions/manage';
import {
  canAccessUserDocs,
  canAccessUserWageSchedule
} from '../../../../../permissions/access';
import {
  MODAL_KEY_USER_APPRENTICESHIP_SETTINGS,
  MODAL_KEY_USER_INVITATION
} from '../../../../../constants/modals';

const ChecklistRow = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  div {
    align-items: center;
    display: flex;

    i {
      font-size: 1.5rem;
    }
  }

  div > span {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
`;

const UserChecklist = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  const dictionary = useSelector((state) => state.dictionary);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Training Program
  const apprenticeshipId = params?.apprenticeshipId || '';
  const apprenticeshipUserId = params?.apprenticeshipUserId || '';
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserWageSchedule =
    apprenticeship?.userWageSchedule || null;
  const apprenticeshipUserInstructionProvider =
    apprenticeship?.userInstructionProvider || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  const messageUser = () => {
    dispatch(
      setCurrentModal({
        key: 'sendEmail',
        data: {
          name: apprenticeshipUser?.name,
          email: apprenticeshipUser?.email,
          message: `Friendly reminder to fill out required personal information in ${GLOBAL_PLATFORM_NAME} for your participation in the ${apprenticeship.title} Training Program.

Use this link to enter the required information: ${TURBINE}
        `,
          fromName: currentUser?.name,
          replyTo: currentUser?.email,
          subject: 'Reminder to fill out required personal information',
          hideSubject: true,
          disableSubject: true
        }
      })
    );
  };

  const inviteToSignApprenticeAgreement = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_USER_INVITATION,
        data: {
          modalTitle: 'New Request',
          locationId: apprenticeshipUser?.locationId,
          employerAgreementId: employerAgreement?.id,
          apprenticeshipId,
          singleUserInvite: true,
          includeName: true,
          firstName: apprenticeshipUser?.firstName,
          lastName: apprenticeshipUser?.lastName,
          email: apprenticeshipUser?.email,
          inviteType: INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE,
          inviteTypeTitle: 'Trainee Agreement : Request Signature'
        }
      })
    );
  };

  const canRequestApprenticeSignature = useMemo(() => {
    if (!canManageProgramUsers(role)) {
      return false;
    }

    if (!employerAgreement?.id) {
      return false;
    }

    if (!apprenticeshipUserWageSchedule?.id) {
      return false;
    }

    if (!apprenticeshipUserInstructionProvider?.id) {
      return false;
    }

    return true;
  }, [
    role,
    employerAgreement?.id,
    apprenticeshipUserWageSchedule?.id,
    apprenticeshipUserInstructionProvider?.id
  ]);

  const reasonCantRequestApprenticeSignature = useMemo(() => {
    if (!apprenticeshipUser.userProfileId) {
      return 'Personal Info. Needed';
    }

    if (!apprenticeshipUserWageSchedule?.id) {
      return 'Wage Schedule Needed';
    }

    if (!apprenticeshipUserInstructionProvider?.id) {
      return 'Instruction Provider Needed';
    }

    if (!employerAgreement?.id) {
      return 'Employer Agreement Needed';
    }

    return '';
  }, [
    apprenticeshipUser?.userProfileId,
    employerAgreement?.id,
    apprenticeshipUserWageSchedule?.id,
    apprenticeshipUserInstructionProvider?.id
  ]);

  return (
    <Collapse
      title={`${dictionary.user} Checklist`}
      id="traineeChecklist"
      className="mt-3"
      ariaExpanded={
        !apprenticeAgreement?.id && !apprenticeAgreement?.signerSignature
      }
      badge={() => {
        return (
          <span className="h6 mb-0 ml-2 py-2">
            {apprenticeAgreement?.id && apprenticeAgreement?.signerSignature ? (
              <span className="badge bg-keppel text-white">
                <span className="d-flex align-items-center">
                  <FaCheckCircle className="mr-1" />
                  <span>Complete</span>
                </span>
              </span>
            ) : (
              <span className="badge bg-warning">
                <span className="d-flex align-items-center">
                  <FaExclamationTriangle className="mr-1" />
                  <span>Incomplete</span>
                </span>
              </span>
            )}
          </span>
        );
      }}
      p={3}
    >
      <ChecklistRow>
        <div>
          <FaCheckCircle
            size={24}
            className={`${apprenticeshipUser.userProfileId ? 'text-keppel' : 'text-muted opacity-25'}`}
          />
          <span className="d-none d-sm-inline-block">
            Personal Information (PII, EEO)
          </span>
          <span className="d-inline-block d-sm-none">Personal Info.</span>
        </div>

        {!apprenticeshipUser.userProfileId && (
          <button
            className="btn btn-primary btn-sm text-nowrap"
            type="button"
            onClick={messageUser}
          >
            <span className="d-flex align-items-center">
              <span>Take Action</span>
              <FaChevronRight className="ml-2 d-none d-md-block" />
            </span>
          </button>
        )}
      </ChecklistRow>

      <ChecklistRow>
        <div>
          <FaCheckCircle
            size={24}
            className={`${apprenticeshipUserWageSchedule?.id ? 'text-keppel' : 'text-muted opacity-25'}`}
          />
          <span>Wage Schedule</span>
        </div>

        {apprenticeshipUser.userProfileId ? (
          <>
            {!apprenticeshipUserWageSchedule?.id &&
              canAccessUserWageSchedule(role) && (
                <Link
                  className="btn btn-primary btn-sm text-nowrap"
                  to={routeWithOrgId({
                    route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/wage-schedule`,
                    orgId
                  })}
                  title="Wage Schedule"
                >
                  <span className="d-flex align-items-center">
                    <span>Take Action</span>
                    <FaChevronRight className="ml-2 d-none d-md-block" />
                  </span>
                </Link>
              )}
          </>
        ) : (
          <span className="h6 mb-0 ml-2">
            <span className="badge badge-warning px-2 py-1">
              Personal Info. Needed
            </span>
          </span>
        )}
      </ChecklistRow>
      <ChecklistRow>
        <div>
          <FaCheckCircle
            size={24}
            className={`${apprenticeshipUserInstructionProvider?.id ? 'text-keppel' : 'text-muted opacity-25'}`}
          />
          <span>Instruction Provider</span>
        </div>

        {apprenticeshipUser.userProfileId ? (
          <>
            {!apprenticeshipUserInstructionProvider?.id &&
              canManageProgramUsers(role) && (
                <button
                  className="btn btn-primary btn-sm text-nowrap"
                  onClick={() => {
                    dispatch(
                      setCurrentModal({
                        key: MODAL_KEY_USER_APPRENTICESHIP_SETTINGS,
                        data: { user: apprenticeshipUser }
                      })
                    );
                  }}
                  type="button"
                  title="Take Action"
                >
                  <span className="d-flex align-items-center">
                    <span>Take Action</span>
                    <FaChevronRight className="ml-2 d-none d-md-block" />
                  </span>
                </button>
              )}
          </>
        ) : (
          <span className="h6 mb-0 ml-2">
            <span className="badge badge-warning px-2 py-1">
              Personal Info. Needed
            </span>
          </span>
        )}
      </ChecklistRow>

      {!apprenticeAgreement?.signerSignature && (
        <ChecklistRow>
          <div>
            <FaCheckCircle
              size={24}
              className={`${apprenticeAgreement?.id ? 'text-keppel' : 'text-muted opacity-25'}`}
            />
            <span className="d-block d-sm-none">Request Signature</span>
            <span className="d-none d-sm-block">Request Trainee Signature</span>
          </div>

          {canRequestApprenticeSignature ? (
            <button
              className={`btn ${apprenticeAgreement?.id ? 'btn-outline-secondary' : 'btn-primary'} btn-sm text-nowrap`}
              type="button"
              onClick={inviteToSignApprenticeAgreement}
            >
              <span className="d-flex align-items-center">
                <span>
                  {apprenticeAgreement?.id ? 'New Request' : 'Send Request'}
                </span>
                <FaChevronRight className="ml-2 d-none d-md-block" />
              </span>
            </button>
          ) : (
            <span className="h6 mb-0 ml-2">
              <span className="badge badge-warning px-2 py-1">
                {reasonCantRequestApprenticeSignature}
              </span>
            </span>
          )}
        </ChecklistRow>
      )}

      {/* <ChecklistRow>
          <div>
            <FaCheckCircle
              size={24}
              className={hasTraineeAgreement ? 'text-keppel' : 'text-muted opacity-25'}
            />
            <span>Request Trainee Sign Agreement</span>
          </div>

          {!hasTraineeAgreement && (
            <>
              <button
                className="btn btn-primary btn-sm text-nowrap"
                onClick={() => {
                  dispatch(setCurrentModal({
                    key: MODAL_KEY_USER_APPRENTICESHIP_SETTINGS,
                    data: { user: apprenticeshipUser }
                  }));
                }}
                type='button'
                title="Take Action"
              >
                <span className='d-flex align-items-center'>
                  <span>
                    Take Action
                  </span>
                  <FaChevronRight
                    className="ml-2 d-none d-md-block"
                  />
                </span>
              </button>
              <Link
                className="btn btn-white btn-sm text-nowrap disabled"
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/docs`,
                  orgId
                })}
                title="Take Action"
              >
                <span className='d-flex align-items-center'>
                  <span>
                    Take Action
                  </span>
                  <FaChevronRight
                    className="ml-2 d-none d-md-block"
                  />
                </span>

              </Link>
            </>
          )}
        </ChecklistRow> */}

      <ChecklistRow className="border-bottom-0">
        <div>
          <FaCheckCircle
            size={24}
            className={`${apprenticeAgreement?.signerSignature ? 'text-keppel' : 'text-muted opacity-25'}`}
          />
          <span className="d-inline-block d-sm-none">Agreement</span>
          <span className="d-none d-sm-inline-block">Trainee Agreement</span>

          {apprenticeAgreement?.id && !apprenticeAgreement?.signerSignature && (
            <span className="h6 mb-0 ml-2">
              <span className="badge badge-warning px-2 py-1">Not Signed</span>
            </span>
          )}
        </div>

        {apprenticeAgreement?.id && canAccessUserDocs(role) && (
          <Link
            className="btn btn-primary btn-sm text-nowrap"
            to={routeWithOrgId({
              route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/docs`,
              orgId
            })}
            title="Trainee Agreement"
          >
            <span className="d-flex align-items-center">
              <span className="d-block d-sm-none">Docs</span>
              <span className="d-none d-sm-block">Documents</span>
              <FaChevronRight className="ml-2 d-none d-md-block" />
            </span>
          </Link>
        )}
      </ChecklistRow>
    </Collapse>
  );
};

export default UserChecklist;
