import React from 'react';
import { useSelector } from 'react-redux';
import { HiDocumentSearch } from 'react-icons/hi';
import {
  canAccessAdminDocs,
  canAccessProgramTrainingDocs
} from '../../../permissions/access';
import { DashboardCards } from '../WorkforceSuite/Dashboard/DashboardCards';
import {
  TURBINE_ADMIN_DOCS,
  TURBINE_TRAINING_PROGRAM_DOCS
} from '../../../constants/urls';

const DashboardDocs = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Current User
  const role = currentUser?.role || [];
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;

  if (
    !canAccessAdminDocs(role) &&
    !canAccessProgramTrainingDocs(role, enableWorkforceSuite)
  ) {
    return null;
  }

  return (
    <div className="DashboardDocs">
      <div className="pt-3 pb-2">
        <div className="row">
          <div className="col">
            <div className="h3 m-0">Documentation</div>
          </div>
        </div>
      </div>

      <div className="my-3 mb-5">
        <div className="row">
          {canAccessAdminDocs(role) && (
            <div
              className="col-12 col-sm-6 mb-4" // col-xl-4
            >
              <DashboardCards
                title="Turbine Admin Guide"
                mobileTitle="Turbine Admin"
                titleIconComponent={() => (
                  <HiDocumentSearch
                    size={25}
                    className="mr-2 text-primary opacity-50"
                  />
                )}
                externalLink
                href={TURBINE_ADMIN_DOCS}
                subTitle="Manage your Workforce Space."
              />
            </div>
          )}

          {canAccessProgramTrainingDocs(role, enableWorkforceSuite) && (
            <div
              className="col-12 col-sm-6 mb-4" // col-xl-4
            >
              <DashboardCards
                title="Apprentage Guide"
                titleIconComponent={() => (
                  <HiDocumentSearch
                    size={25}
                    className="mr-2 text-primary opacity-50"
                  />
                )}
                externalLink
                href={TURBINE_TRAINING_PROGRAM_DOCS}
                subTitle="Build & Manage your Training Programs."
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardDocs;
