import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Switch from '../../../components/Switch';
import { updatePage } from '../../../services/pages';

const Title = ({ className = '', showSwitch = false, onSuccessCallback }) => {
  // Redux
  const page = useSelector((state) => state.page);
  // Local State
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(page?.showTitle || false);
  const [title, setTitle] = useState(page?.title || '');

  const titleIsDisabled = useMemo(() => {
    return (title || '').trim() === '' || page?.title === title;
  }, [page?.title, title]);

  const errorSave = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.', {
      autoClose: false,
      toastId: 'errorSave'
    });
    setShowTitle(!showTitle);
    setLoading(false);
    if (setLoading) {
      setLoading(false);
    }
  };

  const handleOnChangeShowTitle = () => {
    const value = !showTitle;

    toast.dismiss('errorSave');

    setShowTitle(value);

    updatePage({ showTitle: value === false ? null : true }, page?.id)
      .then((response) => {
        if (response?.error) {
          errorSave(response?.error);
          return;
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }

        toast.success('Title visibility updated!');
        setLoading(false);
      })
      .catch((error) => {
        errorSave(error);
      });
  };

  const onSubmitTitle = (e) => {
    e.preventDefault();

    toast.dismiss('errorSave');

    setLoading(true);

    updatePage({ title }, page?.id)
      .then((response) => {
        if (response?.error) {
          errorSave(response?.error);
          return;
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }
        toast.success('Title updated!');
        setLoading(false);
      })
      .catch((error) => {
        errorSave(error);
      });
  };

  return (
    <div className={`border p-3 ${className}`}>
      <div className="mb-3 d-flex align-items-end justify-content-between">
        <label
          className="h6 mb-0 font-weight-bold"
          htmlFor="title"
        >
          Title
        </label>

        {showSwitch && (
          <Switch
            id="pageShowTitle"
            value={showTitle}
            disabled={loading}
            onChange={handleOnChangeShowTitle}
          />
        )}
      </div>

      <form onSubmit={onSubmitTitle}>
        <input
          data-demo="disabled"
          type="text"
          name="title"
          onChange={(e) => {
            const { value } = e.currentTarget;

            setTitle(value);
          }}
          className="form-control"
          value={title}
          required
        />

        {!titleIsDisabled && (
          <div className="mt-3">
            <button
              className="btn btn-sm btn-primary"
              type="submit"
              disabled={titleIsDisabled}
            >
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Title;
