const groupDataByDate = (data, key = 'date') => {
  // this gives an object with dates as keys
  const dateGroups = data.reduce((groups, record) => {
    const dateGroup = record[key].split('T')[0];
    if (!groups[dateGroup]) {
      groups[dateGroup] = [];
    }
    groups[dateGroup].push(record);

    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(dateGroups).map((date) => {
    return {
      date,
      records: dateGroups[date]
    };
  });

  return groupArrays;
};

export default groupDataByDate;
