import { RESET_PATHWAY, SET_PATHWAY } from '../../actions/types';
import pathwayState from '../initialState/pathwayState';

const initialState = {
  ...pathwayState,
  fetched: false
};

export default function pathway(state = initialState, action) {
  switch (action.type) {
    case SET_PATHWAY:
      return {
        ...state,
        ...action.pathway,
        fetched: true
      };
    case RESET_PATHWAY:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
