const pageState = {
  id: null,
  createdAt: null,
  updatedAt: null,
  title: null,
  subtitle: null,
  inactive: null,
  pageLinkId: null,
  type: null,
  locationIds: null,
  userGroupIds: null,
  userId: null,
  orgId: null,
  dashboardId: null,
  showOrgLogo: null,
  showTitle: null,
  integration: null,
  url: null
};

export default pageState;
