/* eslint-disable import/prefer-default-export */
import { fetchResourceGroupTags } from '../services/resourceGroupTags';
import { SET_ENTRY_ORG_ID, SET_RESOURCE_GROUP_TAGS } from './types';

export const getResourceGroupTags = ({
  orgId,
  label,
  order,
  page,
  select,
  limit
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        console.error('Missing parameter, orgId is required!');
        reject(new Error('Missing parameter, orgId is required!'));
      }

      fetchResourceGroupTags({
        orgId,
        label,
        order,
        page,
        select,
        limit
      })
        .then((response) => {
          const list = response?.items || [];

          const responseData = {
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          };
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_RESOURCE_GROUP_TAGS,
            ...responseData
          });
          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};
