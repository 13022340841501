import formatAnswer from './formatAnswer';
import formatChallenge from './formatChallenge';
import formatMaterial from './formatMaterial';
import formatQuiz from './formatQuiz';
import formatTopic from './formatTopic';
import formatClass from './formatClass';
import formatGroup from './formatGroup';
import formatCohort from './formatCohort';
import formatEUnitTracking from './formatEUnitTracking';
import formatOrganization from './formatOrganization';
import formatAgreement from './formatAgreement';
import formatLocation from './formatLocation';
import formatUser from './formatUser';

function formatData(args, type, entry) {
  switch (type) {
    case 'user':
      return formatUser(args, entry);
    case 'answer':
      return formatAnswer(args);
    case 'agreement':
      return formatAgreement(args);
    case 'material':
      return formatMaterial(args);
    case 'challenge':
      return formatChallenge(args);
    case 'location':
      return formatLocation(args);
    case 'quiz':
      return formatQuiz(args);
    case 'topic':
      return formatTopic(args);
    case 'class':
      return formatClass(args);
    case 'group':
      return formatGroup(args);
    case 'cohort':
      return formatCohort(args);
    case 'eUnitTracking':
      return formatEUnitTracking(args);
    case 'organization':
      return formatOrganization(args);
    default:
      return false;
  }
}

export default formatData;
