import {
  SET_NOTIFICATION,
  RESET_NOTIFICATION,
  SET_NOTIFICATION_FILES,
  RESET_NOTIFICATION_FILES
} from '../../actions/types';
import notificationState from '../initialState/notificationState';

const initialState = {
  ...notificationState,
  cdnFiles: null,
  fetched: false
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        ...action.notification,
        cdnFiles: action.cdnFiles || null,
        fetched: true
      };
    case SET_NOTIFICATION_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles || null
      };
    case RESET_NOTIFICATION_FILES:
      return {
        ...state,
        cdnFiles: initialState.cdnFiles
      };
    case RESET_NOTIFICATION:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
