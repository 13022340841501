import React from 'react';

const Tabs = ({ activeTab, setActiveTab, className = '' }) => {
  return (
    <div className={className}>
      <ul className="nav nav-tabs">
        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'atRisk' ? 'active' : 'bg-light border-top border-left border-right'}`}
            type="button"
            onClick={() => {
              setActiveTab('atRisk');
            }}
          >
            At Risk
          </button>
        </li>

        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'noLogbook' ? 'active' : 'bg-light border-top border-left border-right'}`}
            type="button"
            onClick={() => {
              setActiveTab('noLogbook');
            }}
          >
            No Logbook
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
