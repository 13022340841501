import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import { canPostCourseNotifications } from '../../../services/currentUser';
import NotificationsList from '../../lists/NotificationsList';
import PostNotification from '../../btns/PostNotification';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import { goToElem } from '../../../services/manageContent';
import NotificationsTabs from '../../tabs/NotificationsTabs';

const Notifications = () => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const notificationsCourse = useSelector((state) => state.notificationsCourse);
  // Organization
  const orgId = organization?.id || '';
  // Course
  const classId = course?.id || null;
  const classTitle = course?.title || '';
  const teacherIds = course?.teacherIds || [];
  // User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  // Pagination
  const initialListConfig = {
    orgId,
    classId,
    page: 1,
    limit: 5
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  return (
    <div className="card my-3">
      <div className="card-header p-3 d-flex justify-content-between align-items-center">
        <h1 className="h5 m-0">Notifications</h1>
        {canPostCourseNotifications(role, userId, teacherIds) && (
          <PostNotification
            notificationType="course"
            listConfig={listConfig}
          />
        )}
      </div>

      <NotificationsTabs
        listConfig={listConfig}
        setListConfig={setListConfig}
        className="pt-3"
        cardStyle
      />

      <div className="card-content px-3">
        <NotificationsList
          listConfig={listConfig}
          className="mt-4"
          showUserRoles
          showMenu
        />
        {Array.isArray(notificationsCourse.list) &&
          notificationsCourse.list.length > 0 && (
            <PaginationSupabase
              items={notificationsCourse?.list}
              pagination={notificationsCourse?.pagination}
              page={notificationsCourse?.pagination?.page}
              className=""
              style={{}}
              onClickNext={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: notificationsCourse?.pagination?.page + 1
                }));
                goToElem('root');
              }}
              onClickPrev={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: notificationsCourse?.pagination?.page - 1
                }));
                goToElem('root');
              }}
            />
          )}
      </div>
      <div className="card-footer text-center border-left border-right border-bottom">
        Notifications posted here are visible to users enrolled in{' '}
        <b>{classTitle}</b>.
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(Notifications);
