import React, { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import { fetchOrgs } from '../../../services/organizations';
import sortArrByKey from '../../../utils/sortArrByKey';
import OrgAuthor from '../../OrgAuthor';

const Organizations = ({ orgIds, className = '', children }) => {
  const organization = useSelector((state) => state.organization);
  const orgId = pathOr(null, ['id'], organization);
  const [selectedOrgs, setSelectedOrgs] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (orgId && orgIds.length !== 0) {
      fetchOrgs({
        orgIds,
        orgId
      }).then((newOrgs) => {
        if (mounted) setSelectedOrgs(newOrgs);
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [orgIds, orgId]);

  if (!selectedOrgs) {
    return (
      <>
        <div>No organizations selected.</div>

        {children && <div className="mt-2">{children}</div>}
      </>
    );
  }

  const whichOrgs = sortArrByKey(selectedOrgs, 'name');

  return (
    <div className={`organizations d-flex flex-column ${className}`}>
      {whichOrgs &&
        whichOrgs.map((org) => (
          <div className="border px-2 py-3 d-flex align-items-center justify-content-between mb-1 position-relative">
            <OrgAuthor orgId={org.id} />
          </div>
        ))}
      {children && <div className="mt-2">{children}</div>}
    </div>
  );
};

export default Organizations;
