import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiTeacher } from 'react-icons/gi';
import { FaArrowRight } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Modal from '../../Modal';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
// import { getGeoLocation } from '../../../services/ipApi';
import { fetchTrainingInstitutions } from '../../../services/instructionProviders';
import { SET_INSTRUCTION_PROVIDER } from '../../../actions/types';
import { resetInstructionProvider } from '../../../actions/InstructionProviders';

const ChangeZipCodeInstructionProvider = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const userLocation = useSelector((state) => state.userLocation);
  // Local State
  const [showInstructionProviders, setShowInstructionProviders] =
    useState(false);
  const [ipAddressData, setIpAddressData] = useState({
    city: '',
    ip: '',
    latitude: '',
    longitude: '',
    state: '',
    stateReadable: '',
    zip: ''
  });
  const [zipCode, setZipCode] = useState(userLocation?.addressPostalCode || '');
  const [loading, setLoading] = useState(false);
  const [instructionProviders, setInstructionProviders] = useState([]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleClickSearchInstructionProvider = async (event) => {
    event.preventDefault();
    setLoading(true);
    await getTrainingInstitutions(zipCode);
    setLoading(false);
  };

  // const fetchTheLocationDataWithIp = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const response = await getGeoLocation();
  //     if (response) {
  //       setIpAddressData((prevValues) => ({
  //         ...prevValues,
  //         ...response
  //       }));
  //       await getTrainingInstitutions(response.zip);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // }, []);

  const getTrainingInstitutions = useCallback(async (postalCode) => {
    try {
      const response = await fetchTrainingInstitutions({
        location: postalCode
      });
      if (response?.SchoolPrograms && response?.SchoolPrograms.length > 0) {
        setInstructionProviders(response?.SchoolPrograms);
        setShowInstructionProviders(true);
      } else {
        setInstructionProviders([]);
        setShowInstructionProviders(true);
      }
    } catch (error) {
      setInstructionProviders([]);
      setShowInstructionProviders(true);
      console.error(error);
    }
  }, []);

  const getUrlWithHttp = (url) => {
    if (!url) return '';
    if (url.startsWith('http') || url.startsWith('https')) {
      return url;
    }
    return `https://${url}`;
  };

  const handleClickTrainingInstitution = (instructionProviderData) => {
    dispatch({
      type: SET_INSTRUCTION_PROVIDER,
      instructionProvider: {
        externalId: instructionProviderData.ID || '',
        name: instructionProviderData.SchoolName || '',
        url: getUrlWithHttp(instructionProviderData.SchoolUrl || ''),
        phone: instructionProviderData.Phone || '',
        addressLine1: instructionProviderData.Address || '',
        addressLine2: instructionProviderData.Region || '',
        addressCity: instructionProviderData.City || '',
        addressState: instructionProviderData.StateAbbr || '',
        addressPostalCode: instructionProviderData.Zip || ''
      }
    });
    dispatch(
      setCurrentModal({
        key: 'manageInstructionProvider',
        data: {
          autoFocusFirstName: true
        }
      })
    );
  };

  useEffect(() => {
    getTrainingInstitutions(zipCode);
  }, [getTrainingInstitutions, zipCode]);

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      pos="middle"
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h1 className="h5 m-0 text-white">Create Instruction Provider</h1>
        </div>

        {loading && (
          <div className="card-body">
            <Skeleton
              count={4}
              className="mb-3"
              style={{ height: '55px' }}
            />
            <div className="h4 text-center text-dark">
              Finding providers near you...
            </div>
          </div>
        )}

        {!loading && !showInstructionProviders && (
          <div className="card-body">
            <form
              id="change-pin"
              name="change-pin"
              onSubmit={handleClickSearchInstructionProvider}
            >
              <div className="mb-2">
                <label
                  htmlFor="zipCode"
                  className="font-weight-bold"
                >
                  Zip Code
                </label>
                <input
                  id="zipCode"
                  className="form-control"
                  name="zipCode"
                  placeholder="Enter zip code"
                  value={zipCode}
                  required
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btn-primary btm-sm"
                  type="submit"
                  disabled={!zipCode}
                >
                  Search Instruction Provider
                </button>
              </div>
            </form>
          </div>
        )}

        {showInstructionProviders && (
          <>
            <div className="d-flex justify-content-center align-items-center border-bottom py-2">
              <div className="h6 font-weight-bold m-0">
                {`Instruction Providers in ${ipAddressData?.zip || zipCode}`}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-link"
                  onClick={() => {
                    setShowInstructionProviders(false);
                    setInstructionProviders([]);
                    setZipCode('');
                    setIpAddressData({
                      city: '',
                      ip: '',
                      latitude: '',
                      longitude: '',
                      state: '',
                      stateReadable: '',
                      zip: ''
                    });
                  }}
                >
                  Change
                </button>
              </div>
            </div>
            <div
              id="training-institutes"
              className="px-3"
              style={{
                overflowY: 'scroll',
                maxHeight: '400px',
                minHeight: '300px'
              }}
            >
              {instructionProviders?.map((item) => (
                <div
                  key={item.ID}
                  className="my-2 my-md-3"
                >
                  <TrainingInstitution
                    handleClick={() => {
                      handleClickTrainingInstitution(item);
                    }}
                    name={item.SchoolName}
                    city={item.City}
                    state={item.StateAbbr}
                  />
                </div>
              ))}
              <div className="h-100 d-flex align-items-center justify-content-center">
                {instructionProviders?.length === 0 && !zipCode && (
                  <p className="h4 m-0 text-center">
                    No Training institutes found near you.
                  </p>
                )}
                {instructionProviders?.length === 0 && zipCode && (
                  <p className="h4 text-center">
                    No Training institutes found for provided zip code.
                  </p>
                )}
              </div>
            </div>
          </>
        )}

        {!loading && (
          <div className="d-flex justify-content-center card-footer">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                dispatch(
                  setCurrentModal({
                    key: 'manageInstructionProvider',
                    data: {}
                  })
                );
                dispatch(resetInstructionProvider());
              }}
            >
              Manually Enter Instruction Provider
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChangeZipCodeInstructionProvider;

export const TrainingInstitution = ({
  handleClick,
  name = '',
  city = '',
  state = ''
}) => {
  return (
    <div
      className="row-clickable bg-light rounded p-2 p-md-3 rounded-5 d-flex justify-content-between align-items-center flex-row border border-1 border-dark"
      onClick={handleClick}
      aria-hidden="true"
      style={{
        cursor: 'pointer'
      }}
    >
      <div className="mr-3 d-flex flex-row align-items-center">
        <div className="mr-4">
          <GiTeacher size={35} />
        </div>
        <div className="font-weight-bold">
          <div>{name}</div>
          <div>{`${city}, ${state}`}</div>
        </div>
      </div>
      <div>
        <div
          className="btn btn-sm rounded-circle btn-primary d-flex align-items-center"
          style={{
            height: '32px',
            width: '32px'
          }}
        >
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
};
