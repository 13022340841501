import { TURBINE_MARKETING_SITE } from '../../constants/urls';

const PoweredByTurbineLogo = ({ trackingSlug }) => `
  <table
    align="center"
    border="0"
    cellpadding="0"
    cellspacing="0"
    class="row row-10"
    role="presentation"
    width="100%"
  >
    <tbody>
      <tr>
        <td>
          <table
            align="center"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="row-content stack"
            role="presentation"
            style="mso-table-lspace: 0; mso-table-rspace: 0;"
            width="500"
          >
            <tbody>
              <tr>
                <th
                  class="column"
                  style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top;"
                  width="100%"
                >

                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="image_block"
                    role="presentation"
                    style="mso-table-lspace: 0; mso-table-rspace: 0;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="width:100%;padding-right:0px;padding-left:0px;padding-top:30px;padding-bottom:50px;"
                        >
                          <div
                            align="center"
                            style="line-height:10px"
                          >
                            <a
                              href="${TURBINE_MARKETING_SITE}?track=${trackingSlug}PoweredByLogo"
                              style="outline:none"
                              tabindex="-1"
                              target="_blank"
                            >
                              <img
                                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo-white.png"
                                style="display: block; height: auto; border: 0; width: 125px; max-width: 100%;"
                                width="125"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </th>
              </tr>
            </tbody>
          </table>

        </td>
      </tr>
    </tbody>
  </table>
`;

export default PoweredByTurbineLogo;
