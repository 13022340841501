import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import {
  ICON_CHECK_CIRCLE,
  ICON_TRIANGLE_BANG
} from '@apprentage/components/dist/constants/icons';
import { INVITE_TYPE_EMPLOYER_PROFILE_UPDATE } from '@apprentage/constants';
import {
  employerInviteStatuses,
  hasRequiredWorkforceInformation
} from '../../../services/employers';

const ProfileStatusCell = ({ value: employerProfileId }) => {
  // Redux
  const employerProfiles = useSelector((state) => state.employerProfiles);
  const invites = useSelector((state) => state.invites);
  // Employer Profile
  const employerProfile = useMemo(() => {
    if (!employerProfiles.list) {
      return {};
    }

    return employerProfiles.list.find(({ id }) => id === employerProfileId);
  }, [employerProfiles.list]);
  // Invites
  const inviteStatuses = useMemo(() => {
    const invitesList = pathOr([], ['list'], invites);
    const inviteHistory = pathOr([], ['inviteHistory'], employerProfile);
    const statuses = employerInviteStatuses({
      invitesList,
      inviteHistory
    });

    return statuses;
  }, [employerProfile?.id, invites]);

  const requiredWorkforceInformation = useMemo(() => {
    return hasRequiredWorkforceInformation(employerProfile);
  }, [employerProfile?.id]);

  if (requiredWorkforceInformation) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_CHECK_CIRCLE} text-success mr-1`} /> Completed
      </div>
    );
  }

  if (inviteStatuses[INVITE_TYPE_EMPLOYER_PROFILE_UPDATE]) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_TRIANGLE_BANG} text-warning mr-1`} /> Data
        Requested
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center text-nowrap">
      <i className={`${ICON_TRIANGLE_BANG} text-danger mr-1`} /> Incomplete
    </div>
  );
};

export default ProfileStatusCell;
