import { getClient, flattenItems } from '../contentful';

export const topicSectionsTypes = (sections) => {
  const sectionTypes = {
    material: 0,
    challenge: 0,
    quiz: 0
  };

  sections.forEach((section) => {
    const contentType = section.sys.contentType.sys.id;
    sectionTypes[contentType] += 1;
  });

  return sectionTypes;
};

export const numQuizzesInTopic = ({ topicId, orgId }) => {
  const config = {
    content_type: 'quiz',
    include: 1,
    'fields.topicId': topicId,
    'fields.orgId': orgId
  };

  return new Promise((resolve) => {
    getClient()
      .getEntries(config)
      .then(({ items, total }) => {
        const quizzesList = items.length ? flattenItems(items) : null;

        resolve({
          quizzesList,
          quizzesTotal: total
        });
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

export const numChallengesInTopic = ({ topicId, orgId, classId }) => {
  const config = {
    content_type: 'challenge',
    include: 1,
    'fields.topicId': topicId,
    'fields.classId': classId,
    'fields.orgId': orgId
  };

  return new Promise((resolve) => {
    getClient()
      .getEntries(config)
      .then(({ total }) => {
        resolve(total);
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
