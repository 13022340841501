import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ORG_CONSOLE_CHALLENGES } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';

const Tabs = ({ active, className = 'mt-3' }) => {
  const organization = useSelector((state) => state.organization);
  const orgId = pathOr(null, ['id'], organization);

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item">
          <Link
            className={`nav-link disabled mr-2 ${active === 'all' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: ORG_CONSOLE_CHALLENGES,
              orgId
            })}
            title="All Challenges"
          >
            All
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link mr-2 ${active === 'submissions' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: ORG_CONSOLE_CHALLENGES,
              orgId
            })}
            title="Submissions"
          >
            Submissions
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
