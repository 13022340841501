import React, { useMemo } from 'react';
import Table from './Table';
import NameCell from './NameCell';
import LocationCell from '../../../ManageContent/Tables/LocationCell';
import ApprenticeshipIdsCell from './ApprenticeshipIdsCell';

const List = ({ data, onClickName, orgType }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: NameCell,
        onClickName,
        orgType
      },
      {
        Header: orgType !== 'workforce' ? 'Employer' : 'Office',
        accessor: 'locationId',
        Cell: LocationCell,
        onClickName,
        orgType
      },
      {
        Header: 'Training Programs',
        accessor: 'apprenticeshipIds',
        Cell: ApprenticeshipIdsCell
      }
    ],
    [onClickName, orgType]
  );

  const hiddenColumns = useMemo(() => {
    const cols = [];

    return cols;
  }, []);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Users.</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
