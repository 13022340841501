import { referenceType, referenceTypes } from '../contentful';

function formatTopic({
  locked,
  featured,
  title,
  description,
  topicHeader,
  classId,
  category,
  level,
  sectionIds,
  sections,
  challenges,
  prerequisiteId,
  instructionNotes,
  enableNewEditor
}) {
  const data = {};

  if (enableNewEditor !== undefined) {
    data.enableNewEditor = {
      'en-US': enableNewEditor
    };
  }

  if (locked !== undefined) {
    data.locked = {
      'en-US': locked
    };
  }

  if (featured !== undefined) {
    data.featured = {
      'en-US': featured
    };
  }

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (topicHeader !== undefined) {
    data.topicHeader = {
      'en-US': topicHeader
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (classId !== undefined) {
    data.classId = {
      'en-US': classId
    };
  }

  if (category !== undefined) {
    data.category = {
      'en-US': category
    };
  }

  if (level !== undefined) {
    data.level = {
      'en-US': level
    };
  }

  if (instructionNotes !== undefined) {
    data.instructionNotes = {
      'en-US': instructionNotes
    };
  }

  if (sections !== undefined) {
    data.sections = {
      'en-US': sections === null ? undefined : referenceTypes(sections) // TODO topic flatten
    };
  }

  /**
   * {array of strings || undefined || null } sectionIds
   * FE will send null which is then converted to undefined
   */
  if (sectionIds !== undefined) {
    data.sectionIds = {
      'en-US': sectionIds === null ? undefined : sectionIds
    };
  }

  if (challenges !== undefined) {
    data.challenges = {
      'en-US': referenceTypes(challenges) // TODO topic flatten
    };
  }

  if (prerequisiteId !== undefined) {
    data.prerequisite = {
      'en-US':
        prerequisiteId === null ? undefined : referenceType(prerequisiteId) // TODO topic flatten
    };
  }

  return { fields: { ...data } };
}

export default formatTopic;
