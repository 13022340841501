import { isLocalhost } from '../serviceWorker';
import * as twApi from './tw-api';
import { updateUser } from './user';
import { createEntryUrl } from './entry';
import { classAnswersEval, topicAnswersEval } from './contentFlow/challenges';
import {
  classQuizOutcomesEval,
  topicQuizOutcomesEval
} from './contentFlow/quizzes';
import { GMAIL_SEND } from '../constants/api';
import { TURBINE_MARKETING_SITE } from '../constants/urls';

export const subjectIcon = (status) => {
  switch (status) {
    case 'accepted':
      return '✅';
    case 'rejected':
      return '❌';
    case 'requestChanges':
      return '⚠️';
    case 'inReview':
      return '👀';
    default:
      return '';
  }
};

/**
 * Email an update about a submission to a challenge
 * @param {string} status // accepted, rejected, requestChanges
 * @returns {Promise<string>} emails, link
 */
export const notifyMember = ({
  message,
  subject,
  status,
  // notes,
  orgName,
  classId,
  topicId,
  entryId, // challengeId
  classTitle,
  challengeTitle,
  reviewerName,
  // User who submitted answer to challenge
  userEmail,
  userName
}) => {
  const challengeUrl = createEntryUrl({ classId, topicId, entryId });
  const linkText = 'Instructor Feedback';
  const ctaText = 'Click this link to review:';
  const defaultMessage = `${reviewerName} reviewed your submission to <b>${challengeTitle}</b>.`;

  return new Promise((resolve, reject) => {
    const emailParts = {
      to: userEmail,
      from: {
        name: orgName
      },
      subject: `${subjectIcon(status)} ${subject}`,
      body: '',
      htmlBody: `
        <h1>
          ${orgName}
        </h1>
        <p>
          Hi ${userName},
        </p>
        <p>
          ${subjectIcon(status)} ${message || defaultMessage}
        </p>
        <p>
          ${ctaText} <a href="${challengeUrl}" target="_blank" title="${linkText}">${linkText}</a>
        </p>
        <p>
          Challenge: ${challengeTitle}
          <br />
          Course: ${classTitle}
        </p>
        <br />
        <br />
        <font color='#888888'>--</font>
        <br />
        <p>
          Powered by <a href="${TURBINE_MARKETING_SITE}?track=reviewAnswer" target="_blank" title="Turbine Workforce">Turbine Workforce</a>
        </p>
      `
    };

    // LOCALHOST don't send email
    if (isLocalhost) {
      console.log(emailParts);
      return false;
    }

    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          // TODO set flag on org notifyUsersByEmail
          // TODO implement firebase messaging and browser notifications to push messages to user
          resolve();
        } else {
          console.error(response.status);

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

// #4 (Content Flow)
export const classCompleted = ({ classId, userId }) => {
  updateUser(
    {
      completedClassId: classId
    },
    userId
  );
};

// #3 (Content Flow)
export const classApproval = (data) => {
  const {
    catalyst,
    classAnswers,
    classChallenges,
    classQuizzes,
    classQuizOutcomes
  } = data;

  const classChallengesCompleted = classAnswersEval({
    catalyst,
    challenges: classChallenges,
    answers: classAnswers
  });
  const classQuizzesCompleted = classQuizOutcomesEval({
    quizzes: classQuizzes,
    quizOutcomes: classQuizOutcomes
  });

  console.log('class', { classChallengesCompleted, classQuizzesCompleted });

  if (classChallengesCompleted && classQuizzesCompleted) {
    classCompleted(data);
  }
};

// #2 (Content Flow)
export const topicCompleted = (data) => {
  const { topicId, userId } = data;

  updateUser(
    {
      completedTopicId: topicId
    },
    userId
  ).then(() => {
    // Topic Completed, check if class is Completed
    classApproval(data);
  });
};

// #1 (Content Flow)
export const topicApproval = (data) => {
  const {
    catalyst,
    topicChallenges,
    topicAnswers,
    topicQuizzes,
    topicQuizOutcomes
  } = data;

  const topicChallengesCompleted = topicAnswersEval({
    catalyst,
    challenges: topicChallenges,
    answers: topicAnswers
  });
  const topicQuizzesCompleted = topicQuizOutcomesEval({
    quizzes: topicQuizzes,
    quizOutcomes: topicQuizOutcomes
  });

  console.log('topic', { topicChallengesCompleted, topicQuizzesCompleted });

  if (topicChallengesCompleted && topicQuizzesCompleted) {
    topicCompleted(data);
  }
};
