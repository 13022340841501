import * as twApi from './tw-api';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import {
  API_CONTEXT_INPUT_COUNTS,
  DEFAULT_PAGINATION_LIMIT
} from '../constants/api';

const table = 'contextInputs';

export async function fetchInputCounts({
  orgId,
  locationIds,
  userId,
  // gteDate,
  // lteDate,
  version = 'v1'
}) {
  const params = {
    orgId,
    ...(userId ? { userId } : {})
    // ...(gteDate ? { gteDate } : {}),
    // ...(lteDate ? { lteDate } : {})
  };

  if (locationIds !== undefined) {
    if (Array.isArray(locationIds) && locationIds.length > 0) {
      params['f.locationIds[ov]'] = locationIds.join(',');
    } else if (locationIds === null) {
      params.locationIds = null;
    }
  }

  const urlParams = new URLSearchParams(params);
  const url = `/${version}${API_CONTEXT_INPUT_COUNTS}`;
  const response = await twApi.get(`${url}?${urlParams.toString()}`);

  return response;
}

export const fetchContextInputs = async ({
  orgId,
  locationId,
  userId,
  order = 'createdAt',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    orgId,
    order,
    page,
    limit
  };

  if (orgId !== undefined) {
    if (orgId === null) {
      params['f.orgId[is]'] = null;
    } else {
      params['f.orgId[eq]'] = orgId;
    }
  }

  if (locationId) {
    params['f.locationId[eq]'] = locationId;
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchContextInput = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateContextInput = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createContextInput = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteContextInput = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
