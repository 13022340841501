import React from 'react';
import {
  quizFeedback,
  quizTotalScore
} from '../../services/contentFlow/quizzes';

const PercentageCell = ({ row }) => {
  const {
    original: { score, maxScore }
  } = row;

  return (
    <div className="h6">
      <span
        className="d-inline-block text-right"
        style={{ minWidth: '40px' }}
      >
        {quizTotalScore({ score, maxScore })}
      </span>
      <span className="ml-3">
        {quizFeedback({ score, maxScore, iconOnly: true })}
      </span>
    </div>
  );
};

export default PercentageCell;
