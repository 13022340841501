import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ICON_TASKS } from '@apprentage/constants';
import { getCourseGroup, resetCourseGroup } from '../../../../actions/Groups';
import { getCourses, resetCourses } from '../../../../actions/Courses';
import { withAuthorization } from '../../../Session';
import { updateGroup } from '../../../../services/groups';
import { routeWithOrgId } from '../../../../services/organizations';
import {
  ORG_CONSOLE_COURSES,
  ORG_CONSOLE_DASHBOARD
} from '../../../../constants/routes';
import { ICON_COURSES } from '../../../../constants/assets';
import withOrgConsole from '../../../App/withOrgConsole';
import Loading from '../../../Loading';
import CoursesList from '../../../CoursesList';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import Tabs from '../Tabs';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';

const ManageCourses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courseGroup = useSelector((state) => state.courseGroup);
  const courses = useSelector((state) => state.courses);
  // Misc
  const orgId = organization?.id || null;
  const groupId = params?.groupId || null;
  const courseGroupRouteBase = `${ORG_CONSOLE_COURSES}/group/${groupId}`;
  const courseGroupRoute = routeWithOrgId({
    route: courseGroupRouteBase,
    orgId
  });
  // Local State
  const [loading, setLoading] = useState(false);
  const [selectedCourseIds, setSelectedCourseIds] = useState(
    courseGroup.itemIds || []
  );

  useEffect(() => {
    if (orgId) {
      dispatch(getCourses({ orgId }));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    const href = window?.location?.href || '';
    const groupRoute = `org/courses/group/${groupId}`;

    return function cleanup() {
      dispatch(resetCourses());

      if (!href.includes(groupRoute)) {
        dispatch(resetCourseGroup());
      }
    };
  }, [dispatch, groupId]);

  if (!groupId || (groupId && !courseGroup.id)) {
    return <Redirect to={courseGroupRoute} />;
  }

  const handleSave = () => {
    setLoading(true);

    const dataToSave = {
      itemIds: selectedCourseIds.length
        ? selectedCourseIds.map((id) => id)
        : null
    };

    updateGroup(dataToSave, groupId).then(() => {
      dispatch(getCourseGroup({ groupId })).then(() => {
        toast.success('Saved!');
        history.replace(courseGroupRoute);
      });
    });
  };

  const handleSelectCourse = ({ classId, type }) => {
    let newSelectedCourseIds = [];

    if (type === 'remove') {
      newSelectedCourseIds = selectedCourseIds.filter((id) => id !== classId);
    }

    if (type === 'add') {
      newSelectedCourseIds = [...selectedCourseIds, classId];
    }

    setSelectedCourseIds(newSelectedCourseIds);
  };

  if (!courses.fetched && !courses.list) {
    return <Loading text="Loading courses..." />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-xl-7">
        <OrgConsoleHeader
          pageTitle="Courses"
          image={ICON_COURSES}
          imageSize={25}
          route={ORG_CONSOLE_DASHBOARD}
        />

        {/* <span className='badge bg-white border ml-2'>
        {selectedCourseIds ? selectedCourseIds.length : 0}
      </span> */}

        <Tabs active="groups" />

        <div className="mb-5 bg-white border-left border-right border-bottom p-3">
          <OrgConsoleSubheader
            pageTitle="Manage Courses"
            icon={ICON_TASKS}
            iconStyle={{
              fontSize: '1rem'
            }}
            route={courseGroupRouteBase}
          />

          <div className="card mt-3">
            <div className="card-header bg-dark border-dark">
              <h6 className="m-0 text-white font-weight-bold">
                {courseGroup.title}
              </h6>
            </div>
            <div
              id="group-manage-courses"
              className="card-body p-0"
            >
              <CoursesList
                key={
                  selectedCourseIds ? selectedCourseIds.join('-') : 'no-courses'
                }
                showCheckBoxes={!loading}
                list={courses?.list}
                selectedCourseIds={selectedCourseIds}
                handleSelectCourse={handleSelectCourse}
                showGroupsCol
              />
            </div>

            <div className="card-footer d-flex align-items-center">
              <button
                className="btn btn-primary mr-2"
                type="button"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
              <Link
                to={courseGroupRoute}
                title="Cancel"
                className="btn btn-link"
                disabled={loading}
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ManageCourses);
