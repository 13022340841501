import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { isProgramManager, isProgramTrainer } from '@apprentage/utils';
import { withAuthorization } from './Session';
import Loading from './Loading';
import { isActiveMembership } from '../services/currentUser';
import { redirectToAuth } from '../services/auth';
import { ACCOUNT_INACTIVE } from '../constants/errors';
import Dashboard from './Dashboard';
import {
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_WORKFORCE
} from '../constants/routes';

const Slash = () => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const user = useSelector((state) => state.user);
  // Misc
  const orgSlug = organization?.slug || '';
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;

  const role = currentUser?.role || [];

  if (user?.uid && !currentUser.id) {
    return <Loading />;
  }

  if (!isActiveMembership(currentUser?.membership)) {
    redirectToAuth({
      organizationSlug: orgSlug,
      error: {
        code: ACCOUNT_INACTIVE
      }
    });
    return;
  }

  if (organization?.dashboard) {
    if (!isProgramManager(role) && !isProgramTrainer(role)) {
      return <Dashboard />;
    }
  }

  if (enableWorkforceSuite) {
    return <Redirect to={ORG_CONSOLE_WORKFORCE} />;
  }

  return <Redirect to={ORG_CONSOLE_DASHBOARD} />;
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition))(Slash);
