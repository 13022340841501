import React from 'react';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import Supervisors from './Supervisors';
import Location from '../../../Location';

const WorkBlock = ({ route }) => {
  // Local State
  const directoryUser = useSelector((state) => state.directoryUser);
  const locations = useSelector((state) => state.locations);
  const dictionary = useSelector((state) => state.dictionary);
  // Directory User
  const supervisorIds = directoryUser?.supervisorIds || null;
  const locationId = directoryUser?.locationId || null;
  const locationsHash = locations?.hash || {};
  const directoryUserLocation = locationsHash[locationId];

  return (
    <div className="mb-4">
      {Array.isArray(supervisorIds) && (
        <div className="card">
          <div className="card-header text-left">
            <h6 className="m-0 font-weight-bold">
              {pluralize(dictionary.supervisor, supervisorIds.length)}
            </h6>
          </div>
          <div
            className="card-body pb-1"
            data-demo="disabled"
          >
            <Supervisors
              userIds={supervisorIds}
              route={route}
            />
          </div>
        </div>
      )}

      {directoryUserLocation && ( // TODO make locationName clickable
        <Location
          data={directoryUserLocation}
          showHeader
          className="shadow mt-3"
          headerTitle={directoryUserLocation?.name}
          headerSubtitle={directoryUserLocation?.subtitle}
        />
      )}
    </div>
  );
};

export default WorkBlock;
