import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import { flattenItems } from '../../../services/contentful';
import { getTopicId } from '../../../services/references';
import isPublished from '../../../utils/isPublished';
import ReferenceDnd from '../ReferenceDnd';

/** *
 * @items arr
 * @title str
 * @required bool
 * @onUpdate fn, passes new items to parent after reorder
 * @helperText str
 * // Reference props
 * @classId str
 * @editContent bool
 * @addContent bool
 * @addTypes arr, ex: ['topic']
 */
class ReferencesDnd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNewItem: null,
      itemIndex: null,
      itemModal: false,
      showDialogConfirmDeleteItem: false,
      ...props
    };
  }

  setModalData = ({ key, val }) => {
    // TODO is this needed?
    this.setState({
      [key]: val
    });
  };

  toggleModal = ({ key, val }) => {
    // TODO is this needed?
    this.setState({
      [key]: val
    });
  };

  saveTextField = (e) => {
    const { currentTarget } = e;
    const {
      name,
      value,
      dataset: { temp: tempDataKey }
    } = currentTarget;
    const tempData = this.state[tempDataKey];

    this.setState({
      [tempDataKey]: {
        ...tempData,
        [name]: value
      }
    });
  };

  onDragEndItems = (result) => {
    const {
      destination,
      source: { index: sourceIndex, droppableId: sourceDropId }
    } = result;

    if (!destination) return false;

    const { index: destinationIndex, droppableId: destinationDropId } =
      destination;

    if (
      destinationDropId === sourceDropId &&
      destinationIndex === sourceIndex
    ) {
      return false;
    }

    const { items } = this.state;
    const itemsNew = Array.from(items);
    const itemToMove = itemsNew.splice(sourceIndex, 1);

    itemsNew.splice(destinationIndex, 0, itemToMove[0]);

    this.setState(
      {
        items: itemsNew
      },
      () => {
        this.props.onUpdate(itemsNew); // Save to parent
      }
    );
  };

  render() {
    const {
      items,
      title,
      helperText,
      // References props
      classId,
      topicId,
      editContent,
      addContent,
      addTypes
    } = this.state;

    let publishedItems = items.filter((item) => isPublished(item));

    publishedItems = flattenItems(publishedItems); // TODO course flatten, topic flatten

    return (
      <>
        <div className="references-container">
          <div className="d-flex justify-content-between align-items-center">
            {title && (
              <>
                <label
                  htmlFor="type"
                  className="mb-2"
                >
                  <b>{title}:</b> <span className="text-danger">*</span>
                </label>
                {publishedItems && publishedItems.length > 0 && (
                  <div className="d-flex align-items-center text-muted justify-content-end">
                    <i className={`${ICON_TRIANGLE_BANG} mr-1 text-warning`} />
                    <span className="small">
                      Save changes after reordering.
                    </span>
                  </div>
                )}
              </>
            )}
          </div>

          {publishedItems && publishedItems.length > 0 && (
            <DragDropContext onDragEnd={this.onDragEndItems}>
              <Droppable droppableId="dnd-column--quiz">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="references references-manage rounded border p-1"
                  >
                    {publishedItems.map((item, i) => (
                      <Draggable
                        draggableId={`item-${i}`}
                        index={i}
                        key={i}
                      >
                        {(providedItem) => (
                          <ReferenceDnd
                            index={i}
                            provided={providedItem}
                            innerRef={providedItem.innerRef}
                            referenceIndex={i}
                            content={item}
                            classId={classId}
                            entryId={item.id}
                            topicId={getTopicId({ topicId, content: item })}
                            addContent={addContent}
                            editContent={editContent}
                            addTypes={addTypes}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>

        {helperText && (
          <div className="text-muted medium mt-2">{helperText}</div>
        )}
      </>
    );
  }
}

export default ReferencesDnd;
