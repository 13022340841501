import { getClient, flattenItem } from '../services/contentful';
import { updateEntry } from '../services/entry';
import formatData from '../services/formatData';
import convertUrlParamsToObject from '../utils/convertUrlParamsToObject';
import { setCurrentModal } from './Modals';
import { SET_BILLING_AGREEMENT } from './types';

export const getAgreement = ({ agreementId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getClient()
        .getEntry(agreementId)
        .then((response) => {
          const billingAgreement = response ? flattenItem(response) : null;

          dispatch({
            type: SET_BILLING_AGREEMENT,
            billingAgreement
          });

          const {
            location: { search: locationSearch }
          } = document;
          const { agreement } = convertUrlParamsToObject(locationSearch);

          if (billingAgreement.title && !billingAgreement.accepted) {
            if (agreement && agreement === 'decline') {
              // do nothing
              console.log('agreement', agreement);
            } else {
              dispatch(setCurrentModal({ key: 'billingAgreement' }));
            }
          }

          resolve(billingAgreement);
        })
        .catch((error) => {
          console.error('getAgreement', error);
          reject(error);
        });
    });
  };
};

export const updateAgreement = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const agreementId = data?.id || null;
      const preppedData = formatData(data, 'agreement');

      updateEntry(preppedData, agreementId)
        .then(() => {
          setTimeout(() => {
            dispatch(getAgreement({ agreementId })).then((updatedAgreement) => {
              resolve(updatedAgreement);
            });
          }, 250);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};
