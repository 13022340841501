import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import CoursesList from '../../../CoursesList';
import Collapse from '../../../ManageContent/Collapse';

const SharedWithOrg = () => {
  // Redux
  const courses = useSelector((state) => state.courses);
  // Misc
  const syndicatedCourses = pathOr([], ['syndicatedCourses'], courses);

  return (
    <Collapse
      title="Inbound"
      id="courseSharedWithOrg"
      className="mb-3"
    >
      <CoursesList
        list={syndicatedCourses}
        showSyndicationCol
      />
    </Collapse>
  );
};

export default SharedWithOrg;
