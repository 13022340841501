import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaPencilAlt } from 'react-icons/fa';
import { dayMdyDate, hrMin } from '../../utils/date';
import { courseAttendanceByCohort } from '../../services/attendance';
import { setCurrentModal } from '../../actions/Modals';

const UserCeAttendance = ({ student, attendance }) => {
  const dispatch = useDispatch();
  const currentCohort = useSelector((state) => state.currentCohort);

  if (!student?.id || !attendance) {
    return null;
  }

  const manageAttendance = (data) => {
    dispatch(
      setCurrentModal({
        key: 'manageAttendance',
        data
      })
    );
  };

  let whichAttendance = attendance;

  if (currentCohort.id) {
    const cohortAttendance = courseAttendanceByCohort({
      courseAttendance: attendance,
      cohortId: currentCohort.id
    });

    whichAttendance = cohortAttendance;
  }

  const renderRows = () => {
    // TODO => <Rows />
    const eUnitTracking = currentCohort?.eUnitTracking?.fields || null; // TODO cohort flatten
    const eUnits = currentCohort?.eUnits || null;
    const semesterCode = eUnitTracking?.semesterCode || null;
    const code = eUnitTracking?.code || null;

    return (
      <>
        {whichAttendance.map((item, i) => {
          return (
            <tr key={`${item.date}-${i}`}>
              <td className="border-right pr-0">{dayMdyDate(item.date)}</td>
              <td className="border-right pr-0">
                {item.session ? item.session : '-'}
              </td>

              {currentCohort.id && semesterCode && (
                <td className="border-right pr-0">
                  {item.semesterCode ? item.semesterCode : '-'}
                </td>
              )}

              {currentCohort.id && code && (
                <td className="border-right pr-0">
                  {item.eUnitCode ? item.eUnitCode : '-'}
                </td>
              )}

              {currentCohort.id && eUnits && eUnits.length && (
                <td className="border-right pr-0">
                  {item.eUnitCodes ? item.eUnitCodes.join(',') : '-'}
                </td>
              )}

              {/* {eUnits && eUnits.length && (
              <td className="border-right pr-0">
                {item.sessionCredits ? item.sessionCredits.map((credit) => (<span>{ credit.number } {credit.type || '' }</span>)) : '-'}
              </td>
            )} */}

              <td className="border-right pr-0">
                {item.records ? (
                  item.records.map((record, recordIndex) => {
                    return (
                      <span
                        key={`${record}-${recordIndex}`}
                        className="badge bg-light border px-3 py-2 mr-1 mb-1"
                      >
                        {hrMin(record)}
                      </span>
                    );
                  })
                ) : (
                  <span className="badge bg-light border px-3 py-2 mr-1">
                    {hrMin(item.date)}
                  </span>
                )}
              </td>
              <td className="d-print-none">
                <div className="btn-group">
                  <button
                    className="btn-link text-ships-officer"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-label="Manage Menu"
                    title={
                      item.session
                        ? `Session ${item.session} Settings`
                        : 'Settings'
                    }
                  >
                    <FaEllipsisH />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right p-0">
                    <div className="card-header bg-dark text-nowrap text-white">
                      <h6 className="m-0">
                        {item.session ? `Session ${item.session}` : 'Settings'}
                      </h6>
                    </div>
                    <button
                      className="dropdown-item py-2 d-flex align-items-center d-flex align-items-center"
                      onClick={() => {
                        manageAttendance({
                          courseAttendance: whichAttendance,
                          courseAttendanceIndex: item.courseAttendanceIndex,
                          attendanceRecord: item,
                          userId: student?.id
                        });
                      }}
                      type="button"
                    >
                      <FaPencilAlt size={12} />
                      <span className="ml-2">Edit Record</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      {attendance ? (
        <table className="table table-striped m-0">
          <thead>
            <tr>
              <th
                scope="col"
                className="border-0"
              >
                Date
              </th>
              <th
                scope="col"
                className="border-0 d-none d-md-table-cell"
              >
                Session
              </th>
              <th
                scope="col"
                className="border-0 d-none d-md-table-cell"
              >
                Semester
              </th>
              <th
                scope="col"
                className="border-0 d-none d-md-table-cell"
              >
                Unit Code
              </th>
              <th
                scope="col"
                className="border-0 d-none d-md-table-cell"
              >
                Unit Codes
              </th>
              {/* <th scope="col" className='border-0 d-none d-md-table-cell'>
                Credits
              </th> */}
              <th
                scope="col"
                className="border-0 d-none d-md-table-cell"
              >
                Records
              </th>
              <th
                scope="col"
                className="border-0 d-none d-md-table-cell d-print-none"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      ) : (
        <div className="dash-module-no-content">No entries.</div>
      )}
      <div className="d-flex justify-content-end d-print-none border-top px-2 pt-3 pb-1">
        <button
          className="btn btn-sm btn-white"
          onClick={() => {
            manageAttendance({
              courseAttendance: whichAttendance,
              userId: student?.id
              // attendance: student?.attendance
            });
          }}
          type="button"
        >
          <i className="fas fa-plus" /> Attendance Record
        </button>
      </div>
    </>
  );
};

export default UserCeAttendance;
