import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../actions/Courses';
import { getOrgCourseGroupIds, getGroups } from '../../actions/Groups';
import Loading from '../Loading';
import List from './List';

// TODO Rename to CourseGroupsList and move to /lists
const GroupsList = ({ type = 'course' }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courseGroups = useSelector((state) => state.courseGroups);
  // Organization
  const orgId = organization?.id || null;

  useEffect(() => {
    dispatch(getOrgCourseGroupIds({ orgId })).then((courseGroupIds) => {
      dispatch(
        getGroups({
          orgId,
          type,
          sortByIds: courseGroupIds
        })
      );
      dispatch(getCourses({ orgId }));
    });

    // return function cleanup() {
    //   dispatch(resetCourses());
    //   dispatch(resetCourseGroups());
    // };
  }, [dispatch, orgId, type]);

  if (!courseGroups.fetched) {
    return <Loading text="Loading Groups..." />;
  }

  return (
    <List
      data={courseGroups.list}
      className="groups-list border"
    />
  );
};

export default GroupsList;
