import React from 'react';
import { Link } from 'react-router-dom';
import { canAccessSkills } from '@apprentage/utils';
import {
  canAccessAllUsers,
  canAccessOrgLocations,
  canAccessResources
} from '../../services/currentUser';
import { canManageProjects } from '../../permissions/manage';
import {
  ORG_CONSOLE_LOCATIONS,
  ORG_CONSOLE_PAGES,
  ORG_CONSOLE_PROJECTS,
  ORG_CONSOLE_SKILLS,
  ORG_CONSOLE_USERS,
  SEARCH_CONTENT
} from '../../constants/routes';
import {
  ICON_LOCATIONS,
  ICON_PAGES,
  ICON_PROJECTS,
  ICON_SEARCHES,
  ICON_SKILLS,
  ICON_USER_DIRECTORY
} from '../../constants/assets';
import { canAccessPages } from '../../permissions/access';
import { locationTerminology } from '../../services/locations';
import AppsIcon from './AppsIcon';

const MoreApps = ({
  className = '',
  role = [],
  orgType,
  enableWorkforceSuite,
  enableResources,
  enablePages,
  enableProjects
}) => {
  const locationTerm = locationTerminology({ orgType });

  const disablePages = !canAccessPages(role) || !enablePages;
  const disableProjects = !canManageProjects(role) || !enableProjects;
  const disableSkills = !canAccessSkills(role, orgType);
  const disableResources = !canAccessResources(role, enableResources, orgType);
  const disableSearches = disableResources;
  const disableUserDirectory = !canAccessAllUsers(role, orgType);

  return (
    <div className={`topNav-apps-more ${className} bg-light`}>
      <AppLink
        url={ORG_CONSOLE_USERS}
        text="Directory"
        testId="topNavOrgLinkUserDirectory"
        disabled={disableUserDirectory}
        imgSrc={ICON_USER_DIRECTORY}
      />

      {canAccessOrgLocations(role, orgType) && !enableWorkforceSuite && (
        <AppLink
          url={ORG_CONSOLE_LOCATIONS}
          text={`${locationTerm.text}s`}
          testId="topNavOrgLinkLocations"
          imgSrc={ICON_LOCATIONS}
        />
      )}

      <AppLink
        url={SEARCH_CONTENT}
        text="Searches"
        testId="topNavOrgLinkSkills"
        disabled={disableSearches}
        imgSrc={ICON_SEARCHES}
      />

      <AppLink
        url={ORG_CONSOLE_PAGES}
        text="Pages"
        testId="topNavOrgLinkPages"
        disabled={disablePages}
        imgSrc={ICON_PAGES}
        imgSize={30}
        showPro
      />

      <AppLink
        url={ORG_CONSOLE_PROJECTS}
        text="Projects"
        testId="topNavOrgLinkProjects"
        disabled={disableProjects}
        imgSrc={ICON_PROJECTS}
        showPro
      />

      <AppLink
        url={ORG_CONSOLE_SKILLS}
        text="Skills"
        testId="topNavOrgLinkSkills"
        disabled={disableSkills}
        imgSrc={ICON_SKILLS}
        imgSize={25}
      />
    </div>
  );
};

const AppLink = ({
  disabled,
  url,
  imgSrc,
  imgSize,
  text,
  testId,
  showPro = false
}) => {
  return (
    <Link
      to={url}
      className={`d-block p-3 position-relative ${disabled ? 'pointer-events-none' : ''}`}
      data-testid={testId}
      disabled={disabled}
    >
      <span
        className={`d-flex align-items-center ${disabled ? 'opacity-50' : ''}`}
      >
        <AppsIcon
          src={imgSrc}
          name={text}
          className="bg-white border"
          background
          imgSize={imgSize}
        />
        <span className="text-nowrap small">{text}</span>
      </span>

      {disabled && showPro && (
        <span
          className="small position-absolute"
          style={{
            top: '35%',
            right: '10px'
          }}
        >
          <span
            className="badge text-white"
            style={{ backgroundColor: 'rgb(129,41,217)' }}
          >
            Pro
          </span>
        </span>
      )}
    </Link>
  );
};

export default MoreApps;
