import { SET_CURRENT_CLASS, RESET_CURRENT_CLASS } from '../../actions/types';

const initialState = {
  list: null, // [{}] "Categories"
  fetched: false
};

export default function courseTopics(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLASS: // TODO change to SET_TOPICS
      return {
        ...state,
        list: action.courseTopics,
        fetched: true
      };
    case RESET_CURRENT_CLASS: // TODO change to RESET_TOPICS
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
