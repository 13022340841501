import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import {
  INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
  // INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_UNION,
  INVITE_TYPE_EMPLOYER_PROFILE_UPDATE
} from '@apprentage/constants';
import {
  createFirebaseEntry,
  getFirebaseEntriesNew,
  getFirebaseEntry,
  updateFirebaseEntry
} from './firebaseProxy';

export const fetchEmployerProfile = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection: 'employerProfiles', id })
      .then((response) => {
        const employerProfile = response || null;

        resolve(employerProfile);
      })
      .catch((error) => {
        console.error('fetch employerProfile', error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} employerProfileId
 * @param {object} data
 */
export const updateEmployerProfile = (data, employerProfileId) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection: 'employerProfiles',
      data,
      id: employerProfileId
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error('update employerProfile', error);
      });
  });
};

export const createEmployerProfile = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection: 'employerProfiles' })
      .then((response) => {
        const employerProfileId = pathOr(null, ['docId'], response);

        resolve(employerProfileId);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error('update employerProfile', error);
      });
  });
};

/**
 * @param {Array} ids
 */
export const fetchEmployerProfiles = ({ ids }) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntriesNew({
      collection: 'employerProfiles',
      ids: ids.join(',')
    })
      .then(({ items }) => {
        const list = items.length ? items : null;

        resolve(list);
      })
      .catch((error) => {
        console.error('fetch employerProfiles', error);
        reject(error);
      });
  });
};

/**
 * @param {Array} ids
 * @param {String} locationId
 * @param {String} apprenticeshipId
 * @param {String} employerProfileId
 */
export const fetchWageSchedules = ({
  ids = [],
  locationId,
  apprenticeshipId,
  employerProfileId
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    // Training Program: Workforce
    if (locationId) {
      config['f.locationId[eq]'] = locationId;
    }

    if (employerProfileId) {
      config['f.employerProfileId[eq]'] = employerProfileId;
    }

    if (apprenticeshipId) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew({
      collection: 'wageSchedules',
      ...config
    })
      .then(({ items }) => {
        const list = items.length ? items : null;

        resolve(list);
      })
      .catch((error) => {
        console.error('fetch wageSchedules', error);
        reject(error);
      });
  });
};

export const fetchWageSchedule = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection: 'wageSchedules', id })
      .then((response) => {
        const wageSchedule = response || null;

        resolve(wageSchedule);
      })
      .catch((error) => {
        console.error('fetch employerProfile', error);
        reject(error);
      });
  });
};

export const createWageSchedule = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection: 'wageSchedules' })
      .then((response) => {
        const wageScheduleId = pathOr(null, ['docId'], response);

        resolve(wageScheduleId);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error('update employerProfile', error);
      });
  });
};

/**
 *
 * @param {string} employerProfileId
 * @param {object} data
 */
export const updatedWageSchedule = (data, wageScheduleId) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection: 'wageSchedules',
      data,
      id: wageScheduleId
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error('update employerProfile', error);
      });
  });
};

export const hasRequiredWorkforceInformation = (data) => {
  // if (data?.ein === '') {
  //   return false;
  // }

  if (data?.totalEmployees === undefined || data?.totalEmployees === '') {
    return false;
  }

  if (
    data?.totalEmployeesFemale === undefined ||
    data?.totalEmployeesFemale === ''
  ) {
    return false;
  }

  if (
    data?.totalEmployeesColor === undefined ||
    data?.totalEmployeesColor === ''
  ) {
    return false;
  }

  if (
    data?.totalJourneyWorkers === undefined ||
    data?.totalJourneyWorkers === ''
  ) {
    return false;
  }

  if (
    data?.totalJourneyWorkersFemale === undefined ||
    data?.totalJourneyWorkersFemale === ''
  ) {
    return false;
  }

  if (
    data?.totalJourneyWorkersColor === undefined ||
    data?.totalJourneyWorkersColor === ''
  ) {
    return false;
  }

  return true;
};

export const employerInviteStatuses = ({
  invitesList = [],
  inviteHistory = []
}) => {
  const inviteStatuses = {
    [INVITE_TYPE_EMPLOYER_PROFILE_UPDATE]: false,
    [INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT]: false
    // [INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_UNION]: false
  };

  const allInvites = [...invitesList, ...inviteHistory];

  if (!allInvites.length) {
    return inviteStatuses;
  }

  allInvites.forEach((invite) => {
    inviteStatuses[invite.inviteType] = true;
  });

  return inviteStatuses;
};

export const findEmployerAgreement = ({
  list = [],
  employerProfileId,
  apprenticeshipId
}) => {
  let employerAgreement;

  if (list && Array.isArray(list) && list.length) {
    employerAgreement = list.find(
      (eA) =>
        eA?.employerProfileId === employerProfileId &&
        eA.apprenticeshipId === apprenticeshipId
    );
  }

  return employerAgreement;
};
