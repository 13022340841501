import React from 'react';
import AssistantBadge from '../../../Assistants/AssistantBadge';

const AssistantCell = ({ row }) => {
  if (!row?.original?.integration?.id) {
    return null;
  }

  return (
    <AssistantBadge
      agentImage={row?.original?.integration?.agentImage}
      name={row?.original?.integration?.name}
      colorBtnBkg={row?.original?.integration?.colorBtnBkg}
    />
  );
};

export default AssistantCell;
