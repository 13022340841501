import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { withAuthorization } from '../../components/Session';
import { CONTEXT_DASHBOARD, SLASH } from '../../constants/routes';
import { canAccessContext } from '../../permissions/access';
import withOrgConsole from '../../components/App/withOrgConsole';
import './style.css';

const ContextRoute = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];

  if (!canAccessContext(role)) {
    return <Redirect to={SLASH} />;
  }

  return <Redirect to={CONTEXT_DASHBOARD} />;
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  ContextRoute,
  true
);
