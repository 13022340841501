import React from 'react';

const EmbedUrlsCell = ({ value: embedUrls }) => {
  if (Array.isArray(embedUrls) && embedUrls.length > 0) {
    return (
      <div className="d-flex flex-wrap align-items-center">
        {embedUrls.map((url, i) => (
          <div
            key={`embedUrl-${i}`}
            className="rounded bg-light border mr-2 px-2 py-1 mt-2"
          >
            {url}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default EmbedUrlsCell;
