import { toast } from 'react-toastify';
import removeObjectFromArray from '../utils/removeObjectFromArray';
import sortArrByArr from '../utils/sortArrByArr';
import {
  getClient,
  getClientManagement,
  CSPACE,
  flattenItems,
  referenceType
} from './contentful';
import { addTopicToClass } from './courses';
import { createEntry, updateEntry } from './entry';
import formatData from './formatData';

export const fetchTopics = ({ classId, topicIds }) => {
  const config = {
    content_type: 'topic',
    include: 1,
    'fields.classId': classId
  };

  return new Promise((resolve, reject) => {
    getClient()
      .getEntries(config)
      .then(({ items, total }) => {
        const courseTopics = items.length ? flattenItems(items) : null;
        const sortedTopics =
          courseTopics && topicIds
            ? sortArrByArr(courseTopics, topicIds, 'id')
            : null;

        resolve({
          topics: sortedTopics,
          total
        });
      })
      .catch((error) => {
        console.error('getGroups', error);
        reject(error);
      });
  });
};

export const createMaterialInTopic = ({ data, topicId }) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(
      {
        title: data.title.value,
        body: data.body,
        enableNewEditor: data.enableNewEditor
      },
      'material'
    );

    createEntry({
      contentType: 'material',
      data: preppedData
    })
      .then((entry) => {
        addEntriesToTopic({
          entryIds: entry?.sys?.id ? [entry.sys.id] : null,
          contentType: 'material',
          topicId
        }).then(() => {
          resolve();
        });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

const defaultMaterial = {
  title: { value: 'Overview' },
  body: 'A brief overview of the Material, Challenges & Quizzes contained in this Topic.',
  enableNewEditor: true
};

export const createTopicInClass = ({ data }) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'topic');

    // Create Topic
    createEntry({
      contentType: 'topic',
      data: preppedData
    })
      .then((newTopic) => {
        toast.info('Adding topic to Course');

        // Add Topic to Class
        addTopicToClass({
          topicId: newTopic.sys.id,
          classId: data.classId
        })
          .then(() => {
            toast.info('Saving Course');

            // Create Material "Overview" and
            // Add to Topic sections reference.
            createMaterialInTopic({
              data: defaultMaterial,
              topicId: newTopic.sys.id
            })
              .then(() => {
                resolve(newTopic);
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const addEntriesToTopic = ({ entryIds, topicId, contentType }) => {
  return new Promise((resolve, reject) => {
    // Update entry
    return getClientManagement()
      .getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(topicId))
      .then((entry) => {
        // sections & sectionIds
        if (entry.fields.sections === undefined) {
          entry.fields.sections = { 'en-US': [] };
          entry.fields.sectionIds = { 'en-US': [] };
        }

        entry.fields.sections = {
          // TODO topic flatten
          'en-US': [...entry.fields.sections['en-US']]
        };

        entry.fields.sectionIds = {
          'en-US': [...entry.fields.sectionIds['en-US']]
        };

        entryIds.forEach((entryId) => {
          entry.fields.sections['en-US'].push(referenceType(entryId));
          entry.fields.sectionIds['en-US'].push(entryId);
        });

        // challenge
        if (contentType === 'challenge') {
          if (entry.fields.challenges === undefined) {
            entry.fields.challenges = { 'en-US': [] };
          }

          entry.fields.challenges = {
            // TODO topic flatten
            'en-US': [...entry.fields.challenges['en-US']]
          };

          entryIds.forEach((entryId) => {
            entry.fields.challenges['en-US'].push(referenceType(entryId));
          });
        }

        // quiz
        if (contentType === 'quiz') {
          if (entry.fields.quizes === undefined) {
            // TODO topic flatten
            entry.fields.quizes = { 'en-US': [] };
          }

          entry.fields.quizes = {
            'en-US': [...entry.fields.quizes['en-US']]
          };

          entryIds.forEach((entryId) => {
            entry.fields.quizes['en-US'].push(referenceType(entryId));
          });
        }

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeEntryFromTopic = ({ entryId, topicId, contentType }) => {
  return new Promise((resolve, reject) => {
    // Update entry
    return getClientManagement()
      .getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(topicId))
      .then((entry) => {
        const topicSections = entry.fields.sections['en-US'];
        const sections = removeObjectFromArray(
          topicSections,
          'sys.id',
          entryId
        );

        entry.fields.sections = {
          // TODO topic flatten
          'en-US': sections
        };

        if (contentType === 'challenge') {
          const topicChallenges = entry.fields.challenges['en-US'];
          const updatedChallenges = removeObjectFromArray(
            topicChallenges,
            'sys.id',
            entryId
          );

          entry.fields.challenges = {
            // TODO topic flatten
            'en-US': updatedChallenges
          };
        }

        if (contentType === 'quiz') {
          const topicQuizzes = entry.fields.quizes['en-US'];
          const quizzes = removeObjectFromArray(
            topicQuizzes,
            'sys.id',
            entryId
          );

          entry.fields.quizes = {
            // TODO topic flatten
            'en-US': quizzes
          };
        }

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        setTimeout(() => {
          resolve(entry);
        }, 250);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCurrentTopic = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'topic');

    updateEntry(preppedData, data?.topicId)
      .then((updatedTopic) => {
        setTimeout(() => {
          resolve(updatedTopic);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
