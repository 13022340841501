import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { v4 as uuid4, v5 as uuid5 } from 'uuid';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  findClassificationIdsToDelete,
  updateApprenticeship
} from '../../../services/apprenticeships';
import findObjectIndexByKey from '../../../utils/findObjectIndexByKey';
import { getApprenticeship } from '../../../actions/Apprenticeships';
import { legacyCreateCompletion } from '../../../services/openAi';
import Modal from '../../Modal';
import Collapse from '../../ManageContent/Collapse';
import FieldTextArea from '../../ManageContent/FieldTextarea';
import ClassificationTags from '../../OrgConsole/Logbook/ClassificationTags';
import KeyValueItem from '../../KeyValueItem';
import Loading from '../../Loading';

const ManageCompetency = () => {
  const dispatch = useDispatch();
  // redux
  const currentModal = useSelector((state) => state.currentModal);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // misc
  const visible = pathOr(null, ['visible'], currentModal);
  const key = pathOr(null, ['key'], currentModal);
  const modalTitle = pathOr('Competency', ['data', 'modalTitle'], currentModal);
  const competency = pathOr({}, ['data', 'competency'], currentModal);
  const competencyGroupId = pathOr(
    null,
    ['data', 'competencyGroupId'],
    currentModal
  );
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);
  const competencyGroups = pathOr([], ['competencyGroups'], apprenticeship);
  const competencies = pathOr({}, ['competencies'], apprenticeship);
  const classifications = pathOr({}, ['classifications'], apprenticeship);
  const classificationIds = pathOr([], ['classificationIds'], competency);
  // local state
  const [loading, setLoading] = useState(false);
  const [loadingClassifications, setLoadingClassifications] = useState(false);
  const [loadingClassificationsText, setLoadingClassificationsText] =
    useState('Analyzing...');
  const [description, setDescription] = useState(competency?.description || '');
  const [totalHours, setTotalHours] = useState(competency?.totalHours || 0);

  const competencyNameSpace = useMemo(() => {
    return apprenticeship?.competencyNameSpace || uuid4();
  }, [apprenticeship?.competencyNameSpace]);

  if (!visible) return null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const classifyCompetency = async () => {
    setLoadingClassifications(true);

    setTimeout(() => {
      setLoadingClassificationsText('Classifying...');
    }, 1000);

    let sanitizedDescription = description.trim();

    if (
      sanitizedDescription.substring(sanitizedDescription.length - 1) === '.'
    ) {
      sanitizedDescription = sanitizedDescription.substring(
        0,
        sanitizedDescription.length - 1
      );
    }

    const stopChar = '\n';
    let prompt =
      'Analyze the CONTEXT, determine what Competency Categories of an Apprenticeship would be the most relevant. Write five Competency Categories (keywords) based on the CONTEXT.';
    prompt += stopChar;
    // Human input
    prompt += `CONTEXT: ${sanitizedDescription}`;
    prompt += stopChar; // 2
    prompt +=
      'Return only plain text in CSV format with no spaces after the commas.';
    prompt += stopChar;

    const response = await legacyCreateCompletion({
      prompt
    });

    if (response?.data && typeof response?.data === 'string') {
      let sanitizedChoices = response.data;

      if (sanitizedChoices.substring(sanitizedChoices.length - 1) === '.') {
        sanitizedChoices = sanitizedChoices.substring(
          0,
          sanitizedChoices.length - 1
        );
      }

      if (/\r|\n/.exec(sanitizedChoices)) {
        sanitizedChoices = sanitizedChoices.replace(/\n/g, ' ');
      }

      if (sanitizedChoices && sanitizedChoices.includes(',')) {
        return sanitizedChoices.split(',');
      }
    }

    return [];
  };

  const save = (dataToSave, dataChildToSave) => {
    dataToSave.competencies[dataChildToSave.id] = dataChildToSave;

    updateApprenticeship(dataToSave, apprenticeshipId)
      .then(() => {
        dispatch(getApprenticeship(apprenticeship?.id));
        toast.success('Competency saved!');
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const classifyAndSave = (dataToSave, dataChildToSave) => {
    classifyCompetency()
      .then((response) => {
        // Create AI classifications based on description
        if (response.length) {
          dataChildToSave.classificationIds =
            competency?.classificationIds || {};

          response.forEach((classification) => {
            // Remove any extra spaces
            const classificationSanitized = classification.trim();
            // Create unique ID based on classification text using UUID v5
            // This prevents duplicate Classifications from being added
            const id = uuid5(classificationSanitized, competencyNameSpace);
            // Save Classification on apprenticeship
            dataToSave.classifications[id] = classificationSanitized;
            // Save Classification IDs on Competency
            dataChildToSave.classificationIds[id] = true;
          });
        }

        save(dataToSave, dataChildToSave);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // Construction data to store on apprenticeship
    const dataToSave = {
      // Create UUID Name Space on Training Program to use when making UUIDs from Competency Strings
      competencyNameSpace,
      competencyGroups,
      competencies,
      classifications
    };

    // Construct competency data to save to competencyGroups
    const dataChildToSave = {
      id: competency?.id || uuid4(),
      description,
      totalHours: parseInt(totalHours, 10)
    };

    // Get Competency Group Index
    const cgI = findObjectIndexByKey(competencyGroups, 'id', competencyGroupId);
    const competencyIds = pathOr(
      [],
      ['competencyIds'],
      dataToSave.competencyGroups[cgI]
    );

    /**
     *
     * NEW COMPETENCY
     *
     */

    if (!competency?.id) {
      // Include the newly generated ID in the competency group.
      dataToSave.competencyGroups[cgI].competencyIds = [
        ...competencyIds,
        dataChildToSave?.id
      ];

      classifyAndSave(dataToSave, dataChildToSave);

      return;
    }

    /**
     *
     * EDIT COMPETENCY
     *
     * Existing Competency with classificationIds
     * Description was changed
     *
     */

    if (competency.id && competency.description !== description) {
      if (
        competency.classificationIds &&
        Object.keys(competency.classificationIds).length
      ) {
        const classificationIdsToDelete = findClassificationIdsToDelete({
          classificationIds: competency.classificationIds,
          competencyId: competency.id,
          competencies: apprenticeship.competencies
        });

        // Remove active competency classifications
        // This is safe because we will generate new ones
        delete dataToSave.competencies[competency.id].classificationIds;

        // Delete apprenticeship classifications based on ID
        classificationIdsToDelete.forEach((classificationId) => {
          delete dataToSave.classifications[classificationId];
        });
      }

      classifyAndSave(dataToSave, dataChildToSave);

      return;
    }

    /**
     *
     * EDIT COMPETENCY
     *
     * Existing Competency with classificationIds
     * Description was NOT changed
     *
     */

    if (competency.id) {
      // 2. EDIT (description unchanged)
      save(dataToSave, dataChildToSave);
    }
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={visible}
      close={false}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark d-flex align-items-center justify-content-between">
          <h1 className="h5 m-0 text-white">{modalTitle}</h1>
          <button
            title="Close"
            aria-label="Close"
            type="button"
            className="close text-white"
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </div>

        <form
          onSubmit={onSubmit}
          className="position-relative"
        >
          <div className="card-body">
            <div>
              <div className="font-weight-bold mb-1">Description:</div>
              <FieldTextArea
                value={description}
                maxCharCount={400}
                autoFocus
                onChange={(newBody) => {
                  setDescription(newBody);
                }}
              />
            </div>

            <div className="mb-4">
              <div className="font-weight-bold mb-1">Total Hours</div>
              <input
                className="form-control"
                type="number"
                required
                min={1}
                value={totalHours}
                onChange={(e) => {
                  const { valueAsNumber } = e.currentTarget;

                  setTotalHours(valueAsNumber);
                }}
              />
            </div>

            {classificationIds.length !== 0 && (
              <Collapse
                className=""
                title="Classifications"
                id="competencyClassifications"
                badge={() => {
                  return (
                    <span className="h6 m-0 ml-2">
                      <span className="badge badge-info">
                        <i
                          className="fa fa-bolt"
                          style={{ fontSize: '.7rem ' }}
                          aria-hidden="true"
                        />{' '}
                        Auto-Generated
                      </span>
                    </span>
                  );
                }}
              >
                <ClassificationTags data={classificationIds} />
              </Collapse>
            )}
          </div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            {competency?.id && (
              <div>
                <KeyValueItem
                  title="ID"
                  value={competency?.id}
                  className="small d-block"
                />
                <KeyValueItem
                  title="GroupId"
                  value={competencyGroupId}
                  className="small d-block"
                />
              </div>
            )}
          </div>

          {loadingClassifications && (
            <Loading
              text={loadingClassificationsText}
              style={{ height: '100%' }}
            />
          )}
        </form>
      </div>
    </Modal>
  );
};

export default ManageCompetency;
