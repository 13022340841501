import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import {
  INVITE_TYPE_REGISTRATION,
  ROLE_PROGRAM_MANAGER,
  ROLE_PROGRAM_TRAINER,
  ROLE_STUDENT
} from '@apprentage/constants';
import { FaPaperPlane } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { resetUsers } from '../../../../../actions/Users';
import { getEmployers } from '../../../../../actions/Employers';
import {
  canInviteProgramManagers,
  canInviteProgramUsers
} from '../../../../../permissions/invite';
import { canAccessAllLocations } from '../../../../../permissions/access';
import { getInvites, resetInvites } from '../../../../../actions/Invites';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import Loading from '../../../../Loading';
import Tabs from '../Tabs';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import DotMenu from '../DotMenu';
import UsersTabs from './UsersTabs';
import InvitesList from '../../../../lists/InvitesList';
import UserInvitationButton from '../../../../btns/UserInvitationButton';

const ApprenticeshipUserInvitations = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const invites = useSelector((state) => state.invites);
  //  Current User
  const role = currentUser?.role || [];
  //  Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || '';
  const employerIds = apprenticeship?.employerIds || [];
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId)).then((response) => {
        const config = {
          orgId,
          ...(response?.employerIds
            ? { employerIds: response.locationIds }
            : {})
        };

        dispatch(getEmployers(config));
      });

      dispatch(
        getInvites({
          apprenticeshipId,
          inviteType: INVITE_TYPE_REGISTRATION,
          ...(canAccessAllLocations(role)
            ? {}
            : { locationId: currentUser?.locationId })
        })
      );
    }

    return function cleanup() {
      const { href } = window.location;

      dispatch(resetUsers()); // TODO does this need logic like logic below?
      dispatch(resetInvites());

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId]);

  const hiddenColumns = useMemo(() => {
    const result = ['inviteType', 'title'];

    if (!canAccessAllLocations(role)) {
      result.push('locationId');
    }

    return result;
  }, [role]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program Users" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="users" />

          <div className="bg-white shadow overflow-content border-left border-right border-bottom">
            <div className="p-3">
              <OrgConsoleSubheader
                pageTitle="Invitations"
                className="mb-3"
              />

              <UsersTabs active="invites" />

              <div className="border-left border-right border-bottom">
                <InvitesList
                  list={invites.list}
                  hiddenColumns={hiddenColumns}
                />
              </div>

              {canInviteProgramUsers(role) && (
                <div className="pt-3 w-100 text-center">
                  <UserInvitationButton
                    // className="dropdown-item border-bottom py-2"
                    inviteType={INVITE_TYPE_REGISTRATION}
                    locationId={
                      canAccessAllLocations(role)
                        ? undefined
                        : currentUser?.locationId
                    }
                    allowedRoles={[
                      ROLE_STUDENT,
                      ROLE_PROGRAM_TRAINER,
                      ...(canInviteProgramManagers(role)
                        ? [ROLE_PROGRAM_MANAGER]
                        : [])
                    ]}
                    includeName
                    includeRole
                    modalTitle="Invite Users"
                    disabled={!employerIds.length}
                  >
                    <span className="d-flex align-items-center">
                      <FaPaperPlane />
                      <span className="ml-2">Invite User</span>
                    </span>
                  </UserInvitationButton>
                </div>
              )}
            </div>
            <div className="card-footer d-flex justify-content-center">
              {apprenticeship?.employerIds ? (
                <span>Invite users to this training program.</span>
              ) : (
                <span className="d-flex align-items-center">
                  <FaTriangleExclamation className="text-danger" />
                  <span className="ml-">
                    To invite users, at least one Employer must be selected.
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUserInvitations);
