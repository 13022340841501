import generateHashFromArray from '../../../utils/generateHashFromArray';
import {
  CREATE_USER_GROUP,
  RESET_USER_GROUPS,
  SET_USER_GROUPS
} from '../../../actions/types';

const initialState = {
  list: null,
  hash: null,
  fetched: false
};

export default function userGroups(state = initialState, action) {
  switch (action.type) {
    case SET_USER_GROUPS:
      return {
        ...state,
        list: action.list,
        hash: generateHashFromArray(action.list),
        fetched: true
      };
    case CREATE_USER_GROUP:
      return {
        ...state,
        list: action.list,
        hash: generateHashFromArray(action.list)
      };
    case RESET_USER_GROUPS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
