import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CalendarDatePicker from '@apprentage/components/dist/components/CalendarDatePicker';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import {
  reactSelectDefaultValues,
  reactSelectOptionsFromEnum,
  roleReadable
} from '@apprentage/utils';
import { FaBell, FaInfoCircle, FaThumbtack, FaTrash } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';
import * as aws from '../../../services/aws';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  updateNotification,
  deleteNotification
} from '../../../services/notifications';
import {
  getUserRole,
  canPostNotificationAsOrganization,
  canViewAllRoleSpecificNotifications
} from '../../../services/currentUser';
import { userRolesEnum } from '../../../services/users';
import {
  getNotifications,
  resetNotificationFiles
} from '../../../actions/Notifications';
import { getResourcesByParentId } from '../../../actions/Resources';
import { NOTIFICATIONS_PAGINATION_LIMIT } from '../../../constants/api';
import Modal from '../../Modal';
import UserRoleDefinitions from '../../UserRoleDefinitions';
import Alert from '../../Alert';
import RenderTinyMceEditor from '../../ManageContent/Wysiwyg/RenderTinyMceEditor';
import CdnFiles from '../../ManageContent/CdnFiles';
import Switch from '../../Switch';
import DotMenu from './DotMenu';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import Tabs from './Tabs';
import { deleteResource } from '../../../services/resources';

const ManageNotification = () => {
  const dispatch = useDispatch();
  const messageRef = useRef(null);
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const notification = useSelector((state) => state.notification);
  const notificationsOrg = useSelector((state) => state.notificationsOrg);
  const notificationsCourse = useSelector((state) => state.notificationsCourse);
  const course = useSelector((state) => state.course);
  // Modal
  const data = currentModal?.data || {};
  const isNewEntry = data?.isNewEntry || false;
  const listConfig = data?.listConfig || {};
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  const orgName = organization?.name || '';
  // Notification
  const contentId = data?.notification?.id || '';
  const notificationType = data?.notificationType || 'organization';
  const isDraft = data?.notification?.isDraft || null;
  // Current User
  const role = currentUser?.role || [];
  // Course
  const classId = course?.id || null;
  const classTitle = course?.title || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('settings');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [createdAt, setCreatedAt] = useState(
    data?.notification?.createdAt || new Date().toISOString()
  );
  const [postAsOrg, setPostAsOrg] = useState(
    data?.notification?.postAsOrg || null
  );
  const [userRoles, setUserRoles] = useState(
    data?.notification?.userRoles || null
  );
  const [isPinned, setIsPinned] = useState(
    data?.notification?.isPinned || false
  );
  const [visibleToAllUsers, setVisibleToAllUsers] = useState(
    !data?.notification?.userRoles || null
  );
  // User Roles
  const userRolesOptions = reactSelectOptionsFromEnum(
    userRolesEnum({ orgType, enableWorkforceSuite })
  );
  const userRolesDefaultValues = reactSelectDefaultValues(
    userRoles,
    userRolesOptions
  );

  useEffect(() => {
    if (contentId) {
      dispatch(getResourcesByParentId(contentId, 'notifications'));
    }
  }, [contentId, dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetNotificationFiles());
    };
  }, [dispatch]);

  const hiddenColumns = useMemo(() => {
    const columns = [
      'parentId',
      'body',
      'expander',
      'similarity',
      'isEmbedded'
    ];

    return columns;
  }, []);

  const hasFiles = useMemo(() => {
    return (
      Array.isArray(notification?.cdnFiles) && notification?.cdnFiles.length > 0
    );
  }, [notification?.cdnFiles]);

  const deleteSubtitle = useMemo(() => {
    let subtitle = 'This action cannot be undone.';

    if (hasFiles) {
      subtitle +=
        ' All files attached to this notification will be deleted as well.';
    }

    return subtitle;
  }, [hasFiles]);

  const refreshNotifications = (page) => {
    const config = {
      isDraft: listConfig?.isDraft || null,
      isPinned: listConfig?.isPinned || null,
      orgId,
      includePinned: true,
      classId: notificationType === 'course' && classId ? classId : null,
      // Set page number when creating to show it at the top of the list
      // Use page from pagination when editing a notification on a page other than 1
      // Fallback to 1
      page: page || notificationsOrg?.pagination?.page || 1,
      limit:
        notificationsOrg?.pagination?.limit || NOTIFICATIONS_PAGINATION_LIMIT
    };

    dispatch(getNotifications(config));
  };

  const onClickSave = () => {
    const dataToSave = {
      isDraft: null,
      postAsOrg: postAsOrg || null,
      isPinned: isPinned || null,
      userRoles:
        Array.isArray(userRoles) && userRoles.length > 0 ? userRoles : null,
      createdAt
    };

    if (messageRef && messageRef.current && messageRef.current.isDirty) {
      dataToSave.message = messageRef.current.getContent();
    }

    if (!dataToSave.message || dataToSave.message.trim() === '') {
      toast.error('A notification can not be empty.');
      return;
    }

    setLoading(true);

    toast.info(`${isNewEntry ? 'Updating' : 'Posting'} Notification...`, {
      toastId: 'notificationInfo'
    });

    updateNotification(dataToSave, contentId).then(() => {
      toast.dismiss('notificationInfo');
      toast.success(`Notification ${isNewEntry ? 'posted' : 'updated'}!`);
      // User is on a page greater than 1 and posts a new notification
      // Set page to 1 so User sees the new notification they posted
      refreshNotifications(isNewEntry ? 1 : undefined);
      dispatch(resetCurrentModal());
    });
  };

  const showRoleSpecificAlert = () => {
    if (canViewAllRoleSpecificNotifications(role)) {
      // User can view all notification regardless of role
      return false;
    }

    if (!course?.id) {
      return false;
    }

    if (Array.isArray(userRoles)) {
      // userRoles is not null/undefined

      if (userRoles.length === 0) {
        // User chose not visible to everyone
        // User didn't choose any roles
        // Upon save, this notification will be visible to everyone
        return false;
      }

      if (userRoles.includes(getUserRole({ role }))) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        theme="dark"
      >
        <div className="card">
          <div className="card-header p-3 bg-dark text-white">
            <h6 className="m-0 text-capitalize">
              <span className="d-flex align-items-center">
                <FaBell />
                <span className="ml-2 font-weight-bold">
                  {isNewEntry ? 'Post' : 'Edit'} Notification
                </span>
              </span>
            </h6>
          </div>

          <Tabs
            cardStyle
            hasFiles={hasFiles}
            className="pt-3"
            activeTab={activeTab}
            setActiveTab={(tab) => {
              setActiveTab(tab);
            }}
          />

          {activeTab === 'settings' && (
            <div className="card-body">
              <div className="mb-3 d-flex align-items-center">
                <span className="mr-2 font-weight-bold">Current Status:</span>
                {data?.notification?.isDraft ? (
                  <span className="h6 m-0">
                    <span
                      className="badge badge-warning"
                      style={{
                        padding: '0.3rem 0.5rem'
                      }}
                    >
                      Draft
                    </span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center">
                    <span className="h6 m-0">
                      <span
                        className="badge badge-success mr-2"
                        style={{
                          padding: '0.3rem 0.5rem'
                        }}
                      >
                        Published
                      </span>
                    </span>
                    <DotMenu
                      data={data?.notification}
                      notificationType={notificationType}
                    />
                  </span>
                )}
              </div>

              {canPostNotificationAsOrganization(role) && (
                <Switch
                  id="postAsOrg"
                  className="mb-3"
                  label={`Post as ${orgName}`}
                  value={postAsOrg}
                  onChange={() => {
                    setPostAsOrg(!postAsOrg);
                  }}
                />
              )}

              <div className="mb-3">
                <label
                  htmlFor="createdAt"
                  className="d-flex align-items-center"
                >
                  <span className="font-weight-bold">Date:</span>
                  <span className="text-danger ml-1">*</span>
                </label>
                <CalendarDatePicker
                  selected={createdAt ? new Date(createdAt) : null}
                  id="createdAt"
                  name="createdAt"
                  className="form-control"
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => {
                    setCreatedAt(date ? date.toISOString() : '');
                  }}
                  dropdownMode
                  required
                />
              </div>

              <RenderTinyMceEditor
                id="notification-message"
                contentId={contentId}
                contentType="notifications"
                className="mb-3"
                title="Message"
                defaultValue={data?.notification?.message || ''}
                editorRef={messageRef}
                uploadPdfButton={false}
                embedMediaButton={false}
                autoFocus
                required
              />

              <div
                id="group-user-access"
                className="form-group mb-3"
                style={{
                  display: 'none'
                }}
              >
                <label
                  className="mb-1"
                  htmlFor="visibleToAllUsers"
                >
                  <b>User Visibility:</b> <span className="text-danger">*</span>
                </label>
                <div className="text-muted medium mb-2">
                  Notification can be viewed by any user.
                </div>
                <Switch
                  id="visibleToAllUsers"
                  className="mb-2"
                  label="Visible to all users"
                  value={visibleToAllUsers}
                  onChange={() => {
                    if (visibleToAllUsers) {
                      setUserRoles(null);
                    }

                    setVisibleToAllUsers(!visibleToAllUsers);
                  }}
                />
              </div>

              {canPostNotificationAsOrganization(role) && (
                <div
                  className={`mb-4 ${isPinned && 'border-primary-light'}`}
                  style={{
                    borderLeft: '5px #dee2e6 solid',
                    paddingLeft: '1rem'
                  }}
                >
                  <div className="text-muted mb-2 d-flex align-items-center">
                    <FaThumbtack
                      className={
                        isPinned ? 'text-primary' : 'text-muted opacity-50'
                      }
                    />
                    <span className="ml-2">
                      Pin notification at the top of other notifications.
                    </span>
                  </div>
                  <div className="bg-light p-2 border rounded">
                    <Switch
                      id="isPinned"
                      className="bg-white"
                      label="Pinned"
                      value={isPinned}
                      onChange={() => {
                        setIsPinned(!isPinned);
                      }}
                    />
                  </div>
                </div>
              )}

              {!visibleToAllUsers && (
                <div
                  id="group-user-roles"
                  className="mb-4"
                  style={{
                    borderLeft: '5px #dee2e6 solid',
                    paddingLeft: '1rem',
                    display: 'none'
                  }}
                >
                  <label
                    className="mb-0"
                    htmlFor="userRoles"
                  >
                    <b>User Roles:</b> <span className="text-danger">*</span>
                  </label>

                  <div className="text-muted mb-2">
                    Choose all user roles that can view this notification.
                  </div>

                  <SelectMenu
                    className="user-roles-select-menu zIndex-1000"
                    id="userRoles"
                    name="userRoles"
                    placeholder="Choose Role"
                    options={userRolesOptions}
                    isMulti
                    defaultValue={userRolesDefaultValues}
                    onChange={(values) => {
                      const newValues =
                        values.length === 0
                          ? null
                          : values.map((tag) => tag.value);

                      setUserRoles(newValues);
                    }}
                  />

                  {showRoleSpecificAlert() && (
                    <Alert
                      type="warning"
                      iconComponent={() => <FaTriangleExclamation />}
                      className="mt-2 mb-0 w-100"
                    >
                      You will not be able to see this notification if{' '}
                      <b>{roleReadable(role, orgType)}s</b> is <b>NOT</b>{' '}
                      included in the list above.
                    </Alert>
                  )}

                  <UserRoleDefinitions />
                </div>
              )}

              <div>
                {notificationType === 'organization' ? (
                  <div className="d-flex align-items-center">
                    <FaInfoCircle className="text-muted" />
                    <span className="ml-1">
                      Visible to all users in <strong>{orgName}</strong>.
                    </span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <FaInfoCircle className="text-muted" />
                    <span className="ml-1">
                      Visible to users enrolled in <strong>{classTitle}</strong>
                      .
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === 'files' && contentId && hasFiles && (
            <div className="m-1">
              <CdnFiles
                id="resource-integration-files"
                contentId={contentId}
                contentType="notifications"
                className="mb-0 border-0"
                theme="dark"
                header={false}
                files={notification?.cdnFiles}
                // fileUpload
                hiddenColumns={hiddenColumns}
                // editMenu
                removeFileCallback={() => {
                  dispatch(getResourcesByParentId(contentId, 'notifications'));
                }}
              />
            </div>
          )}

          <div className="card-footer d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <button
                className={`btn ${isDraft ? 'btn-success' : 'btn-primary'} btm-sm`}
                type="button"
                onClick={onClickSave}
                disabled={loading}
              >
                {isDraft ? (
                  <span>{loading ? 'Publishing...' : 'Publish'}</span>
                ) : (
                  <span>{loading ? 'Saving...' : 'Save'}</span>
                )}
              </button>

              <button
                className="btn btn-link btm-sm ml-2"
                type="button"
                onClick={() => {
                  refreshNotifications();
                  dispatch(resetCurrentModal());
                }}
                disabled={confirmDelete || loading}
              >
                Cancel
              </button>
            </div>

            {activeTab === 'settings' && (
              <button
                className="btn-line text-danger"
                type="button"
                disabled={loading || confirmDelete}
                title="Delete Notification"
                onClick={() => {
                  setConfirmDelete(true);
                }}
              >
                <span className="d-flex align-items-center">
                  <FaTrash className="text-danger" />
                  <span className="ml-2">Delete</span>
                </span>
              </button>
            )}
          </div>
        </div>
      </Modal>

      {confirmDelete && (
        <ConfirmationToast
          title="Are you sure?"
          primaryActionBtnText="Yes, delete"
          toastType="danger"
          subtitle={deleteSubtitle}
          onConfirm={() => {
            toast.info('Processing...', {
              toastId: 'confirmDeleteNotification'
            });

            setLoading(true);
            setConfirmDelete(false);

            if (hasFiles) {
              // Delete images & resources
              // Build Promises to upload resources to Supabase
              const promisesResources = notification?.cdnFiles.map(
                async (resource) => {
                  return [
                    await deleteResource(resource?.id),
                    await aws.deleteFile({
                      url: resource?.url,
                      orgId
                    })
                  ];
                }
              );

              if (
                Array.isArray(promisesResources) &&
                promisesResources.length !== 0
              ) {
                Promise.all(promisesResources)
                  .then(() => {
                    toast.success('Notification files deleted.');
                  })
                  .catch((error) => {
                    console.error(error);
                    throw new Error(error);
                  });
              } else {
                throw new Error('Something went wrong, try again');
              }
            }

            deleteNotification(contentId).then(() => {
              toast.dismiss('confirmDeleteNotification');
              toast.success('Notification deleted');
              // Prevent showing "No notifications" a user is on
              // a page greater than 1 that shows a single entry
              // User deletes that one entry, set page to 1
              let page;
              if (listConfig?.page > 1) {
                // Course
                if (notificationType === 'course') {
                  if (
                    Array.isArray(notificationsCourse.list) &&
                    notificationsCourse.list.length === 1
                  ) {
                    page = 1;
                  }
                }
                // Organization
                if (notificationType === 'organization') {
                  if (
                    Array.isArray(notificationsOrg.list) &&
                    notificationsOrg.list.length === 1
                  ) {
                    page = 1;
                  }
                }
              }

              refreshNotifications(page);
              dispatch(resetCurrentModal());
            });
          }}
          handleCancel={() => {
            setConfirmDelete(false);
          }}
          handleClose={() => {
            setConfirmDelete(false);
          }}
        />
      )}
    </>
  );
};

export default ManageNotification;
