import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SLASH } from '../../constants/routes';
import { goToElem } from '../../services/manageContent';
import { getOrg } from '../../actions/Organizations';

const withOrgConsole = (Component, renderComponentOnly = false) => {
  const WithOrgConsole = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // Redux
    const currentUser = useSelector((state) => state.currentUser);
    const organization = useSelector((state) => state.organization);
    // User
    const currentUserOrgId = currentUser?.orgId || '';
    // Params
    const searchParams = new URLSearchParams(window.location.search);
    const scrollToId = searchParams.get('scrollToId');

    useEffect(() => {
      if (!currentUser?.id) {
        history.replace(SLASH);
      }

      dispatch(getOrg({ orgId: currentUserOrgId })).then((response) => {
        // Admin from another org logged out while on Org Console
        // upon logging into this Org, the previous orgId is still present
        // evaluate them and if they are not the same, send to /
        // TODO orgConsole fix (slug)
        // replace orgId with slug in url and get orgId from organization
        if (response?.organization?.id !== currentUserOrgId) {
          history.replace(SLASH);
          return null;
        }
      });

      // Deep link into section of Form
      // URL param ex: scrollToId=topic-instructionNotes
      if (scrollToId) {
        setTimeout(() => {
          goToElem(scrollToId);
        }, 500);
      }

      document.body.classList.add('bg-light');

      return function cleanup() {
        document.body.classList.remove('bg-light');
      };
    }, [currentUser?.id, currentUserOrgId, dispatch, history, scrollToId]);

    if (!organization?.id) {
      return null;
    }

    if (renderComponentOnly) {
      return <Component />;
    }

    return (
      <div className="h-100">
        <main role="main">
          <div className="container-fluid px-3">
            <Component />
          </div>
        </main>
      </div>
    );
  };

  return WithOrgConsole;
};

export default withOrgConsole;
