import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { parse } from 'gift-pegjs';
import { pathOr } from 'ramda';
import Loading from '../../../Loading';
import FileUploader from '../../../ManageContent/FileUploader';
import parseTrueFalse from './parseTrueFalse';
import parseChoices from './parseChoices';

const Moodle = ({ handleImportSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [accept] = useState('.txt');

  const handleInsertUploadedFile = () => {
    console.log('handleInsertUploadedFile');
  };

  const processFile = (e) => {
    const { result: file } = e.target;

    const importedQuestions = parse(file);
    // Filter out unsupported question types
    // TODO support other question types (Category, Short, etc.)
    const filteredQuestions = importedQuestions.filter(
      (q) => q.type === 'MC' || q.type === 'TF'
    );
    /**
     * Question "type"
     *
     * MC (multiple choice)
     * - https://docs.moodle.org/311/en/GIFT_format#Multiple_choice
     *
     * MC (multiple choice with multiple right answers)
     * - https://docs.moodle.org/311/en/GIFT_format#Multiple_choice_with_multiple_right_answers
     * - isCorrect will always be false
     * - weight: -100 = false
     * - weight: 50 = true (any positive number)
     *
     * TF (True/False)
     * - https://docs.moodle.org/311/en/GIFT_format#True-false
     *
     */
    const questions = filteredQuestions
      ? filteredQuestions.map((question) => {
          const { title, tags, type, choices, globalFeedback } = question;

          const QA = {
            questionId: uuid(),
            questionType: 'radio',
            title: pathOr('', ['stem', 'text'], question),
            description: '',
            ...(globalFeedback ? { hint: globalFeedback } : {}),
            ...(tags ? { tags } : {})
          };

          if (QA.tags === undefined) QA.tags = [];

          if (title) QA.tags.push(title);

          if (type === 'TF') {
            QA.answers = parseTrueFalse(question);
          }

          if (type === 'MC') {
            QA.answers = choices ? parseChoices(choices) : [];
          }

          return QA;
        })
      : null;

    return handleImportSuccess(questions);
  };

  const handleFileUploaderChange = (blob) => {
    // blob
    const reader = new FileReader();
    // Read the file
    reader.readAsText(blob);
    reader.addEventListener('load', processFile);
    // Before upload, have basic file data from upload input
    setLoading(true);
  };

  return (
    <div className="position-relative">
      <div className="mb-3">
        <img
          alt="Moodle"
          src="https://turbine-content.s3.us-east-2.amazonaws.com/moodle.jpeg"
          width="100"
        />
      </div>
      <div className="mb-3">
        <p>
          <b>Step 1:</b> Export Moodle Questions (<b>GIFT format</b>)
        </p>
        <a
          href="https://docs.moodle.org/311/en/Export_questions"
          target="_blank"
          rel="noopener noreferrer"
          title="How to Export Questions from Moodle"
        >
          How to Export Questions from Moodle
        </a>
      </div>

      <p>
        <b>Step 2:</b> Import Moodle Questions
      </p>
      <FileUploader
        hideToastOptimize
        onFileUploadCallback={handleInsertUploadedFile}
        onChange={handleFileUploaderChange}
        accept={accept || '*'}
      />

      <div className="mt-2 text-muted">
        Supported File formats: <b>GIFT</b>
      </div>

      {accept && (
        <div className="mt-2 text-muted">
          Supported File types: <b>{accept}</b>
        </div>
      )}

      {loading && (
        <Loading
          text="Analyzing data..."
          className="h-100 bg-white position-absolute"
        />
      )}
    </div>
  );
};

export default Moodle;
