import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NO_NOTIFICATIONS } from '@apprentage/constants';
import { canAccessByRole } from '../../../services/currentUser';
import { getNotifications } from '../../../actions/Notifications';
import Notification from './Notification';

const NotificationsList = ({
  list,
  listConfig,
  className = '',
  // accessControls = true,
  showMenu = false,
  showUserRoles = false,
  showNotFoundUi = true
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const notificationsOrg = useSelector((state) => state.notificationsOrg);
  const notificationsCourse = useSelector((state) => state.notificationsCourse);
  // User
  const role = currentUser?.role || [];

  const noDataText = useMemo(() => {
    if (listConfig?.isDraft) {
      return 'No draft notifications.';
    }

    if (listConfig?.isPinned) {
      return 'No pinned notifications.';
    }

    return 'No published notifications.';
  }, [listConfig?.isDraft, listConfig?.isPinned]);

  useEffect(() => {
    if (list === undefined) {
      dispatch(getNotifications(listConfig));
    }
  }, [dispatch, list, listConfig]);

  const whichNotifications = useMemo(() => {
    let results = [];
    if (list !== undefined) {
      results = Array.isArray(list) && list.length > 0 ? list : [];
    } else if (listConfig?.classId) {
      results = notificationsCourse.list;
    } else {
      results = notificationsOrg.list;
    }

    return results;
  }, [
    list,
    listConfig?.classId,
    notificationsCourse.list,
    notificationsOrg.list
  ]);

  const hasNotifications = useMemo(() => {
    return Array.isArray(whichNotifications) && whichNotifications.length > 0;
  }, [whichNotifications]);

  if (showNotFoundUi && !hasNotifications) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-auto"
        style={{
          padding: '1rem 0'
        }}
      >
        <img
          src={NO_NOTIFICATIONS}
          width="100"
          className="my-3"
          alt="No notifications"
        />
        <p className="m-0">{noDataText}</p>
      </div>
    );
  }

  // if (accessControls) {
  //   whichNotifications = whichNotifications.filter((n) => canAccessByRole(role, n.userRoles));
  // }

  // whichNotifications = whichNotifications.slice(0, limit);

  return (
    <div>
      <div className={`NotificationsList d-flex flex-column ${className}`}>
        {hasNotifications &&
          whichNotifications.map((notification, i) => {
            if (!canAccessByRole(role)) return null;

            return (
              <Notification
                key={i}
                data={notification}
                showUserRoles={showUserRoles}
                listConfig={listConfig}
                showMenu={showMenu}
                className="mb-4"
              />
            );
          })}
      </div>
    </div>
  );
};

export default NotificationsList;
