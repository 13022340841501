import React from 'react';
import styled from 'styled-components';

const SignatureFlag = ({ style = {} }) => {
  return (
    <SignatureFlagContainer style={style}>
      <SignatureFlagText>SIGN</SignatureFlagText>
      <SignatureFlagArrow />
    </SignatureFlagContainer>
  );
};

export default SignatureFlag;

const SignatureFlagContainer = styled.div`
  background-color: #fcb800;
  bottom: 0;
  min-height: 40px;
  left: 0;
  position: absolute;
  width: 22px;
`;

const SignatureFlagText = styled.div`
  font-size: 11px;
  padding: 20px 0;
  white-space: nowrap;
  transform: rotate(-90deg);

  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
`;

const SignatureFlagArrow = styled.div`
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid #fcb800;
  bottom: -11px;
  height: 0;
  position: absolute;
  width: 0;
`;
