import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import UserProfilePicture from '../UserProfilePicture';

const SearchUserInput = ({ key = '1234', theme = 'light', children }) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div
      key={`SearchUserInput-${key}`}
      className="d-flex mb-3"
    >
      <div className="d-none d-sm-flex justify-content-center mr-3">
        <MessageAvatar
          className="d-flex align-items-center justify-content-center text-white"
          style={{
            background: 'black',
            border: '1px solid #4b4b4b'
          }}
        >
          <UserProfilePicture
            src={currentUser?.profilePicture}
            alt={currentUser?.name}
          />
        </MessageAvatar>
      </div>
      <div className="w-100">
        <MessageContainer
          data-demo="disabled"
          className="m-0"
        >
          <div className="d-none d-sm-flex align-items-center justify-content-between mb-1">
            <div
              className={`font-weight-bold opacity-75 ${theme === 'dark' && 'text-white'}`}
              style={{ fontSize: '1rem' }}
            >
              {currentUser?.name}
            </div>
            <div className="d-flex align-items-center" />
          </div>
          <div className="shadow p-3 rounded bg-white position-relative">
            {children || null}
          </div>
        </MessageContainer>
      </div>
    </div>
  );
};

const MessageContainer = styled.div`
  textarea {
    font-size: 1rem;
  }

  @media (min-width: 767.98px) {
    textarea {
      font-size: 1.25rem;
    }
  }
`;

const MessageAvatar = styled.div`
  border-radius: 100%;
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
`;

export default SearchUserInput;
