import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import isPublished from '../../../utils/isPublished';
import Item from './Item';

/** *
 * @items array
 * @title string
 * @required boolean
 * @onUpdate fn, passes new items to parent after reorder
 * @helperText string
 * // Reference props
 * @handleEditItem function
 * @showBadgeOnFirstItem boolean
 * @firstItemBadgeText string
 */
class ItemsDnd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNewItem: null,
      itemIndex: null,
      itemModal: false,
      showDialogConfirmDeleteItem: false,
      ...props
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState((prevState) => ({
        ...prevState,
        items: this.props.items
      }));
    }
  }

  setModalData = ({ key, val }) => {
    // TODO is this needed?
    this.setState({
      [key]: val
    });
  };

  toggleModal = ({ key, val }) => {
    // TODO is this needed?
    this.setState({
      [key]: val
    });
  };

  saveTextField = (e) => {
    const { currentTarget } = e;
    const {
      name,
      value,
      dataset: { temp: tempDataKey }
    } = currentTarget;
    const tempData = this.state[tempDataKey];

    this.setState({
      [tempDataKey]: {
        ...tempData,
        [name]: value
      }
    });
  };

  onDragEndItems = (result) => {
    const {
      destination,
      source: { index: sourceIndex, droppableId: sourceDropId }
    } = result;

    if (!destination) return false;

    const { index: destinationIndex, droppableId: destinationDropId } =
      destination;

    if (
      destinationDropId === sourceDropId &&
      destinationIndex === sourceIndex
    ) {
      return false;
    }

    const { items } = this.state;
    const itemsNew = Array.from(items);
    const itemToMove = itemsNew.splice(sourceIndex, 1);

    itemsNew.splice(destinationIndex, 0, itemToMove[0]);

    this.setState(
      {
        items: itemsNew
      },
      () => {
        this.props.onUpdate(itemsNew); // Save to parent
      }
    );
  };

  render() {
    const { items, title, helperText } = this.state;
    const {
      showBadgeOnFirstItem,
      firstItemBadgeText,
      handleEditItem,
      handleDeleteItem // TODO show dialog first to confirm delete
    } = this.props;

    const publishedItems = items.filter((item) => isPublished(item));

    return (
      <>
        <div className="references-container">
          <div className="d-flex justify-content-between align-items-center">
            {title && (
              <>
                <label
                  htmlFor="type"
                  className="mb-2"
                >
                  <b>{title}:</b> <span className="text-danger">*</span>
                </label>
                {publishedItems && publishedItems.length > 0 && (
                  <div className="d-flex align-items-center text-muted justify-content-end">
                    <i className={`${ICON_TRIANGLE_BANG} mr-1 text-warning`} />
                    <span className="small">
                      Save changes after reordering.
                    </span>
                  </div>
                )}
              </>
            )}
          </div>

          {publishedItems && publishedItems.length > 0 && (
            <DragDropContext onDragEnd={this.onDragEndItems}>
              <Droppable droppableId="dnd-column--quiz">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="references references-manage rounded border p-1"
                  >
                    {publishedItems.map((item, i) => (
                      <Draggable
                        draggableId={`item-${i}`}
                        index={i}
                        key={i}
                      >
                        {(providedItem) => (
                          <Item
                            index={i}
                            item={item}
                            showBadgeOnFirstItem={showBadgeOnFirstItem}
                            firstItemBadgeText={firstItemBadgeText}
                            provided={providedItem}
                            innerRef={providedItem.innerRef}
                            onClickEdit={handleEditItem}
                            onClickDelete={handleDeleteItem}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>

        {helperText && (
          <div className="text-muted medium mt-2">{helperText}</div>
        )}
      </>
    );
  }
}

export default ItemsDnd;
