import { PLATFORM_NAME } from '../../constants/globals';
import { TURBINE_MARKETING_SITE } from '../../constants/urls';

const PoweredByTurbineLink = ({ trackingSlug }) => `
  <table
    border="0"
    cellpadding="0"
    cellspacing="0"
    class="text_block"
    role="presentation"
    style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;"
    width="100%"
  >
    <tbody>
      <tr>
        <td
          style="padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;"
        >
          <div
            style="font-family: sans-serif"
          >
            <div
              style="font-size: 14px; color: #555555; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;"
            >
              <p
                style="margin: 0; font-size: 14px; text-align: center;"
              >
                Powered by <a href="${TURBINE_MARKETING_SITE}?track=${trackingSlug}PoweredBy" rel="noopener" style="text-decoration: underline; color: #8e44ad;" target="_blank">${PLATFORM_NAME}</a>
              </p>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
`;

export default PoweredByTurbineLink;
