import { isStudent } from '@apprentage/utils';
import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import { TURBINE_ADMIN } from '../../../constants/urls';

const inviteExistingUserHtml = ({
  fullName,
  role,
  orgName,
  // Course Invitation
  courseInvitation,
  courseTitle,
  // Apprenticeship Invitation
  apprenticeshipId,
  apprenticeshipInvitation,
  apprenticeshipTitle,
  apprenticeshipCourses
}) => {
  let linkText = 'My Logbooks';
  let link = 'https://app.tellvela.com/logbooks';
  let invitationOpeningLine = '';
  let invitationBody = '';

  if (apprenticeshipInvitation) {
    invitationOpeningLine += `
      <p>
        You've been enrolled in <strong>${apprenticeshipTitle}</strong> sponsored by ${orgName}.
      </p>
    `;

    if (
      Array.isArray(apprenticeshipCourses) &&
      apprenticeshipCourses.length > 0
    ) {
      invitationOpeningLine += `
        <h4>
          Training Program Courses:
        </h4>
      `;

      invitationOpeningLine += '<ul>';

      apprenticeshipCourses.forEach((course) => {
        invitationOpeningLine += `
          <li style="margin-bottom: 5px">
            <a
              href="https://app.turbinelms.com/classes/${course?.id}"
              title="${course?.title}"
              target="_blank"
            >
              ${course?.title}
            </a>
          </li>
        `;
      });

      invitationOpeningLine += '</ul>';
    }

    if (isStudent(role)) {
      invitationBody += `
        <h4>
          Training Program Logbook:
        </h4>

        <p>
          Get started by creating a <strong>${apprenticeshipTitle}</strong> Logbook.
        </p>
      `;
    } else {
      invitationBody += `
        <h4>
          Trainee's Logbook Records
        </h4>

        <p>
          Get started by reviewing your Trainee's <strong>${apprenticeshipTitle}</strong> Logbook Records.
        </p>
      `;

      link = `${TURBINE_ADMIN}/org/workforce/apprenticeships/${apprenticeshipId}`;
      linkText = 'Review Logbook Records';
    }
  }

  if (courseInvitation) {
    invitationOpeningLine = `
      <p>
        You've been enrolled in ${courseTitle} in ${orgName}'s Workforce Space.
      </p>
    `;
  }

  return `
    <p>
      Hi ${fullName}!
    </p>

    ${invitationOpeningLine}

    ${invitationBody}

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: link })}
    </div>

    <p>
      Team ${orgName}
    </p>

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${link}
    </p>
  `;
};

export default inviteExistingUserHtml;
