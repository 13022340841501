import React from 'react';
import NumberCard from '../../NumberCard';
import UserCourseCompletionChart from './UserCourseCompletionChart';

const UsersInsights = ({
  courseCompletionRate,
  completedCourses,
  usersEnrolled
}) => {
  return (
    <div className="row mt-3">
      <div className="col-sm-4 mb-2">
        <UserCourseCompletionChart
          courseCompletionRate={courseCompletionRate}
          completedCourses={completedCourses}
          usersEnrolled={usersEnrolled}
        />
      </div>

      <div className="col-sm-4 mb-2">
        <NumberCard
          num={completedCourses}
          text="Users Completed Course"
        />
      </div>

      <div className="col-sm-4 mb-2">
        <NumberCard
          num={usersEnrolled}
          text="Users Enrolled"
        />
      </div>
    </div>
  );
};

export default UsersInsights;
