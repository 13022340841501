import {
  RESET_EMPLOYER_AGREEMENT,
  SET_EMPLOYER_AGREEMENT
} from '../../actions/types';
import employerAgreementState from '../initialState/employerAgreementState';

const initialState = {
  ...employerAgreementState,
  fetched: false
};

export default function employerAgreement(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER_AGREEMENT:
      return {
        ...state,
        ...action.employerAgreement,
        fetched: true
      };
    case RESET_EMPLOYER_AGREEMENT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
