import React from 'react';

const Tabs = ({
  contextQuestionsList,
  className = '',
  activeTab,
  setActiveTab,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'context' && 'active'}`}
            type="button"
            disabled={activeTab === 'context'}
            onClick={() => {
              setActiveTab('context');
            }}
          >
            <span className="d-flex align-items-center">
              <span className="mr-2">Questions</span>
              <span className="badge badge-primary text-white rounded">
                {Array.isArray(contextQuestionsList)
                  ? contextQuestionsList.length
                  : 0}
              </span>
            </span>
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'search' && 'active'}`}
            type="button"
            disabled={activeTab === 'search'}
            onClick={() => {
              setActiveTab('search');
            }}
          >
            Ask or Search
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
