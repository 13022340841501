import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import ScanningAnimation from './ScanningAnimation';
import Header from './Header';
import Switch from '../../Switch';

const JobInfo = ({
  resourceGroupTitle,
  rawText,
  showScanningAnimation,
  animationActionStep,
  hasFiles,
  hasEmbeddedFiles,
  embeddedFiles,
  //
  jobLocationRemote,
  setJobLocationRemote,
  //
  numCandidates,
  setNumCandidates,
  //
  jobTitle,
  setJobTitle,
  //
  jobLocation,
  setJobLocation,
  //
  companyDescription,
  setCompanyDescription,
  //
  jobDescription,
  setJobDescription,
  //
  responsibilities,
  setResponsibilities,
  //
  qualifications,
  setQualifications
}) => {
  const numCandidatesRef = useRef(null);
  const jobLocationRef = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgDescription = organization?.description || null;
  // Local State
  const [editNumCandidates, setEditNumCandidates] = useState(false);
  const [editJobLocation, setEditJobLocation] = useState(false);

  const numCandidatesMax = useMemo(() => {
    const defaultNumCandidatesMax = 10;
    let max = defaultNumCandidatesMax;

    if (embeddedFiles.length < defaultNumCandidatesMax) {
      max = embeddedFiles.length;
    }

    return max;
  }, [embeddedFiles.length]);

  useEffect(() => {
    if (editNumCandidates) {
      numCandidatesRef.current.focus();
    }
  }, [editNumCandidates]);

  useEffect(() => {
    if (editJobLocation) {
      jobLocationRef.current.focus();
    }
  }, [editJobLocation]);

  return (
    <div className="card-body overflow-y-scroll">
      <div className="row">
        {showScanningAnimation && (
          <ScanningAnimation
            jobTitle={jobTitle}
            rawText={rawText}
            animationActionStep={animationActionStep}
          />
        )}

        <div
          className="col-12 col-sm-6 mb-3"
          style={showScanningAnimation ? { display: 'none' } : {}}
        >
          <Header
            style={{ display: showScanningAnimation ? 'none' : 'block' }}
            numCandidates={numCandidates}
            resourceGroupTitle={resourceGroupTitle}
            hasFiles={hasFiles}
            embeddedFiles={embeddedFiles}
            hasEmbeddedFiles={hasEmbeddedFiles}
          />

          <div
            className="mb-3"
            style={showScanningAnimation ? { display: 'none' } : {}}
          >
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">1</span>
                <span className="ml-2">
                  <strong>Candidates</strong>
                </span>
              </span>
            </h5>
            <div
              className="shadow p-3 py-4 border position-relative"
              style={{ minHeight: '92px' }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <input
                  ref={numCandidatesRef}
                  type="number"
                  className="form-control form-control-md"
                  style={{
                    width: 'auto',
                    display: editNumCandidates ? 'block' : 'none'
                  }}
                  value={numCandidates}
                  min={1}
                  max={numCandidatesMax}
                  onBlur={() => {
                    setEditNumCandidates(false);
                  }}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setNumCandidates(value);
                  }}
                />

                {!editNumCandidates && (
                  <div
                    style={{ fontSize: '2rem', lineHeight: '2rem' }}
                    className="mt-2"
                  >
                    {numCandidates}
                  </div>
                )}

                {!editNumCandidates && (
                  <button
                    className="btn btn-sm btn-white position-absolute"
                    aria-label="Edit"
                    type="button"
                    style={{
                      right: '5px',
                      top: '5px'
                    }}
                    onClick={() => {
                      setEditNumCandidates(!editNumCandidates);
                    }}
                  >
                    <FaPencilAlt />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div
            className="mb-3"
            style={showScanningAnimation ? { display: 'none' } : {}}
          >
            <div className="mb-2">
              <span className="d-flex align-items-center justify-content-between">
                <span className="h5 mb-0">
                  <span className="badge bg-dark text-white">2</span>
                  <span className="ml-2">
                    <strong>Location</strong>
                  </span>
                </span>
                <Switch
                  id="descriptionAutoGen"
                  label="Fully Remote"
                  className=""
                  slim
                  value={jobLocationRemote}
                  onChange={() => {
                    setJobLocationRemote(!jobLocationRemote);
                  }}
                />
              </span>
            </div>

            {!jobLocationRemote && (
              <div
                className="shadow p-3 py-4 border position-relative"
                style={{ minHeight: '112px' }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {editJobLocation ? (
                    <textarea
                      ref={jobLocationRef}
                      type="text"
                      className="form-control form-control-md"
                      value={jobLocation}
                      style={{
                        maxHeight: '100px'
                      }}
                      onBlur={() => {
                        setEditJobLocation(false);
                      }}
                      onChange={(e) => {
                        const { value } = e.currentTarget;
                        setJobLocation(value);
                      }}
                    />
                  ) : (
                    <div
                      // style={{ fontSize: '2rem', lineHeight: '2rem' }}
                      className="mt-3"
                    >
                      {jobLocation}
                    </div>
                  )}

                  <div className="text-muted small mt-4">
                    Prioritize candidates in close proximity to this address.
                  </div>

                  {!editJobLocation && (
                    <button
                      className="btn btn-sm btn-white position-absolute"
                      aria-label="Edit"
                      type="button"
                      style={{
                        right: '5px',
                        top: '5px'
                      }}
                      onClick={() => {
                        setEditJobLocation(!editJobLocation);
                      }}
                    >
                      <FaPencilAlt />
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          <div style={showScanningAnimation ? { display: 'none' } : {}}>
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">4</span>
                <span className="ml-2 font-weight-bold">
                  Company Information
                </span>
              </span>
            </h5>
            <div className="shadow p-3 border mb-3">
              <textarea
                className="form-control mb-1"
                value={companyDescription}
                autoFocus={!orgDescription}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setCompanyDescription(value);
                }}
                style={{ minHeight: '120px' }}
              />
              <div className="text-muted small">
                A brief introduction to the company, its mission and culture.
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6">
          <div className="mb-3">
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">5</span>
                <span className="ml-2 font-weight-bold">Job Title</span>
              </span>
            </h5>
            <div className="shadow p-3 border mb-3">
              <input
                type="text"
                className="form-control mb-1"
                value={jobTitle}
                autoFocus={orgDescription}
                disabled={showScanningAnimation}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setJobTitle(value);
                }}
              />
              <div className="text-muted small">Ex: Program Assistant</div>
            </div>
          </div>

          <div className="mb-3">
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">5</span>
                <span className="ml-2 font-weight-bold">Job Description</span>
              </span>
            </h5>
            <div className="shadow p-3 border mb-3">
              <textarea
                className="form-control mb-1"
                disabled={showScanningAnimation}
                value={jobDescription}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setJobDescription(value);
                }}
                style={{ minHeight: '170px' }}
              />
              <div className="text-muted small">
                Ex: Provide support and assistance to the Division, Program
                Manager, team members, and clients to accomplish program goals
                and objectives.
              </div>
            </div>
          </div>

          <div className="mb-3">
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">6</span>
                <span className="ml-2 font-weight-bold">Responsibilities</span>
              </span>
            </h5>
            <div className="shadow p-3 border mb-3">
              <textarea
                className="form-control mb-1"
                disabled={showScanningAnimation}
                value={responsibilities}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setResponsibilities(value);
                }}
              />
              <div className="text-muted small">
                Ex: Assists Program Managers with planning and execution of
                customer programs.
              </div>
            </div>
          </div>

          <div>
            <h5>
              <span className="d-flex align-items-center">
                <span className="badge bg-dark text-white">7</span>
                <span className="ml-2 font-weight-bold">Qualifications</span>
              </span>
            </h5>
            <div className="shadow p-3 border mb-3">
              <textarea
                className="form-control mb-1"
                disabled={showScanningAnimation}
                value={qualifications}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setQualifications(value);
                }}
              />
              <div className="text-muted small">
                Ex: Three years of industry experience is required; five years
                is preferred.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobInfo;
