import React from 'react';
import { FaEllipsisH, FaEye, FaPencilAlt, FaTrash } from 'react-icons/fa';

const Item = ({
  index,
  showBadgeOnFirstItem = false,
  firstItemBadgeText = 'Showcased',
  innerRef,
  provided,
  item,
  onClickEdit,
  onClickDelete
}) => {
  const { title, name, url } = item;

  return (
    <div
      className="reference d-flex justify-content-between align-items-center border rounded"
      ref={innerRef}
      {...provided.draggableProps}
    >
      <div className="d-inline-flex align-items-center">
        <div
          className="item-drag-handle px-2"
          {...provided.dragHandleProps}
        >
          <i className="fas fa-grip-vertical" />
        </div>

        {url && (
          <img
            src={url}
            className="ml-3"
            alt={title || name}
            height="30"
            width="30"
            style={{
              height: '30px',
              width: '30px'
            }}
          />
        )}

        <span className="px-3 d-inline-flex align-items-center">
          <span>{title || name}</span>
          {showBadgeOnFirstItem && index === 0 && (
            <span className="badge border ml-2">
              <span className="d-inline-flex align-items-center">
                <FaEye
                  className="text-primary"
                  size={14}
                />
                <span className="ml-1">{firstItemBadgeText}</span>
              </span>
            </span>
          )}
        </span>
      </div>

      {(onClickEdit || onClickDelete) && (
        <div className="btn-group align-items-center px-2 py-3">
          <button
            className="btn btn-link btn-sm rounded"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Manage Menu"
            aria-label="Manage Menu"
          >
            <FaEllipsisH />
          </button>
          <div className="dropdown-menu dropdown-menu-right p-0">
            {onClickEdit && (
              <button
                className="dropdown-item border-bottom py-2 px-3"
                type="button"
                title={`Edit ${title || name}`}
                onClick={() => {
                  onClickEdit({
                    item,
                    index
                  });
                }}
              >
                <span className="d-flex align-items-center">
                  <FaPencilAlt />
                  <span className="ml-2">Edit</span>
                </span>
              </button>
            )}

            {onClickDelete && (
              <button
                className="dropdown-item py-2 px-3"
                type="button"
                title={`Delete ${title || name}`}
                onClick={() => {
                  onClickDelete({
                    item,
                    index
                  });
                }}
              >
                <span className="d-flex align-items-center">
                  <FaTrash className="text-danger" />
                  <span className="ml-2">Delete</span>
                </span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;
