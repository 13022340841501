import {
  SET_BILLING_AGREEMENT,
  RESET_BILLING_AGREEMENT
} from '../../actions/types';
import billingAgreementState from '../initialState/billingAgreementState';

const initialState = {
  ...billingAgreementState,
  fetched: false
};

export default function billingAgreement(state = initialState, action) {
  switch (action.type) {
    case SET_BILLING_AGREEMENT:
      return {
        ...state,
        ...action.billingAgreement,
        fetched: true
      };
    case RESET_BILLING_AGREEMENT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
