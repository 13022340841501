import React, { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import {
  DASHBOARD_DEFAULT_LAYOUT,
  DASHBOARD_DEFAULT_ZONES,
  DASHBOARD_MODULE_TYPE,
  LAYOUTS
} from '../../../constants/dashboard';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import {
  MODAL_KEY_DASHBOARD_MODULE_CONFIG,
  MODAL_KEY_DASHBOARD_NEW_MODULE,
  MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS
} from '../../../constants/modals';
import { getMaterial } from '../../../actions/Materials';
import { SHEET_KEY_MATERIAL } from '../../../constants/sideSheets';
import { setSideSheet } from '../../../actions/SideSheets';
import { createMaterial } from '../../../services/materials';
import { mdyDate } from '../../../utils/date';
import Module from './Module';
import DotMenu from './DotMenu';

const DraggableWidgetsDashboard = ({
  children,
  defaultZones = DASHBOARD_DEFAULT_ZONES,
  selectedLayout = DASHBOARD_DEFAULT_LAYOUT,
  moduleTypes,
  onClickSave,
  onClickCancel
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const dashboard = useSelector((state) => state.dashboard);
  // Local State
  const [zones, setZones] = useState(defaultZones);

  const idHash = useMemo(() => {
    if (dashboard?.id) {
      const idHashArr = dashboard?.id.split('-');

      return idHashArr[0];
    }

    return '';
  }, [dashboard?.id]);

  const selectSpecificModuleData = useCallback((moduleType) => {
    switch (moduleType) {
      case DASHBOARD_MODULE_TYPE.projects:
      case DASHBOARD_MODULE_TYPE.links:
      case DASHBOARD_MODULE_TYPE.embeds:
      case DASHBOARD_MODULE_TYPE.materialsList:
      case DASHBOARD_MODULE_TYPE.material:
        return true;
      default:
        return false;
    }
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const newZonesData = { ...zones };
      const zone = newZonesData[source.droppableId];
      const [removed] = zone.splice(source.index, 1);
      zone.splice(destination.index, 0, removed);

      setZones(newZonesData);
    } else {
      const sourceZone = zones[source.droppableId];
      const destZone = zones[destination.droppableId];
      const [removed] = sourceZone.splice(source.index, 1);
      destZone.splice(destination.index, 0, removed);

      setZones({ ...zones });
    }
  };

  const handleClickEditMaterial = (materialId) => {
    dispatch(getMaterial(materialId)).then(() => {
      dispatch(
        setSideSheet({
          key: SHEET_KEY_MATERIAL,
          data: {
            editMode: true,
            showTitleInternal: true,
            tinyMceEditor: {
              uploadPdfButton: false,
              embedMediaButton: true,
              uploadImgButton: true
            },
            showDelete: false
          },
          className: 'MaterialSideSheet',
          callbackPrimaryAction: (material) => {
            const materialModuleBody = document.getElementById(
              `MaterialBlock-${materialId}`
            );
            const materialBody = (material?.body || '').trim();

            if (materialModuleBody && materialBody) {
              materialModuleBody.innerHTML = materialBody;
            }
          }
        })
      );
    });
  };

  const createDashboardMaterial = (newUuid, zone, moduleType) => {
    const dataToSave = {
      title: `${mdyDate(new Date().toISOString())} Text | ${idHash}`,
      body: '<p>Text goes here...</p>',
      orgId: organization?.id,
      noteDashboardId: dashboard?.id,
      userId: currentUser?.id,
      enableNewEditor: true
    };

    createMaterial(dataToSave)
      .then((response) => {
        if (response?.id) {
          setZones((prevState) => {
            prevState[zone]?.push({
              moduleType,
              id: newUuid,
              selectedIds: [response?.id]
            });
            return { ...prevState };
          });

          setTimeout(() => {
            // Wait to make sure Material is created
            // a race condition was observed without timeout
            handleClickEditMaterial(response?.id);
          }, 250);
        } else {
          toast.error('Something went wrong, try again');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Something went wrong, try again');
      })
      .finally(() => {
        dispatch(resetCurrentModal());
      });
  };

  /** Add New Module */
  const handleAddNewModule = (zone, options = {}) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_DASHBOARD_NEW_MODULE,
        data: {
          options
        },
        callbackPrimaryAction: (moduleType) => {
          const newUuid = uuid();

          if (moduleType === DASHBOARD_MODULE_TYPE.material) {
            createDashboardMaterial(newUuid, zone, moduleType);
            return;
          }

          setZones((prevState) => {
            prevState[zone]?.push({
              moduleType,
              id: newUuid,
              ...(moduleType === DASHBOARD_MODULE_TYPE.notifications
                ? {
                    showOnlyPinned: false,
                    limit: 3
                  }
                : {
                    selectedIds: []
                  })
            });
            return { ...prevState };
          });

          dispatch(resetCurrentModal());

          setTimeout(() => {
            const editModuleBtn = document.getElementById(
              `module-settings-${newUuid}`
            );

            if (editModuleBtn) {
              editModuleBtn.click();
            }
          }, [150]);
        }
      })
    );
  };

  /** Handle Delete Module */
  const handleDeleteModule = (zoneId, arrayIndex) => {
    setZones((prevZones) => {
      prevZones[zoneId].splice(arrayIndex, 1);
      return {
        ...prevZones
      };
    });
  };

  const handleOnclickReorderMenu = (zoneId, index) => {
    const data = zones[zoneId][index];
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS,
        data: {
          moduleType: data?.moduleType,
          selectedItemsList: data?.selectedIds
        },
        callbackPrimaryAction: (reorderedData) => {
          handleReorderLinkWidgets(zoneId, index, reorderedData);
        }
      })
    );
  };

  const handleReorderLinkWidgets = (zoneId, index, reorderedLinkWidgets) => {
    setZones((prevZones) => {
      const newArr = prevZones[zoneId];
      newArr[index] = {
        ...newArr[index],
        selectedIds: [...reorderedLinkWidgets]
      };
      prevZones[zoneId] = newArr;
      return { ...prevZones };
    });
  };

  const handleSubmitEditModule = (
    zoneKey,
    arrayIndex,
    moduleData,
    moduleType
  ) => {
    setZones((prevZones) => {
      const newArr = prevZones[zoneKey];

      newArr[arrayIndex] = {
        ...newArr[arrayIndex],
        ...(moduleType === DASHBOARD_MODULE_TYPE.links
          ? {
              styling: moduleData?.styling
            }
          : {}),
        ...(moduleType === DASHBOARD_MODULE_TYPE.notifications
          ? {
              showOnlyPinned: moduleData?.showOnlyPinned,
              limit: moduleData?.limit
            }
          : {}),
        ...(selectSpecificModuleData(moduleType)
          ? { selectedIds: moduleData?.selectedIds || [] }
          : {})
      };
      prevZones[zoneKey] = newArr;
      return { ...prevZones };
    });
  };

  const handleEditModule = (moduleType, zoneId, index) => {
    const data = zones[zoneId][index];

    dispatch(
      setCurrentModal({
        key: MODAL_KEY_DASHBOARD_MODULE_CONFIG,
        data: {
          moduleType,
          ...(moduleType === DASHBOARD_MODULE_TYPE.notifications
            ? {
                showOnlyPinnedNotifications: data.showOnlyPinned || false,
                notificationsLimit: data.limit || 0,
                selectedIds: []
              }
            : {
                showOnlyPinnedNotifications: false,
                notificationsLimit: 0,
                selectedIds: data.selectedIds || []
              })
        },
        callbackPrimaryAction: (moduleData) => {
          handleSubmitEditModule(zoneId, index, moduleData, moduleType);
        }
      })
    );
  };

  return (
    <div className="container my-3">
      <div className="row">
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(zones).map((zoneId, zoneIndex) => (
            <Droppable
              key={zoneId}
              droppableId={zoneId}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`col-12 col-md-${LAYOUTS[selectedLayout][zoneIndex]} w-100 p-1 p-md-3 rounded border border-dashed border-black mh-50`}
                >
                  {zones[zoneId]?.map((module, index) => (
                    <Draggable
                      key={module.id}
                      draggableId={module.id}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="border rounded mb-3"
                          style={{
                            ...draggableProvided.draggableProps.style
                          }}
                        >
                          <div className="p-4 position-relative">
                            <div
                              className="position-absolute"
                              style={{
                                top: '-1px',
                                right: '-1px'
                              }}
                            >
                              <DotMenu
                                id={module?.id}
                                handleRemove={() => {
                                  handleDeleteModule(zoneId, index);
                                }}
                                handleEditModule={(moduleType) =>
                                  handleEditModule(moduleType, zoneId, index)
                                }
                                moduleData={module}
                                handleClickReorder={() => {
                                  handleOnclickReorderMenu(zoneId, index);
                                }}
                                handleClickEditMaterial={(materialId) => {
                                  handleClickEditMaterial(materialId);
                                }}
                                moduleType={module.moduleType}
                              />
                            </div>

                            <Module
                              type={module.moduleType}
                              isConfig
                              moduleData={module}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <div className="w-100 d-flex justify-content-center p-3 rounded border border-dashed border-black">
                    <button
                      type="button"
                      onClick={() => {
                        handleAddNewModule(zoneId, { moduleTypes });
                      }}
                      aria-label="New Module"
                    >
                      <FaPlus size={25} />
                    </button>
                  </div>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>

      {children || null}

      <div className="d-flex justify-content-center mt-3">
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={() => {
            onClickSave(zones);
          }}
        >
          Save
        </button>

        {onClickCancel && (
          <button
            type="button"
            className="btn btn-link ml-3"
            onClick={onClickCancel}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default DraggableWidgetsDashboard;
