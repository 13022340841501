import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactSignatureCanvas from 'react-signature-canvas';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import { updateUser } from '../../../services/user';
import dataURLToBlob from '../../../utils/dataURLToBlob';
import SignatureFlag from './SignatureFlag';

const UserSignaturePad = ({
  onChange,
  className = '',
  textAboveSignature = '',
  textBelowSignature = 'Sign above'
}) => {
  let sigPad = useRef(null);
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // misc
  const orgId = organization?.id || '';
  const userId = currentUser?.id || '';
  // local state
  const [loading, setLoading] = useState(false);

  const handleSaveSignature = () => {
    if (sigPad.isEmpty()) {
      toast.error('Please provide a signature first.');
    } else {
      setLoading(true);
      toast.info('Saving signature...');

      const dataURL = sigPad.getTrimmedCanvas().toDataURL();
      const blob = dataURLToBlob(dataURL);

      aws
        .uploadFile({
          prefix: `${AWS_USER_PREFIX}/${orgId}`,
          file: blob,
          fileExt: 'png',
          orgId
        })
        .then(({ url }) => {
          updateUser(
            {
              userSignature: url
            },
            userId
          ).then(() => {
            // SUCCESS
            toast.success('Signature saved!');
            onChange(url);
          });
        });
    }
  };

  const handleClearSignature = () => {
    sigPad.clear();
  };

  // const handleUndoClick = (signaturePad) => {
  //   let data = sigPad.toData();

  //   if (data) {
  //     data.pop(); // remove the last dot or line
  //     signaturePad.fromData(data);
  //   }
  // };

  return (
    <>
      <div className={`card-body bg-light text-center ${className}`}>
        <div
          className="text-center text-muted"
          style={{ fontSize: '.9rem' }}
        >
          {textAboveSignature}
        </div>
        <span className="position-relative">
          <ReactSignatureCanvas
            ref={(ref) => {
              sigPad = ref;
            }}
            penColor="#455371"
            canvasProps={{
              width: 330,
              height: 100,
              className: 'sigCanvas m-auto'
            }}
          />
          <SignatureFlag
            style={{
              bottom: '22px'
            }}
          />
        </span>
        <div
          className="mb-4 text-center no-select"
          style={{ fontSize: '.8rem' }}
        >
          {textBelowSignature}
        </div>
      </div>
      <div className="card-footer d-flex align-items-center justify-content-between">
        <button
          className="btn btn-md btn-primary"
          type="button"
          onClick={handleSaveSignature}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
        <button
          className="btn btn-md btn-secondary"
          type="button"
          onClick={handleClearSignature}
          disabled={loading}
        >
          Clear
        </button>
        {/* <button
        type="button"
        onClick={handleUndoClick}
      >
        Undo
      </button> */}
      </div>
    </>
  );
};

export default UserSignaturePad;
