import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInvoices } from '../../../actions/Billing';
import { formatCurrency } from '../../../services/billing';
import { mdyDate, parseUnix } from '../../../utils/date';

function paymentButtons({ hostedInvoiceUrl }) {
  return (
    <>
      {hostedInvoiceUrl && (
        <a
          className="btn btn-sm btn-outline-primary"
          href={hostedInvoiceUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-file-invoice" /> Details
        </a>
      )}
    </>
  );
}

class Invoices extends Component {
  componentDidMount() {
    this.props.getInvoices().catch((error) => console.warn(error.message));
  }

  render() {
    const { billing } = this.props;
    const invoices = billing?.invoices || null;

    if (!invoices) {
      return null;
    }

    return (
      <div className="invoices-container mt-4">
        <h5>Invoices</h5>
        <div className="card overflow-hidden">
          <div className="card-body p-0">
            <table className="table table-striped mb-0">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Interval</th>
                  <th scope="col">Amount Due</th>
                  <th scope="col">Balance</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map(
                  (
                    {
                      hosted_invoice_url: hostedInvoiceUrl,
                      invoice_pdf: invoicePdf,
                      amount_due: amountDue,
                      amount_remaining: amountRemaining,
                      created,
                      lines
                    },
                    i
                  ) => (
                    <tr key={`invoice-${i}`}>
                      <td>{mdyDate(parseUnix(created))}</td>
                      <td>
                        {lines.data &&
                          lines.data.map(({ period }, lineIndex) => (
                            <span key={`line-${lineIndex}`}>
                              {mdyDate(parseUnix(period.start))} -{' '}
                              {mdyDate(parseUnix(period.end))}
                            </span>
                          ))}
                      </td>
                      <td>{formatCurrency(amountDue / 100)}</td>
                      <td>{formatCurrency(amountRemaining / 100)}</td>
                      <td>
                        {paymentButtons({ hostedInvoiceUrl, invoicePdf })}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ billing }) => ({ billing });

export default connect(mapStateToProps, { getInvoices })(Invoices);
