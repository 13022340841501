import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setCurrentModal } from '../actions/Modals';
import { MODAL_KEY_USER_SIGNATURE } from '../constants/modals';
import SignatureFlag from './Modals/UserSignature/SignatureFlag';

const defaultText = 'I attest the information provided is accurate.';

const UserSignatureBlock = ({
  id = 'UserSignatureBlock',
  onChange,
  text = defaultText,
  textAboveSignature = `By signing, ${defaultText}`,
  textBelowSignature = ''
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const userName = currentUser?.name || null;
  const userSignature = currentUser?.userSignature || '';
  // Local State
  const [signature, setSignature] = useState(userSignature);

  const addSignature = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_USER_SIGNATURE,
        data: {
          textAboveSignature,
          textBelowSignature
        },
        callbackPrimaryAction: (newUserSignature) => {
          setSignature(newUserSignature);
          onChange(newUserSignature);
        }
      })
    );
  };

  return (
    <div
      id={id}
      className="border rounded my-3 px-3 py-2"
    >
      <div className="mb-4 pb-4 text-center">{text}</div>
      {signature ? (
        <img
          src={signature}
          className="w-100"
          alt={`${userName} Signature`}
        />
      ) : (
        <div className="d-flex justify-content-center position-relative">
          <SignatureFlag
            style={{
              bottom: '15px'
            }}
          />
          <button
            className="btn btn-sm btn-warning mb-4"
            onClick={addSignature}
            type="button"
          >
            Add Signature
          </button>
        </div>
      )}
      <SignatureBelowText>{userName}</SignatureBelowText>
    </div>
  );
};

const SignatureBelowText = styled.div`
  border-top: 1px dashed #dee2e6;
  text-align: center;
  margin-bottom: 0 0 20px;
  padding-top: 10px;
`;

export default UserSignatureBlock;
