import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: none;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
  min-width: initial;

  i {
    font-size: 1.1rem;
  }
`;

const CollapseControl = ({ onChange, expanded = false, className = '' }) => (
  <Container
    className={`d-flex align-items-center border p-2 rounded text-ships-officer ${className}`}
  >
    <div
      className="custom-control custom-switch d-flex align-items-center"
      style={{ minHeight: 'auto', height: '1rem' }}
    >
      <input
        data-cy="courses-expanded-switch"
        type="checkbox"
        className="custom-control-input"
        id="courses-expanded-switch"
        value={expanded}
        onChange={onChange}
        checked={expanded}
      />
      <label
        className="custom-control-label text-nowrap"
        htmlFor="courses-expanded-switch"
      >
        Expand
      </label>
    </div>
  </Container>
);

export default CollapseControl;
