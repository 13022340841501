import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CalendarDatePicker from '@apprentage/components/dist/components/CalendarDatePicker';
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import { FaCheckCircle, FaEllipsisH, FaExternalLinkAlt } from 'react-icons/fa';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  createApprenticeship,
  updateApprenticeship
} from '../../../../../services/apprenticeships';
import { routeWithOrgId } from '../../../../../services/organizations';
import { apprenticeshipTypesEnum, createTrainingEnum } from './constants';
import { resetCurrentModal } from '../../../../../actions/Modals';
import { canAccessAllLocations } from '../../../../../permissions/access';
import { getApprenticeships } from '../../../../../actions/Apprenticeships';
import Loading from '../../../../Loading';
import Collapse from '../../../../ManageContent/Collapse';
import SelectMenu from '../../../../ManageContent/SelectMenu';
import Badge from '../../../../Badge';

const Form = ({
  isDuplicating = false,
  isBuildingOwn,
  editOccupation = true,
  handleChangeOccupation
}) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const employers = useSelector((state) => state.employers);
  const instructionProviders = useSelector(
    (state) => state.instructionProviders
  );
  const courses = useSelector((state) => state.courses);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  // misc
  const role = currentUser?.role || [];
  const locationId = currentUser?.locationId || '';
  // Organization
  const orgId = organization?.id || '';
  // Params
  const apprenticeshipId = params?.apprenticeshipId || '';

  const apprenticeshipTitle = useMemo(() => {
    if (apprenticeship?.title) {
      if (isDuplicating) {
        return `(copy) ${apprenticeship?.title}`;
      }

      return apprenticeship?.title;
    }

    return '';
  }, [apprenticeship?.title, isDuplicating]);

  // local state
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(apprenticeshipTitle);
  const [estimatedLength, setEstimatedLength] = useState(
    apprenticeship?.estimatedLength || ''
  );
  const [type, setType] = useState(apprenticeship?.type || '');
  const [isOccupationEditable, setIsOccupationEditable] =
    useState(editOccupation);
  const [occupationName, setOccupationName] = useState(
    apprenticeship?.occupationName || ''
  );
  const [competencies] = useState(apprenticeship?.competencies || '');
  const [instructionHours, setInstructionHours] = useState(
    apprenticeship?.instructionHours || ''
  );
  const [ojtHours, setOjtHours] = useState(apprenticeship?.ojtHours || '');
  const [startDate, setStartDate] = useState(apprenticeship?.startDate || '');
  const [expectedEndDate, setExpectedEndDate] = useState(
    apprenticeship?.expectedEndDate || ''
  );
  const [rapidsCode, setRapidsCode] = useState(
    apprenticeship?.rapidsCode || ''
  );
  const [occupationONetCode, setOccupationONetCode] = useState(
    apprenticeship?.occupationONetCode || ''
  );
  const [employerIds, setEmployerIds] = useState(
    apprenticeship?.employerIds || []
  );
  const [courseIds, setCourseIds] = useState(apprenticeship?.courseIds || []);
  const [instructionProviderIds, setInstructionProviderIds] = useState(
    apprenticeship?.instructionProviderIds || []
  );

  // Options
  const apprenticeshipTypeOptions = reactSelectOptionsFromEnum(
    apprenticeshipTypesEnum
  );
  const employerOptions = useMemo(() => {
    if (employers.list) {
      return employers?.list?.map((employer) => ({
        label: employer.name,
        value: employer.id
      }));
    }

    return null;
  }, [employers.list]);

  const instructionProviderOptions = useMemo(() => {
    if (instructionProviders.list) {
      return instructionProviders.list.map((instructionProvider) => ({
        label: instructionProvider.name,
        value: instructionProvider.id
      }));
    }

    return null;
  }, [instructionProviders.list]);

  const courseOptions = useMemo(() => {
    if (courses.list) {
      return courses.list.map((course) => ({
        label: course.title,
        value: course.id
      }));
    }

    return null;
  }, [courses.list]);

  useEffect(() => {
    if (apprenticeship?.occupationName !== occupationName) {
      setOccupationName(apprenticeship?.occupationName);
    }
  }, [apprenticeship?.occupationName, occupationName, occupationONetCode]);

  // Default Values
  const apprenticeshipTypeDefaultValue = reactSelectDefaultValue(
    type,
    apprenticeshipTypeOptions
  );
  const employersDefaultValues = reactSelectDefaultValues(
    employerIds,
    employerOptions
  );
  const coursesDefaultValues = reactSelectDefaultValues(
    courseIds,
    courseOptions
  );
  const instructionProvidersDefaultValues = reactSelectDefaultValues(
    instructionProviderIds,
    instructionProviderOptions
  );

  const handleSaveSuccessCallback = () => {
    toast.success(
      `${title} ${apprenticeshipId && !isDuplicating ? 'Updated' : 'Created'}`
    );

    if (isDuplicating) {
      /**
       * Duplicating Training Program
       * Close modal before redirection
       */
      dispatch(resetCurrentModal());
    }

    if (
      window.location.toString().includes(ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS)
    ) {
      const config = {
        orgId,
        ...(canAccessAllLocations(role) ? {} : { employerId: locationId })
      };

      if (orgId) {
        dispatch(getApprenticeships(config));
      }
    }

    history.push(
      routeWithOrgId({
        route: apprenticeshipId
          ? `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`
          : ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS,
        orgId
      })
    );
    setLoading(false);
  };

  const handleSaveErrorCallback = () => {
    toast.error('Something went wrong, please try again.');

    if (isDuplicating) {
      /**
       * Duplicating Training Program
       * Close modal if error
       */
      dispatch(resetCurrentModal());
    }
    setLoading(false);
  };

  const onCancel = () => {
    const goBackRoute = apprenticeshipId
      ? `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`
      : ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS;

    if (isDuplicating || isBuildingOwn) {
      dispatch(resetCurrentModal());
      return;
    }

    history.replace(
      routeWithOrgId({
        route: goBackRoute,
        orgId
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      title: title || null,
      estimatedLength: estimatedLength || null,
      type: type || null,
      occupationName: occupationName || null,
      instructionHours: instructionHours || null,
      ojtHours: ojtHours || null,
      startDate: startDate || null,
      expectedEndDate: expectedEndDate || null,
      rapidsCode: rapidsCode || null,
      occupationONetCode: occupationONetCode || null
    };

    if (!isDuplicating) {
      /**
       * Existing or New Training Program
       * Update Employers & Courses
       */
      if (Array.isArray(employerIds) && employerIds?.length) {
        dataToSave.employerIds = employerIds || null;
      }

      if (
        Array.isArray(instructionProviderIds) &&
        instructionProviderIds?.length
      ) {
        dataToSave.instructionProviderIds = instructionProviderIds || null;
      }

      if (Array.isArray(courseIds) && courseIds?.length) {
        dataToSave.courseIds = courseIds || null;
      }
    }

    if (isDuplicating) {
      /**
       * Duplicating Training Program
       * Copy over require Competency Modal data
       */
      if (apprenticeship?.competencyNameSpace) {
        dataToSave.competencyNameSpace =
          apprenticeship?.competencyNameSpace || null;
      }

      if (apprenticeship?.classifications) {
        dataToSave.classifications = apprenticeship?.classifications || null;
      }

      if (apprenticeship?.competencies) {
        dataToSave.competencies = apprenticeship?.competencies || null;
      }

      if (apprenticeship?.competencyGroups) {
        dataToSave.competencyGroups = apprenticeship?.competencyGroups || null;
      }
    }

    setLoading(true);

    if (apprenticeshipId && !isDuplicating) {
      /**
       * Existing Training Program
       * User is editing
       */
      updateApprenticeship(dataToSave, apprenticeshipId)
        .then(() => {
          handleSaveSuccessCallback();
        })
        .catch(() => {
          handleSaveErrorCallback();
        });
    } else {
      /**
       * New Training Program or
       * Duplicating Existing Training Program
       */
      createApprenticeship({
        ...dataToSave,
        orgId
      })
        .then(() => {
          handleSaveSuccessCallback();
        })
        .catch(() => {
          handleSaveErrorCallback();
        });
    }
  };

  if (apprenticeshipId && !apprenticeship.id) {
    return <Loading text="Loading apprenticeship..." />;
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="card-body">
        <div className="mb-3">
          <label htmlFor="title">
            <strong>Title</strong> <span className="text-danger">*</span>{' '}
            (internal use)
          </label>
          <input
            type="text"
            name="title"
            onChange={(e) => {
              const { value } = e.currentTarget;

              setTitle(value);
            }}
            className="form-control"
            value={title}
            required
          />
          <div className="text-muted small mt-1">
            Ex: Mechatronics Technician 2025
          </div>
        </div>

        <Collapse
          id="occupationCollapse"
          title="Occupation"
          className="mb-3"
          ariaExpanded={
            apprenticeship?.occupationName === '' ||
            apprenticeship?.rapidsCode === '' ||
            apprenticeship?.occupationONetCode === ''
          }
        >
          {!isOccupationEditable && (
            <div>
              <div className="border bg-white px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-weight-bold mb-1">
                      {apprenticeship?.occupationName}
                    </div>
                    <ul className="m-0">
                      <li className="ml-2">
                        <span>RAPIDS Code: {apprenticeship?.rapidsCode}</span>
                      </li>
                      <li className="ml-2">
                        <span>
                          O*NET Code: {apprenticeship?.occupationONetCode}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {isBuildingOwn !==
                    createTrainingEnum.CLONE_SUCCESSFUL_TRAINING && (
                    <div className="align-self-start btn-group align-items-center">
                      <button
                        type="button"
                        className="btn-link rounded"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="Manage Menu"
                        title="Manage Menu"
                      >
                        <FaEllipsisH />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right p-0">
                        {handleChangeOccupation && (
                          <button
                            className="dropdown-item border-bottom p-2"
                            type="button"
                            onClick={handleChangeOccupation}
                          >
                            Select New Occupation
                          </button>
                        )}
                        <button
                          className="dropdown-item p-2"
                          type="button"
                          onClick={() => {
                            setIsOccupationEditable(true);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isOccupationEditable && (
            <div>
              <div className="mb-3">
                <label htmlFor="occupationName">
                  <strong>Occupation Name</strong>{' '}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="occupationName"
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setOccupationName(value);
                  }}
                  className="form-control"
                  style={handleChangeOccupation ? { paddingRight: '5rem' } : {}}
                  value={occupationName}
                  required
                />
                <div className="text-muted small mt-1">
                  Ex: Mechatronics Technician,
                  {handleChangeOccupation && (
                    <button
                      title="Choose approved occupation"
                      className="small ml-1 btn-link btn-sm p-0 m-0"
                      onClick={handleChangeOccupation}
                      type="button"
                      style={{
                        fontSize: '0.75rem'
                      }}
                    >
                      Select Occupation
                    </button>
                  )}
                </div>
              </div>

              {occupationName && (
                <div className="row">
                  <div className="col-12 col-sm-6 mb-3">
                    <label htmlFor="rapidsCode">RAPIDS Code</label>
                    <input
                      type="text"
                      name="rapidsCode"
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setRapidsCode(value);
                      }}
                      className="form-control"
                      value={rapidsCode}
                    />
                    <div className="text-muted small mt-1 d-flex align-items-center">
                      Ex: 2104,
                      <a
                        href={`https://www.onetonline.org/crosswalk/RAPIDS?s=${encodeURIComponent(
                          occupationName
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Lookup NAICS code"
                        className="d-flex align-items-center ml-1"
                      >
                        <span className="mr-1">Lookup RAPIDS code</span>
                        <FaExternalLinkAlt size={10} />
                      </a>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 mb-3">
                    <label htmlFor="occupationONetCode">
                      Occupation O*NET Code
                    </label>
                    <input
                      type="text"
                      name="occupationONetCode"
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setOccupationONetCode(value);
                      }}
                      className="form-control"
                      value={occupationONetCode}
                    />
                    <div className="text-muted small mt-1">
                      <div className="d-flex align-items-center">
                        <span>Ex: 29-1151.00,</span>
                        <a
                          href={`https://www.onetonline.org/crosswalk/RAPIDS?s=${encodeURIComponent(
                            occupationName
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Lookup NAICS code"
                          className="d-flex align-items-center ml-1"
                        >
                          <span className="mr-1">Lookup o*NET code</span>
                          <FaExternalLinkAlt size={10} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapse>

        {isDuplicating && (
          <div className="mb-3 border bg-white p-3 d-flex align-items-center justify-content-between">
            <div>
              <div className="font-weight-bold mb-1 d-flex align-items-center">
                <span>Competencies</span>
                <Badge className="ml-1">
                  {Object.keys(competencies || {}).length}
                </Badge>
              </div>
            </div>

            <FaCheckCircle
              className="text-success"
              size={18}
            />
          </div>
        )}

        <div className="row">
          <div className="col-sm-6 mb-3">
            <label
              htmlFor="startDate"
              className="d-block"
            >
              Start Date <span className="text-danger">*</span>
            </label>
            <CalendarDatePicker
              selected={startDate ? new Date(startDate) : null}
              id="startDate"
              name="startDate"
              className="form-control"
              placeholderText="MM/DD/YYYY"
              onChange={(date) => {
                setStartDate(date.toISOString());
              }}
              dropdownMode
              // inline
              // timeInputLabel="Time:"
              // dateFormat="MM/dd/yyyy"
              // showTimeInput
              required
            />
          </div>

          <div className="col-sm-6 mb-3">
            <label
              htmlFor="expectedEndDate"
              className="d-block"
            >
              Expected End Date <span className="text-danger">*</span>
            </label>
            <CalendarDatePicker
              selected={expectedEndDate ? new Date(expectedEndDate) : null}
              id="expectedEndDate"
              name="expectedEndDate"
              className="form-control"
              placeholderText="MM/DD/YYYY"
              onChange={(date) => {
                setExpectedEndDate(date.toISOString());
              }}
              dropdownMode
              // inline
              // timeInputLabel="Time:"
              // dateFormat="MM/dd/yyyy"
              // showTimeInput
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-3">
            <label htmlFor="instructionHours">
              Instruction Hours <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="instructionHours"
              onChange={(e) => {
                const { valueAsNumber } = e.currentTarget;

                setInstructionHours(valueAsNumber);
              }}
              className="form-control"
              value={instructionHours}
              required
            />
          </div>

          <div className="col-sm-6 mb-3">
            <label htmlFor="ojtHours">
              On the Job Training (OJT) Hours{' '}
              <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="ojtHours"
              onChange={(e) => {
                const { valueAsNumber } = e.currentTarget;

                setOjtHours(valueAsNumber);
              }}
              className="form-control"
              value={ojtHours}
              required
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="type">
            Program Type <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="type"
            options={apprenticeshipTypeOptions}
            defaultValue={apprenticeshipTypeDefaultValue}
            required
            onChange={({ type: newType }) => {
              setType(newType);
            }}
          />
        </div>

        <div>
          <label htmlFor="title">Estimated Program Duration</label>
          <input
            type="text"
            name="estimatedLength"
            onChange={(e) => {
              const { value } = e.currentTarget;

              setEstimatedLength(value);
            }}
            className="form-control"
            value={estimatedLength}
          />
          <div className="text-muted small mt-1">Ex: 2 Years</div>
        </div>

        {!isDuplicating && !isBuildingOwn && (
          <div
            id="apprenticeshipEmployersSelect"
            className="mb-4"
          >
            <label htmlFor="employerIds">
              <strong>Employers</strong> <span className="text-danger">*</span>
            </label>
            <SelectMenu
              defaultValue={employersDefaultValues}
              name="employerIds"
              options={employerOptions}
              isMulti
              onChange={({ employerIds: newEmployerIds }) => {
                setEmployerIds(newEmployerIds);
              }}
            />
          </div>
        )}

        {!isDuplicating && !isBuildingOwn && (
          <div
            id="apprenticeshipCoursesSelect"
            className="mb-4"
          >
            <label htmlFor="courseIds">
              <strong>Courses</strong> <span className="text-danger">*</span>
            </label>
            <SelectMenu
              id="courseIds"
              name="courseIds"
              options={courseOptions}
              defaultValue={coursesDefaultValues}
              isMulti
              onChange={({ courseIds: newCourseIds }) => {
                setCourseIds(newCourseIds);
              }}
            />
          </div>
        )}

        {!isDuplicating && !isBuildingOwn && (
          <div
            id="apprenticeshipInstructionProvidersSelect"
            className="mb-4"
          >
            <label htmlFor="instructionProviderIds">
              <strong>Instruction Providers</strong>{' '}
              <span className="text-danger">*</span>
            </label>
            <SelectMenu
              id="instructionProviderIds"
              name="instructionProviderIds"
              options={instructionProviderOptions}
              defaultValue={instructionProvidersDefaultValues}
              isMulti
              required
              onChange={({
                instructionProviderIds: newInstructionProviderIds
              }) => {
                setInstructionProviderIds(newInstructionProviderIds);
              }}
            />
          </div>
        )}
      </div>
      <div className="card-footer d-flex">
        <button
          className="btn btn-primary btn-md"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
        <button
          className="btn btn-link p-2 ml-3"
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default Form;
