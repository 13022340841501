const sortArrByKey = (arr, key, reverse) => {
  const keyArr = key.split('.');

  let sortOrder = 1;

  if (reverse) sortOrder = -1;

  return arr.sort((a, b) => {
    let x = a;
    let y = b;

    for (let i = 0; i < keyArr.length; i += 1) {
      x =
        typeof x[keyArr[i]] === 'string'
          ? x[keyArr[i]].toLowerCase()
          : x[keyArr[i]];
      y =
        typeof y[keyArr[i]] === 'string'
          ? y[keyArr[i]].toLowerCase()
          : y[keyArr[i]];
    }

    return sortOrder * (x < y ? -1 : x > y ? 1 : 0);
  });
};

export default sortArrByKey;
