import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { ICON_LINK, ICON_COPY } from '@apprentage/constants';
import { reactSelectDefaultValue } from '@apprentage/utils';
import { resetCurrentModal } from '../../../actions/Modals';
import { TURBINE } from '../../../constants/urls';
import { canInviteUser } from '../../../permissions/invite';
import { inviteUsers } from '../../../services/mailer';
import SelectMenu from '../../ManageContent/SelectMenu';
import Modal from '../../Modal';
import OrgLocation from '../../OrgLocation';

const InviteUser = () => {
  const dispatch = useDispatch();
  const userRegLink = useRef(null);
  const orgLoginLink = useRef(null);
  const orgRegLink = useRef(null);
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const employers = useSelector((state) => state.employers);
  const currentModal = useSelector((state) => state.currentModal);
  const course = useSelector((state) => state.course);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  // const currentCohort = useSelector((state) => state.currentCohort);
  const apprenticeship = useSelector((state) => state.apprenticeship);

  // misc
  const key = 'inviteUser';
  const currentModalKey = currentModal?.key || null;
  const currentModalCohortId = currentModal?.data?.cohortId || null;
  const currentModalEmployerId = currentModal?.data?.employerId || null;
  const modalTitle = currentModal?.data?.modalTitle || '';
  // Organization
  const orgSlug = organization?.slug || null;
  const orgName = organization?.name || null;
  const orgEmail = organization?.email || null;
  // Current User
  const role = currentUser?.role || [];
  // Course
  const classId = course?.id || null;
  const courseTitle = course?.title || null;
  const hasStripeIntegration = course?.integration?.stripe || false;
  // Training Program
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipTitle = apprenticeship?.title || null;
  const employerIds = apprenticeship?.employerIds || [];
  const firstCohort = courseCohorts.list ? courseCohorts.list[0] : null;
  const firstEmployerId = employerIds.length ? employerIds[0] : null;
  // local state
  const [loading, setLoading] = useState(false);
  const [courseCohortId, setCourseCohortId] = useState(
    currentModalCohortId || firstCohort?.id || null
  );
  const [employerId, setEmployerId] = useState(
    currentModalEmployerId || firstEmployerId || null
  );
  const [emails, setEmails] = useState('');
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [waiveCosts, setWaiveCosts] = useState(false); // TODO review

  const apprenticeshipEmployers =
    employers.list && employerIds.length
      ? employers.list.filter(({ id }) => employerIds.includes(id))
      : [];
  const employerOptions = apprenticeshipEmployers.length
    ? apprenticeshipEmployers.map((employer) => ({
        label: employer.name,
        value: employer.id
      }))
    : [];

  const courseCohortOptions =
    classId && courseCohorts.list
      ? courseCohorts.list.reverse().map((cohort) => ({
          label: cohort.title,
          value: cohort.id
        }))
      : null;

  // Default Values
  const employersDefaultValue = reactSelectDefaultValue(
    employerId || employerOptions[0],
    employerOptions
  );
  const courseCohortDefaultValue = courseCohortId
    ? reactSelectDefaultValue(
        courseCohortId || courseCohortOptions[0],
        courseCohortOptions
      )
    : null;

  const courseCohortTitle = useMemo(() => {
    let title = ''; // currentCohort.title || '';

    if (courseCohortId && courseCohortOptions) {
      const newCohortOption = courseCohortOptions.find(
        ({ value }) => courseCohortId === value
      );

      if (newCohortOption) {
        title = newCohortOption.label;
      }
    }

    return title;
  }, [courseCohortId]);

  const orgRegisterUrl = `${TURBINE}/login/${orgSlug}?action=signup`;

  const registerLink = useMemo(() => {
    const cohortId = courseCohortId;

    let url = orgRegisterUrl;

    // Class
    if (classId) {
      url += `&classId=${classId}`;

      // Cohort
      if (cohortId) {
        url += `&cohortId=${cohortId}`;
      }

      // Waive Costs
      if (waiveCosts) {
        url += `&wc=${waiveCosts}`;
      }

      // Invitation Only (inviteOnly)
      if (course.inviteOnly) {
        url += `&io=${course.inviteOnly}`;
      }
    }

    if (apprenticeshipId) {
      url += `&apprenticeshipId=${apprenticeshipId}`;

      // Employer
      if (employerId) {
        url += `&userLocationId=${employerId}`;
      }
    }

    return url;
  }, [
    courseCohortId,
    orgRegisterUrl,
    classId,
    apprenticeshipId,
    waiveCosts,
    course.inviteOnly,
    employerId
  ]);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    inviteUsers({
      emails,
      registerLink,
      orgName,
      orgEmail,
      // Course
      courseInvitation: !!classId,
      ...(classId && courseTitle ? { courseTitle } : {}),
      // Training Program
      apprenticeshipInvitation: !!apprenticeshipId,
      ...(apprenticeshipId ? { apprenticeshipTitle } : {})
    })
      .then(() => {
        const successMessage = `${pluralize('User invite', emails.length)} sent!`;

        toast.success(successMessage);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const selectAll = (e) => {
    e.currentTarget.select();
  };

  const copyLink = (refElem, url = '') => {
    if (!refElem?.current && !url) {
      toast.error('Something went wrong, try again.');
      return;
    }

    if (refElem?.current?.value) {
      refElem.current?.focus();
      refElem.current?.select();
      navigator.clipboard.writeText(refElem.current?.value);
    } else if (url) {
      navigator.clipboard.writeText(url);
    }

    toast.success('Link copied!');
  };

  const inviteToText = useMemo(() => {
    let text = orgName;

    // Course
    if (classId && courseTitle) {
      text = courseTitle;

      // Cohort
      if (courseCohortId && courseCohortTitle) {
        text += ` [${courseCohortTitle}]`;
      }
    }

    if (apprenticeshipId) {
      text = apprenticeshipTitle;
    }

    return text;
  }, [courseCohortId, employerId]);

  if (!currentModal.visible) return null;

  if (currentModalKey !== key) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModalKey === key}
      close={handleClose}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">{modalTitle}</h1>
        </div>
        <div className="card-body p-0">
          <div className="border-bottom p-3">
            <form
              id="invite-user"
              name="invite-user"
              onSubmit={onSubmit}
            >
              {apprenticeshipId && (
                <div className="d-flex align-items-center mb-3">
                  <OrgLocation
                    locationId={employerId}
                    className="h6 m-0"
                    imageHeight={30}
                  />
                  {!advancedSettings && employerOptions.length > 1 && (
                    <button
                      title="Show advanced settings"
                      type="button"
                      className="btn btn-sm btn-primary ml-2 px-1 py-0"
                      style={{ fontSize: '.75rem' }}
                      onClick={() => {
                        setAdvancedSettings(true);
                      }}
                    >
                      Change
                    </button>
                  )}
                </div>
              )}

              <h6 className="font-weight-bold">{inviteToText}</h6>

              <textarea
                type="text"
                name="emails"
                className="form-control mb-2"
                placeholder="name@gmail.com"
                aria-label="User Emails"
                aria-describedby="input-sendInvitation"
                value={emails}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setEmails(value);
                }}
                disabled={loading}
                autoFocus
                required
              />
              <div className="mb-3 text-muted small">
                Ex: dylan@company.com, rmaxwell@organization.com
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-muted text-break-all">
                  <button
                    title="Copy Link"
                    type="button"
                    className="btn-link pr-1"
                    onClick={() => {
                      // TODO apprenticeship
                      if (classId || courseCohortId || apprenticeshipId) {
                        copyLink(userRegLink, registerLink);
                      } else {
                        copyLink(orgRegLink, orgRegisterUrl);
                      }
                    }}
                  >
                    <i className={ICON_LINK} /> Copy invite link
                  </button>

                  {!advancedSettings && (
                    <span className="text-muted ml-1">
                      <span>-</span>
                      <button
                        title="Show advanced settings"
                        type="button"
                        className="btn-link text-muted pl-1"
                        onClick={() => {
                          setAdvancedSettings(!advancedSettings);
                        }}
                      >
                        Settings
                      </button>
                    </span>
                  )}
                </div>
                <button
                  title="Send Invitation"
                  aria-label="Send Invitation"
                  className={`btn ${emails ? 'btn-primary' : 'btn-secondary'}`}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Sending...' : 'Send'}
                </button>
              </div>
            </form>
          </div>

          <div
            id="invite-link-settings"
            className={advancedSettings ? 'd-block' : 'd-none'}
          >
            <div className="card-header mb-2 d-flex align-items-center justify-content-between">
              <h6 className="m-0">Advanced Settings</h6>
              <button
                title="Hide advanced settings"
                type="button"
                className="btn-link p-0"
                onClick={() => {
                  setAdvancedSettings(false);
                }}
              >
                Close settings
              </button>
            </div>
            <div className="card-body pt-2">
              {(classId || apprenticeshipId) && (
                <>
                  {apprenticeshipId && (
                    <OrgLocation
                      locationId={employerId}
                      className="mb-1"
                    />
                  )}

                  <h5 className="font-weight-bold">{inviteToText}</h5>

                  <div className="border p-2 mb-3">
                    {apprenticeshipId && employerOptions.length > 1 && (
                      <div className="my-2 border p-2">
                        <h6 className="font-weight-bold">
                          <i className="fas fa-users" /> Employer
                        </h6>
                        <div>
                          User will be assigned this employer during
                          registration.
                        </div>
                        <SelectMenu
                          defaultValue={employersDefaultValue}
                          name="employerId"
                          options={employerOptions}
                          onChange={({ employerId: newEmployerId }) => {
                            setEmployerId(newEmployerId);
                          }}
                        />
                      </div>
                    )}

                    {classId && hasStripeIntegration && (
                      <div className="my-2 border p-2 d-flex justify-content-between">
                        <div>
                          <h6 className="font-weight-bold">
                            <i className="far fa-credit-card" /> Waive Costs
                          </h6>
                          <div>Waive enrollment fees.</div>
                        </div>
                        <div>
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="waiveCosts"
                              onChange={() => {
                                setWaiveCosts(!waiveCosts);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="waiveCosts"
                            >
                              Waive Costs
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {classId && courseCohortOptions && (
                      <div className="my-2 border p-2">
                        <h6 className="font-weight-bold">
                          <i className="fas fa-users" /> Cohort
                        </h6>
                        <div>
                          Automatically add user to Cohort during registration.
                        </div>
                        <SelectMenu
                          name="courseCohortId"
                          className="mt-2"
                          options={courseCohortOptions}
                          defaultValue={courseCohortDefaultValue}
                          onChange={({ courseCohortId: newCourseCohortId }) => {
                            setCourseCohortId(newCourseCohortId);
                          }}
                        />
                      </div>
                    )}

                    <div className="mb-2">Register:</div>
                    <div className="input-group">
                      <textarea
                        readOnly
                        style={{ minHeight: '100px' }}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        value={registerLink}
                        onClick={selectAll}
                        ref={userRegLink}
                      />
                      <div className="input-group-append">
                        <button
                          title="Copy Link"
                          type="button"
                          aria-label="Copy"
                          className="btn btn-info"
                          onClick={() => {
                            copyLink(userRegLink);
                          }}
                        >
                          <i className={ICON_COPY} />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!apprenticeshipId && ( // TODO - add classId as well?
                <div>
                  <h6 className="mb-0">
                    <button
                      className="btn btn-link text-left text-dark p-0"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseInviteUserOrg"
                      aria-expanded={!classId}
                      aria-controls="collapseInviteUserOrg"
                    >
                      <i className="fas fa-caret-right" />
                      <i className="fas fa-caret-down" />
                      {orgName}
                    </button>
                  </h6>

                  <div
                    id="collapseInviteUserOrg"
                    className={`collapse ${classId ? '' : 'show'}`}
                  >
                    <div className="bg-light border p-2 mt-2">
                      <div className="mb-2">Login:</div>
                      <div className="input-group">
                        <input
                          readOnly
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          defaultValue={`${TURBINE}/login/${orgSlug}`}
                          onClick={selectAll}
                          ref={orgLoginLink}
                        />
                        <div className="input-group-append">
                          <button
                            title="Copy Link"
                            type="button"
                            aria-label="Copy"
                            className="btn btn-info"
                            onClick={() => {
                              copyLink(orgLoginLink);
                            }}
                          >
                            <i className={ICON_COPY} />
                          </button>
                        </div>
                      </div>
                      <br />

                      {canInviteUser(role) && (
                        <>
                          <div className="mb-2">Register:</div>
                          <div className="input-group">
                            <input
                              readOnly
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              defaultValue={`${TURBINE}/login/${orgSlug}?action=signup`}
                              onClick={selectAll}
                              ref={orgRegLink}
                            />
                            <div className="input-group-append">
                              <button
                                title="Copy Link"
                                type="button"
                                aria-label="Copy"
                                className="btn btn-info"
                                onClick={() => {
                                  copyLink(orgRegLink, orgRegisterUrl);
                                }}
                              >
                                <i className={ICON_COPY} />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card-footer text-muted">
          <b>Note:</b> New users are automatically assigned the role{' '}
          <b>Student</b>. An organization <b>Owner</b> can modify user roles.
        </div>
      </div>
    </Modal>
  );
};

export default InviteUser;
