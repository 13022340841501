import { sortCaseInsensitive } from '@apprentage/utils';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'resourceGroupTags';

export const fetchResourceGroupTags = async ({
  orgId,
  label,
  ids,
  order = 'label',
  page = 1,
  select,
  limit = 2000
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (label) {
    params['f.label[ilike]'] = label;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  if (Array.isArray(response?.items) && response?.items.length > 0) {
    response.items = response?.items.sort((a, b) =>
      sortCaseInsensitive(a, b, order)
    );
  }

  return response;
};

export const fetchResourceGroupTag = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateResourceGroupTag = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`update ${table}`, error);
        reject(error);
      });
  });
};

export const createResourceGroupTag = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`create ${table}`, error);
        reject(error);
      });
  });
};

export const deleteResourceGroupTag = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(`delete ${table}`, error);
        reject(error);
      });
  });
};
