import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { getCustomer } from '../../../actions/Billing';
import Address from '../../ManageContent/Address';

class Customer extends Component {
  componentDidMount() {
    this.props.getCustomer().catch((error) => console.warn(error.message));
  }

  saveField = (e) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value
    });
  };

  render() {
    const {
      currentUser,
      organization,
      onAddressChange,
      toggleEditMode,
      editMode,
      address
    } = this.props;
    const email = pathOr(null, ['email'], currentUser);
    const orgName = pathOr(null, ['name'], organization);
    // Address : Location
    const addressLine1 = pathOr('', ['addressLine1'], address);
    const addressLine2 = pathOr('', ['addressLine2'], address);
    const addressCity = pathOr('', ['addressCity'], address);
    const addressState = pathOr('', ['addressState'], address);
    const addressPostalCode = pathOr('', ['addressPostalCode'], address);
    const addressCountry = pathOr('', ['addressCountry'], address);
    // TODO if customer exists, pre-fill billing info, collapse form
    // const customer = pathOr(null, ['customer'], billing);

    return (
      <div className="customer-container mt-4">
        <h5 className="mb-3">Billing information</h5>

        <div className="card">
          <div className="card-body">
            <div>
              <div className="mb-3">
                <label htmlFor="line2">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  defaultValue={orgName}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="line2">Billing Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  defaultValue={email}
                  onChange={this.props.onChange}
                />
              </div>
            </div>

            <Address
              title="Billing Address"
              cardStyle
              formButtons={false}
              editMode={editMode}
              toggleEditMode={toggleEditMode}
              onChange={onAddressChange}
              address={{
                line1: addressLine1,
                line2: addressLine2,
                city: addressCity,
                state: addressState,
                postal_code: addressPostalCode,
                country: addressCountry
              }}
              required
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser, organization }) => ({
  currentUser,
  organization
});

export default connect(mapStateToProps, { getCustomer })(Customer);
