import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { ICON_REORDER } from '@apprentage/constants';
import { resetCourseGroups } from '../../../../actions/Groups';
import { withAuthorization } from '../../../Session';
import { routeWithOrgId } from '../../../../services/organizations';
import {
  ORG_CONSOLE_COURSES_GROUPS,
  ORG_CONSOLE_DASHBOARD
} from '../../../../constants/routes';
import { ICON_COURSES } from '../../../../constants/assets';
import withOrgConsole from '../../../App/withOrgConsole';
import Form from './Form';
import Tabs from '../Tabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';

const ReorderGroups = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courseGroups = useSelector((state) => state.courseGroups);
  // Misc
  const orgId = organization?.id || null;
  const courseGroupIds = organization.courseGroupIds || null;
  const courseGroupsRoute = routeWithOrgId({
    route: ORG_CONSOLE_COURSES_GROUPS,
    orgId
  });

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCourseGroups());
    };
  }, []);

  if (!courseGroups.list || !courseGroupIds) {
    history.replace(courseGroupsRoute);
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-xl-7">
        <OrgConsoleHeader
          pageTitle="Courses"
          image={ICON_COURSES}
          imageSize={25}
          route={ORG_CONSOLE_DASHBOARD}
        />

        <Tabs active="groups" />

        <div className="mb-5 bg-white border-left border-right border-bottom p-3">
          <OrgConsoleSubheader
            pageTitle="Reorder"
            icon={ICON_REORDER}
            iconStyle={{
              fontSize: '1rem'
            }}
            route={ORG_CONSOLE_COURSES_GROUPS}
          />

          <div className="card mt-3">
            <div className="card-header bg-dark border-dark">
              <h6 className="m-0 text-white font-weight-bold">Course Groups</h6>
            </div>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ReorderGroups);
