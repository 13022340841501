import React, { useState } from 'react';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { MODULES_PAGINATION_LIMIT } from '../../../../constants/api';
import { DASHBOARD_MODULES } from '../../../../constants/dashboard';
import PaginationSupabase from '../../../ManageContent/PaginationSupabase';
import List from './List';
import Tabs from './Tabs';
import StylingCard from './StylingCard';

const SelectModulesData = ({
  moduleType,
  hasStyling = false,
  tableData = {},
  handleOnClickRow,
  setDataConfig,
  handleSubmitConfig,
  isSingleSelect,
  moduleSettings,
  setModuleSettings,
  handleClose
}) => {
  const [activeTab, setActiveTab] = useState('items');
  const defaultTabName = DASHBOARD_MODULES[moduleType];

  return (
    <>
      <div className="card-body p-0">
        <Tabs
          defaultTabName={defaultTabName}
          cardStyle
          hasStyling={hasStyling}
          className="pt-3"
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
        />

        {hasStyling && activeTab === 'styling' && (
          <>
            <div className="container-fluid mt-3 mb-2">
              <div className="row">
                <div className="col-12">
                  <h6 className="font-weight-bold">Styling</h6>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <StylingCard
                    title="Box"
                    value="box"
                    selectedValue={moduleSettings?.styling}
                    onClick={(value) => {
                      setModuleSettings((prevState) => ({
                        ...prevState,
                        styling: value
                      }));
                    }}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <StylingCard
                    title="Basic"
                    value="basic"
                    selectedValue={moduleSettings?.styling}
                    onClick={(value) => {
                      setModuleSettings((prevState) => ({
                        ...prevState,
                        styling: value
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {activeTab === 'items' && (
          <div className="container-fluid mb-4">
            <div className="row">
              <div className="col">
                <div className="p-3">
                  <SearchBox
                    className="w-100"
                    onSubmit={(value) => {
                      if (setDataConfig) {
                        setDataConfig({
                          page: 1,
                          limit: MODULES_PAGINATION_LIMIT,
                          searchValue: value
                        });
                      }
                    }}
                    onClear={() => {
                      const searchBox = document.querySelector(
                        'input[name="moduleDataSearch"]'
                      );
                      searchBox.focus();
                    }}
                    name="moduleDataSearch"
                    value=""
                    placeholder="Search by title..."
                    autoFocus
                  />
                </div>
                <List
                  data={tableData.list}
                  handleOnClickRow={handleOnClickRow}
                  isSingleSelect={isSingleSelect}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="card-footer d-flex justify-content-between">
        <div>
          <button
            className="btn btn-primary mr-2"
            onClick={handleSubmitConfig}
            title="Save"
            type="button"
          >
            Save
          </button>

          <button
            className="btn btn-sm btn-link"
            onClick={handleClose}
            title="Cancel"
            type="button"
          >
            Cancel
          </button>
        </div>

        {Array.isArray(tableData.list) && tableData?.list?.length > 0 && (
          <PaginationSupabase
            items={tableData?.list}
            pagination={tableData?.pagination}
            page={tableData?.pagination?.page}
            className=""
            style={{}}
            onClickNext={() => {
              setDataConfig((prev) => ({
                ...prev,
                page: tableData?.pagination?.page + 1
              }));
            }}
            onClickPrev={() => {
              setDataConfig((prev) => ({
                ...prev,
                page: tableData?.pagination?.page - 1
              }));
            }}
          />
        )}
      </div>
    </>
  );
};

export default SelectModulesData;
