import {
  fetchResourceGroup,
  fetchResourceGroups
} from '../services/resourceGroups';
import { createSupabaseEntry } from '../services/supabaseProxy';
import { getResourcesByParentId } from './Resources';
import {
  SET_RESOURCE_GROUP,
  SET_RESOURCE_GROUPS,
  CREATE_RESOURCE_GROUP,
  RESET_RESOURCE_GROUP,
  RESET_RESOURCE_GROUPS,
  SET_ENTRY_ORG_ID
} from './types';

export const getResourceGroups = ({
  orgId,
  topLevel = null,
  parentId = undefined,
  select = null,
  limit = 1000,
  // from URL params for breadcrumb
  groupTags = null,
  groupIds = null
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchResourceGroups({
        ...(topLevel ? { topLevel } : {}),
        ...(groupIds ? { ids: groupIds } : {}),
        ...(select ? { select } : {}),
        groupTags,
        parentId,
        orgId,
        limit
      })
        .then((response) => {
          const list = Array.isArray(response?.items) ? response.items : null;
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_RESOURCE_GROUPS,
            groupType: 'resource',
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            },
            groupTags,
            groupIds
          });

          resolve(list);
        })
        .catch((error) => {
          console.error('getResourceGroups', error);
          reject(error);
        });
    });
  };
};

export const getResourceGroupsByParentId = ({ parentId, orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchResourceGroups({ orgId, parentId })
        .then((response) => {
          const list = response?.items;

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_RESOURCE_GROUP,
            list
          });
          resolve(list);
        })
        .catch((error) => {
          console.error('fetchResourceGroups', error);
          reject(error);
        });
    });
  };
};

export const getResourceGroup = ({ groupId, orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchResourceGroup(groupId)
        .then((resourceGroup) => {
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: resourceGroup?.orgId || null
          });

          dispatch({
            type: SET_RESOURCE_GROUP,
            resourceGroup,
            list: null
          });
          // Fetch groups inside the group
          dispatch(getResourceGroupsByParentId({ parentId: groupId, orgId }));
          // Fetch resources inside the group
          dispatch(getResourcesByParentId(groupId));
          resolve(resourceGroup);
        })
        .catch((error) => {
          console.error('getResourceGroup', error);
          reject(error);
        });
    });
  };
};

export const createResourceGroup = (data) => {
  return (dispatch, getState) => {
    const { resourceGroup, resourceGroups, currentUser } = getState();
    const userId = currentUser?.id || '';
    const userName = currentUser?.name || '';
    const resourceGroupsList = resourceGroups?.list || null;

    return new Promise((resolve, reject) => {
      const isChild = Boolean(resourceGroup?.id);

      const dataToSave = {
        ...data,
        ...(isChild ? {} : { topLevel: true }),
        ...(isChild ? { parentId: resourceGroup?.id } : {}),
        ...(isChild ? { parentIds: resourceGroups?.groupIds } : {}),
        authorUserId: userId,
        authorUserName: userName
      };

      createSupabaseEntry({
        table: 'resourceGroups',
        data: dataToSave
      })
        .then((response) => {
          const list = [...resourceGroupsList, dataToSave]; // TODO is this needed?

          dispatch({
            type: CREATE_RESOURCE_GROUP,
            list
          });

          resolve(response);
        })
        .catch((error) => {
          console.error('createResourceGroup', error);
          reject(error);
        });
    });
  };
};

export const resetResourceGroups = () => {
  return (dispatch) => {
    dispatch({ type: RESET_RESOURCE_GROUPS });
  };
};

export const resetResourceGroup = () => {
  return (dispatch) => {
    dispatch({ type: RESET_RESOURCE_GROUP });
  };
};
