import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZ_OUTCOMES } from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';

const Tabs = ({ className = '', active = 'outcomes', userId }) => {
  // Redux
  const course = useSelector((state) => state.course);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Course
  const classId = course?.id || null;

  return (
    <div className={`${className}`}>
      <ul className="nav nav-tabs pl-3">
        <li className="nav-item">
          <Link
            title="Outcomes"
            className={`disabled nav-link ${active === 'outcomes' ? 'active' : ''} d-flex align-items-center`}
            // to={routeWithClassId({
            //   route: `${CLASS_CONSOLE_QUIZZES}/${quizId}`,
            //   classId
            // })}
            to={routeWithClassId({
              route: `${CLASS_CONSOLE_QUIZ_OUTCOMES}/${userId}`,
              urlParams: {
                classId,
                redirectUrl: window.location.pathname
              }
            })}
          >
            <span>Outcomes</span>
            <span className="badge bg-white border px-2 py-1 ml-2">
              {quizOutcomes?.pagination?.total || 0}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
