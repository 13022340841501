import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LuCircuitBoard } from 'react-icons/lu';
import {
  canAccessAssistants,
  canAccessTurbineLMS
} from '../../permissions/access';
import {
  ORG_CONSOLE_ASSISTANTS,
  ORG_CONSOLE_COURSES,
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_RESOURCES
} from '../../constants/routes';
import {
  canAccessCourses,
  canAccessResources
} from '../../services/currentUser';
import {
  ICON_AGENTS,
  ICON_COURSES,
  ICON_RESOURCES
} from '../../constants/assets';
import AppsIcon from './AppsIcon';

const KnowledgeOpsApps = ({
  role = [],
  orgType,
  enableResources,
  className = ''
}) => {
  return (
    <div className={`topNav-apps-knowledgeOps ${className}`}>
      {canAccessTurbineLMS(role, orgType) && (
        <div className="nav-item">
          <Link
            to={ORG_CONSOLE_DASHBOARD}
            className="dropdown-item p-3 border-bottom w-100 bg-light"
            data-cy="topNavOrgLinkWorkforce"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="font-weight-bold d-flex align-items-center">
                <span
                  className="rounded bg-primary mr-2 text-white d-flex align-items-center justify-content-center"
                  style={{ width: '31px', height: '31px' }}
                >
                  <LuCircuitBoard size={20} />
                </span>

                <span>
                  <span className="d-block font-weight-bold ">
                    KnowledgeOps
                  </span>
                  {/* <span className='d-block font-weight-normal'>
                              Capture & Management
                            </span> */}
                </span>
              </div>
              <FaArrowCircleRight
                size={20}
                className="text-muted opacity-50"
              />
            </div>
          </Link>
        </div>
      )}
      <div className="topNav-apps py-3 bg-light overflow-hidden border-bottom d-flex justify-content-around align-items-center bkg-pattern-light">
        <Link
          to={ORG_CONSOLE_RESOURCES}
          className="btn btn-sm btn-white topNav-app-link"
          data-cy="topNavOrgLinkResources"
          disabled={!canAccessResources(role, enableResources, orgType)}
        >
          <span className="d-flex flex-column align-items-center">
            <span className="topNav-app-link-icon d-flex align-items-center">
              <AppsIcon
                src={ICON_RESOURCES}
                name="Resources"
              />
            </span>
            <span className="mt-1 small">Resources</span>
          </span>
        </Link>

        <Link
          className="btn btn-sm btn-white topNav-app-link"
          data-cy="topNavOrgLinkCourses"
          to={ORG_CONSOLE_COURSES}
          title="Courses"
          disabled={!canAccessCourses(role)}
        >
          <span className="d-flex flex-column align-items-center">
            <span className="topNav-app-link-icon d-flex align-items-center">
              <AppsIcon
                src={ICON_COURSES}
                name="Courses"
              />
            </span>
            <span className="mt-1 small">Courses</span>
          </span>
        </Link>

        <Link
          to={ORG_CONSOLE_ASSISTANTS}
          className="btn btn-sm btn-white topNav-app-link"
          data-cy="topNavOrgLinkAssistants"
          disabled={!canAccessAssistants(role)}
        >
          <span className="d-flex flex-column align-items-center">
            <span className="topNav-app-link-icon d-flex align-items-center">
              <AppsIcon
                src={ICON_AGENTS}
                name="Agents"
              />
            </span>
            <span className="mt-1 small">Assistants</span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default KnowledgeOpsApps;
