import {
  RESET_APPRENTICE_AGREEMENT,
  SET_APPRENTICE_AGREEMENT
} from '../../actions/types';
import apprenticeAgreementState from '../initialState/apprenticeAgreementState';

const initialState = {
  ...apprenticeAgreementState,
  fetched: false
};

export default function apprenticeAgreement(state = initialState, action) {
  switch (action.type) {
    case SET_APPRENTICE_AGREEMENT:
      return {
        ...state,
        ...action.apprenticeAgreement,
        fetched: true
      };
    case RESET_APPRENTICE_AGREEMENT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
