import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { redirectToAuth } from '../../services/auth';
import { currentPageTitle } from '../../services/global';
import { getOrg } from '../../actions/Organizations';
import { setCurrentPage, setLoading } from '../../actions/Session';
import { setCurrentUser } from '../../actions/Users';
import { setCurrentModal } from '../../actions/Modals';
import { SSO } from '../../constants/routes';
// import { MODAL_KEY_RELOAD } from '../../constants/modals';
import { TURBINE_ADMIN } from '../../constants/urls';
import {
  ACCOUNT_INACTIVE,
  PERM_ROLE_TEACHER_ADMIN
} from '../../constants/errors';
import {
  canAccessTurbineAdmin,
  isActiveMembership
} from '../../services/currentUser';
import {
  fetchOrg
  // usingLatestAppVersion
} from '../../services/organizations';
import convertUrlParamsToObject from '../../utils/convertUrlParamsToObject';
import convertObjectToUrlParams from '../../utils/convertObjectToUrlParams';
import isPublished from '../../utils/isPublished';
import Loading from '../Loading';
import goToTop from '../../utils/goToTop';
import onAuthUserListener from '../../utils/onAuthUserListener';

const createSearchQuery = (searchParams) => {
  const {
    location: { href, search }
  } = window;
  const baseUrl = href.replace(search, '');
  const searchQuery = { token: searchParams.token };
  const continueUrlParams = { ...searchParams };

  delete continueUrlParams.token; // Don't pass along token in continueUrl

  searchQuery.continueUrl = encodeURIComponent(
    baseUrl + convertObjectToUrlParams(continueUrlParams)
  );

  return convertObjectToUrlParams(searchQuery);
};

const goToSSO = (searchParams) => {
  const search = createSearchQuery(searchParams);
  const ssoUrl = TURBINE_ADMIN + SSO + search;

  window.location = ssoUrl;
};

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      goToTop();

      const {
        // organization, // TODO user flatten (uncomment after migration)
        location: { search: locationSearch }
      } = this.props;
      const searchParams = convertUrlParamsToObject(locationSearch);
      const hasToken = searchParams && searchParams.token;

      this.listener = onAuthUserListener(
        (response) => {
          if (!condition(response.authUser)) {
            if (hasToken) {
              goToSSO(searchParams);
            } else {
              redirectToAuth({});
            }

            return;
          }

          if (!response.currentUser) {
            // No data, send to auth
            redirectToAuth({});

            return;
          }

          const role = response?.currentUser?.role || []; // TODO user roles
          const membership = response?.currentUser?.membership || []; // TODO user roles
          const orgId = response?.currentUser?.orgId || null;

          this.props
            .getOrg({ orgId })
            .then(({ organization }) => {
              const orgSlug = organization?.slug || null;
              const orgType = organization?.type || null;
              const isActive = isActiveMembership(membership);

              // Allow "Active" Workforce employees to access
              // All other org types, users must be teacher or greater to access
              if (!canAccessTurbineAdmin(role, orgType) || !isActive) {
                redirectToAuth({
                  organizationSlug: orgSlug,
                  error: {
                    code: isActive ? PERM_ROLE_TEACHER_ADMIN : ACCOUNT_INACTIVE
                  }
                });

                return;
              }

              this.props.setCurrentUser(response.currentUser);
              this.getCurrentPage();
            })
            .catch((error) => {
              // No org data, send to auth
              redirectToAuth({});
              console.error(error);
            });
        },
        () => {
          if (hasToken) {
            goToSSO(searchParams);
          } else {
            const { organization } = this.props;
            const orgSlug = organization?.slug || null;

            if (orgSlug) {
              redirectToAuth({
                organizationSlug: orgSlug
              });

              return;
            }

            if (searchParams?.orgId) {
              fetchOrg({
                orgId: searchParams?.orgId,
                select: ['fields.slug']
              }).then((responseOrg) => {
                redirectToAuth({
                  organizationSlug: responseOrg?.slug
                });
              });

              return;
            }

            redirectToAuth({});
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    getCurrentPage() {
      const {
        app,
        location: { pathname }
      } = this.props;
      const currentPage = app?.currentPage || null;
      const pageTitle = currentPageTitle({ pathname });

      if (currentPage !== pageTitle) {
        this.props.setCurrentPage(pageTitle);
      }
    }

    render() {
      const { user, currentUser, organization } = this.props;

      return condition(user) &&
        isPublished(currentUser) &&
        isPublished(organization) ? (
        <Component {...this.props} />
      ) : (
        <Loading className="position-fixed" />
      );
    }
  }

  const mapStateToProps = ({
    user,
    currentUser,
    course,
    currentEntry,
    organization,
    currentAnswer,
    app
  }) => ({
    user,
    currentUser,
    course,
    currentEntry,
    organization,
    currentAnswer,
    app
  });

  return compose(
    withRouter,
    connect(mapStateToProps, {
      setLoading,
      setCurrentUser,
      setCurrentPage,
      getOrg,
      setCurrentModal
    })
  )(WithAuthorization);
};

export default withAuthorization;
