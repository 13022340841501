import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { MdOutlineSpaceDashboard } from 'react-icons/md';
// import { FaUsersViewfinder } from 'react-icons/fa6';
import { FaBell, FaToolbox } from 'react-icons/fa';
import { SiLightning } from 'react-icons/si';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { useFlags, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import { isSuperAdmin } from '@apprentage/utils';
import {
  FLAG_APP_TURBINE_ADMIN,
  FLAG_MAINTENANCE_MODE,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import {
  ONBOARDING,
  ORG_CONSOLE_NOTIFICATIONS,
  SLASH
} from '../../constants/routes';
import { canAccessResources } from '../../services/currentUser';
import {
  entryOrgMatchesUserOrg,
  usingLatestAppVersion
} from '../../services/organizations';
import { setCurrentModal } from '../../actions/Modals';
import {
  // MODAL_KEY_ONBOARD_WELCOME,
  MODAL_KEY_NOTIFICATIONS_MENU,
  MODAL_KEY_RELOAD
} from '../../constants/modals';
// import pageTitleByPathname from './pageTitleByPathname';
import TopNavWeather from '../Weather/TopNavWeather';
import SearchBoxLauncher from './SearchBoxLauncher';
import TopNavLocation from '../TopNavLocation';
import TopNavApps from '../TopNavApps';
import UserMenu from './UserMenu';
import './style.css';
import { canAccessTopNavToolbox } from '../../permissions/access';
import { SHEET_KEY_ACTIONS } from '../../constants/sideSheets';
import { setSideSheet } from '../../actions/SideSheets';
import { fetchUnansweredQuestions } from '../../services/contextQuestions';

const TopNav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const flags = useFlags([FLAG_APP_TURBINE_ADMIN, FLAG_MAINTENANCE_MODE]);
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const app = useSelector((state) => state.app);
  const currentModal = useSelector((state) => state.currentModal);
  // App
  const currentPage = app?.currentPage || null;
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || '';
  const enablePages = organization?.enablePages || false;
  const enableResources = organization?.enableResources || false;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  const enableProjects = organization?.enableProjects || false;
  // Current User
  const userId = currentUser?.id || null;
  const userOrgId = currentUser?.orgId || '';
  const role = currentUser?.role || [];
  // Route Match
  const isOrgNotificationsRoute = useRouteMatch(ORG_CONSOLE_NOTIFICATIONS);
  // Local State
  const [numContextQuestions, setNumContextQuestions] = useState(null);

  useEffect(() => {
    if (orgId && userId) {
      fetchUnansweredQuestions({ orgId, userId }).then((rsp) => {
        setNumContextQuestions(
          Array.isArray(rsp.items) ? rsp.items.length : null
        );
      });
    }
  }, [orgId, userId]);

  const openAppsMenu = () => {
    dispatch(
      setCurrentModal({
        key: 'appsMenu'
      })
    );
  };

  const openActionsSideSheet = () => {
    dispatch(
      setSideSheet({
        key: SHEET_KEY_ACTIONS,
        className: 'MaterialSideSheet ImportMaterialSideSheet'
        // data: {}
        // callbackPrimaryAction: () => {
        //   // retrieveMaterials();
        // }
      })
    );
  };

  const openNotificationsMenu = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_NOTIFICATIONS_MENU
      })
    );
  };

  useEffect(() => {
    if (app?.entryOrgId && userOrgId) {
      if (!entryOrgMatchesUserOrg(app?.entryOrgId, userOrgId)) {
        toast.error('Access to this Entry is not allowed (ORG)');
      }
    }
  }, [app?.entryOrgId, userOrgId]);

  useEffect(() => {
    if (!location?.pathname.includes(ONBOARDING)) {
      // currentModal?.key !== MODAL_KEY_ONBOARD_WELCOME &&
      if (currentUser?.id && !currentUser?.onboarded) {
        // dispatch(setCurrentModal({
        //   key: MODAL_KEY_ONBOARD_WELCOME
        // }));
        history.push(ONBOARDING);
      }
    }
  }, [
    currentModal?.key,
    currentUser?.id,
    currentUser?.onboarded,
    dispatch,
    history,
    location,
    location?.pathname
  ]);

  // FLAGS & REMOTE CONFIG
  useEffect(() => {
    if (!isLoading) {
      getFlags();
    }

    if (
      flags?.app_turbine_admin?.enabled &&
      flags?.app_turbine_admin?.value !== undefined
    ) {
      if (!usingLatestAppVersion(flags?.app_turbine_admin?.value)) {
        dispatch(
          setCurrentModal({
            key: MODAL_KEY_RELOAD,
            data: {
              appVersion: flags?.app_turbine_admin?.value
            }
          })
        );
      }
    }

    if (
      flags?.maintenance_mode?.enabled &&
      flags?.maintenance_mode?.value !== undefined
    ) {
      dispatch(
        showFeatureFlagNoticeModal({
          modalTitle: NOTICE_MAINTENANCE_MODE_TITLE,
          modalBody: NOTICE_MAINTENANCE_MODE_BODY,
          preventClose: true
        })
      );
    }
  }, [
    location,
    getFlags,
    isLoading,
    flags?.maintenance_mode,
    flags?.app_turbine_admin,
    dispatch
  ]);
  // /FLAGS & REMOTE CONFIG

  if (!orgId || !currentUser.id) {
    // TODO Skeleton Loading
    return null;
  }

  if (location.pathname.includes('onboarding')) {
    return null;
  }

  return (
    <nav className="navbar sticky-top bg-white border-bottom p-0 d-block">
      <div className="d-flex align-items-center justify-content-between flex-md-nowrap">
        <div className="d-flex align-items-center">
          <Link
            className="top-nav-trigger border-right"
            data-cy="topNavOrgLinkUsers"
            to={SLASH}
          >
            <OrgLogo
              src={organization?.orgLogo}
              alt={organization?.name}
              height="30"
            />
          </Link>

          {canAccessResources(role, enableResources, orgType) &&
            currentPage !== 'search' && (
              <div className="d-flex align-items-center">
                <SearchBoxLauncher
                  className="in-top-nav"
                  placeholder="Ask or Search..."
                />
              </div>
            )}

          {/* <div className="d-none d-md-flex align-items-center">
            <span className="h5 m-0 font-weight-bold d-none d-lg-block">
              {pageTitleByPathname({ pathname, orgType })}
            </span>
            <span className="h6 m-0 font-weight-bold d-block d-lg-none">
              {pageTitleByPathname({ pathname, orgType })}
            </span>
          </div> */}
        </div>

        {currentPage !== 'Update Required' && (
          <div className="d-flex align-items-center">
            {!isOrgNotificationsRoute && (
              <button
                type="button"
                className="topNav-icon-dropdown-btn"
                onClick={openNotificationsMenu}
                aria-label="Notifications"
                style={{
                  width: '40px'
                }}
              >
                <FaBell size={18} />
              </button>
            )}

            {canAccessTopNavToolbox(role) && (
              <button
                type="button"
                className="topNav-icon-dropdown-btn"
                onClick={openAppsMenu}
                title="Toolbox"
                aria-label="Toolbox"
                style={{
                  width: '35px'
                }}
              >
                <FaToolbox size={18} />
              </button>
            )}

            {isSuperAdmin(role) && (
              <button
                type="button"
                className="topNav-icon-dropdown-btn position-relative"
                onClick={openActionsSideSheet}
                title="PULSE"
                aria-label="PULSE"
                style={{
                  width: '35px'
                }}
              >
                <SiLightning size={18} />
                {numContextQuestions && (
                  <span
                    className="badge badge-primary text-white rounded position-absolute"
                    style={{
                      fontSize: '10px',
                      top: '10px',
                      right: '-2px'
                    }}
                  >
                    {numContextQuestions}
                  </span>
                )}
              </button>
            )}

            <TopNavApps
              role={role}
              orgId={orgId}
              orgType={orgType}
              enableResources={enableResources}
              enableWorkforceSuite={enableWorkforceSuite}
              enablePages={enablePages}
              enableProjects={enableProjects}
            />

            {orgType === 'workforce' && <TopNavWeather />}

            <UserMenu />
          </div>
        )}
      </div>
      <TopNavLocation />
    </nav>
  );
};

export default TopNav;
