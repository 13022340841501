import React, { useMemo } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const PaginationSupabase = ({
  items,
  pagination, // { rangeTo, rangeFrom, total, limit }
  page,
  onClickNext,
  onClickPrev,
  className = 'border-top py-3',
  style = {
    position: 'sticky',
    bottom: 0,
    background: 'white'
  }
}) => {
  const total = pagination?.total;
  const limit = pagination?.limit;
  const rangeTo = pagination?.rangeTo + 1;
  const rangeFrom = pagination?.rangeFrom + 1;
  const numPages = total >= items.length ? Math.ceil(total / limit) : 1;

  const nextDisabled = rangeTo >= total;
  const prevDisabled = page === 1;

  const rangeToReadable = useMemo(() => {
    if (rangeTo > total) {
      return total;
    }
    return rangeTo;
  }, [rangeTo, total]);

  if (!items || (items && !items.length) || !pagination) {
    return null;
  }

  if (numPages === 1) {
    return null;
  }

  return (
    <div
      style={style}
      className={`d-flex justify-content-end align-items-center ${className}`}
    >
      <div className="d-none d-sm-flex mr-3">
        {rangeFrom} - {rangeToReadable} of {total} items
      </div>
      <div className="mr-2">
        <button
          className={`mr-2 btn btn-sm ${prevDisabled ? 'btn-outline-secondary' : 'btn-outline-primary'}`}
          tabIndex="-1"
          aria-disabled="true"
          onClick={onClickPrev}
          disabled={prevDisabled}
          type="button"
        >
          <span className="d-flex align-items-center">
            <FaChevronLeft />
            <span className="ml-2">Prev</span>
          </span>
        </button>

        <button
          className={`btn btn-sm ${nextDisabled ? 'btn-outline-secondary' : 'btn-outline-primary'}`}
          tabIndex="-1"
          aria-disabled="true"
          onClick={onClickNext}
          disabled={nextDisabled}
          type="button"
        >
          <span className="d-flex align-items-center">
            <span className="mr-2">Next</span>
            <FaChevronRight />
          </span>
        </button>
      </div>
    </div>
  );
};

export default PaginationSupabase;
