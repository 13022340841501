import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useHistory, useParams, Redirect } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { canAccessAllLocations } from '@apprentage/utils';
import { withAuthorization } from '../../../../Session';
import {
  ORG_CONSOLE_LOCATIONS,
  ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS,
  SLASH
} from '../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { routeWithOrgId } from '../../../../../services/organizations';
import {
  resetEmployer,
  resetEmployerProfile
} from '../../../../../actions/Employers';
import { resetInvites } from '../../../../../actions/Invites';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import EmployersList from '../../../../EmployersList';
import DotMenuEmployers from './DotMenuEmployers';
import DotMenu from '../DotMenu';

const ApprenticeshipEmployers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employers = useSelector((state) => state.employers);
  const currentUser = useSelector((state) => state.currentUser);
  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  // Current User
  const role = currentUser?.role || [];
  // Routing
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const employerIds = apprenticeship?.employerIds || [];
  const hasEmployers =
    Array.isArray(apprenticeship?.employerIds) &&
    apprenticeship?.employerIds.length > 0;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, [apprenticeshipId, dispatch]);

  useEffect(() => {
    // TODO this set of reset actions may not be complete
    dispatch(resetEmployer());
    dispatch(resetInvites());
    dispatch(resetEmployerProfile());

    return function cleanup() {
      const { href } = window ? window.location : undefined;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipRoute, dispatch]);

  const handleClickRow = (employer) => {
    const route = routeWithOrgId({
      route: `${apprenticeshipRoute}/employer/${employer.id}`,
      orgId
    });

    history.push(route);
  };

  if (!canAccessAllLocations(role)) {
    return <Redirect to={SLASH} />;
  }

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="employers" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle="Program Employers"
              className="mb-3"
              badge={() => (
                <span className="ml-2 badge bg-white border">
                  {hasEmployers ? employerIds.length : 0}
                </span>
              )}
            >
              <DotMenuEmployers />
            </OrgConsoleSubheader>

            {hasEmployers && (
              <EmployersList
                locationIds={employerIds}
                handleClickRow={handleClickRow}
                apprenticeshipId={apprenticeshipId}
                hiddenColumns={['managerIds']}
              />
            )}

            <div className="py-4 d-flex justify-content-center flex-column">
              <div className="mt-3 w-100 text-center">
                {!hasEmployers && (
                  <div className="mb-3">No Employers selected.</div>
                )}

                {((hasEmployers && employers.fetched) || !hasEmployers) && (
                  <div className="dropdown">
                    <button
                      className="btn btn-primary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="mr-1">Add Employers</span>
                    </button>
                    <div className="dropdown-menu p-0">
                      <Link
                        className="dropdown-item py-2 border-bottom"
                        to={routeWithOrgId({
                          route: `${ORG_CONSOLE_LOCATIONS}/manage`,
                          orgId
                        })}
                        title="Add New Employer"
                      >
                        <span className="d-flex align-items-center">
                          <FaPlus />
                          <span className="ml-2">Add New</span>
                        </span>
                      </Link>
                      <Link
                        className="dropdown-item py-2"
                        to={routeWithOrgId({
                          route: `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/manage/${apprenticeshipId}`,
                          urlParams: {
                            orgId,
                            scrollToId: 'apprenticeshipEmployersSelect'
                          }
                        })}
                        title="Add Existing Employer"
                      >
                        Add Existing
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipEmployers);
