import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { routeWithOrgId } from '../../../../../services/organizations';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import DotMenuCourses from './DotMenuCourses';
import DotMenu from '../DotMenu';
import CoursesList from '../../../../CoursesList';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';

const ApprenticeshipCourses = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // misc
  const orgId = organization?.id || null;
  const apprenticeshipId = params?.apprenticeshipId || null;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    return function cleanup() {
      const { href } = window.location;
      const apprenticeshipRoute = `org/workforce/apprenticeships/${apprenticeshipId}`;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            image={ICON_TRAINING_PROGRAMS}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="courses" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle="Courses"
              className="mb-3"
            >
              <DotMenuCourses />
            </OrgConsoleSubheader>

            {apprenticeship.courseIds &&
            Array.isArray(apprenticeship.courseIds) &&
            apprenticeship.courseIds.length ? (
              <CoursesList
                courseIds={apprenticeship.courseIds}
                showGroupsCol
              />
            ) : (
              <div className="py-5 d-flex justify-content-center flex-column">
                <div className="mt-3 w-100 text-center">
                  <div className="mb-3">No Courses selected.</div>

                  <Link
                    className="btn btn-primary btn-sm"
                    to={routeWithOrgId({
                      route: `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/manage/${apprenticeshipId}`,
                      urlParams: {
                        orgId,
                        scrollToId: 'apprenticeshipCoursesSelect'
                      }
                    })}
                    title="Manage Courses"
                  >
                    Manage Courses
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipCourses);
