import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaCog, FaUsers } from 'react-icons/fa';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../../actions/FeatureFlags';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import UsersList from '../../../../UsersList';
import { setCurrentModal } from '../../../../../actions/Modals';
import { updateUser } from '../../../../../services/user';
import { getApprenticeshipUser } from '../../../../../actions/Apprenticeships';
import { canManageProgramTrainers } from '../../../../../permissions/manage';
import { MODAL_KEY_SEARCH_SELECT_USERS } from '../../../../../constants/modals';
import { canAccessAllApprenticeshipUsers } from '../../../../../permissions/access';

const TrainersBlock = ({ title = 'Trainers', className = 'card mt-3' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const locationId = currentUser?.locationId || null;
  // Apprenticeship
  const apprenticeshipId = params.apprenticeshipId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || null;
  const supervisorIds = useMemo(() => {
    return apprenticeshipUser?.supervisorIds || [];
  }, [apprenticeshipUser?.supervisorIds]);
  const apprenticeshipUserId = apprenticeshipUser?.id || null;
  const apprenticeshipUserEmployerId = apprenticeshipUser?.locationId || null;

  const handleClickRow = (clickedUser) => {
    const route = `${apprenticeshipRoute}/users/${clickedUser.id}`;

    history.push(
      routeWithOrgId({
        route,
        orgId
      })
    );
  };

  const manageTrainers = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(
        showFeatureFlagNoticeModal({
          modalTitle: NOTICE_USERS_READONLY_TITLE,
          modalBody: NOTICE_USERS_READONLY_BODY
          // preventClose: true
        })
      );
      return;
    }

    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: 'Manage Trainers',
          ids: supervisorIds,
          searchFilters: {
            locationId: apprenticeshipUserEmployerId,
            roles: ['programManager', 'programTrainer']
          }
        },
        callbackPrimaryAction: (newSupervisorIds) => {
          toast.info('Updating Trainers...');
          updateUser(
            {
              supervisorIds: newSupervisorIds
            },
            apprenticeshipUserId
          ).then(() => {
            toast.success('Trainers updated!');

            dispatch(
              getApprenticeshipUser(
                apprenticeshipUserId,
                apprenticeshipId,
                orgId
              )
            );
            // setTimeout(() => {
            //   document.location.reload();
            // }, 500);
          });
        }
      })
    );
  };

  const hiddenColumns = useMemo(() => {
    const cols = [
      'locationId',
      'isApprentice',
      'userProfileId',
      'remindersCell',
      'wageScheduleCell'
    ];

    return cols;
  }, []);

  const userListConfig = useMemo(
    () => ({
      orgId,
      apprenticeshipId,
      roles: ['programTrainer', 'programManager'],
      userIds: supervisorIds,
      excludeTestUsers: true,
      excludeInactive: true,
      ...(!canAccessAllApprenticeshipUsers(role) ? { locationId } : {})
    }),
    [apprenticeshipId, locationId, orgId, role, supervisorIds]
  );

  const [listConfig] = useState(userListConfig);

  return (
    <div className={className}>
      <div className="card-header d-flex align-items-center justify-content-between">
        <div className="h6 mb-0 d-flex align-items-center">
          <FaUsers
            size={20}
            className="mr-2"
          />
          <span>{title}</span>
        </div>

        {canManageProgramTrainers(role) && (
          <button
            className="btn btn-sm btn-outline-secondary"
            type="button"
            onClick={manageTrainers}
            aria-label="Settings"
          >
            <FaCog />
          </button>
        )}
      </div>

      {supervisorIds.length !== 0 ? (
        <UsersList
          key={supervisorIds.join(',')}
          showRole
          handleClickRow={handleClickRow}
          hiddenColumns={hiddenColumns}
          listConfig={listConfig}
        />
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '100px' }}
        >
          No Trainers.
        </div>
      )}
    </div>
  );
};

export default TrainersBlock;
