import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle } from 'react-icons/fa';
import { fetchUsersNoLogbook } from '../../../services/users';
import TableLoading from '../../ManageContent/Tables/TableLoading';
import List from './List';

const UsersNoLogbookList = ({ onClickName }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;

  const [fetched, setFetched] = useState(false);
  const [list, setList] = useState(null);

  useEffect(() => {
    if (orgId) {
      fetchUsersNoLogbook({
        orgId,
        ...(apprenticeshipId ? { apprenticeshipId } : {})
      })
        .then((rsp) => {
          setList(rsp.items);
        })
        .finally(() => {
          setFetched(true);
        });
    }
  }, [apprenticeshipId, dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      setFetched(false);
    };
  });

  if (!fetched && !list) {
    return <TableLoading />;
  }

  if (Array.isArray(list) && list.length > 0) {
    return (
      <div className="UsersNoLogbook-list">
        <List
          onClickName={onClickName}
          data={list}
          orgType={orgType}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column text-center justify-content-center my-5">
      <div className="d-flex justify-content-center">
        <FaCheckCircle
          className="text-keppel"
          size={30}
        />
      </div>
      <div className="mt-3 h5">Trainees are using their Logbooks.</div>
    </div>
  );
};

export default UsersNoLogbookList;
