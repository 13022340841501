import React from 'react';
import { RiShieldCheckFill } from 'react-icons/ri';

const NoPiiAi = () => {
  return (
    <div
      id="NoPiiAi"
      className="mce-content-body vela-insights-text"
    >
      <p className="d-inline-flex align-items-center mb-0">
        <RiShieldCheckFill
          size={20}
          className="text-primary mr-1"
        />
        <strong>No PII in AI</strong>
      </p>

      <ul>
        <li>
          Don't worry — no names, company info, or personal data were used by
          the AI to generate these insights.
        </li>
        <li>
          Our AI-enhanced workflows employ rigorous data anonymization and
          aggregation techniques to ensure that no personally identifiable
          information (PII) is processed or stored during AI analysis.
        </li>
        <li>
          Our commitment to privacy extends to our AI systems; we adhere to
          strict compliance standards and internal policies designed to ensure
          PII is not used in AI-generated outputs.
        </li>
      </ul>
    </div>
  );
};

export default NoPiiAi;
