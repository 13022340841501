import React, { useState } from 'react';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';
import { fetchResourceGroupTag } from '../../../../../services/resourceGroupTags';
import { setCurrentModal } from '../../../../../actions/Modals';
import { showFeatureFlagNoticeModal } from '../../../../../actions/FeatureFlags';
import { MODAL_KEY_MANAGE_RESOURCE_GROUP_TAG } from '../../../../../constants/modals';
import {
  FLAG_RESOURCE_TAGS_READONLY,
  NOTICE_RESOURCE_TAGS_READONLY_TITLE
} from '../../../../../constants/flagsmith';
import { fetchResourceGroupsByTags } from '../../../../../services/resourceGroups';

const EditCell = ({ row, column }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Flagsmith
  const flags = useFlags([FLAG_RESOURCE_TAGS_READONLY]);
  // Organization
  const orgId = organization?.id || null;
  // Row
  const id = row?.original?.id || null;
  const label = row?.original?.label || '';

  const [resourceGroupsList, setResourceGroupsList] = useState([]);

  const retrieveGroups = () => {
    if (orgId && id) {
      fetchResourceGroupsByTags({
        orgId,
        groupTags: [id]
      }).then((rsp) => {
        setResourceGroupsList(rsp.groups || []);
      });
    }
  };

  const onClickEdit = () => {
    if (
      flags?.resource_tags_readonly?.enabled &&
      flags?.resource_tags_readonly?.value
    ) {
      dispatch(
        showFeatureFlagNoticeModal({
          modalTitle: NOTICE_RESOURCE_TAGS_READONLY_TITLE
        })
      );
      return;
    }

    fetchResourceGroupTag(id)
      .then((resourceGroupTag) => {
        dispatch(
          setCurrentModal({
            key: MODAL_KEY_MANAGE_RESOURCE_GROUP_TAG,
            data: {
              resourceGroupTag
            },
            callbackPrimaryAction: () => {
              column.setListConfig((prev) => ({
                ...prev,
                /**
                 * Delete Considerations
                 *
                 * Ex: if limit=9, total=10, page=2
                 *
                 * If a user is on any page besides 1 and performs a delete
                 * check to see if by deleting the Tag, there wouldn't be
                 * any results remaining on the page,
                 *
                 */
                ...(prev.page > 1
                  ? { page: prev.page - 1 === 0 ? 1 : prev.page - 1 }
                  : {})
              }));
            },
            callbackSecondaryAction: () => {
              column.setListConfig((prev) => ({
                ...prev
              }));
            }
          })
        );
      })
      .catch((error) => {
        console.error('get resourceGroupTags', error);
      });
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
          onClick={retrieveGroups}
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6 className="m-0 text-nowrap">
              <span className="d-flex align-items-center">
                <span className="mr-2">{label}</span>
                <span className="badge bg-dark border">
                  <span>{resourceGroupsList.length}</span>
                </span>
              </span>
            </h6>
          </div>
          <button
            className="dropdown-item py-2"
            type="button"
            title={`Edit ${label}`}
            onClick={onClickEdit}
          >
            <span className="d-flex align-items-center">
              <FaCog />
              <span className="ml-2">Settings</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCell;
