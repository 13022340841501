import { useMemo } from 'react';

const TypeCell = ({ value: type }) => {
  const renderType = useMemo(() => {
    switch (type) {
      case 'embedRagResourceGroups':
        return 'RAG Resources';
      default:
        return type;
    }
  }, [type]);

  return renderType;
};

export default TypeCell;
