/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import {
  FaCloudDownloadAlt,
  FaDownload,
  FaFileAlt,
  FaTimes
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { VELA_ICON } from '@apprentage/constants';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  downloadResource,
  fetchResource,
  previewFileTypes
} from '../../../services/resources';
import { embedDoc, vectorSearchDocTypes } from '../../../services/openAi';
import { getResourcesByParentId } from '../../../actions/Resources';
import Modal from '../../Modal';
import Assistant from './Assistant';
import './style.css';

const ResourcePreview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  //  Current Modal
  const modalTitle = currentModal?.data?.modalTitle || '';
  const iframeTitle = currentModal?.data?.iframeTitle || modalTitle;
  const iframeSrc = currentModal?.data?.iframeSrc || '';
  const resource = currentModal?.data?.resource || {};
  // Organization
  const orgId = organization?.id || '';
  // Params
  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  const paramFileId = searchParams.get('fileId');
  // Local State
  const [showAssistant, setShowAssistant] = useState(false);

  // LLM
  const isEmbedded = resource.isEmbedded || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [embedding, setEmbedding] = useState(false);
  const [fileEmbedded, setFileEmbedded] = useState(isEmbedded);
  // /LLM

  const canPreviewFile = useMemo(() => {
    return previewFileTypes(resource?.type);
  }, [resource?.type]);

  const handleClose = () => {
    history.goBack();
    dispatch(resetCurrentModal());
  };

  const onClickDownload = () => {
    downloadResource({
      name: resource?.name,
      file: resource?.url,
      orgId
    }).then((downloadUrl) => {
      if (downloadUrl) {
        window.location.assign(downloadUrl);
      }
    });
  };

  const onError = (error) => {
    toast.dismiss('docEmbedInfo');
    setEmbedding(false);
    console.error(error);
    toast.error('Something went wrong, try again.', { autoClose: false });
  };

  const handleCreateEmbedding = async () => {
    setEmbedding(true);
    toast.info('Preparing Assistant...', {
      autoClose: false,
      toastId: 'docEmbedInfo'
    });

    try {
      const response = await embedDoc({
        refTable: 'resources',
        orgId,
        id: resource?.id,
        url: resource?.url
      });

      if (response?.error) {
        onError(response?.error);
        return;
      }

      toast.dismiss('docEmbedInfo');
      toast.success('Assistant is ready!');
      setEmbedding(false);
      setFileEmbedded(true);
      setShowAssistant(true);
      if (resourceGroup?.id) {
        dispatch(getResourcesByParentId(resourceGroup?.id));
      }
    } catch (error) {
      onError(error);
    }
  };

  const checkEmbeddingStatus = useCallback(async () => {
    setLoading(true);
    const response = await fetchResource(resource?.id);

    setFileEmbedded(response?.isEmbedded);

    setLoading(false);
  }, [resource?.id]);

  useEffect(() => {
    checkEmbeddingStatus();
  }, [checkEmbeddingStatus]);

  useEffect(() => {
    return function cleanup() {
      if (paramFileId) {
        searchParams.delete('fileId');

        history.replace(
          `${window.location.pathname}?${searchParams.toString()}`
        );
      }
    };
  }, [history, paramFileId, searchParams]);

  if (!currentModal?.visible) {
    return;
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark d-flex align-items-center justify-content-between">
          <h1
            className="h5 mb-0 text-white mr-2"
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '75%',
              overflow: 'hidden'
            }}
          >
            {modalTitle}
          </h1>
          <div className="d-flex align-items-center">
            {!fileEmbedded &&
              !loading &&
              resource?.type &&
              vectorSearchDocTypes.includes(resource?.type) && (
                <button
                  type="button"
                  title="Embed Resource"
                  className="mr-2 actionBtn btn btn-sm btn-primary"
                  onClick={handleCreateEmbedding}
                  disabled={fileEmbedded || loading || embedding}
                >
                  <span className="d-flex align-items-center">
                    {/* <FaMagic size={18} /> */}
                    <img
                      src={VELA_ICON}
                      alt="VELA"
                      className="d-none d-sm-inline-block"
                      height={20}
                      style={{
                        height: '20px'
                      }}
                    />
                    <span className="d-none d-sm-inline-block font-weight-bold ml-2">
                      {embedding ? 'Preparing...' : 'Assistant'}
                    </span>
                  </span>
                </button>
              )}

            {fileEmbedded && vectorSearchDocTypes.includes(resource?.type) && (
              <button
                className={`mr-2 actionBtn btn btn-sm ${
                  showAssistant
                    ? 'btn-white text-muted'
                    : 'btn-primary text-white'
                }`}
                onClick={() => {
                  setShowAssistant(!showAssistant);
                }}
                disabled={loading}
                title="Assistant"
                aria-label="Assistant"
                type="button"
                // data-tooltip-content="Show Resource Assistant"
                // data-tooltip-id="ResourcePreview"
                data-tip={`${showAssistant ? 'Hide' : 'Show'} Assistant`}
                data-for="ResourcePreview"
              >
                <span className="d-flex align-items-center">
                  {/* <FaMagic size={18} /> */}
                  <img
                    src={VELA_ICON}
                    alt="VELA"
                    className="d-none d-sm-inline-block"
                    height={20}
                    style={{
                      height: '20px'
                    }}
                  />
                  <span className="font-weight-bold ml-2">
                    {loading ? 'Loading...' : 'Assistant'}
                  </span>
                </span>
              </button>
            )}

            <button
              className="actionBtn btn btn-sm btn-outline-primary mr-2"
              style={{
                padding: '0.3rem .5rem',
                borderColor: 'transparent'
              }}
              onClick={onClickDownload}
              title="Download"
              aria-label="Download"
              type="button"
              // data-tooltip-content="Download"
              // data-tooltip-id="ResourcePreview"
              data-tip="Download"
              data-for="ResourcePreview"
            >
              <FaDownload size={18} />
            </button>
            <button
              className="actionBtn"
              onClick={handleClose}
              title="Close"
              aria-label="Close"
              type="button"
            >
              <FaTimes size={25} />
            </button>
          </div>
        </div>
        <div
          className="h-100 bkg-pattern-dark container-fluid"
          style={{
            maxWidth: 'none'
          }}
        >
          <div className="row h-100">
            {showAssistant && (
              <div className="col-12 col-md-6 bkg-pattern-dark">
                <Assistant />
              </div>
            )}
            <div
              id="resource-preview-file-col"
              className={`p-0 ${
                showAssistant ? 'd-none d-md-block col-6 bg-light' : 'col-12'
              }`}
            >
              {canPreviewFile ? (
                <iframe
                  title={iframeTitle}
                  src={iframeSrc}
                />
              ) : (
                <div
                  // className={`d-flex justify-content-center h-100 px-3 ${showAssistant && 'bg-light'}`}
                  className="d-flex justify-content-center h-100 px-3"
                >
                  <div className="m-auto d-flex flex-column justify-content-center">
                    <div className="mx-auto mb-2">
                      <FaFileAlt
                        size={40}
                        className={!showAssistant && 'text-white'}
                      />
                    </div>
                    <div
                      className={`h4 text-center mb-3 ${
                        !showAssistant && 'text-white'
                      }`}
                    >
                      {resource?.name}
                    </div>
                    <div className="mx-auto">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={onClickDownload}
                      >
                        <span className="d-flex align-items-center">
                          <FaCloudDownloadAlt />
                          <span className="ml-2">Download</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="ResourcePreview" />
    </Modal>
  );
};

export default ResourcePreview;
