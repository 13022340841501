import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Switch from '../../Switch';
import { updatePageLink } from '../../../services/pageLinks';

const TitleSwitch = ({
  className = '',
  defaultValue = false,
  onSuccessCallback
}) => {
  const pageLink = useSelector((state) => state.pageLink);
  const [showTitle, setShowTitle] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const toastId = 'errorSavePageLink';

  const errorSaving = (error) => {
    console.error(error);
    toast.error('Something went wrong, try again.', {
      autoClose: false,
      toastId
    });
    setShowTitle(!showTitle);
    setLoading(false);
  };

  const onChangeShowTitle = () => {
    const value = !showTitle;

    setShowTitle(value);
    setLoading(true);

    updatePageLink({ showTitle: value === false ? null : true }, pageLink?.id)
      .then((response) => {
        if (response?.error) {
          errorSaving(response?.error);
          return;
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }

        toast.success('Title visibility updated!');
        setLoading(false);
      })
      .catch((error) => {
        errorSaving(error);
      });
  };

  return (
    <div className={`${className} d-flex justify-content-between`}>
      <label
        className="h6 mb-0 font-weight-bold"
        htmlFor="pageLinkShowTitle"
      >
        Title
      </label>
      <Switch
        id="pageLinkShowTitle"
        // label="Visible"
        value={showTitle}
        disabled={loading}
        onChange={onChangeShowTitle}
      />
    </div>
  );
};

export default TitleSwitch;
