function svgToCanvasAndDownload(svgElement, filename = 'download.png', id) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    // Convert SVG to Data URL
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const svgDataURL = `data:image/svg+xml;base64,${btoa(svgData)}`;

    // Load SVG as image
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d', { alpha: false });

      // Set canvas dimensions
      canvas.width = img.width * 2;
      canvas.height = img.height * 2;

      // Draw SVG image onto canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      ctx.font = '10px Arial';
      ctx.letterSpacing = '2px';
      ctx.fillText(
        `turbine | ${id}`,
        canvas.width / 2 - 60,
        canvas.height - 12
      );

      // Create download link
      const link = document.createElement('a');
      link.download = filename;
      link.href = canvas.toDataURL('image/png');
      link.click();

      resolve();
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = svgDataURL;
  });
}

export default svgToCanvasAndDownload;
