import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withAuthorization } from '../../../components/Session';
import { SLASH } from '../../../constants/routes';
import { getPage, resetPage } from '../../../actions/Pages';
import { canAccessPages } from '../../../permissions/access';
import { TURBINE_PAGE } from '../../../constants/urls';
import { updateDashboardData } from '../../../services/dashboards';
import withOrgConsole from '../../../components/App/withOrgConsole';
import ChangeDashboardLayout from '../../../components/Dashboard/ChangeDashboardLayout';
import Phone from '../components/Phone';
import Loading from '../../../components/Loading';
import TopNav from '../components/TopNav';
import LeftNav from '../components/LeftNav';
import Title from '../Settings/TItle';
import Logo from './Logo';
import '../style.css';

const PageAppearance = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  const dashboard = useSelector((state) => state.dashboard);
  // Organization
  const orgId = organization?.id || null;
  // Page
  const pageId = params?.pageId || null;
  const pageLinkId = useMemo(() => {
    return page?.pageLinkId || null;
  }, [page?.pageLinkId]);

  // Current User
  const role = currentUser?.role || [];
  const [selectedLayout, setSelectedLayout] = useState(dashboard?.activeLayout);
  // Local State
  const turbinePagesNavCollapse = window?.localStorage?.getItem(
    'turbine-pages-nav-collapse'
  );
  const [collapseNav, setCollapseNav] = useState(
    turbinePagesNavCollapse || false
  );

  const qrCodeUrl = useMemo(() => {
    if (pageLinkId && pageLink?.id) {
      return `${TURBINE_PAGE}/link/${pageLinkId}`;
    }

    return null;
  }, [pageLink?.id, pageLinkId]);

  const handleSelectLayout = (layout) => {
    setSelectedLayout(layout);

    updateDashboardData({ activeLayout: layout }, dashboard?.id).then(() => {
      toast.success('Layout updated!');
    });
  };

  const refreshPreview = () => {
    const iframe = document.getElementById('page-preview');
    const src = iframe.getAttribute('src');

    iframe.setAttribute('src', '');
    iframe.setAttribute('src', src);
  };

  const onSaveTitle = () => {
    refreshPreview();
    dispatch(getPage(page?.id));
  };

  useEffect(() => {
    if (pageId) {
      dispatch(getPage(pageId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }

    return function cleanup() {
      dispatch(resetPage());
    };
  }, [dispatch, orgId, pageId]);

  if (!canAccessPages(role)) {
    return <Redirect to={SLASH} />;
  }

  if (pageId && !page?.id) {
    return <Loading text="Loading Page..." />;
  }

  return (
    <div
      id="body-row"
      className="row"
    >
      <LeftNav
        id="sidebar-container"
        collapseNav={collapseNav}
        setCollapseNav={setCollapseNav}
      />

      <div
        role="main"
        className="col p-3"
        style={{
          marginLeft: collapseNav ? '50px' : '230px'
        }}
      >
        <TopNav />

        <div className="row mb-4">
          <div className={`col-12 ${collapseNav ? 'col-lg-8' : 'col-xl-8'}`}>
            <div className="mb-3">
              <h5 className="font-weight-bold mb-2">Header</h5>
              <div className="bg-white p-3 rounded shadow">
                <Logo onSuccessCallback={refreshPreview} />
                <Title
                  onSuccessCallback={onSaveTitle}
                  className="mb-3"
                  showSwitch
                />
              </div>
            </div>

            <div className="mb-3">
              <h5 className="font-weight-bold">Layouts</h5>
              <ChangeDashboardLayout
                className="bg-white rounded py-3 p-md-5 shadow"
                activeLayout={selectedLayout || dashboard?.activeLayout}
                selectedLayout={selectedLayout}
                handleSelectLayout={handleSelectLayout}
              />
            </div>
          </div>

          <div
            className={`d-none col-12 ${collapseNav ? 'col-lg-4 d-lg-block' : 'col-xl-4 d-xl-block'} `}
          >
            <Phone className="mt-3">
              <iframe
                id="page-preview"
                title={page?.name}
                src={qrCodeUrl}
                // src={`${TURBINE_PAGE}/o/${organization?.slug}/page/${pageId}`}
                style={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </Phone>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(
  PageAppearance,
  true
);
