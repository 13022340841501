import React, { useEffect, useState } from 'react';
import { fetchWidgets } from '../../../../services/widgets';
import { IMAGE_TOOLKIT_EMPTY } from '../../../../constants/assets';
import ListView from '../../../lists/WidgetsList/ListView';

const LinkWidgetModule = ({ orgId, widgetIds, styling }) => {
  // Local State
  const [linkWidgets, setLinkWidgets] = useState([]);
  const [showNoDataUi, setShowNoDataUi] = useState(false);

  useEffect(() => {
    if (Array.isArray(widgetIds) && widgetIds?.length > 0) {
      fetchWidgets({
        orgId,
        type: 'link',
        ids: widgetIds,
        sortByIds: widgetIds
      }).then((response) => {
        const list = response?.list || [];
        if (list.length > 0) {
          setShowNoDataUi(false);
        } else {
          setShowNoDataUi(true);
        }
        setLinkWidgets(list);
      });
    } else {
      setLinkWidgets([]);
      setShowNoDataUi(true);
    }
  }, [widgetIds?.length, widgetIds, orgId]);

  if (showNoDataUi) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center justify-content-center"
        style={{
          minHeight: '400px'
        }}
      >
        <img
          src={IMAGE_TOOLKIT_EMPTY}
          className="mb-3"
          height={150}
          style={{
            height: '150px'
          }}
          alt="No Links"
        />
        <p className="mt-1 mb-3">No Links.</p>
      </div>
    );
  }

  return (
    <ListView
      data={linkWidgets}
      styling={styling}
    />
  );
};

export default LinkWidgetModule;
