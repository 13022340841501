import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import CohortContainer from './CohortContainer';
import Rows from './Rows';

const Attendance = () => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const users = useSelector((state) => state.users);
  const currentCohort = useSelector((state) => state.currentCohort);
  const course = useSelector((state) => state.course);
  // Misc
  const orgName = pathOr(null, ['name'], organization);
  const courseTitle = pathOr(null, ['title'], course);

  if (!currentCohort.id) {
    return <CohortContainer title="Attendance" />;
  }

  return (
    <CohortContainer
      title="Attendance"
      users={users.list}
      showPrintButton
    >
      <table className="table table-striped th-border-top-0">
        <thead>
          <tr>
            <th
              className="d-print-none"
              scope="col"
            >
              Student
            </th>
            <th scope="col">
              <div className="d-print-none">Records</div>
              <div className="d-none d-print-block">
                <h4>{orgName}</h4>
                <h6>{courseTitle}</h6>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <Rows />
        </tbody>
      </table>
    </CohortContainer>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withConsole)(Attendance);
