import {
  RESET_PAGE_FILES,
  RESET_PAGE,
  SET_PAGE,
  SET_PAGE_FILES
} from '../../actions/types';
import pageState from '../initialState/pageState';

const initialState = {
  ...pageState,
  fetched: false,
  cdnFiles: null
};

export default function page(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        ...action.page,
        cdnFiles: null,
        fetched: true
      };
    case SET_PAGE_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles || null
      };
    case RESET_PAGE_FILES:
      return {
        ...state,
        cdnFiles: initialState.cdnFiles
      };
    case RESET_PAGE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
