import React from 'react';
import { FaCheckSquare, FaRegCircle, FaRegSquare } from 'react-icons/fa';
import { FaCircleDot } from 'react-icons/fa6';

const SelectBtnCell = ({ column, row }) => {
  return (
    <div className="d-flex align-items-center mt-1">
      <button
        data-cy="ResourcesList-selectBtn"
        title="Select"
        className={`btn-link ${row.original.selected ? '' : 'text-muted'}`}
        onClick={() => {
          if (typeof column?.onSelect === 'function') {
            column?.onSelect(row?.original, row.index);
          }
        }}
        type="button"
      >
        {row.original.selected ? (
          <>
            {!column?.isSingleSelect && <FaCheckSquare size={20} />}

            {column?.isSingleSelect && <FaCircleDot size={20} />}
          </>
        ) : (
          <>
            {!column?.isSingleSelect && <FaRegSquare size={20} />}

            {column?.isSingleSelect && <FaRegCircle size={20} />}
          </>
        )}
      </button>
    </div>
  );
};

export default SelectBtnCell;
