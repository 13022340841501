import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuizzes } from '../../../services/courseDashboard/quizzes';
import {
  fetchChallenges
  // fetchAnswers
} from '../../../services/courseDashboard/challenges';
import {
  fetchUsersCompletedCourse,
  fetchUsersEnrolled
} from '../../../services/courseDashboard/users';
import { getSkills } from '../../../actions/Skills';
import { fetchQuizOutcomesForGraph } from '../../../services/quizOutcomes';
import UsersInsights from './UsersInsights';
import SkillImportanceChart from './SkillImportanceChart';
import SkillGainChart from './SkillGainChart';
import percentComplete from '../../../utils/percentComplete';
import UserCourseCompletionChart from './UserCourseCompletionChart';

const UsersAndSkillsInsights = () => {
  const dispatch = useDispatch();
  // Redux
  const course = useSelector((state) => state.course);
  const organization = useSelector((state) => state.organization);
  const skills = useSelector((state) => state.skills);
  // Users
  const [completedCourses, setCompletedCourses] = useState(0);
  const [usersEnrolled, setUsersEnrolled] = useState(0);
  // Skills
  const [quizzes, setQuizzes] = useState(null);
  const [quizOutcomesList, setQuizOutcomesList] = useState(null);
  const [quizOutcomesAccepted, setQuizOutcomesAccepted] = useState(null);
  const [quizOutcomesHash, setQuizOutcomesHash] = useState(null);
  const [challengesList, setChallengesList] = useState(null);
  // const [challengeAnswersAccepted, setChallengeAnswersAccepted] = useState(null);
  // Course
  const classId = course?.id || '';
  const skillIds = course?.skillIds || null;
  // Organization
  const orgId = organization?.id || '';
  const skillsHash = skills.hash || null;

  const quizSkillIds = useMemo(() => {
    if (Array.isArray(quizzes) && quizzes.length > 0) {
      const qSkillIds = quizzes.reduce((acc, curr) => {
        const result = { ...acc };

        result[curr.id] = true;

        return result;
      }, {});

      return Object.keys(qSkillIds);
    }

    return null;
  }, [quizzes]);

  const challengeSkillIds = useMemo(() => {
    if (Array.isArray(challengesList) && challengesList.length > 0) {
      const cSkillIds = challengesList.reduce((acc, curr) => {
        const result = { ...acc };

        result[curr.id] = true;

        return result;
      }, {});

      return Object.keys(cSkillIds);
    }

    return null;
  }, [challengesList]);

  const allSkillIds = useMemo(() => {
    let result = [];

    if (Array.isArray(skillIds) && skillIds.length > 0) {
      result = [...result, ...skillIds];
    }

    if (Array.isArray(quizSkillIds) && quizSkillIds.length > 0) {
      result = [...result, ...quizSkillIds];
    }

    if (Array.isArray(challengeSkillIds) && challengeSkillIds.length > 0) {
      result = [...result, ...challengeSkillIds];
    }

    return result;
  }, [skillIds, quizSkillIds, challengeSkillIds]);

  const retrieveAllSkills = useCallback(() => {
    if (Array.isArray(allSkillIds) && allSkillIds.length > 0) {
      dispatch(getSkills({ orgId, ids: allSkillIds }));
    }
  }, [allSkillIds, dispatch, orgId]);

  useEffect(() => {
    retrieveAllSkills();
  }, [retrieveAllSkills]);

  useEffect(() => {
    let mounted = true;

    if (classId && orgId) {
      // Users
      if (!completedCourses) {
        fetchUsersCompletedCourse({
          completedClassId: classId,
          orgId
        }).then((completedCoursesTotal) => {
          if (mounted) {
            setCompletedCourses(completedCoursesTotal);
          }
        });
      }

      if (!usersEnrolled) {
        fetchUsersEnrolled({
          enrolledClassId: classId,
          orgId
        }).then((usersEnrolledTotal) => {
          if (mounted) {
            setUsersEnrolled(usersEnrolledTotal);
          }
        });
      }
      // /Users

      // Skills
      if (!quizOutcomesList && skillsHash) {
        fetchQuizOutcomesForGraph({
          orgId,
          classId
        }).then(({ outcomesList, outcomesAcceptedList, outcomesHash }) => {
          if (mounted) {
            setQuizOutcomesList(outcomesList);
            setQuizOutcomesAccepted(outcomesAcceptedList);
            setQuizOutcomesHash(outcomesHash);
          }
        });
      }

      if (!quizzes && skillsHash) {
        fetchQuizzes({
          classId,
          orgId
        }).then((quizzesList) => {
          if (mounted) {
            setQuizzes(quizzesList);
          }
        });
      }

      if (!challengesList && skillsHash) {
        fetchChallenges({
          classId,
          orgId
        }).then((courseChallenges) => {
          if (mounted) {
            setChallengesList(courseChallenges);
          }
        });

        // fetchAnswers({
        //   orgId,
        //   classId,
        //   status: 'accepted'
        // }).then((acceptedChallengeAnswers) => {
        //   if (mounted) {
        //     setChallengeAnswersAccepted(acceptedChallengeAnswers);
        //   }
        // });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    challengesList,
    classId,
    completedCourses,
    orgId,
    skillsHash,
    quizOutcomesList,
    quizzes,
    usersEnrolled
  ]);

  // Users
  let courseCompletionRate = null;
  // Skills
  let courseSkills = null;
  let quizSkillsHash = null;
  // const challengeSkillsHash = null;
  let usersSkills = null;

  // Course has quizzes and quiz
  if (skillsHash && (quizzes || challengesList)) {
    courseSkills = {};
  }

  if (skillsHash && quizzes && courseSkills) {
    quizSkillsHash = {};

    if (quizOutcomesAccepted && quizOutcomesHash) {
      console.log('quizOutcomesHash', quizOutcomesHash);
      usersSkills = {};
    }

    // TODO use reducer
    quizzes.forEach((q) => {
      if (q.quizSkills) {
        if (quizSkillsHash[q.id] === undefined) {
          quizSkillsHash[q.id] = [];
        }

        q.quizSkills.forEach((qsId) => {
          if (courseSkills[qsId] === undefined) {
            courseSkills[qsId] = { ...skillsHash[qsId], importance: 0 };
          }

          quizSkillsHash[q.id].push({ id: qsId, ...skillsHash[qsId] });

          courseSkills[qsId].importance += 1;
        });
      }
    });

    // Users Skills
    if (quizOutcomesAccepted && quizOutcomesHash) {
      Object.entries(quizSkillsHash).forEach(([key, skillsArr]) => {
        if (quizOutcomesHash[key] !== undefined) {
          // If there are accepted outcomes for this quiz
          // Include the skills in usersSkills
          skillsArr.forEach((s) => {
            if (usersSkills[s.id] === undefined) {
              usersSkills[s.id] = { ...s, outcomes: 0 };
            }

            usersSkills[s.id].outcomes += 1;
          });
        }
      });
    }

    // if (Array.isArray(challengeAnswersAccepted) && challengeAnswersAccepted.length > 0) {
    //   challengeAnswersAccepted.forEach((a) => {
    //     if (usersSkills[a.id] === undefined) {
    //       usersSkills[a.id] = { ...a, outcomes: 0 };
    //     }

    //     usersSkills[a.id].outcomes += 1;
    //   });
    // }
  }

  if (challengesList && courseSkills) {
    // TODO use reducer
    challengesList.forEach((c) => {
      if (c.challengeSkills) {
        c.challengeSkills.forEach((csId) => {
          if (courseSkills[csId] === undefined) {
            courseSkills[csId] = { ...skillsHash[csId], importance: 0 };
          }

          courseSkills[csId].importance += 1;
        });
      }
    });
  }

  // Users
  if (completedCourses !== 0 && usersEnrolled !== 0) {
    courseCompletionRate = percentComplete({
      current: completedCourses,
      total: usersEnrolled
    });
  }

  if (!skills.fetched) {
    return null;
  }

  // No org or course Skills, load User insights
  if (!skillsHash || !courseSkills) {
    return (
      <UsersInsights
        courseCompletionRate={courseCompletionRate}
        completedCourses={completedCourses}
        usersEnrolled={usersEnrolled}
      />
    );
  }

  // console.log('skillsHash', skillsHash);
  // console.log('courseSkills', courseSkills);
  // console.log('usersSkills', usersSkills);

  // Has course skills
  return (
    <>
      <div className="row mt-3">
        <div className="col-sm-12 col-md-6 mb-3">
          <UserCourseCompletionChart
            title="Users"
            courseCompletionRate={courseCompletionRate}
            completedCourses={completedCourses}
            usersEnrolled={usersEnrolled}
          />
        </div>
        <SkillImportanceChart
          title="Skills"
          className="col-sm-12 col-md-6 mb-3"
          courseSkills={courseSkills}
        />
      </div>

      <div className="row mt-3">
        <SkillGainChart
          title="Users Skill Gains"
          className="col-sm-12 mb-2"
          usersSkills={usersSkills}
          courseSkills={courseSkills}
        />
      </div>
    </>
  );
};

export default UsersAndSkillsInsights;
