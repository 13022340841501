import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { FaClock, FaCog, FaPlus } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import TimeBadge from '@apprentage/components/dist/components/TimeBadge';
import { withAuthorization } from '../../../../Session';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { setCurrentModal } from '../../../../../actions/Modals';
import { canCreateCompetency } from '../../../../../permissions/create';
import { canManageCompetencyGroup } from '../../../../../permissions/manage';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import DotMenuCompetencies from './DotMenuCompetencies';
import DotMenu from '../DotMenu';
import CompetenciesList from '../../../../lists/CompetenciesList';
import Collapse from '../../../../ManageContent/Collapse';
import CompetencyBreakdown from '../../../../CompetenyBreakdown';
import { MODAL_KEY_MANAGE_COMPETENCY } from '../../../../../constants/modals';
import { ICON_TRAINING_PROGRAMS } from '../../../../../constants/assets';

const ApprenticeshipCompetencies = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current User
  const role = currentUser?.role || [];
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const ojtHours = apprenticeship?.ojtHours || 0;
  const competencies = useMemo(() => {
    return apprenticeship?.competencies || {};
  }, [apprenticeship?.competencies]);

  const competencyGroups = useMemo(() => {
    return apprenticeship?.competencyGroups || [];
  }, [apprenticeship?.competencyGroups]);

  const addCompetency = (competencyGroupId) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_COMPETENCY,
        data: {
          modalTitle: 'Add Competency',
          competencyGroupId
        }
      })
    );
  };

  const manageCompetencyGroup = (competencyGroup) => {
    dispatch(
      setCurrentModal({
        key: 'manageCompetencyGroup',
        data: {
          competencyGroup
        }
      })
    );
  };

  const totalHours = useMemo(() => {
    let total = 0;

    if (Array.isArray(competencyGroups) && competencyGroups.length > 0) {
      competencyGroups.forEach((cg) => {
        if (Array.isArray(cg.competencyIds)) {
          cg.competencyIds.forEach((cId) => {
            total += parseInt(competencies[cId]?.totalHours, 10);
          });
        }
      });
    }

    return total;
  }, [competencies, competencyGroups]);

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    return function cleanup() {
      const { href } = window.location;
      const apprenticeshipRoute = `org/workforce/apprenticeships/${apprenticeshipId}`;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId, dispatch]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program..." />;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <div className="mb-5">
            <OrgConsoleHeader
              pageTitle={apprenticeship.title}
              image={ICON_TRAINING_PROGRAMS}
              className="mb-3"
            >
              <DotMenu />
            </OrgConsoleHeader>

            <Tabs active="competencies" />

            <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
              <OrgConsoleSubheader
                pageTitle="Competencies"
                className="mb-3"
                badge={() => (
                  <CompetencyBreakdown
                    className="p-2"
                    competencies={competencies}
                    competencyGroups={competencyGroups}
                    ojtHours={ojtHours}
                    tooltipContainerId="Competencies"
                  />
                )}
              >
                <DotMenuCompetencies />
              </OrgConsoleSubheader>

              {!competencyGroups ||
              (Array.isArray(competencyGroups) &&
                competencyGroups?.length === 0) ? (
                <div>
                  <div>No competency groups.</div>

                  {canManageCompetencyGroup(role) && (
                    <button
                      className="btn btn-sm btn-primary mt-3"
                      onClick={manageCompetencyGroup}
                      type="button"
                    >
                      <span className="d-flex align-items-center">
                        <FaPlus />
                        <span className="ml-2">Competency Group</span>
                      </span>
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  {ojtHours !== totalHours && totalHours > 0 && (
                    <div className="alert alert-danger d-flex align-items-center">
                      <FaClock />
                      <div className="ml-2">
                        Competency Hours (<strong>{totalHours}</strong>) don't
                        match OJT Hours (<strong>{ojtHours}</strong>)
                      </div>
                    </div>
                  )}

                  {competencyGroups.map((competencyGroup, i) => {
                    const childCompetencies = competencyGroup?.competencyIds
                      ?.length
                      ? competencyGroup?.competencyIds.map(
                          (cId) => competencies[cId]
                        )
                      : [];

                    const childCompetencyHours = childCompetencies.reduce(
                      (acc, curr) => {
                        acc += parseInt(curr?.totalHours, 10);

                        return acc;
                      },
                      0
                    );

                    return (
                      <Fragment key={`competencyGroup-${i}`}>
                        <Collapse
                          id={`competencyGroup-${competencyGroup?.id}`}
                          title={competencyGroup?.description}
                          className="mb-3"
                          p="0"
                          ariaExpanded={competencyGroups.length === 1}
                          badge={() => (
                            <TimeBadge
                              duration={childCompetencyHours}
                              className="ml-2 border rounded pb-0 mb-0"
                              iconColor="#3f5366"
                              textColor="#3f5366"
                              backgroundColor="#f8f9fa"
                            />
                          )}
                        >
                          <CompetenciesList
                            list={childCompetencies}
                            competencyGroupId={competencyGroup?.id}
                          />
                          <div className="p-3 d-flex align-items-center justify-content-between">
                            {canCreateCompetency(role) && (
                              <button
                                className="btn btn-sm btn-white"
                                onClick={() => {
                                  addCompetency(competencyGroup?.id);
                                }}
                                type="button"
                                title="Add Competency"
                              >
                                <span className="d-flex align-items-center">
                                  <FaPlus className="mr-1" />
                                  <span>Competency</span>
                                </span>
                              </button>
                            )}

                            {canManageCompetencyGroup(role) && (
                              <button
                                className="btn btn-sm btn-white"
                                onClick={() => {
                                  manageCompetencyGroup(competencyGroup);
                                }}
                                type="button"
                                title="Manage Competency Group"
                              >
                                <span className="d-flex align-items-center">
                                  <FaCog />
                                  <span className="ml-2">Settings</span>
                                </span>
                              </button>
                            )}
                          </div>
                        </Collapse>
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="Competencies" />
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipCompetencies);
