import React from 'react';

const ReviewCheckboxCell = () => {
  return (
    <input
      type="checkbox"
      checked
      readOnly
    />
  );
};

export default ReviewCheckboxCell;
