import {
  SET_RESOURCE_GROUP_TAGS,
  RESET_RESOURCE_GROUP_TAGS
} from '../../../actions/types';

const initialState = {
  fetched: false,
  list: null,
  pagination: null
};

export default function resourceGroupTags(state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCE_GROUP_TAGS: {
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    }
    case RESET_RESOURCE_GROUP_TAGS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
