import React, { useMemo } from 'react';
import Table from './Table';
import NameCell from './NameCell';
import EmbedUrlsCell from './EmbedUrlsCell';
import TypeCell from './TypeCell';

const List = ({ data, handleClickRow }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: NameCell
      },
      {
        Header: 'Web Pages',
        accessor: 'embedUrls',
        Cell: EmbedUrlsCell
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: TypeCell
      }
    ],
    []
  );

  const hiddenColumns = useMemo(() => {
    const cols = [];

    return cols;
  }, []);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Assistants.</div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
