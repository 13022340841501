import React from 'react';
import { FaLayerGroup } from 'react-icons/fa';

const CourseSummary = ({ onClick, trainingCourse, loading }) => {
  const sectionsDefault = [
    {
      sectionTitle: '...',
      sectionSummary: '...',
      sectionContent: '...'
    }
  ];
  const skillsDefault = ['...', '...', '...'];
  const learningOutcomesDefault = ['...', '...', '...'];

  const title = trainingCourse?.title || '...';
  const summary = trainingCourse?.summary || '...';
  const sections = trainingCourse?.trainingCourseSections || sectionsDefault;
  const skills = trainingCourse?.skills || skillsDefault;
  const learningOutcomes =
    trainingCourse?.learningOutcomes || learningOutcomesDefault;

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <FaLayerGroup size={30} />
        <div className="ml-2">
          <div>Knowledge Captured</div>
          <h1 className="h6 m-0 font-weight-bold text-purple-light">
            Learning Module Preview
          </h1>
        </div>
      </div>

      <p className="font-weight-bold text-dark">
        Our robots created your first Learning Module
      </p>

      <div className="bg-light p-4 mb-3 rounded">
        <div className="mb-2">
          <div className="mb-2 font-weight-bold">Title</div>
          <div className="bg-white p-3 border border-dashed border-2 rounded">
            {title}
          </div>
        </div>

        <div className="mb-2">
          <div className="mb-2 font-weight-bold">Overview</div>
          <div className="bg-white p-3 border border-dashed border-2 rounded">
            {summary}
          </div>
        </div>

        <div className="mb-2">
          <div className="mb-2 font-weight-bold">
            <span className="d-flex align-items-center">
              <span>Learning Outcomes</span>
              <span className="badge badge-secondary ml-2">
                {Array.isArray(learningOutcomes) ? learningOutcomes.length : 0}
              </span>
            </span>
          </div>
          <div className="bg-white pt-3 px-3 pb-2 border border-dashed border-2 rounded">
            {learningOutcomes.map((learningOutcome) => (
              <div className="bg-white badge p-3 border border-dashed border-2 rounded mb-2 text-wrap text-left">
                {learningOutcome}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-2">
          <div className="mb-2 font-weight-bold">
            <span className="d-flex align-items-center">
              <span>Sections</span>
              <span className="badge badge-secondary ml-2">
                {Array.isArray(sections) ? sections.length : 0}
              </span>
            </span>
          </div>

          {sections.map((section) => (
            <div className="bg-white p-3 border border-dashed border-2 rounded mb-3">
              <div className="bg-white p-3 border border-dashed border-2 rounded mb-2">
                {section.sectionTitle}
              </div>
              {/* <div className='bg-white p-3 border border-dashed border-2 rounded mb-2'>
                {section.sectionSummary}
              </div> */}
              <div className="bg-white p-3 border border-dashed border-2 rounded mb-2">
                {section.sectionContent}
              </div>
            </div>
          ))}
        </div>

        <div className="mb-2">
          <div className="mb-2 font-weight-bold">
            <span className="d-flex align-items-center">
              <span>Skills</span>
              <span className="badge badge-secondary ml-2">
                {Array.isArray(skills) ? skills.length : 0}
              </span>
            </span>
          </div>
          <div className="bg-white pt-3 px-3 pb-2 border border-dashed border-2 rounded d-flex flex-wrap">
            {skills.map((skill) => (
              <div className="bg-white badge py-2 px-3 border border-dashed border-2 rounded mb-2 mr-2">
                {skill}
              </div>
            ))}
          </div>
        </div>
      </div>

      <p className="font-weight-bold mb-4 text-center">
        Next, let's explore how Turbine simplifies knowledge capture with an app
        called VELA.
      </p>

      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          disabled={loading}
          className="btn bg-purple-light btn-md text-white"
          onClick={onClick}
        >
          {loading ? 'Loading...' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default CourseSummary;
