import React, { useMemo, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { FaCheck, FaEyeDropper } from 'react-icons/fa';

const PopoverChooseColor = ({
  color,
  activeColor,
  onChange,
  onClose,
  tooltipContent,
  tooltipId
}) => {
  const popover = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutsidePopover = (e) => {
    console.log('handleClickOutsidePopover', e);
    setIsOpen(false);

    if (onClose) {
      onClose(color);
    }
  };

  const handleOnClick = (e) => {
    console.log('handleOnClick', e);
    setIsOpen(!isOpen);
  };

  const onKeyUp = (e) => {
    const { value } = e.currentTarget;

    if (e.key === 'Backspace' || e.keyCode === 8) {
      // Handle the Delete key press here
      if (value === '#') {
        onChange();
      }
    }
  };

  const isValidHex = useMemo(() => {
    return color && color.length === 7 && color.includes('#');
  }, [color]);

  return (
    <div className="picker">
      <button
        ref={popover}
        type="button"
        className="btn btn-white ColorSwatches--color border m-0"
        style={{
          backgroundColor: isValidHex ? color : 'transparent',
          ...(isValidHex ? { width: 'auto' } : {})
        }}
        aria-label="Choose Color"
        onClick={handleOnClick}
        // data-tooltip-content="Choose Color"
        // data-tooltip-id="ColorSwatches"
        data-tip="Choose Color"
        data-for="ColorSwatches"
      >
        <span className="d-flex align-items-center">
          <span className="bg-white py-2">
            <FaEyeDropper
              size={15}
              className="mx-2"
            />
          </span>

          {activeColor && color && activeColor === color && (
            <span
              className="ColorSwatches--color m-0 p-3 d-flex justify-content-center"
              // data-tooltip-content={tooltipContent}
              // data-tooltip-id={tooltipId}
              data-tip={tooltipContent}
              data-for={tooltipId}
              style={{
                backgroundColor: color,
                width: 'auto',
                height: 'auto'
              }}
            >
              {activeColor !== undefined && activeColor === color && (
                <FaCheck
                  className="text-white m-0"
                  size={12}
                />
              )}
            </span>
          )}
        </span>
      </button>

      {isOpen && (
        <>
          <div
            className="popover-bkg"
            onClick={handleClickOutsidePopover}
            aria-hidden
          />
          <div className="popover">
            <div className="bg-white border p-3 rounded">
              <HexColorPicker
                color={color}
                onChange={onChange}
              />
              <div className="mt-2">
                <div className="d-flex align-items-center mb-1">
                  <span className="mr-1 font-weight-bold">Custom Color</span>
                  <span className="badge badge-secondary">HEX6</span>
                </div>
                <div className="d-flex align-items-start">
                  <div>
                    <HexColorInput
                      color={color}
                      onChange={onChange}
                      prefixed
                      onKeyUp={onKeyUp}
                    />
                    <div className="small text-muted mt-1">Ex: ff2200</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PopoverChooseColor;
