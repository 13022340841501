import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { setCurrentClass } from '../../../actions/Class';
import { withAuthorization } from '../../Session';
import { updateCourse } from '../../../services/courses';
import RadioGroup from '../../ManageContent/RadioGroup';
import withConsole from '../../App/withConsole';

const Streaming = () => {
  const dispatch = useDispatch();
  // redux
  const course = useSelector((state) => state.course);
  //
  const classId = pathOr(null, ['id'], course);
  // local state
  const [loading, setLoading] = useState(false);
  // const [streamNotify, setStreamNotify] = useState(false);
  const [streamingLink, setStreamingLink] = useState(
    course.streamingLink || ''
  );
  const [isLive, setIsLive] = useState(course.isLive || false);
  const [showStreamLink, setShowStreamLink] = useState(
    course.showStreamLink || false
  );

  const handleOnChangeLink = (e) => {
    const { value } = e.target;
    const link = value.trim();

    setStreamingLink(value);

    if (link === '') {
      setIsLive(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    updateCourse({
      classId,
      streamingLink,
      isLive,
      showStreamLink
    }).then((updatedClass) => {
      toast.success('Streaming settings saved!');
      dispatch(
        setCurrentClass({
          classId: updatedClass.sys.id
        })
      );
      setLoading(false);
    });
  };

  return (
    <div className="card border-bottom mt-3 mb-5">
      <form
        name="form-go-live"
        onSubmit={onSubmit}
      >
        <div className="card-header p-3">
          <h1 className="h5 m-0">Live Stream</h1>
        </div>
        <div className="card-body">
          <div className="form-group my-3">
            <label
              className="d-block"
              id="streamingLinkText"
              htmlFor="streamingLink"
            >
              <b>Live Streaming Link:</b>
            </label>
            <input
              data-demo="disabled"
              id="streamingLink"
              name="streamingLink"
              type="url"
              pattern="https?://.*"
              className="form-control"
              aria-describedby="streamingLinkText"
              onChange={handleOnChangeLink}
              defaultValue={streamingLink}
            />
            <small className="mt-1 text-muted d-flex align-items-center">
              URL to Zoom, Google Meet, Skype, etc.
            </small>
          </div>

          {streamingLink && (
            <>
              <div className="form-group my-3">
                <label
                  className="d-block mb-1"
                  id="streamLiveText"
                  htmlFor="isLive"
                >
                  <b>Live Now:</b>
                </label>
                <div className="text-muted medium mb-2">
                  Show "Class is Live" banners and "Join Class" buttons so
                  students can join the Live Streaming Link.
                </div>
                <RadioGroup
                  name="isLive"
                  value={isLive}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setIsLive(value === 'true');
                  }}
                />
              </div>

              {/* <div
                id="streamNotifyToggle"
                className={`form-group my-3 ${streamingLink ? '' : 'd-none'}`}
              >
                <label className="d-block mb-1" htmlFor="streamNotify">
                  Notify Users via Email
                </label>
                <RadioGroup
                  name="streamNotify"
                  value={streamNotify}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setStreamNotify(value === 'true');
                  }}
                />
              </div> */}

              <hr />
              <div className="form-group mb-3">
                <label className="mb-1">
                  <b>Always Show Live Streaming Link:</b>
                </label>
                <div className="text-muted medium mb-2">
                  Show Live Streaming Link even when class is <b>NOT</b> live.
                </div>
                <RadioGroup
                  name="showStreamLink"
                  value={showStreamLink}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setShowStreamLink(value === 'true');
                  }}
                />
              </div>
            </>
          )}
        </div>

        <div className="card-footer d-flex p-3">
          <button
            className="btn btn-primary d-block"
            type="submit"
            disabled={!!loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withConsole)(Streaming);
