import React from 'react';
import { FaRegObjectGroup } from 'react-icons/fa';

const TitleCell = ({ value: title }) => {
  return (
    <div
      data-cy="groupsList-title"
      className="py-2 d-flex align-items-center"
    >
      <FaRegObjectGroup size={15} />
      <span className="ml-2">{title}</span>
    </div>
  );
};

export default TitleCell;
