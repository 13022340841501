import React from 'react';
import { roleReadable } from '@apprentage/utils';
import { FaExclamationCircle, FaUserAlt } from 'react-icons/fa';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { isActiveMembership } from '../../../../services/currentUser';

const NameCell = ({ value: name, row, handleOnClickRow }) => {
  return (
    <div className="d-flex align-items-center">
      <img
        data-demo="disabled"
        className="bd-placeholder-img rounded-circle mr-3"
        src={row.original.profilePicture || DEFAULT_PROFILE_PIC}
        alt={name}
        width="60"
        height="60"
        style={{
          height: '60px',
          width: '60px'
        }}
      />
      <div>
        <div className="h5 m-0">
          {typeof handleOnClickRow === 'function' ? (
            <button
              onClick={() => handleOnClickRow(row?.original?.id)}
              type="button"
              data-demo="disabled"
              className="btn-link text-left p-0"
            >
              {name}
            </button>
          ) : (
            <span>{name}</span>
          )}
        </div>
        <div
          className="text-muted"
          data-demo="disabled"
        >
          {row.original.title || roleReadable(row?.original?.role || [])}
        </div>

        {row?.original?.testUser && (
          <div className="h6 m-0 badge alert-warning p-1">
            <span className="d-flex align-items-center">
              <FaUserAlt size={10} />
              <span className="ml-1">Test User</span>
            </span>
          </div>
        )}

        {!isActiveMembership(row?.original?.membership) && (
          <div className="h6 m-0 badge alert-danger p-1">
            <span className="d-flex align-items-center">
              <FaExclamationCircle size={10} />
              <span className="ml-1">Inactive</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NameCell;
