import React from 'react';
import { useSelector } from 'react-redux';

const ActionsList = ({ list, onClick }) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgName = organization?.name || null;
  // Local State

  if (Array.isArray(list) && list.length > 0) {
    return (
      <div>
        {list.map((action) => (
          <div
            className="row-clickable border p-3 mb-3"
            onClick={() => {
              onClick(action);
            }}
            aria-hidden
          >
            {action.title.replace('{ORG_NAME}', orgName)}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default ActionsList;
