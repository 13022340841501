import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { TURBINE_PAGE } from '../constants/urls';
import {
  createFirebaseEntry,
  deleteFirebaseEntry,
  getFirebaseEntriesNew,
  updateFirebaseEntry
} from './firebaseProxy';

const collection = 'employerAgreements';

/**
 * @param {Array} ids
 */
export const fetchEmployerAgreements = ({
  ids = [],
  locationId,
  employerProfileId,
  apprenticeshipId
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    // Training Program: Workforce
    if (locationId) {
      config['f.locationId[eq]'] = locationId;
    }

    if (employerProfileId) {
      config['f.employerProfileId[eq]'] = employerProfileId;
    }

    if (apprenticeshipId) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew({
      collection,
      ...config
    })
      .then(({ items }) => {
        const list = items.length ? items : null;

        resolve(list);
      })
      .catch((error) => {
        console.error(`fetch ${collection}`, error);
        reject(error);
      });
  });
};

export const createEmployerAgreement = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection })
      .then((response) => {
        const docId = pathOr(null, ['docId'], response);

        resolve(docId);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${collection}`, error);
      });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const updateEmployerAgreement = (data, id) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${collection}`, error);
      });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const deleteEmployerAgreement = (id) => {
  return new Promise((resolve) => {
    deleteFirebaseEntry({
      collection: 'invites',
      id
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`delete ${collection}`, error);
      });
  });
};

export const generateDocumentLink = (params) => {
  let url = `${TURBINE_PAGE}/external/doc/${params?.collection}/${params?.id}`;

  if (params?.accessCode) {
    url += `?code=${params.accessCode}`;
  }

  if (params?.preview) {
    url += `&preview=${params.preview}`;
  }

  return url;
};
