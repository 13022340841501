import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { isSuperAdmin } from '@apprentage/utils';
import { canManageUsers } from '../../../../services/currentUser';
import { setCurrentModal } from '../../../../actions/Modals';
import KeyValueItem from '../../../KeyValueItem';
import { MODAL_KEY_MANAGE_GROUP } from '../../../../constants/modals';

const ActionsCell = ({ row }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];
  // Row
  const title = row?.original?.title || '';

  const openManageGroupModal = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_GROUP,
        data: {
          type: 'user',
          editMode: true,
          group: row?.original
        }
      })
    );
  };

  return (
    <div className="btn-group d-flex align-items-self justify-content-end mr-1 mt-2">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div
          className="bg-dark px-4 py-2 text-white text-nowrap"
          data-demo="disabled"
        >
          {title}
        </div>

        <button
          type="button"
          className="dropdown-item border-bottom py-2 d-flex align-items-center"
          onClick={openManageGroupModal}
          disabled={!canManageUsers(role)}
        >
          <FaCog size={15} />
          <span className="ml-2">Settings</span>
        </button>

        {isSuperAdmin(role) && (
          <div className="bg-light px-2 py-1 small">
            <div
              className="bg-light px-2"
              data-demo="disabled"
            >
              <KeyValueItem
                className="text-nowrap mb-1"
                title="ID"
                value={row?.original?.id}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionsCell;
