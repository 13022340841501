// import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../../../actions/Modals';
import {
  MODAL_KEY_SEARCH_SELECT_USERS,
  MODAL_KEY_USER_INVITATION_CONFIRM
} from '../../../../../constants/modals';
import { fetchUser } from '../../../../../services/currentUser';

const AddExistingUser = ({
  userIdsToExclude,
  maxNumUsers = 1,
  userListConfig,
  style = {},
  children
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;

  const selectUser = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: 'Add Existing User',
          ids: [],
          userIdsToExclude,
          maxNumUsers,
          primaryActionBtnText: 'Continue',
          searchFilters: {
            excludeRoles: ['superAdmin']
          }
        },
        callbackPrimaryAction: (newUserIds) => {
          const userId =
            Array.isArray(newUserIds) && newUserIds.length > 0
              ? newUserIds[0]
              : null;

          if (userId) {
            fetchUser({
              orgId,
              userId,
              select: [
                'id',
                'email',
                'role',
                'locationId',
                'firstName',
                'lastName',
                'title',
                'profilePicture'
              ]
            }).then((response) => {
              if (response?.id) {
                dispatch(
                  setCurrentModal({
                    key: MODAL_KEY_USER_INVITATION_CONFIRM,
                    data: {
                      modalTitle: 'Add Existing User',
                      userId: response?.id,
                      role: response?.role,
                      userRole: response?.role,
                      userTitle: response?.title,
                      firstName: response?.firstName,
                      lastName: response?.lastName,
                      email: response?.email,
                      profilePicture: response?.profilePicture,
                      locationId: response?.locationId,
                      userListConfig
                    }
                  })
                );
              }
            });
          }
        }
      })
    );
  };

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
          <div
            className="p-3 bg-white rounded border d-flex justify-content-center"
            style={style}
          >
            <div>
              <h6 className="mb-3 text-center">
                Add <strong>Existing</strong> User
              </h6>
              <div className="d-flex align-items-start">
                <img
                  src={DEFAULT_PROFILE_PIC}
                  className="bd-placeholder-img rounded-circle mr-2"
                  height={40}
                  width={40}
                  alt="Add User"
                />
                <div>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Enter name..."
                    onFocus={(e) => {
                      e.currentTarget.blur();
                      selectUser();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
          <div
            className="p-3 bg-white border rounded text-center"
            style={{
              minHeight: '130px'
            }}
          >
            <h6 className="mb-1">
              Add <strong>New</strong> User
            </h6>
            {children || null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExistingUser;
