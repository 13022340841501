import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEllipsisH, FaEye, FaPlus } from 'react-icons/fa';
import { canManageUsers } from '../../../../../services/currentUser';
import { createMaterial } from '../../../../../services/materials';
import {
  getMaterial,
  getNoteMaterials
} from '../../../../../actions/Materials';
import { setSideSheet } from '../../../../../actions/SideSheets';
import {
  SHEET_KEY_MATERIAL,
  SHEET_KEY_USER_DETAILS
} from '../../../../../constants/sideSheets';
import { canCreateNoteUser } from '../../../../../permissions/create';

const DotMenu = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const noteUserMaterials = useSelector((state) => state.noteUserMaterials);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipUser = apprenticeship?.user || null;
  // Permissions
  const manageUsers = canManageUsers(role);
  // Materials
  const hasUserMaterials = useMemo(() => {
    return (
      Array.isArray(noteUserMaterials?.list) &&
      noteUserMaterials.list.length > 0
    );
  }, [noteUserMaterials.list]);

  const createNote = () => {
    const noteType = 'user';
    const noteTypeId = apprenticeshipUser?.id;

    if (!noteTypeId) {
      console.error('Missing required argument, noteTypeId');
      return;
    }

    const dataToSave = {
      title: `Internal Note: ${apprenticeshipUser?.name}`,
      body: '',
      orgId,
      noteUserId: noteTypeId,
      userId: currentUser?.id,
      enableNewEditor: true
    };

    createMaterial(dataToSave).then((response) => {
      setTimeout(() => {
        // Wait to make sure Material is created
        // a race condition was observed without timeout

        dispatch(getMaterial(response?.id)).then(() => {
          dispatch(
            setSideSheet({
              key: SHEET_KEY_MATERIAL,
              className: 'MaterialSideSheet z-index-100',
              data: {
                editMode: true,
                tinyMceEditor: {
                  uploadPdfButton: false,
                  embedMediaButton: false,
                  uploadImgButton: false
                },
                noteType,
                noteTypeId
              },
              callbackPrimaryAction: () => {
                if (apprenticeshipUser?.id) {
                  dispatch(
                    getNoteMaterials({
                      noteType,
                      noteTypeId,
                      orgId
                    })
                  );
                }
              }
            })
          );
        });
      }, 250);
    });
  };

  const viewDetails = () => {
    dispatch(
      setSideSheet({
        key: SHEET_KEY_USER_DETAILS
      })
    );
  };

  if (!manageUsers) {
    return;
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <button
            className="dropdown-item border-bottom py-2 px-3"
            onClick={viewDetails}
            type="button"
          >
            <span className="d-flex align-items-center">
              <FaEye />
              <span className="ml-2">User Details</span>
            </span>
          </button>

          {canCreateNoteUser(role) && !hasUserMaterials && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              onClick={createNote}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2">Internal Note</span>
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
