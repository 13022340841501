import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_UPLOAD } from '@apprentage/constants';
import Button from '@apprentage/components/dist/components/Button';
// import { FaCheck } from 'react-icons/fa';
import { saveCdnFiles } from '../../../actions/Entry';
import { resetCurrentModal } from '../../../actions/Modals';
import {
  coerceToResourceName,
  createResource
} from '../../../services/resources';
import { TW_API } from '../../../constants/urls';
import { API_AWS_UPLOAD, SUPABASE_CONTENT_TYPES } from '../../../constants/api';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import Modal from '../../Modal';
import FileUploader from '../../ManageContent/FileUploader';
import UploadMultipleFiles from './UploadMultipleFiles';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import { MODAL_KEY_UPLOAD_FILES } from '../../../constants/modals';

const catchError = (error) => {
  console.error(error);
  throw new Error(error);
};

// TODO convert to functional component
class UploadFiles extends Component {
  state = {
    key: MODAL_KEY_UPLOAD_FILES,
    files: [],
    uploadStatus: 'Not started',
    // loading: false,
    integration: true, // TODO better named key
    clickCancel: false,
    ...this.props.currentModal.data
  };

  handleClose = () => {
    toast.dismiss();
    this.props.resetCurrentModal();
  };

  handleSuccess = (fileObj) => {
    const { currentModal } = this.props;
    toast.success('Files Uploaded Successfully !');
    if (
      currentModal.data.callback &&
      typeof currentModal.data.callback === 'function'
    ) {
      currentModal.data.callback(fileObj);
    }

    this.handleClose();
  };

  /**
   *
   * @param {object | array[object]} fileObj // TODO name argument better, doesn't indicate there are multiple files
   */
  handleInsertUploadedFile = (fileObj) => {
    const { organization, resourceGroups, resourceGroup, currentUser } =
      this.props;
    const orgId = organization?.id || '';
    const { contentId, contentType, groupType, integration } = this.state;
    // Check Supabase Content Types
    if (SUPABASE_CONTENT_TYPES.includes(contentType)) {
      const fileObjects = Array.isArray(fileObj) ? fileObj : [fileObj];
      // Build Resources Schema
      const errorMsg = `Files uploaded successfully, but can't create Supabase Resources (resourceGroup ID: ${contentId})`;

      if (fileObjects.length === 0) {
        throw new Error(errorMsg, resourceGroup?.title || '');
      }

      const resources = fileObjects.map((fileData) => ({
        ...fileData,
        orgId,
        parentType: contentType,
        parentId: contentId,
        // TODO change to "resourceGroups"
        ...(contentType === 'resourceGroup'
          ? { parentIds: resourceGroups?.groupIds }
          : {}),
        userId: currentUser?.id,
        userName: currentUser?.name
      }));

      // Build Promises to upload resources to Supabase
      const promises = resources.map(async (resource, i) => {
        setTimeout(
          async () => {
            await createResource(resource);
          },
          50 * (i + 1)
        );
      });

      if (Array.isArray(promises) && promises.length !== 0) {
        Promise.all(promises)
          .then(() => {
            this.handleSuccess(resources);
          })
          .catch(catchError);
      } else {
        throw new Error(errorMsg, resourceGroup?.title);
      }

      return;
    }

    // Don't save files to content's integration files
    // TODO rename this key
    if (!integration) {
      this.handleSuccess(fileObj);
      return;
    }

    // Contentful
    // Update entity's integration.cdnFiles by contentType (course, topic, entry (material, challenge, quiz, ...)
    this.props
      .saveCdnFiles({
        data: { fileObj },
        contentId,
        contentType,
        ...(groupType ? { groupType } : {})
      })
      .then(() => {
        this.handleSuccess(fileObj);
      });
  };

  /**
   * @param {array of File} files
   */
  handleFileUploaderChange = (files) => {
    // blob
    const newState = Array.from(files).map((file) => {
      return { file, uploadProgress: 0 };
    });
    this.setState((prevState) => ({ ...prevState, files: newState }));
  };

  handleUpload = () => {
    const { organization } = this.props;
    const { id: orgId } = organization;
    const filesUploadedData = [];
    const promises = [];

    this.state.files.forEach((blob, index) => {
      const xhr = new XMLHttpRequest();
      const baseUrl = TW_API + API_AWS_UPLOAD;
      const params = new URLSearchParams({
        prefix: `${AWS_USER_PREFIX}/${orgId}`,
        organizationId: orgId
      });
      const url = `${baseUrl}?${params.toString()}`;

      xhr.open('POST', url);
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.floor((e.loaded / e.total) * 100);
          this.setState((prevState) => {
            const newFiles = [...prevState.files];
            newFiles[index].uploadProgress = progress;
            return { ...prevState, files: newFiles };
          });
        }
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          const fileObj = { url: response.url, id: response.id };
          if (blob.file.name) {
            fileObj.name = coerceToResourceName({
              str: blob.file.name,
              fixCamelCase: true,
              fixHyphens: true,
              fixUnderScores: true,
              fixPlus: true
            });
          }

          if (blob.file.type) fileObj.type = blob.file.type;
          if (blob.file.size) fileObj.size = blob.file.size;
          filesUploadedData.push(fileObj);
        }
      };

      const formData = new FormData();
      formData.append('file', blob.file);
      xhr.send(formData);

      promises.push(
        new Promise((resolve) => {
          xhr.onloadend = resolve;
        })
      );
    });

    Promise.all(promises).then(() => {
      this.handleInsertUploadedFile(filesUploadedData);
    });
  };

  handleCancel = (value) => {
    this.setState({
      clickCancel: value
    });
  };

  render() {
    const { currentModal, organization } = this.props;

    const { id: orgId } = organization;

    const {
      title,
      accept
      // loading,
      // name,
      // size,
      // type
    } = this.state;

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal?.visible}
        close={() => {
          if (this.state.files && this.state.files?.length > 0) {
            this.setState({
              clickCancel: true
            });
          } else {
            this.handleClose();
          }
        }}
        theme="dark"
      >
        <div className="card border-0">
          <div className="card-header bg-dark">
            <h5>
              <i className={`${ICON_UPLOAD} mr-1`} /> {title || 'Upload files'}
            </h5>
          </div>
          <div className="card-body">
            {this.state.files.length > 0 ? (
              <div>
                <div>
                  <div className="d-flex">
                    <b className="mt-2 text-dark">Files to Upload</b>
                    <button
                      title="Change"
                      type="button"
                      size="sm"
                      className="btn btn-link"
                      onClick={() => this.setState({ files: [] })}
                    >
                      Change
                    </button>
                  </div>

                  {this.state.files.map((file, index) => (
                    <UploadMultipleFiles
                      key={index}
                      file={file.file}
                      uploadProgress={file.uploadProgress}
                      uploadStatus={this.state.uploadStatus}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <FileUploader
                orgId={orgId}
                onFileUploadCallback={this.handleInsertUploadedFile}
                onChange={this.handleFileUploaderChange}
                multiple={this.state.multiple}
                accept={accept || '*'}
              />
            )}

            {accept && (
              <div className="mt-2 text-muted">
                Supported File types: {accept}
              </div>
            )}
          </div>
          {this.state.files && this.state.files?.length > 0 ? (
            <div className="card-footer mt-4">
              <Button
                variant="primary"
                title="Upload"
                className="btn btn-primary mr-2"
                size="sm"
                disabled={
                  this.state.uploadStatus === 'uploading' ||
                  this.state.files.length === 0
                }
                onClick={() => {
                  this.setState({ uploadStatus: 'uploading' });
                  this.handleUpload();
                }}
              >
                {`${this.state.uploadStatus === 'uploading' ? 'Uploading...' : 'Upload'}`}
              </Button>
              <Button
                variant="link"
                title="Cancel"
                size="sm"
                className="text-primary btn bg-transparent"
                onClick={() => this.handleCancel(true)}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </div>

        {this.state.clickCancel && this.state.uploadStatus !== 'uploading' && (
          <ConfirmationToast
            subtitle="You will lose all changes!"
            onConfirm={this.handleClose}
            handleCancel={this.handleCancel}
            // iconComponent={<FaCheck className='text-success' size={40} />}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentModal,
  currentUser,
  organization,
  resourceGroups,
  resourceGroup
}) => ({
  currentModal,
  currentUser,
  organization,
  resourceGroups,
  resourceGroup
});

export default connect(mapStateToProps, {
  saveCdnFiles,
  resetCurrentModal
})(UploadFiles);
