import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchSkills } from '../../../services/skills';

const Skills = ({ className = 'mb-3' }) => {
  const organization = useSelector((state) => state.organization);
  const orgId = organization?.id || null;

  const [skillsList, setSkillsList] = useState(null);

  const defaultSkills = [
    {
      name: 'Critical Thinking'
    },
    {
      name: 'Adaptability'
    },
    {
      name: 'Time Management'
    },
    {
      name: 'Spreadsheet Management'
    },
    {
      name: 'Accounts Receivable Analysis'
    }
  ];

  useEffect(() => {
    fetchSkills({ orgId }).then((orgSkills) => {
      if (Array.isArray(orgSkills?.items) && orgSkills?.items.length > 0) {
        setSkillsList(orgSkills?.items.slice(0, 8));
      }
    });
  }, [orgId]);

  return (
    <div className={className}>
      <h5>Skills</h5>
      <div className="d-flex flex-wrap mb-0">
        {Array.isArray(skillsList) && skillsList.length > 0 ? (
          <>
            {skillsList.map((skill, i) => (
              <div
                key={`skill-${i}`}
                className="h6 mr-3 mb-2"
              >
                <span className="badge badge-pill bg-white py-2 px-3 shadow">
                  {skill?.name}
                </span>
              </div>
            ))}
          </>
        ) : (
          <>
            {defaultSkills.map((skill, i) => (
              <div
                key={`defaultSkill-${i}`}
                className="h6 mr-3 mb-2"
              >
                <span className="badge badge-pill bg-white py-2 px-3 shadow">
                  {skill?.name}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Skills;
