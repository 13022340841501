import React from 'react';
import { PLATFORM_NAME } from '../../../constants/globals';
import { TURBINE_MARKETING_SITE } from '../../../constants/urls';
import './style.css';

const PoweredBy = ({
  theme = 'light',
  className = '',
  trackingCode = 'poweredBy'
}) => {
  return (
    <div
      className={`poweredby ${className} ${theme === 'dark' ? 'theme-dark' : ''}`}
    >
      Powered by{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${TURBINE_MARKETING_SITE}?track=${trackingCode}`}
        title={`Powered by ${PLATFORM_NAME}`}
      >
        {PLATFORM_NAME}
      </a>
    </div>
  );
};

export default PoweredBy;
