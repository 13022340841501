import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CLASS_CONSOLE_QUIZZES,
  MANAGE_CURRENT_ENTRY
} from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';
import { canManageCourseContent } from '../../../services/currentUser';
import { manageEntry } from '../../../services/entry';

const Tabs = ({ className = '', active = 'all' }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const currentUser = useSelector((state) => state.currentUser);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Organization
  const orgType = organization?.type || null;
  // Course
  const classId = course?.id || null;
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });
  // Quiz
  const quizId = currentQuiz?.id || null;
  const topicId = currentQuiz.topicId || null;

  return (
    <div className={`${className} bg-light`}>
      <ul className="nav nav-tabs pl-3">
        <li className="nav-item">
          <Link
            to={{
              pathname: MANAGE_CURRENT_ENTRY,
              search: manageEntry({
                manageType: 'edit',
                entryId: quizId,
                topicId,
                classId,
                contentType: 'quiz'
              })
            }}
            title="Settings"
            className={`nav-link mr-2 ${active === 'settings' ? 'active' : ''} ${manageCourse ? '' : 'disabled'} `}
          >
            Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            title="Outcomes"
            className={`disabled nav-link ${active === 'outcomes' ? 'active' : ''} d-flex align-items-center`}
            to={routeWithClassId({
              route: `${CLASS_CONSOLE_QUIZZES}/${quizId}`,
              classId
            })}
          >
            <span>Outcomes</span>
            <span className="badge bg-white border px-2 py-1 ml-2">
              {quizOutcomes?.pagination?.total || 0}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
