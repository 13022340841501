import React from 'react';

const TitleCell = ({ value: name }) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ height: '40px' }}
    >
      <span>{name}</span>
    </div>
  );
};

export default TitleCell;
