import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ASSESSMENTS } from '../../../../constants/routes';
import { canManageAssessments } from '../../../../services/currentUser';

const ReviewCell = ({ row, reviewActive }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const courses = useSelector((state) => state.courses);

  const {
    original: { id: answerId, classId, topicId, userId }
  } = row;
  let answerCourse = course;

  if (!course?.id && classId && Array.isArray(courses.list)) {
    answerCourse = courses.list.find((c) => c.id === classId) || null;
  }

  const manageAssessment = canManageAssessments({
    course: answerCourse,
    currentUser
  });

  if (manageAssessment && answerId && classId && topicId && userId) {
    return (
      <Link
        to={{
          pathname: `${ASSESSMENTS}/${answerId}/${classId}/${topicId}/${userId}/?redirectUrl=${window.location.pathname}`
        }}
        title="Review"
        className="btn btn-outline-info btn-sm d-inline-flex align-items-center"
        target={reviewActive ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        <span>Review</span>
        {reviewActive && (
          <span className="small ml-2">
            <FaExternalLinkAlt />
          </span>
        )}
      </Link>
    );
  }

  return null;
};

export default ReviewCell;
