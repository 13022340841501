import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaBorderStyle,
  FaCog,
  FaDownload,
  FaEquals,
  FaMobileAlt,
  FaPalette,
  FaPrint
} from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import {
  reactSelectDefaultValue,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import { OrgLogo } from '@apprentage/components';
import { resetCurrentModal } from '../../../actions/Modals';
import { IMAGE_TURBINE_BLACK } from '../../../constants/assets';
import { getPageLink } from '../../../actions/PageLinks';
import svgToCanvasAndDownload from '../../../utils/svgToCanvasAndDownload';
import Modal from '../../Modal';
import Switch from '../../Switch';
import ColorPicker from '../../ManageContent/ColorPicker';
import TitleSwitch from './TitleSwitch';
import Title from './Title';
import OrgLogoSwitch from './OrgLogoSwitch';
import SubtitleSwitch from './SubtitleSwitch';
import Subtitle from './Subtitle';
import './rulers.css';
import './style.css';

// Dimensions
const DEFAULT_DIMENSIONS = '3x4';
const DEFAULT_HEIGHT = 4.25;
const DEFAULT_WIDTH = 3.25;
const DEFAULT_COLOR_BKG = '#ffffff';
const DEFAULT_COLOR_TEXT = '#2c3a47';
const DIMENSIONS_ENUM = {
  '2x3': '2" x 3"',
  '3x4': '3" x 4"',
  '3x5': '3" x 5"'
};

const PageLinkShare = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const qrCodeRef = useRef(null);
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const page = useSelector((state) => state.page);
  const pageLink = useSelector((state) => state.pageLink);
  // Modal
  // const pageTitle = currentModal?.data?.pageTitle || null;
  const qrCodeUrl = currentModal?.data?.qrCodeUrl || null;
  // Local State
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const [dimensions, setDimensions] = useState(
    pageLink?.dimensions || DEFAULT_DIMENSIONS
  );
  const [colorBkg, setColorBkg] = useState(
    pageLink?.colorBkg || DEFAULT_COLOR_BKG
  );
  const [colorText, setColorText] = useState(
    pageLink?.colorText || DEFAULT_COLOR_TEXT
  );

  const [showSafetyBleed, setShowSafetyBleed] = useState(true);
  const [showRulers, setShowRulers] = useState(true);

  const dimensionOptions = reactSelectOptionsFromEnum(DIMENSIONS_ENUM);
  const genderDefaultValue = reactSelectDefaultValue(
    dimensions,
    dimensionOptions
  );

  const idHash = useMemo(() => {
    if (pageLink?.id) {
      const idHashArr = pageLink?.id.split('-');

      return idHashArr[0];
    }

    return '';
  }, [pageLink?.id]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const inToPx = (inches) => {
    // 1px = 96px
    const unit = 96;
    const pixels = inches * (unit + unit / 3);
    return pixels.toFixed(2);
  };

  const handleColorBkg = ({ colorBkg: newColorBkg }) => {
    setColorBkg(newColorBkg);
  };

  const handleColorText = ({ colorText: newColorText }) => {
    setColorText(newColorText);
  };

  const retrievePageLink = () => {
    dispatch(getPageLink(pageLink?.id));
  };

  const onClickDownload = async () => {
    await svgToCanvasAndDownload(
      qrCodeRef?.current,
      `turbine-qrcode-${pageLink?.id}`,
      idHash
    );
  };

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key} turbine-modal--style-fullscreen`}
        visible={currentModal.visible}
        close={handleClose}
      >
        <div className="card">
          <div className="card-body p-0 h-100">
            <div className="d-flex h-100">
              <div
                id="pageLink-leftNav"
                className="col-3 h-100 bg-white p-3 shadow d-print-none"
              >
                <h5 className="font-weight-bold text-center mb-5">
                  Linkpage QR Code
                </h5>
                <div>
                  <div className="mb-4">
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold">
                        <span className="d-flex align-items-center">
                          <FaBorderStyle />
                          <span className="ml-2">Dimensions</span>
                        </span>
                      </h6>
                    </div>
                    <SelectMenu
                      name="dimensions"
                      options={dimensionOptions}
                      defaultValue={genderDefaultValue}
                      required
                      onChange={({ value }) => {
                        if (value === '2x3') {
                          setWidth(2.25);
                          setHeight(3.25);
                        }

                        if (value === '3x4') {
                          setWidth(3.25);
                          setHeight(4.25);
                        }

                        if (value === '3x5') {
                          setWidth(3.25);
                          setHeight(5.25);
                        }

                        setDimensions(value);
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold">
                        <span className="d-flex align-items-center">
                          <FaPalette />
                          <span className="ml-2">Design</span>
                        </span>
                      </h6>
                    </div>
                    <div className="border rounded p-3 mb-3 d-flex align-items-center justify-content-between">
                      <div>Background</div>
                      <ColorPicker
                        label="Background Color"
                        id="colorBkg"
                        name="colorBkg"
                        hexColor={colorBkg}
                        hexColorDefault={colorBkg}
                        onChange={handleColorBkg}
                        showLabel={false}
                        // chooseDefaultColors
                        chooseCustomColor
                      />
                    </div>
                    <div className="border rounded p-3 d-flex align-items-center justify-content-between">
                      <div>Text</div>
                      <ColorPicker
                        label="Text Color"
                        id="colorText"
                        name="colorText"
                        hexColor={colorText}
                        hexColorDefault={colorText}
                        onChange={handleColorText}
                        showLabel={false}
                        // chooseDefaultColors
                        chooseCustomColor
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold">
                        <span className="d-flex align-items-center">
                          <FaEquals />
                          <span className="ml-2">Elements</span>
                        </span>
                      </h6>
                    </div>
                    <div className="">
                      <OrgLogoSwitch
                        imageHeight={25}
                        className="mb-3 border rounded p-2 pl-3 align-items-center d-flex justify-content-between"
                        defaultValue={pageLink?.showOrgLogo}
                        onSuccessCallback={retrievePageLink}
                      />

                      <TitleSwitch
                        className="mb-3 border rounded p-2 pl-3 align-items-center d-flex justify-content-between"
                        defaultValue={pageLink?.showTitle}
                        onSuccessCallback={retrievePageLink}
                      />

                      <SubtitleSwitch
                        className="mb-3 border rounded p-2 pl-3 align-items-center d-flex justify-content-between"
                        defaultValue={pageLink?.showSubtitle}
                        onSuccessCallback={retrievePageLink}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100 h-100 py-3 d-flex justify-content-center position-relative">
                <div
                  id="pageLink-view-dropdown"
                  className="dropdown position-absolute d-print-none"
                  style={{
                    bottom: '16px',
                    left: '16px'
                  }}
                >
                  <button
                    className="btn btn-sm btn-white border mr-2 d-flex align-items-center"
                    type="button"
                    id="userConfig"
                    title="Settings"
                    aria-label="Settings"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FaCog />
                    <span className="ml-2">View</span>
                  </button>
                  <div
                    className="dropdown-menu p-3"
                    aria-labelledby="userConfig"
                    style={{
                      minWidth: '400px'
                    }}
                  >
                    <h5 className="mb-4">View</h5>

                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div>
                        <h6 className="font-weight-bold">Rulers</h6>
                        <div className="small pr-3">
                          This is the size of your design.
                        </div>
                      </div>
                      <Switch
                        id="rulersSwitch"
                        value={showRulers}
                        onChange={() => {
                          setShowRulers(!showRulers);
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="font-weight-bold">
                          Safety area & Bleed
                        </h6>
                        <div className="small pr-3">
                          Text and images should fit inside, anything outside
                          the line will get cut.
                        </div>
                      </div>
                      <Switch
                        id="safetyBleedSwitch"
                        value={showSafetyBleed}
                        onChange={() => {
                          setShowSafetyBleed(!showSafetyBleed);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="position-absolute d-print-none p-3"
                  style={{
                    top: 0,
                    left: 0
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-white btn-sm mr-2"
                    aria-label="Download"
                    onClick={onClickDownload}
                  >
                    <span className="d-flex align-items-center">
                      <FaDownload />
                      <span className="ml-1">Download</span>
                    </span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-white btn-sm mr-2"
                    aria-label="Print"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <FaPrint />
                      <span className="ml-1">Print</span>
                    </span>
                  </button>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <div
                    ref={containerRef}
                    id="page-qrcode-container"
                    className="border rounded shadow d-flex flex-column justify-content-center position-relative"
                    style={{
                      height: `${inToPx(height)}px`,
                      width: `${inToPx(width)}px`,
                      backgroundColor: colorBkg,
                      color: colorText
                    }}
                  >
                    {showSafetyBleed && (
                      <div
                        className="safety-bleed d-print-none"
                        style={{
                          height: `${inToPx(height) - 32 * 2}px`,
                          width: `${inToPx(width) - 32 * 2}px`,
                          left: `calc(50% - ${(inToPx(width) - 32 * 2) / 2}px)`
                        }}
                      />
                    )}
                    <div className="pageLink-inner">
                      <div>
                        {pageLink?.showOrgLogo && (
                          <OrgLogo
                            className="mx-auto mt-4"
                            src={organization?.orgLogo}
                            alt={organization?.name}
                            height={40}
                          />
                        )}

                        {pageLink?.showTitle && (
                          <Title
                            className="mt-4"
                            defaultValue={pageLink?.title || page?.title}
                            onSuccessCallback={retrievePageLink}
                          />
                        )}

                        {pageLink?.showSubtitle && (
                          <Subtitle
                            className="mt-4"
                            defaultValue={
                              pageLink?.subtitle ||
                              page?.subtitle ||
                              'Subtitle...'
                            }
                            onSuccessCallback={retrievePageLink}
                          />
                        )}

                        {qrCodeUrl && (
                          <>
                            <div className="position-relative">
                              <QRCodeSVG
                                ref={qrCodeRef}
                                className="rounded mx-auto mt-3 border"
                                value={qrCodeUrl}
                                size={140}
                                bgColor="#ffffff"
                                fgColor="#000000"
                                marginSize={5}
                                level="L"
                              />
                              <div
                                className="position-absolute w-100 text-center"
                                style={{
                                  fontSize: '8px',
                                  bottom: '3px',
                                  letterSpacing: '2px',
                                  fontWeight: 'bold'
                                }}
                              >
                                {idHash}
                              </div>
                            </div>

                            <div className="d-flex align-items-start justify-content-center mt-2 mx-auto">
                              <img
                                src={IMAGE_TURBINE_BLACK}
                                height="18"
                                style={{
                                  height: '18px'
                                }}
                                alt="Turbine Workforce"
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex justify-content-center mb-5">
                        <div className="d-flex align-items-center mt-5">
                          <FaMobileAlt size={50} />
                          <div className="ml-2">
                            {/* <div className='font-weight-bold h6 mb-0'>

                      </div> */}
                            <div className="mb-1">
                              Use mobile device camera
                              <br />
                              to scan the QR code.
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='mt-3 d-flex justify-content-center'>
                        <div>
                          Powered by turbineworkforce.com
                        </div>
                      </div> */}
                    </div>

                    {showRulers && (
                      <>
                        <div
                          className="ruler-h d-print-none"
                          style={{
                            height: `${inToPx(height)}px`
                          }}
                        >
                          <div className="ruler-h-inner">
                            <div className="ruler-h-line" />
                            <div
                              className="ruler-h-dimensions"
                              style={{
                                color: 'black'
                              }}
                            >
                              {height}"
                            </div>
                          </div>
                        </div>

                        <div
                          className="ruler-w d-print-none"
                          style={{
                            width: `${inToPx(width)}px`
                          }}
                        >
                          <div className="ruler-w-inner">
                            <div className="ruler-w-line" />
                            <div
                              className="ruler-w-dimensions"
                              style={{
                                color: 'black'
                              }}
                            >
                              {width}"
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card-footer d-flex justify-content-center d-print-none">
            <button
              className="btn btn-link p-0"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default PageLinkShare;
