import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import {
  routeWithOrgId,
  updateOrganization
} from '../../../../services/organizations';
import { ORG_CONSOLE_USERS_GROUPS } from '../../../../constants/routes';
import sortArrByArr from '../../../../utils/sortArrByArr';
import ItemsDnd from '../../../ManageContent/Dnd/Items';

const Form = () => {
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const userGroups = useSelector((state) => state.userGroups);
  // Local State
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [reorderedList, setReorderedList] = useState(userGroups.list);
  // Misc
  const orgId = pathOr('', ['id'], organization);
  const userGroupIds = pathOr(null, ['userGroupIds'], organization);
  const userGroupsRoute = routeWithOrgId({
    route: ORG_CONSOLE_USERS_GROUPS,
    orgId
  });

  const saveListOrder = () => {
    setLoading(true);

    const data = {
      userGroupIds: reorderedList.map((item) => item.id)
    };

    updateOrganization(data, orgId).then(() => {
      toast.success('Saved!');
      setLoading(false);
      history.push(userGroupsRoute);
    });
  };

  const onUpdate = (newListOrder) => {
    setHasChanges(true);
    setReorderedList(
      newListOrder && newListOrder.length > 0 ? newListOrder : []
    );
  };

  const sortedUserGroups =
    userGroups.list && userGroupIds
      ? sortArrByArr(userGroups.list, userGroupIds, 'id')
      : null;
  const sortedGroups = hasChanges ? reorderedList : sortedUserGroups;

  return (
    <>
      <div
        id="org-userGroups"
        className="card-body"
      >
        {sortedGroups && sortedGroups && (
          <ItemsDnd
            items={sortedGroups}
            onUpdate={onUpdate}
          />
        )}
      </div>

      {sortedGroups && sortedGroups.length > 0 && (
        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={saveListOrder}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <Link
              to={userGroupsRoute}
              title="Cancel"
              className="btn btn-link"
              disabled={loading}
            >
              Cancel
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Form;
