import React, { useMemo } from 'react';

const RiskRatingCell = ({ value: riskRatingRatio, row }) => {
  const riskRatingReadable = row?.original?.riskRatingReadable;

  const badgeClassName = useMemo(() => {
    if (riskRatingReadable === 'Low') {
      return 'badge-secondary';
    }

    if (riskRatingReadable === 'Medium') {
      return 'badge-warning';
    }

    if (riskRatingReadable === 'High') {
      return 'border-danger text-danger';
    }

    if (riskRatingReadable === 'Critical') {
      return 'badge-danger';
    }

    return '';
  }, [riskRatingReadable]);

  if (!riskRatingRatio) {
    return null;
  }

  return (
    <span className="h6">
      <span
        className={`badge ${badgeClassName}`}
        data-ratio={riskRatingRatio}
      >
        {riskRatingReadable}
      </span>
    </span>
  );
};

export default RiskRatingCell;
