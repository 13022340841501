import React, { Fragment } from 'react';
import { FaMagic } from 'react-icons/fa';
import { turbinePrompts } from '../../../../constants/resources';
import Prompt from './Prompt';

const DotMenu = ({ className = '', disabled = false, performQuery }) => {
  return (
    <div className={className}>
      <button
        className="btn btn-md btn-white"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaMagic />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white">
          <span className="d-flex align-items-center">
            <FaMagic />
            <span className="ml-2">Prompts</span>
          </span>
        </div>

        {turbinePrompts.map((prompt, i) => (
          <Fragment key={`default-menu-prompts-${i}`}>
            <Prompt
              disabled={disabled}
              className="dropdown-item border-bottom py-2 pr-3 cursor-pointer"
              promptText={prompt?.text}
              promptBadge={prompt?.badge}
              promptType={prompt?.type}
              onClick={performQuery}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default DotMenu;
