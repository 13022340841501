import {
  RESET_CURRENT_ANSWERS,
  SET_CURRENT_ANSWERS
} from '../../actions/types';

const initialState = {
  list: null,
  pagination: null,
  fetched: false
};

export default function currentAnswers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ANSWERS:
      return {
        ...state,
        list: action.currentAnswers,
        pagination: action.pagination || null,
        fetched: true
      };
    case RESET_CURRENT_ANSWERS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
