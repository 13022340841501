import { utils, writeFile } from 'xlsx';
import { quizTotalScore } from '../../../services/contentFlow/quizzes';
import { mdySimple } from '../../../utils/date';

const generateFileName = ({ quizOutcome }) => {
  let name = 'Quiz Outcome';

  if (quizOutcome?.userName) {
    name += ` - ${quizOutcome.userName}`;
  }

  name += ` - ${mdySimple(quizOutcome?.createdAt, '-')}`;

  return name;
};

/* This function creates gap rows */
function createGapRows(ws, nRows) {
  const ref = utils.decode_range(ws['!ref']); // get original range
  ref.e.r += nRows; // add to ending row
  ws['!ref'] = utils.encode_range(ref); // reassign row
}

const exportData = ({
  course,
  currentQuiz,
  quizOutcome,
  orgName,
  syndicatedCourseOrgs
}) => {
  const { userName, score, maxScore, attempts, createdAt, orgId } = quizOutcome;

  const syndicatedCourseOrg = syndicatedCourseOrgs.list
    ? syndicatedCourseOrgs.list.find((o) => o.id === orgId)
    : null;

  const quizOutcomeData = quizOutcome.userAnswers.map((answer, i) => {
    return [
      `${i + 1}`,
      `${quizOutcome.rubric[i] + 1}`,
      `${answer + 1}`,
      quizOutcome.evaluation[i] ? 'Correct' : 'x'
    ];
  });

  const fileName = generateFileName({ quizOutcome });

  const wb = utils.book_new();

  const metadata = [
    ['Name:', userName],
    ['Organization:', syndicatedCourseOrg?.name || orgName],
    ['Course:', course?.title],
    ['Quiz:', currentQuiz.title],
    ['Submitted', mdySimple(createdAt)],
    ['Score', `${score}`],
    ['Percentage', quizTotalScore({ score, maxScore })],
    ['Attempts', `${attempts}`]
  ];

  const userAnswersData = [
    ['Question #', 'Correct Answer', 'Submitted Answer', 'Evaluation'],
    ...quizOutcomeData
  ];

  /**
   * User Meta Data
   * User Answers
   */
  // https://docs.sheetjs.com/docs/api/utilities/#array-of-arrays-input
  const wsUser = utils.aoa_to_sheet(metadata);

  createGapRows(wsUser, 1);
  utils.sheet_add_aoa(wsUser, userAnswersData, { origin: -1 });
  createGapRows(wsUser, 2);
  utils.sheet_add_aoa(wsUser, [[`Exported: ${mdySimple(new Date(), '/')}`]], {
    origin: -1
  });
  utils.sheet_add_aoa(wsUser, [['Powered by Turbine Workforce']], {
    origin: -1
  });

  wsUser['!cols'] = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 }
  ];

  utils.book_append_sheet(wb, wsUser, 'Single User Basis');

  /**
   * Quiz Questions
   */
  const questionData = [];
  quizOutcome.questions.forEach((question, index) => {
    const answers = question.answers.map(
      ({ text, correct }, i) => `    ${i + 1}. ${correct ? '(✓) ' : ''}${text}`
    );

    questionData.push([`${index + 1}. ${question?.title}`]);
    questionData.push(['']);
    questionData.push([answers.join('\n\n')]);
    questionData.push(['']);
    questionData.push(['']);
    questionData.push(['']);
  });

  const wsQuestions = utils.aoa_to_sheet(questionData);

  utils.sheet_add_aoa(
    wsQuestions,
    [[`Exported: ${mdySimple(new Date(), '/')}`]],
    { origin: -1 }
  );
  utils.sheet_add_aoa(wsQuestions, [['Powered by Turbine Workforce']], {
    origin: -1
  });

  wsQuestions['!cols'] = [{ width: 300 }];

  utils.book_append_sheet(wb, wsQuestions, 'Quiz');

  writeFile(wb, `${fileName}.xlsx`);
};

export default exportData;
